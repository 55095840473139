import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import {
  LeftAlignedTableCell,
  StyledRow,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import { Table } from 'semantic-ui-react';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import React from 'react';
import { InstructionOverview } from '../../../model/domain/instruction/instruction';
import { StyledRightColumn } from '../../../components/StyledComponents';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { INSTRUCTIONS_ROUTE_KEY } from '../../instructions/interfaces';

const ActionName = styled(LeftAlignedTableCell)`
  font-family: Relative;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #242424;
  &&& {
    padding-left: 24px;
  }
`;

const Score = styled(LeftAlignedTableCell)`
  color: red;

  ${(props) => props.color > 50 && 'color: orange;'}
  ${(props) => props.color > 80 && 'color: green;'}
`;

interface ActionRowProps {
  data: InstructionOverview;
}

const ActionRowSimplified: React.FC<ActionRowProps> = ({ data }) => {
  const { id, createdDate, accessCount, votes, name } = data;
  const navigate = useNavigate();
  const { formatTimestamp } = useLocalizedDateFormatters();
  const score = votes > 0 ? `${votes.toFixed(0)} %` : '-';

  const onRowSelected = () => {
    navigate(`/${INSTRUCTIONS_ROUTE_KEY}/${id}`);
  };

  return (
    <StyledRow onClick={onRowSelected}>
      <ActionName>{name}</ActionName>
      <Score color={votes}>{score}</Score>
      <LeftAlignedTableCell>{accessCount}</LeftAlignedTableCell>
      <LeftAlignedTableCell>
        {formatTimestamp(createdDate)}
      </LeftAlignedTableCell>
      <Table.Cell>
        <StyledRightColumn>
          <Icon name={'arrow-right-bold'} color={'#8c8c8c'} size={16} />
        </StyledRightColumn>
      </Table.Cell>
    </StyledRow>
  );
};

export default ActionRowSimplified;
