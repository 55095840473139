import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';
import { connect } from 'react-redux';
import ManufacturingEntityDropdown from '@dev/base-web/dist/view/components/inputs/manufacturing_entity_dropdown';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { RootReducerInterface } from '../../../model/redux/interfaces';
import ToggleSwitch from '@dev/base-web/dist/view/components/inputs/toggle_switch';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import { StyledInput } from '@dev/base-web/dist/view/components/inputs/styled_components';

const Description = styled.h2`
  margin: 16px 0 8px 0;
  font-size: 14px;
  color: #8c8c8c;
`;

const ModalInput = styled(StyledInput)`
  width: 100%;
`;

const Wrapper = styled.div`
  margin-top: 16px;
`;

const StyledManufacturingEntityDropdown = styled(ManufacturingEntityDropdown)`
  &&&& {
    width: 312px;
  }
`;

interface DeleteModalExternalProps {
  currentManufacturingEntity?: ManufacturingEntity;
  readonly open: boolean;
  readonly changeCallback: (manEnt: ManufacturingEntity) => void;
  readonly deleteCallback: () => void;
  closeCallback: () => void;
}

interface DeleteModalState {
  readonly selectedManufacturingEntity: ManufacturingEntity | undefined;
  readonly changeMan: boolean;
}

interface DeleteModalStoreProps {
  readonly manufacturingEntities: ManufacturingEntity[];
}

type DeleteModalProps = DeleteModalExternalProps & DeleteModalStoreProps;

class DeleteModal extends Component<DeleteModalProps, DeleteModalState> {
  constructor(props: DeleteModalProps) {
    super(props);

    this.state = {
      selectedManufacturingEntity: undefined,
      changeMan: false,
    };
  }

  onSwitch = (checked: boolean) => {
    this.setState({ changeMan: checked });
  };

  onManufacturingEntitySelected = (manEnt: readonly ManufacturingEntity[]) => {
    if (manEnt.length === 0) {
      this.setState({ selectedManufacturingEntity: undefined });
      return;
    }
    this.setState({ selectedManufacturingEntity: manEnt[0] });
  };

  handleDelete = () => {
    this.props.deleteCallback();
  };

  handleChange = () => {
    if (!this.state.selectedManufacturingEntity) return;
    this.props.changeCallback(this.state.selectedManufacturingEntity);
  };

  render() {
    const { selectedManufacturingEntity, changeMan } = this.state;

    return (
      <BasicModalWithHeaderAndTwoButtons
        open={this.props.open}
        headerLabel="remove_or_update_production_entity"
        cancelLabel="cancel_button"
        okLabel={changeMan ? 'update' : 'remove'}
        onOk={() => {
          changeMan ? this.handleChange() : this.handleDelete();
        }}
        onCancel={this.props.closeCallback}
        onClose={this.props.closeCallback}
        okDisabled={changeMan && !selectedManufacturingEntity}
      >
        <Description>
          <FormattedMessage id="selected_production_entity" />
        </Description>
        <ModalInput
          value={
            this.props.currentManufacturingEntity
              ? this.props.currentManufacturingEntity.name
              : ''
          }
          disabled
        />
        <Description>
          <FormattedMessage id="replace_production_entity" />
        </Description>
        <Wrapper>
          <ToggleSwitch onChange={this.onSwitch} checked={changeMan} />
        </Wrapper>
        {changeMan && (
          <Wrapper>
            <Description>
              <FormattedMessage id="new_production_entity" />
            </Description>
            <StyledManufacturingEntityDropdown
              fluid
              manufacturingEntities={this.props.manufacturingEntities}
              onManufacturingEntitySelected={this.onManufacturingEntitySelected}
              selectedManufacturingEntity={selectedManufacturingEntity}
              searchable
            />
          </Wrapper>
        )}
        <Wrapper>
          <Hint messageKey={'event_replace_production_entity_hint'} />
        </Wrapper>
      </BasicModalWithHeaderAndTwoButtons>
    );
  }
}

const mapStateToProps = (
  state: RootReducerInterface
): DeleteModalStoreProps => ({
  manufacturingEntities:
    state.data.manufacturingEntityState.manufacturingEntities.data,
});

export default connect(mapStateToProps)(DeleteModal);
