import { createActionsForSingleItem } from '@dev/base-web/dist/model/redux/helpers/actions';
import { Instruction } from '../../domain/instruction/instruction';

export const ACTION_TYPES = {
  GET_CURRENT_ITEM_KEY: 'GET_CURRENT_ITEM',
};

export const Actions = {
  currentInstruction: createActionsForSingleItem<Instruction>(
    ACTION_TYPES.GET_CURRENT_ITEM_KEY
  ),
};
