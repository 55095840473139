import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';
import styled from 'styled-components';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import { Column } from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import { TableRow } from 'semantic-ui-react';
import {
  CenterAlignedTableCell,
  CheckBoxCell,
  LeftAlignedTableCell,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from '@dev/base-web/dist/view/components/global/checkbox';
import React, { useEffect, useMemo, useState } from 'react';
import { CheckboxProps } from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import AddLanguagePopup from './add_language_popup';
import { getTranslationWarning } from '@/screens/edit_instruction/components/helper.ts';
import { BasicActionDefinitionDTO } from '@/model/domain/instruction/instruction';
import ConfigurationParameters from '@dev/base-web/dist/model/domain/configuration/configuration';
import { localisation } from '@/model/redux/reducers.ts';
import { LoadingMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';

const columns: Column[] = [
  {
    title: 'language',
    isLeftAligned: true,
    width: 8,
  },
  {
    title: 'existing_translation',
    width: 8,
  },
];

const ContentContainer = styled.div`
  padding-bottom: 16px;
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
`;

const HintContainer = styled.div`
  margin-top: 16px;
`;

const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.43;
  letter-spacing: normal;
  color: #242424;
`;

const WarningText = styled.p`
  margin-left: 7px;
`;

export interface NewTranslationModalProps {
  readonly instruction: BasicActionDefinitionDTO;
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onOk: (languageCodes: string[]) => void;
  readonly selectedLanguage?: string;
  readonly config: ConfigurationParameters | null;
  readonly currentLocale: string;
  readonly translationMeta: LoadingMetaState;
}

const SelectTranslationsToUpdateModal: React.FC<NewTranslationModalProps> = ({
  instruction,
  open,
  onClose,
  onOk,
  config,
  selectedLanguage,
  currentLocale,
  translationMeta,
}) => {
  const translationServiceWarningText = getTranslationWarning(
    config,
    currentLocale
  );

  // instruction must not be null here
  if (!instruction) return null;

  const [showLanguages, setShowLanguages] = useState(
    instruction.availableTranslations ?? []
  );

  const languageCount = instruction.availableTranslations?.length ?? 0;

  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);

  useEffect(() => {
    setSelectedLanguages(
      instruction.availableTranslations?.filter(
        (t) => t !== selectedLanguage
      ) || []
    );
  }, [instruction.availableTranslations, selectedLanguage]);

  const localAvailableTranslations = useMemo(() => {
    return instruction.translations
      .filter((t) => !!t.translation)
      .map((t) => t.language);
  }, [instruction]);

  const selectableLanguages = Object.keys(localisation).filter(
    (item) => !showLanguages.includes(item.toUpperCase())
  );

  return (
    <>
      <BasicModalWithHeaderAndTwoButtons
        okLabel="translate"
        cancelLabel="no"
        headerLabel="update_translations"
        okDisabled={selectedLanguages.length === 0}
        onOk={() => {
          onOk(selectedLanguages);
        }}
        onClose={onClose}
        onCancel={onClose}
        open={open}
        okLoading={translationMeta.loadingInProgress}
      >
        <ContentContainer>
          <AddLanguagePopup
            values={selectableLanguages}
            position="top left"
            trigger={
              <Button
                type="secondary"
                icon="plus"
                label="add_language"
                size="small"
              />
            }
            onAddLanguage={(language) => {
              setShowLanguages([...showLanguages, language.toUpperCase()]);
              setSelectedLanguages([
                ...selectedLanguages,
                language.toUpperCase(),
              ]);
            }}
          />

          <SortableTable
            hasMoreResults={false}
            loadingInProgress={false}
            columns={columns}
            dataLength={languageCount - 1}
            getNextResults={() => {}}
            filtersDisabled
            headerCheckboxInvisible
            onAllRowsCheckboxChanged={() => {}}
            minHeight={150}
          >
            {showLanguages.map((language) => {
              if (language === selectedLanguage) return null;

              return (
                <TableRow key={language}>
                  <CheckBoxCell>
                    <Checkbox
                      checked={selectedLanguages.includes(language)}
                      onChange={(_e: unknown, data: CheckboxProps) => {
                        if (
                          data.checked &&
                          !selectedLanguages.includes(language)
                        ) {
                          // Put the last edited language the first element.
                          if (language === instruction?.language) {
                            setSelectedLanguages((selectedLanguages) => [
                              language,
                              ...selectedLanguages,
                            ]);
                          } else {
                            setSelectedLanguages((selectedLanguages) => [
                              ...selectedLanguages,
                              language,
                            ]);
                          }
                        } else if (!data.checked) {
                          setSelectedLanguages((selectedLanguages) => [
                            ...selectedLanguages.filter((l) => l !== language),
                          ]);
                        }
                      }}
                    />
                  </CheckBoxCell>
                  <LeftAlignedTableCell>
                    <FormattedMessage id={language.toLowerCase()} />
                  </LeftAlignedTableCell>
                  <CenterAlignedTableCell>
                    {localAvailableTranslations.includes(language) && (
                      <Icon name="checkmark" size={16} color="#078388" />
                    )}
                  </CenterAlignedTableCell>
                </TableRow>
              );
            })}
          </SortableTable>
          {translationServiceWarningText ? (
            <WarningContainer>
              <Icon name="warning" color={'#DC8500'} size={34} />
              <WarningText>
                {translationServiceWarningText}
                <br />
                <FormattedMessage id={'update_translations_hint'} />
              </WarningText>
            </WarningContainer>
          ) : (
            <HintContainer>
              <Hint messageKey="update_translations_hint" textColor="black" />
            </HintContainer>
          )}
        </ContentContainer>
      </BasicModalWithHeaderAndTwoButtons>
    </>
  );
};

export default SelectTranslationsToUpdateModal;
