import { createActionsForLoadingPagingList } from '@dev/base-web/dist/model/redux/helpers/actions';
import AggregatedDowntime from '../../domain/downtime/AggregatedDowntime';

export const AGGREGATED_DOWNTIMES_KEY = 'AGGREGATED_DOWNTIMES';

export const Actions = {
  aggregatedDowntimes: createActionsForLoadingPagingList<AggregatedDowntime>(
    AGGREGATED_DOWNTIMES_KEY
  ),
};
