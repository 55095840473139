import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Popup, StrictPopupProps } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { ListItem } from '@/screens/edit_instruction/components/sidebar/instruction_language_card.tsx';

const StyledGrid = styled.div`
  min-width: 180px;
  max-width: 384px;
`;

interface PopupProps {
  values: string[];
  trigger: ReactNode;
  onAddLanguage: (languageCode: string) => void;
  position?: StrictPopupProps['position'];
  readonly disabled?: boolean;
}

const AddLanguagePopup = (props: PopupProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Popup
      trigger={props.trigger}
      on={'click'}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      flowing
      position={props.position ?? 'top right'}
      disabled={props.disabled}
    >
      <StyledGrid>
        {props.values.map((lang, index) => {
          return (
            <ListItem
              key={lang}
              onClick={() => {
                setOpen(false);
                props.onAddLanguage(lang.toLowerCase());
              }}
              last={props.values.length - 1 === index}
            >
              <span>
                <FormattedMessage id={lang.toLowerCase()} />
              </span>
            </ListItem>
          );
        })}
      </StyledGrid>
    </Popup>
  );
};

export default AddLanguagePopup;
