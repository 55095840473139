import {
  Button,
  RightAlignedButtons,
} from '@dev/base-web/dist/view/components/global/button';
import React from 'react';
import styled from 'styled-components';
import { FilterTag } from '../../../../model/domain/filter_tag';

const ActionsContainer = styled(RightAlignedButtons)`
  width: 100%;
  margin-top: 16px;
`;

interface FilterPopupActionsProps {
  readonly onClearPressed: () => void;
  readonly onApplyPressed: () => void;
  readonly localFilters: FilterTag[];
  readonly externalFilters: FilterTag[];
}

const FilterPopupActions: React.FC<FilterPopupActionsProps> = (props) => {
  const { onClearPressed, onApplyPressed, localFilters, externalFilters } =
    props;

  const buttonsDisabled =
    localFilters.length === 0 && externalFilters.length === 0;

  return (
    <ActionsContainer>
      <Button
        type="secondary"
        label="clear"
        onClick={onClearPressed}
        disabled={buttonsDisabled}
      />
      <Button
        type="primary"
        label="apply"
        onClick={onApplyPressed}
        disabled={buttonsDisabled}
      />
    </ActionsContainer>
  );
};

export default FilterPopupActions;
