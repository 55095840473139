import { Wrapper } from '../styled_components';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import EditImage from '../edit_image';
import { Crop } from 'react-image-crop';
import PreUploadImageThumbnail from './pre_upload_image_thumbnail';
import { FormattedMessage } from 'react-intl';

import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';

const FORCE_CROP_ASPECT = 4 / 3;
const FORCE_CROP_CONFIG: Crop = {
  width: 100,
  unit: '%',
  x: 0,
  y: 0,
  height: 75,
};

const FullWidthCropButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 24px 8px;
`;

const TitleDescriptionContainer = styled.div`
  font-family: Relative;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ theme }) => theme.buttons.tertiary.textColor};
`;

const ScrollContainer = styled.div`
  overflow-y: scroll;
  //padding-bottom: 70px;
`;

const ImageGridContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin: 8px 24px 8px;
  padding: 16px 24px 0px;
  border-radius: 4px;
  background-color: #f2f2f2;
  border: dashed 2px ${({ theme }) => theme.colors.positive};
  flex-wrap: wrap;
`;

interface PreUploadImageEditor {
  readonly imageFilesForCropping: File[];
  readonly onUploadCancelled: () => void;
  readonly uploadFiles: (files: File[]) => void;
}

interface FileState {
  readonly file: File;
  readonly completed: boolean;
}
function doesFileNeedCropping(file: File): boolean {
  // get width/height of picture to check
  console.log(file.type);
  return false;
}

const PreUploadImageEditor = ({
  imageFilesForCropping,
  onUploadCancelled,
  uploadFiles,
}: PreUploadImageEditor) => {
  const [editedImageFiles, setEditedImageFiles] = useState<FileState[]>(
    imageFilesForCropping.map((file) => ({
      file,
      completed: !doesFileNeedCropping(file),
    }))
  );
  const [fullContent, setFullContent] = useState<string | undefined>();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const selected = editedImageFiles[selectedIndex];

  useEffect(() => {
    const fileUrl = URL.createObjectURL(selected.file);
    setFullContent(fileUrl);
    return () => URL.revokeObjectURL(fileUrl);
  }, [selected]);

  const onSelectedImageDeleted = () => {
    setEditedImageFiles((files) => [
      ...files.slice(0, selectedIndex),
      ...files.slice(selectedIndex + 1, files.length),
    ]);

    setSelectedIndex(0);
  };

  const onSelectedPictureUpdated = (updatedFile: File) => {
    setEditedImageFiles((files) =>
      files.map((fileState, index) => {
        if (index === selectedIndex) {
          return {
            file: updatedFile,
            completed: true,
          };
        } else {
          return fileState;
        }
      })
    );
  };

  return (
    <BasicModalWithHeaderAndTwoButtons
      open={true}
      headerLabel="choose_picture_video"
      okLabel="save"
      cancelLabel="cancel_button"
      cancelDisabled={false}
      onClose={onUploadCancelled}
      onCancel={onUploadCancelled}
      modalSize={'large'}
      okDisabled={editedImageFiles.some((f) => !f.completed)}
      onOk={() => uploadFiles(editedImageFiles.map((fs) => fs.file))}
    >
      <FullWidthCropButtonContainer>
        <Button
          type="tertiary"
          size="large"
          icon="arrow-left-bold"
          onClick={onUploadCancelled}
        />
        <TitleDescriptionContainer>
          <FormattedMessage id={'crop_images_before_upload'} />
        </TitleDescriptionContainer>
      </FullWidthCropButtonContainer>
      <ScrollContainer>
        <ImageGridContainer>
          {editedImageFiles.map((fileState, index) => (
            <PreUploadImageThumbnail
              key={index}
              file={fileState.file}
              onSelect={() => setSelectedIndex(index)}
              selected={selectedIndex === index}
              completed={fileState.completed}
            />
          ))}
        </ImageGridContainer>
        <Wrapper fullWidth>
          <EditImage
            onDelete={onSelectedImageDeleted}
            onChange={onSelectedPictureUpdated}
            fullContent={fullContent}
            forceCropAspect={FORCE_CROP_ASPECT}
            forceCrop={!selected.completed ? FORCE_CROP_CONFIG : undefined}
            fileName={selected.file.name}
          />
        </Wrapper>
      </ScrollContainer>
    </BasicModalWithHeaderAndTwoButtons>
  );
};

export default PreUploadImageEditor;
