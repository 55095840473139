import { RootReducerInterface } from '@/model/redux/interfaces.ts';
import { StatusChartStoreProps } from '@dev/base-web/dist/view/components/charts/status_charts/gantt_chart';
import { connect } from 'react-redux';
import StateOverviewChartContainer from '@dev/base-web/dist/view/components/charts/status_charts/aggregated_status_chart_container';

const mapStateToProps = (
  state: RootReducerInterface
): StatusChartStoreProps => ({
  durationDeviations: state.data.durations.durationDeviations.data,
  durationDeviationsLoading:
    state.data.durations.durationDeviations.meta.loadingInProgress,
});

export default connect(mapStateToProps)(StateOverviewChartContainer);
