import React, { useReducer, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { pages, StepWithTitle } from './pages';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { useNavigate } from 'react-router';
import JoyRide, { ACTIONS, EVENTS, STATUS, Step } from 'react-joyride';

export const TOUR_CURRENT_EVENTS = 'tour-currentEvents';
export const TOUR_INSTRUCTION = 'tour-solution';
export const TOUR_DASHBOARD = 'tour-dashboard';

const titleFormatter = (title: string) => {
  return (
    <div
      className="joyride-step-text"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <Icon name="info" color="#ffffff" size={16} />
      <span
        style={{
          marginLeft: 8,
          fontSize: '18px',
          textAlign: 'left',
          fontFamily: 'Relative',
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.33,
          letterSpacing: 'normal',
          paddingTop: '2px',
        }}
        className="active"
      >
        {<FormattedMessage id={title} />}
      </span>
    </div>
  );
};

const FormatTitle = (steps: StepWithTitle[]) => {
  steps.forEach((_step, index) => {
    const title = steps[index].titleText ? steps[index].titleText! : '';
    steps[index] = { ...steps[index], title: titleFormatter(title) };
  });

  return steps as Step[];
};

const defaultOptions = {
  arrowColor: '#262626',
  backgroundColor: '#262626',
  beaconSize: 36,
  texAlign: 'left',
  overlayColor: 'rgba(0, 0, 0, 0.5)',
  primaryColor: '#0da6a0',
  spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
  textColor: '#ffffff',
  width: '450px',
  zIndex: 100,
};

const tooltipContainer = {
  textAlign: 'left',
  fontSize: '14px',
  fontFamily: 'Relative',
  fontWeight: 500,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.43,
  letterSpacing: 'normal',
  padding: '8px',
};

const tooltipContent = {
  padding: '24px 0px',
};

const tooltip = {
  borderRadius: '12px',
};

const buttonClose = {
  fontSize: '14px',
  fontWeight: 'bold',
};

const buttonNext = {
  backgroundColor: '#0da6a0',
  fontSize: '14px',
  padding: '12px',
  //height: '18px',
  fontFamily: 'Relative',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  outline: 'none',
};

const buttonBack = {
  color: '#0da6a0',
  fontSize: '14px',
  //height: '18px',
  fontFamily: 'Relative',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  outline: 'none',
};

interface StateInterface {
  key: Date;
  run: boolean;
  continuous: boolean;
  loading: boolean;
  stepIndex: number;
  steps: Step[];
}

interface Props {
  page: string;
  active?: boolean;
  setTourActive?: (active: boolean) => void;
  activeSolution?: any;
  solutionSteps?: any[];
}

export const checkLocalStorage = (page: string) => {
  return Number(localStorage.getItem(page));
};

// Tour component
const Tour = ({
  page,
  active,
  setTourActive,
  activeSolution,
  solutionSteps,
}: Props) => {
  const tourPage = `tour-${page}`;

  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    if (active) startTour();
  }, [active]);

  const getInitialStepIndex = () => {
    if (
      localStorage.getItem(tourPage) &&
      Number(localStorage.getItem(tourPage))
    ) {
      return Number(localStorage.getItem(tourPage));
    } else return 0;
  };

  const INITIAL_STATE: StateInterface = {
    key: new Date(), // This field makes the tour to re-render when we restart the tour
    run: false,
    continuous: true,
    loading: false,
    stepIndex: getInitialStepIndex(),
    steps: FormatTitle(pages[page]),
  };

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case 'START':
        return { ...state, run: true };
      case 'RESET':
        return { ...state, stepIndex: 0 };
      case 'STOP':
        return { ...state, run: false };
      case 'NEXT_OR_PREV':
        return { ...state, ...action.payload };
      case 'RESTART':
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };
      default:
        return state;
    }
  };

  // Tour state is the state which control the JoyRide component
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    // Auto start the tour if the tour is not viewed before
    if (
      !localStorage.getItem(tourPage) ||
      !isNaN(checkLocalStorage(tourPage))
    ) {
      setTourActive && setTourActive(true);
      if (tourPage == TOUR_DASHBOARD && localStorage.getItem(tourPage) == '4') {
        setTimeout(() => {
          dispatch({
            type: 'START',
          });
        }, 600);
      } else if (
        tourPage == TOUR_CURRENT_EVENTS &&
        localStorage.getItem(tourPage) &&
        Number(localStorage.getItem(tourPage)) > 1
      ) {
        setTimeout(() => {
          dispatch({
            type: 'RESTART',
          });
        }, 600);
      } else dispatch({ type: 'START' });
    }
  }, []);

  ////// FOR CURRENT EVENTS
  useEffect(() => {
    if (
      tourPage == TOUR_CURRENT_EVENTS &&
      activeSolution &&
      tourState.stepIndex == 2
    ) {
      setTimeout(() => {
        dispatch({
          type: 'NEXT_OR_PREV',
          payload: { stepIndex: 3 },
        });
      }, 800);
    }
  }, [activeSolution]);

  useEffect(() => {
    // Auto start the tour if the tour is not viewed before
    if (solutionSteps && solutionSteps.length > 0 && tourState.stepIndex == 1) {
      dispatch({
        type: 'NEXT_OR_PREV',
        payload: { stepIndex: 2 },
      });
    } else if (
      solutionSteps &&
      solutionSteps.length > 0 &&
      tourState.stepIndex == 3
    ) {
      dispatch({
        type: 'NEXT_OR_PREV',
        payload: { stepIndex: 4 },
      });
    }
  }, [solutionSteps]);

  // Set once tour is viewed, skipped or closed
  const setTourViewed = () => {
    localStorage.setItem(tourPage, 'v');
  };

  const callback = (data: any) => {
    const { action, index, type, status } = data;

    if (action === ACTIONS.CLOSE) {
      // If close button clicked, then close all tours

      localStorage.setItem(TOUR_CURRENT_EVENTS, 'v');
      localStorage.setItem(TOUR_DASHBOARD, 'v');
      localStorage.setItem(TOUR_INSTRUCTION, 'v');
      dispatch({ type: 'STOP' });
      setTourActive && setTourActive(false);
    } else if (
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: 'STOP' });
      setTourActive && setTourActive(false);
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.

      if (tourPage == TOUR_INSTRUCTION && index == 5) {
        setTourViewed();
        localStorage.setItem(TOUR_DASHBOARD, '4');
        navigate('/');
        return;
      }

      localStorage.setItem(
        tourPage,
        index + (action === ACTIONS.PREV ? -1 : 1)
      );
      dispatch({
        type: 'NEXT_OR_PREV',
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  const startTour = () => {
    // Start the tour manually

    localStorage.setItem(TOUR_CURRENT_EVENTS, '0');
    localStorage.setItem(TOUR_INSTRUCTION, '0');
    dispatch({ type: 'RESTART' });
    setTourActive && setTourActive(true);
  };

  return (
    <>
      <JoyRide
        {...tourState}
        //tooltipComponent
        disableOverlayClose
        callback={callback}
        //showSkipButton={true}
        styles={{
          options: defaultOptions,
          tooltipContainer: tooltipContainer,
          buttonNext: buttonNext,
          buttonBack: buttonBack,
          tooltipContent: tooltipContent,
          buttonClose: buttonClose,
          tooltip: tooltip,
        }}
        locale={{
          last: intl.formatMessage({ id: 'end_tour' }),
          close: intl.formatMessage({ id: 'close' }),
          skip: intl.formatMessage({ id: 'skip' }),
          open: intl.formatMessage({ id: 'open' }),
          next: intl.formatMessage({ id: 'next' }),
          back: intl.formatMessage({ id: 'back' }),
        }}
      />
    </>
  );
};

export default Tour;
