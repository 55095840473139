import React from 'react';
import styled from 'styled-components';
import { AddMark, CompleteMark } from '../../../components/StepMarks';

export const StyledButton = styled.div<{ active: boolean }>`
  display: flex;
  height: 40px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  padding: 8px 24px 8px 24px;
  margin: 4px;
  color: #000000;
  background-color: ${({ active }) =>
    active ? '#e9f5f5' : 'rgba(255, 255, 255, 0.7)'};
  flex-wrap: nowrap;
  white-space: nowrap;
  border: solid 1.5px rgba(0, 0, 0, 0.02);
  ${({ active }) =>
    active ? 'solid 1px #d2edec' : 'solid 1.5px rgba(0, 0, 0, 0.02)'};
`;

interface ManufacturingEntityPillProps {
  readonly name: string;
  readonly active: boolean;
  readonly addNew?: boolean;
  readonly onClick: (name: string) => void;
  readonly onClickMark: (name: string) => void;
  readonly complete: boolean;
}

export const ManufacturingEntityPill: React.FC<
  ManufacturingEntityPillProps
> = ({ name, active, addNew, onClick, complete, onClickMark }) => {
  const color = '#f7f7f7';
  const icon = addNew ? 'plus' : 'edit';

  return (
    <>
      <StyledButton active={active} color={color} onClick={() => onClick(name)}>
        {complete ? (
          <CompleteMark onClick={() => onClickMark(name)} />
        ) : (
          <AddMark icon={icon} onClick={() => onClickMark(name)} />
        )}
        {name}
      </StyledButton>
    </>
  );
};

export const EventTypeButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -4px;
`;
