import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

const size32Style = css`
  height: 32px;
  padding-left: 8px;
`;

interface InputfieldProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  size32?: boolean;
}

const Input = styled.textarea<InputfieldProps>`
  height: 94px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding-left: 12px;
  outline: none;
  resize: none;
  padding-top: 10px;
  padding-bottom: 10px;

  ::placeholder {
    font-family: Relative;
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    //line-height: 1.43;
    letter-spacing: normal;
    padding-left: 2px;
  }

  &:hover {
    border: solid 1px rgba(0, 0, 0, 0.4);
  }
  &:active {
    border: solid 1px ${({ theme }) => theme.colors.primary};
  }
  &:focus {
    border: solid 1px ${({ theme }) => theme.colors.primary};
  }
  &:invalid {
    border: solid 1px ${({ theme }) => theme.colors.inputs.error.border};
    background-color: ${({ theme }) => theme.colors.inputs.error.background};

    ::placeholder {
      color: #9f3a38;
    }
  }
  &:disabled {
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #f0f0f0;
  }
  ${(props) => props.size32 && size32Style};
`;

export const TextArea = (props: PropsWithChildren<InputfieldProps>) => {
  return <Input {...props}>{props.children}</Input>;
};
