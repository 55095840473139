import { useEffect, useState } from 'react';
import {
  ActionDefinitionDTO,
  ActionDefinitionHistoryDTO,
} from '@/model/domain/instruction/instruction.ts';
import {
  LoadingListInterface,
  OperationMetaState,
} from '@dev/base-web/dist/model/redux/helpers/interfaces';

const useStateHandling = (
  instruction: ActionDefinitionDTO | null,
  instructionVersions: LoadingListInterface<ActionDefinitionHistoryDTO>,
  instructionUpdateMeta: OperationMetaState
) => {
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    if (
      !instructionUpdateMeta.operationInProgress &&
      !instructionVersions.meta.loadingInProgress
    ) {
      const localIsApproved = !!(
        instruction &&
        instructionVersions.data.find(
          (v) => v.revisionNumber === instruction.revisionNumber
        )?.isApproved
      );

      if (localIsApproved !== isApproved) setIsApproved(localIsApproved);
    }
  }, [instruction, instructionVersions]);

  return {
    isApproved,
  };
};

export default useStateHandling;
