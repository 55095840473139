import { createReducer } from 'reduxsauce';
import { combineReducers, Reducer } from 'redux';
import {
  ShiftReportProductInterface,
  ShiftReportProductMetaInterface,
  ShiftReportProductRootStateInterface,
} from './interface';
import { ActionTypes } from './actions';
import ProductBatch from '../../../domain/report/ProductBatch';
import { PagingRequestResult } from '@dev/base-web/dist/model/api/common/paging_request_result';

const INITIAL_STATE: ShiftReportProductInterface = {
  productSummary: undefined,
  productBatches: { hasMoreResults: false, results: [] },
  products: [],
};

const META_INITIAL_STATE: ShiftReportProductMetaInterface = {
  loadingInProgress: false,
  error: undefined,
  productBatchMeta: {
    loadingInProgress: false,
    operationInProgress: false,
    error: undefined,
    updateSucceeded: false,
    createSucceeded: false,
    deleteSucceeded: false,
  },
};

const ACTION_HANDLERS = {
  LOADING_PRODUCT_SUMMARY_SUCCESSFUL: (
    state: ShiftReportProductInterface,
    action: ActionTypes
  ): ShiftReportProductInterface => {
    return { ...state, productSummary: action.payload };
  },
  LOADING_PRODUCT_BATCHES_SUCCESSFUL: (
    state: ShiftReportProductInterface,
    action: ActionTypes
  ): ShiftReportProductInterface => {
    const { results } = action.payload.result;

    const productBatches: PagingRequestResult<ProductBatch> = {
      hasMoreResults: action.payload.result.hasMoreResults,
      results: state.productBatches.results.concat(results),
    };
    return { ...state, productBatches, products: action.payload.products };
  },
  RESET_PRODUCT_BATCHES: (
    state: ShiftReportProductInterface,
    action: ActionTypes
  ): ShiftReportProductInterface => {
    return {
      ...state,
      productBatches: action.payload.result,
      products: action.payload.products,
    };
  },
};

const META_ACTION_HANDLERS = {
  START_LOADING_PRODUCT_SUMMARY: (
    state: ShiftReportProductMetaInterface
  ): ShiftReportProductMetaInterface => {
    return { ...state, loadingInProgress: true };
  },
  END_LOADING_PRODUCT_SUMMARY: (
    state: ShiftReportProductMetaInterface
  ): ShiftReportProductMetaInterface => {
    return { ...state, loadingInProgress: false };
  },
  LOADING_PRODUCT_SUMMARY_FAILED: (
    state: ShiftReportProductMetaInterface,
    action: any
  ): ShiftReportProductMetaInterface => {
    return {
      ...state,
      productBatchMeta: {
        ...state.productBatchMeta,
        error: action.payload.error,
      },
    };
  },
  START_LOADING_PRODUCT_BATCHES: (
    state: ShiftReportProductMetaInterface
  ): ShiftReportProductMetaInterface => {
    return {
      ...state,
      productBatchMeta: {
        ...state.productBatchMeta,
        error: undefined,
        loadingInProgress: true,
      },
    };
  },
  END_LOADING_PRODUCT_BATCHES: (
    state: ShiftReportProductMetaInterface
  ): ShiftReportProductMetaInterface => {
    return {
      ...state,
      productBatchMeta: { ...state.productBatchMeta, loadingInProgress: false },
    };
  },
  LOADING_PRODUCT_BATCHES_FAILED: (
    state: ShiftReportProductMetaInterface,
    action: any
  ): ShiftReportProductMetaInterface => {
    return {
      ...state,
      productBatchMeta: {
        ...state.productBatchMeta,
        error: action.payload.error,
      },
    };
  },
  START_PRODUCT_BATCH_OPERATION: (
    state: ShiftReportProductMetaInterface
  ): ShiftReportProductMetaInterface => {
    return {
      ...state,
      productBatchMeta: {
        ...state.productBatchMeta,
        operationError: undefined,
        updateSucceeded: false,
        createSucceeded: false,
        deleteSucceeded: false,
        operationInProgress: true,
      },
    };
  },
  END_PRODUCT_BATCH_OPERATION: (
    state: ShiftReportProductMetaInterface
  ): ShiftReportProductMetaInterface => {
    return {
      ...state,
      productBatchMeta: {
        ...state.productBatchMeta,
        operationInProgress: false,
      },
    };
  },
  UPDATING_PRODUCT_BATCH_SUCCEEDED: (
    state: ShiftReportProductMetaInterface
  ): ShiftReportProductMetaInterface => {
    return {
      ...state,
      productBatchMeta: { ...state.productBatchMeta, updateSucceeded: true },
    };
  },
  DELETING_PRODUCT_BATCH_SUCCEEDED: (
    state: ShiftReportProductMetaInterface
  ): ShiftReportProductMetaInterface => {
    return {
      ...state,
      productBatchMeta: { ...state.productBatchMeta, deleteSucceeded: true },
    };
  },
  CREATING_PRODUCT_BATCH_SUCCEEDED: (
    state: ShiftReportProductMetaInterface
  ): ShiftReportProductMetaInterface => {
    return {
      ...state,
      productBatchMeta: { ...state.productBatchMeta, createSucceeded: true },
    };
  },
  PRODUCT_BATCH_OPERATION_FAILED: (
    state: ShiftReportProductMetaInterface,
    action: any
  ): ShiftReportProductMetaInterface => {
    return {
      ...state,
      productBatchMeta: {
        ...state.productBatchMeta,
        operationError: action.payload.error,
      },
    };
  },
};

const shiftReportProductData = createReducer(INITIAL_STATE, ACTION_HANDLERS);
const shiftReportProductDataMeta = createReducer(
  META_INITIAL_STATE,
  META_ACTION_HANDLERS
);

const shiftReportProductState: Reducer<ShiftReportProductRootStateInterface> =
  combineReducers({
    shiftReportProductData,
    shiftReportProductDataMeta,
  });

export default shiftReportProductState;
