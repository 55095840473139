import _ from 'lodash';

export const isValueNumber = (value: any) => !Number.isNaN(Number(value));
export const isValueInteger = (value: any) =>
  isValueNumber(value) && _.isInteger(parseFloat(value));
export const isEqualsIgnoreCase = (a: any, b: any) => {
  return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
    : a === b;
};
