import { EventRule } from '../../../../model/domain/event/event_definition';
import React from 'react';
import { FeatureColors } from '@dev/base-web/dist/view/components/feature/styles';
import styled from 'styled-components';
import { StyledInput } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { FormattedMessage } from 'react-intl';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';

const StyledFeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  justify-content: center;
  min-width: 330px;
  width: 100%;
  display: flex;
  border-radius: 8px;
  border: solid 1px #dfdfdf;
  font-family: Relative;
  padding: 2px 16px 2px 16px;
  transition: opacity 0.2s;
  cursor: pointer;
`;

const StyledFeatureCardContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
`;

const FunctionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-top: 6px;
  //margin-bottom: 6px;
`;

const StyledFunction = styled.div`
  background-color: rgba(233, 245, 245, 0.5);
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: 1px solid rgba(36, 36, 36, 0.05);
  line-height: 20px;
  text-align: center;
  font-family: Relative;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #242424;
  font-size: 14px;
  padding: 6px 16px;
  cursor: pointer;
  transition: opacity 0.2s;
  transform: translate(
    0,
    0
  ); //this is important to not have white borders around the corners when dragging;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 0;
  width: -webkit-fill-available;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  align-items: center;
  justify-content: right;
`;

const Rectangle = styled(StyledInput)`
  &&&& {
    //min-width: 160px;
    width: 85%;
    transition: width 0.3s;
    height: 32px;
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: Relative;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    color: #242424;
    cursor: pointer !important;

    &&::placeholder {
      font-style: italic;
      line-height: 1.43 !important;
    }
  }
`;

const InputLabel = styled.div`
  font-family: Relative;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #8c8c8c;
  padding-right: 8px;
`;

interface DynamicConditionCardProps {
  readonly rule: EventRule;
  readonly odd?: boolean;
  readonly replaceColors?: FeatureColors;
}

const DynamicConditionCard: React.FC<DynamicConditionCardProps> = ({
  rule,
}) => {
  return (
    <>
      <StyledFeatureCard>
        <StyledFeatureCardContent>
          <FunctionWrapper>
            <StyledFunction>
              <FormattedMessage id={'dynamic'} />
            </StyledFunction>
          </FunctionWrapper>
          <InputsWrapper>
            <InputRow>
              <InputLabel>
                <FormattedMessage id="text" />
              </InputLabel>
              <Rectangle
                size="mini"
                value={rule.dynamicProperties.text}
                placeholder="-"
                readOnly={true}
              ></Rectangle>
            </InputRow>
            <InputRow>
              <InputLabel>
                <FormattedMessage id="location" />
              </InputLabel>
              <Rectangle
                value={rule.dynamicProperties?.entityName}
                readOnly={true}
              ></Rectangle>
            </InputRow>
          </InputsWrapper>
        </StyledFeatureCardContent>
        <div style={{ paddingTop: '16px' }}>
          <Hint iconName="hand" messageKey="dynamic_rule_text"></Hint>
        </div>
      </StyledFeatureCard>
    </>
  );
};

export default DynamicConditionCard;
