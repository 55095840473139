import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { LoadingEventListInterface } from '@dev/base-web/dist/model/redux/events/interface';
import { EventInterface } from '@dev/base-web/dist/model/domain/event/event_type';
import {
  FilterTag,
  FilterType,
  PopupFilterProps,
} from '@dev/base-web/dist/view/components/filter_tags_and_sorting_dropdown/components/interfaces';
import { EventStatus } from '@/screens/current_events/view';

export const EVENT_NAME_FILTER_KEY = 'name';
export const EVENT_TYPE_FILTER_KEY = 'type';
export const MANUFACTURING_ENTITY_FILTER_KEY = 'manufacturingEntity.id';
export const INCLUDE_SUBSEQUENT_EVENTS_FILTER_KEY = 'includeSubsequentEvents';
export const TIMESPAN_FILTER_KEY = `startTimestamp`;

export const eventListFilterProps = (
  manufacturingEntities: readonly ManufacturingEntity[],
  manufacturingEntitiesLoading: boolean,
  tab: EventStatus
): PopupFilterProps[] => {
  return [
    {
      filterLabelKey: 'eval_time_span',
      filterRangeStartKey: 'startTimestamp',
      filterRangeEndKey:
        tab === EventStatus.CLOSED ? 'endTimestamp' : 'startTimestamp',
      isInclusive: false,
      filterType: FilterType.DATE_RANGE,
    },
    {
      filterLabelKey: 'event_name',
      filterType: FilterType.TEXT,
      filterKey: 'name',
      iconName: 'info',
    },
    {
      filterLabelKey: 'event_category',
      filterType: FilterType.EVENT_TYPE,
      filterKey: 'type',
      multiselect: true,
    },
    {
      filterLabelKey: 'manufacturing_entity',
      filterType: FilterType.MANUFACTURING_ENTITY,
      filterKey: 'manufacturingEntity.id',
      manufacturingEntities: manufacturingEntities,
      loadingInProgress: manufacturingEntitiesLoading,
    },
    {
      filterLabelKey: 'include_subsequent_events',
      filterType: FilterType.BOOLEAN,
      filterKey: 'includeSubsequentEvents',
      iconName: 'info',
    },
  ];
};

export enum ComponentType {
  dashboard = `dashboard`,
  events_list = `events_list`,
}

export interface CurrentEventsCardExternalProps {
  readonly tab?: EventStatus;
  readonly selectedEvent?: EventInterface;
  readonly onEventSelect?: (id: string) => void;
  readonly tourActive?: boolean;
  readonly componentType: ComponentType;
  readonly externalFilterTag?: FilterTag;
}

export interface CurrentEventsCardStoreProps {
  readonly activeEvents: LoadingEventListInterface;
  readonly closedEvents: LoadingEventListInterface;
  readonly manufacturingEntities: readonly ManufacturingEntity[];
  readonly manufacturingEntitiesLoading: boolean;
}

export interface CurrentEventsCardDispatchProps {
  readonly getActiveEvents: (filters: readonly FilterData[]) => Promise<void>;
  readonly reloadActiveEvents: (
    filters: readonly FilterData[]
  ) => Promise<void>;
  readonly getClosedEventsFirstPage: (
    filters: readonly FilterData[]
  ) => Promise<void>;
  readonly getMoreClosedEvents: (
    filters: readonly FilterData[]
  ) => Promise<void>;
  readonly reloadClosedEvents: (
    filters: readonly FilterData[]
  ) => Promise<void>;
  readonly getAllManufacturingEntities: () => void;
}

export type CurrentEventsCardProps = CurrentEventsCardExternalProps &
  CurrentEventsCardStoreProps &
  CurrentEventsCardDispatchProps;
