import React, { ReactNode, useMemo } from 'react';
import { defaultLabelMap } from '@dev/base-web/dist/view/components/modal/import_modal';
import { ErrorInterface } from '@dev/base-web/dist/model/api/error/error_interface';
import {
  INVALIDITY_CAUSE_FILTER_KEY,
  ImportColMap,
  ImportModalLabelMappings,
  ImportStatus,
} from '@dev/base-web/dist/view/components/modal/import_modal/interface';
import { useIntl } from 'react-intl';
import {
  Column,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import { localisation } from '../../../model/redux/reducers';
import { AsyncJobStatusDTO } from '@dev/base-web/dist/model/domain/jobs/AsyncJobStatusDTO';
import { EventDefinitionValidationResponse } from '../../../model/domain/event/event_definition_import';
import { AsyncJobResponseDTO } from '@dev/base-web/dist/model/domain/jobs/AsyncJobResponseDTO';
import ImportModalWithTrigger from '@dev/base-web/dist/view/components/modal/import_modal/import_modal_with_trigger';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';

interface EventDefinitionImportModalProps {
  readonly triggerButton: ReactNode;
  readonly triggerDisabled?: boolean;
  readonly importId: string | null;
  readonly importInProgress: boolean;
  readonly importError?: ErrorInterface;
  readonly importStatusData: AsyncJobStatusDTO<EventDefinitionValidationResponse> | null;
  readonly importStatusInProgress: boolean;
  readonly importStatusError?: ErrorInterface;
  readonly confirmImportData: AsyncJobResponseDTO | null;
  readonly confirmImportInProgress: boolean;
  readonly confirmImportError?: ErrorInterface;
  readonly startImport: (
    file: File,
    colMap: ImportColMap,
    maxAllowedInvalidEntries?: number
  ) => void;
  readonly checkImportStatus: (importId: string) => void;
  readonly confirmImport: (
    importId: string,
    save?: boolean,
    overwriteExistings?: boolean
  ) => void;
  readonly resetImport: () => void;
  readonly resetImportStatus: () => void;
}

const labelMap: ImportModalLabelMappings = {
  ...defaultLabelMap,
  explanation: 'event_definition_import_modal_explanation',
  foundElements: 'event_definition_import_modal_found_elements',
  headerLabel: 'event_definition_import_modal_header',
};

const locales = Object.keys(localisation);
const nameColumns = locales.map((l) => `name_${l}`);
const descColumns = locales.map((l) => `description_${l}`);
const mandatoryColumns = [
  'manufacturing_entity_name',
  'type',
  ['trigger_start', 'dynamic_text'],
  nameColumns,
  descColumns,
];

const optionalColumns = [
  'data_source',
  'subscription',
  'manufacturing_entity_parent_name',
  'trigger_end',
  'duration_close',
  'dynamic_id',
];

const entitiesColumns: Column[] = [
  {
    title: 'manufacturing_entity_parent_name',
    isLeftAligned: true,
    width: 4,
    sortKey: 'rawEntity.manufacturingEntityParentName',
    filterKey: 'rawEntity.manufacturingEntityParentName',
    filterType: ColumnFilterType.TEXT,
    dtoAttributeKey: 'manufacturingEntityParentName',
    attributeKeyToCompare: 'rules[0].manufacturingEntity.parents[0].name',
  },
  {
    title: 'manufacturing_entity_name',
    isLeftAligned: true,
    width: 3,
    sortKey: 'rawEntity.manufacturingEntityName',
    filterKey: 'rawEntity.manufacturingEntityName',
    filterType: ColumnFilterType.TEXT,
    dtoAttributeKey: 'manufacturingEntityName',
    attributeKeyToCompare: 'rules[0].manufacturingEntity.name',
  },
  {
    title: 'event_type',
    width: 3,
    isLeftAligned: true,
    sortKey: 'rawEntity.eventType',
    filterKey: 'rawEntity.eventType',
    filterType: ColumnFilterType.SELECTOR,
    filterOptions: Object.values(EventType).map((type) => ({
      textId: type.toLowerCase(),
      value: type,
    })),
    dtoAttributeKey: 'eventType',
    attributeKeyToCompare: 'type',
  },
  {
    title: 'data_source',
    width: 3,
    isLeftAligned: true,
    sortKey: 'rawEntity.plc',
    filterKey: 'rawEntity.plc',
    filterType: ColumnFilterType.TEXT,
    dtoAttributeKey: 'plc',
  },
  {
    title: 'subscription',
    width: 3,
    isLeftAligned: true,
    sortKey: 'rawEntity.subscription',
    filterKey: 'rawEntity.subscription',
    filterType: ColumnFilterType.TEXT,
    dtoAttributeKey: 'subscription',
  },
  {
    title: 'trigger_start',
    width: 3,
    isLeftAligned: true,
    sortKey: 'rawEntity.triggerStart',
    filterKey: 'rawEntity.triggerStart',
    filterType: ColumnFilterType.TEXT,
    dtoAttributeKey: 'triggerStart',
    attributeKeyToCompare: 'rules[0].open.featureCondition.valueAlias',
  },
  {
    title: 'trigger_end',
    width: 3,
    isLeftAligned: true,
    sortKey: 'rawEntity.triggerEnd',
    filterKey: 'rawEntity.triggerEnd',
    filterType: ColumnFilterType.TEXT,
    dtoAttributeKey: 'triggerEnd',
    attributeKeyToCompare: 'rules[0].close.featureCondition.valueAlias',
  },
  {
    title: 'invalidity_cause',
    width: 3,
    filterKey: INVALIDITY_CAUSE_FILTER_KEY,
    filterType: ColumnFilterType.TEXT,
    isLeftAligned: true,
  },
];

const EventDefinitionImportModal: React.FC<EventDefinitionImportModalProps> = ({
  triggerButton,
  triggerDisabled,
  importId: importSignalId,
  importInProgress: importSignalInProgress,
  importError: importSignalError,
  importStatusData,
  importStatusInProgress,
  importStatusError,
  confirmImportInProgress: confirmSignalImportInProgress,
  confirmImportError: confirmSignalImportError,
  startImport,
  checkImportStatus,
  confirmImport,
  resetImport,
  resetImportStatus,
}) => {
  const intl = useIntl();

  const importStatus = useMemo<ImportStatus | null>(() => {
    if (importStatusData) {
      const {
        finished,
        processedElements,
        totalElements,
        validationResponse,
        processStatus,
        errorMessage,
      } = importStatusData;

      const invalidEntities = validationResponse?.invalidEntities || [];
      const importId = validationResponse?.importId;

      return {
        finished,
        importId,
        processedElements,
        totalElements,
        invalidCount: validationResponse?.invalidCount,
        validAndExistingCount: validationResponse?.validAndExistingCount,
        validCount: validationResponse?.validCount,
        deletedCount: validationResponse?.deletedCount,
        importedCount: validationResponse?.importedCount,
        invalidEntries: invalidEntities.map(({ message, rawEntity }) => ({
          message,
          columns: [
            { rawValue: rawEntity.manufacturingEntityParentName || '' },
            { rawValue: rawEntity.manufacturingEntityName || '' },
            { rawValue: rawEntity.eventType || '' },
            { rawValue: rawEntity.plc || '' },
            { rawValue: rawEntity.subscription || '' },
            { rawValue: rawEntity.triggerStart || '' },
            { rawValue: rawEntity.triggerEnd || '' },
          ],
        })),
        processStatus,
        errorMessage,
      };
    } else {
      return null;
    }
  }, [importStatusData]);

  const filePickerHint = intl.formatMessage({ id: 'choose_file_hint' });

  return (
    <ImportModalWithTrigger
      checkImportStatus={checkImportStatus}
      confirmImport={confirmImport}
      confirmImportInProgress={confirmSignalImportInProgress}
      confirmImportError={confirmSignalImportError}
      importId={importSignalId}
      importInProgress={importSignalInProgress}
      importError={importSignalError}
      importStatusInProgress={importStatusInProgress}
      importStatusData={importStatus}
      importStatusError={importStatusError}
      labelMap={labelMap}
      mandatoryColumns={mandatoryColumns}
      optionalColumns={optionalColumns}
      resetImport={resetImport}
      resetImportStatus={resetImportStatus}
      startImport={startImport}
      triggerButton={triggerButton}
      localizedHint={filePickerHint}
      entryTableColumns={entitiesColumns}
      triggerDisabled={triggerDisabled}
    />
  );
};

export default EventDefinitionImportModal;
