import React, { ChangeEvent, Component } from 'react';
import { Span } from './event_description';
import { CenteredTabs, DurationLabel } from './styled_components';
import { FormattedMessage } from 'react-intl';
import { TimerCondition } from '../../../model/domain/event/event_definition';
import NumberInput from '@dev/base-web/dist/view/components/inputs/number_input';

interface TimerPropsInterface {
  readonly rule: TimerCondition;
  readonly onDurationUpdate: (duration: number | undefined) => void;
}

class Timer extends Component<TimerPropsInterface> {
  constructor(props: TimerPropsInterface) {
    super(props);
  }

  isDurationValid = (value: number) =>
    value && value > 0 && !Number.isNaN(Number(value));

  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numberValue = parseFloat(e.target.value);
    const numValueCorrectNumber = !isNaN(numberValue);
    if (numValueCorrectNumber) {
      this.props.onDurationUpdate(numberValue);
    }
    if (e.target.value === '') {
      this.props.onDurationUpdate(undefined);
    }
  };

  render() {
    return (
      <>
        <Span>
          <FormattedMessage id="event_close_duration_hint" />
        </Span>
        <CenteredTabs style={{ marginTop: 0 }}>
          <DurationLabel>
            <FormattedMessage id="duration" />
          </DurationLabel>
          <NumberInput
            key={'name'}
            value={this.props.rule.duration}
            onChange={this.onChange}
            error={!this.isDurationValid(this.props.rule.duration)}
            //style={{ maxWidth: '320px' }}
            disabled={false}
          />
        </CenteredTabs>
      </>
    );
  }
}

export default Timer;
