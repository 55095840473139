import { createReducersForLoadingPagingList } from '@dev/base-web/dist/model/redux/helpers/reducers';
import { combineReducers, Reducer } from 'redux';
import AggregatedDowntime from '../../domain/downtime/AggregatedDowntime';
import { AGGREGATED_DOWNTIMES_KEY } from './actions';
import { DowntimeStateInterface } from './interface';

const downtimeState: Reducer<DowntimeStateInterface> = combineReducers({
  aggregatedDowntimes: createReducersForLoadingPagingList<AggregatedDowntime>(
    AGGREGATED_DOWNTIMES_KEY
  ),
});

export default downtimeState;
