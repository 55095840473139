import styled from 'styled-components';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChartDatum } from './downtimes_in_report_chart';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import { millisToDurationFormat } from '@dev/base-web/dist/model/domain/durations/duration';
import StyledChartTooltip, {
  StyledChartTooltipProps,
} from '@dev/base-web/dist/view/components/charts/styled_chart_tooltip';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import DowntimeOverview from '@dev/base-web/dist/model/domain/report/DowntimeOverview';
import { ManufacturingEntityType } from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';

const EventInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
  height: 16px;
`;

const EventInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  //max-width: max-content;
`;

const EventInfoMainColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  flex-shrink: 0;
  padding-right: 10px;
`;

const EventInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 4px;

  ${EventInfoColumn}:nth-of-type(1) {
    margin-right: 8px;
  }
  width: 300px;
`;

const EventButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  &&& > *:first-child {
    margin-right: 10px !important;
  }
`;

interface GanttChartLabelProps {
  readonly datum?: ChartDatum;
  readonly onDowntimeSelected: (downtime?: DowntimeOverview) => void;
  readonly domainWidthForPixel?: number;
}

type Props = StyledChartTooltipProps & GanttChartLabelProps;

const GanttChartLabel: React.FC<Props> = ({
  barWidth,
  leftPadding,
  x,
  y,
  datum,
  onDowntimeSelected,
  domainWidthForPixel,
}) => {
  if (!datum || !datum.downtimeOverview) return <></>;

  const dataWidth = domainWidthForPixel
    ? Math.abs(datum.y0.valueOf() - datum.y.valueOf()) / domainWidthForPixel
    : undefined;

  const intl = useIntl();
  const { formatDate } = useLocalizedDateFormatters();

  return (
    <StyledChartTooltip
      leftPadding={leftPadding}
      barWidth={barWidth}
      dataWidth={dataWidth}
      x={x}
      y={y}
    >
      <EventInfoContainer>
        <EventInfoMainColumn>
          {datum.downtimeOverview.causingEvent?.manufacturingEntity && (
            <EventInfo>
              <FormattedMessage id={'location'} />:
            </EventInfo>
          )}
          {datum.downtimeOverview.causingEvent?.name && (
            <EventInfo>
              <FormattedMessage id={'aggregated_child_rule_column_event'} />:
            </EventInfo>
          )}
          {!datum.downtimeOverview.causingEvent && (
            <EventInfo>
              <FormattedMessage id={'cause'} />:
            </EventInfo>
          )}
          <EventInfo>
            <FormattedMessage id={'start'} />:
          </EventInfo>
          <EventInfo>
            <FormattedMessage id={'end'} />:
          </EventInfo>
          <EventInfo>
            <FormattedMessage id={'duration'} />:
          </EventInfo>
        </EventInfoMainColumn>
        <EventInfoColumn>
          {datum.downtimeOverview.causingEvent?.manufacturingEntity && (
            <EventInfo>
              <ManufacturingEntityHierarchyElement
                manufacturingEntity={
                  datum.downtimeOverview.causingEvent?.manufacturingEntity
                }
                hideHiddenTypes
                hideTooltip
                showShortNames
                hiddenTypes={[
                  ManufacturingEntityType.FACTORY,
                  ManufacturingEntityType.AREA,
                  ManufacturingEntityType.LINE,
                ]}
                lines={1}
              />
            </EventInfo>
          )}
          {datum.downtimeOverview.causingEvent?.name && (
            <EventInfo>
              <TextWithEllipsisAndTooltip
                text={datum.downtimeOverview.causingEvent.name}
                lines={1}
              />
            </EventInfo>
          )}
          {datum.downtimeOverview.cause && (
            <EventInfo>
              <TextWithEllipsisAndTooltip
                text={intl.formatMessage({
                  id: datum.downtimeOverview.cause.toLowerCase(),
                  defaultMessage: datum.downtimeOverview.cause,
                })}
                lines={1}
              />
            </EventInfo>
          )}
          {!datum.downtimeOverview.cause &&
            !datum.downtimeOverview.causingEvent && (
              <EventInfo>
                <FormattedMessage id={'not_documented_stop'} />
              </EventInfo>
            )}
          <EventInfo>
            {formatDate(datum.downtimeOverview.startTimestamp, 'Ppp')}
          </EventInfo>
          <EventInfo>
            {formatDate(datum.downtimeOverview.endTimestamp, 'Ppp')}
          </EventInfo>
          <EventInfo>
            {millisToDurationFormat(
              datum.downtimeOverview.endTimestamp -
                datum.downtimeOverview.startTimestamp
            )}
          </EventInfo>
        </EventInfoColumn>
      </EventInfoContainer>
      <EventButtons>
        <Button
          type={'secondary'}
          size={'small'}
          icon={'report'}
          onClick={() => onDowntimeSelected(datum.downtimeOverview)}
        />
      </EventButtons>
    </StyledChartTooltip>
  );
};

export default GanttChartLabel;
