import React from 'react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { FormattedMessage } from 'react-intl';
import { localizeNumber } from '@dev/base-web/dist/model/helpers/number-formatter';
import { MinMax } from '../../../model/domain/kpis/factory_area_child_result';

const StyledRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const StyledColumn = styled.div`
  flex: 1 0 0;
  padding: 0 !important;
  margin-bottom: 0;
  margin-right: ${(props: ColumnProps) => (props.left ? '8px' : '0')};
  max-width: 175px;
  flex-grow: ${(props: ColumnProps) => (props.bold ? 2 : 0)};
  text-align: ${(props: ColumnProps) => (props.left ? 'left' : 'right')};
  font-weight: ${(props: ColumnProps) => (props.bold ? 'bold' : 'regular')};
`;

const StyledGrid = styled.div`
  min-width: 180px;
  max-width: 384px;
`;

interface ColumnProps {
  readonly left?: boolean;
  readonly bold?: boolean;
}

interface PopupProps {
  manufacturingEntity: ManufacturingEntity | null;
  productsPerCycle: MinMax;
  cycleCount: number;
  producedProducts: number;
  trigger: any;
}

const ProducedProductsPopup = (props: PopupProps) => {
  return (
    <Popup trigger={props.trigger} hoverable flowing position="bottom left">
      <StyledGrid>
        <StyledRow>
          <StyledColumn left bold>
            <p>
              <FormattedMessage id={'cycle_count'} />
            </p>
          </StyledColumn>
          <StyledColumn left>
            <p>{localizeNumber(props.cycleCount)}</p>
          </StyledColumn>
        </StyledRow>
        <StyledRow>
          <StyledColumn left bold>
            <p>
              <FormattedMessage id={'products_per_cycle'} />
            </p>
          </StyledColumn>
          <StyledColumn left>
            <p>
              {props.productsPerCycle.min === props.productsPerCycle.max
                ? localizeNumber(props.productsPerCycle.min)
                : `${localizeNumber(
                    props.productsPerCycle.min
                  )} - ${localizeNumber(props.productsPerCycle.max)}`}
            </p>
          </StyledColumn>
        </StyledRow>
        <StyledRow>
          <StyledColumn left bold>
            <p>
              <FormattedMessage id={'produced_products'} />
            </p>
          </StyledColumn>
          <StyledColumn left>
            <p>{localizeNumber(props.producedProducts)}</p>
          </StyledColumn>
        </StyledRow>
      </StyledGrid>
    </Popup>
  );
};

export default ProducedProductsPopup;
