import { RootReducerInterface } from '../../../../../model/redux/interfaces';
import DurationThunks from '../../../../../model/redux/durations/thunks';
import DurationConfigThunks from '../../../../../model/redux/duration_config/thunks';
import { connect } from 'react-redux';
import {
  ProducedProductsChartDispatchProps,
  ProducedProductsChartStoreProps,
} from '@dev/base-web/dist/view/components/charts/duration_charts/produced_products_chart/interface';
import ProducedProductsChart from '@dev/base-web/dist/view/components/charts/duration_charts/produced_products_chart/view';

const mapStateToProps = (
  state: RootReducerInterface
): ProducedProductsChartStoreProps => ({
  producedProducts: state.data.durations.aggregatedDurationIntervalData.data,
  producedProductsMeta:
    state.data.durations.aggregatedDurationIntervalData.meta,
  durationConfig: state.data.durationConfigState.durationConfig.data,
  durationConfigMeta: state.data.durationConfigState.durationConfig.meta,
});

const mapDispatchToProps: ProducedProductsChartDispatchProps = {
  getProducedProducts: DurationThunks.getAggregatedDurationIntervals,
  getDurationConfig: DurationConfigThunks.getDurationConfig,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProducedProductsChart);
