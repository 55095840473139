import { combineReducers, Reducer } from 'redux';
import { createReducersForLoadingSingleItem } from '@dev/base-web/dist/model/redux/helpers/reducers';
import {
  CONFIRM_EVENT_DEFINITIONS_IMPORT_KEY,
  EVENT_DEFINITIONS_IMPORT_STATUS_KEY,
  IMPORT_EVENT_DEFINITIONS_KEY,
} from './actions';
import { AsyncJobResponseDTO } from '@dev/base-web/dist/model/domain/jobs/AsyncJobResponseDTO';
import { AsyncJobStatusDTO } from '@dev/base-web/dist/model/domain/jobs/AsyncJobStatusDTO';
import { EventDefinitionValidationResponse } from '../../domain/event/event_definition_import';
import { EventDefinitionImportStateInterface } from './interface';

const eventDefinitionImportState: Reducer<EventDefinitionImportStateInterface> =
  combineReducers({
    importEventDefinition:
      createReducersForLoadingSingleItem<AsyncJobResponseDTO>(
        IMPORT_EVENT_DEFINITIONS_KEY
      ),
    eventDefinitionImportStatus: createReducersForLoadingSingleItem<
      AsyncJobStatusDTO<EventDefinitionValidationResponse>
    >(EVENT_DEFINITIONS_IMPORT_STATUS_KEY),
    confirmEventDefinitionImport:
      createReducersForLoadingSingleItem<AsyncJobResponseDTO>(
        CONFIRM_EVENT_DEFINITIONS_IMPORT_KEY
      ),
  });

export default eventDefinitionImportState;
