import React, { useEffect, useMemo, useState } from 'react';
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  WrappedComponentProps,
} from 'react-intl';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import ToggleSwitch from '@dev/base-web/dist/view/components/inputs/toggle_switch';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import { DropdownProps } from 'semantic-ui-react';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { LoadingMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';
import ManufacturingEntityDropdown from '@dev/base-web/dist/view/components/inputs/manufacturing_entity_dropdown';
import Event from '../../../model/domain/event/event_definition';

const TextWrapper = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
  padding: 12px 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 16px;
`;

const StyledLabelWithHint = styled(LabelWithHint)`
  margin-bottom: 8px;
`;

const findEqualManufacturingEntity = (
  selectedEvents: Event[],
  isEventWithMultipleManufacturingEntitiesSelected: boolean
) => {
  if (isEventWithMultipleManufacturingEntitiesSelected) {
    return undefined;
  }

  if (
    selectedEvents &&
    selectedEvents.length &&
    selectedEvents.length > 0 &&
    selectedEvents[0].manufacturingEntities.length === 1
  ) {
    const manufacturingEntity = selectedEvents[0].manufacturingEntities[0];
    if (selectedEvents.length === 1) {
      return manufacturingEntity;
    } else if (selectedEvents.length > 1) {
      for (let i = 1; i < selectedEvents.length; i++) {
        if (
          selectedEvents[i].manufacturingEntities[0].id !==
          manufacturingEntity.id
        ) {
          return undefined;
        }
      }
      return manufacturingEntity;
    } else return undefined;
  }
};

const checkEqualType = (selectedEvents: Event[]) => {
  if (selectedEvents.length > 0) {
    const type = selectedEvents[0].type;
    if (selectedEvents.length > 1) {
      for (let i = 0; i < selectedEvents.length - 1; i++) {
        if (selectedEvents[i].type !== selectedEvents[i + 1].type) {
          return '';
        }
      }
    }
    return type;
  } else return '';
};

const dropdownOptions = (intl: IntlShape) => {
  const options: {
    text: string;
    value: string;
  }[] = Object.keys(EventType).map((key) => ({
    text: intl.formatMessage({ id: key.toLowerCase() }),
    value: key,
  }));
  return options;
};

interface Props extends WrappedComponentProps {
  readonly selectedEvents: Event[];
  readonly initialArchive: boolean;
  readonly batchEditCallback: (
    archive?: boolean,
    type?: string,
    manufacturingEntity?: ManufacturingEntity
  ) => void;
  readonly selectedQuantity: number;
  readonly manufacturingEntities: readonly ManufacturingEntity[];
  readonly manufacturingEntitiesMeta: LoadingMetaState;
}

const BatchEdit: React.FC<Props> = ({
  selectedEvents,
  initialArchive,
  batchEditCallback,
  selectedQuantity,
  intl,
  manufacturingEntities,
  manufacturingEntitiesMeta,
}) => {
  const [selectedType, setSelectedType] = useState<string>();
  const [archive, setArchive] = useState(initialArchive);
  const [selectedManufacturingEntity, setSelectedManufacturingEntity] =
    useState<ManufacturingEntity | undefined>();

  useEffect(() => {
    setSelectedType(checkEqualType(selectedEvents) as string);
    setSelectedManufacturingEntity(
      findEqualManufacturingEntity(
        selectedEvents,
        isEventWithMultipleManufacturingEntitiesSelected
      )
    );
  }, [selectedEvents]);

  const handleSelectType = (
    _e: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    setSelectedType(data.value as string);
  };

  const handleChangeArchive = (checked: boolean) => {
    setArchive(!checked);
  };

  const changeLocalManufacturingEntity = (
    manufacturingEntities: readonly ManufacturingEntity[]
  ) => {
    if (manufacturingEntities.length === 1)
      setSelectedManufacturingEntity(manufacturingEntities[0]);
    else setSelectedManufacturingEntity(undefined);
  };

  const isEventWithMultipleManufacturingEntitiesSelected = useMemo(
    () => selectedEvents.some((r) => r.manufacturingEntities.length > 1),
    [selectedEvents]
  );

  const isOkDisabled = useMemo(() => {
    const currentEqualType = checkEqualType(selectedEvents);
    const currentEqualManufacturingEntity = findEqualManufacturingEntity(
      selectedEvents,
      isEventWithMultipleManufacturingEntitiesSelected
    );

    const isArchiveEqual = initialArchive === archive;

    const areBothUndefined = !selectedType && !selectedManufacturingEntity;

    const isSameType = !!selectedType && selectedType === currentEqualType;

    const isSameManufacturingEntity =
      !!selectedManufacturingEntity &&
      !!currentEqualManufacturingEntity &&
      selectedManufacturingEntity.id === currentEqualManufacturingEntity.id;

    return (
      isArchiveEqual &&
      (areBothUndefined ||
        (isSameType && isSameManufacturingEntity) ||
        (isSameType && !selectedManufacturingEntity) ||
        (isSameManufacturingEntity && !selectedType))
    );
  }, [
    initialArchive,
    archive,
    selectedType,
    selectedManufacturingEntity,
    selectedEvents,
    isEventWithMultipleManufacturingEntitiesSelected,
  ]);

  return (
    <BasicModalWithHeaderAndTwoButtons
      headerLabel={<FormattedMessage id="edit_events" />}
      onClose={() => batchEditCallback(archive, 'cancel')}
      onCancel={() => batchEditCallback(archive, 'cancel')}
      onOk={() =>
        batchEditCallback(archive, selectedType, selectedManufacturingEntity)
      }
      open={true}
      okLabel="save"
      cancelLabel="cancel_button"
      modalSize="tiny"
      okDisabled={isOkDisabled}
    >
      <Content>
        <div>
          <StyledLabelWithHint label={'event_category'} />
          <StyledDropDown
            selection
            value={selectedType}
            options={dropdownOptions(intl)}
            onChange={handleSelectType}
            placeholder={'-'}
            clearable
          />
        </div>
        <div>
          <StyledLabelWithHint
            label={'manufacturing_entity'}
            hint={
              isEventWithMultipleManufacturingEntitiesSelected
                ? 'disabled_manufacturing_entity_multiple_hint'
                : undefined
            }
          />
          <ManufacturingEntityDropdown
            manufacturingEntities={
              manufacturingEntities ? manufacturingEntities : []
            }
            onManufacturingEntitySelected={changeLocalManufacturingEntity}
            selectedManufacturingEntity={selectedManufacturingEntity}
            placeholder={intl.formatMessage({ id: 'select_location' })}
            noResultFoundLabel={intl.formatMessage({
              id: 'no_manufacturing_entity_found',
            })}
            loading={manufacturingEntitiesMeta.loadingInProgress}
            searchable
            clearable
            disabled={isEventWithMultipleManufacturingEntitiesSelected}
          />
        </div>
        <div>
          <StyledLabelWithHint label={'active'} />
          <ToggleSwitch checked={!archive} onChange={handleChangeArchive} />
        </div>
      </Content>
      <TextWrapper>
        <Hint
          messageKey={'event_overwrite_explanation'}
          messageValues={{
            br: <br />,
            count: selectedQuantity,
          }}
          textColor={'#939393'}
        />
      </TextWrapper>
    </BasicModalWithHeaderAndTwoButtons>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {})(injectIntl(BatchEdit));
