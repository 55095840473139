import createThunks from '@dev/base-web/dist/model/redux/feature/thunks_factory';
import { Actions } from '@dev/base-web/dist/model/redux/feature/actions';
import {
  extraFeatureApi,
  featureApi,
  featureConfigurationApi,
  featureStatisticsApi,
  featureTemplatesApi,
} from '../../api';
import { RootReducerInterface } from '../interfaces';
import { OperationType } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import { Dispatch } from 'redux';

export default createThunks(
  featureApi,
  featureConfigurationApi,
  featureStatisticsApi,
  featureTemplatesApi
);

export const newCreateFeature =
  (feature: any) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.featureOperation.meta.startOperation({
        operation: OperationType.CREATE,
      })
    );

    try {
      const response = await extraFeatureApi.createFeature(
        feature,
        token.accessToken
      );

      dispatch(Actions.featureOperation.meta.operationSucceeded());
      dispatch(Actions.feature.loadingItemSuccessful(response));
      return response;
    } catch (error) {
      dispatch(Actions.featureOperation.meta.operationFailed({ error }));
    }
  };

export const newUpdateFeature =
  (id: string, feature: any) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.featureOperation.meta.startOperation({
        operation: OperationType.UPDATE,
      })
    );

    try {
      const response = await extraFeatureApi.updateFeature(
        id,
        feature,
        token.accessToken
      );

      dispatch(Actions.featureOperation.meta.operationSucceeded());
      dispatch(Actions.feature.loadingItemSuccessful(response));
      return response;
    } catch (error) {
      dispatch(Actions.featureOperation.meta.operationFailed({ error }));
    }
  };
