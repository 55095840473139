import { useEffect, useMemo, useState } from 'react';
import {
  BasicActionDefinitionDTO,
  ActionDefinitionDTO,
} from '@/model/domain/instruction/instruction.ts';
import {
  DecisionOptionDTO,
  DisplayedDecision,
  DisplayedStep,
  SelectedDecision,
  StepDTO,
} from '@/model/domain/instruction/step.ts';
import {
  changeSelectedDecisions,
  deleteStep,
  getRemovableDecisionIds,
  moveStep,
  rebuildStepsRecursiveAddWithoutDecision,
} from '@/model/domain/instruction/step_helper.ts';
import { v4 as uuid } from 'uuid';
import { toastrError } from '@dev/base-web/dist/view/helpers/notification_helpers';
import { BasicEvent } from '@/model/redux/actions/interface.ts';
import { InstructionCardContentProps } from './editable_content';
import {
  anyTranslationsChanged,
  isEditEqual,
  isInstructionValid,
} from '@/model/domain/instruction/helper.ts';
import { usePrompt } from '@dev/base-web/dist/view/helpers/navigation_helpers';
import { useIntl } from 'react-intl';
import {
  LoadingMetaState,
  OperationMetaState,
  OperationType,
} from '@dev/base-web/dist/model/redux/helpers/interfaces';
import {
  addEmptyStep,
  addStep,
  enumerateSteps,
  rebuildStepsRecursiveModify,
} from '@/model/domain/instruction/step_tree_helper.ts';
import {
  extractTitleFromSuggetion,
  suggestionToSteps,
} from '@/model/domain/event/solution_suggestion.ts';
import { InstructionSuggestion } from '@/model/domain/instruction/suggestion.ts';

const LOCALE_STORAGE_KEY = 'copiedStep';

function getStringAfterLastSlash(input: string): string {
  const parts = input.split('/');
  return parts.pop() || '';
}

const useInstructionEditing = (
  instruction: ActionDefinitionDTO | null,
  selectedLanguage: string | null,
  setSelectedLanguage: (lang: string) => void,
  locale: string,
  instructionUpdateMeta: OperationMetaState,
  suggestedInstruction: InstructionSuggestion | null,
  suggestedInstructionMeta: LoadingMetaState,
  id?: string,
  eventId?: string
) => {
  const intl = useIntl();

  const [localInstruction, setLocalInstruction] =
    useState<BasicActionDefinitionDTO>();
  const [currentDecision, setCurrentDecision] = useState<DecisionOptionDTO>();
  const [currentStep, setCurrentStep] = useState<StepDTO>();
  const [selectedDecisions, setSelectedDecisions] = useState<
    readonly SelectedDecision[]
  >([]);
  const [lastEditedLanguage, setLastEditedLanguage] = useState<string>();
  const [isRevertActive, setIsRevertActive] = useState(false);
  const [wasSuggestionActive, setWasSuggestionActive] = useState(false);

  useEffect(() => {
    setLocalInstruction(instruction || undefined);
    setLastEditedLanguage(instruction?.language);

    if (instruction && instruction?.language !== selectedLanguage)
      setSelectedLanguage(instruction.language);
  }, [instruction]);

  useEffect(() => {
    if (id === 'new' && !localInstruction) {
      setLocalInstruction({
        steps: [],
        labels: [],
        translations: [],
        events: [],
        isHidden: false,
      });
    }
  }, [id, localInstruction, eventId]);

  useEffect(() => {
    if (id === 'new' && !selectedLanguage) setSelectedLanguage(locale);
  }, [id, selectedLanguage]);

  useEffect(() => {
    if (suggestedInstructionMeta.loadingInProgress)
      setWasSuggestionActive(true);
  }, [suggestedInstructionMeta]);

  // need previous event to check if it has changed and based on that we can auto-set title
  const [prevFirstEvent, setPrevFirstEvent] = useState<BasicEvent>();

  useEffect(() => {
    if (
      localInstruction?.events.length &&
      localInstruction.events[0] !== prevFirstEvent
    ) {
      setPrevFirstEvent(localInstruction.events[0]);
    }
  }, [localInstruction?.events]);

  useEffect(() => {
    if (suggestedInstruction && wasSuggestionActive) {
      //TODO: is intl.locale correct??
      const steps = suggestionToSteps(
        suggestedInstruction.suggestion,
        intl.locale
      );
      updateSteps(steps);

      // set a title if it's not set yet
      if (
        localInstruction?.translations.length === 0 ||
        (localInstruction?.events.length &&
          prevFirstEvent &&
          localInstruction?.events[0] !== prevFirstEvent &&
          localInstruction.translations[0].translation ===
            intl.formatMessage(
              { id: 'resolve_event_template' },
              { event: prevFirstEvent.name }
            ))
      ) {
        let title = extractTitleFromSuggetion(suggestedInstruction.suggestion);

        // if there's already a step, the title was apparently not included in the response. So setting it manually.
        if (!title && steps.length && localInstruction.events.length === 1) {
          title = intl.formatMessage(
            { id: 'resolve_event_template' },
            { event: localInstruction.events[0].name }
          );
        }

        if (title) {
          setLocalInstruction({
            ...localInstruction,
            translations: [
              {
                language: intl.locale.toUpperCase(),
                translation: title,
              },
            ],
          });
        }
      }
    }
  }, [suggestedInstruction, localInstruction?.events, wasSuggestionActive]);

  const isInstructionChanged = useMemo(() => {
    return (
      localInstruction &&
      (!localInstruction.id || localInstruction.id === instruction?.id) &&
      (isRevertActive || !isEditEqual(localInstruction, instruction))
    );
  }, [localInstruction, instruction, id, isRevertActive]);

  const isSavable = useMemo(() => {
    if (isInstructionChanged && localInstruction) {
      // check if everything is valid
      return isInstructionValid(localInstruction);
    } else return false;
  }, [localInstruction, isInstructionChanged]);

  const hasMultipleLanguages = useMemo(() => {
    return (
      localInstruction?.availableTranslations &&
      localInstruction?.availableTranslations.length > 1
    );
  }, [localInstruction]);

  const areAnyTranslationsChanged = useMemo(() => {
    return (
      instruction &&
      localInstruction &&
      anyTranslationsChanged(instruction, localInstruction)
    );
  }, [localInstruction, instruction]);

  usePrompt((nextPath) => {
    const { pathname: currentPathname } = location;

    // We don't want to show the prompt if a new user is created or one has been
    // deleted.
    const isOnCreatePage = currentPathname.endsWith('new');
    const skipPrompt =
      !isInstructionChanged ||
      (isOnCreatePage &&
        !!instructionUpdateMeta.operation &&
        instructionUpdateMeta.operation === OperationType.CREATE);
    if (skipPrompt) return false;

    return (
      !nextPath ||
      getStringAfterLastSlash(nextPath) !==
        getStringAfterLastSlash(currentPathname)
    );
  }, intl.formatMessage({ id: 'unsaved_changes_prompt' }));

  const updateSteps = (steps: readonly StepDTO[]) => {
    if (localInstruction)
      setLocalInstruction({
        ...localInstruction,
        steps: steps,
      });
  };

  const temporaryAddDecision = (step: StepDTO, parent?: StepDTO) => {
    /// --> REMOVE THIS FUNCTION WHEN WINDOW IS ADDED BACK
    const decisionOptions: DecisionOptionDTO[] = [
      {
        id: uuid(),
        name: 'yes',
        translations: [],
        steps: [],
      },
      {
        id: uuid(),
        name: 'no',
        translations: [],
        steps: [],
      },
    ];

    const newStep =
      parent && parent.step
        ? { ...step, step: parent.step + 1, decisionOptions }
        : { ...step, decisionOptions };

    if (localInstruction && parent) {
      setLocalInstruction({
        ...localInstruction,
        steps: rebuildStepsRecursiveAddWithoutDecision(
          localInstruction.steps,
          parent,
          newStep
        ),
      });
    } else if (localInstruction) {
      setLocalInstruction({
        ...localInstruction,
        steps: [...localInstruction.steps, newStep],
      });
    }
  };

  const onAddDecisions = (newStep: StepDTO) => {
    if (currentStep) {
      modifyStep(currentStep, newStep);
      setCurrentStep(undefined);
    }
  };

  const onAddEmptyStep = (
    step: StepDTO,
    position: 'below' | 'above',
    parent?: DisplayedDecision
  ) => {
    if (localInstruction && localInstruction.steps) {
      updateSteps(
        addEmptyStep(
          localInstruction.steps,
          position,
          step.step,
          parent?.orig?.id
        )
      );
    }
  };

  const onAddFirstEmptyStep = () => {
    if (localInstruction && !localInstruction.steps.length) {
      updateSteps([
        {
          step: 1,
          type: 'INSTRUCTION',
          decisionOptions: [],
          additionalInfos: [],
          media: null,
          translations: [],
        },
      ]);
    }
  };

  const modifyStep = (
    oldStep: StepDTO,
    newStep: StepDTO,
    parent?: DisplayedDecision
  ) => {
    if (localInstruction && localInstruction.steps) {
      updateSteps(
        rebuildStepsRecursiveModify(
          localInstruction.steps,
          oldStep,
          newStep,
          parent?.orig?.id
        )
      );
    }
  };

  const changeStepOrder = (
    item: StepDTO,
    action: 'increase' | 'decrease' | 'delete'
  ) => {
    if (localInstruction) {
      switch (action) {
        case 'increase':
          updateSteps(
            enumerateSteps(moveStep(localInstruction.steps, item, 'up'))
          );
          break;
        case 'decrease':
          updateSteps(
            enumerateSteps(moveStep(localInstruction.steps, item, 'down'))
          );
          break;
        case 'delete':
          {
            const updatedSteps = enumerateSteps(
              deleteStep(localInstruction.steps, item)
            );
            updateSteps(updatedSteps);
            const removableDecisionIds = getRemovableDecisionIds(updatedSteps);

            if (removableDecisionIds.length)
              setSelectedDecisions(
                selectedDecisions.filter(
                  (id) => !removableDecisionIds.some((r) => r === id.decisionId)
                )
              );
          }
          break;
        default:
          break;
      }
    }
  };

  //TODO: what is this really used for?
  const changeValues: InstructionCardContentProps['onChange'] = (changes) => {
    if (localInstruction) {
      // during editing we have an existing value anyway
      setLocalInstruction({
        ...localInstruction,
        ...changes,
      });
    }
  };

  // copy step to local storage
  const copyStep = (step: StepDTO) => {
    localStorage.setItem(
      LOCALE_STORAGE_KEY,
      JSON.stringify({ ...step, id: uuid() })
    );
  };

  // paste step from local storage
  const pasteStep = (
    referencedStep: StepDTO,
    position: 'below' | 'above',
    parent?: DisplayedDecision
  ) => {
    if (localInstruction && localInstruction.steps) {
      const step = localStorage.getItem(LOCALE_STORAGE_KEY);
      if (step) {
        const parsedStep: StepDTO = JSON.parse(step);
        if (parsedStep) {
          updateSteps(
            addStep(
              localInstruction.steps,
              {
                type: parsedStep.type,
                media: parsedStep.media,
                decisionOptions: parsedStep.decisionOptions,
                additionalInfos: parsedStep.additionalInfos,
                translations: parsedStep.translations,
                dummyId: uuid(),
              },
              position,
              referencedStep.step,
              parent?.orig?.id
            )
          );
        }

        localStorage.removeItem(LOCALE_STORAGE_KEY);
      } else {
        toastrError('No step copied');
      }
    }
  };

  const onEventsAdded = (events: BasicEvent[], overwrite?: boolean) => {
    if (localInstruction) {
      const basicEvents = events.map((r) => {
        return {
          id: r.id,
          type: r.type,
          name: r.name ? r.name : '-',
          manufacturingEntities: r.manufacturingEntities ?? [],
        };
      });
      const copyItems =
        localInstruction && !overwrite ? localInstruction.events : [];

      setLocalInstruction({
        ...localInstruction,
        events: [...copyItems, ...(basicEvents as BasicEvent[])],
      });
    }
  };

  const onSelectDecision = (s: DisplayedStep, d: DisplayedDecision) => {
    if (d.orig.steps.length === 0 && d.orig.id && localInstruction) {
      updateSteps(
        addStep(
          localInstruction.steps,
          {
            media: {
              full: '',
              thumb: '',
              fileType: 'PICTURE',
            },
            decisionOptions: [],
            type: 'INSTRUCTION',
            additionalInfos: [],
            translations: [],
          },
          'below',
          undefined,
          d.orig.id
        )
      );

      setCurrentDecision(d.orig);
      setSelectedDecisions(
        changeSelectedDecisions(selectedDecisions, {
          stepNumber: s.orig.step,
          stepId: s.orig.id,
          decisionId: d.orig.id,
        })
      );
    } else {
      if (currentDecision === d.orig) {
        setCurrentDecision(undefined);
      } else {
        setCurrentDecision(d.orig);
      }

      if (d.orig.id)
        setSelectedDecisions(
          changeSelectedDecisions(selectedDecisions, {
            stepNumber: s.orig.step,
            stepId: s.orig.id,
            decisionId: d.orig.id,
          })
        );
    }
  };

  return {
    localInstruction,
    currentStep,
    setCurrentStep,
    currentDecision,
    selectedDecisions,
    setLocalInstruction,
    temporaryAddDecision,
    onAddFirstEmptyStep,
    onAddEmptyStep,
    modifyStep,
    changeStepOrder,
    changeValues,
    pasteStep,
    copyStep,
    onEventsAdded,
    onSelectDecision,
    onAddDecisions,
    isSavable,
    lastEditedLanguage,
    hasMultipleLanguages,
    areAnyTranslationsChanged,
    setLastEditedLanguage,
    setIsRevertActive,
  };
};

export default useInstructionEditing;
