import styled from 'styled-components';
import { DropdownProps, Popup } from 'semantic-ui-react';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';
import React, { useEffect, useState } from 'react';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { RequestReviewPopupProps } from './interface';
import { useIntl } from 'react-intl';
import {
  FilterOperation,
  FilterData,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { useNotificationForOperation } from '@dev/base-web/dist/view/helpers/notification_helpers';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import UserAvatar from '@dev/base-web/dist/view/components/global/dummy_avatar';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-top: 8px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
`;

const StyledDropdown = styled(StyledDropDown)`
  &&&&&&&&& {
    margin-top: 4px;
    width: 288px;
  }
`;

const StyledLabelWithHint = styled(LabelWithHint)`
  margin-top: 8px;
`;

const StyledUserAvatar = styled(UserAvatar)`
  margin-right: 8px;
`;

const RequestReviewPopup = ({
  instructionId,
  usersMeta,
  requestReview,
  instructionApprovalUsers,
  getAllUsers,
  requestReviewOperation,
  checkIfInstructionReviewRequestExists,
  reviewStatus,
  currentUser,
  reviewStatusMeta,
}: RequestReviewPopupProps) => {
  const intl = useIntl();
  const { formatDate } = useLocalizedDateFormatters();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedReviewer, setSelectedReviewer] = useState<string | null>();

  useEffect(() => {
    setSelectedReviewer(null);
    checkIfInstructionReviewRequestExists(instructionId);
  }, [instructionId]);

  useEffect(() => {
    getAllUsers(
      [new FilterData('canApproveSolution', FilterOperation.EQ_OPERATOR, true)],
      'username',
      SortingDirection.ASCENDING
    );
  }, []);

  useEffect(() => {
    if (reviewStatus) {
      setSelectedReviewer(
        reviewStatus.reviewers.length > 0
          ? reviewStatus.reviewers[reviewStatus.reviewers.length - 1].id
          : null
      );
    }
  }, [reviewStatus]);

  const onUserDropdownSelect = (
    _event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    setSelectedReviewer(data.value as unknown as string);
  };

  const userOptions = instructionApprovalUsers
    .filter((u) => u.id !== currentUser?.id)
    .map((user) => ({
      text:
        user.firstName && user.lastName
          ? `${user.firstName} ${user.lastName}`
          : user.username,
      value: user.id,
      image: (
        <StyledUserAvatar
          name={
            !user.firstName && !user.lastName
              ? user.username
              : `${user.firstName || '-'} ${user.lastName || '-'}`
          }
          src={user.pictures.thumb}
          size={'24px'}
        />
      ),
    }));

  useNotificationForOperation(
    requestReviewOperation,
    'review_requested',
    'review_requested',
    'review_requested',
    intl,
    () => onReviewRequestSuccess()
  );

  const onReviewRequestSuccess = () => {
    checkIfInstructionReviewRequestExists(instructionId);
    setOpen(false);
  };

  return (
    <Popup
      wide
      trigger={
        <div>
          <Button
            type="secondary"
            label={
              reviewStatus?.hasReviewRequest
                ? 'pending_review_request'
                : 'request_review'
            }
            loading={reviewStatusMeta.loadingInProgress}
            disabled={reviewStatus?.hasReviewRequest}
          />
        </div>
      }
      on="click"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      position={'bottom right'}
      pinned
      //offset={[-14]}
      style={{ paddingLeft: 4 }}
      disabled={false}
    >
      <FormContainer>
        <div style={{ marginLeft: 10 }}>
          {reviewStatus?.hasReviewRequest && (
            <>
              <LabelWithHint label={'requested_at'} />
              <p>{formatDate(reviewStatus.requestedAt, 'Pp')}</p>
            </>
          )}
          <StyledLabelWithHint
            label={
              reviewStatus?.hasReviewRequest
                ? 'selected_reviewer'
                : 'choose_reviewer'
            }
          />
          <StyledDropdown
            placeholder={intl.formatMessage({ id: 'search_for_a_user' })}
            value={selectedReviewer}
            selection
            search
            onChange={onUserDropdownSelect}
            options={userOptions}
            //error={!selectedReviewer}
            noResultsMessage={intl.formatMessage({
              id: 'no_users_found',
            })}
            loading={usersMeta.loadingInProgress}
          />
        </div>
        <ButtonContainer>
          <Button
            type="primary"
            icon={reviewStatus?.hasReviewRequest ? 'rotate' : 'checkmark'}
            size={'small'}
            disabled={!selectedReviewer}
            onClick={() => {
              if (selectedReviewer)
                requestReview(instructionId, selectedReviewer);
            }}
            loading={requestReviewOperation.operationInProgress}
            tooltipLabel={
              reviewStatus?.hasReviewRequest
                ? intl.formatMessage({ id: 'redo_request' })
                : undefined
            }
            tooltipDelay={600}
          />
        </ButtonContainer>
      </FormContainer>
    </Popup>
  );
};

export default RequestReviewPopup;
