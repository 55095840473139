import React, { Component } from 'react';
import {
  ScreenRoot,
  ScreenContent,
} from '@dev/base-web/dist/view/components/global/styled_components';
import {
  Card,
  CardColumn,
  CardRow,
  FlexibleCardColumn,
  MultiCardLayout,
} from '@dev/base-web/dist/view/components/global/card';
import styled from 'styled-components';
import {
  FeatureConfigurationInterface,
  FeatureConfigurationProps,
} from './feature_interface';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PlcSignal } from '@dev/base-web/dist/model/domain/plc/plc_signal';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import EmptyInput from '@dev/base-web/dist/view/components/feature/feature_config_card/empty_input';
import { FeatureFunction as InitialFeature } from '@dev/base-web/dist/view/components/feature/feature_config_card/feature_function';
import { FeatureTemplateContext } from '@dev/base-web/dist/view/components/feature/feature_config_card/select_template/components/feature_template_context';
import { FeatureGeneratorInputProps } from '@dev/base-web/dist/model/domain/feature/feature';
import { SignalStoreProps } from '@dev/base-web/dist/view/components/feature/feature_config_card/select_signal/select_signal';
import { featureSaveable } from '@dev/base-web/dist/view/components/feature/feature_helpers';
import { getAcceptableInput } from '@dev/base-web/dist/view/components/feature/feature_config_card/allowed_inputs_helper';
import DetailLoader from '@dev/base-web/dist/view/components/global/detail_loader';
import {
  CardSpacer,
  CenteredCardContent,
} from '@dev/base-web/dist/view/components/charts/styled_components';
import FeatureValuesChartOptionsPopup from '@dev/base-web/dist/view/components/charts/feature_value_chart_options_popup';
import {
  subHours,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  isBefore,
  isSameDay,
  addHours,
  addMinutes,
  startOfHour,
} from 'date-fns';
import SignalValueChartRow from '@dev/base-web/dist/view/components/charts/signal_value_chart_row';
import { DragAndDrop } from '@dev/base-web/dist/view/components/feature/styles';
import FunctionsCard from './components/functions_card';
import FeatureErrorInfo from './components/feature_error_info';
import TrashBinOverlay from '@dev/base-web/dist/view/components/feature/feature_config_card/trashbin_overlay';

const CardContent = styled.div`
  margin-top: 1rem;
`;

const StyledEmptyInput = styled(EmptyInput)`
  height: 200px;
`;

const InitialFeatureContainer = styled.div`
  width: min-content;
  overflow: visible;
`;

interface MemoedValue<ValueType> {
  readonly current: ValueType;
  readonly previous: ValueType;
}

interface FeatureCreationScreenState {
  feature: FeatureGeneratorInputProps | undefined;
  signalChoosing: boolean;
  setSignal: null | ((signal: PlcSignal) => void);
  functionSearch: string;
  saveable: boolean;
  loadbreak: boolean;
  acceptableInput: string[];
  begin: MemoedValue<number>;
  end: MemoedValue<number>;
  timeFormat: string;
  ticks: number[];
  invalidConfiguration: boolean;
  deleteLock: boolean;
}

class FeatureCreationScreen extends Component<
  WrappedComponentProps & FeatureConfigurationProps,
  FeatureCreationScreenState
> {
  constructor(props: WrappedComponentProps & FeatureConfigurationProps) {
    super(props);
    const nowWithZeroSeconds = Math.floor(Date.now() / 1000) * 1000;

    this.state = {
      feature: undefined,
      signalChoosing: false,
      setSignal: null,
      functionSearch: '',
      saveable: false,
      loadbreak: false,
      acceptableInput: [],
      end: {
        previous: -1,
        current: nowWithZeroSeconds,
      },
      begin: {
        previous: -1,
        current: subHours(nowWithZeroSeconds, 2).valueOf(),
      },
      timeFormat: '',
      ticks: [],
      invalidConfiguration: false,
      deleteLock: false,
    };
  }

  componentDidMount(): void {
    // Clear previously loaded feature values.
    if (this.props.featureValues.length > 0) {
      this.props.clearFeatureValues();
    }

    // Request feature values when component is mounted and there's a valid feature in props.
    if (
      this.props.feature &&
      featureSaveable(this.props.featureConfiguration, this.props.feature)
    ) {
      this.handleIfFeatureChanged(this.props.feature, undefined, undefined);
    }

    this.setState({
      acceptableInput: this.props.featureConfiguration
        ? getAcceptableInput(
            this.props.featureConfiguration.functions,
            this.state.feature
          )
        : [],
    });
  }

  componentDidUpdate(
    prevProps: Readonly<FeatureConfigurationInterface>,
    prevState: Readonly<FeatureCreationScreenState>
  ): void {
    const { feature, stage } = this.props;
    const {
      feature: prevFeature,
      manufacturingEntity: prevMan,
      stage: prevStage,
    } = prevProps;

    const { feature: prevFeatureValue } = prevState;

    this.handleIfThereIsNoFeatureInStateButInProps(
      feature,
      prevMan,
      stage,
      prevStage
    );
    this.handleIfFeatureChanged(feature, prevFeature, prevFeatureValue);
    this.handleIfFeatureChangedLocallyComparedToTheProp(
      feature,
      prevFeature,
      prevFeatureValue,
      prevMan
    );
    this.handleIfFeatureIsChangedInState(
      prevFeatureValue,
      feature,
      prevFeature
    );
  }

  private handleIfFeatureIsChangedInState(
    prevFeatureValue: FeatureGeneratorInputProps | undefined,
    feature: FeatureGeneratorInputProps | undefined,
    prevFeature: FeatureGeneratorInputProps | undefined
  ) {
    if (prevFeatureValue !== this.state.feature) {
      if (
        feature === prevFeature || //if the props are the same (no other api call was made)
        this.state.feature
      ) {
        this.setState({
          acceptableInput: this.props.featureConfiguration
            ? getAcceptableInput(
                this.props.featureConfiguration.functions,
                this.state.feature
              )
            : [],
        });
        if (this.state.loadbreak) {
          this.setState({ saveable: false, loadbreak: false });
        } else
          this.setState({
            saveable: featureSaveable(
              this.props.featureConfiguration,
              this.state.feature
            ),
          });
      } else this.setState({ saveable: false });
    }
  }

  private handleIfFeatureChangedLocallyComparedToTheProp(
    feature: FeatureGeneratorInputProps | undefined,
    prevFeature: FeatureGeneratorInputProps | undefined,
    prevFeatureValue: FeatureGeneratorInputProps | undefined,
    prevMan: ManufacturingEntity
  ) {
    if (
      feature === prevFeature &&
      this.state.feature &&
      feature !== this.state.feature &&
      feature !== prevFeatureValue &&
      this.props.manufacturingEntity === prevMan
    ) {
      this.requestFeatureValues();
    }
  }

  private handleIfFeatureChanged(
    feature: FeatureGeneratorInputProps | undefined,
    prevFeature: FeatureGeneratorInputProps | undefined,
    prevFeatureValue: FeatureGeneratorInputProps | undefined
  ) {
    if (
      feature !== prevFeature &&
      feature !== this.state.feature &&
      feature !== prevFeatureValue
    ) {
      this.setState(
        {
          feature: feature,
          loadbreak: true,
        },
        this.requestFeatureValues
      );
    }
  }

  private handleIfThereIsNoFeatureInStateButInProps(
    feature: FeatureGeneratorInputProps | undefined,
    prevMan: ManufacturingEntity,
    stage: string,
    prevStage: string
  ) {
    if (
      (feature && !this.state.feature) ||
      (feature !== this.state.feature &&
        (this.props.manufacturingEntity !== prevMan || stage !== prevStage))
    ) {
      if (this.state.deleteLock) {
        this.setState({
          deleteLock: false,
        });
      } else
        this.setState({
          feature: feature,
        });
    }
  }

  requestFeatureValues = () => {
    if (
      this.state.feature &&
      featureSaveable(this.props.featureConfiguration, this.state.feature)
    ) {
      const feature = {
        type: null,
        description: '',
        manufacturingEntityId: null,
        value: { ...this.state.feature },
      };

      this.setState(
        {
          invalidConfiguration: false,
        },
        () => {
          this.props.computeFeatureValues(
            [feature],
            this.state.begin.current,
            this.state.end.current
          );
        }
      );
    } else {
      this.setState({
        invalidConfiguration: true,
      });
    }
  };

  saveFeatureTimes = (
    newBegin: number,
    newEnd: number,
    callback: VoidFunction | undefined = undefined
  ) => {
    this.setState(
      (state) => ({
        begin: {
          previous: state.begin.current,
          current: newBegin,
        },
        end: {
          previous: state.end.current,
          current: newEnd,
        },
      }),
      callback
    );
  };

  calculateTimeFormatCached = (): string => {
    const { begin, end, timeFormat } = this.state;
    if (begin.previous === begin.current && end.previous === end.current)
      return timeFormat;

    const newTimeFormat =
      differenceInDays(begin.current, end.current) > 1 ||
      !isSameDay(begin.current, end.current)
        ? 'Pp'
        : 'pp';

    this.setState({
      timeFormat: newTimeFormat,
    });
    this.saveFeatureTimes(begin.current, end.current);
    return newTimeFormat;
  };

  updateTicksCached = () => {
    const { begin, end } = this.state;
    if (begin.previous === begin.current && end.previous === end.current)
      return;

    const startTime = new Date(begin.current);
    const endTime = new Date(end.current);

    let stepUnit: 'hour' | 'minute' = 'hour';
    let stepSize: number;
    if (differenceInHours(endTime, startTime) >= 5) {
      stepSize = Math.max(
        Math.round(differenceInHours(endTime, startTime) / 5),
        1
      );
    } else {
      stepSize = Math.max(
        Math.round(differenceInMinutes(endTime, startTime) / 5),
        1
      );
      stepUnit = 'minute';
    }

    const currentTick = startOfHour(addHours(startTime, 1));

    const newTicks = [];
    while (isBefore(currentTick, endTime)) {
      newTicks.push(currentTick.valueOf());
      stepUnit === 'hour'
        ? addHours(currentTick, stepSize)
        : addMinutes(currentTick, stepSize);
    }

    this.setState({
      ticks: newTicks,
    });
  };

  render() {
    const {
      featureValues,
      featureValuesLoading,
      featureValuesError,
      allowedToModify,
    } = this.props;

    const updateFeature = (data: FeatureGeneratorInputProps) => {
      const newData = { ...data };
      this.props.featureCallback(newData, this.props.manufacturingEntity);
    };

    const signalDispatch = {
      getSignals: this.props.getSignals,
    };

    const signals: SignalStoreProps = {
      signals: this.props.signals,
      moreSignalsCanBeLoaded: this.props.moreSignalsCanBeLoaded,
      signalsMeta: this.props.signalsMeta,
    };

    const hasFeatureValues = featureValues.length > 0;
    const { begin, end, ticks, invalidConfiguration } = this.state;
    const featureValuesChartTimeFormat = this.calculateTimeFormatCached();

    return (
      <ScreenRoot>
        <ScreenContent>
          <MultiCardLayout>
            <CardRow>
              <DndProvider backend={HTML5Backend}>
                <CardColumn>
                  <FunctionsCard
                    functionSearch={this.state.functionSearch}
                    setFunctionSearch={(search) =>
                      this.setState({ functionSearch: search })
                    }
                    featureConfiguration={this.props.featureConfiguration}
                    acceptableInput={
                      this.props.manufacturingEntity !== null
                        ? this.state.acceptableInput
                        : []
                    }
                    onlyEdgeFunctions={this.props.onlyEdges}
                  />
                </CardColumn>
                <FlexibleCardColumn>
                  <CardRow>
                    <Card titleId="feature_definition">
                      <CardContent>
                        <DragAndDrop hasContent>
                          <FeatureTemplateContext.Provider
                            value={{
                              getFeatureTemplates:
                                this.props.getFeatureTemplates,
                              featureTemplateState:
                                this.props.featureTemplateState,
                              featureTemplatesEnabled: true,
                            }}
                          >
                            {this.state.feature !== undefined ? (
                              <InitialFeatureContainer>
                                <InitialFeature
                                  allowedInput={[]}
                                  featureConfiguration={
                                    this.props.featureConfiguration == null
                                      ? undefined
                                      : this.props.featureConfiguration
                                          .functions
                                  }
                                  data={this.state.feature}
                                  sendChanges={() => {}}
                                  node="initial"
                                  updateState={updateFeature}
                                  manufacturingEntity={
                                    this.props.manufacturingEntity
                                  }
                                  onDelete={() => {
                                    this.setState({
                                      feature: undefined,
                                    });
                                    this.props.featureCallback(
                                      undefined,
                                      this.props.manufacturingEntity
                                    );
                                  }}
                                  signals={signals}
                                  signalDispatch={signalDispatch}
                                  isParentDragging={false}
                                  disabled={!allowedToModify}
                                />
                              </InitialFeatureContainer>
                            ) : (
                              <StyledEmptyInput
                                node="initial"
                                allowedInput={[]}
                                initial={true}
                                sendChanges={updateFeature}
                                featureConfiguration={
                                  this.props.featureConfiguration == null
                                    ? undefined
                                    : this.props.featureConfiguration.functions
                                }
                                isParentDragging={false}
                              />
                            )}
                          </FeatureTemplateContext.Provider>
                        </DragAndDrop>
                      </CardContent>
                    </Card>
                  </CardRow>
                  <CardSpacer />
                  <TrashBinOverlay />
                  <CardRow>
                    <Card
                      titleId={'values_preview'}
                      headerContent={
                        <FeatureValuesChartOptionsPopup
                          startDateTime={begin.current}
                          endDateTime={end.current}
                          onApplyPressed={(start, end) => {
                            this.saveFeatureTimes(
                              start,
                              end,
                              this.requestFeatureValues
                            );
                          }}
                        />
                      }
                    >
                      {featureValuesLoading && (
                        <CenteredCardContent>
                          <DetailLoader />
                        </CenteredCardContent>
                      )}
                      {!featureValuesLoading &&
                        (!featureValuesError &&
                        !invalidConfiguration &&
                        hasFeatureValues ? (
                          <CardContent>
                            {featureValues.map((featureValue, index) => {
                              return (
                                <SignalValueChartRow
                                  key={index}
                                  index={index}
                                  signalStatistic={featureValue}
                                  startTimeFrame={begin.current}
                                  endTimeFrame={end.current}
                                  zoomedStartTimestamp={begin.current}
                                  zoomedEndTimestamp={end.current}
                                  timeFormat={featureValuesChartTimeFormat}
                                  ticks={ticks}
                                  onZoomChanged={() => {}}
                                  onRemovePressed={() => {}}
                                  chartOnly
                                />
                              );
                            })}
                          </CardContent>
                        ) : (
                          <FeatureErrorInfo
                            featureValuesError={featureValuesError}
                            invalidConfiguration={invalidConfiguration}
                          />
                        ))}
                    </Card>
                  </CardRow>
                </FlexibleCardColumn>
              </DndProvider>
            </CardRow>
          </MultiCardLayout>
        </ScreenContent>
      </ScreenRoot>
    );
  }
}

export default injectIntl(FeatureCreationScreen);
