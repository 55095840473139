import React, { useState } from 'react';
import styled from 'styled-components';
import {
  LeftAlignedTableCell,
  StyledFirstCell,
  StyledRow,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import {
  GlossaryGroupedEntry,
  KeyValue,
} from '../../../../model/domain/translation/glossary';
import {
  Button,
  RightAlignedButtons,
  TightlyPackedButtons,
} from '@dev/base-web/dist/view/components/global/button';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import EditTranslationsList from './edit_translations_list';
import { Input } from './styled_components';
import { FormattedMessage } from 'react-intl';
import { InputErrorHint } from '@dev/base-web/dist/view/components/inputs/input_error';
import { InputOnChangeData, Popup } from 'semantic-ui-react';

const StyledEventRow = styled(StyledRow)`
  height: 48px;
  cursor: auto;
`;

const LowerRowCell = styled(LeftAlignedTableCell)`
  &&&&& {
    vertical-align: top;
    padding-top: 14px !important;
    overflow: visible;
  }
`;

const StyledLeftAlignedTableCell = styled(LeftAlignedTableCell)<{
  isEdit?: boolean;
}>`
  &&&& {
    vertical-align: top;
    padding-top: ${({ isEdit }) =>
      !isEdit ? '24px !important' : '14px !important'};
  }
`;

const StyledFirstTopCell = styled(StyledFirstCell)`
  &&&& {
    vertical-align: top;
    padding-top: 24px !important;
  }
`;

interface GlossaryRowProps {
  readonly data: GlossaryGroupedEntry;
  readonly isEditActive: boolean;
  readonly setEditActive: () => void;
  readonly onValueChanged: (label: GlossaryGroupedEntry) => void;
  readonly removeGlossaryEntry: () => void;
  readonly isEditDisabled: boolean;
}

const GlossaryRow: React.FC<GlossaryRowProps> = ({
  data,
  isEditActive,
  setEditActive,
  removeGlossaryEntry,
  onValueChanged,
  isEditDisabled,
}) => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const translationList = data.translations.map((v) => v.value).join(', ');

  const sortAndSetTranslations = (entries: KeyValue[]) => {
    // setLocalTranslations(
    //   entries.sort((a, b) => (a.language > b.language ? 1 : -1))
    // );
    onValueChanged({ ...data, translations: entries });
  };

  return (
    <StyledEventRow>
      <StyledFirstTopCell>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ marginBottom: 8 }}>
            <FormattedMessage id={data.sourceLanguage.toLowerCase()} />
          </p>
          {isEditActive && (
            <Popup
              basic
              open={deleteConfirmOpen}
              trigger={
                <Button
                  type={'tertiary'}
                  icon={'delete'}
                  size={'small'}
                  onClick={() => setDeleteConfirmOpen(true)}
                />
              }
              hoverable
              style={{
                borderRadius: '4px',
                boxShadow:
                  '0 0 4px 0 rgba(0, 0, 0, 0.08), 0 9px 20px 0 rgba(0, 0, 0, 0.09)',
                padding: 0,
                border: 'none',
              }}
              onClose={() => setDeleteConfirmOpen(false)}
            >
              <div style={{ margin: 16 }}>
                <p style={{ marginBottom: 12 }}>
                  <FormattedMessage id={'delete_item_confirm'} />
                </p>
                <RightAlignedButtons>
                  <TightlyPackedButtons>
                    <Button
                      type={'primary'}
                      label={'delete'}
                      onClick={() => {
                        removeGlossaryEntry();
                        setDeleteConfirmOpen(false);
                      }}
                    />
                    <Button
                      type={'tertiary'}
                      label={'cancel'}
                      onClick={() => setDeleteConfirmOpen(false)}
                    />
                  </TightlyPackedButtons>
                </RightAlignedButtons>
              </div>
            </Popup>
          )}
        </div>
      </StyledFirstTopCell>
      <StyledLeftAlignedTableCell isEdit={isEditActive}>
        {isEditActive ? (
          <Input
            key={data.id}
            value={data.key}
            error={!data.key}
            onChange={(
              _event: React.ChangeEvent<HTMLInputElement>,
              change: InputOnChangeData
            ) => {
              onValueChanged({ ...data, key: change.value });
            }}
          />
        ) : (
          <TextWithEllipsisAndTooltip text={data.key} />
        )}
        {data.isKeyDuplicate && <InputErrorHint id={'duplicate_found'} />}
      </StyledLeftAlignedTableCell>
      <LowerRowCell>
        {isEditActive ? (
          <EditTranslationsList
            sourceLanguage={data.sourceLanguage}
            translations={data.translations}
            setTranslations={sortAndSetTranslations}
          />
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              value={translationList}
              onClick={() => setEditActive()}
              disabled={isEditDisabled}
            />
            <Button
              type={'tertiary'}
              icon={'edit'}
              size={'small'}
              onClick={() => setEditActive()}
              disabled={isEditDisabled}
            />
          </div>
        )}
      </LowerRowCell>
    </StyledEventRow>
  );
};

export default GlossaryRow;
