import styled from 'styled-components';
import { TimePicker } from '@dev/base-web/dist/view/components/inputs/time_picker';

export const CenteredTabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 36px;
`;

export const ScheduleDateTimePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  .SingleDatePicker {
    width: 212px;
  }

  .SingleDatePicker .SingleDatePickerInput {
    width: 212px;
  }

  .SingleDatePicker .SingleDatePickerInput .DateInput {
    width: 212px;
  }
`;

export const DurationLabel = styled.span`
  font-size: 14px;
  height: 40px;
  margin-right: 48px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: #a6a6a6;
`;

export const HintText = styled.p`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.43;
  color: #939393;
`;

export const TextWrapper = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
  justify-content: center;
`;

export const StyledTimePicker = styled(TimePicker)`
  margin-top: 12px;

  .rc-time-picker-input,
  .rc-time-picker-panel-input {
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    width: 212px;
    height: 42px;
    color: #242424;
    font-family: Relative;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;

    ::placeholder {
      font-family: Relative;
      font-size: 14px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #a6a6a6;
    }
  }

  .rc-time-picker-panel-select {
    color: #242424;
    font-family: Relative;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
  }
  .rc-time-picker-panel-select {
    width: 112px;
  }

  .rc-time-picker-panel-select li {
    text-align: center;
    padding: 0px;
  }
`;

export const HintContainer = styled.div`
  padding-top: 48px;
`;
