import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { CommentDTO } from '../../../../model/redux/actions/interface';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { renderUserAvatar } from '../../../common/common';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import InstructionSideCard from './instruction_side_card';

const CommentListCard = styled(InstructionSideCard)`
  width: 100%;
  //max-height: 360px;
  position: relative;
`;

export const ListItem = styled.div<{ last: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 16px 6px;

  ${(props) => !props.last && 'box-shadow: inset 0 -1px 0 0 #efefef;'}

  span {
    width: 230px;
  }
`;

const UpperContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 12px;
`;

const EmptyComment = styled.div`
  margin: 24px 0;
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.43;
  letter-spacing: normal;
  color: #939393;
`;

const UserInfo = styled.div`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  line-height: 1.14;
  letter-spacing: normal;
  color: #8c8c8c;
`;

const UserText = styled.span`
  padding-left: 8px;
`;

interface InstructionCommentsSideCardProps {
  readonly title: string;
  readonly comments: CommentDTO[];
  readonly onDeleteClicked: (id: string) => void;
  readonly canDelete: boolean;
}

const InstructionCommentsSideCard: React.FC<
  InstructionCommentsSideCardProps
> = ({ title, comments, onDeleteClicked, canDelete }) => {
  const { formatRelative } = useLocalizedDateFormatters();
  const renderHistoryDate = (timeStamp: number) => {
    return `(${formatRelative(timeStamp, Date.now())})`;
  };

  return (
    <CommentListCard title={title} disableContainerMargin>
      {comments.length > 0 ? (
        comments.map((item, index) => (
          <ListItem key={index} last={comments.length - 1 === index}>
            <UpperContent>
              <span>
                <FormattedMessage id={item.description} />
              </span>
              <Button
                type="tertiary"
                icon="delete"
                onClick={() => onDeleteClicked(item.id)}
                disabled={!canDelete}
              />
            </UpperContent>
            <UserInfo>
              {renderUserAvatar(item.user)}
              <UserText>
                {item.user.firstName
                  ? `${item.user.firstName} ${
                      item.user.lastName
                    }  ${renderHistoryDate(item.timestamp)}`
                  : `${item.user.username}`}
              </UserText>
            </UserInfo>
          </ListItem>
        ))
      ) : (
        <EmptyComment>
          <span>
            <FormattedMessage id={'no_comments'} />
          </span>
        </EmptyComment>
      )}
    </CommentListCard>
  );
};

export default InstructionCommentsSideCard;
