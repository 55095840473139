import React from 'react';
import styled from 'styled-components';
import ColoredKPIComponent from '@dev/base-web/dist/view/components/kpi/colored_kpi';
import { MetricsDTO } from '../../../../model/redux/actions/interface';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 12px 0 12px;
  & > * {
    padding-right: 8px;
    padding-left: 8px;
  }
  & > *:last-child {
    padding-right: 0;
  }
  & > *:first-child {
    padding-left: 0;
  }
`;

const InstructionFigures = (props: { figures?: MetricsDTO }) => {
  const { figures } = props;

  const transformedFigures = [
    { count: 0, label: 'access_count' },
    {
      count: 0,
      label: 'positive_votes',
      status: 'positive',
    },
    {
      count: 0,
      label: 'negative_votes',
      status: 'negative',
    },
  ];

  if (figures) {
    transformedFigures[0].count = figures.accessCount;
    transformedFigures[1].count = figures.upVotes;
    transformedFigures[2].count = figures.downVotes;
  }

  return (
    <Wrapper>
      {transformedFigures.map((figure, index) => {
        const { status, label, count } = figure;

        const colorValue = (status && (status === 'positive' ? 1 : 18)) || -1;
        const icon = status === 'positive' ? 'votepositiv' : 'votenegativ';

        return (
          <ColoredKPIComponent
            key={index}
            title={label}
            value={count}
            showIcon={!!status}
            customIcon={icon}
            colorValue={colorValue}
            showGradient={true}
          />
        );
      })}
    </Wrapper>
  );
};

export default InstructionFigures;
