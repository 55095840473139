import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import {
  EventInterface,
  EventType,
} from '@dev/base-web/dist/model/domain/event/event_type';
import EmptyOrLoadingView from '@dev/base-web/dist/view/components/global/no_data';
import DetailLoader from '@dev/base-web/dist/view/components/global/detail_loader';
import CurrentEventItem from './events_list_item';
import { CardInfiniteScroll } from '@dev/base-web/dist/view/components/global/card';
import { LoadType } from '@dev/base-web/dist/model/redux/events/interface';
import { useNavigate } from 'react-router';
import { dummyItem } from './dummy_item';
import { EventStatus } from '@/screens/current_events/view';
import usePolling from '@dev/base-web/dist/view/helpers/use_polling';

export interface EventsListProps {
  readonly height: number;
  readonly className?: string;
  readonly selectedEvent?: EventInterface;
  readonly onEventSelect: (id: string) => void;
  readonly filters?: readonly FilterData[];
  readonly events: readonly EventInterface[];
  readonly hasMoreResults: boolean;
  readonly loadingInProgress: LoadType | undefined;
  readonly loadError: unknown;
  readonly topGradientHeight?: number;
  readonly bottomGradientHeight?: number;
  readonly loadEvents: (filters: readonly FilterData[]) => Promise<void>;
  readonly reloadEvents: (filters: readonly FilterData[]) => Promise<void>;
  readonly loadMoreEvents?: (filters: readonly FilterData[]) => Promise<void>;
  readonly tourActive?: boolean;
  readonly isLoadingDisabled?: boolean;
  readonly tab?: EventStatus;
}

const EmptyViewWrapper = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  display: flex;
  align-items: center;
`;

const RelativeWrapper = styled.div`
  position: relative;
`;

const RefreshingOverlay = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const EMPTY: readonly FilterData[] = [];
const NO_ACTION: (filters: readonly FilterData[]) => Promise<void> = () =>
  Promise.resolve();

const CardEventsList: React.FC<EventsListProps> = ({
  height,
  tab,
  filters = EMPTY,
  events,
  hasMoreResults,
  loadingInProgress,
  loadError,
  loadEvents,
  reloadEvents,
  loadMoreEvents = NO_ACTION,
  onEventSelect,
  selectedEvent,
  tourActive,
  isLoadingDisabled,
  ...props
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [scheduleNextPage, setScheduleNextPage] = useState(false);

  const reschedule = usePolling(
    async () => {
      if (scheduleNextPage) {
        setScheduleNextPage(false);
        await loadMoreEvents(filters);
      } else if (events.length > 0) {
        await reloadEvents(filters);
      } else {
        await loadEvents(filters);
      }
    },
    5000,
    [filters, intl.locale, isLoadingDisabled, tab]
  );

  if (loadError || events.length === 0) {
    return (
      <EmptyViewWrapper height={height}>
        <EmptyOrLoadingView
          loadingInProgress={loadingInProgress !== undefined}
          hasError={!!loadError}
          hasResults={events.length > 0}
          noDataAvailableText={'no_active_events'}
        />
      </EmptyViewWrapper>
    );
  }

  return (
    <RelativeWrapper>
      {loadingInProgress === 'reload' && (
        <RefreshingOverlay>
          <DetailLoader />
        </RefreshingOverlay>
      )}
      <CardInfiniteScroll
        dataLength={events.length}
        next={() => {
          setScheduleNextPage(true);
          reschedule(true);
        }}
        hasMore={hasMoreResults}
        height={height}
        onScroll={() => {
          // postpone next polling a bit on user interaction
          // to make the UX more "streamlined"
          reschedule(false);
        }}
        {...props}
      >
        {events.map((item) => {
          return (
            <CurrentEventItem
              unselectable={
                tourActive && item.type === EventType.RECOMMENDATION
              }
              item={item}
              key={item.id}
              onClick={onEventSelect}
              selected={selectedEvent && item.id === selectedEvent.id}
            />
          );
        })}
        {tourActive && (
          <CurrentEventItem
            item={dummyItem}
            key={dummyItem.id}
            onClick={() => navigate('/currentEvents/dum')}
            selected={false}
          />
        )}
      </CardInfiniteScroll>
    </RelativeWrapper>
  );
};

export default CardEventsList;
