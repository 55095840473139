import {
  DecisionOptionDTO,
  StepDTO,
  DisplayedStep,
  DisplayedDecision,
  SelectedDecision,
} from './step';

export const rebuildDecisions = (
  decisions: readonly DecisionOptionDTO[],
  parentStep: StepDTO,
  decision: DecisionOptionDTO,
  newStep: StepDTO
) => {
  if (decisions.length === 0) {
    return decisions;
  }

  const index = decisions.indexOf(decision);

  if (index < 0) {
    return decisions.map((d) => ({
      ...d,
      steps: rebuildStepsRecursiveAdd(d.steps, newStep, parentStep, decision),
    }));
  }

  const pre = decisions.slice(0, index);
  const post = decisions.slice(index + 1);

  const editedDecision: DecisionOptionDTO = {
    ...decision,
    steps: [...decision.steps, newStep],
  };

  return [...pre, editedDecision, ...post];
};

export const removeIdFromDecisions = (
  stepLevel: readonly StepDTO[]
): readonly StepDTO[] => {
  return stepLevel.map((step) => ({
    ...step,
    decisionOptions: step.decisionOptions.map(
      (decision: DecisionOptionDTO) => ({
        name: decision.name,
        translations: decision.translations,
        steps: removeIdFromDecisions(decision.steps),
      })
    ),
  }));
};

export const deleteStep = (
  stepLevel: readonly StepDTO[],
  steper: StepDTO
): readonly StepDTO[] => {
  const index = stepLevel.indexOf(steper);

  if (index < 0) {
    return stepLevel.map((step) => ({
      ...step,
      decisionOptions: step.decisionOptions.map((decision) => ({
        ...decision,
        steps: deleteStep(decision.steps, steper),
      })),
    }));
  } else {
    const pre = stepLevel.slice(0, index);
    const post = stepLevel.slice(index + 1);

    if (pre.length > 0) {
      const lastItem = pre[pre.length - 1];

      if (
        lastItem.decisionOptions.length === 0 &&
        steper.decisionOptions.length !== 0
      ) {
        const newStep = { ...lastItem };
        const preMinusLast = pre.slice(0, pre.length - 1);

        return [...preMinusLast, newStep, ...post];
      }
    }

    return [...pre, ...post];
  }
};

export const moveStep = (
  stepLevel: readonly StepDTO[],
  steper: StepDTO,
  direction: string
): readonly StepDTO[] => {
  const index = stepLevel.indexOf(steper);

  if (index < 0) {
    return stepLevel.map((step) => ({
      ...step,
      decisionOptions: step.decisionOptions.map((decision) => ({
        ...decision,
        steps: moveStep(decision.steps, steper, direction),
      })),
    }));
  } else {
    // const pre = stepLevel.slice(0, index);
    // const post = stepLevel.slice(index + 1);

    if (stepLevel.length === 1) {
      return stepLevel;
    }

    switch (direction) {
      case 'up':
        if (index === 0) {
          return stepLevel;
        } else {
          const pre = stepLevel.slice(0, index - 1);
          const post = stepLevel.slice(index + 1);

          return [...pre, stepLevel[index], stepLevel[index - 1], ...post];
        }
      case 'down':
        if (index === stepLevel.length - 1) {
          return stepLevel;
        } else {
          const pre = stepLevel.slice(0, index);
          const post = stepLevel.slice(index + 2);

          return [...pre, stepLevel[index + 1], stepLevel[index], ...post];
        }
    }
    return stepLevel;
  }
};

export const rebuildStepsRecursiveAddWithoutDecision = (
  stepLevel: readonly StepDTO[],
  oldStep: StepDTO,
  newStep: StepDTO
): readonly StepDTO[] => {
  const index = stepLevel.indexOf(oldStep);

  if (index < 0) {
    return stepLevel.map((step) => ({
      ...step,
      decisionOptions: step.decisionOptions.map((decision) => ({
        ...decision,
        steps: rebuildStepsRecursiveAddWithoutDecision(
          decision.steps,
          oldStep,
          newStep
        ),
      })),
    }));
  } else {
    const steps = stepLevel.map((item) => ({ ...item }));

    return [...steps, { ...newStep }];
  }
};

export const rebuildStepsRecursiveAdd = (
  stepLevel: readonly StepDTO[],
  newStep: StepDTO,
  parentStep: StepDTO,
  decision: DecisionOptionDTO
): readonly StepDTO[] => {
  if (stepLevel.length === 0) {
    return stepLevel;
  }

  const index = stepLevel.indexOf(parentStep);

  if (index < 0) {
    return stepLevel.map((step): StepDTO => {
      if (step.type === 'INSTRUCTION' && step.decisionOptions.length > 0) {
        return {
          ...step,
          decisionOptions: rebuildDecisions(
            step.decisionOptions,
            parentStep,
            decision,
            newStep
          ),
        };
      }

      return step;
    });
  }

  const pre = stepLevel.slice(0, index);
  const post = stepLevel.slice(index + 1);

  const editedParentStep: StepDTO = {
    ...parentStep,
    decisionOptions: rebuildDecisions(
      parentStep.decisionOptions,
      parentStep,
      decision,
      newStep
    ),
  };

  return [...pre, editedParentStep, ...post];
};

const isCompleted = (decision: DecisionOptionDTO): boolean => {
  if (decision.steps.length === 0) {
    return false;
  }

  return decision.steps.reduce((res: boolean, s: StepDTO) => {
    if (s.type === 'COMMUNICATION' || s.decisionOptions.length === 0) {
      return res;
    }

    return (
      res &&
      s.decisionOptions.reduce(
        (dres: boolean, d: DecisionOptionDTO) => dres && isCompleted(d),
        res
      )
    );
  }, true);
};

export const toDisplayedUntilDecision = (
  stepLevel: readonly StepDTO[],
  decisionIds: readonly SelectedDecision[],
  parent?: DisplayedDecision
): readonly DisplayedStep[] => {
  const results: DisplayedStep[] = [];

  for (const [index, step] of stepLevel.entries()) {
    if (step.type === 'INSTRUCTION' && step.decisionOptions.length > 0) {
      const decisions: readonly DisplayedDecision[] = step.decisionOptions.map(
        (d) => ({
          orig: d,
          isSelected: decisionIds.some(
            (sd) => sd.stepId === step.id && sd.decisionId === d.id
          ),
          isComplete: isCompleted(d),
        })
      );

      results.push({
        orig: step,
        isComplete: decisions.reduce(
          (res: boolean, d: DisplayedDecision) => res && d.isComplete,
          true
        ),
        decisionOptions: decisions,
        localIndex: index,
        parent: parent,
      });

      const selected = decisions.find((d) => d.isSelected);

      if (selected) {
        return results.concat(
          toDisplayedUntilDecision(selected.orig.steps, decisionIds, selected)
        );
      }
    } else {
      results.push({
        orig: step,
        isComplete: true,
        decisionOptions: [],
        localIndex: index,
        parent: parent,
      });
    }
  }

  return results;
};

export const changeSelectedDecisions = (
  decisions: readonly SelectedDecision[],
  newDecision: SelectedDecision
): readonly SelectedDecision[] => {
  const index = decisions.findIndex(
    (d) => d.stepNumber === newDecision.stepNumber
  );

  if (index < 0) {
    // this is a newly selected decision for a step that has no decision selected before
    // the only way it can happen if it's the last step on the screen (with decisions).
    return [...decisions, newDecision];
  } else {
    const pre = decisions.slice(0, index);

    if (decisions[index].decisionId === newDecision.decisionId) {
      // the same decision is selected for a step that has been selected before
      // this means unselect, we just throw away the decision and the decisions in the subtree
      return pre;
    } else {
      // other decision is selected for a step that has a decision already selected before
      // we throw away the decisions of the substeps that were in the old decision's subtree, and add the new decision
      return [...pre, newDecision];
    }
  }
};

export const getRemovableDecisionIds = (
  steps: readonly StepDTO[]
): string[] => {
  const removableDecisionIds: string[] = [];

  for (const step of steps) {
    for (const decisionOption of step.decisionOptions) {
      if (!decisionOption.steps.length && decisionOption.id)
        removableDecisionIds.push(decisionOption.id);
      else {
        const nestedIds = getRemovableDecisionIds(decisionOption.steps);
        removableDecisionIds.push(...nestedIds);
      }
    }
  }

  return removableDecisionIds;
};
