import { createReducersForLoadingList } from '@dev/base-web/dist/model/redux/helpers/reducers';
import { combineReducers, Reducer } from 'redux';
import { PARALLEL_EVENTS_ENTRY_KEY } from './actions';
import ParallelEventsEntry from '../../domain/parallel_events/parallel_events';
import { ParallelEventsStateInterface } from './interface';

const parallelEventsState: Reducer<ParallelEventsStateInterface> =
  combineReducers({
    parallelEvents: createReducersForLoadingList<ParallelEventsEntry>(
      PARALLEL_EVENTS_ENTRY_KEY
    ),
  });

export default parallelEventsState;
