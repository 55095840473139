import { useEffect, useMemo, useState } from 'react';
import {
  InstructionStepTranslation,
  InstructionWithTranslation,
} from '@/model/domain/instruction/translation.ts';
import { instructionTranslationToInstruction } from '@/model/domain/instruction/translation_helper.ts';
import { isInstructionValid } from '@/model/domain/instruction/helper.ts';
import { rebuildStepsRecursiveModify } from '@/model/domain/instruction/translation_tree_helper.ts';

const useInstructionTranslationEditing = (
  instructionTranslation: InstructionWithTranslation | null
) => {
  const [localInstructionTranslation, setLocalInstructionTranslation2] =
    useState<InstructionWithTranslation>();
  const [verifiedLanguages, setVerifiedLanguages] = useState<string[]>([]);
  const [selectedTranslationsToVerify, setSelectedTranslationsToVerify] =
    useState<string[]>([]);

  const setLocalInstructionTranslation = (
    i: InstructionWithTranslation | undefined
  ) => {
    setLocalInstructionTranslation2(i);
  };

  useEffect(() => {
    if (instructionTranslation) {
      setLocalInstructionTranslation(instructionTranslation);
    } else setLocalInstructionTranslation(undefined);
  }, [instructionTranslation]);

  const changeTitleTranslations = (selectedLanguage: string, title: string) => {
    if (localInstructionTranslation) {
      const tr = localInstructionTranslation.nameTranslations.find(
        (t) => t.targetLanguage === selectedLanguage
      );

      if (tr) {
        setLocalInstructionTranslation({
          ...localInstructionTranslation,
          nameTranslations: [
            ...localInstructionTranslation.nameTranslations.filter(
              (t) => t.targetLanguage !== selectedLanguage
            ),
            {
              ...tr,
              targetLanguageText: title,
            },
          ],
        });
      }
    }
  };

  const updateSteps = (steps: readonly InstructionStepTranslation[]) => {
    if (localInstructionTranslation)
      setLocalInstructionTranslation({
        ...localInstructionTranslation,
        stepTranslations: steps,
      });
  };

  const modifyStepTranslations = (
    oldStep: InstructionStepTranslation,
    newStep: InstructionStepTranslation,
    parent?: string
  ) => {
    if (
      localInstructionTranslation &&
      localInstructionTranslation.stepTranslations
    ) {
      updateSteps(
        rebuildStepsRecursiveModify(
          localInstructionTranslation.stepTranslations,
          oldStep,
          newStep,
          parent
        )
      );
    }
  };

  const isTranslationSavable = useMemo(() => {
    return !!(
      localInstructionTranslation &&
      (selectedTranslationsToVerify.length === 1 ||
        verifiedLanguages.length === selectedTranslationsToVerify.length) &&
      isInstructionValid(
        instructionTranslationToInstruction(localInstructionTranslation)
      )
    );
  }, [
    localInstructionTranslation,
    verifiedLanguages,
    selectedTranslationsToVerify,
  ]);

  return {
    localInstructionTranslation,
    changeTitleTranslations,
    modifyStepTranslations,
    isTranslationSavable,
    verifiedLanguages,
    setVerifiedLanguages,
    selectedTranslationsToVerify,
    setSelectedTranslationsToVerify,
  };
};

export default useInstructionTranslationEditing;
