import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { EllipsisContainer } from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import { SubsequentEventDTO } from '@/model/domain/event/event_definition.ts';
import { Popup } from 'semantic-ui-react';
import { getEventTypeLookForType } from '@dev/base-web/dist/view/components/global/common';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import { TerminatedMarker } from './terminated_marker';
import { EventStatusType } from '@dev/base-web/dist/model/domain/event/event_type';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';

const hiddenListStyle = css`
  visibility: hidden;
  opacity: 0;
`;

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.5s linear;

  ${(props) => props.hidden && hiddenListStyle}

  li {
    box-shadow: inset 0 -1px 0 0 #efefef;
  }
  li:last-child {
    box-shadow: none;
  }
`;

const SubEventInfo = styled.p`
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
`;

const CircleIcon = styled.div<{
  isActive: boolean;
}>`
  margin: 0 8px;
  width: 6px;
  height: 6px;
  border-radius: 24px;
  background-color: ${(props) => (props.isActive ? '#0da6a0' : '#000000')};
`;

const SubEventAlign = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const PopupAlignContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Item = styled.li`
  width: 100%;
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  display: block;
  min-height: 48px;
  padding: 0px 0;
`;

export const H2 = styled.span`
  font-family: Relative;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  display: inline-block;
  padding: 0;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const P = styled.p`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.brownGrey};
  margin: 0;
`;

export const TimeAndDate = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.brownGrey};
`;

const CommentList = styled.div`
  padding-left: 10px;
`;

interface Props {
  subEvents: SubsequentEventDTO[] | undefined;
}

const SubsequentEventsList: React.FC<Props> = ({ subEvents }) => {
  const [isActive, setIsActive] = useState(false);

  const intl = useIntl();
  const theme = useTheme();
  const { formatDate } = useLocalizedDateFormatters();

  return (
    <CommentList>
      <H2 onClick={() => setIsActive(!isActive)}>
        <SubEventAlign>
          <FormattedMessage id="sub_events" />
          <SubEventInfo>
            {subEvents &&
              `(${subEvents.filter((e) => !e.endTimestamp).length}/${
                subEvents.length
              } `}{' '}
            <FormattedMessage id="active" />
            {')'}
          </SubEventInfo>
        </SubEventAlign>
        <Icon
          name="arrow-right-light"
          color={theme.colors.primary}
          size={16}
          iconStyle={{
            transform: `rotate(${isActive ? '90deg' : '0deg'})`,
            transition: 'transform 0.2s',
            marginRight: '12px',
          }}
        />
      </H2>
      <List hidden={!isActive}>
        {subEvents && subEvents.length > 0 ? (
          subEvents.map((e) => {
            const { color, icon } = getEventTypeLookForType(e.type, intl);
            return (
              <Item
                key={e.id}
                //onClick={() => this.props.onClickEvent(e.id as string)}
              >
                <Popup
                  wide={true}
                  position={'top left'}
                  hoverable
                  trigger={
                    <SubEventAlign>
                      <CircleIcon isActive={e.endTimestamp ? false : true} />
                      <div>
                        <EllipsisContainer
                          lines={1}
                          style={{ marginRight: '10px' }}
                        >
                          {e.name}
                        </EllipsisContainer>
                        <TimeAndDate>
                          {`(${formatDate(e.startTimestamp, 'Ppp')} -
                          ${
                            e.endTimestamp
                              ? formatDate(e.endTimestamp, 'Ppp')
                              : '...'
                          })`}
                        </TimeAndDate>
                      </div>
                      {e.status === EventStatusType.TERMINATED && (
                        <div
                          style={{
                            margin: '0 8px',
                            paddingTop: 6,
                            alignItems: 'center',
                            height: '100%',
                          }}
                        >
                          <Icon
                            name="warning"
                            size={16}
                            color={theme.colors.pumpkin}
                            iconStyle={{ marginRight: 4 }}
                          />
                        </div>
                      )}
                    </SubEventAlign>
                  }
                >
                  <PopupAlignContent>
                    <SubEventAlign>
                      <Icon
                        iconStyle={{ marginRight: 5, minWidth: 16 }}
                        color={color}
                        name={icon}
                        size={16}
                      />
                      <P
                        style={{
                          color: theme.colors.black,
                          fontStyle: 'normal',
                          fontWeight: 700,
                        }}
                      >
                        {e.name}
                      </P>
                    </SubEventAlign>
                    <SubEventAlign>
                      <Icon
                        name={'location'}
                        color={'#939393'}
                        size={16}
                        iconStyle={{ marginRight: 5, minWidth: 16 }}
                      />
                      <ManufacturingEntityHierarchyElement
                        manufacturingEntity={e.manufacturingEntity}
                        hideHiddenTypes
                        hideTooltip
                      />
                    </SubEventAlign>
                    <SubEventAlign>
                      <Icon
                        name={'time'}
                        color={'#939393'}
                        size={16}
                        iconStyle={{ marginRight: 5, minWidth: 16 }}
                      />
                      {`${formatDate(e.startTimestamp, 'Ppp')} -
                          ${
                            e.endTimestamp
                              ? formatDate(e.endTimestamp, 'Ppp')
                              : '...'
                          }`}
                    </SubEventAlign>
                    {e.status === EventStatusType.TERMINATED && (
                      <TerminatedMarker tooltip />
                    )}
                  </PopupAlignContent>
                </Popup>
              </Item>
            );
          })
        ) : (
          <P>
            <FormattedMessage id="no_subsequent_events" />
          </P>
        )}
      </List>
    </CommentList>
  );
};

export default SubsequentEventsList;
