import { parallelEventsAPI } from '../../api';
import { Dispatch } from '../store';
import { RootReducerInterface } from '../interfaces';
import { Actions } from './actions';
import { TimeSpan } from '@dev/base-web/dist/model/domain/common/time_span';

export const getParallelEvents =
  (timespan: TimeSpan) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(Actions.parallelEvents.meta.startLoading());

    try {
      const result = await parallelEventsAPI.getByTimeSpan(
        token.accessToken,
        timespan
      );
      dispatch(Actions.parallelEvents.loadingListSuccessful(result));
    } catch (error: unknown) {
      dispatch(
        Actions.parallelEvents.meta.loadingFailed({
          error: error,
        })
      );
    } finally {
      dispatch(Actions.parallelEvents.meta.endLoading());
    }
  };
