import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';
import {
  GlossaryCreate,
  GlossaryDetail,
  GlossaryEntry,
} from '../../domain/translation/glossary';

const GLOSSARY_BASE = `/translation/glossary`;

export default class TranslationApi extends BaseDataApi<
  GlossaryDetail,
  GlossaryEntry,
  GlossaryCreate,
  GlossaryCreate
> {
  async getGlossaries(
    token: string,
    signal: AbortSignal
  ): Promise<GlossaryDetail[]> {
    return this.fetchAllData(GLOSSARY_BASE, token, signal);
  }

  async getGlossary(
    id: string,
    token: string,
    signal?: AbortSignal
  ): Promise<GlossaryEntry> {
    return this.fetchOneItemByIdWithUrl(
      token,
      `${GLOSSARY_BASE}/${id}`,
      signal
    );
  }

  async addGlossary(data: GlossaryCreate, token: string) {
    await this.createItem(data, GLOSSARY_BASE, token);
  }

  async deleteGlossary(id: string, token: string) {
    await this.deleteItem(`${GLOSSARY_BASE}/${id}`, token);
  }
}
