import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  Button,
  StyledButtonBase,
} from '@dev/base-web/dist/view/components/global/button';
import { StyledTextArea } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { SolutionFeedbackProps } from './interface';

const disLikeBgColor = '#fef0f2';
const disLikeBorderColor = '#fcdfe2';

const FeedbackContainer = styled.div``;

const Info = styled.div`
  color: ${({ theme }) => theme.colors.warmGrey};
`;

const VoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  margin: 11px 0px 13px 0px;
`;

const NegativeVote = styled.div`
  &&&&&& > ${StyledButtonBase} {
    color: ${({ theme }) => theme.colors.cherryRed};
    background-color: ${disLikeBgColor};
    border: solid 1px ${disLikeBorderColor};

    & > svg {
      fill: ${({ theme }) => theme.colors.cherryRed} !important;
    }

    &:hover {
      color: ${disLikeBgColor};
      background-color: ${disLikeBorderColor};

      & > svg {
        fill: ${disLikeBgColor} !important;
      }
    }

    &:active,
    &.active {
      color: ${disLikeBgColor};
      background-color: ${({ theme }) => theme.colors.cherryRed};

      & > svg {
        fill: ${disLikeBgColor} !important;
      }
    }

    &.disabled {
      color: ${({ theme }) => theme.colors.cherryRed};
      background-color: ${disLikeBgColor};
      opacity: ${({ theme }) => theme.buttons.primary.disabled.opacity};

      & > svg {
        fill: ${({ theme }) => theme.colors.cherryRed} !important;
      }
    }
  }
`;

export const SolutionFeedbackComponent: React.FC<SolutionFeedbackProps> = ({
  isPositiveVote,
  feedbackText,
  onSubmit,
}) => {
  const intl = useIntl();
  const [vote, setVote] = useState<boolean | null>(isPositiveVote);
  const [description, setDescription] = useState<string | null>(feedbackText);
  const [loading, setLoading] = useState<boolean>(false);
  const belowSubmitButtonRef = useRef<null | HTMLDivElement>(null);

  const noVote = vote === null;
  const feedBackUnchanged =
    description === feedbackText &&
    feedbackText !== null &&
    vote === isPositiveVote &&
    isPositiveVote !== null;

  useEffect(() => {
    setVote(isPositiveVote);
    setDescription(feedbackText);
    setLoading(false);
  }, [isPositiveVote, feedbackText]);

  const onVotePressed = (isPositive: boolean) => {
    setVote(isPositive);
    setTimeout(
      () =>
        belowSubmitButtonRef.current?.scrollIntoView({ behavior: 'smooth' }),
      0
    );
  };

  const submitFeedback = async () => {
    if (vote !== null && !feedBackUnchanged) {
      try {
        setLoading(true);
        await onSubmit(vote, description ?? '');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <FeedbackContainer>
      <Info>
        <FormattedMessage id="action_feedback_question" />
      </Info>
      <VoteContainer>
        <Button
          type="secondary"
          icon="votepositiv"
          label="yes"
          active={vote === true}
          disabled={loading}
          onClick={() => onVotePressed(true)}
        />
        <NegativeVote>
          <Button
            type="secondary"
            icon="votenegativ"
            label="no"
            active={vote === false}
            disabled={loading}
            onClick={() => onVotePressed(false)}
          />
        </NegativeVote>
      </VoteContainer>
      {(feedbackText || vote !== null) && (
        <>
          <StyledTextArea
            name="description"
            disabled={loading}
            placeholder={intl.formatMessage({
              id: 'improvement_feedback_question',
            })}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setDescription(e.target.value)
            }
            value={description}
          />
          <Button
            type="primary"
            label="send"
            disabled={noVote || feedBackUnchanged}
            loading={loading}
            onClick={() => void submitFeedback()}
          />
        </>
      )}
      <div ref={belowSubmitButtonRef} />
    </FeedbackContainer>
  );
};
