import { DisplayedStep, StepDTO } from '../instruction/step';

export default interface CompletionChoice {
  readonly text: string;
}

export const extractTitleFromSuggetion = (
  suggestion: string
): string | undefined => {
  const titleMatch = suggestion.match(/^Title:\s*(.+)$/m);
  return titleMatch ? titleMatch[1].trim() : undefined;
};

export const suggestionToSteps = (
  suggestion: string,
  language: string
): StepDTO[] => {
  let localSuggestion = suggestion;

  // ignore first line with title (begins with "Title:") if present. Also ignore first like if it doesn't start with "1."
  if (localSuggestion.startsWith('Title:') || !/^\d+\./.test(localSuggestion)) {
    localSuggestion = localSuggestion.substring(
      localSuggestion.indexOf('\n') + 1
    );
  }

  let suggestionSplit = localSuggestion
    .replace(/^\d+\.\s*/, '')
    .split(/\n\d+\.\s*/);

  // sometimes LLMs return some greetings at the end (even if we ask them to not do it). Trying to filter it out by assuming
  // that the relevant information is before the first line break.
  suggestionSplit = suggestionSplit.map((s) => {
    const split = s.split('\n');

    if (split.length) return split[0];
    else return s;
  });

  return suggestionSplit
    .filter((s) => s.trim() !== '')
    .map((s, idx) => {
      return {
        type: 'INSTRUCTION',
        step: idx + 1,
        media: null,
        translations: [
          { language: language.toUpperCase(), translation: s.trim() },
        ],
        decisionOptions: [],
        additionalInfos: [],
      };
    });
};

export const suggestionToDisplayedStep = (
  suggestion: string,
  language: string
): DisplayedStep[] => {
  const steps = suggestionToSteps(suggestion, language);

  return steps.map((s) => {
    return {
      orig: s,
      isComplete: true,
      decisionOptions: [],
    };
  });
};
