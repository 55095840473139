import { Button } from '@dev/base-web/dist/view/components/global/button';
import DeleteConfirmationModal from '@dev/base-web/dist/view/components/modal/delete_confirmation_modal';
import { EditMode } from './interface';
import { Menu, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const evList = {
  /// THIS is a Popper Modifier --> Since Semantic UI Popup runs on Popper, the modifiers can change it's behavior.
  name: 'eventListeners',
  phase: 'afterWrite',
  options: {
    scroll: false,
    resize: true,
  },
};

const MenuHeader = styled(Menu.Header)`
  &&&&& {
    padding: 12px 12px 6px 12px !important;
    width: 200px;
    font-family: Relative;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #242424;
  }
`;

const MenuItem = styled(Menu.Item)`
  &&&&& {
    padding: 12px 12px !important;
    width: 100%;
    font-family: Relative;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #242424;
  }
`;

export interface FunctionButtonsProps {
  readonly mode: EditMode | undefined;
  readonly disabled?: boolean;
  readonly onRotatePressed: () => void;
  readonly onCropPressed: () => void;
  readonly onDrawPressed: () => void;
  readonly onDeletePressed: () => void;
  readonly onRevertPressed?: (asNew: boolean) => void;
  readonly canEdit: boolean;
  readonly canDelete: boolean;
}

const FunctionButtons = ({
  mode,
  disabled = false,
  onRotatePressed,
  onCropPressed,
  onDeletePressed,
  onDrawPressed,
  canEdit,
  canDelete,
  onRevertPressed,
}: FunctionButtonsProps) => {
  return (
    <>
      <Button
        type="secondary"
        size="small"
        icon="rotate"
        onClick={onRotatePressed}
        active={mode === EditMode.ROTATE}
        disabled={disabled || !canEdit}
        tooltipLabel={'rotate_hint'}
        tooltipDelay={700}
      />
      <Button
        type="secondary"
        size="small"
        icon="crop"
        onClick={onCropPressed}
        active={mode === EditMode.CROP}
        disabled={disabled || !canEdit}
        tooltipLabel={'crop_hint'}
        tooltipDelay={700}
      />
      <Button
        type="secondary"
        size="small"
        icon="draw"
        onClick={onDrawPressed}
        active={mode === EditMode.DRAW}
        disabled={disabled || !canEdit}
        tooltipLabel={'draw_hint'}
        tooltipDelay={700}
      />
      {onRevertPressed && !mode && (
        <Popup
          trigger={
            <Button
              type="secondary"
              size="small"
              icon="revert"
              disabled={disabled || !canEdit}
              //tooltipLabel={'revert_to_initial_hint'}
              //tooltipDelay={700}
            />
          }
          basic
          popperModifiers={[evList]}
          style={{
            borderRadius: '4px',
            boxShadow:
              '0 0 4px 0 rgba(0, 0, 0, 0.08), 0 9px 20px 0 rgba(0, 0, 0, 0.09)',
            padding: 0,
            border: 'none',
          }}
        >
          <Menu
            compact
            //borderless
            //onItemClick={() => props.setOpen(false)}
            vertical
            style={{ padding: 0, border: 'none', boxShadow: 'none' }}
          >
            <MenuHeader>
              <FormattedMessage id={'revert_to_initial_hint'} />
            </MenuHeader>
            <MenuItem
              onClick={() => onRevertPressed(false)}
              style={{
                cursor: 'pointer',
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: 'none',
                width: '100%',
              }}
            >
              <FormattedMessage id={'overwrite_existing'} />
            </MenuItem>
            <MenuItem
              onClick={() => onRevertPressed(true)}
              style={{
                cursor: 'pointer',
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: 'none',
                width: '100%',
              }}
            >
              <FormattedMessage id={'create_new'} />
            </MenuItem>
          </Menu>
        </Popup>
      )}
      <DeleteConfirmationModal
        title="delete_image"
        confirmationTitle="delete_image_confirmation"
        onDeleteConfirmed={onDeletePressed}
        triggerButton={
          <Button
            type="secondary"
            size="small"
            icon="delete"
            disabled={disabled || !canDelete}
          />
        }
        triggerDisabled={disabled || !canDelete}
      />
    </>
  );
};

export default FunctionButtons;
