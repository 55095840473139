import { UserActionCondition } from '../../../../model/domain/event/event_definition';
import React from 'react';
import Function from '@dev/base-web/dist/view/components/feature/feature_config_card/function_label_card';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';

interface UserActionConditionCardProps {
  readonly condition: UserActionCondition;
}

const StyledFeatureCard = styled.div<{ odd?: boolean }>`
  width: 85%;
  display: flex;
  flex-direction: column;
  background-color: ${({ odd }) => (odd ? '#e8ebea' : '#f7f7f7')};
  justify-content: center;
  min-width: 72px;
  display: flex;
  border-radius: 8px;
  border: solid 1px #dfdfdf;
  font-family: Relative;
  padding: 2px 16px 2px 16px;
  transition: opacity 0.2s;
  cursor: pointer;
  padding-bottom: 12px;
`;

const FunctionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  margin-top: 6px;
  margin-bottom: 20px;
`;

const UserActionConditionCard: React.FC<UserActionConditionCardProps> = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <StyledFeatureCard odd={false}>
        <FunctionWrapper>
          <Function value={'user_action'} draggable={false} />
          <div></div>
        </FunctionWrapper>
        <Hint messageKey={'user_action_hint'} />
      </StyledFeatureCard>
    </DndProvider>
  );
};

export default UserActionConditionCard;
