import React, { Component } from 'react';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { Span } from './event_description';
import { TextWrapper, HintText } from './styled_components';
import { FormattedMessage } from 'react-intl';

class UserAction extends Component {
  render() {
    return (
      <Span>
        <TextWrapper>
          <Icon name="hand" color="#242424" size={24} />
          <HintText style={{ marginLeft: '8px', textAlign: 'left' }}>
            {<FormattedMessage id="user_action_hint" />}
          </HintText>
        </TextWrapper>
      </Span>
    );
  }
}

export default UserAction;
