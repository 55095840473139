import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import styled from 'styled-components';
import InstructionSideCard from './instruction_side_card';

interface Props {
  readonly languageCodes: string[];
  readonly verifiedLanguages: string[];
  readonly onLanguageChanged: (languageCode: string) => void;
}

const ButtonContainer = styled.div`
  &&&&&&&&&& button {
    min-width: 100%;
    justify-content: space-between;
    margin: 4px 0;
  }
`;

const InstructionSideTranslationsCard: React.FC<Props> = ({
  languageCodes,
  verifiedLanguages,
  onLanguageChanged,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>();

  useEffect(() => {
    if (!selectedLanguage) {
      const firstNotVerifiedLanguage = languageCodes.find(
        (language) => !verifiedLanguages.includes(language)
      );
      if (firstNotVerifiedLanguage) {
        setSelectedLanguage(firstNotVerifiedLanguage);
      }
    }
  }, [verifiedLanguages]);

  const intl = useIntl();

  const title = intl.formatMessage(
    { id: 'translations_updated' },
    {
      translationsUpdated: languageCodes.filter((lang) =>
        verifiedLanguages.includes(lang)
      ).length,
      numberOfTranslations: languageCodes.length,
    }
  );

  return (
    <>
      <InstructionSideCard title={title}>
        {languageCodes.map((languageCode) => {
          const isSelected = languageCode === selectedLanguage;
          const isVerified = verifiedLanguages.includes(languageCode);
          const verifiedIcon = isVerified ? 'checkmark' : '';
          const icon = isSelected ? 'edit' : verifiedIcon;
          const iconColor = isVerified && !isSelected ? 'primary' : undefined;
          const type = isSelected ? 'primary' : 'secondary';
          const label = intl.formatMessage({ id: languageCode.toLowerCase() });

          return (
            <ButtonContainer>
              <Button
                key={languageCode}
                type={type}
                label={label}
                icon={icon}
                iconPosition="right"
                iconColor={iconColor}
                onClick={() => {
                  setSelectedLanguage(languageCode);
                  onLanguageChanged(languageCode);
                }}
              />
            </ButtonContainer>
          );
        })}
      </InstructionSideCard>
      <br />
      <br />
    </>
  );
};

export default InstructionSideTranslationsCard;
