import React, { useMemo } from 'react';
import { Checkbox } from '@dev/base-web/dist/view/components/global/checkbox';
import {
  CenterAlignedTableCell,
  LeftAlignedTableCell,
  StyledFirstCell,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import { UserPrivilege } from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import styled from 'styled-components';
import {
  DefaultRoles,
  Permission,
  RolePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/role_permission';
import { FormattedMessage } from 'react-intl';
import { Table } from 'semantic-ui-react';
import { getIdForCategory } from './UserPriviligesTableConfig';

const TripleCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100px;
`;

export const StyledRow = styled(Table.Row)`
  border-radius: 15px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
`;

interface UserPrivilegeRowProps {
  isSuperAdmin: boolean;
  userPrivilege: UserPrivilege;
  onPrivilegeChanged: (privilege: UserPrivilege) => void;
}

const createCellForRole = (
  role: RolePermission,
  onPermissionUpdated: (
    role: RolePermission,
    permission: Permission,
    value: boolean
  ) => void,
  disabled = false
) => {
  return (
    <CenterAlignedTableCell>
      <TripleCheckboxContainer>
        <Checkbox
          onChange={() => onPermissionUpdated(role, 'read', !role.read)}
          checked={role.read ?? false}
          disabled={disabled}
        />
        <Checkbox
          onChange={() => onPermissionUpdated(role, 'write', !role.write)}
          checked={role.write ?? false}
          disabled={disabled}
        />
        <Checkbox
          onChange={() => onPermissionUpdated(role, 'delete', !role.delete)}
          checked={role.delete ?? false}
          disabled={disabled}
        />
      </TripleCheckboxContainer>
    </CenterAlignedTableCell>
  );
};

const UserTableRow: React.FC<UserPrivilegeRowProps> = ({
  userPrivilege,
  onPrivilegeChanged,
  isSuperAdmin,
}) => {
  const { category, permission, rolePermissions } = userPrivilege;

  const onPermissionUpdated = (
    role: RolePermission,
    permission: Permission,
    value: boolean
  ) => {
    const newRole = { ...role, [permission]: value };
    const newRolePermissions = rolePermissions;
    const index = newRolePermissions.findIndex(
      (roleP) => roleP.role === role.role
    );

    // Splicing doesn't change the order of the items.
    newRolePermissions.splice(index, 1, newRole);

    onPrivilegeChanged({
      ...userPrivilege,
      rolePermissions: newRolePermissions,
    });
  };

  // These 3 roles are there for sure.
  const adminRole = useMemo(() => {
    return rolePermissions.find(
      (role) => role.role === 'ADMIN'
    ) as RolePermission;
  }, [userPrivilege]);

  const maintainerRole = useMemo(() => {
    return rolePermissions.find(
      (role) => role.role === 'MAINTAINER'
    ) as RolePermission;
  }, [userPrivilege]);

  const operatorRole = useMemo(() => {
    return rolePermissions.find(
      (role) => role.role === 'OPERATOR'
    ) as RolePermission;
  }, [userPrivilege]);

  const botRole = useMemo(() => {
    return rolePermissions.find(
      (role) => role.role === 'BOT'
    ) as RolePermission;
  }, [userPrivilege]);

  const supportRole = useMemo(() => {
    return rolePermissions.find(
      (role) => role.role === 'SUPPORT'
    ) as RolePermission;
  }, [userPrivilege]);

  const otherRoles = rolePermissions.filter(
    (role) => !DefaultRoles.includes(role.role)
  );

  return (
    <StyledRow>
      <StyledFirstCell>
        <FormattedMessage id={getIdForCategory(category)} />
      </StyledFirstCell>
      <LeftAlignedTableCell>
        <FormattedMessage id={permission ? permission.toLowerCase() : '-'} />
      </LeftAlignedTableCell>
      {createCellForRole(adminRole, onPermissionUpdated, !isSuperAdmin)}
      {createCellForRole(maintainerRole, onPermissionUpdated, !isSuperAdmin)}
      {createCellForRole(operatorRole, onPermissionUpdated, !isSuperAdmin)}
      {createCellForRole(botRole, onPermissionUpdated, !isSuperAdmin)}
      {createCellForRole(supportRole, onPermissionUpdated, !isSuperAdmin)}
      {otherRoles.map((role) => createCellForRole(role, onPermissionUpdated))}
    </StyledRow>
  );
};

export default UserTableRow;
