import { FormattedMessage, useIntl } from 'react-intl';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { Dropdown, MenuItem } from 'semantic-ui-react';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
  DropdownWithLabelContainer,
  LabelContainer,
} from '../styled_components';
import styled from 'styled-components';
import { EventCoreInterface } from '@dev/base-web/dist/model/domain/event/event_type';
import { DowntimeReason } from '@dev/base-web/dist/model/domain/report/DowntimeReason';
import Downtime from '@dev/base-web/dist/model/domain/report/Downtime';
import DowntimeEvent from '@dev/base-web/dist/model/domain/report/DowntimeEvent';
import SelectRawEventsDialog from './select_raw_events_dialog';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import ShiftReportOverview from '@dev/base-web/dist/model/domain/report/ShiftReportOverview';
import { getManufacturingEntityTitle } from '@dev/base-web/dist/view/helpers/common';

const MainDropdownMenu = styled(Dropdown.Menu)`
  &&&&&&& {
    overflow: visible;
  }
`;

const NestedStyledDropdown = styled(StyledDropDown)`
  &&& {
    padding: 0.78571429rem 1.14285714rem !important;
    i.angle.right.icon {
      position: absolute;
      right: 4px;
      top: 12px;
      color: #767676 !important;
    }
  }
`;

const NestedDropdownMenu = styled(Dropdown.Menu)`
  &&&&&&& {
    border-top-width: 1px !important;
  }
`;

const NestedDropdownItem = styled(MenuItem)`
  &&&&& {
    padding: 0 !important;
  }
`;

const DropdownOption = styled.div`
  padding: 2px 7px 2px 7px;
`;

const useDistanceFromViewPortRight = (
  ref: MutableRefObject<null | HTMLDivElement>
) => {
  const [distanceFormViewPortRight, setDistanceFormViewPortRight] =
    useState<number>(window.innerWidth);

  function handleResize() {
    if (ref.current) {
      setDistanceFormViewPortRight(
        window.innerWidth - ref.current.getBoundingClientRect().right
      );
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [ref.current]);

  return distanceFormViewPortRight;
};

interface DowntimeCauseSelectorProps {
  readonly shiftReportManufacturingEntity: ManufacturingEntity;
  readonly report?: ShiftReportOverview;
  readonly selectedEvent?: EventCoreInterface;
  readonly selectedOtherReason?: DowntimeReason;
  readonly selectedDowntimeLoadingInProgress: boolean;
  readonly downtimeReasonsLoadingInProgress: boolean;
  readonly selectedDowntime: Downtime | null;
  readonly downtimeReasons: readonly DowntimeReason[];
  readonly onPossibleEventSelected: (event: DowntimeEvent | undefined) => void;
  readonly onOtherReasonSelected: (reason: DowntimeReason | undefined) => void;
  readonly onClicked?: () => void;
  readonly disabled: boolean;
}

const DowntimeCauseSelector = ({
  shiftReportManufacturingEntity,
  report,
  selectedEvent,
  selectedOtherReason,
  downtimeReasonsLoadingInProgress,
  selectedDowntimeLoadingInProgress,
  selectedDowntime,
  downtimeReasons,
  onOtherReasonSelected,
  onPossibleEventSelected,
  onClicked,
  disabled,
}: DowntimeCauseSelectorProps) => {
  const intl = useIntl();
  const ref = useRef<null | HTMLDivElement>(null);
  const distanceFormViewPortRight = useDistanceFromViewPortRight(ref);

  const [eventsModalOpen, setEventsModalOpen] = useState<boolean>(false);

  const selectedOtherReasonName = selectedOtherReason
    ? intl.formatMessage({
        id: selectedOtherReason.name.toLowerCase(),
        defaultMessage: selectedOtherReason.name,
      })
    : undefined;

  return (
    <DropdownWithLabelContainer ref={ref}>
      <LabelContainer>
        <FormattedMessage id={'what_was_the_cause'} />
      </LabelContainer>
      <StyledDropDown
        fluid
        clearable
        selection
        text={selectedEvent?.name ?? selectedOtherReasonName ?? '-'}
        loading={selectedDowntimeLoadingInProgress}
        onClick={onClicked}
        disabled={disabled}
      >
        <MainDropdownMenu>
          <Dropdown.Item
            value={undefined}
            text={'-'}
            onClick={() => {
              onOtherReasonSelected(undefined);
              onPossibleEventSelected(undefined);
            }}
          />
          {selectedDowntime &&
            selectedDowntime.possibleEvents.map((event) => (
              <Dropdown.Item
                value={event.id}
                text={`${event.name} (${getManufacturingEntityTitle(
                  event.manufacturingEntity
                )})`}
                selected={event.id === selectedEvent?.id}
                onClick={() => onPossibleEventSelected(event)}
              />
            ))}
          <NestedDropdownItem selected={!!selectedOtherReason}>
            <NestedStyledDropdown
              text={intl.formatMessage({ id: 'other' })}
              pointing={distanceFormViewPortRight > 300 ? 'left' : 'right'}
              icon="angle right"
              item
              fluid
              loading={downtimeReasonsLoadingInProgress}
            >
              <NestedDropdownMenu>
                {downtimeReasons.map((reason) => (
                  <Dropdown.Item
                    value={reason.name}
                    text={intl.formatMessage({
                      id: reason.name.toLowerCase(),
                      defaultMessage: reason.name,
                    })}
                    onClick={() => onOtherReasonSelected(reason)}
                    selected={selectedOtherReason?.name === reason.name}
                  />
                ))}
              </NestedDropdownMenu>
            </NestedStyledDropdown>
          </NestedDropdownItem>
          <DropdownOption>
            <Dropdown.Item
              children={
                <Button
                  type="tertiary"
                  label="choose_event"
                  size="small"
                  noMargin
                />
              }
              selected={false}
              onClick={() => setEventsModalOpen(true)}
            />
          </DropdownOption>
        </MainDropdownMenu>
      </StyledDropDown>
      {selectedDowntime && (
        <SelectRawEventsDialog
          modalOpen={eventsModalOpen}
          onClose={() => setEventsModalOpen(false)}
          throughputTime={report?.throughputTime || undefined}
          begin={selectedDowntime.startTimestamp}
          end={selectedDowntime.endTimestamp}
          selectedManufacturingEntity={shiftReportManufacturingEntity}
          selectedEvent={selectedEvent}
          onSelectEvent={(e) => {
            if (e.event.id) {
              onPossibleEventSelected({
                id: e.event.id,
                name: e.event.name,
                type: e.event.type,
                manufacturingEntity: e.manufacturingEntity,
                causedDowntime: true,
                possibleActions: [],
              });
              setEventsModalOpen(false);
            }
          }}
        />
      )}
    </DropdownWithLabelContainer>
  );
};

export default DowntimeCauseSelector;
