import styled from 'styled-components';
import { StyledInput } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export const LanguageField = styled.div<{ isFilter?: boolean }>`
  width: 50px;
  height: ${({ isFilter }) =>
    isFilter ? '30px !important' : '38px !important'};
  border-radius: 0 4px 4px 0;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-left: none;
  background-color: #ffffff;
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: ${({ isFilter }) => (isFilter ? 1 : 1.43)};
  letter-spacing: normal;
  color: #242424;
  padding: 8px 22px 8px 8px;
`;

export const Input = styled(StyledInput)<{ withoutBorder?: boolean }>`
  width: 100%;
  margin-right: 4px;
  &&&& {
    opacity: 1;
  }

  &&&&&&&&&& input {
    border-top-right-radius: ${({ withoutBorder }) =>
      withoutBorder ? 0 : undefined};
    border-bottom-right-radius: ${({ withoutBorder }) =>
      withoutBorder ? 0 : undefined};
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
`;

export const LanguageTitle = ({ language }: { language: string }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <p style={{ fontWeight: 'bold' }}>{language.toUpperCase()}</p>:{' '}
      <FormattedMessage id={language} />
    </div>
  );
};
