import {
  OpeningCondition,
  OpeningConditionType,
  OpeningFeatureCondition,
  ScheduledCondition,
} from '../../../../model/domain/event/event_definition';
import React from 'react';
import FeatureCard from './feature_card';
import ScheduledConditionCard from './scheduled_condition_card';
import EmptyConditionCard from './empty_condition_card';
import { FeatureColors } from '@dev/base-web/dist/view/components/feature/styles';
import FeatureConfigurationInstance from '@dev/base-web/dist/model/domain/feature/feature_configuration';

interface OpeningConditionCardProps {
  readonly condition: OpeningCondition | null;
  readonly odd?: boolean;
  readonly replaceColors?: FeatureColors;
  readonly featureConfiguration: FeatureConfigurationInstance | null;
}

const OpeningConditionCard: React.FC<OpeningConditionCardProps> = ({
  condition,
  odd,
  replaceColors,
  featureConfiguration,
}) => {
  return (
    <>
      {condition === null ||
      (condition.type === OpeningConditionType.FEATURE &&
        !(condition as OpeningFeatureCondition).value) ? (
        <EmptyConditionCard />
      ) : condition.type === OpeningConditionType.FEATURE ? (
        <FeatureCard
          featureId={(condition as OpeningFeatureCondition).featureId}
          featureValue={(condition as OpeningFeatureCondition).value}
          odd={odd}
          replaceColors={replaceColors}
          featureConfiguration={featureConfiguration}
        />
      ) : condition.type === OpeningConditionType.SCHEDULED ? (
        <ScheduledConditionCard
          condition={condition as ScheduledCondition}
          replaceColor={replaceColors && replaceColors.even}
        />
      ) : (
        <EmptyConditionCard />
      )}
    </>
  );
};

export default OpeningConditionCard;
