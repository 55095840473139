import { Actions } from './actions';
import { RootReducerInterface } from '../interfaces';
import { instructionApi } from '../../api';
import { VoidThunk } from '@dev/base-web/dist/model/redux/helpers/thunks';

export const getCurrentInstruction =
  (eventId: string, actionId: string): VoidThunk<RootReducerInterface> =>
  async (dispatch, getState) => {
    const token =
      getState().authenticationState.authentication.token.accessToken;

    dispatch(Actions.currentInstruction.meta.startLoading());

    try {
      const response = await instructionApi.getCurrentInstruction(
        token,
        eventId,
        actionId
      );
      dispatch(Actions.currentInstruction.loadingItemSuccessful(response));
    } catch (error) {
      dispatch(Actions.currentInstruction.meta.loadingFailed({ error }));
    }

    dispatch(Actions.currentInstruction.meta.endLoading());
  };

export const cleanCurrentInstruction =
  (): VoidThunk<RootReducerInterface> => (dispatch) =>
    dispatch(Actions.currentInstruction.reset());
