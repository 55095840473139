import { combineReducers, Reducer } from 'redux';
import { createReducersForExecutingOperation } from '@dev/base-web/dist/model/redux/helpers/reducers';
import { SEND_FEEDBACK } from './actions';
import { FeedbackStateInterface } from './interface';

const feedbackState: Reducer<FeedbackStateInterface> = combineReducers({
  sendFeedback: createReducersForExecutingOperation(SEND_FEEDBACK),
});

export default feedbackState;
