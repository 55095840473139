import React from 'react';
import styled from 'styled-components';
import DeleteConfirmationModal from '@dev/base-web/dist/view/components/modal/delete_confirmation_modal';
import { Image as Img } from '../../../model/redux/actions/interface';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import Media from '../../Media';
import { FunctionContainer, FunctionWrapper } from './styled_components';
import { useIntl } from 'react-intl';
import { toastrWarning } from '@dev/base-web/dist/view/helpers/notification_helpers';
import { MediaErrorType } from '@dev/base-web/dist/view/components/global/media';
import { useAuthorizationCheckRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import { SizeMe } from 'react-sizeme';
import PdfViewer from '../../../screens/edit_instruction/components/media/pdf_viewer';

const StyledMedia = styled(Media)`
  margin-bottom: 5px;
  height: calc(50vh - 200px);
`;

const FullSize = styled.div`
  width: 100%;
  height: 90%;
  padding-left: 12px;
`;

const ButtonContainer = styled.div`
  margin-right: 12px;
  margin-top: -25px;
  width: 100%;
`;

export const EditMedia: React.FC<{
  item: Img;
  onDelete: (imgUrl: string) => void;
}> = ({ item, onDelete }) => {
  const intl = useIntl();

  const onVideoError = (e: any) => {
    if (
      e.title === 'BAD_REQUEST' ||
      e.type === MediaErrorType.VIDEO_PLAYING_ERROR
    )
      toastrWarning(intl.formatMessage({ id: 'video_still_processing' }));
    else toastrWarning(intl.formatMessage({ id: 'video_processing_error' }));
  };

  const { delete: canDelete } = useAuthorizationCheckRWD(
    UserPrivilegePermission.ACTION,
    ApplicationCategory.SHANNON
  );

  console.log(item.urls.fileType);

  return (
    <>
      <FullSize>
        {item.urls.fileType === 'VIDEO' ? (
          <StyledMedia value={item.urls} onError={onVideoError} showErrorHint />
        ) : (
          <SizeMe
            monitorWidth
            monitorHeight
            refreshRate={128}
            refreshMode={'debounce'}
          >
            {({ size }) => (
              <PdfViewer
                urls={item.urls}
                scale={0.4}
                height={size.height!}
                pageButtonsTop
                deleteComponent={
                  <DeleteConfirmationModal
                    title="delete_pdf"
                    confirmationTitle="delete_pdf_confirmation"
                    onDeleteConfirmed={() => onDelete(item.urls.full)}
                    triggerButton={
                      <ButtonContainer>
                        <Button
                          type="secondary"
                          size={'small'}
                          icon="delete"
                          disabled={!canDelete}
                        />
                      </ButtonContainer>
                    }
                    triggerDisabled={!canDelete}
                  />
                }
              />
            )}
          </SizeMe>
        )}
      </FullSize>
      {item.urls.fileType === 'VIDEO' && (
        <FunctionWrapper>
          <FunctionContainer>
            <DeleteConfirmationModal
              title="delete_image"
              confirmationTitle="delete_image_confirmation"
              onDeleteConfirmed={() => onDelete(item.urls.full)}
              triggerButton={
                <Button
                  type="secondary"
                  size="small"
                  icon="delete"
                  disabled={!canDelete}
                />
              }
              triggerDisabled={!canDelete}
            />
          </FunctionContainer>
        </FunctionWrapper>
      )}
    </>
  );
};
