import { WrappedComponentProps } from 'react-intl';
import { InstructionOverview } from '@/model/domain/instruction/instruction.ts';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { ErrorInterface } from '@dev/base-web/dist/model/api/error/error_interface';
import { OperationType } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import SearchData from '@dev/base-web/dist/model/api/common/search_data';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';

export const INSTRUCTIONS_ROUTE_KEY = 'actions';

export interface InstructionsStoreProps {
  readonly instructions: InstructionOverview[];
  readonly moreInstructionsCanBeLoaded: boolean;
  readonly instructionLoadingInProgress: boolean;
  readonly instructionLoadingError?: ErrorInterface;
  readonly instructionsOperation: OperationType | undefined;
  readonly instructionsOperationError: ErrorInterface | undefined;
  readonly instructionsOperationLoading: boolean;
  readonly previousInstructionsSearchData?: SearchData;
  readonly showHierarchy?: boolean | null;
  readonly totalElements?: number;
}

export interface InstructionsDispatchProps {
  readonly getInstructions: (
    isApproved: boolean,
    isArchived: boolean,
    page: number,
    filters: readonly FilterData[],
    sortingKey?: string,
    sorting?: SortingDirection,
    loadAllPagesUntilTheGivenOne?: boolean
  ) => void;
  readonly archiveInstructions: (ids: string[], isHidden: boolean) => void;
  readonly deleteInstructions: (ids: string[]) => void;
  readonly cleanInstruction: () => void;
}

export type InstructionsProps = InstructionsStoreProps &
  InstructionsDispatchProps &
  WrappedComponentProps;
