import { Card } from '@dev/base-web/dist/view/components/global/card';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import useDowntimeReportEditing from './use_downtime_report_editing';
import DowntimeCauseSelector from './downtime_cause_selector';
import DowntimeSolutionSelector from './downtime_solution_selector';
import DowntimeEditButton from './downtime_edit_button';
import React from 'react';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';
import { ManufacturingEntityType } from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { LabelContainer } from '../styled_components';
import DowntimeCommentSelector from './downtime_comment_selector';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import DowntimeOverview from '@dev/base-web/dist/model/domain/report/DowntimeOverview';
import Downtime from '@dev/base-web/dist/model/domain/report/Downtime';
import { DowntimeReason } from '@dev/base-web/dist/model/domain/report/DowntimeReason';
import DowntimeEvent from '@dev/base-web/dist/model/domain/report/DowntimeEvent';
import DowntimeAction from '@dev/base-web/dist/model/domain/report/DowntimeAction';
import EventDetail from '../../../../../model/domain/event/event';
import ShiftReportOverview from '@dev/base-web/dist/model/domain/report/ShiftReportOverview';

const CardContent = styled.div`
  //width: 500px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  margin-bottom: 16px;
`;

const CardDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CardInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 300px;
`;

const DataContainer = styled.div`
  margin-bottom: 16px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

interface OpenDowntimeCardProps {
  readonly reportId: string;
  readonly report?: ShiftReportOverview;
  readonly downtimeOverview: DowntimeOverview;
  readonly selected: boolean;
  readonly onSelected: () => void;
  readonly getDowntime: (reportId: string, downTimeId: string) => void;
  readonly selectedDowntime: Downtime | null;
  readonly selectedDowntimeLoadingInProgress: boolean;
  readonly showManufacturingEntityHierarchy: boolean;
  readonly downtimeReasons: readonly DowntimeReason[];
  readonly downtimeReasonsLoadingInProgress: boolean;
  readonly updateDowntimeInShiftReport: (
    newCause?: DowntimeEvent,
    newAction?: DowntimeAction,
    newOtherReason?: DowntimeReason,
    newComment?: string,
    downtimeOverView?: DowntimeOverview
  ) => void;
  readonly downtimeUpdateInProgress: boolean;
  readonly editable: boolean;
  readonly getSelectedEvent: (eventId: string) => void;
  readonly selectedEventData: EventDetail | null;
}

const OpenDowntimeCard = ({
  downtimeOverview,
  onSelected,
  selected,
  getDowntime,
  selectedDowntime,
  reportId,
  report,
  selectedDowntimeLoadingInProgress,
  showManufacturingEntityHierarchy,
  updateDowntimeInShiftReport,
  downtimeReasons,
  downtimeReasonsLoadingInProgress,
  downtimeUpdateInProgress,
  editable,
  getSelectedEvent,
  selectedEventData,
}: OpenDowntimeCardProps) => {
  const {
    startTimestamp,
    duration,
    causingEvent,
    chosenActionName,
    id,
    shiftReportManufacturingEntity,
  } = downtimeOverview;

  const theme = useTheme();
  const { formatDate, formatDuration } = useLocalizedDateFormatters();

  const formattedStartShift = formatDate(startTimestamp, 'Ppp');
  const formattedDownTime = formatDuration(duration, 'HH:mm:ss');
  const {
    selectedEvent,
    selectedOtherReason,
    selectedActionName,
    selectedDowntimeAction,
    selectedPossibleEvent,
    comment,
    onRowPressed,
    onPossibleEventSelected,
    onOtherReasonSelected,
    onActionSelected,
    onCommentChanged,
  } = useDowntimeReportEditing(
    downtimeOverview,
    chosenActionName,
    selectedDowntime,
    selected,
    getDowntime,
    reportId,
    id,
    onSelected,
    getSelectedEvent,
    selectedEventData
  );

  return (
    <Card>
      <TitleContainer>
        <StyledIcon
          name={'error'}
          color={theme.colors.kpi.txtNegative}
          size={16}
        />
        <FormattedMessage id={'downtime'} />
      </TitleContainer>
      <CardContent>
        <CardDataContainer>
          <LabelContainer>
            <StyledIcon
              name={'location'}
              size={16}
              color={theme.colors.warmGrey}
            />
            <FormattedMessage id={'location'} />
          </LabelContainer>
          <DataContainer>
            {causingEvent && causingEvent.manufacturingEntity ? (
              <ManufacturingEntityHierarchyElement
                manufacturingEntity={causingEvent.manufacturingEntity}
                hideHiddenTypes
                showShortNames
                hiddenTypes={[
                  ManufacturingEntityType.FACTORY,
                  ManufacturingEntityType.AREA,
                  ManufacturingEntityType.LINE,
                ]}
                showHierarchy={showManufacturingEntityHierarchy}
                lines={2}
              />
            ) : (
              '-'
            )}
          </DataContainer>
          <LabelContainer>
            <StyledIcon name={'time'} size={16} color={theme.colors.warmGrey} />
            <FormattedMessage id={'date'} />
          </LabelContainer>
          <DataContainer>{formattedStartShift}</DataContainer>
          <LabelContainer>
            <StyledIcon name={'time'} size={16} color={theme.colors.warmGrey} />
            <FormattedMessage id={'downtime'} />
          </LabelContainer>
          <DataContainer> {formattedDownTime}</DataContainer>
        </CardDataContainer>
        <CardInputContainer>
          <DataContainer>
            <DowntimeCauseSelector
              shiftReportManufacturingEntity={shiftReportManufacturingEntity}
              report={report}
              downtimeReasons={downtimeReasons}
              downtimeReasonsLoadingInProgress={
                downtimeReasonsLoadingInProgress
              }
              selectedDowntime={selectedDowntime}
              selectedDowntimeLoadingInProgress={
                selectedDowntimeLoadingInProgress
              }
              selectedEvent={selectedEvent}
              onOtherReasonSelected={onOtherReasonSelected}
              onPossibleEventSelected={onPossibleEventSelected}
              selectedOtherReason={selectedOtherReason}
              onClicked={onRowPressed}
              disabled={!editable}
            />
          </DataContainer>
          <DataContainer>
            <DowntimeSolutionSelector
              selectedActionName={selectedActionName}
              onActionSelected={onActionSelected}
              selectedPossibleEvent={selectedPossibleEvent}
              disabled={!editable}
            />
          </DataContainer>
          <DataContainer>
            <DowntimeCommentSelector
              comment={comment}
              onCommentChanged={onCommentChanged}
              disabled={!editable}
            />
          </DataContainer>
          <ButtonContainer>
            <DowntimeEditButton
              updateDowntimeInShiftReport={(
                newCause,
                newAction,
                newOtherReason,
                newComment
              ) =>
                updateDowntimeInShiftReport(
                  newCause,
                  newAction,
                  newOtherReason,
                  newComment,
                  downtimeOverview
                )
              }
              selectedPossibleEvent={selectedPossibleEvent}
              selectedOtherReason={selectedOtherReason}
              selectedDowntimeAction={selectedDowntimeAction}
              selectedEvent={selectedEvent}
              loading={downtimeUpdateInProgress}
              comment={comment}
            />
          </ButtonContainer>
        </CardInputContainer>
      </CardContent>
    </Card>
  );
};

export default OpenDowntimeCard;
