import { Actions } from './actions';
import { RootReducerInterface } from '../../interfaces';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { Dispatch } from 'redux';
import { kpiOverTimeApi } from '@/model/api';
import {
  DataApiSortConfig,
  SortingDirection,
} from '@dev/base-web/dist/model/api/common/data_api_sort_config';

export const getKPIResults =
  (
    page: number,
    groupBy: string,
    filters?: readonly FilterData[],
    sortKey?: string,
    sortingDirection?: SortingDirection
  ) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const abortController = new AbortController();
    const { token } = getState().authenticationState.authentication;

    dispatch(Actions.kpiResults.meta.startLoading({ abortController }));

    try {
      let sortConfig: DataApiSortConfig | undefined;
      if (sortKey && sortingDirection) {
        sortConfig = new DataApiSortConfig(sortKey, sortingDirection);
      }

      const result = await kpiOverTimeApi.getKPIResults(
        token.accessToken,
        abortController.signal,
        page,
        groupBy,
        filters,
        sortConfig
      );

      dispatch(Actions.kpiResults.resetList({ result }));
      dispatch(
        Actions.kpiResults.meta.endLoading({ page, filters, sortConfig })
      );
    } catch (error) {
      dispatch(Actions.kpiResults.meta.loadingFailed({ error }));
    }

    dispatch(Actions.kpiResults.meta.endLoading());
  };
