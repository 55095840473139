import React from 'react';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import useFilterState from './filter_hook';
import { DropdownProps } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { FilterProps } from './interfaces';

const UsageFilter: React.FC<FilterProps> = (props) => {
  const { localFilters, setLocalFilters, externalFilters } = props;

  const intl = useIntl();
  const [isTrue, setIsTrue] = useFilterState<boolean>(
    'isUsed',
    { groupingKey: 'usage', icon: 'star' },
    (value) =>
      value
        ? intl.formatMessage({ id: 'used' })
        : intl.formatMessage({ id: 'not_used' }),
    localFilters,
    setLocalFilters,
    externalFilters
  );

  const analysisTypeOptions = [
    { value: true, text: intl.formatMessage({ id: 'used' }) },
    { value: false, text: intl.formatMessage({ id: 'not_used' }) },
  ];

  return (
    <StyledDropDown
      fluid
      clearable
      selection
      value={isTrue}
      options={analysisTypeOptions}
      onChange={(
        _event: React.SyntheticEvent<HTMLElement>,
        data: DropdownProps
      ) => {
        setIsTrue(data.value as boolean);
      }}
    />
  );
};

export default UsageFilter;
