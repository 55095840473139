import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import InstructionItem, {
  DisplayedInstructionFields,
} from './instruction_item';
import { useAuthorizationCheckRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import SuggestInstructionModal from '../Card/EventCard/components/suggest_instruction_modal';
import EventDetail from '../../model/domain/event/event';
import { EventDefinition } from '@/model/domain/event/event_definition.ts';
import ConfigurationParameters from '@dev/base-web/dist/model/domain/configuration/configuration';

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 13px;
  padding-left: 10px;
`;

const Span = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 1.38;
  color: #242424;
`;

const P = styled.p`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #939393;
  margin: 0;
  padding: 0px 0px 0px 10px;
`;

export interface InstructionListContainerProps {
  readonly event: EventDetail | EventDefinition;
  readonly instructions?: readonly DisplayedInstructionFields[];
  readonly currentUser: UserDetails | null;
  readonly canAddNew?: boolean;
  readonly canDelete?: boolean;
  readonly notEditable: boolean;
  readonly onInstructionSelect: (actionId: string) => void;
  readonly removeInstruction?: (id: string) => void;
  readonly openCallback?: () => void;
  readonly createNewInstruction: () => void;
  readonly configs: ConfigurationParameters | null;
}

const InstructionListContainer = ({
  event,
  instructions,
  currentUser,
  canAddNew = true,
  canDelete = true,
  notEditable,
  onInstructionSelect,
  openCallback,
  createNewInstruction,
  removeInstruction,
  configs,
}: InstructionListContainerProps) => {
  const { read: userHasMaintainerPrivilege } = useAuthorizationCheckRWD(
    UserPrivilegePermission.MAINTAINER_ACTION_DEFINITIONS,
    ApplicationCategory.SHANNON
  );

  const { read: userHasSolutionSuggestionPrivilege } = useAuthorizationCheckRWD(
    UserPrivilegePermission.SOLUTION_SUGGESTION,
    ApplicationCategory.SHANNON
  );

  const generateInstructionAllowed = configs?.instructionSuggestion.value;

  return (
    <div>
      <ListHead>
        <div>
          <Span>
            <FormattedMessage id="solutions" />
          </Span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {notEditable || !canDelete ? null : (
            <Button
              type="tertiary"
              icon="plus"
              label="select"
              onClick={openCallback}
            />
          )}
          {userHasSolutionSuggestionPrivilege &&
            generateInstructionAllowed &&
            !event.isInternal &&
            instructions?.length === 0 && (
              <SuggestInstructionModal event={event} />
            )}
          {canAddNew && (
            <div id="add-new">
              <Button
                type="tertiary"
                icon="new"
                label="create"
                onClick={createNewInstruction}
              />
            </div>
          )}
        </div>
      </ListHead>
      <List>
        {instructions && instructions.length > 0 ? (
          instructions
            .filter((a) => a.isApproved)
            .map((e) => (
              <InstructionItem
                key={e.id}
                instruction={e}
                disabled={
                  e.accessLevel !== 'OPERATOR' && !userHasMaintainerPrivilege
                }
                onInstructionSelect={onInstructionSelect}
                notEditable={notEditable}
                removeInstruction={removeInstruction}
                currentUser={currentUser}
                canDelete={canDelete}
              />
            ))
        ) : (
          <P>
            <FormattedMessage id="no_actions_info" />
          </P>
        )}
      </List>
    </div>
  );
};

export default InstructionListContainer;
