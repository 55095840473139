import styled from 'styled-components';

export const HorizontalBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0px;
`;

export const ConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #8c8c8c;
  margin-top: 12px;
`;

export const SubHeader = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #242424;
  padding-bottom: 12px;
`;
