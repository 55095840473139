import {
  createActionsForExecutingOperation,
  createActionsForSingleItem,
  createActionsForLoadingPagingList,
  createActionsForList,
} from '@dev/base-web/dist/model/redux/helpers/actions';
import { createAction } from 'typesafe-actions';
import {
  ActionDefinitionHistoryDTO,
  InstructionOverview,
  ActionDefinitionDTO,
} from '../../domain/instruction/instruction';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import { InstructionWithTranslation } from '@/model/domain/instruction/translation.ts';
import { InstructionSuggestion } from '@/model/domain/instruction/suggestion.ts';

export const ACTION_TYPES = {
  UPDATE_INSTRUCTION_KEY: 'UPDATE_INSTRUCTION',
  UPDATE_EVENT_FOR_INSTRUCTION_KEY: 'UPDATE_EVENT_FOR_INSTRUCTION',
  INSTRUCTIONS_KEY: 'INSTRUCTIONS_KEY',
  INSTRUCTIONS_FOR_DASHBOARD_SEARCH_KEY: 'INSTRUCTIONS_FOR_DASHBOARD_SEARCH',
  INSTRUCTION_KEY: 'INSTRUCTION_KEY',
  APPROVE_INSTRUCTION: 'APPROVE_INSTRUCTION0',
  SET_ACTIVE_INSTRUCTIONS_TAB: 'SET_ACTIVE_INSTRUCTIONS_TAB',
  SET_SELECTED_INSTRUCTION_LANGUAGE: 'SET_SELECTED_INSTRUCTION_LANGUAGE',
  DELETE_COMMENT: 'DELETE_COMMENT',
  ADD_EVENTS_TO_INSTRUCTION_KEY: 'ADD_EVENTS_TO_INSTRUCTION_KEY',
  TRANSLATE_INSTRUCTION_KEY: 'TRANSLATE_INSTRUCTION',
  UPDATE_TRANSLATION_KEY: 'UPDATE_TRANSLATION',
  SAVE_LAST_EDITED_LANGUAGE_AND_TRANSLATE_KEY:
    'SAVE_LAST_EDITED_LANGUAGE_AND_TRANSLATE',
  INSTRUCTIONS_OPERATION_KEY: 'INSTRUCTIONS_OPERATION',
  INSTRUCTIONS_FOR_ADD_RELATION_MODAL_KEY:
    'INSTRUCTIONS_FOR_ADD_RELATION_MODAL_KEY',
  REQUEST_REVIEW_KEY: 'REQUEST_REVIEW',
  REVIEW_STATUS: 'REVIEW_STATUS',
  SOLUTION_APPROVAL_USERS: 'SOLUTION_APPROVAL_USERS',
  INSTRUCTION_VERSIONS: 'INSTRUCTION_VERSIONS',
  INSTRUCTION_SUGGESTION_KEY: 'GET_INSTRUCTION_SUGGESTION_KEY',
};

export const Actions = {
  instruction: createActionsForSingleItem<ActionDefinitionDTO>(
    ACTION_TYPES.INSTRUCTION_KEY
  ),
  instructionVersions: createActionsForList<ActionDefinitionHistoryDTO>(
    ACTION_TYPES.INSTRUCTION_VERSIONS
  ),
  instructions: createActionsForLoadingPagingList<InstructionOverview>(
    ACTION_TYPES.INSTRUCTIONS_KEY
  ),
  instructionsForDashboardSearch:
    createActionsForLoadingPagingList<InstructionOverview>(
      ACTION_TYPES.INSTRUCTIONS_FOR_DASHBOARD_SEARCH_KEY
    ),
  instructionsForAddRelationModal:
    createActionsForLoadingPagingList<InstructionOverview>(
      ACTION_TYPES.INSTRUCTIONS_FOR_ADD_RELATION_MODAL_KEY
    ),
  updateInstruction: createActionsForExecutingOperation(
    ACTION_TYPES.UPDATE_INSTRUCTION_KEY
  ),
  addEventsToInstruction: createActionsForExecutingOperation(
    ACTION_TYPES.ADD_EVENTS_TO_INSTRUCTION_KEY
  ),
  approveInstruction: createActionsForExecutingOperation(
    ACTION_TYPES.APPROVE_INSTRUCTION
  ),
  translateInstruction: createActionsForSingleItem<InstructionWithTranslation>(
    ACTION_TYPES.TRANSLATE_INSTRUCTION_KEY
  ),
  updateTranslation: createActionsForExecutingOperation(
    ACTION_TYPES.UPDATE_TRANSLATION_KEY
  ),
  saveLastEditedLanguageAndTranslate: createActionsForExecutingOperation(
    ACTION_TYPES.SAVE_LAST_EDITED_LANGUAGE_AND_TRANSLATE_KEY
  ),
  setActiveTab: createAction(
    ACTION_TYPES.SET_ACTIVE_INSTRUCTIONS_TAB
  )<number>(),
  setSelectedInstructionLanguage: createAction(
    ACTION_TYPES.SET_SELECTED_INSTRUCTION_LANGUAGE
  )<string>(),
  deleteComment: createActionsForExecutingOperation(
    ACTION_TYPES.DELETE_COMMENT
  ),
  instructionsOperation: createActionsForExecutingOperation(
    ACTION_TYPES.INSTRUCTIONS_OPERATION_KEY
  ),
  requestReview: createActionsForExecutingOperation(
    ACTION_TYPES.REQUEST_REVIEW_KEY
  ),
  reviewStatus: createActionsForSingleItem(ACTION_TYPES.REVIEW_STATUS),
  instructionApprovalUsers: createActionsForList<UserDetails>(
    ACTION_TYPES.SOLUTION_APPROVAL_USERS
  ),
  suggestedInstruction: createActionsForSingleItem<InstructionSuggestion>(
    ACTION_TYPES.INSTRUCTION_SUGGESTION_KEY
  ),
};
