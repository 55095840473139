import { TimerCondition } from '../../../../model/domain/event/event_definition';
import React from 'react';
import styled from 'styled-components';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Function from '@dev/base-web/dist/view/components/feature/feature_config_card/function_label_card';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import { DndProvider } from 'react-dnd';
import DurationInput from '@dev/base-web/dist/view/components/inputs/duration_input';
import {
  Duration,
  DurationUnit,
} from '@dev/base-web/dist/model/domain/durations/duration';

interface TimerConditionCardProps {
  readonly condition: TimerCondition;
  readonly disabled: boolean;
  readonly replaceColor?: string;
}

const StyledFeatureCard = styled.div<{ color?: string }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${({ color }) => (color ? color : '#f7f7f7')};
  justify-content: start;
  min-width: 72px;
  display: flex;
  border-radius: 8px;
  border: solid 1px #dfdfdf;
  font-family: Relative;
  padding: 2px 16px 2px 16px;
  transition: opacity 0.2s;
  cursor: pointer;
  min-width: fit-content;
`;

const FunctionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-top: 6px;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const DurationInputWrapper = styled.div`
  padding: 6px 0;
`;

const HintWrapper = styled.div`
  margin-left: 12px;
  margin-top: 8px;
  max-width: 250px;
`;

const TimerConditionCard: React.FC<TimerConditionCardProps> = ({
  condition,
  disabled,
  replaceColor,
}) => {
  const duration: Duration = {
    value: condition.duration,
    unit: condition.unit,
  };

  const onChange = (duration: Duration | null) => {
    console.log(duration);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <StyledFeatureCard color={replaceColor}>
        <FunctionWrapper>
          <Function value={'timer'} draggable={false} />
        </FunctionWrapper>
        <DurationInputWrapper>
          <DurationInput
            defaultValue={duration}
            allowedUnits={[
              DurationUnit.MINUTES,
              DurationUnit.HOURS,
              DurationUnit.DAYS,
              DurationUnit.WEEKS,
              DurationUnit.MONTHS,
            ]}
            disabled={!disabled}
            onChange={onChange}
            alignment="horizontal"
          />
          <HintWrapper>
            <Hint messageKey={'timer_hint'} />
          </HintWrapper>
        </DurationInputWrapper>
      </StyledFeatureCard>
    </DndProvider>
  );
};

export default TimerConditionCard;
