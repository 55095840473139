import { Button } from '@dev/base-web/dist/view/components/global/button';
import Modal from '@dev/base-web/dist/view/components/modal/modal';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyledInput } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { Form, InputOnChangeData } from 'semantic-ui-react';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 30px;
`;

export interface AddNewRoleModalProps {
  roleNames: string[];
  onModalOkPressed: (roleName: string) => void;
}

const AddNewRoleModal: React.FC<AddNewRoleModalProps> = ({
  roleNames,
  onModalOkPressed,
}) => {
  const intl = useIntl();

  const [roleName, setRoleName] = useState('');

  const isBadRoleName = (newRoleName: string): boolean => {
    return (
      !newRoleName ||
      roleNames.some((role) => role.toLowerCase() === newRoleName.toLowerCase())
    );
  };

  const onChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => setRoleName(data.value);

  return (
    <Modal
      headerLabel={'add_new_role'}
      onModalOkPressed={() => onModalOkPressed(roleName)}
      okLabel={'add'}
      okDisabled={isBadRoleName(roleName)}
      cancelLabel={'cancel_button'}
      triggerButton={
        <Button
          type="secondary"
          icon="new"
          label="new_role"
          onClick={() => setRoleName('')}
        />
      }
    >
      <Form>
        <Form.Field>
          <label>
            <FormattedMessage id="role_name" />
          </label>
          <StyledInput
            placeholder={intl.formatMessage({ id: 'name_placeholder' })}
            value={roleName}
            onChange={onChange}
            error={isBadRoleName(roleName)}
          />
        </Form.Field>
        <InfoContainer>
          <Hint messageKey={'add_new_role_description'} />
        </InfoContainer>
      </Form>
    </Modal>
  );
};

export default AddNewRoleModal;
