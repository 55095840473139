import { combineReducers, Reducer } from 'redux';
import {
  createReducersForExecutingOperation,
  createReducersForLoadingSingleItem,
  createReducersForLoadingPagingList,
} from '@dev/base-web/dist/model/redux/helpers/reducers';
import {
  ARCHIVE_EVENTS_KEY,
  CLOSE_EVENT,
  CURRENT_EVENT_KEY,
  RAW_EVENTS_KEY,
  FORWARD_EVENT,
} from './actions';
import { EventStateInterface } from './interface';
import EventDetail from '../../domain/event/event';
import RawEvent from '../../domain/event/raw_event';

const eventState: Reducer<EventStateInterface> = combineReducers({
  currentEvent:
    createReducersForLoadingSingleItem<EventDetail>(CURRENT_EVENT_KEY),
  closeEventUpdate: createReducersForExecutingOperation(CLOSE_EVENT),
  archiveEvents: createReducersForExecutingOperation(ARCHIVE_EVENTS_KEY),
  rawEvents: createReducersForLoadingPagingList<RawEvent>(RAW_EVENTS_KEY),
  forwardEvent: createReducersForExecutingOperation(FORWARD_EVENT),
});

export default eventState;
