import styled from 'styled-components';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { Lightbox, SlideImage } from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import { MediaPathsDTO } from '@dev/base-web/dist/model/domain/common/media';
import { useApiBlob } from './Media';

const zoomAnimationDuration = 300;
const zoomProps = {
  maxZoomPixelRatio: 8,
  zoomInMultiplier: 2,
  doubleTapDelay: 300,
  doubleClickDelay: 300,
  doubleClickMaxStops: 3,
  keyboardMoveDistance: 50,
  wheelZoomDistanceFactor: 100,
  pinchZoomDistanceFactor: 100,
  scrollToZoom: true,
};

const ImageMediaContainer = styled.div<{ isImageMedia: boolean }>`
  ${(props) => (props.isImageMedia ? 'cursor: pointer;' : '')}
`;

interface LightboxWrapperProps {
  readonly media: MediaPathsDTO;
  readonly canOpenLightbox: boolean;
  readonly children?: ReactNode;
}

export const LightboxWrapper: React.FC<LightboxWrapperProps> = ({
  media,
  canOpenLightbox,
  children,
}) => {
  const [isPictureOpen, setIsPictureOpen] = useState(false);

  const isPicture = media.fileType === 'PICTURE';

  const handleImageMediaContainerOnClick = useCallback(() => {
    if (isPicture && canOpenLightbox) {
      setIsPictureOpen(true);
    }
  }, [isPicture, canOpenLightbox]);

  const [fullContent] = useApiBlob(media.full, false);

  const imageMediaSlides = useMemo((): SlideImage[] => {
    if (!isPicture || !fullContent) {
      return [];
    }

    return [{ src: fullContent }];
  }, [fullContent, isPicture]);

  return (
    <ImageMediaContainer
      isImageMedia={isPicture && canOpenLightbox}
      onClick={handleImageMediaContainerOnClick}
    >
      <Lightbox
        key={media.full}
        open={isPictureOpen}
        close={() => setIsPictureOpen(false)}
        slides={imageMediaSlides}
        carousel={{ finite: true }}
        render={{
          iconClose: () => <Icon name="cross" size={16} color="white" />,
        }}
        plugins={[Zoom]}
        animation={{
          zoom: zoomAnimationDuration,
        }}
        zoom={zoomProps}
      />
      {children}
    </ImageMediaContainer>
  );
};
