import React, { useEffect, useState } from 'react';
import FeatureConfigurationInstance from '@dev/base-web/dist/model/domain/feature/feature_configuration';
import { FormattedMessage } from 'react-intl';
import {
  ClosingConditionType,
  OpeningConditionType,
  ScheduledCondition,
} from '@/model/domain/event/event_definition.ts';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import {
  Button,
  ButtonGroup,
} from '@dev/base-web/dist/view/components/global/button';
import { ManufacturingEntityPill } from './man_ent_pill';
import { Span } from './event_description';
import FeatureCreationScreen from './feature_editor/view';
import {
  PlcSignal,
  SignalStatistic,
} from '@dev/base-web/dist/model/domain/plc/plc_signal';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import UserAction from './user_action';
import { CenteredTabs, HintContainer } from './styled_components';
import ScheduledCond from './scheduled_condition';
import Timer from './timer_condition';
import {
  isClosingConditionValid,
  isOpeningConditionValid,
} from './event_validator';
import { DurationUnit } from '@dev/base-web/dist/model/domain/durations/duration';
import { ErrorInterface } from '@/model/api/error/ErrorInterface.ts';
import { OneToOneOperation } from '@dev/base-web/dist/model/domain/feature/feature';
import RuleCopyModal from './rule_copy_modal';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import {
  LoadingListInterface,
  LoadingMetaState,
} from '@dev/base-web/dist/model/redux/helpers/interfaces';
import FeatureTemplate from '@dev/base-web/dist/model/domain/feature/feature_template';
import styled from 'styled-components';

const EntitiesSpan = styled(Span)`
  margin-top: 0;
`;

//TODO: replace any types for rules
export interface EventRulesConfigurationProps {
  eventType: EventType;
  stage: string; //If it is on 'open' or 'close'
  rules: any[];
  readonly selectedRuleId?: string;
  ruleCallback: (rules: any[]) => void;
  featureConfiguration: FeatureConfigurationInstance | null;
  signals: PlcSignal[];
  readonly moreSignalsCanBeLoaded: boolean;
  readonly signalsMeta: LoadingMetaState;
  readonly featureValues: SignalStatistic[];
  readonly featureValuesLoading: boolean;
  readonly featureValuesError: ErrorInterface | undefined;
  readonly featureTemplateState: LoadingListInterface<FeatureTemplate>;
  readonly handleOpenModal: (item: ManufacturingEntity) => void;
  readonly getSignalsForManufacturingEntity: (
    manufacturingEntityId: string,
    page: number,
    filters: readonly FilterData[],
    onlyActive: boolean,
    sortingKey?: string,
    sorting?: SortingDirection
  ) => void;
  readonly getSignalsForPlc: (
    plcId: string,
    page: number,
    filters: readonly FilterData[],
    onlyActive: boolean,
    sortingKey?: string,
    sorting?: SortingDirection
  ) => void;
  readonly getSignals: (
    page: number,
    filters: readonly FilterData[],
    onlyActive: boolean,
    sortingKey?: string,
    sorting?: SortingDirection
  ) => void;
  readonly computeFeatureValues: (
    features: any[],
    beginTimestamp: number,
    endTimestamp: number
  ) => void;
  readonly clearFeatureValues: () => void;
  readonly getFeatureTemplates: (
    inputNumber?: number,
    parentName?: string
  ) => void;
  readonly allowedToModify: boolean;
  readonly setSelectedRuleId: (ruleId?: string) => void;
}

const EventRulesConfig: React.FC<EventRulesConfigurationProps> = ({
  eventType,
  stage,
  rules,
  featureConfiguration,
  signals,
  moreSignalsCanBeLoaded,
  signalsMeta,
  getSignalsForManufacturingEntity,
  getSignalsForPlc,
  getSignals,
  ruleCallback,
  featureValues,
  featureValuesLoading,
  featureValuesError,
  computeFeatureValues,
  clearFeatureValues,
  handleOpenModal,
  getFeatureTemplates,
  featureTemplateState,
  allowedToModify,
  selectedRuleId,
  setSelectedRuleId,
}: EventRulesConfigurationProps) => {
  //TODO: replace any type
  const [modalOpen, setModalOpen] = useState<boolean>(false); //IF THE COPY RULE MODAL IS OPEN5
  const [changedRules, setChangedRules] = useState<Record<string, string>>({}); /// KEY-VALUE PAIR. INDICATES THAT A CHANGE HAS BEEN MADE IN ONE OF THE STEPS (OPEN, CLOSE) OF THE RULE. ITS (ManufacturingEntity id: step)
  const [selectedRule, setSelectedRule] = useState<any>();

  useEffect(() => {
    if (!selectedRuleId && rules && rules[0]) {
      setSelectedRule(rules[0]);
    } else if (!selectedRuleId && !rules) setSelectedRule(undefined);
    else
      setSelectedRule(
        rules.find(
          (r) => r.id === selectedRuleId || r.temporaryId === selectedRuleId
        )
      );
  }, [selectedRuleId, rules]);

  useEffect(() => {
    if (
      selectedRule === undefined ||
      (!selectedRule.open.value && !selectedRule.close.value)
    )
      return;

    const counterStage = stage === 'open' ? 'close' : 'open';

    if (
      selectedRule[counterStage].type === 'FEATURE' &&
      checkStepChange(selectedRule.manufacturingEntity.id, counterStage)
    ) {
      setModalOpen(true);

      removeStepChange(selectedRule.manufacturingEntity.id);
    }
  }, [stage, selectedRule]);

  const onDurationChange = (value: number | undefined) => {
    const copyRules = rules.map((rule) => {
      if (rule.manufacturingEntity === selectedRule.manufacturingEntity) {
        return {
          ...rule,
          [stage]: {
            ...rule[stage],
            duration: value,
            unit: DurationUnit.MINUTES,
          },
        };
      } else return { ...rule };
    });
    ruleCallback(copyRules);
  };

  //////////FUNCTIONS FOR CHANGED RULES////////////

  const addStepChange = (manufacturingEntityId: string, step: string) => {
    if (
      Object.keys(changedRules).some(
        (key) => key === manufacturingEntityId && changedRules[key] === step
      )
    )
      return;

    return setChangedRules({ ...changedRules, [manufacturingEntityId]: step });
  };

  const removeStepChange = (manufacturingEntityId: string) => {
    if (!Object.keys(changedRules).some((key) => key === manufacturingEntityId))
      return;

    const copyState = changedRules;
    delete copyState[manufacturingEntityId];

    return setChangedRules({ ...copyState });
  };

  const checkStepChange = (manufacturingEntityId: string, step: string) => {
    if (!Object.keys(changedRules).some((key) => key === manufacturingEntityId))
      return false;

    return changedRules[manufacturingEntityId] === step;
  };

  /////////////////////////////////////////////////

  const setRuleType = (
    type: string,
    manufacturingEntity: ManufacturingEntity
  ) => {
    const copyRules = rules.map((rule) => {
      if (rule.manufacturingEntity.id === manufacturingEntity.id) {
        if (stage === 'close') {
          return {
            ...rule,
            [stage]: { ...rule[stage], type: type, onClose: true },
          };
        } else return { ...rule, [stage]: { ...rule[stage], type: type } };
      } else return { ...rule };
    });

    ruleCallback(copyRules);
  };

  function getCounterFeatureOperation(operation: string) {
    return operation === OneToOneOperation.RISING_EDGE
      ? OneToOneOperation.FALLING_EDGE
      : OneToOneOperation.RISING_EDGE;
  }

  //TODO: use actual type instead of any for feature param
  const setRuleFeature = (
    feature: any,
    manufacturingEntity: ManufacturingEntity
  ) => {
    if (selectedRule[stage].type === 'FEATURE') {
      addStepChange(selectedRule.manufacturingEntity.id, stage);
    }

    const copyRules = rules.map((rule) => {
      if (rule.manufacturingEntity?.id === manufacturingEntity.id)
        return { ...rule, [stage]: { ...rule[stage], value: feature } };
      else return { ...rule }; /////IF IT'S NOT THE MANUFACTURING ENTITY, IT MEANS THE USER IS CURRENTLY WORKING ON ANOTHER RULE
    });

    ruleCallback(copyRules);
  };

  const counterStepRule = (manufacturingEntity: ManufacturingEntity) => {
    const counterStage = stage === 'open' ? 'close' : 'open';
    const copyRules = rules.map((rule) => {
      if (
        rule.manufacturingEntity === manufacturingEntity &&
        rule[counterStage].value &&
        Object.keys(rule[counterStage].value).length > 0
      ) {
        return {
          ...rule,
          [stage]: {
            ...rule[stage],
            type: 'FEATURE',
            value: {
              ...rule[counterStage].value,
              operation: getCounterFeatureOperation(
                rule[counterStage].value.operation
              ),
            },
          },
        };
      } else return { ...rule };
    });

    ruleCallback(copyRules);
    setModalOpen(false);
  };

  const swapTypeButton = (value: string) => {
    if (selectedRule !== null) {
      setRuleType(value, selectedRule.manufacturingEntity);
    }
  };

  const changeScheduledValues = (schCond: ScheduledCondition) => {
    const copyRules = rules.map((rule) => {
      if (rule.manufacturingEntity === selectedRule.manufacturingEntity) {
        return {
          ...rule,
          [stage]: {
            ...rule[stage],
            iterationType: schCond.iterationType,
            iteration: schCond.iteration,
            timestamp: schCond.timestamp,
          },
        };
      } else return { ...rule };
    });
    ruleCallback(copyRules);
  };

  const switchRule = (item: any) => {
    if (
      (item.id || item.temporaryId) &&
      (item.id !== selectedRuleId || item.temporaryId !== selectedRuleId)
    ) {
      setSelectedRuleId(item.id ? item.id : item.temporaryId);
    }
  };

  return (
    <div style={{ backgroundColor: '#f7f7f7' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <EntitiesSpan>
          <FormattedMessage
            id={
              stage === 'open'
                ? 'event_manufacturing_entity_open_triggers'
                : 'event_manufacturing_entity_close_triggers'
            }
          />
        </EntitiesSpan>
        <CenteredTabs style={{ margin: '0px' }}>
          {(!rules || rules.length === 0) && (
            <HintContainer>
              <Hint messageKey={'no_manufacturing_entities_selected'} />
            </HintContainer>
          )}
          {/*TODO: remove this any*/}
          {rules &&
            rules.map((item: any) => (
              <ManufacturingEntityPill
                key={
                  item && item.manufacturingEntity
                    ? item.manufacturingEntity.name
                    : ''
                }
                complete={
                  item &&
                  item[stage] &&
                  ((stage === 'open' &&
                    isOpeningConditionValid(
                      featureConfiguration,
                      item[stage]
                    )) ||
                    (stage === 'close' &&
                      isClosingConditionValid(
                        featureConfiguration,
                        item[stage]
                      )))
                }
                active={
                  item &&
                  item.manufacturingEntity &&
                  selectedRule &&
                  selectedRule.manufacturingEntity
                    ? item.manufacturingEntity ===
                      selectedRule.manufacturingEntity
                    : false
                }
                name={
                  item && item.manufacturingEntity
                    ? item.manufacturingEntity.name
                    : ''
                }
                addNew={false}
                onClick={() => switchRule(item)}
                onClickMark={() => handleOpenModal(item.manufacturingEntity)}
              />
            ))}
        </CenteredTabs>
        <Span>
          <FormattedMessage
            id={
              stage === 'open'
                ? 'event_open_trigger_question'
                : 'event_close_trigger_question'
            }
          />
        </Span>
        <RuleCopyModal
          newStage={stage}
          rule={selectedRule}
          open={modalOpen}
          closeCallback={() => {
            setModalOpen(false);
          }}
          changeCallback={() =>
            counterStepRule(selectedRule.manufacturingEntity)
          }
          cancelCallback={() => {
            setModalOpen(false);
          }}
          featureConfiguration={featureConfiguration}
        ></RuleCopyModal>
        <CenteredTabs style={{ margin: '0px', paddingBottom: '10px' }}>
          <ButtonGroup>
            <Button
              disabled={!(selectedRule && allowedToModify)}
              key="FEATURE"
              type="secondary"
              icon="signal"
              label={'signal'}
              onClick={() => swapTypeButton('FEATURE')}
              active={
                selectedRule &&
                selectedRule[stage] &&
                selectedRule[stage].type === 'FEATURE'
              }
            />
            {stage === 'open' && (
              <Button
                disabled={!(selectedRule && allowedToModify)}
                key="SCHEDULED"
                type="secondary"
                icon="time"
                label={'condition_time'}
                onClick={() => swapTypeButton('SCHEDULED')}
                active={
                  selectedRule &&
                  selectedRule[stage] &&
                  selectedRule[stage].type === 'SCHEDULED'
                }
              />
            )}
            {stage === 'close' && (
              <>
                <Button
                  disabled={!selectedRule}
                  key="TIMER"
                  type="secondary"
                  icon="time"
                  label={'duration'}
                  onClick={() => swapTypeButton('TIMER')}
                  active={
                    selectedRule &&
                    selectedRule[stage] &&
                    selectedRule[stage].type === 'TIMER'
                  }
                />
                {eventType === EventType.TASK && (
                  <Button
                    disabled={!selectedRule}
                    key="USER_ACTION"
                    type="secondary"
                    icon="user"
                    label={'condition_user_action'}
                    onClick={() => swapTypeButton('USER_ACTION')}
                    active={
                      selectedRule && selectedRule[stage].type === 'USER_ACTION'
                    }
                  />
                )}
              </>
            )}
          </ButtonGroup>
        </CenteredTabs>

        {selectedRule &&
          selectedRule[stage].type === ClosingConditionType.USER_ACTION && (
            <UserAction />
          )}
        {selectedRule &&
          selectedRule[stage].type === ClosingConditionType.TIMER && (
            <Timer
              rule={selectedRule[stage]}
              onDurationUpdate={onDurationChange}
            />
          )}
        {selectedRule && selectedRule[stage].type === 'FEATURE' && (
          <FeatureCreationScreen
            onlyEdges={!selectedRule[stage].value}
            stage={stage}
            featureCallback={setRuleFeature}
            getSignalsForManufacturingEntity={getSignalsForManufacturingEntity}
            getSignalsForPlc={getSignalsForPlc}
            getSignals={getSignals}
            manufacturingEntity={selectedRule.manufacturingEntity}
            signals={signals}
            signalsMeta={signalsMeta}
            moreSignalsCanBeLoaded={moreSignalsCanBeLoaded}
            feature={selectedRule[stage].value}
            featureConfiguration={featureConfiguration}
            featureValues={featureValues}
            featureValuesLoading={featureValuesLoading}
            featureValuesError={featureValuesError}
            computeFeatureValues={computeFeatureValues}
            clearFeatureValues={clearFeatureValues}
            featureTemplateState={featureTemplateState}
            getFeatureTemplates={getFeatureTemplates}
            allowedToModify={allowedToModify}
          />
        )}

        {selectedRule &&
          selectedRule[stage].type === OpeningConditionType.SCHEDULED && (
            <ScheduledCond
              rule={selectedRule && selectedRule[stage]}
              onConditionUpdate={(cond) => changeScheduledValues(cond)}
            />
          )}
      </div>
    </div>
  );
};

export default EventRulesConfig;
