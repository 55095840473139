import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { EventRule } from '@/model/domain/parallel_events/parallel_events.ts';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';
import { CardColumn } from '@dev/base-web/dist/view/components/global/card';
import { Row } from '@dev/base-web/dist/view/components/global/styled_components';
import {
  EventDefinition,
  EventDefinitionAdd,
  EventRuleGroupOperation,
  EventRuleType,
} from '@/model/domain/event/event_definition.ts';
import {
  LoadingMetaState,
  OperationMetaState,
} from '@dev/base-web/dist/model/redux/helpers/interfaces';
import {
  EventDefinitionPropertiesOnChangeValue,
  FlexEndRow,
  translationColumns,
} from '../../event_definition/components/event_definition_properties';
import { TranslationRow } from '../../event_definition/components/translation_row';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import { useDateLocale } from '@dev/base-web/dist/view/helpers/date_hooks';
import { localisation } from '@/model/redux/reducers.ts';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';
import { ManufacturingEntityType } from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';

const ModalDropDown = styled(StyledDropDown)`
  width: 312px;
  margin-top: 8px;
  margin-bottom: 16px;
  &.ui.selection.dropdown {
    z-index: 500;
  }
`;

const HighModalDropDown = styled(ModalDropDown)`
  &.ui.selection.dropdown {
    z-index: 501;
  }
`;

const TextContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  color: black;
`;

const MergeModal = ({
  eventRules,
  open,
  getEvent,
  event,
  eventOperationState,
  eventCreationOperationState,
  close,
  merge,
}: {
  eventRules: EventRule[];
  open: boolean;
  getEvent: (id: string) => void;
  readonly event: EventDefinition | null;
  readonly eventOperationState: LoadingMetaState;
  readonly eventCreationOperationState: OperationMetaState;
  close: () => void;
  merge: (newEvent: EventDefinitionAdd) => void;
}) => {
  const intl = useIntl();
  const { code } = useDateLocale();
  const localeLanguage = code?.substring(0, 2);
  const locales = Object.keys(localisation);

  const [filteredLocales, setFilteredLocales] = useState<string[]>([]);
  // const [newEventName, setNewEventName] = useState<string>();
  const [newEventTrigger, setNewEventTrigger] =
    useState<EventRuleGroupOperation>();
  const [eventId, setEventId] = useState<string>();
  const [newEvent, setNewEvent] = useState<EventDefinitionAdd>();

  const mapEventRulesToDropdown = (eventRule: EventRule) => ({
    text: eventRule.name,
    value: eventRule.eventDefinitionId,
  });

  const [okDisabled, setOkDisabled] = useState<boolean>(true);

  const [newEventNameOptions, setNewEventNameOptions] = useState<
    readonly DropdownItemProps[]
  >([]);

  const newEventTriggerOptions = Object.keys(EventRuleGroupOperation).map(
    (operation) => ({
      text: intl.formatMessage({
        id:
          operation === EventRuleGroupOperation.ANY
            ? 'any_occurs'
            : 'all_occur',
      }),
      value: operation,
    })
  );

  useEffect(() => {
    const options = [
      {
        text: intl.formatMessage({ id: 'none' }),
        value: 'new_event',
      },
    ];
    const eventOptions = eventRules.map(mapEventRulesToDropdown);
    setNewEventNameOptions([...options, ...eventOptions]);
  }, [eventRules]);

  useEffect(() => {
    if (eventId && eventId === 'new_event') {
      getEvent(eventRules[0].eventDefinitionId);
    } else if (eventId) {
      getEvent(eventId);
    }
  }, [eventId]);

  useEffect(() => {
    if (event && eventId) {
      setNewEvent({
        id: '',
        name: eventId === 'new_event' ? '' : event.name,
        type: event.type,
        description: eventId === 'new_event' ? '' : event.description,
        isCommon: event.isCommon,
        isHidden: event.isHidden,
        excludeFromStats: event.excludeFromStats,
        translations:
          eventId === 'new_event'
            ? [{ name: '', description: '', language: localeLanguage! }]
            : event.translations,
        subscribers:
          eventId === 'new_event'
            ? []
            : event.subscribers && event.subscribers.length
            ? event.subscribers.map((subscriber) => subscriber.id)
            : [],
        actions:
          eventId === 'new_event'
            ? []
            : event.actions && event.actions.length
            ? event.actions.map((a) => a.id)
            : [],
        rules: [
          {
            manufacturingEntity: eventRules[0].manufacturingEntity,
            open: null,
            close: null,
            type: EventRuleType.AGGREGATED, //eventRule.TYPE
            groupOperation: newEventTrigger,
            aggregationChildRules: eventRules.map((eventRule) => {
              return {
                childRuleId: eventRule.id,
                eventDefinition: {
                  id: eventRule.eventDefinitionId,
                  name: eventRule.name,
                  type: eventRule.type,
                  manufacturingEntities: event.manufacturingEntities,
                },
                primary: eventRule.eventDefinitionId === eventId ? true : false,
              };
            }),
            isClosedByDuration: null,
            dynamicProperties: { entityName: null, text: '' },
          },
        ],
      });
    }
  }, [event, newEventTrigger]);

  useEffect(() => {
    if (
      newEvent &&
      newEvent.translations &&
      newEvent.translations.length > 0 &&
      newEvent.name &&
      newEvent.name !== '' &&
      newEventTrigger
    ) {
      setOkDisabled(false);
    } else {
      setOkDisabled(true);
    }
  }, [newEvent]);

  useEffect(() => {
    if (newEvent) {
      const filter = locales.filter(
        (locale) =>
          !newEvent.translations.some(
            (translation) => translation.language.toLowerCase() === locale
          )
      );

      if (JSON.stringify(filter) !== JSON.stringify(filteredLocales)) {
        setFilteredLocales(filter);
      }
    }
  }, [locales, newEvent]);

  const onAddTranslation = (language: string) => {
    const newTranslation = {
      name: '',
      description: '',
      language: language.toUpperCase(),
    };

    if (newEvent) {
      // setUnsavedChanges(true);
      setNewEvent({
        ...newEvent,
        translations: [...newEvent.translations, newTranslation],
      });
    }
  };

  const changeValues = (e: EventDefinitionPropertiesOnChangeValue) => {
    if (newEvent && 'target' in e) {
      // setUnsavedChanges(true);

      if (e.target.name in newEvent) {
        setNewEvent({
          ...newEvent,
          [e.target.name]: e.target.value,
        });
      } else if (
        'target' in e &&
        (e.target.name.includes('name-') ||
          e.target.name.includes('description-'))
      ) {
        const nameParts = e.target.name.split('-');
        const language = nameParts[1];
        const isEventData = nameParts[2] === 'true';
        const index = newEvent.translations.findIndex(
          (translation) =>
            translation.language.toLowerCase() === language.toLowerCase()
        );

        if (index !== -1) {
          if (e.target.name.includes('name-')) {
            const newTranslations = [...newEvent.translations];
            const updatedTranslation = {
              ...newTranslations[index],
              name: e.target.value,
            };
            newTranslations[index] = updatedTranslation;

            if (isEventData) {
              setNewEvent({
                ...newEvent,
                name: e.target.value,
                translations: newTranslations,
              });
              return;
            } else {
              setNewEvent({ ...newEvent, translations: newTranslations });
            }
          } else if ('target' in e && e.target.name.includes('description-')) {
            const newTranslations = [...newEvent.translations];
            const updatedTranslation = {
              ...newTranslations[index],
              description: e.target.value,
            };
            newTranslations[index] = updatedTranslation;

            if (isEventData) {
              setNewEvent({
                ...newEvent,
                description: e.target.value,
                translations: newTranslations,
              });
            } else {
              setNewEvent({ ...newEvent, translations: newTranslations });
            }
          }
        }
      }
    }
  };

  const deleteTranslation = (language: string) => {
    if (newEvent) {
      const index = newEvent.translations.findIndex(
        (translation) =>
          translation.language.toLowerCase() === language.toLowerCase()
      );

      if (index !== -1) {
        // setUnsavedChanges(true);
        const newTranslations = [...newEvent.translations];
        newTranslations.splice(index, 1);
        setNewEvent({
          ...newEvent,
          translations: newTranslations,
        });
      }
    }
  };

  return (
    <BasicModalWithHeaderAndTwoButtons
      modalSize="small"
      headerLabel="merge_events"
      cancelLabel="cancel_button"
      onCancel={() => {
        setNewEvent(undefined);
        close();
      }}
      onClose={() => {
        setNewEvent(undefined);
        close();
      }}
      open={open}
      okLabel="merge"
      onOk={() => {
        newEvent && merge(newEvent);
      }}
      okDisabled={okDisabled}
      okLoading={eventCreationOperationState.operationInProgress}
    >
      <CardColumn>
        <Row style={{ justifyContent: 'space-between', gap: '10px' }}>
          <div>
            <LabelWithHint label="manufacturing_entity" />
            <TextContainer>
              <ManufacturingEntityHierarchyElement
                manufacturingEntity={eventRules[0].manufacturingEntity}
                hideHiddenTypes
                showShortNames
                hiddenTypes={[
                  ManufacturingEntityType.FACTORY,
                  ManufacturingEntityType.AREA,
                  ManufacturingEntityType.LINE,
                ]}
                lines={2}
              />
            </TextContainer>
            <LabelWithHint label="events" />
            <TextContainer>
              {eventRules.map((entry) => {
                return (
                  <TextWithEllipsisAndTooltip
                    key={entry.id}
                    text={'- ' + entry.name}
                  />
                );
              })}
            </TextContainer>
          </div>
          <div>
            <LabelWithHint
              label="when_should_event_trigger"
              hint="aggregated_event_operation_hint"
            />
            <HighModalDropDown
              selection
              value={newEventTrigger}
              onChange={(
                _event: React.SyntheticEvent<HTMLElement>,
                data: DropdownItemProps
              ) => {
                setNewEventTrigger(data.value as EventRuleGroupOperation);
              }}
              options={newEventTriggerOptions}
              placeholder="-"
              error={!newEventTrigger}
              search
            />
            <LabelWithHint
              label="properties_based_on"
              hint="aggregated_event_name_hint"
            />
            <ModalDropDown
              allowAdditions
              selection
              value={eventId}
              onChange={(
                _event: React.SyntheticEvent<HTMLElement>,
                data: DropdownItemProps
              ) => {
                // setNewEventName(data.text as string);
                setEventId(data.value as string);
              }}
              placeholder="-"
              error={!eventId}
              search
              options={newEventNameOptions}
              onAddItem={(_: unknown, data: DropdownItemProps) => {
                setNewEventNameOptions([
                  ...newEventNameOptions,
                  {
                    value: data.value,
                    text: data.value,
                  },
                ]);
              }}
            />
          </div>
        </Row>
      </CardColumn>
      {eventId && (
        <CardColumn>
          <FlexEndRow
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0px 0px -10px 0px',
            }}
          >
            <LabelWithHint label={'translations'} />
            <Dropdown
              trigger={
                <div>
                  <Button
                    type="secondary"
                    icon="plus"
                    iconPosition="right"
                    label="add_language"
                    onClick={() => {
                      // setIsAddLanguageModalOpen(false);
                    }}
                    size={'small'}
                    disabled={filteredLocales.length === 0}
                    tooltipLabel={
                      filteredLocales.length === 0
                        ? 'no_languages_available'
                        : undefined
                    }
                  />
                </div>
              }
              icon={null}
              floating
              direction="left"
              labeled
              disabled={false}
              closeOnChange={false}
            >
              <Dropdown.Menu>
                {filteredLocales.map((locale) => (
                  <Dropdown.Item onClick={() => onAddTranslation(locale)}>
                    <div style={{ height: '15px' }}>
                      <strong>
                        <FormattedMessage id={'add_translation'} />:{' '}
                        <FormattedMessage id={locale} />
                      </strong>
                    </div>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </FlexEndRow>
          <SortableTable
            dataLength={
              newEvent && newEvent.translations
                ? newEvent.translations.length
                : 0
            }
            hasMoreResults={false}
            columns={translationColumns}
            getNextResults={() => {}}
            loadingInProgress={eventOperationState.loadingInProgress}
            filtersDisabled
            maxHeight={350}
            minHeight={50}
          >
            {newEvent &&
              newEvent.translations &&
              newEvent.translations
                .sort((a, b) => a.language.localeCompare(b.language))
                .map((translation) => {
                  return (
                    <TranslationRow
                      translation={translation}
                      onChange={changeValues}
                      disabled={false}
                      hideRemove={false}
                      disableRemove={false}
                      removeTranslation={deleteTranslation}
                    />
                  );
                })}
          </SortableTable>
        </CardColumn>
      )}
    </BasicModalWithHeaderAndTwoButtons>
  );
};

export default connect(null, {})(MergeModal);
