import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import React, { useState } from 'react';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import UserPrivilegesTableRow from './UserPrivilegesTableRow';
import UserRole from '@dev/base-web/dist/model/domain/user/user_role';
import { UserPrivilege } from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import { DefaultRoles } from '@dev/base-web/dist/model/domain/user_privilege/role_permission';
import styled from 'styled-components';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import {
  createUserPrivilegesColumns,
  useUserPrivilegeTableSortingAndFiltering,
} from './UserPriviligesTableConfig';

const RWDContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100px;
  flex-basis: 100%;
  padding: 0 4px;
  color: #8c8c8c;
  font-family: Relative;
  font-size: 10px;
`;

const RWDElement = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      padding: '0 11px',
    }}
  >
    <RWDContainer>
      <span>R</span>
      <span>W</span>
      <span>D</span>
    </RWDContainer>
  </div>
);

interface UserPrivilegesTableProps {
  readonly userPrivilegesLoading: boolean;
  readonly userPrivileges: UserPrivilege[];
  readonly currentUser: UserDetails | null;

  readonly onPrivilegeChanged: (privilege: UserPrivilege) => void;
  readonly contentOverHeader?:
    | ((contentSticky: boolean) => React.ReactNode)
    | undefined;
}

const UserPrivilegesTable = ({
  contentOverHeader,
  userPrivilegesLoading,
  currentUser,
  onPrivilegeChanged,
  userPrivileges,
}: UserPrivilegesTableProps) => {
  const [sortKey, setSortKey] = useState<string>();
  const [sortDirection, setSortDirection] = useState<SortingDirection>();
  const [filters, setFilters] = useState<readonly FilterData[]>([]);

  const filteredAndSortedPrivileges = useUserPrivilegeTableSortingAndFiltering(
    userPrivileges,
    filters,
    sortKey,
    sortDirection
  );

  const otherRoleNames = (
    userPrivileges && userPrivileges.length > 0
      ? userPrivileges[0].rolePermissions.map((r) => r.role)
      : []
  ).filter((role) => !DefaultRoles.includes(role));

  return (
    <SortableTable
      contentOverHeader={contentOverHeader}
      stickyHeaderOffset={72}
      dataLength={filteredAndSortedPrivileges?.length ?? 0}
      hasMoreResults={false}
      columns={createUserPrivilegesColumns(otherRoleNames, <RWDElement />)}
      getNextResults={(_, filters, sortKey, sortDirection) => {
        setFilters(filters);
        setSortKey(sortKey);
        setSortDirection(sortDirection);
      }}
      loadingInProgress={userPrivilegesLoading}
      //  initialSearchData={initialPrivilegesSearchData}
      // filtersDisabled
    >
      {filteredAndSortedPrivileges?.map((userPrivilege) => {
        return (
          <UserPrivilegesTableRow
            isSuperAdmin={
              !!(currentUser && currentUser.role === UserRole.SUPERADMIN)
            }
            userPrivilege={userPrivilege}
            onPrivilegeChanged={(privilege) => {
              if (userPrivileges) {
                onPrivilegeChanged(privilege);
              }
            }}
          />
        );
      })}
    </SortableTable>
  );
};

export default UserPrivilegesTable;
