import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import UserCell from '@dev/base-web/dist/view/components/global/user_cell';
import { ActionDefinitionHistoryDTO } from '@/model/domain/instruction/instruction';
import { LoadingListInterface } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import EmptyOrLoadingView from '@dev/base-web/dist/view/components/global/no_data';
import DetailLoader from '@dev/base-web/dist/view/components/global/detail_loader';
import InstructionSideCard from './instruction_side_card';

const HistoryListCard = styled(InstructionSideCard)`
  width: 100%;
  max-height: 360px;
  position: relative;
`;

const HistoryList = styled.div`
  margin-top: 10px;
  min-height: 100px;
  max-height: 260px;
  overflow: auto;
  margin-right: -20px;
`;

const HistoryItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

const HistoryDateAndRevert = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HistoryDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-family: Relative;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
`;

const CurrentVersionLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-family: Relative;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #939393;
  fill: #939393;
  padding-right: 12px;
  padding-top: 11px;
  padding-bottom: 11px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 4px;
`;

const ApprovedIcon = styled(Icon)`
  margin-right: 28px;
  margin-top: 10px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const UserChangeText = styled.div`
  font-family: Relative;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #8c8c8c;

  margin-bottom: 4px;
`;

const UserInfo = styled.div`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #242424;
`;

const LinearGradient = styled.div`
  position: absolute;
  width: 100%;
  pointer-events: none;
  bottom: 0px;
  left: 0px;
  height: 64px;
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9) 45%,
    white
  );
`;

interface InstructionHistorySideCardProps {
  readonly title: string;
  readonly currentRevisionNumber: number;
  readonly selectedRevisionNumber?: number;
  readonly versions: LoadingListInterface<ActionDefinitionHistoryDTO>;
  readonly pendingUpdates: ActionDefinitionHistoryDTO[];
  readonly onRevisionSelected: (revision: ActionDefinitionHistoryDTO) => void;
  readonly canChangeVersion: boolean;
  readonly isLoading: boolean;
}

const InstructionHistorySideCard: React.FC<InstructionHistorySideCardProps> = (
  props
) => {
  const {
    title,
    versions,
    onRevisionSelected,
    currentRevisionNumber,
    selectedRevisionNumber,
    pendingUpdates,
    canChangeVersion,
    isLoading,
  } = props;

  const theme = useTheme();
  const { formatDate } = useLocalizedDateFormatters();

  const sortedVersions = useMemo(() => {
    const allVersions = [...versions.data, ...pendingUpdates];
    allVersions.sort((a, b) => (b.timestamp > a.timestamp ? 1 : -1));

    return allVersions;
  }, [versions.data, pendingUpdates]);

  const renderHistoryDate = (timeStamp: number) => formatDate(timeStamp, 'Pp');

  return (
    <HistoryListCard
      title={title}
      disableContainerMargin
      headerContent={
        sortedVersions.length > 0 &&
        versions.meta.loadingInProgress && (
          <DetailLoader style={{ width: 100, height: 30 }} />
        )
      }
      noRightContentMargin
    >
      <HistoryList>
        {!sortedVersions.length && (
          <EmptyOrLoadingView
            loadingInProgress={versions.meta.loadingInProgress}
            dataSelected={true}
            hasResults={!!sortedVersions.length}
            hasError={!!versions.meta.error}
            noDataAvailableText={'no_versions_yet'}
          />
        )}
        {sortedVersions.map((historyItem) => (
          <HistoryItemContainer key={historyItem.revisionNumber}>
            <HistoryDateAndRevert>
              <HistoryDate>
                <StyledIcon
                  name={
                    historyItem.isCurrent ||
                    historyItem.revisionNumber === currentRevisionNumber ||
                    historyItem.revisionNumber === currentRevisionNumber
                      ? 'new'
                      : 'edit'
                  }
                  color={'#242424'}
                  size={16}
                />
                {renderHistoryDate(historyItem.timestamp)}
              </HistoryDate>
              {historyItem.revisionNumber !== currentRevisionNumber && (
                <Button
                  type="tertiary"
                  icon={historyItem.revisionNumber ? 'revert' : 'repeat'}
                  loading={
                    historyItem.revisionNumber === selectedRevisionNumber &&
                    isLoading
                  }
                  label={
                    historyItem.revisionNumber
                      ? historyItem.isActive
                        ? 'current_version'
                        : 'revert'
                      : 'update'
                  }
                  onClick={() => onRevisionSelected(historyItem)}
                  disabled={!canChangeVersion}
                />
              )}
              {((historyItem.isCurrent &&
                historyItem.revisionNumber === currentRevisionNumber) ||
                historyItem.revisionNumber === currentRevisionNumber) && (
                <CurrentVersionLabel>
                  <StyledIcon name={'checkmark'} color={'#939393'} size={16} />
                  <FormattedMessage
                    id={
                      historyItem.isApproved && historyItem.isActive
                        ? 'current_version'
                        : 'displayed'
                    }
                  />
                </CurrentVersionLabel>
              )}
            </HistoryDateAndRevert>{' '}
            <BottomContainer>
              <UserInfoContainer>
                <UserChangeText>
                  <FormattedMessage id="action_was_changed_by" />
                </UserChangeText>
                <UserInfo>
                  <UserCell user={historyItem.user} size={24} />
                </UserInfo>
              </UserInfoContainer>{' '}
              {historyItem.isApproved ? (
                <ApprovedIcon
                  name="approved"
                  size={28}
                  color={theme.colors.primary}
                />
              ) : (
                <ApprovedIcon
                  name={'not-approved'}
                  color={theme.colors.primary}
                  size={28}
                />
              )}
            </BottomContainer>
          </HistoryItemContainer>
        ))}
      </HistoryList>
      {versions.data.length > 2 && <LinearGradient />}
    </HistoryListCard>
  );
};

export default InstructionHistorySideCard;
