import React, { Component } from 'react';
import { Span } from './event_description';
import { CenteredTabs } from './styled_components';
import { FormattedMessage } from 'react-intl';
import Scheduled_condition_config from '../../event_definition/components/event_rule_config/scheduled_condition_config';
import { ScheduledCondition } from '../../../model/domain/event/event_definition';

interface ScheduledConditionPropsInterface {
  readonly rule: ScheduledCondition;
  readonly onConditionUpdate: (
    condition: ScheduledCondition,
    valid: boolean
  ) => void;
}

//////this is a simplified version of the TabBar component
class ScheduledCond extends Component<ScheduledConditionPropsInterface> {
  constructor(props: ScheduledConditionPropsInterface) {
    super(props);
  }

  render() {
    return (
      <>
        <Span>
          <FormattedMessage id="event_scheduled_trigger_question" />
        </Span>
        <CenteredTabs style={{ marginTop: 0 }}>
          <div style={{ width: 'fit-content' }}>
            <Scheduled_condition_config
              condition={this.props.rule}
              disabled={false}
              wizard
              onConditionUpdate={this.props.onConditionUpdate}
            />
          </div>
        </CenteredTabs>
      </>
    );
  }
}

export default ScheduledCond;
