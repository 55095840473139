import {
  SimpleSearchStateOptions,
  useNumberSearchState,
  useStringArraySearchState,
  useStringSearchState,
  useBooleanSearchState,
} from '@dev/base-web/dist/view/components/global/url_param_hooks';
import { useAppDispatch, useAppSelector } from '@/model/redux/hooks.ts';
import { ThunkAction } from 'redux-thunk';
import { RootReducerInterface } from '@/model/redux/interfaces.ts';
import { AnyAction } from 'redux';
import { DependencyList, useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { setActiveTab } from '@/model/redux/instruction_definition/thunks.ts';
import { emptyArray } from '@dev/base-web/dist/model/helpers/immutable_array';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import { useUserConfiguration } from '@dev/base-web/dist/view/components/global/current_user_context';
import { EventListFilterConfig } from '@dev/base-web/dist/model/domain/user/user_details';

export const useGlobalConfigSync = <T>(
  value: T,
  actionCreator: (
    value: T | undefined
  ) => ThunkAction<void, RootReducerInterface, undefined, AnyAction>,
  deps: DependencyList
) => {
  const dispatch = useAppDispatch();

  const globalUpdate = useDebouncedCallback((value: T | undefined) => {
    dispatch(actionCreator(value));
  }, 1000);

  useEffect(() => {
    globalUpdate(value);
  }, [value, ...deps]);
};

/**
 * A helper hook for creating entity id config state which should appear in the URL,
 * and also synchronized to the global redux settings state.
 *
 * The return value is similar to the build in useState hook, use it the same way:
 * ```
 * const [value, setValue] = useGlobalManufacturingEntitiesSearchState({ <config options> })
 * ```
 *
 * @param options configuration for the hook:
 *  - `key`: what should be the name of the URL query string parameter
 *  - `entities`: man. entity array which will be used for populating the returned array (from the id string array)
 *  - `onChange`: called when the state is changed EXTERNALLY. Isn't called during initialization (use standard `useEffect` for that!)
 * @returns similar to what useState returns
 */
export const useGlobalInstructionTabSearchState = ({
  key,
  defaultValue,
  onChange,
}: Pick<
  SimpleSearchStateOptions<number>,
  'key' | 'defaultValue' | 'onChange'
>) => {
  const globalValue = useAppSelector(
    (state) => state.data.instructionDefinitionState.activeTab
  );

  const state = useNumberSearchState({
    key,
    defaultValue: defaultValue || 0,
    initialValue: globalValue,
    onChange,
  });

  useGlobalConfigSync(state[0], (value) => setActiveTab(value || 0), []);

  return state;
};

export const useGlobalStringSearchState = ({
  key = 'eventName',
}: Pick<SimpleSearchStateOptions<string>, 'key' | 'onChange'>) => {
  const listKey = key as keyof EventListFilterConfig;

  const [userConfiguration, , , updateEventListFilterConfigField] =
    useUserConfiguration();

  return useStringSearchState({
    key,
    initialValue:
      (userConfiguration.eventListFilterConfig?.[listKey] as string | null) !==
      null
        ? (userConfiguration.eventListFilterConfig?.[listKey] as string)
        : '',
    onChange: (value) => updateEventListFilterConfigField(listKey, value),
  });
};

export const useGlobalEventTypesSearchState = ({
  key = 'eventTypes',
}: Pick<SimpleSearchStateOptions<readonly string[]>, 'key' | 'onChange'>): [
  readonly EventType[],
  (values: readonly EventType[]) => void
] => {
  const [userConfiguration, , , updateEventListFilterConfigField] =
    useUserConfiguration();

  const [values, setValues] = useStringArraySearchState({
    key,
    initialValue: userConfiguration.eventListFilterConfig?.eventTypes?.length
      ? userConfiguration.eventListFilterConfig?.eventTypes
      : emptyArray(),
    onChange: (value) =>
      updateEventListFilterConfigField(
        key as keyof EventListFilterConfig,
        value as string[]
      ),
  });

  return [(values as EventType[]) ?? emptyArray(), setValues];
};

export const useGlobalEntityIdsSearchState = ({
  key = 'manufacturingEntityIds',
}: Pick<SimpleSearchStateOptions<readonly string[]>, 'key' | 'onChange'>): [
  readonly string[],
  (values: readonly string[] | undefined) => void
] => {
  const [userConfiguration, , , updateEventListFilterConfigField] =
    useUserConfiguration();

  const filterManufacturingEntity = useMemo(() => {
    if (
      userConfiguration.eventListFilterConfig?.manufacturingEntityIds &&
      userConfiguration.eventListFilterConfig.manufacturingEntityIds.length > 0
    ) {
      return userConfiguration.eventListFilterConfig.manufacturingEntityIds;
    } else if (userConfiguration.selectedMainManufacturingEntity) {
      return [
        userConfiguration.selectedMainManufacturingEntity?.id,
      ] as readonly string[];
    }
  }, [
    userConfiguration.eventListFilterConfig?.manufacturingEntityIds,
    userConfiguration.selectedMainManufacturingEntity,
  ]);

  const [values, setValues] = useStringArraySearchState({
    key,
    initialValue: filterManufacturingEntity,
    onChange: (value) =>
      updateEventListFilterConfigField(
        key as keyof EventListFilterConfig,
        value as string[]
      ),
  });

  return [values ?? emptyArray(), setValues];
};

export const useGlobalBooleanSearchState = ({
  key = 'includeSubsequentEvents',
  defaultValue,
}: Pick<
  SimpleSearchStateOptions<boolean>,
  'key' | 'defaultValue' | 'onChange'
>) => {
  const listKey = key as keyof EventListFilterConfig;

  const [userConfiguration, , , updateEventListFilterConfigField] =
    useUserConfiguration();

  return useBooleanSearchState({
    key,
    defaultValue: defaultValue,
    initialValue: userConfiguration.eventListFilterConfig?.[listKey] as boolean,
    onChange: (value) => updateEventListFilterConfigField(listKey, value),
  });
};
