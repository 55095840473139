import DowntimeOverview from '@dev/base-web/dist/model/domain/report/DowntimeOverview';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

export const OPEN_DOWNTIME_REPORT_LIST_ITEM_HEIGHT = 90;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: ${OPEN_DOWNTIME_REPORT_LIST_ITEM_HEIGHT}px;

  padding-top: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ListItemInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  line-height: 16px;
`;

const RightColumn = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

interface Props {
  readonly shiftReportId?: string;
  readonly loading: boolean;
  readonly openDowntimeReport: DowntimeOverview;
}

const OpenDowntimeReportsListItem: React.FC<Props> = ({
  shiftReportId,
  loading,
  openDowntimeReport,
}) => {
  const { formatDate } = useLocalizedDateFormatters();

  const startTime = formatDate(openDowntimeReport.startTimestamp, 'pp');
  const endTime = formatDate(openDowntimeReport.endTimestamp, 'pp');

  const navigate = useNavigate();

  return (
    <ListItem>
      <LeftColumn>
        <ListItemInfo>
          <Icon name="error" size={16} color="#5e0c34" />
          <FormattedMessage id="downtime" />
        </ListItemInfo>
        <ListItemInfo>
          <Icon name="location" size={16} color="#939393" />
          {openDowntimeReport.shiftReportManufacturingEntity.name}
        </ListItemInfo>
        <ListItemInfo>
          <Icon name="time" size={16} color="#939393" />
          {startTime} - {endTime}
        </ListItemInfo>
      </LeftColumn>
      <RightColumn>
        <Button
          icon="report"
          type="secondary"
          circular={true}
          loading={loading}
          disabled={!shiftReportId}
          onClick={() => {
            if (shiftReportId) {
              navigate(
                `/reports/${shiftReportId}?downtime=${openDowntimeReport.id}`
              );
            }
          }}
        />
      </RightColumn>
    </ListItem>
  );
};

export default OpenDowntimeReportsListItem;
