import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import EventRowSimplified from './EventRowSimplified';
import React, { useEffect, useState } from 'react';
import Event, {
  EventDefinitionStatus,
} from '../../../model/domain/event/event_definition';
import styled from 'styled-components';
import { Card } from '@dev/base-web/dist/view/components/global/styled_components';
import { FormattedMessage, useIntl } from 'react-intl';
import TabBar from '@dev/base-web/dist/view/components/global/tab_bar';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { createColumns, TableContainer } from '../../events/view';
import { ConfigValue } from '@dev/base-web/dist/model/domain/configuration/configuration';
import { LoadingMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';

const StyledCard = styled(Card)`
  margin-right: 24px;
  margin-left: 12px;
  margin-top: 36px;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
`;

const tabBarElements = [
  <FormattedMessage id="tab_events" />,
  <FormattedMessage id="tab_inactive_events" />,
  <FormattedMessage id="tab_event_archive" />,
];

export interface EventsCardProps {
  readonly events: Event[];
  readonly hasMoreEvents: boolean;
  readonly loadingInProgress: boolean;
  readonly getEvents: (status: EventDefinitionStatus, page: number) => void;
  readonly manufacturingEntities: ManufacturingEntity[];
  readonly manufacturingEntitiesMeta: LoadingMetaState;
  readonly getAllManufacturingEntities: (query?: string) => void;
  readonly eventOccurrenceCountDuration?: ConfigValue;
}

const EventsCard: React.FC<EventsCardProps> = ({
  events,
  hasMoreEvents,
  loadingInProgress,
  getEvents,
  manufacturingEntities,
  getAllManufacturingEntities,
  manufacturingEntitiesMeta,
  eventOccurrenceCountDuration,
}) => {
  const [activeTab, onTabChanged] = useState(0);

  const intl = useIntl();

  useEffect(() => {
    getEvents(getStatus(), 0);
  }, [activeTab]);

  const getStatus = () => {
    if (activeTab === 0) {
      return EventDefinitionStatus.ACTIVE;
    } else if (activeTab === 1) {
      return EventDefinitionStatus.INACTIVE;
    }
    return EventDefinitionStatus.ARCHIVED;
  };

  return (
    <StyledCard>
      <TableHeaderContainer>
        <TabBar
          titles={tabBarElements}
          tabIndex={activeTab}
          onSelectedIndexChange={onTabChanged}
        />
      </TableHeaderContainer>
      <TableContainer>
        <SortableTable
          dataLength={events.length}
          hasMoreResults={hasMoreEvents}
          columns={createColumns(
            intl,
            eventOccurrenceCountDuration,
            manufacturingEntities,
            getAllManufacturingEntities,
            manufacturingEntitiesMeta.loadingInProgress
          )}
          getNextResults={(page) => {
            getEvents(getStatus(), page);
          }}
          loadingInProgress={loadingInProgress}
        >
          {events.map((event) => {
            return <EventRowSimplified data={event} />;
          })}
        </SortableTable>
      </TableContainer>
    </StyledCard>
  );
};

export default EventsCard;
