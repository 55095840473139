import React, { useEffect, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { AddMark, CompleteMark } from '../../../StepMarks';

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;
`;

const selectedImageThumbnailStyle = (theme: DefaultTheme) =>
  `border: solid 3px ${theme.colors.positive};`;

const ImageThumbnail = styled.img<{ selected: boolean }>`
  height: 80px;
  border-radius: 4px;
  ${({ selected, theme }) =>
    selected ? selectedImageThumbnailStyle(theme) : ''}
`;

interface PreUploadImageThumbnailProps {
  readonly file: File;
  readonly selected: boolean;
  readonly onSelect: () => void;
  readonly completed: boolean;
}

const PreUploadImageThumbnail = ({
  file,
  onSelect,
  selected,
  completed,
}: PreUploadImageThumbnailProps) => {
  const [imageSrc, setImageSrc] = useState<string | undefined>();

  useEffect(() => {
    const fileUrl = URL.createObjectURL(file);
    setImageSrc(fileUrl);
    return () => URL.revokeObjectURL(fileUrl);
  }, [file]);

  return (
    <ImageContainer>
      {completed ? <CompleteMark /> : <AddMark icon="edit" />}
      <ImageThumbnail
        onClick={onSelect}
        src={imageSrc}
        selected={selected}
      ></ImageThumbnail>
    </ImageContainer>
  );
};

export default PreUploadImageThumbnail;
