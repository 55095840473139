import EventDetail, { EventForwardRequest } from '../../domain/event/event';
import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';

const CURRENT_EVENT_URL = (id: string) => `/event/${id}`;

export default class EventApi extends BaseDataApi<EventDetail> {
  async getCurrentEvent(id: string, token: string): Promise<EventDetail> {
    const url = CURRENT_EVENT_URL(id);
    return await this.fetchOneItemByIdWithUrl(token, url);
  }

  async closeEvent(eventId: string, token: string, closeDate?: number) {
    await this.executePostRequest(
      token,
      `${CURRENT_EVENT_URL(eventId)}/close`,
      undefined,
      {
        closeDate,
      }
    );
  }

  async archiveEvents(token: string, ids: string[], isHidden: boolean) {
    const url = `/event/definition/archive/${isHidden}`;

    return this.executePatchRequest(token, url, undefined, { ids });
  }

  async forwardEvent(token: string, id: string, request: EventForwardRequest) {
    const url = `/event/${id}/forward`;
    return this.executePostRequest(token, url, undefined, request);
  }
}
