import React from 'react';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import useFilterState from './filter_hook';
import { DropdownProps } from 'semantic-ui-react';
//import Product from '../../../../../model/domain/product';
import { FilterProps } from './interfaces';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';

interface UserFilterProps extends FilterProps {
  users: UserDetails[];
}

const UserFilter: React.FC<UserFilterProps> = (props) => {
  const { localFilters, setLocalFilters, externalFilters, users } = props;

  const [usersId, setUsersId] = useFilterState<string>(
    'userId',
    { groupingKey: 'user', icon: 'user' },
    (_value, filterContext) => {
      return `${filterContext.firstName} ${filterContext.lastName}`;
    },
    localFilters,
    setLocalFilters,
    externalFilters
  );

  const usersOptions = users.map((user) => ({
    value: user.id,
    text: `${user.firstName} ${user.lastName}`,
  }));

  return (
    <StyledDropDown
      fluid
      clearable
      selection
      value={usersId}
      options={usersOptions}
      onChange={(
        _event: React.SyntheticEvent<HTMLElement>,
        data: DropdownProps
      ) => {
        const user = users.find((user) => user.id === data.value);
        setUsersId(data.value as string, user);
      }}
    />
  );
};

export default UserFilter;
