import React from 'react';
import { FormattedMessage } from 'react-intl';
import ToggleSwitch from '@dev/base-web/dist/view/components/inputs/toggle_switch';
import styled from 'styled-components';
import { Hint } from './../../event_definition/components/Hint';
import { UserTag } from './../../event_definition/components/UserTag';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import { Button } from '@dev/base-web/dist/view/components/global/button';

interface SubscriberConfigProps {
  readonly isCommonNotification: boolean;
  readonly subscribers: UserDetails[];
  readonly allowedToModify: boolean;
  readonly setIsCommonNotification: (isCommonNotification: boolean) => void;
  readonly selfUser: UserDetails | null;
  readonly subscribeToEvent: () => void;
  readonly unsubscribeFromEvent: () => void;
}

const Container = styled.div`
  padding-top: 48px;
  font-family: Relative;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #8c8c8c;
  max-width: 440px;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 18px;
`;

const HorizontalLabel = styled.span`
  margin-right: 20px;
`;

const UserTags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 12px;
`;

const Info = styled.p`
  margin: 0;
  padding-top: 24px;
  padding-bottom: 8px;
`;

const SubscribersWizard: React.FC<SubscriberConfigProps> = ({
  isCommonNotification,
  setIsCommonNotification,
  allowedToModify,
  subscribers,
  subscribeToEvent,
  unsubscribeFromEvent,
  selfUser,
}) => {
  const isSelfSubscribed =
    selfUser && subscribers.some((s) => s.id === selfUser.id);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Container>
        <OptionContainer>
          <HorizontalLabel>
            <FormattedMessage id="common_notification" />
          </HorizontalLabel>
          <ToggleSwitch
            checked={isCommonNotification}
            onChange={setIsCommonNotification}
            disabled={!allowedToModify}
          />
        </OptionContainer>
        <Hint messageKey="event_subscription_hint" />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '18px',
          }}
        >
          <Info>
            <FormattedMessage id="subscribed_users" />
          </Info>
          <UserTags>
            {subscribers.map((s) => (
              <UserTag user={s} />
            ))}
            <Button
              type="tertiary"
              size="medium"
              label={isSelfSubscribed ? 'unsubscribe' : 'subscribe'}
              onClick={() =>
                isSelfSubscribed ? unsubscribeFromEvent() : subscribeToEvent()
              }
            />
          </UserTags>
        </div>
      </Container>
    </div>
  );
};

export default SubscribersWizard;
