import { connect } from 'react-redux';
import { RootReducerInterface } from '../../../../model/redux/interfaces';
import { DowntimeCardStoreProsInterface } from './interface';
import Dashboard from './view';
import {
  getAggregatedDowntimes,
  resetAggregatedDowntimes,
} from '../../../../model/redux/downtime/thunks';

const mapStateToProps = (
  state: RootReducerInterface
): DowntimeCardStoreProsInterface => {
  return {
    aggregatedDowntimes:
      state.data.aggregatedDowntimesState.aggregatedDowntimes.data.results,
    aggregatedDowntimesMeta:
      state.data.aggregatedDowntimesState.aggregatedDowntimes.meta,
  };
};

export default connect(mapStateToProps, {
  getAggregatedDowntimes,
  resetAggregatedDowntimes,
})(Dashboard);
