import ModalWithHeaderAndTwoButtons from '@dev/base-web/dist/view/components/modal/modal';
import { FormattedMessage, useIntl } from 'react-intl';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';
import RichTextEditor, {
  RichText,
} from '@dev/rich-text-editor/dist/components/RichTextEditor/view';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { DropdownProps } from 'semantic-ui-react';
import { ForwardEventModalProps } from './interface';
import UserAvatar from '@dev/base-web/dist/view/components/global/dummy_avatar';
import { Theme } from '@dev/base-web/dist/view/themes/interface';
import {
  toastrError,
  useNotificationForOperation,
} from '@dev/base-web/dist/view/helpers/notification_helpers';

const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const DropdownItemContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

export const StyledDropdown = styled(StyledDropDown)`
  &&&&&&&&& {
    width: 288px;
    margin-bottom: 12px;
    z-index: 999;
  }
`;

const StyledUserAvatar = styled(UserAvatar)`
  margin-right: 8px;
`;

const HeaderContainer = styled.div``;

const Title = styled.div`
  margin-bottom: 12px;
`;

const StyledHint = styled(Hint)`
  text-align: left;
`;

export const StyledLabelWithHint = styled(LabelWithHint)`
  margin-bottom: 6px;
`;

const Header = ({ theme }: { theme: Theme }) => {
  return (
    <HeaderContainer>
      <Title>
        <StyledIcon
          name={'arrow-rightdash'}
          color={theme.colors.primary}
          size={16}
        />
        <FormattedMessage id={'forward_event'} />
      </Title>
      <StyledHint
        messageKey={'forward_event_hint'}
        textColor={theme.colors.warmGrey}
      />
    </HeaderContainer>
  );
};

const ForwardEventModal = ({
  event,
  usersMeta,
  users,
  getAllUsers,
  forwardEvent,
  forwardEventMeta,
  images,
  selected,
  uploads,
  updating,
  clearUploadedPictures,
  clearSelectedPicture,
  uploadFiles,
}: ForwardEventModalProps) => {
  const theme = useTheme();
  const intl = useIntl();

  const [description, setDescription] = useState<RichText>();
  const [assignee, setAssignee] = useState<string>();

  const richTextRef = useRef<{ resetEditor: () => void }>(null);

  useEffect(() => {
    getAllUsers([]);
  }, []);

  const userOptions = useMemo(() => {
    return users.map((user) => ({
      text:
        user.firstName && user.lastName
          ? `${user.firstName} ${user.lastName}`
          : user.username,
      value: user.id,
      image: (
        <StyledUserAvatar
          name={
            !user.firstName && !user.lastName
              ? user.username
              : `${user.firstName || '-'} ${user.lastName || '-'}`
          }
          src={user.pictures.thumb}
          size={'24px'}
        />
      ),
    }));
  }, [users]);

  const onOkPressed = () => {
    if (assignee && description)
      forwardEvent(event.id, {
        type: 'MEASURE',
        targetUser: assignee,
        message: JSON.stringify(description),
      });
  };

  const onUserDropdownSelect = (
    _event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    setAssignee(data.value as unknown as string);
  };

  const isDescriptionEmpty = () => {
    return (
      !description ||
      (description.length &&
        description[0].children.length &&
        !description[0].children[0].text)
    );
  };

  useNotificationForOperation(
    forwardEventMeta,
    'event_forwarded',
    'event_forwarded',
    'event_forwarded',
    intl,
    () => {
      setAssignee(undefined);
      setDescription(undefined);
    }
  );

  return (
    <ModalWithHeaderAndTwoButtons
      headerLabel={<Header theme={theme} />}
      okLabel={'forward'}
      okIcon={'arrow-rightdash'}
      okDisabled={isDescriptionEmpty() || !assignee}
      okLoading={forwardEventMeta.operationInProgress}
      cancelLabel={'cancel'}
      cancelIcon={'cross'}
      onModalOkPressed={onOkPressed}
      triggerButton={
        <DropdownItemContent>
          <StyledIcon
            name="arrow-rightdash"
            color={theme.colors.primary}
            size={16}
          />
          <FormattedMessage id={'forward'} />
        </DropdownItemContent>
      }
    >
      <Container>
        <StyledLabelWithHint label={'forward_to'} />
        <StyledDropdown
          placeholder={intl.formatMessage({ id: 'select_user' })}
          value={assignee}
          selection
          search
          onChange={onUserDropdownSelect}
          options={userOptions}
          error={!assignee}
          noResultsMessage={intl.formatMessage({
            id: 'no_users_found',
          })}
          loading={usersMeta.loadingInProgress}
          clearable
        />
        <StyledLabelWithHint label={'description'} />
        <RichTextEditor
          ref={richTextRef}
          maxHeight={400}
          placeholder={intl.formatMessage({
            id: 'why_are_you_forwarding',
          })}
          onChange={setDescription}
          images={images}
          selected={selected}
          uploads={uploads}
          updating={updating}
          clearUploadedPictures={clearUploadedPictures}
          clearSelectedPicture={clearSelectedPicture}
          uploadFiles={uploadFiles}
          toastrError={toastrError}
        />
      </Container>
    </ModalWithHeaderAndTwoButtons>
  );
};

export default ForwardEventModal;
