import React from 'react';
import styled from 'styled-components';
import { ThemeProps } from '@dev/base-web/dist/view/themes/interface';
import { TextArea } from './TextArea';

const DEFAULT_MAX_LENGTH = 250;

interface StyledInputProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  readonly showCount?: boolean;
}

const InputCount = styled.span`
  float: right;
  color: ${({ theme }: ThemeProps) => theme.colors.lightGrey};
  padding-top: 4px;
`;

function TextAreaWithCount(props: StyledInputProps) {
  const length = props.maxLength || DEFAULT_MAX_LENGTH;

  return (
    <div style={{ height: 126, width: '100%' }}>
      <TextArea {...props} maxLength={props.showCount ? length : undefined} />
      {props.showCount && (
        <InputCount>
          {length - (props.value ? (props.value as string) : '').length}
        </InputCount>
      )}
    </div>
  );
}

export default TextAreaWithCount;
