import { Button } from '@dev/base-web/dist/view/components/global/button';
import { AdditionSolutionInfo } from '@/model/domain/instruction/instruction.ts';
import styled from 'styled-components';
import React from 'react';
import DeleteConfirmationModal from '@dev/base-web/dist/view/components/modal/delete_confirmation_modal';
import AdditionalSolutionInfoModal from './additional_solution_info_modal';

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const ListElement = styled.li`
  text-indent: 20px;
  padding-left: 10px;
  list-style: none;
  position: relative;
  :before {
    content: '-';
    left: -6px;
    position: absolute;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.buttons.tertiary.textColor};
    text-decoration: underline;
  }
  margin-right: 8px;
`;

interface Props {
  readonly infos: readonly AdditionSolutionInfo[];
  readonly editMode?: boolean;
  readonly allowedToModify?: boolean;
  readonly updateInfos?: (info: readonly AdditionSolutionInfo[]) => void;
}

const AdditionalSolutionInfo = ({
  infos,
  editMode,
  allowedToModify,
  updateInfos,
}: Props) => {
  const addNewInfo = (info: AdditionSolutionInfo) => {
    updateInfos && updateInfos([...infos, info]);
  };

  const onDeleteInformation = (info: AdditionSolutionInfo) => {
    updateInfos && updateInfos(infos.filter((i) => i.url !== info.url));
  };

  const updateInfo = (
    info: AdditionSolutionInfo,
    oldInfo: AdditionSolutionInfo
  ) => {
    updateInfos &&
      updateInfos([...infos.filter((i) => i.url !== oldInfo.url), info]);
  };

  return (
    <div>
      {infos.length > 0 && (
        <List>
          {infos.map((info) => {
            return (
              <ListElement>
                <Link href={info.url} target="_blank">
                  {info.label}
                </Link>
                {editMode && (
                  <>
                    <AdditionalSolutionInfoModal
                      info={info}
                      triggerButton={
                        <Button
                          type="tertiary"
                          icon="edit"
                          noMargin
                          size="small"
                          disabled={!allowedToModify}
                        />
                      }
                      isUpdate={true}
                      update={(newInfo) => updateInfo(newInfo, info)}
                      close={() => {}}
                      triggerDisabled={!allowedToModify}
                    />
                    <DeleteConfirmationModal
                      title="delete_additional_info"
                      primaryButtonTitle={'delete'}
                      confirmationTitle="delete_additional_info_confirmation"
                      onDeleteConfirmed={() => onDeleteInformation(info)}
                      triggerDisabled={!allowedToModify}
                      triggerButton={
                        <Button
                          type="tertiary"
                          icon="delete"
                          noMargin
                          size="small"
                          disabled={!allowedToModify}
                        />
                      }
                    />
                  </>
                )}
              </ListElement>
            );
          })}
        </List>
      )}
      {editMode && (
        <AdditionalSolutionInfoModal
          info={{ label: '', url: '' }}
          triggerButton={
            <Button
              type="tertiary"
              icon="plus"
              label="add_more_information"
              disabled={!allowedToModify}
            />
          }
          isUpdate={false}
          triggerDisabled={!allowedToModify}
          update={addNewInfo}
          close={() => {}}
        />
      )}
    </div>
  );
};

export default AdditionalSolutionInfo;
