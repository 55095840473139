import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { To, useNavigate, useParams } from 'react-router-dom';
import TabBar from '@dev/base-web/dist/view/components/global/tab_bar';
import ShannonConfig from '@dev/base-web/dist/model/domain/authorization/shannon';
import EventCard from '../../components/Card/EventCard';
import * as Constants from '../../Constants';
import { ScreenRoot } from '@dev/base-web/dist/view/components/global/styled_components';
import {
  CardRow,
  MultiCardLayout,
} from '@dev/base-web/dist/view/components/global/card';
import {
  showNotificationForOperation,
  useNotificationForOperation,
  useNotificationForOperationError,
} from '@dev/base-web/dist/view/helpers/notification_helpers';
import Tour from '../../components/tour/Tour';
import { usePrevious } from '@dev/base-web/dist/view/helpers/use_previous';
import {
  addStringParam,
  useStringSearchState,
} from '@dev/base-web/dist/view/components/global/url_param_hooks';
import { CONFIG, CurrentEventsProps } from './interface';
import {
  EventListColumn,
  SlideInAnimation,
  TabBarContainer,
  NarrowColumn,
  WideColumn,
  Wrapper,
} from './components';
import Header from '@dev/base-web/dist/view/components/global/header_view/index';
import { InstructionCard } from '../../components/instruction/instruction_card';
import { Instruction } from '@/model/domain/instruction/instruction.ts';
import { useWindowSize } from '@dev/base-web/dist/view/themes/helpers';
import { NotificationModal } from '@dev/base-web/dist/view/components/modal/modal';
import styled from 'styled-components';
import Loader from '@dev/base-web/dist/view/components/global/loader';
import { EventInterface } from '@dev/base-web/dist/model/domain/event/event_type';
import EventsListCardWithFilters from '@/components/events_list_with_filters';
import { ComponentType } from '@/components/events_list_with_filters/interface';

const ActionModal = styled(NotificationModal)`
  &&&&& .content {
    padding: 0;
  }
`;

export enum EventStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

const eventStatusParamsConfig = {
  key: 'status',
  defaultValue: EventStatus.OPEN,
};

const useEventsStatus = (): [EventStatus, (value: EventStatus) => void] => {
  const [status, setStatus] = useStringSearchState(eventStatusParamsConfig);

  return [(status as EventStatus) || EventStatus.OPEN, setStatus];
};

const url = (status: EventStatus, id?: string): To => {
  const params = new URLSearchParams();
  addStringParam(status, params, eventStatusParamsConfig);

  return {
    pathname: `/${Constants.CURRENTEVENTSSLUG}/${id || ''}`,
    search: params.toString(),
  };
};

const CurrentEvents: React.FC<CurrentEventsProps> = ({
  currentInstruction,
  currentInstructionMeta,
  currentEvent,
  currentEventMeta,
  currentUser,
  feedbackMeta,
  getCurrentInstruction,
  getCurrentEvent,
  sendInstructionFeedback,
  closeEvent,
  closeEventUpdate,
  cleanCurrentInstruction,
  configs,
  activeEvents,
  closedEvents,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();

  const [activeInstruction, setActiveInstruction] =
    useState<Instruction | null>(null);
  const [selectedEventId, setSelectedEventId] = useState<string | undefined>(
    params.id
  );
  const prevFeedbackMeta = usePrevious(feedbackMeta);
  const [selectedEvent, setSelectedEvent] = useState<
    EventInterface | undefined
  >();
  const [eventCardAnimationTrigger, setEventCardAnimationTrigger] = useState(0);

  const [status, setStatus] = useEventsStatus();

  const { width } = useWindowSize();
  const isScreenWiderThanTablet = width > 1200;

  useEffect(() => {
    prevFeedbackMeta &&
      showNotificationForOperation(
        prevFeedbackMeta,
        feedbackMeta,
        'feedback_sent',
        'feedback_sent',
        'feedback_sent',
        intl,
        () => currentEvent && getCurrentEvent(currentEvent.id)
      );
  }, [feedbackMeta]);

  useNotificationForOperation(
    closeEventUpdate,
    'task_closed',
    'task_closed',
    'task_closed',
    intl,
    () => currentEvent && delayedEventCall(currentEvent.id)
  );

  const delayedEventCall = (eventId: string) => {
    setTimeout(function () {
      getCurrentEvent(eventId);
    }, 2000);
  };

  useNotificationForOperationError(closeEventUpdate, intl);

  useEffect(() => {
    cleanCurrentInstruction();
    setActiveInstruction(null);
    setSelectedEventId(params.id);
    params.id && getCurrentEvent(params.id);
  }, [params.id]);

  useEffect(() => {
    if (currentEvent && activeInstruction) {
      getCurrentInstruction(currentEvent.id, activeInstruction.id, intl.locale);
    }
  }, [currentEvent, intl.locale]);

  const onEventSelect = (id?: string) => navigate(url(status, id));

  useEffect(() => {
    setActiveInstruction(currentInstruction);
  }, [currentInstruction]);

  useEffect(() => {
    onEventSelect(selectedEventId);
  }, [selectedEventId]);

  const eventSelectedFromList = (eventId?: string) => {
    onEventSelect(eventId);
    if (eventId && eventId !== selectedEventId) {
      setEventCardAnimationTrigger((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (selectedEventId !== selectedEvent?.id) {
      if (selectedEventId) {
        let event = activeEvents.data.results.find(
          (event) => event.id === selectedEventId
        );
        if (!event) {
          event = closedEvents.data.results.find(
            (event) => event.id === selectedEventId
          );
        }
        if (event) {
          setSelectedEvent(event);
        } else {
          setSelectedEvent(undefined);
        }
      } else {
        setSelectedEvent(undefined);
      }
    }
  }, [selectedEventId, activeEvents, closedEvents]);

  const onInstructionSelect = (eventId: string, actionId: string) => {
    setActiveInstruction(null);
    getCurrentInstruction(eventId, actionId, intl.locale);
  };

  const closeInstruction = () => {
    setActiveInstruction(null);
  };

  const submitFeedback = async (
    isPositiveVote: boolean,
    feedbackText: string
  ) => {
    const userId = currentUser?.id;

    if (activeInstruction && currentEvent && userId) {
      await sendInstructionFeedback(currentEvent.id, activeInstruction.id, {
        userId,
        isPositive: isPositiveVote,
        feedbackText,
      });
      setActiveInstruction({
        ...activeInstruction,
        isPositiveVote,
        feedbackText,
      });
    }
  };

  const changeEvents = (openEvents: boolean) => {
    setStatus(openEvents ? EventStatus.OPEN : EventStatus.CLOSED);
    setSelectedEventId(undefined);
    setActiveInstruction(null);
  };

  return (
    <ScreenRoot>
      <Header
        title={CONFIG.label ?? ''}
        backButtonTitle={ShannonConfig.Dashboard.label}
        onBackPressed={() => navigate('/', { replace: true })}
      />
      <Tour page="currentEvents" activeSolution={activeInstruction} />
      <Wrapper>
        <TabBarContainer>
          <div id="tab_bar" style={{ width: 'fit-content' }}>
            <TabBar
              titles={[
                <FormattedMessage id="tab_curr_events" />,
                <FormattedMessage id="tab_closed_events" />,
              ]}
              tabIndex={status === EventStatus.OPEN ? 0 : 1}
              onSelectedIndexChange={(index: number) =>
                changeEvents(index === 0)
              }
            />
          </div>
        </TabBarContainer>
        <MultiCardLayout>
          <CardRow>
            <EventListColumn
              isActionOpen={
                !!activeInstruction ||
                (!!selectedEventId && !isScreenWiderThanTablet)
              }
            >
              <EventsListCardWithFilters
                onEventSelect={eventSelectedFromList}
                tab={status}
                selectedEvent={
                  selectedEventId && currentEvent ? currentEvent : undefined
                }
                componentType={ComponentType.events_list}
              />
            </EventListColumn>
            {(!!selectedEventId || isScreenWiderThanTablet) &&
              // When the currentEvent is first loaded it will change from null to an object
              // this will trigger a re-render and the animation will be played 1 extra time
              currentEvent !== null && (
                <NarrowColumn>
                  <SlideInAnimation key={eventCardAnimationTrigger}>
                    {selectedEvent && (currentEvent || currentEventMeta) && (
                      <EventCard
                        eventId={selectedEvent.id}
                        eventDefId={selectedEvent.defId}
                        initialEventInfo={selectedEvent}
                        onInstructionSelect={onInstructionSelect}
                        editable={false}
                        item={currentEvent}
                        onEventNeedsToBeRefreshed={() =>
                          getCurrentEvent(selectedEvent.id)
                        }
                        currentUser={currentUser}
                        closeEvent={closeEvent}
                        configs={configs}
                        cleanInstruction={cleanCurrentInstruction}
                        onClosePressed={() => setSelectedEventId(undefined)}
                        otherEvents={
                          status === EventStatus.CLOSED
                            ? closedEvents
                            : activeEvents
                        }
                        onEventSelected={setSelectedEventId}
                        showEventListIndicator={!isScreenWiderThanTablet}
                        eventLoadingMetaState={currentEventMeta}
                      />
                    )}
                  </SlideInAnimation>
                </NarrowColumn>
              )}
            <WideColumn
              isActionOpen={
                isScreenWiderThanTablet &&
                (activeInstruction !== null ||
                  !!currentInstructionMeta.loadingInProgress ||
                  !!currentInstructionMeta.error)
              }
            >
              {(activeInstruction !== null ||
                currentInstructionMeta.loadingInProgress ||
                currentInstructionMeta.error) && (
                <SlideInAnimation>
                  <div id="solution">
                    <InstructionCard
                      item={activeInstruction}
                      closeAction={closeInstruction}
                      feedbackAction={submitFeedback}
                      meta={currentInstructionMeta}
                      selectedLanguage={intl.locale}
                    />
                  </div>
                </SlideInAnimation>
              )}
            </WideColumn>
            {!isScreenWiderThanTablet && (
              <>
                <ActionModal
                  open={activeInstruction !== null}
                  onClose={closeInstruction}
                  modalSize="small"
                >
                  <InstructionCard
                    item={activeInstruction}
                    closeAction={closeInstruction}
                    feedbackAction={submitFeedback}
                    meta={currentInstructionMeta}
                    selectedLanguage={intl.locale}
                  />
                </ActionModal>
                {!!currentInstructionMeta.loadingInProgress && <Loader />}
              </>
            )}
          </CardRow>
        </MultiCardLayout>
      </Wrapper>
    </ScreenRoot>
  );
};

export default CurrentEvents;
