import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ToggleSwitch from '@dev/base-web/dist/view/components/inputs/toggle_switch';
import {
  Container,
  HorizontalLabel,
  Info,
  OptionContainer,
  UserTags,
} from './components';
import { Hint } from '../Hint';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { EventSubscriberConfigProps } from './interfaces';
import { UserTag } from '../UserTag';
import { showNotificationForOperation } from '@dev/base-web/dist/view/helpers/notification_helpers';
import { OperationMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import { usePrevious } from '@dev/base-web/dist/view/helpers/use_previous';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

const UNSUBSCRIBE_QUERY_PARAM = 'unsubscribe';

function useUnsubscribeProperty() {
  const { search } = useLocation();

  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  const unsubscribeQueryParam = query.get(UNSUBSCRIBE_QUERY_PARAM);
  return unsubscribeQueryParam && unsubscribeQueryParam === 'true';
}

const EventSubscriberConfig: React.FC<EventSubscriberConfigProps> = ({
  item,
  eventId,
  isCommonNotification,
  setIsCommonNotification,
  allowedToModify,
  subscribeToEvent,
  unsubscribeFromEvent,
  subscribers,
  selfUser,
  subscribeOperationStatus,
  unsubscribeOperationStatus,
  getEventTrigger,
}) => {
  const intl = useIntl();

  const prevSubscribeStatus = usePrevious(subscribeOperationStatus);
  const prevUnsubscribeStatus = usePrevious(unsubscribeOperationStatus);
  const unsubscribe = useUnsubscribeProperty();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  useEffect(() => {
    prevSubscribeStatus !== undefined &&
      showNotificationForOperation(
        prevSubscribeStatus as unknown as OperationMetaState,
        subscribeOperationStatus,
        'event_subscribed_success',
        'event_subscribed_success',
        'event_subscribed_success',
        intl
      );

    if (
      prevSubscribeStatus !== undefined &&
      !subscribeOperationStatus.operationInProgress &&
      !subscribeOperationStatus.error
    ) {
      getEventTrigger(eventId);
    }
  }, [subscribeOperationStatus]);

  useEffect(() => {
    prevUnsubscribeStatus !== undefined &&
      showNotificationForOperation(
        prevUnsubscribeStatus as unknown as OperationMetaState,
        unsubscribeOperationStatus,
        'event_unsubscribed_success',
        'event_unsubscribed_success',
        'event_unsubscribed_success',
        intl
      );

    if (
      prevUnsubscribeStatus !== undefined &&
      !unsubscribeOperationStatus.operationInProgress &&
      !unsubscribeOperationStatus.error
    ) {
      getEventTrigger(eventId);
    }
  }, [unsubscribeOperationStatus]);

  const isSelfSubscribed =
    selfUser && subscribers.some((s) => s.id === selfUser.id);

  useEffect(() => {
    if (isSelfSubscribed && unsubscribe) {
      unsubscribeFromEvent(eventId);
      const searchParams = new URLSearchParams(search);
      searchParams.delete(UNSUBSCRIBE_QUERY_PARAM);
      navigate({ pathname, search: searchParams.toString() });
    }
  }, [isSelfSubscribed, unsubscribe]);

  return (
    <Container isInternal={item.isInternal}>
      <OptionContainer>
        <HorizontalLabel>
          <FormattedMessage id="common_notification" />
        </HorizontalLabel>
        <ToggleSwitch
          checked={isCommonNotification}
          onChange={setIsCommonNotification}
          disabled={!allowedToModify}
        />
      </OptionContainer>
      <Hint messageKey="event_subscription_hint" />
      <div
        style={{ display: 'flex', flexDirection: 'column', paddingTop: '18px' }}
      >
        <Info>
          <FormattedMessage id="subscribed_users" />
        </Info>
        <UserTags>
          {subscribers.map((s) => (
            <UserTag user={s} />
          ))}
          <Button
            type="tertiary"
            size="medium"
            label={isSelfSubscribed ? 'unsubscribe' : 'subscribe'}
            onClick={() =>
              isSelfSubscribed
                ? unsubscribeFromEvent(eventId)
                : subscribeToEvent(eventId)
            }
          />
        </UserTags>
      </div>
    </Container>
  );
};

export default EventSubscriberConfig;
