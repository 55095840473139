import React from 'react';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import { AggregationChildRule } from '../../../../model/domain/event/event_definition';
import { Column } from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import { AggregationChildRuleRow } from './aggregation_child_rule_row';

export interface AggregationChildRulesTableProps {
  readonly rules: AggregationChildRule[];
}

const columns: Column[] = [
  {
    title: 'manufacturing_entity',
    width: 6,
    isLeftAligned: true,
  },
  {
    title: 'aggregated_child_rule_column_event',
    width: 10,
    isLeftAligned: true,
  },
];

export const AggregationChildRulesTable: React.FC<
  AggregationChildRulesTableProps
> = ({ rules }) => {
  return (
    <SortableTable
      dataLength={rules.length}
      hasMoreResults={false}
      columns={columns}
      loadingInProgress={false}
      getNextResults={() => {}}
      filtersDisabled
      height={'11em'}
      minHeight={'11em'}
    >
      {rules.map((rule) => {
        return <AggregationChildRuleRow key={rule.childRuleId} rule={rule} />;
      })}
    </SortableTable>
  );
};
