import { StepDTO } from '@/model/domain/instruction/step.ts';
import { v4 as uuid } from 'uuid';

export function addStep(
  steps: readonly StepDTO[],
  newStep: Omit<StepDTO, 'step'>,
  position: 'above' | 'below',
  referenceStepNumber?: number,
  parentId?: string
): readonly StepDTO[] {
  const s = addStepInternal(
    steps,
    newStep,
    position,
    referenceStepNumber,
    parentId
  );
  return enumerateSteps(s);
}

function addStepInternal(
  steps: readonly StepDTO[],
  newStep: Omit<StepDTO, 'step'>,
  position: 'above' | 'below',
  referenceStepNumber?: number,
  parentId?: string,
  isActualParent?: boolean
): readonly StepDTO[] {
  if (!parentId || isActualParent) {
    if (referenceStepNumber) {
      const idx = steps.findIndex((s) => s.step === referenceStepNumber);

      if (idx >= 0) {
        const updatedSteps = [...steps];
        if (position === 'above')
          insertAbove(updatedSteps, idx, {
            ...newStep,
            step: referenceStepNumber + 1,
          });
        else
          insertBelow(updatedSteps, idx, {
            ...newStep,
            step: referenceStepNumber + 1,
          });

        return updatedSteps;
      }
    } else if (!steps.length) {
      return [{ ...newStep, step: 1 }];
    }

    return steps;
  } else {
    return steps.map((s) => ({
      ...s,
      decisionOptions: s.decisionOptions.map((o) => ({
        ...o,
        steps: addStepInternal(
          o.steps,
          newStep,
          position,
          referenceStepNumber,
          parentId,
          o.id === parentId
        ),
      })),
    }));
  }
}

export function enumerateSteps(
  steps: readonly StepDTO[],
  initialNumber?: number
): readonly StepDTO[] {
  let number = 1;

  if (initialNumber) {
    number = initialNumber;
  }

  return steps.map((step, index) => ({
    ...step,
    step: index + number,
    decisionOptions: step.decisionOptions.map((decision) => ({
      ...decision,
      steps: enumerateSteps(decision.steps, index + number + 1),
    })),
  }));
}

function insertBelow(
  array: StepDTO[],
  index: number,
  newElement: StepDTO
): void {
  if (index >= 0 && index < array.length) {
    array.splice(index + 1, 0, newElement);
  } else {
    console.error('Index out of bounds');
  }
}

function insertAbove(
  array: StepDTO[],
  index: number,
  newElement: StepDTO
): void {
  if (index >= 0 && index < array.length) {
    array.splice(index, 0, newElement);
  } else {
    console.error('Index out of bounds');
  }
}

export function addEmptyStep(
  steps: readonly StepDTO[],
  position: 'above' | 'below',
  referenceStepNumber: number,
  parentId?: string
): readonly StepDTO[] {
  const newStep: Omit<StepDTO, 'step'> = {
    type: 'INSTRUCTION',
    decisionOptions: [],
    additionalInfos: [],
    media: null,
    translations: [],
    dummyId: uuid(),
  };

  return addStep(steps, newStep, position, referenceStepNumber, parentId);
}

export const rebuildStepsRecursiveModify = (
  steps: readonly StepDTO[],
  oldStep: StepDTO,
  newStep: StepDTO,
  parentId?: string
): readonly StepDTO[] => {
  return rebuildStepsRecursiveModifyInternal(steps, oldStep, newStep, parentId);
};

const rebuildStepsRecursiveModifyInternal = (
  steps: readonly StepDTO[],
  oldStep: StepDTO,
  newStep: StepDTO,
  parentId?: string,
  isActualParent?: boolean
): readonly StepDTO[] => {
  if (!parentId || isActualParent) {
    const idx = steps.findIndex((s) => s.step === oldStep.step);

    const pre = steps.slice(0, idx);
    const post = steps.slice(idx + 1);

    return [...pre, newStep, ...post];
  } else {
    return steps.map((step) => ({
      ...step,
      decisionOptions: step.decisionOptions.map((o) => ({
        ...o,
        steps: rebuildStepsRecursiveModifyInternal(
          o.steps,
          oldStep,
          newStep,
          parentId,
          o.id === parentId
        ),
      })),
    }));
  }
};
