import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Stage, Layer, Line, Image } from 'react-konva';
import Konva from 'konva';
import LineConfig = Konva.LineConfig;
import useImage from 'use-image';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import {
  ButtonWrapper,
  FunctionContainer,
  FunctionWrapper,
  ImageContainer,
} from '../styled_components';
import styled from 'styled-components';
import sizeMe, { SizeMeProps } from 'react-sizeme';

const ImageContainerWithConstrainedHeight = styled(ImageContainer)`
  display: flex;
  justify-content: center;
  margin: 0px 24px 24px;
`;

const StageWithCrosshairCursor = styled(Stage)`
  cursor: crosshair;
`;

interface DrawOnImageViewProps {
  readonly src: string;
  readonly onDrawApplied: (canvas: HTMLCanvasElement) => Promise<void>;
  readonly onDrawCancelled: () => void;
  readonly loadedImage?: HTMLImageElement;
}

const DrawOnImageView = ({
  src,
  onDrawApplied,
  onDrawCancelled,
  loadedImage,
  size,
  children,
}: PropsWithChildren<DrawOnImageViewProps & SizeMeProps>) => {
  const [lines, setLines] = useState<LineConfig[]>([]);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const isDrawing = useRef(false);
  const [image] = useImage(src, 'anonymous');
  const stageRef = useRef<any>();

  useEffect(() => {
    if (loadedImage && size && size.height && size.width) {
      let newWidth = loadedImage.width * (size.height / loadedImage.height);
      let newHeight = Math.max(size.height, 0);
      if (newWidth > size.width) {
        newWidth = size.width;
        newHeight = loadedImage.height * (size.width / loadedImage.width);
      }
      setWidth(newWidth);
      setHeight(newHeight);
    }
  }, [loadedImage, size]);

  const handleMouseDown = (e: any) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { tool: 'pen', points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e: any) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    const lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points?.concat([point.x, point.y]);

    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  const revertLastLine = () => {
    lines.splice(lines.length - 1, 1);
    setLines([...lines]);
  };

  const onCancelPressed = () => {
    setLines([]);
    onDrawCancelled();
  };

  const onApplyPressed = () => {
    if (loadedImage) {
      const konvaCanvas = stageRef.current.toCanvas({
        pixelRatio: loadedImage.width / width,
      });
      void onDrawApplied(konvaCanvas);
    }
  };

  return (
    <>
      <ImageContainerWithConstrainedHeight>
        <StageWithCrosshairCursor
          width={width}
          height={height}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchMove={handleMouseMove}
          onTouchEnd={handleMouseUp}
          ref={stageRef}
        >
          <Layer>
            <Image image={image} width={width} height={height} />
            {lines.map((line, i) => (
              <Line
                key={i}
                points={line.points}
                stroke={'#f26b24'}
                strokeWidth={5}
                tension={0.5}
                lineCap="round"
              />
            ))}
          </Layer>
        </StageWithCrosshairCursor>
      </ImageContainerWithConstrainedHeight>
      <FunctionWrapper>
        <FunctionContainer>
          {children}
          <ButtonWrapper>
            <Button
              type="primary"
              size="small"
              icon="checkmark"
              onClick={onApplyPressed}
              disabled={!lines.length}
            />
            <Button
              type="secondary"
              size="small"
              icon="revert"
              onClick={revertLastLine}
              disabled={!lines.length}
            />
            <Button
              type="secondary"
              size="small"
              icon="cross"
              onClick={onCancelPressed}
            />
          </ButtonWrapper>
        </FunctionContainer>
      </FunctionWrapper>
    </>
  );
};

export default sizeMe({ monitorHeight: true })(DrawOnImageView);
