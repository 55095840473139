import { ReactNode, useEffect, useState } from 'react';
import {
  Column,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import {
  ApplicationCategory,
  UserPrivilege,
  UserPrivilegeSortingKey,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import { findAndCheckStringFilter } from '@dev/base-web/dist/view/helpers/local_filter_helpers';
import { sortBasedOnStringValue } from '@dev/base-web/dist/view/helpers/local_sorting_helpers';
import { IntlShape, useIntl } from 'react-intl';
import { UserPrivilegePermission } from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';

export const createUserPrivilegesColumns = (
  otherRoleNames: string[],
  bottomComponent: ReactNode
): Array<Column> => [
  {
    title: 'category',
    isLeftAligned: true,
    sortKey: UserPrivilegeSortingKey.CATEGORY,
    filterType: ColumnFilterType.TEXT,
    width: 3,
  },
  {
    title: 'privilege',
    isLeftAligned: true,
    sortKey: UserPrivilegeSortingKey.PRIVILEGE,
    filterType: ColumnFilterType.TEXT,
    width: 5,
  },
  {
    title: 'administrator',
    width: 3,
    bottomComponent,
  },
  {
    title: 'maintainer_role_column_header',
    width: 3,
    bottomComponent,
  },
  {
    title: 'operator',
    width: 3,
    bottomComponent,
  },
  {
    title: 'bot',
    width: 3,
    bottomComponent,
  },
  {
    title: 'support',
    width: 3,
    bottomComponent,
  },
  ...otherRoleNames.map((roleName) => ({
    title: roleName,
    width: 3,
    bottomComponent,
  })),
];

export const getIdForCategory = (category: ApplicationCategory): string => {
  switch (category) {
    case ApplicationCategory.COMMON:
      return 'common';
    case ApplicationCategory.DATA:
      return 'data_collector';
    case ApplicationCategory.DARWIN:
      return 'darwin_analytics';
    case ApplicationCategory.SHANNON:
      return 'shannon_operations';
    case ApplicationCategory.HOPPER:
      return 'Hopper';
  }
};

const getLabelForCategory = (
  category: ApplicationCategory,
  intl: IntlShape
): string => {
  return intl.formatMessage({ id: getIdForCategory(category) });
};

const getLabelForPrivilige = (
  permission: UserPrivilegePermission,
  intl: IntlShape
): string => {
  return intl.formatMessage({ id: permission.toLowerCase() });
};

const applySorting = (
  data: UserPrivilege[],
  intl: IntlShape,
  localSortKey?: string,
  localSortDirection?: SortingDirection
) => {
  const sortedData = [...data];
  let sortFunction: (a: UserPrivilege, b: UserPrivilege) => number = () => 1;

  if (localSortKey && localSortDirection) {
    if (localSortKey === UserPrivilegeSortingKey.CATEGORY)
      sortFunction = (a, b) =>
        sortBasedOnStringValue(
          getLabelForCategory(a.category, intl),
          getLabelForCategory(b.category, intl),
          localSortDirection
        );
    else if (localSortKey === UserPrivilegeSortingKey.PRIVILEGE)
      sortFunction = (a, b) =>
        sortBasedOnStringValue(
          getLabelForPrivilige(a.permission, intl),
          getLabelForPrivilige(b.permission, intl),
          localSortDirection
        );
  }

  return sortedData.sort(sortFunction);
};

export const useUserPrivilegeTableSortingAndFiltering = (
  allData: readonly UserPrivilege[],
  localFilters: readonly FilterData[],
  localSortKey?: string,
  localSortDirection?: SortingDirection
) => {
  const [filteredData, setFilteredData] = useState<UserPrivilege[]>([]);
  const intl = useIntl();

  useEffect(() => {
    const newFilteredData = allData
      .filter((l) =>
        findAndCheckStringFilter(
          getLabelForCategory(l.category, intl),
          localFilters,
          UserPrivilegeSortingKey.CATEGORY
        )
      )
      .filter((l) =>
        findAndCheckStringFilter(
          getLabelForPrivilige(l.permission, intl),
          localFilters,
          UserPrivilegeSortingKey.PRIVILEGE
        )
      );

    const filteredAndSortedLabels = applySorting(
      newFilteredData,
      intl,
      localSortKey,
      localSortDirection
    );

    setFilteredData(filteredAndSortedLabels);
  }, [allData, localFilters, localSortKey, localSortDirection]);

  return filteredData;
};
