import ScreenWithSidebar from '@dev/base-web/dist/view/components/sidebar/screen_with_sidebar';
import ErrorBoundary, {
  GenericErrorScreen,
  NotFoundScreen,
} from '@dev/base-web/dist/view/screens/error';
import { Outlet, Route, createRoutesFromElements } from 'react-router-dom';
import 'yet-another-react-lightbox/styles.css';
import PrivateRouteWithRedirectLogin from './components/helpers/private_route_with_redirect_login';
import Actions, { CONFIG as ACTIONS_CONFIG } from './screens/instructions';
import CurrentEvents, {
  CONFIG as CURRENT_EVENTS_CONFIG,
} from './screens/current_events';
import Dashboard, {
  CONFIG as DASHBOARD_CONFIG,
} from './screens/dashboard/index';
import EventDetail from './screens/event_definition';
import EventWizard from './screens/event_wizard';
import Events from './screens/events';
import { CONFIG as EVENTS_CONFIG } from './screens/events/interfaces';
import Login from './screens/login';
import ParallelEvents, {
  CONFIG as PARALLEL_EVENTS_CONFIG,
} from './screens/parallel_events';
import ShiftReportDetail from './screens/reports/details';
import ShiftReportOverViewTable, {
  CONFIG as REPORTS_CONFIG,
} from './screens/reports/overview';
import UserAdmin from './screens/user_list';
import { CONFIG as USER_ADMIN_CONFIG } from './screens/user_list/interface';
import UserProperties, {
  CONFIG as USER_PROPERTIES_CONFIG,
} from './screens/user_properties';
import EditInstruction from './screens/edit_instruction';

const Layout = () => (
  <ErrorBoundary errorScreen={<GenericErrorScreen />}>
    <ScreenWithSidebar>
      <Outlet />
    </ScreenWithSidebar>
  </ErrorBoundary>
);

const routes = createRoutesFromElements(
  <Route element={<Layout />}>
    <Route
      path={DASHBOARD_CONFIG.url.path}
      element={
        <PrivateRouteWithRedirectLogin
          constraint={DASHBOARD_CONFIG.constraint}
        />
      }
    >
      <Route path="" element={<Dashboard />} />
    </Route>
    <Route
      path={CURRENT_EVENTS_CONFIG.url.path}
      element={
        <PrivateRouteWithRedirectLogin
          constraint={CURRENT_EVENTS_CONFIG.constraint}
        />
      }
    >
      <Route path="" element={<CurrentEvents />} />
    </Route>
    <Route
      path={`${CURRENT_EVENTS_CONFIG.url.path}/:id`}
      element={
        <PrivateRouteWithRedirectLogin
          constraint={CURRENT_EVENTS_CONFIG.constraint}
        />
      }
    >
      <Route path="" element={<CurrentEvents />} />
    </Route>
    <Route
      path={EVENTS_CONFIG.url.path}
      element={
        <PrivateRouteWithRedirectLogin constraint={EVENTS_CONFIG.constraint} />
      }
    >
      <Route path="" element={<Events />} />
    </Route>
    <Route
      path={`${EVENTS_CONFIG.url.path}/:id/wizard`}
      element={
        <PrivateRouteWithRedirectLogin constraint={EVENTS_CONFIG.constraint} />
      }
    >
      <Route path="" element={<EventWizard />} />
    </Route>
    <Route
      path={`${PARALLEL_EVENTS_CONFIG.url.path}/merge`}
      element={
        <PrivateRouteWithRedirectLogin
          constraint={PARALLEL_EVENTS_CONFIG.constraint}
        />
      }
    >
      <Route path="" element={<ParallelEvents />} />
    </Route>
    <Route
      path={`${EVENTS_CONFIG.url.path}/:id`}
      element={
        <PrivateRouteWithRedirectLogin constraint={EVENTS_CONFIG.constraint} />
      }
    >
      <Route path="" element={<EventDetail />} />
    </Route>
    <Route
      path={ACTIONS_CONFIG.url.path}
      element={
        <PrivateRouteWithRedirectLogin constraint={ACTIONS_CONFIG.constraint} />
      }
    >
      <Route path="" element={<Actions />} />
    </Route>
    <Route
      path={`${ACTIONS_CONFIG.url.path}/:id`}
      element={
        <PrivateRouteWithRedirectLogin constraint={ACTIONS_CONFIG.constraint} />
      }
    >
      <Route path="" element={<EditInstruction />} />
    </Route>
    <Route
      path={REPORTS_CONFIG.url.path}
      element={
        <PrivateRouteWithRedirectLogin constraint={REPORTS_CONFIG.constraint} />
      }
    >
      <Route path="" element={<ShiftReportOverViewTable />} />
    </Route>
    <Route
      path={`${REPORTS_CONFIG.url.path}/:id`}
      element={
        <PrivateRouteWithRedirectLogin constraint={REPORTS_CONFIG.constraint} />
      }
    >
      <Route path="" element={<ShiftReportDetail />} />
    </Route>
    <Route
      path={USER_ADMIN_CONFIG.url.path}
      element={
        <PrivateRouteWithRedirectLogin
          constraint={USER_ADMIN_CONFIG.constraint}
        />
      }
    >
      <Route path="" element={<UserAdmin />} />
    </Route>
    <Route
      path={`${USER_PROPERTIES_CONFIG.url.path}/:userId`}
      element={<PrivateRouteWithRedirectLogin />}
    >
      <Route path="" element={<UserProperties />} />
    </Route>
    <Route path="/login" element={<Login />} />
    <Route path="*" element={<NotFoundScreen />} />
  </Route>
);

export default routes;
