import styled from 'styled-components';
import SimpleKPI from '@dev/base-web/dist/view/components/kpi/simple_kpi';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import ThreeStatePercentageKPI from '../../reports/overview/components/three_state_percentage_kpi';
import { useIntl } from 'react-intl';
import React, { useMemo } from 'react';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { FactoryAreaChildResult } from '../../../model/domain/kpis/factory_area_child_result';
import { localizeNumber } from '@dev/base-web/dist/model/helpers/number-formatter';
import IntervalTypePopup from './interval_type_popup';
import ProducedProductsPopup from './produced_products_tooltip';
import { IntervalGroup } from '../../../model/domain/report/manufacturing_entity_summary_interval';

const KPIRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 16px;
`;

const StyledThreeStatePercentageKPI = styled(ThreeStatePercentageKPI)`
  margin-right: 32px;
`;

const StyledSimpleKPI = styled(SimpleKPI)`
  margin-right: 32px;
`;

interface IntervalKPIs {
  readonly selectedEntity?: ManufacturingEntity;
  readonly kpis: FactoryAreaChildResult[];
  readonly globalTimeSpan: IntervalGroup;
}

const IntervalKPIs = ({
  selectedEntity,
  kpis,
  globalTimeSpan,
}: IntervalKPIs) => {
  const { formatDate } = useLocalizedDateFormatters();
  const intl = useIntl();

  const kpi = useMemo(() => {
    if (selectedEntity && kpis.length > 0)
      return kpis.find((k) => k.manufacturingEntity.id === selectedEntity.id);
  }, [selectedEntity, kpis]);

  const lastCycle = kpi?.lastCycleTimestamp
    ? formatDate(kpi.lastCycleTimestamp, 'Pp')
    : '-';

  const interval = kpi ? kpi.interval : globalTimeSpan;

  const localizedIntervalType =
    interval &&
    intl.formatMessage({
      id:
        interval.type === 'PRODUCED_ORDER'
          ? 'current_production_order'
          : interval.type === 'PRODUCTION_SHIFT'
          ? 'current_shift'
          : 'current_day',
    });

  return (
    <KPIRow>
      {kpi && (
        <>
          <ProducedProductsPopup
            manufacturingEntity={kpi.manufacturingEntity}
            productsPerCycle={kpi.productsPerCycle}
            producedProducts={kpi.producedProducts}
            cycleCount={kpi.cycleCount}
            trigger={
              <div>
                <StyledSimpleKPI
                  value={localizeNumber(kpi.producedProducts) || '-'}
                  title={'produced_products'}
                />
              </div>
            }
          />
          <StyledThreeStatePercentageKPI
            value={kpi.utilization}
            upperTreshold={0.7}
            lowerTreshold={0.4}
            labelId={'production_rate'}
          />
          <StyledSimpleKPI
            value={`${lastCycle}`}
            title={'last_cycle'}
            date
            smallFontSize
          />
        </>
      )}
      {localizedIntervalType && (
        <IntervalTypePopup
          trigger={
            <div>
              <StyledSimpleKPI
                value={localizedIntervalType || '-'}
                title={'time_span'}
                date
                smallFontSize
              />
            </div>
          }
          interval={interval}
        />
      )}
      {kpi && (
        <StyledSimpleKPI
          value={kpi.currentProduct || '-'}
          title={'current_product'}
          smallFontSize
        />
      )}
    </KPIRow>
  );
};

export default IntervalKPIs;
