import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import { EventTypeTagSelect } from '@dev/base-web/dist/view/components/global/event_type_tag';
import { ManufacturingEntityMultiSelect } from '@dev/base-web/dist/view/components/inputs/manufacturing_entity_select';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { StyledTextArea } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { EventDefinition } from '../../../model/domain/event/event_definition';
import InputWithCount from '@dev/base-web/dist/view/components/inputs/input_with_count';
import { InputProps } from 'semantic-ui-react';

const SettingsContainer = styled.div`
  margin-right: 48px;
`;

export const Span = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #242424;
  margin-bottom: 16px;
  margin-top: 32px;
  text-align: center;
`;

const WideInput = styled(InputWithCount)`
  & {
    width: 100%;
  }
`;

interface EventDescriptionProps {
  readonly item: EventDefinition;
  readonly onChange: InputProps['onChange'];
  readonly swapButton: (type: EventType) => void;
  readonly allowedToModify: boolean;
  readonly manufacturingEntities: readonly ManufacturingEntity[];
  readonly manufacturingEntitiesLoading: boolean;
  readonly onManufacturingEntitySelected: (ids: readonly string[]) => void;
  readonly selectedEntityIds: readonly string[];
}

const EventDescription: React.FC<EventDescriptionProps> = ({
  item,
  onChange,
  swapButton,
  manufacturingEntities,
  manufacturingEntitiesLoading,
  onManufacturingEntitySelected,
  selectedEntityIds,
  allowedToModify,
}) => {
  const { type, name, description } = item;

  return (
    <SettingsContainer>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Span style={{ marginTop: '0' }}>
          <FormattedMessage id="event_type_question" />
        </Span>
        <EventTypeTagSelect
          required
          value={[type]}
          onSelect={(t) => swapButton(t[0])}
          disabled={!allowedToModify}
        />
        <Span>
          <FormattedMessage id="event_title_question" />
        </Span>
        <WideInput
          name="name"
          value={name}
          placeholder="-"
          onChange={onChange}
          error={!name}
          showCount
          disabled={!allowedToModify}
        />
        <Span>
          <FormattedMessage id="event_location_question" />
        </Span>
        <ManufacturingEntityMultiSelect
          notTransitional
          entities={manufacturingEntities}
          values={selectedEntityIds}
          onChange={onManufacturingEntitySelected}
          error={selectedEntityIds.length == 0}
          disabled={!allowedToModify}
          loading={manufacturingEntitiesLoading}
        />
        <Span>
          <FormattedMessage id="event_description_question" />
        </Span>
        <StyledTextArea
          name="description"
          onChange={onChange}
          value={description}
          disabled={!allowedToModify}
        />
      </div>
    </SettingsContainer>
  );
};

export default EventDescription;
