import React from 'react';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';

const StyledFeatureCard = styled.div<{ odd?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ odd }) => (odd ? '#e8ebea' : '#f7f7f7')};
  justify-content: center;
  min-width: 72px;
  display: flex;
  border-radius: 8px;
  border: solid 1px #dfdfdf;
  font-family: Relative;
  padding: 2px 16px 2px 16px;
  transition: opacity 0.2s;
  cursor: pointer;
  padding-bottom: 12px;
`;

const PaddingContainer = styled.div`
  padding-top: 20px;
  padding-right: 16px;
`;

const EmptyConditionCard: React.FC = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <StyledFeatureCard odd={false}>
        <PaddingContainer>
          <Hint messageKey={'no_condition_hint'} iconName={'warning'} />
        </PaddingContainer>
      </StyledFeatureCard>
    </DndProvider>
  );
};

export default EmptyConditionCard;
