import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import DetailLoader from '@dev/base-web/dist/view/components/global/detail_loader';
import EmptyOrLoadingView from '@dev/base-web/dist/view/components/global/no_data';
//import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
  MediaPathsDTO,
  PageRange,
} from '@dev/base-web/dist/model/domain/common/media';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import NumberInput from '@dev/base-web/dist/view/components/inputs/number_input';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';
import { useApiBlob } from '@/components/Media.ts';
import { SCRIPTS_FOLDER } from '@/Constants';

const PdfFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 12px;
`;

const DocumentContainer = styled.div<{ limitHeight?: boolean }>`
  height: ${(props) => (props.limitHeight ? 'calc(40vh - 50px)' : '100%')};
  width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
`;

const ButtonGroup = styled.div<{ pageButtonsTop?: boolean }>`
  position: relative;
  z-index: 4;
  bottom: ${(props) => (props.pageButtonsTop ? '200px' : '0px')};
  left: 0px;
  min-width: 140px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.6);
`;

const PageRangeContainer = styled.div`
  display: flex;
  margin-right: 12px;
  align-items: center;
`;

const StyledLabelWithHint = styled(LabelWithHint)`
  margin-right: 8px;
`;

const StyledVertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 16px;
  margin-bottom: 16px;
`;

const InputErrorContainer = styled.div`
  color: #e30021;
  text-align: left;
  margin-top: 8px;
  width: 100%;
`;

interface PdfViewerProps {
  readonly urls: MediaPathsDTO;
  readonly width?: number;
  readonly scale: number;
  readonly height?: number;
  readonly deleteComponent?: React.ReactNode;
  readonly pageButtonsTop?: boolean;
  readonly updatePageRange?: (pageRange: PageRange) => void;
}

const PdfViewer = ({
  urls,
  scale,
  height,
  deleteComponent,
  pageButtonsTop,
  updatePageRange,
}: PdfViewerProps) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(urls.pageRange?.firstPage || 1);
  const [renderedPageNumber, setRenderedPageNumber] = useState<number | null>(
    null
  );
  const [fullContent, isLoading] = useApiBlob(urls.full, false);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `${
      import.meta.env.BASE_URL
    }${SCRIPTS_FOLDER}/pdf.worker.min.2.12.313.js`;
  });

  const isEditMode = !!updatePageRange;
  const localFirstPage = isEditMode ? 1 : urls.pageRange?.firstPage || 1;
  const localLastPage = isEditMode
    ? numPages
    : urls.pageRange?.lastPage || numPages;

  function onDocumentLoadSuccess(pdf: PDFDocumentProxy) {
    setNumPages(pdf.numPages);
    setPageNumber(urls.pageRange?.firstPage || 1);
    updatePageRange &&
      !urls.pageRange &&
      updatePageRange({ firstPage: 1, lastPage: pdf.numPages });
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const onPageRangeChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    isFirstPage: boolean
  ) => {
    const val = parseInt(event.target.value);
    updatePageRange &&
      updatePageRange(
        isFirstPage
          ? {
              firstPage: !isNaN(val) ? val : undefined,
              lastPage: urls.pageRange?.lastPage,
            }
          : {
              firstPage: urls.pageRange?.firstPage,
              lastPage: !isNaN(val) ? val : undefined,
            }
      );
  };

  const isNumberValid = (val?: number) => {
    return val && val > 0 && numPages && val <= numPages;
  };

  const onPdfClick = () => {
    const pdfWindow = window.open();
    if (pdfWindow && fullContent) pdfWindow.location.href = fullContent;
  };

  const isPageRangeValid =
    urls.pageRange &&
    urls.pageRange.firstPage &&
    urls.pageRange.lastPage &&
    urls.pageRange?.firstPage <= urls.pageRange?.lastPage;
  const isFirstPageValid =
    isNumberValid(urls.pageRange?.firstPage) && isPageRangeValid;
  const isLastPageValid =
    isNumberValid(urls.pageRange?.lastPage) && isPageRangeValid;

  const isNewPageLoading = renderedPageNumber !== pageNumber;

  return (
    <>
      {isLoading ? (
        <DetailLoader />
      ) : (
        <>
          <DocumentContainer limitHeight={!!height}>
            <Document
              key={fullContent}
              file={fullContent}
              loading={<DetailLoader />}
              noData={
                <EmptyOrLoadingView loadingInProgress={false} hasError={true} />
              }
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {isNewPageLoading && renderedPageNumber ? (
                <Page
                  key={renderedPageNumber}
                  pageNumber={renderedPageNumber}
                  scale={scale}
                  onClick={onPdfClick}
                />
              ) : null}
              <Page
                className={`${isNewPageLoading ? 'loadingPage' : ''}`}
                key={pageNumber}
                pageNumber={pageNumber}
                scale={scale}
                onClick={onPdfClick}
                onRenderSuccess={() => {
                  setRenderedPageNumber(pageNumber);
                }}
              />
              <div className="page-controls">
                <ButtonGroup pageButtonsTop={pageButtonsTop}>
                  <Button
                    key={1}
                    type="secondary"
                    icon={'arrow-left-bold'}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      previousPage();
                      event.preventDefault();
                    }}
                    disabled={pageNumber <= localFirstPage}
                  />
                  <div style={{ marginLeft: 4, marginRight: 4, fontSize: 12 }}>
                    <FormattedMessage
                      id="page_numbers"
                      values={{
                        pageNumber: pageNumber || (localLastPage ? 1 : '-'),
                        numPages: localLastPage || '-',
                      }}
                    />
                  </div>
                  <Button
                    key={2}
                    type="secondary"
                    icon={'arrow-right-bold'}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      nextPage();
                      event.preventDefault();
                    }}
                    disabled={!localLastPage || pageNumber >= localLastPage}
                  />
                </ButtonGroup>
              </div>
            </Document>
          </DocumentContainer>
          <StyledVertical>
            <PdfFooter>
              {numPages && updatePageRange && (
                <PageRangeContainer>
                  <StyledLabelWithHint
                    label={'page'}
                    hint={'page_range_hint'}
                  />
                  <NumberInput
                    value={urls.pageRange?.firstPage || null}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onPageRangeChanged(event, true)
                    }
                    disabled={!numPages}
                    error={!isFirstPageValid}
                  />
                  <div style={{ paddingLeft: 2, paddingRight: 2 }}>-</div>
                  <NumberInput
                    value={urls.pageRange?.lastPage || null}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onPageRangeChanged(event, false)
                    }
                    disabled={!numPages}
                    error={!isLastPageValid}
                  />
                </PageRangeContainer>
              )}
              {deleteComponent}
            </PdfFooter>
            {numPages &&
              updatePageRange &&
              !(isFirstPageValid && isLastPageValid) && (
                <InputErrorContainer>
                  <FormattedMessage id={'page_range_invalid'} />
                </InputErrorContainer>
              )}
          </StyledVertical>
        </>
      )}
    </>
  );
};

export default PdfViewer;
