import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import { SignalStatistic } from '@dev/base-web/dist/model/domain/plc/plc_signal';
import { format, differenceInSeconds, subHours } from 'date-fns';
import { useDateLocale } from '@dev/base-web/dist/view/helpers/date_hooks';

const Description = styled.p`
  margin: 8px 0 8px 0;
  font-family: 'Relative';
  font-size: 14px;
  color: #8c8c8c;
`;

const EventTriggerContainer = styled.span`
  font-family: 'Relative';
  color: black;
`;

const Wrapper = styled.div`
  margin-top: 24px;
`;

interface VerificationModalProps {
  readonly open: boolean;
  readonly onOk: () => void;
  readonly onCancel: () => void;
  readonly featureValues: SignalStatistic[];
}

const formatTimestamp = (timestamp: number, dateLocale: Locale) => {
  return format(timestamp, 'P p', { locale: dateLocale });
};

const calculateTimeSpan = (
  values: Record<number, number | string | boolean | null>,
  dateLocale: Locale
) => {
  const minMax = Object.keys(values).reduce(
    (acc, curr) => ({
      min: Number(curr) < acc.min ? Number(curr) : acc.min,
      max: Number(curr) > acc.max ? Number(curr) : acc.max,
    }),
    { min: Number.MAX_SAFE_INTEGER, max: -1 }
  );

  return {
    min: formatTimestamp(minMax.min, dateLocale),
    max: formatTimestamp(minMax.max, dateLocale),
    duration: differenceInSeconds(minMax.max, minMax.min) / (60 * 60),
  };
};

const VerificationModal: React.FC<VerificationModalProps> = ({
  open,
  onOk,
  onCancel,
  featureValues,
}) => {
  const dateLocale = useDateLocale();

  const timeSpan = useMemo(() => {
    const now = Math.floor(Date.now() / 1000) * 1000;

    return featureValues.length === 1
      ? calculateTimeSpan(featureValues[0].values, dateLocale)
      : {
          min: formatTimestamp(now, dateLocale),
          max: formatTimestamp(subHours(now, 2).valueOf(), dateLocale),
          duration: 1,
        };
  }, [featureValues]);

  const occurrenceCount =
    featureValues.length === 1 && featureValues[0].changeRate
      ? (featureValues[0].changeRate as any).count
      : 0;
  const estimate = (occurrenceCount * (24 / timeSpan.duration)).toFixed(1);

  return (
    <BasicModalWithHeaderAndTwoButtons
      headerLabel="verification"
      cancelLabel="cancel_button"
      okLabel="save"
      onOk={onOk}
      onCancel={onCancel}
      onClose={onCancel}
      open={open}
    >
      <Description>
        {occurrenceCount === 0 && (
          <FormattedMessage id="verification_no_results" />
        )}
        {occurrenceCount > 0 && (
          <FormattedMessage
            id="verification_results"
            values={{
              triggerEstimate: (
                <EventTriggerContainer>
                  <FormattedMessage
                    id="verification_estimate"
                    values={{
                      estimateCount: estimate,
                    }}
                  />
                </EventTriggerContainer>
              ),
            }}
          />
        )}
      </Description>
      <Description>
        {occurrenceCount === 0 && (
          <FormattedMessage id="verification_no_results_confirmation" />
        )}
        {occurrenceCount > 0 && (
          <FormattedMessage id="verification_results_confirmation" />
        )}
      </Description>
      <Wrapper>
        <Hint
          textColor="black"
          messageKey={'verification_estimate_hint'}
          messageValues={{
            from: timeSpan.min,
            to: timeSpan.max,
          }}
        />
      </Wrapper>
    </BasicModalWithHeaderAndTwoButtons>
  );
};

export default VerificationModal;
