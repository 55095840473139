import React from 'react';
import { Table } from 'semantic-ui-react';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import {
  LeftAlignedTableCell,
  StyledRow,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import { ROUTE_ID_TEMPLATE as USER_ROUTE_ID_TEMPLATE } from '../../user_properties';
import {
  StyledName,
  StyledNameContainer,
  StyledRightColumn,
  StyledUserContainer,
  StyledUserName,
} from '../../../components/StyledComponents';
import UserAvatar from '@dev/base-web/dist/view/components/global/dummy_avatar';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import styled from 'styled-components';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';

const StyledUserAvatar = styled.div``;

interface UserRowExternalProps {
  user: UserDetails;
  resetUser: () => void;
}

type UserRowProps = UserRowExternalProps;

export const UserTableRow: React.FC<UserRowProps> = ({ resetUser, user }) => {
  const { formatDateOrDistance } = useLocalizedDateFormatters();
  const navigate = useNavigate();

  const onClick = () => {
    resetUser();
    navigate(USER_ROUTE_ID_TEMPLATE(user.id));
  };

  const {
    username,
    registrationDate,
    lastLogin,
    role,
    manufacturingEntities,
    firstName,
    lastName,
    pictures,
    status,
  } = user;
  const fullName = `${firstName || '-'} ${lastName || '-'}`;

  const formattedAddedDate = registrationDate
    ? formatDateOrDistance(registrationDate)
    : '-';
  const formattedLastLoginDate = lastLogin
    ? formatDateOrDistance(lastLogin)
    : '-';
  const locations = manufacturingEntities
    .map((entity) => entity.name)
    .join(', ');

  let userImage: string | undefined;
  if (pictures && pictures.thumb) {
    userImage = pictures.thumb;
  }

  return (
    <StyledRow onClick={onClick}>
      <Table.Cell>
        <StyledUserContainer>
          <StyledUserAvatar>
            <UserAvatar name={fullName} src={userImage} size="40px" />
          </StyledUserAvatar>
          <StyledNameContainer>
            <StyledName>
              <TextWithEllipsisAndTooltip
                text={fullName}
              ></TextWithEllipsisAndTooltip>
            </StyledName>
            <StyledUserName>
              <TextWithEllipsisAndTooltip
                text={username}
              ></TextWithEllipsisAndTooltip>
            </StyledUserName>
          </StyledNameContainer>
        </StyledUserContainer>
      </Table.Cell>
      <LeftAlignedTableCell>
        <FormattedMessage id={role.toLowerCase()} />
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        <FormattedMessage id={status.toLowerCase()} />
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>{locations || '-'}</LeftAlignedTableCell>
      <LeftAlignedTableCell>{formattedAddedDate}</LeftAlignedTableCell>
      <LeftAlignedTableCell>{formattedLastLoginDate}</LeftAlignedTableCell>
      <Table.Cell>
        <StyledRightColumn>
          <Icon name={'arrow-right-bold'} color={'#8c8c8c'} size={16} />
        </StyledRightColumn>
      </Table.Cell>
    </StyledRow>
  );
};
