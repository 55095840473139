import {
  ScreenContent,
  ScreenRoot,
} from '@dev/base-web/dist/view/components/global/styled_components';
import { ReportOverviewDetailsProps } from './interface';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '@dev/base-web/dist/view/components/global/loader';
import ReportKPIs from './components/reporting/report_kpis';
import styled from 'styled-components';
import DowntimesInReportTable from './components/reporting/downtimes_in_report_table';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import TabBar from '@dev/base-web/dist/view/components/global/tab_bar';
import {
  useBooleanSearchState,
  useNumberSearchState,
  useStringSearchState,
} from '@dev/base-web/dist/view/components/global/url_param_hooks';
import ResultCardSkeleton from './components/results/result_card_skeleton';
import { DurationUnit } from '@dev/base-web/dist/model/domain/durations/duration';
import ShannonConfig from '@dev/base-web/dist/model/domain/authorization/shannon';
import ToggleSwitch from '@dev/base-web/dist/view/components/inputs/toggle_switch';
import { LabelContainer } from './components/styled_components';
import ChartLegend from '@dev/base-web/dist/view/components/global/chart_legend';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import { ConditionType } from '@dev/base-web/dist/model/domain/durations/condition';
import { snakeToCamel } from '@dev/base-web/dist/model/helpers/snake_camel';
import Header from '@dev/base-web/dist/view/components/global/header_view/index';
import DetailLoader from '@dev/base-web/dist/view/components/global/detail_loader';
import { getManufacturingEntityTitle } from '@dev/base-web/dist/view/helpers/common';

const KPIWrapper = styled.div`
  display: flex;
  margin-left: 48px;
  margin-bottom: 24px;
`;

const TabBarContainer = styled.div`
  margin-left: ${({ theme }) => theme.positioning.siteMargin.left}px;
  padding-top: 28px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const LegendAndSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`;

const LegendContainer = styled.div`
  margin-right: ${({ theme }) => theme.positioning.siteMargin.left - 16}px;
  margin-bottom: 8px;
`;

const OnlyOpenDowntimeSwitchContainer = styled.div`
  margin-right: ${({ theme }) => theme.positioning.siteMargin.left}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const OnlyOpenDowntimeSwitchLabel = styled(LabelContainer)`
  margin-right: 8px;
`;

const ReportOverviewDetails = ({
  reportOverview,
  reportOverviewLoadingInProgress,
  getShiftReport,
  downtimesInReport,
  downtimesInReportLoadingError,
  downtimesInReportLoadingInProgress,
  getDowntimesInShiftReport,
  getAllManufacturingEntities,
  manufacturingEntities,
  manufacturingEntityMeta,
  selectedDowntime,
  selectedDowntimeLoadingInProgress,
  getDowntime,
  showManufacturingEntityHierarchy,
  updateDowntimeInShiftReport,
  getDowntimeReasons,
  downtimeReasons,
  downtimeReasonsLoadingInProgress,
  downtimeUpdate,
  getDurationDeviations,
  getProductionProgress,
  updateDowntimeInShiftReportBasedOnOverview,
  getSelectedEvent,
  selectedEventData,
  eventStatsIntervals,
}: ReportOverviewDetailsProps) => {
  const { locale } = useIntl();
  const params = useParams();

  const { formatDate } = useLocalizedDateFormatters();
  const navigate = useNavigate();
  const theme = useTheme();
  const [reportId, setReportId] = useState<string>();

  useEffect(() => {
    if (params.id) {
      getShiftReport(params.id);
      setReportId(params.id);
    }
  }, [params.id]);

  const [activeTab, setActiveTab] = useNumberSearchState({
    key: 'tab',
    defaultValue: 0,
  });

  const [onlyShowDocumented, setOnlyShowDocumented] = useBooleanSearchState({
    key: 'onlyShowDocumented',
    defaultValue: false,
  });

  const [selectedDowntimeId, setSelectedDowntimeId] = useStringSearchState({
    key: 'downtime',
    defaultValue: undefined,
  });

  useEffect(() => {
    if (reportOverview && activeTab !== 0) {
      getDurationDeviations(
        reportOverview.shift.start,
        reportOverview.shift.end,
        reportOverview.manufacturingEntity.id
      );
      getProductionProgress(
        reportOverview.manufacturingEntity.id,
        reportOverview.shift
      );
    }
  }, [reportOverview, activeTab]);

  useEffect(() => {
    getDowntimeReasons();
  }, []);

  const shiftTimeSpan = reportOverview
    ? { start: reportOverview.shift.start, end: reportOverview.shift.end }
    : undefined;

  const chartLegendItems = Object.keys(ConditionType).map(
    (condition: string) => ({
      labelId:
        condition === ConditionType.PROCESSING
          ? 'no_downtime'
          : condition.toLowerCase(),
      color: (
        theme.colors.diagrams.paletteManufacturingStates as Record<
          string,
          string
        >
      )[snakeToCamel(condition.toLowerCase())],
    })
  );

  return (
    <ScreenRoot>
      {reportOverview && (
        <>
          <Header
            title={reportOverview ? 'shift_report_title' : 'shift_report'}
            backButtonTitle="shift_reports"
            titleValues={{
              productionEntity: reportOverview?.manufacturingEntity
                ? getManufacturingEntityTitle(
                    reportOverview.manufacturingEntity,
                    locale
                  )
                : '-',
              date: formatDate(reportOverview?.shift.start, 'Ppp'),
            }}
            onBackPressed={() =>
              navigate(ShannonConfig.Report.url.path, { replace: true })
            }
          />
          <ScreenContent>
            <TabBarContainer>
              <TabBar
                tabIndex={activeTab}
                titles={[
                  <FormattedMessage id="reporting" />,
                  <FormattedMessage id="statistics" />,
                ]}
                onSelectedIndexChange={setActiveTab}
              />
              <LegendAndSettingsContainer>
                <OnlyOpenDowntimeSwitchContainer>
                  <OnlyOpenDowntimeSwitchLabel>
                    <FormattedMessage id={'only_not_documented_downtimes'} />
                  </OnlyOpenDowntimeSwitchLabel>
                  <ToggleSwitch
                    onChange={setOnlyShowDocumented}
                    checked={onlyShowDocumented}
                  />
                </OnlyOpenDowntimeSwitchContainer>
                {/*{!onlyShowDocumented && (*/}
                <LegendContainer>
                  <ChartLegend legendItems={chartLegendItems} />
                </LegendContainer>
                {/*)}*/}
              </LegendAndSettingsContainer>
            </TabBarContainer>
            <KPIWrapper>
              <ReportKPIs report={reportOverview} />
              {reportOverviewLoadingInProgress && reportOverview && (
                <DetailLoader style={{ width: 100, height: 40 }} />
              )}
            </KPIWrapper>
            {activeTab === 0 ? (
              reportId && (
                <DowntimesInReportTable
                  showOnlyNotDocumented={onlyShowDocumented}
                  reportId={reportId}
                  report={reportOverview}
                  downtimesInReport={downtimesInReport}
                  downtimesInReportLoadingInProgress={
                    downtimesInReportLoadingInProgress
                  }
                  getDowntimesInShiftReport={getDowntimesInShiftReport}
                  downtimesInReportLoadingError={downtimesInReportLoadingError}
                  getAllManufacturingEntities={getAllManufacturingEntities}
                  manufacturingEntities={manufacturingEntities}
                  manufacturingEntityMeta={manufacturingEntityMeta}
                  selectedDowntime={selectedDowntime}
                  getDowntime={getDowntime}
                  selectedDowntimeLoadingInProgress={
                    selectedDowntimeLoadingInProgress
                  }
                  showManufacturingEntityHierarchy={
                    showManufacturingEntityHierarchy
                  }
                  updateDowntimeInShiftReport={updateDowntimeInShiftReport}
                  downtimeReasons={downtimeReasons}
                  downtimeReasonsLoadingInProgress={
                    downtimeReasonsLoadingInProgress
                  }
                  downtimeUpdateMeta={downtimeUpdate}
                  selectedDowntimeId={selectedDowntimeId}
                  setSelectedDowntimeId={setSelectedDowntimeId}
                  updateDowntimeInShiftReportBasedOnOverview={
                    updateDowntimeInShiftReportBasedOnOverview
                  }
                  getSelectedEvent={getSelectedEvent}
                  selectedEventData={selectedEventData}
                />
              )
            ) : (
              <ResultCardSkeleton
                selectedTimeSpan={shiftTimeSpan}
                selectedManufacturingEntity={reportOverview.manufacturingEntity}
                selectedDuration={{ value: 30, unit: DurationUnit.MINUTES }}
                eventStatsIntervals={eventStatsIntervals || null}
              />
            )}
          </ScreenContent>
        </>
      )}
      {reportOverviewLoadingInProgress && !reportOverview && <Loader />}
    </ScreenRoot>
  );
};

export default ReportOverviewDetails;
