import { ScheduledCondition } from '../../../../model/domain/event/event_definition';
import React from 'react';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Function from '@dev/base-web/dist/view/components/feature/feature_config_card/function_label_card';
import Scheduled_condition_config from './scheduled_condition_config';

interface ScheduledConditionCardProps {
  readonly condition: ScheduledCondition;
  readonly replaceColor?: string;
}

const StyledFeatureCard = styled.div<{ color?: string }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${({ color }) => (color ? color : '#f7f7f7')};
  justify-content: start;
  min-width: 72px;
  display: flex;
  border-radius: 8px;
  border: solid 1px #dfdfdf;
  font-family: Relative;
  padding: 2px 16px 2px 16px;
  transition: opacity 0.2s;
  cursor: pointer;
  min-width: fit-content;
`;

const FunctionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-top: 6px;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const InputsWrapper = styled.div`
  padding: 6px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ScheduledConditionCard: React.FC<ScheduledConditionCardProps> = ({
  condition,
  replaceColor,
}) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <StyledFeatureCard color={replaceColor}>
        <FunctionWrapper>
          <Function value={'scheduled'} draggable={false} />
        </FunctionWrapper>
        <InputsWrapper>
          <Scheduled_condition_config
            condition={condition}
            disabled={true}
            ruleCard
          />
        </InputsWrapper>
      </StyledFeatureCard>
    </DndProvider>
  );
};

export default ScheduledConditionCard;
