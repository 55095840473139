import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import {
  INTERVAL_TYPES,
  ManufacturingEntitySummaryInterval,
} from '../../../../../../model/domain/report/manufacturing_entity_summary_interval';
import { TimeSpan } from '@dev/base-web/dist/model/domain/common/time_span';

export const TABLE_GROUP_BY = [INTERVAL_TYPES[1], INTERVAL_TYPES[2]] as const;
export type TABLE_GROUP_BY_TYPE = (typeof TABLE_GROUP_BY)[number];

export interface ProductBatchedProducedOrderTableExternalProps {
  readonly manufacturingEntityId?: string;
  readonly timeSpan?: TimeSpan;
}

export interface ProductBatchesProducedOrderTableStoreProps {
  readonly kpiResults: ManufacturingEntitySummaryInterval[];
  readonly hasMoreKpiResults: boolean;
  readonly kpiResultsLoading: boolean;
}

export interface ProductBatchesProducedOrderTableDispatchProps {
  readonly getKPIResults: (
    page: number,
    groupBy: string,
    filters?: readonly FilterData[],
    sortKey?: string,
    sortingDirection?: SortingDirection
  ) => void;
}

export type ProductBatchesProducedOrderTableProps =
  ProductBatchedProducedOrderTableExternalProps &
    ProductBatchesProducedOrderTableStoreProps &
    ProductBatchesProducedOrderTableDispatchProps;
