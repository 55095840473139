import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import React from 'react';
import ConfigurationParameters from '@dev/base-web/dist/model/domain/configuration/configuration';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { getTranslationWarning } from '@/screens/edit_instruction/components/helper.ts';
import { LoadingMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';

const MessageContainer = styled.div`
  padding: 16px;
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
`;

const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.43;
  letter-spacing: normal;
  color: #242424;
  margin: 16px;
`;

const WarningText = styled.p`
  margin-left: 7px;
`;

export interface NewTranslationModalProps {
  readonly open: boolean;
  readonly fromLanguageCode: string | null; // Should be internationalized
  readonly toLanguageCode?: string; // Should be internationalized
  readonly onClose: () => void;
  readonly onOk: () => void;
  readonly config: ConfigurationParameters | null;
  readonly currentLocale: string;
  readonly translationMeta: LoadingMetaState;
}

const NewTranslationModal: React.FC<NewTranslationModalProps> = ({
  open,
  fromLanguageCode,
  toLanguageCode,
  onClose,
  onOk,
  config,
  currentLocale,
  translationMeta,
}) => {
  const intl = useIntl();

  const translationServiceWarningText = getTranslationWarning(
    config,
    currentLocale
  );

  // Both fromLanguageCode and toLanguageCode should be non-null when open.
  if (!fromLanguageCode || !toLanguageCode) return null;

  return (
    <>
      <BasicModalWithHeaderAndTwoButtons
        okLabel="translate"
        cancelLabel="dont_translate"
        headerLabel="add_language"
        onOk={onOk}
        onClose={onClose}
        onCancel={onClose}
        open={open}
        okLoading={translationMeta.loadingInProgress}
      >
        <MessageContainer>
          <FormattedMessage
            id="translate_solution_confirmation"
            values={{
              fromLanguage: (
                <b>
                  {intl.formatMessage({ id: fromLanguageCode.toLowerCase() })}
                </b>
              ),
              toLanguage: (
                <b>
                  {intl.formatMessage({ id: toLanguageCode.toLowerCase() })}
                </b>
              ),
            }}
          />
        </MessageContainer>
        {translationServiceWarningText && (
          <WarningContainer>
            <Icon name="warning" color={'#DC8500'} size={34} />
            <WarningText>
              {translationServiceWarningText}
              <br />
              <FormattedMessage id={'update_translations_hint'} />
            </WarningText>
          </WarningContainer>
        )}
      </BasicModalWithHeaderAndTwoButtons>
    </>
  );
};

export default NewTranslationModal;
