import { combineReducers, Reducer } from 'redux';
import {
  createReducersForLoadingList,
  createReducersForLoadingSingleItem,
} from '@dev/base-web/dist/model/redux/helpers/reducers';
import { FactoryAreaChildResult } from '../../domain/kpis/factory_area_child_result';
import { GET_MAIN_ENTITY_KPIS, PRODUCTION_PROGRESS } from './actions';
import { MainEntityKpiStateInterface } from './interface';
import ManufacturingEntityProductionProgress from '@dev/base-web/dist/model/domain/production_progress';

const kpiState: Reducer<MainEntityKpiStateInterface> = combineReducers({
  mainEntityKpis:
    createReducersForLoadingList<FactoryAreaChildResult>(GET_MAIN_ENTITY_KPIS),
  productionProgress:
    createReducersForLoadingSingleItem<ManufacturingEntityProductionProgress>(
      PRODUCTION_PROGRESS
    ),
});

export default kpiState;
