import { Middleware } from 'redux';

export const requestMiddleware: Middleware = (store) => (next) => (action) => {
  const { token } = store.getState().authenticationState.authentication;

  if ((action as any).request) {
    (action as any).request(token);
  } else {
    next(action);
  }
};
