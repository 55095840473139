import CommentList from '@dev/base-web/dist/view/components/comments/comment_list/view';
import { connect } from 'react-redux';
import CommentThunks from '../../../model/redux/comments/thunks';
import UserThunks from '../../../model/redux/user/thunks';
import { RootReducerInterface } from '@/model/redux/interfaces';
import mediaThunks from '../../../model/redux/media/thunks';
import { CommentListStorePropsWithMedia } from '@dev/base-web/dist/view/components/comments/interface';

const mapStateToProps = (
  state: RootReducerInterface
): CommentListStorePropsWithMedia => ({
  deleteCommentMeta: state.data.commentState.removeCommentFromEvent.meta,
  addCommentMeta: state.data.commentState.addComment.meta,
  users: state.userState.allUsers.data,
  comments: state.data.commentState.getComments.data,
  getCommentsMeta: state.data.commentState.getComments.meta,
  images: state.data.mediaState.imageList.data.results,
  selected: state.data.mediaState.selected,
  uploads: state.data.mediaState.uploads,
  updating: state.data.mediaState.update,
});

export default connect(mapStateToProps, {
  sendComment: CommentThunks.addComment,
  getAllUsers: UserThunks.getAllUsers,
  getComments: CommentThunks.getComments,
  deleteComment: CommentThunks.removeCommentFromEvent,
  clearUploadedPictures: mediaThunks.clearUploadedPictures,
  clearSelectedPicture: mediaThunks.clearSelectedPicture,
  uploadFiles: mediaThunks.uploadMedia,
})(CommentList);
