import {
  CenteredCardContent,
  NoDataMessageContainer,
  NoDataTextContainer,
} from '@dev/base-web/dist/view/components/charts/styled_components';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { ErrorInterface } from '@dev/base-web/dist/model/api/error/error_interface';

interface FeatureErrorInfoProps {
  readonly featureValuesError: ErrorInterface | undefined;
  readonly invalidConfiguration: boolean;
}

const FeatureErrorInfo = ({
  featureValuesError,
  invalidConfiguration,
}: FeatureErrorInfoProps) => (
  <CenteredCardContent>
    <NoDataMessageContainer>
      <Icon name={'hand'} color={'#242424'} size={32} />
      <NoDataTextContainer>
        {!featureValuesError &&
          (invalidConfiguration ? (
            <FormattedMessage id={'no_valid_configuration'} />
          ) : (
            <FormattedMessage id={'no_data_available'} />
          ))}
        {featureValuesError && <FormattedMessage id={'no_data_error'} />}
      </NoDataTextContainer>
    </NoDataMessageContainer>
  </CenteredCardContent>
);

export default FeatureErrorInfo;
