import React from 'react';
import { DashboardButtonProps } from '@dev/base-web/dist/view/components/dashboard/dashboard_button';
import {
  Card,
  CardColumn,
  CardRow,
} from '@dev/base-web/dist/view/components/global/card';
import DashBoardGrid from '@dev/base-web/dist/view/components/dashboard/dashboard_grid';
import EventsList from '@dev/base-web/dist/view/components/search/events_list';
import InstructionList from '@dev/base-web/dist/view/components/search/instructions_list';

interface SupportDashboardProps {
  readonly dashboardItems: DashboardButtonProps[];
  readonly getAllManufacturingEntities: () => void;
}

const SupportDashboard = ({
  dashboardItems,
  getAllManufacturingEntities,
}: SupportDashboardProps) => {
  return (
    <>
      <CardRow>
        <CardColumn>
          <Card titleId={'recent_used_events'} margin={'none'}>
            <EventsList
              minHeight={400}
              card
              getAllManufacturingEntities={getAllManufacturingEntities}
            />
          </Card>
        </CardColumn>
        <CardColumn>
          <Card titleId={'newest_actions'} margin={'none'}>
            <InstructionList minHeight={400} card />
          </Card>
        </CardColumn>
      </CardRow>
      <CardRow>
        <CardColumn>
          <DashBoardGrid buttons={dashboardItems} />
        </CardColumn>
      </CardRow>
    </>
  );
};

export default SupportDashboard;
