import { RootReducerInterface } from '@/model/redux/interfaces';
import { AddEventsModalStoreProps } from './interface';
import { connect } from 'react-redux';
import { createGetEventsThunk } from '@/model/redux/event_definition/thunks';
import ManufacturingEntityThunks from '@/model/redux/manufacturing_entity/thunks';
import AddEventsModal from './view';
import { addEventsToInstruction } from '@/model/redux/instruction_definition/thunks';
import { Actions } from '@/model/redux/event_definition/actions';

const mapStateToProps = (
  state: RootReducerInterface
): AddEventsModalStoreProps => {
  return {
    events:
      state.data.eventDefinitionState.eventsForAddRelationModal.data.results,
    eventsTotalElements:
      state.data.eventDefinitionState.eventsForAddRelationModal.data
        .totalElements,
    moreEventsCanBeLoaded:
      state.data.eventDefinitionState.eventsForAddRelationModal.data
        .hasMoreResults,
    eventsMeta: state.data.eventDefinitionState.eventsForAddRelationModal.meta,
    addEventsToInstructionMeta:
      state.data.instructionDefinitionState.addEventsToInstruction.meta,
    manufacturingEntities:
      state.data.manufacturingEntityState.manufacturingEntities.data,
    manufacturingEntitiesLoadingInProgress:
      state.data.manufacturingEntityState.manufacturingEntities.meta
        .loadingInProgress,
    showHierarchy:
      state.configurationState.config.data?.showManufacturingEntityHierarchy
        .value,
  };
};

export default connect(mapStateToProps, {
  getEvents: createGetEventsThunk(Actions.eventsForAddRelationModal),
  getAllManufacturingEntities:
    ManufacturingEntityThunks.getManufacturingEntities,
  addEventsToInstruction: addEventsToInstruction,
})(AddEventsModal);
