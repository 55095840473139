import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import zxcvbn from 'zxcvbn';
import theme from '@dev/base-web/dist/view/themes/main';

const StrengthMeter = styled.div`
  list-style: none;
  margin: 0;
  margin-top: 3px;
  padding: 0;
  vertical-align: 2px;
  width: 50%;
`;

const BarPoint = styled.li`
  background: ${theme.colors.kpi.brdNeutral};
  display: inline-block;
  transition: background-color 1000ms linear;
  height: 5px;
  width: 20%;
  &:first-child {
    border-radius: 8px 0px 0px 8px;
  }
  &:last-child {
    border-radius: 0px 8px 8px 0px;
  }
`;

interface PasswordMeterProps {
  readonly password?: string;
  readonly onStrengthChanged?: (strength: number) => any;
  readonly username?: string;
  readonly barElementsCount?: number;
}

export const PasswordMeter: React.FC<PasswordMeterProps> = ({
  barElementsCount,
  password,
  username,
  onStrengthChanged,
}) => {
  const localBarElementsCount = barElementsCount ? barElementsCount : 5;

  const colors = [
    theme.colors.cherryRed,
    theme.colors.pumpkin,
    theme.colors.diagrams.paletteManufacturingStates.waiting,
    theme.colors.primary,
    theme.colors.positive,
  ];
  const [barElementsColors, setBarElementsColors] = useState<string[]>([]);

  useEffect(() => {
    if (barElementsColors.length === 0) {
      const barElementsColorsInit = [];
      for (let i = 0; i < localBarElementsCount; i++)
        barElementsColorsInit.push('');
      setBarElementsColors(barElementsColorsInit);
      setBarColors(localBarElementsCount, theme.colors.grey);
    }
  });

  useEffect(() => {
    if (password) {
      measureStrength(password);
    } else if (
      !password &&
      barElementsColors[0] !== theme.colors.kpi.brdNeutral
    ) {
      setBarColors(localBarElementsCount, theme.colors.kpi.brdNeutral);
    }
  }, [password, username]);

  const measureStrength = (password: string) => {
    let strength;
    if (username) {
      strength = zxcvbn(password, [username]).score;
    } else strength = zxcvbn(password).score;
    setBarColors(strength, colors[strength]);
    if (onStrengthChanged) onStrengthChanged(strength);
  };

  const setBarColors = (count: number, color: string) => {
    const barElementsColors = [];
    for (let i = 0; i <= count; i++) barElementsColors[i] = color;
    setBarElementsColors(barElementsColors);
  };

  return (
    <StrengthMeter>
      {(() => {
        const barPoints = [];
        for (let i = 0; i < localBarElementsCount; i++) {
          barPoints.push(
            <BarPoint
              key={i}
              style={{ backgroundColor: barElementsColors[i] }}
            />
          );
        }
        return barPoints;
      })()}
    </StrengthMeter>
  );
};
