import styled from 'styled-components';
import { TightlyPackedButtons } from '@dev/base-web/dist/view/components/global/button';
import { Button } from 'semantic-ui-react';
import { ImageWithThumbnail } from '@dev/base-web/dist/view/components/global/media';

export const ImageContainer = styled.div`
  padding: 16px 16px 0 16px;
  text-align: center;
  height: calc(60vh - 200px);
  margin-bottom: 24px;
`;

export const Image = styled(ImageWithThumbnail).attrs({
  contentStyle: {
    height: `calc(50vh - 200px)`,
    maxWidth: '100%',
    cursor: 'default',
  },
})`
  padding: 16px 16px 0 16px;
  text-align: center;
  height: calc(50vh - 200px);
  margin-bottom: 24px;
`;

export const FunctionWrapper = styled.div`
  padding: 0px 16px;
`;

export const FunctionContainer = styled(TightlyPackedButtons)`
  padding: 8px 0px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
`;

export const Wrapper = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) => (fullWidth ? '' : 'height: calc(80vh - 280px);')}
  display: flex;
  border-width: 2px;
  border-color: #939393;
  border-style: solid;
  transition: border 0.24s ease-in-out;
  margin: ${({ fullWidth }) =>
    fullWidth ? '0px 24px 16px 24px' : '24px 24px 16px 48px'};
  @media (max-width: 768px) {
    margin: ${({ fullWidth }) =>
      fullWidth ? '0px 24px 16px 24px' : ' 24px 24px 16px 24px'};
  }
  padding-bottom: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  flex-direction: column;
  overflow: auto;
`;

export const CardFooter = styled.div`
  height: 70px;
  width: 100%;
  border-radius: 8px;
  box-shadow: inset 0 1px 0 0 #efefef;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  padding: 16px 48px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const LoadWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  border-width: 2px;
  border-color: ${({ theme }) => theme.colors.primary};
  border-style: dashed;
  padding: auto;
  justify-items: center;
  text-align: center;
  box-sizing: content-box;
  border-radius: 4px;
  margin: auto;
  padding-left: 8px;
  background-color: rgba(216, 216, 216, 0.25);

  &.error {
    border-color: ${({ theme }) => theme.colors.cherryRed} !important;
    padding-left: 0;
  }
`;

export const StyledCancelButton = styled(Button)`
  &&&&& {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.buttons.secondary.color};
    border: ${({ theme }) => theme.buttons.secondary.border};
    padding: 0;
    margin: 0;
    right: -12px;
    top: -12px;
    z-index: 1;
  }
`;
