import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import UserAvatar from '@dev/base-web/dist/view/components/global/dummy_avatar';
import { FormattedMessage } from 'react-intl';

export interface DropdownItem {
  readonly id: string;
  readonly label: string;
  readonly value: string;
}

export const renderUserAvatar = (user: UserDetails) => {
  const username =
    !user.firstName && !user.lastName
      ? user.username
      : `${user.firstName || '-'} ${user.lastName || '-'}`;

  let userImage: string | undefined;
  if (user.pictures && user.pictures.thumb) {
    userImage = user.pictures.thumb;
  }
  return <UserAvatar name={username} src={userImage} size="24px" />;
};

export const renderTooltipContent = (key: string) => {
  return (
    <FormattedMessage
      id={key}
      values={{
        b: (chunks) => <strong>{chunks}</strong>,
        br: <br />,
        ul: (chunks) => <ul>{chunks}</ul>,
        li: (chunks) => <li>{chunks}</li>,
      }}
    />
  );
};
