import ManufacturingEntityProductionProgress from '@dev/base-web/dist/model/domain/production_progress';
import {
  createActionsForList,
  createActionsForSingleItem,
} from '@dev/base-web/dist/model/redux/helpers/actions';
import { FactoryAreaChildResult } from '../../domain/kpis/factory_area_child_result';

export const GET_MAIN_ENTITY_KPIS = 'GET_MAIN_ENTITY_KPIS';
export const PRODUCTION_PROGRESS = 'PRODUCTION_PROGRESS';

export const Actions = {
  mainEntityKpis:
    createActionsForList<FactoryAreaChildResult>(GET_MAIN_ENTITY_KPIS),
  productionProgress:
    createActionsForSingleItem<ManufacturingEntityProductionProgress>(
      PRODUCTION_PROGRESS
    ),
};
