import { connect } from 'react-redux';
import { RootReducerInterface } from '../../model/redux/interfaces';
import { createGetInstructionsThunks } from '../../model/redux/instruction_definition/thunks';
import { createGetEventsThunk } from '../../model/redux/event_definition/thunks';
import {
  NEW_USER_ROUTE_KEY,
  UserPropertiesDispatchProps,
  UserPropertiesStoreProps,
} from './interfaces';
import UserProperties from './view';
import UserThunks from '../../model/redux/user/thunks';
import UserRoleThunks from '../../model/redux/user_role/thunks';
import ManufacturingEntityThunks from '../../model/redux/manufacturing_entity/thunks';
import { EventDefinitionStatus } from '../../model/domain/event/event_definition';
import { FilterOperation } from '@dev/base-web/dist/model/domain/common/filter_data';
import {
  ApplicationFunction,
  ApplicationFunctionType,
} from '@dev/base-web/dist/model/domain/authorization/interface';
import { SHANNON_BASE_URL } from '@dev/base-web/dist/model/domain/authorization/shannon';

const ROUTE_ID_WITHOUT_PARAM = '/user';
export const ROUTE_ID_TEMPLATE = (id: string) =>
  `${ROUTE_ID_WITHOUT_PARAM}/${id}`;
export const ROUTE_ID_FOR_NEW = `${ROUTE_ID_WITHOUT_PARAM}/${NEW_USER_ROUTE_KEY}`;

export const CONFIG: ApplicationFunction = {
  type: ApplicationFunctionType.SCREEN,
  label: 'user_properties',
  url: {
    baseUrl: SHANNON_BASE_URL,
    path: ROUTE_ID_WITHOUT_PARAM,
  },
};

const getEventsThunk = createGetEventsThunk();

const mapStateToProps = (
  state: RootReducerInterface
): UserPropertiesStoreProps => {
  return {
    user: state.userState.user.data,
    usernameExists: state.userState.usernameExists.data,
    moreInstructionsCanBeLoaded:
      state.data.instructionDefinitionState.instructions.data.hasMoreResults,
    instructionLoadingInProgress:
      state.data.instructionDefinitionState.instructions.meta.loadingInProgress,
    currentUser: state.userState.currentUser.data,
    instructions:
      state.data.instructionDefinitionState.instructions.data.results,
    events: state.data.eventDefinitionState.events.data.results,
    moreEventsCanBeLoaded:
      state.data.eventDefinitionState.events.data.hasMoreResults,
    eventsLoadingInProgress:
      state.data.eventDefinitionState.events.meta.loadingInProgress,
    userLoadingInProgress: state.userState.user.meta.loadingInProgress,
    updateUserOperation: state.userState.updateUser.meta,
    deleteUserOperation: state.userState.updateUsers.meta,
    pictureUploadResult: state.userState.pictureUpload.data,
    pictureUploadInProgress:
      state.userState.pictureUpload.meta.loadingInProgress,
    manufacturingEntities:
      state.data.manufacturingEntityState.manufacturingEntities.data,
    manufacturingEntitiesMeta:
      state.data.manufacturingEntityState.manufacturingEntities.meta,
    roleNames: state.userRoleState.roleNames.data,
    eventOccurrenceCountDuration:
      state.configurationState.config.data?.eventOccurrenceCountDuration,
    minimumPasswordStrength:
      state.configurationState.config.data?.minimumPasswordStrength,
  };
};

export default connect<
  UserPropertiesStoreProps,
  UserPropertiesDispatchProps,
  Record<string, never>,
  RootReducerInterface
>(mapStateToProps, {
  getUserById: UserThunks.getUserById,
  getUsernameExists: UserThunks.getUsernameExists,
  getInstructions: createGetInstructionsThunks(),
  createUser: UserThunks.createUser,
  updateUser: UserThunks.updateUser,
  deleteUsers: UserThunks.deleteUsers,
  uploadPicture: UserThunks.uploadPicture,
  getAllManufacturingEntities:
    ManufacturingEntityThunks.getAllManufacturingEntities,
  getAllRoleNames: UserRoleThunks.getAllRoleNames,
  getEvents: (userId: string, status: EventDefinitionStatus, page: number) =>
    getEventsThunk(status, page, [
      {
        key: 'subscribedUserId',
        operation: FilterOperation.EQ_OPERATOR,
        value: userId,
      },
    ]),
})(UserProperties);
