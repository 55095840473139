import {
  LoadingMetaState,
  OperationMetaState,
} from '@dev/base-web/dist/model/redux/helpers/interfaces';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { InstructionOverview } from '@/model/domain/instruction/instruction.ts';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import { UserPictureUpdateResultDTO } from '@dev/base-web/dist/model/domain/user/user_picture_update_result_dto';
import UserUpdateDTO from '@dev/base-web/dist/model/domain/user/user_update_dto';
import Event, {
  EventDefinitionStatus,
} from '../../model/domain/event/event_definition';
import { ConfigValue } from '@dev/base-web/dist/model/domain/configuration/configuration';
import { ValueExistDTO } from '@dev/base-web/dist/model/domain/common/value_exist';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';

export const NEW_USER_ROUTE_KEY = 'new';

export interface UserPropertiesStoreProps {
  readonly user: UserDetails | null;
  readonly usernameExists: ValueExistDTO | null;
  readonly currentUser: UserDetails | null;
  readonly instructions: InstructionOverview[];
  readonly events: Event[];
  readonly moreEventsCanBeLoaded: boolean;
  readonly eventsLoadingInProgress: boolean;
  readonly moreInstructionsCanBeLoaded: boolean;
  readonly instructionLoadingInProgress: boolean;
  readonly userLoadingInProgress: boolean;
  readonly updateUserOperation: OperationMetaState;
  readonly deleteUserOperation: OperationMetaState;
  readonly pictureUploadResult: UserPictureUpdateResultDTO | null;
  readonly pictureUploadInProgress: boolean;
  readonly manufacturingEntities: ManufacturingEntity[];
  readonly manufacturingEntitiesMeta: LoadingMetaState;
  readonly roleNames: string[];
  readonly eventOccurrenceCountDuration?: ConfigValue;
  readonly minimumPasswordStrength?: ConfigValue;
}

export interface UserPropertiesDispatchProps {
  readonly getInstructions: (
    isApproved: boolean,
    isArchived: boolean,
    page: number,
    filters: FilterData[],
    sortingKey?: string,
    sorting?: SortingDirection
  ) => void;
  readonly getEvents: (
    userId: string,
    status: EventDefinitionStatus,
    page: number
  ) => void;
  readonly getUserById: (userId: string) => void;
  readonly getUsernameExists: (username: string) => void;
  readonly updateUser: (userId: string, userData: UserUpdateDTO) => void;
  readonly createUser: (userData: UserUpdateDTO) => void;
  readonly deleteUsers: (userIds: string[]) => void;
  readonly uploadPicture: (picture: Blob) => void;
  readonly getAllManufacturingEntities: (query?: string) => void;
  readonly getAllRoleNames: () => void;
}

export type UserPropertiesProps = UserPropertiesStoreProps &
  UserPropertiesDispatchProps;
