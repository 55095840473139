import { Popup } from 'semantic-ui-react';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { FormattedMessage } from 'react-intl';
import ToggleSwitch from '@dev/base-web/dist/view/components/inputs/toggle_switch';
import { useState } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '@dev/base-web/dist/view/themes/interface';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-top: 8px;
`;

const StyledLabel = styled.div`
  display: flex;
  align-items: end;
  color: ${({ theme }: ThemeProps) => theme.colors.warmGrey};
  padding-bottom: 8px;
`;

interface InstructionSettingsPopup {
  readonly isNewInstruction: boolean;
  readonly readMode: boolean;
  readonly setReadMode: (readMode: boolean) => void;
  readonly canWrite: boolean;
  readonly canDelete: boolean;
  readonly setDeleteModalOpen: (open: boolean) => void;
  readonly duplicateInstruction: () => void;
}

const InstructionSettingsPopup = ({
  isNewInstruction,
  readMode,
  setReadMode,
  canWrite,
  canDelete,
  setDeleteModalOpen,
  duplicateInstruction,
}: InstructionSettingsPopup) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Popup
      wide
      trigger={<Button type="secondary" icon="settings" />}
      on="click"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      position={'bottom right'}
      pinned
      offset={[-14]}
      style={{ paddingLeft: 4 }}
    >
      <FormContainer>
        <div style={{ marginLeft: 10 }}>
          <StyledLabel>
            <FormattedMessage id={'read_mode'} />
          </StyledLabel>
          <ToggleSwitch
            checked={readMode}
            onChange={(checked) => {
              setReadMode(checked);
              setOpen(false);
            }}
          />
        </div>
        {!isNewInstruction && (
          <>
            <Button
              type="tertiary"
              label="duplicate_action"
              disabled={!canWrite}
              onClick={() => {
                duplicateInstruction();
                setOpen(false);
              }}
            />
            <Button
              type="tertiary"
              label="delete"
              disabled={!canDelete}
              onClick={() => {
                setDeleteModalOpen(true);
                setOpen(false);
              }}
            />
          </>
        )}
      </FormContainer>
    </Popup>
  );
};

export default InstructionSettingsPopup;
