import React from 'react';
import { toastr } from 'react-redux-toastr';
import ToastrIconSuccess from '@dev/base-web/dist/view/helpers/toastr_icon_success';
import { IntlShape } from 'react-intl';
import { ImageUpdate } from '@dev/base-web/dist/model/redux/media/interface';
import { toastrError } from '@dev/base-web/dist/view/helpers/notification_helpers';

export const toastrSuccess = (text: string) =>
  toastr.success('', text, {
    icon: React.createElement(ToastrIconSuccess, null),
  });

export function showNotificationForUpdate(
  prevState: ImageUpdate | null,
  currentState: ImageUpdate | null,
  successLabel: string,
  intl: IntlShape
) {
  if (prevState === undefined) {
    return;
  }

  if (currentState === undefined) {
    // successfully updated and cleared state
    toastrSuccess(intl.formatMessage({ id: successLabel }));
  }

  if (currentState?.meta.error && !currentState?.meta.error.handled) {
    toastrError(currentState.meta.error.message);
  }
}
