import { FormattedMessage } from 'react-intl';
import { InputOnChangeData } from 'semantic-ui-react';
import React from 'react';
import {
  DropdownWithLabelContainer,
  LabelContainer,
} from '../styled_components';
import styled from 'styled-components';
import InputWithCount from '@dev/base-web/dist/view/components/inputs/input_with_count';

const StyledInputWithCount = styled(InputWithCount)`
  & {
    min-width: 300px;
  }
`;

interface DowntimeCommentSelectorProps {
  readonly comment: string | undefined;
  readonly onCommentChanged: (comment: string) => void;
  readonly disabled: boolean;
}

const DowntimeCommentSelector = ({
  comment,
  onCommentChanged,
  disabled,
}: DowntimeCommentSelectorProps) => {
  const editComment = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => onCommentChanged(data.value);

  return (
    <DropdownWithLabelContainer>
      <LabelContainer>
        <FormattedMessage id={'downtime_comment_optional'} />
      </LabelContainer>
      <StyledInputWithCount
        name="name"
        onChange={editComment}
        value={comment}
        showCount
        disabled={disabled}
      />
    </DropdownWithLabelContainer>
  );
};

export default DowntimeCommentSelector;
