import { RootReducerInterface } from '../../model/redux/interfaces';
import { connect } from 'react-redux';
import EventWizard from './view';
import { EventWizardStoreProps } from './interface';
import ManufacturingEntityThunks from '../../model/redux/manufacturing_entity/thunks';
import FeatureThunks, {
  newCreateFeature,
  newUpdateFeature,
} from '../../model/redux/feature/thunks';
import {
  addInstructionsToEvent,
  createEvent,
  getEvent,
  multiUpdateEvents,
  removeInstructionFromEvent,
} from '../../model/redux/event_definition/thunks';
import SignalThunks from '../../model/redux/signal/thunks';
import { cleanInstruction } from '../../model/redux/instruction_definition/thunks';

const mapStateToProps = (
  state: RootReducerInterface
): EventWizardStoreProps => {
  return {
    event: state.data.eventDefinitionState.event.data,
    eventOperationState: state.data.eventDefinitionState.event.meta,
    manufacturingEntities:
      state.data.manufacturingEntityState.manufacturingEntities.data,
    manufacturingEntitiesLoading:
      state.data.manufacturingEntityState.manufacturingEntities.meta
        .loadingInProgress,
    featureConfiguration: state.data.featureState.featureConfiguration.data,
    featureCreation: state.data.featureState.featureOperation.meta,
    feature: state.data.featureState.feature,
    featureConfigurationLoading:
      state.data.featureState.featureConfiguration.meta.loadingInProgress,
    signals: state.data.signalState.signals.data.results,
    featureValues: state.data.featureState.featureValues.data,
    featureValuesLoading:
      state.data.featureState.featureValues.meta.loadingInProgress,
    featureValuesError: state.data.featureState.featureValues.meta.error,
    eventCreation: state.data.eventDefinitionState.createEvent.meta,
    updateEvent: state.data.eventDefinitionState.multiUpdateEvent.meta,
    signalsMeta: state.data.signalState.signals.meta,
    moreSignalsCanBeLoaded: state.data.signalState.signals.data.hasMoreResults,
    selfUser: state.userState.currentUser.data,
    instructionAddedToEvent:
      state.data.instructionDefinitionState.addEventsToInstruction.meta,
    instructionRemovedFromEvent:
      state.data.eventDefinitionState.removeInstructionFromEvent.meta,
    commentRemovedFromEvent:
      state.data.commentState.removeCommentFromEvent.meta,
    featureTemplateState: state.data.featureState.featureTemplates,
    configs: state.configurationState.config.data,
  };
};

export default connect(mapStateToProps, {
  getEvent,
  updateEvents: multiUpdateEvents,
  createEvent: createEvent,
  createFeature: newCreateFeature,
  updateFeature: newUpdateFeature,
  getConfiguration: FeatureThunks.getConfiguration,
  getAllManufacturingEntities:
    ManufacturingEntityThunks.getManufacturingEntities,
  getSignalsForPlc: SignalThunks.getSignalsForPlc,
  getSignalsForManufacturingEntity:
    SignalThunks.getSignalsForManufacturingEntity,
  getSignals: SignalThunks.getSignals,
  computeFeatureValues: FeatureThunks.computeFeatureValues,
  clearFeatureValues: FeatureThunks.clearFeatureValues,
  addInstructionToEvent: addInstructionsToEvent,
  removeInstructionFromEvent,
  getFeatureTemplates: FeatureThunks.getFeatureTemplates,
  cleanInstruction,
})(EventWizard);
