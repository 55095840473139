import { useEffect, useState } from 'react';
import { EventCoreInterface } from '@dev/base-web/dist/model/domain/event/event_type';
import DowntimeOverview from '@dev/base-web/dist/model/domain/report/DowntimeOverview';
import Downtime from '@dev/base-web/dist/model/domain/report/Downtime';
import { DowntimeReason } from '@dev/base-web/dist/model/domain/report/DowntimeReason';
import DowntimeAction from '@dev/base-web/dist/model/domain/report/DowntimeAction';
import DowntimeEvent from '@dev/base-web/dist/model/domain/report/DowntimeEvent';
import EventDetail from '../../../../../model/domain/event/event';

const useDowntimeReportEditing = (
  downtimeOverview: DowntimeOverview,
  chosenActionName: string | undefined,
  selectedDowntime: Downtime | null,
  selected: boolean,
  getDowntime: (reportId: string, downTimeId: string) => void,
  reportId: string,
  id: string,
  onSelected: () => void,
  getSelectedEvent: (eventId: string) => void,
  selectedEventData: EventDetail | null
) => {
  const [selectedEvent, setSelectedEvent] = useState<
    EventCoreInterface | undefined
  >(downtimeOverview.causingEvent);
  const [selectedOtherReason, setSelectedOtherReason] = useState<
    DowntimeReason | undefined
  >(
    downtimeOverview.cause
      ? { name: downtimeOverview.cause, isSelectable: true }
      : undefined
  );
  const [selectedActionName, setSelectedActionName] = useState<
    string | undefined
  >(chosenActionName);
  const [selectedDowntimeAction, setSelectedDowntimeAction] = useState<
    DowntimeAction | undefined
  >();
  const [selectedPossibleEvent, setSelectedPossibleEvent] = useState<
    DowntimeEvent | undefined
  >();
  const [comment, setComment] = useState<string | undefined>(
    downtimeOverview.comment
  );

  useEffect(() => {
    if (selectedEvent && selectedDowntime) {
      if (
        downtimeOverview.id === selectedDowntime.id &&
        selectedDowntime.possibleEvents.length === 0 &&
        downtimeOverview.causingEvent &&
        selectedDowntime.isDocumented
      ) {
        getSelectedEvent(downtimeOverview.causingEvent.id);
      }

      const foundSelectedPossibleEvent = selectedDowntime.possibleEvents.find(
        (event) => event.id === selectedEvent.id
      );

      if (foundSelectedPossibleEvent)
        setSelectedPossibleEvent(foundSelectedPossibleEvent);
      else
        setSelectedPossibleEvent({
          ...selectedEvent,
          manufacturingEntity: selectedDowntime.lastLineManufacturingEntity,
          causedDowntime: true,
          possibleActions: [],
        });
    }
  }, [selectedDowntime]);

  useEffect(() => {
    setSelectedEvent(downtimeOverview.causingEvent);
    setComment(downtimeOverview.comment);
    setSelectedOtherReason(
      downtimeOverview.cause
        ? { name: downtimeOverview.cause, isSelectable: true }
        : undefined
    );

    setSelectedActionName(downtimeOverview.chosenActionName);
  }, [downtimeOverview]);

  useEffect(() => {
    if (
      selectedEventData &&
      selectedEventData.id === selectedEvent?.id &&
      selectedPossibleEvent
    ) {
      setSelectedPossibleEvent({
        ...selectedPossibleEvent,
        possibleActions: selectedEventData.actions
          .filter((a) => a.isApproved)
          .map((a) => {
            return { ...a, isChosen: false };
          }),
      });
    }
  }, [selectedEventData]);

  const onRowPressed = () => {
    if (!selected) {
      getDowntime(reportId, id);
    }
    onSelected();
  };

  const onPossibleEventSelected = (event: DowntimeEvent | undefined) => {
    setSelectedEvent(event);
    setSelectedPossibleEvent(event);
    setSelectedOtherReason(undefined);

    if (event?.id) getSelectedEvent(event?.id);
  };

  const onOtherReasonSelected = (reason: DowntimeReason | undefined) => {
    setSelectedEvent(undefined);
    setSelectedPossibleEvent(undefined);
    setSelectedOtherReason(reason);
  };

  const onActionSelected = (actionName: string) => {
    setSelectedActionName(actionName);
    if (selectedPossibleEvent) {
      const selectedAction = selectedPossibleEvent.possibleActions.find(
        (action) => action.name === actionName
      );

      if (selectedAction) setSelectedDowntimeAction(selectedAction);
      else {
        setSelectedDowntimeAction({ name: actionName, isChosen: true });
      }
    }
  };

  const onCommentChanged = (comment: string) => {
    setComment(comment);
  };

  return {
    selectedEvent,
    selectedOtherReason,
    selectedActionName,
    selectedDowntimeAction,
    selectedPossibleEvent,
    comment,
    onRowPressed,
    onPossibleEventSelected,
    onOtherReasonSelected,
    onActionSelected,
    onCommentChanged,
  };
};

export default useDowntimeReportEditing;
