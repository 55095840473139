import { connect } from 'react-redux';
import { ProductionProgressCardStoreProps } from './interface';
import ProductionProgressCard from './view';
import { RootReducerInterface } from '../../../../../../model/redux/interfaces';

const mapStateToProps = (
  state: RootReducerInterface
): ProductionProgressCardStoreProps => {
  return {
    productionProgress: state.data.mainEntityKpiState.productionProgress.data,
    productionProgressMeta:
      state.data.mainEntityKpiState.productionProgress.meta,
    durationDeviations: state.data.durations.durationDeviations.data,
    durationDeviationsMeta: state.data.durations.durationDeviations.meta,
  };
};

export default connect(mapStateToProps, {})(ProductionProgressCard);
