import UserUpdateDTO from '@dev/base-web/dist/model/domain/user/user_update_dto';
import NotificationSettings from './notification_settings';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import UserSubscriptionTopic from '@dev/base-web/dist/model/domain/user/user_subscription_topic';
import React from 'react';

interface NotificationsCardProps {
  readonly userData: UserUpdateDTO;
  readonly isUserEditable?: boolean;
  readonly updateUserData: (newUserData: UserUpdateDTO) => void;
}

const NotificationsCard: React.FC<NotificationsCardProps> = ({
  userData,
  updateUserData,
}) => {
  const updateSubscriptionTopics = (topics: UserSubscriptionTopic[]) => {
    updateUserData({ subscriptionTopics: topics });
  };

  return (
    <Card titleId={'notifications'}>
      <NotificationSettings
        userRole={userData.role}
        subscriptionTopics={userData.subscriptionTopics}
        updateSubscriptionTopics={updateSubscriptionTopics}
      />
    </Card>
  );
};

export default NotificationsCard;
