import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';

const Description = styled.h2`
  margin: 16px 0 8px 0;
  font-size: 14px;
  color: #8c8c8c;
`;

interface ChangeTypeModalProps {
  readonly open: boolean;
  readonly changeCallback: () => void;
  readonly closeCallback: () => void;
  readonly cancelCallback: () => void;
}

class ChangeTypeModal extends Component<ChangeTypeModalProps> {
  constructor(props: ChangeTypeModalProps) {
    super(props);

    this.state = {
      selectedManufacturingEntity: undefined,
      changeMan: false,
    };
  }

  onSwitch = (checked: boolean) => {
    this.setState({ changeMan: checked });
  };

  handleOk = () => {
    this.props.changeCallback();
  };

  handleCancel = () => {
    this.props.cancelCallback();
  };

  render() {
    return (
      <BasicModalWithHeaderAndTwoButtons
        open={this.props.open}
        headerLabel="remove_or_update_production_entity"
        cancelLabel="cancel_button"
        okLabel="ok_button"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        onClose={this.props.closeCallback}
      >
        <Description>
          <FormattedMessage id="task_event_change_text" />
        </Description>
      </BasicModalWithHeaderAndTwoButtons>
    );
  }
}

export default ChangeTypeModal;
