import { FormattedMessage } from 'react-intl';
import React from 'react';
import { EventTypeTagSelect } from '@dev/base-web/dist/view/components/global/event_type_tag';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import styled from 'styled-components';
import { Step } from 'react-joyride';

export interface StepWithTitle extends Step {
  titleText?: string;
}

const StyledDiv = styled.div`
  && {
    padding: 16px 0 16px 0;

    .ui.button {
      background-color: white;
    }
  }
`;

const anyName = () => {
  return (
    <>
      <FormattedMessage id="tour_shannon_dashboard_current_events_content_1" />
      <StyledDiv>
        <EventTypeTagSelect value={[]} onSelect={() => {}} />
      </StyledDiv>
      <FormattedMessage id="tour_shannon_dashboard_current_events_content_2" />
      <Icon name="filter" color="#ffffff" size={16} />
      <FormattedMessage
        id="tour_shannon_dashboard_current_events_content_3"
        values={{ lineBreak: <br /> }}
      />
    </>
  );
};

const rightStart = 'right-start';
const leftStart = 'left-start';
const bottomStart = 'bottom-start';

export const pages: Record<string, StepWithTitle[]> = {
  dashboard: [
    {
      target: 'body',
      titleText: 'tour_shannon_dashboard_welcome_to_shannon',
      content: (
        <FormattedMessage id="tour_shannon_dashboard_welcome_to_shannon_content" />
      ),
      disableBeacon: true, // This makes the tour to start automatically without clicking
      placement: 'center',
    },
    {
      target: '#curr_ev',
      titleText: 'current_events',
      content: anyName(),
      placement: rightStart,
    },
    {
      target: '#downtimes',
      titleText: 'downtimes',
      content: (
        <FormattedMessage id="tour_shannon_dashboard_downtimes_content" />
      ),
      placement: 'left',
    },
    {
      target: '#curr_ev',
      titleText: 'current_events',
      content: (
        <FormattedMessage id="tour_shannon_dashboard_current_events_2_content" />
      ),
      placement: rightStart,
      spotlightClicks: true,
      hideFooter: true,
      hideBackButton: false,
      showSkipButton: false,
    },
    {
      target: '#man_cap',
      titleText: 'managing_capabilities',
      content: (
        <FormattedMessage id="tour_shannon_dashboard_capabilities_content" />
      ),
      hideBackButton: true,
    },
    {
      target: '#hamburger-menu',
      titleText: 'additional_functions',
      content: (
        <FormattedMessage id="tour_shannon_dashboard_additional_functions_content" />
      ),
      placement: bottomStart,
    },
    {
      target: 'body',
      titleText: 'have_fun_with_shannon',
      content: (
        <FormattedMessage id="tour_shannon_dashboard_have_fun_content" />
      ),
      placement: 'center',
    },
  ],
  currentEvents: [
    {
      target: '#tab_bar',
      titleText: 'categories',
      content: (
        <FormattedMessage id="tour_shannon_current_events_categories_content" />
      ),
      placement: 'bottom-start',
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: '#ev_card',
      titleText: 'event_details',
      content: (
        <FormattedMessage id="tour_shannon_current_events_details_content" />
      ),
      placement: leftStart,
    },
    {
      target: '#solution_list',
      titleText: 'solutions',
      content: (
        <FormattedMessage id="tour_shannon_current_events_solution_list_content" />
      ),
      placement: leftStart,
      spotlightClicks: true,
      hideFooter: true,
    },
    {
      target: '#solution',
      titleText: 'solution',
      content: (
        <FormattedMessage id="tour_shannon_current_events_solutions_content" />
      ),
      placement: leftStart,
      spotlightClicks: true,
    },
    {
      target: '#add-new',
      titleText: 'solutions',
      content: (
        <FormattedMessage id="tour_shannon_current_events_add_solution" />
      ),
      placement: leftStart,
      spotlightClicks: true,
      hideFooter: true,
    },
  ],
  solution: [
    {
      target: '#solution_title',
      titleText: 'solution_title',
      content: <FormattedMessage id="tour_shannon_solution_title_content" />,
      placement: bottomStart,
      disableBeacon: true,
    },
    {
      target: '#add_step',
      titleText: 'instruction',
      content: (
        <FormattedMessage id="tour_shannon_solution_add_instruction_content" />
      ),
      placement: bottomStart,
      spotlightClicks: true,
      hideFooter: true,
    },
    {
      target: '.step',
      titleText: 'instruction',
      content: (
        <FormattedMessage id="tour_shannon_solution_instruction_content" />
      ),
      placement: bottomStart,
    },
    {
      target: '#add_decision',
      titleText: 'decision',
      content: (
        <FormattedMessage id="tour_shannon_solution_add_decision_content" />
      ),
      placement: bottomStart,
      spotlightClicks: true,
      hideFooter: true,
    },
    {
      target: '#decision',
      titleText: 'decision',
      content: <FormattedMessage id="tour_shannon_solution_decision_content" />,
      placement: 'top-start',
    },
    {
      target: '#save_button',
      titleText: 'save',
      content: <FormattedMessage id="tour_shannon_solution_save_content" />,
      placement: bottomStart,
    },
    {
      target: '#save_button',
      titleText: 'save',
      content: <FormattedMessage id="tour_shannon_solution_save_content" />,
      placement: bottomStart,
    },
  ],
};
