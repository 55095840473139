import { Component } from 'react';
import styled from 'styled-components';
import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import RuleCopyModalContent from './rule_copy_modal_content';
import FeatureConfigurationInstance from '@dev/base-web/dist/model/domain/feature/feature_configuration';

const Wrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
`;

interface RuleCopyModalExternalProps {
  rule: any;
  newStage: string;
  readonly open: boolean;
  readonly changeCallback: () => void;
  closeCallback: () => void;
  cancelCallback: () => void;
  readonly featureConfiguration: FeatureConfigurationInstance | null;
}

type RuleCopyModalProps = RuleCopyModalExternalProps;

class RuleCopyModal extends Component<RuleCopyModalProps> {
  constructor(props: RuleCopyModalProps) {
    super(props);
  }

  render() {
    const { newStage, rule, featureConfiguration } = this.props;

    return (
      <BasicModalWithHeaderAndTwoButtons
        open={this.props.open}
        headerLabel={`auto_update_of_${
          newStage === 'open' ? 'trigger' : 'termination'
        }`}
        cancelLabel="cancel_button"
        okLabel={'update'}
        onOk={() => this.props.changeCallback()}
        onCancel={() => this.props.cancelCallback()}
        onClose={() => this.props.closeCallback()}
        modalSize="large"
      >
        <Wrapper>
          <Hint
            messageKey={`auto_update_modal_text_${
              newStage === 'open' ? 'trigger' : 'termination'
            }`}
          />
        </Wrapper>
        <RuleCopyModalContent
          newStage={newStage}
          rule={rule}
          featureConfiguration={featureConfiguration}
        />
      </BasicModalWithHeaderAndTwoButtons>
    );
  }
}

export default RuleCopyModal;
