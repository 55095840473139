import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';
import { FormattedMessage } from 'react-intl';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import { useState } from 'react';
import styled from 'styled-components';
import { InputOnChangeData } from 'semantic-ui-react';
import InputWithCount from '@dev/base-web/dist/view/components/inputs/input_with_count';
import { LabelContainer } from '../styled_components';
import { InputErrorContainer } from '@dev/base-web/dist/view/components/inputs/styled_components';
import DowntimeAction from '@dev/base-web/dist/model/domain/report/DowntimeAction';

const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const StyledInputWithCount = styled(InputWithCount)`
  & {
    min-width: 400px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 400px;
  margin-right: 24px;
`;

const StyledInputErrorContainer = styled(InputErrorContainer)`
  & {
    padding-bottom: 24px;
  }
`;

interface AddSolutionDialogProps {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly newSolutionName?: string;
  readonly addNewSolution: (newSolutionName: string) => void;
  readonly existingSolutions: readonly DowntimeAction[];
}

const AddSolutionDialog = ({
  newSolutionName,
  addNewSolution,
  existingSolutions,
  open,
  onClose,
}: AddSolutionDialogProps) => {
  const [newSolutionTitle, setNewSolutionTitle] = useState<string | undefined>(
    newSolutionName
  );

  const editSolutionTitle = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setNewSolutionTitle(data.value);
  };

  const isDuplicateSolution =
    newSolutionTitle &&
    existingSolutions.some((s) => s.name === newSolutionTitle);

  return (
    <BasicModalWithHeaderAndTwoButtons
      headerLabel={'add_new_solution'}
      open={open}
      onClose={onClose}
      okLabel={'add'}
      cancelLabel={'cancel'}
      onCancel={onClose}
      onOk={() => {
        newSolutionTitle && addNewSolution(newSolutionTitle);
      }}
      okDisabled={!newSolutionTitle || !!isDuplicateSolution}
    >
      <Container>
        <LabelContainer>
          <FormattedMessage id={'how_was_error_solved'} />
        </LabelContainer>
        <InputContainer>
          <StyledInputWithCount
            name="name"
            onChange={editSolutionTitle}
            value={newSolutionTitle}
            showCount
            error={isDuplicateSolution}
          />
        </InputContainer>
        <StyledInputErrorContainer>
          {isDuplicateSolution && (
            <FormattedMessage id="solution_already_exists" />
          )}
        </StyledInputErrorContainer>
        <Hint messageKey={'add_new_short_solution_hint'} />
      </Container>
    </BasicModalWithHeaderAndTwoButtons>
  );
};

export default AddSolutionDialog;
