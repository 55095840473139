import React, { useEffect, useMemo, useState } from 'react';
import { SelectRawEventsProps } from './interface';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import { TableContainer } from '@/screens/events/view.tsx';
import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import RawEvent from '../../../../../../model/domain/event/raw_event';
import { FormattedMessage, useIntl } from 'react-intl';
import RawEventTableRow from './table_row';
import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';
import {
  createColumns,
  generateDowntimeReportEventMatchingAlerts,
} from './helper';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import styled from 'styled-components';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import Alert from '@dev/base-web/dist/view/components/global/alert';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';

const MANUFACTURING_ENTITY_ID = 'manufacturingEntity.id';

const ListItemInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  line-height: 16px;
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

const ValidationAlertContainer = styled.div`
  margin-top: 12px;
  max-width: 560px;
`;

const SelectRawEventsDialog: React.FC<SelectRawEventsProps> = ({
  begin,
  end,
  selectedManufacturingEntity,
  rawEvents,
  rawEventsMeta,
  moreEventsCanBeLoaded,
  getRawEvents,
  showManufacturingEntityHierarchy,
  onSelectEvent,
  modalOpen,
  onClose,
  selectedEvent,
  throughputTime,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const { formatTimeSpan } = useLocalizedDateFormatters();

  const [selectedRow, setSelectedRow] = useState<RawEvent>();
  const [localFilters, setLocalFilters] = useState<readonly FilterData[]>([]);

  useEffect(() => {
    if (selectedEvent && !selectedRow && rawEvents.length > 0) {
      const rawEvent = rawEvents.find((e) => e.event.id === selectedEvent.id);

      if (rawEvent) setSelectedRow(rawEvent);
    }
  }, [selectedEvent, rawEvents]);

  useEffect(() => {
    setLocalFilters([
      {
        key: 'event.type',
        operation: FilterOperation.EQ_OPERATOR,
        value: EventType.ERROR,
      },
      {
        key: MANUFACTURING_ENTITY_ID,
        operation: FilterOperation.EQ_OPERATOR,
        value: [selectedManufacturingEntity.id],
      },
      {
        key: 'eventStart',
        operation: FilterOperation.GE_OPERATOR,
        value: begin - (throughputTime || 30 * 1000),
      },
      {
        key: 'eventStart',
        operation: FilterOperation.LE_OPERATOR,
        value: end,
      },
      {
        key: 'isParent',
        operation: FilterOperation.EQ_OPERATOR,
        value: true,
      },
    ]);
  }, [begin, end, selectedManufacturingEntity, throughputTime]);

  const getLocalRawEvents = (
    page: number,
    filters: readonly FilterData[],
    sortKey?: string,
    sortDirection?: SortingDirection
  ) => {
    // check if manufacturing entity filter is present. If not, add a default filter.
    let localFilters;
    if (
      filters.some(
        (f) =>
          f.key === MANUFACTURING_ENTITY_ID &&
          Array.isArray(f.value) &&
          f.value.length > 0
      )
    ) {
      localFilters = filters;
    } else
      localFilters = [
        ...filters.filter((f) => f.key !== MANUFACTURING_ENTITY_ID),
        {
          key: MANUFACTURING_ENTITY_ID,
          operation: FilterOperation.EQ_OPERATOR,
          value: selectedManufacturingEntity.id,
        },
      ];

    getRawEvents(page, localFilters, sortKey, sortDirection);
    //setLocalFilters(filters);
  };

  const alerts = useMemo(() => {
    return generateDowntimeReportEventMatchingAlerts(begin, end, selectedRow);
  }, [begin, end, selectedRow]);

  const timeSpan = formatTimeSpan(begin, end, 'Ppp', 'pp');

  const onCloseModal = () => {
    setSelectedRow(undefined);
    onClose();
  };

  return (
    <BasicModalWithHeaderAndTwoButtons
      okLabel="select"
      cancelLabel="cancel"
      headerLabel={'select_causing_event'}
      onOk={() => selectedRow && onSelectEvent(selectedRow)}
      okDisabled={!selectedRow}
      modalSize={'fullscreen'}
      open={modalOpen}
      onClose={onCloseModal}
      onCancel={onCloseModal}
    >
      <FieldsContainer>
        <ListItemInfo>
          <Icon name="error" size={16} color="#5e0c34" />
          <FormattedMessage id="downtime" />
        </ListItemInfo>
        <ListItemInfo>
          <Icon name="location" size={16} color="#939393" />
          {selectedManufacturingEntity.name}
        </ListItemInfo>
        <ListItemInfo>
          <Icon name="time" size={16} color="#939393" />
          {timeSpan}
        </ListItemInfo>
      </FieldsContainer>
      <Hint
        messageKey={'choose_indiviual_downtime_report_event_hint'}
        messageValues={{
          br: <br />,
        }}
        textColor={theme.colors.black}
      />
      <TableContainer>
        <SortableTable
          dataLength={rawEvents.length}
          hasMoreResults={moreEventsCanBeLoaded}
          columns={createColumns(intl, selectedManufacturingEntity.id)}
          getNextResults={getLocalRawEvents}
          loadingInProgress={rawEventsMeta.loadingInProgress}
          externalFilters={localFilters}
          externalSortingConfig={{
            key: 'eventStart',
            direction: SortingDirection.ASCENDING,
          }}
          maxHeight={400}
          minHeight={200}
          openFiltersOnMount
          error={rawEventsMeta.error}
          onAllRowsCheckboxChanged={() => {}}
          headerCheckboxInvisible
        >
          {rawEvents.map((event) => {
            return (
              <RawEventTableRow
                result={event}
                onRowChecked={(checked: boolean) => {
                  if (!checked) setSelectedRow(undefined);
                  else setSelectedRow(event);
                }}
                selected={event.event.id === selectedRow?.event.id}
                showManufacturingEntityHierarchy={
                  !!showManufacturingEntityHierarchy
                }
              />
            );
          })}
        </SortableTable>
      </TableContainer>
      {alerts.map((a) => (
        <ValidationAlertContainer>
          <Alert
            severity={a.severity}
            textId={a.textId}
            textValues={a.textValues}
          />
        </ValidationAlertContainer>
      ))}
    </BasicModalWithHeaderAndTwoButtons>
  );
};

export default SelectRawEventsDialog;
