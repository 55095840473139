import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import {
  Card,
  CardInfiniteScroll,
} from '@dev/base-web/dist/view/components/global/card';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import EmptyOrLoadingView from '@dev/base-web/dist/view/components/global/no_data';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';
import { useCurrentShiftDowntimes } from '../../../../model/redux/report/hooks';
import OpenDowntimeReportsListCardTitle from './components/open_downtime_reports_list_card_title';
import OpenDowntimeReportsListItem, {
  OPEN_DOWNTIME_REPORT_LIST_ITEM_HEIGHT,
} from './components/open_downtime_reports_list_item';
import { REQUEST_CANCEL_ERROR } from '@dev/base-web/dist/model/api/error/error_handler';

export interface OpenDowntimeReportsListCardProps {
  readonly selectedEntity?: ManufacturingEntity;
}

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;

  // 72 px for card title height and 24px for bottom margin
  height: calc(100% - 72px - 24px);

  //margin-left: 24px;
  margin-bottom: 24px;
`;

const LinearGradientPadding = styled.div`
  margin-top: ${OPEN_DOWNTIME_REPORT_LIST_ITEM_HEIGHT / 2}px;
`;

const LinearGradient = styled.div`
  position: absolute;
  width: 100%;
  pointer-events: none;
  bottom: 0px;
  left: 0px;
  height: ${OPEN_DOWNTIME_REPORT_LIST_ITEM_HEIGHT}px;
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9) 45%,
    white
  );
`;

const HintContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
`;

const OpenDowntimeReportsListCard = ({
  selectedEntity,
}: OpenDowntimeReportsListCardProps) => {
  const {
    currentShift,
    currentShiftLoading,
    currentShiftError,
    shiftReport,
    shiftReportLoading,
    shiftReportError,
    openDowntimes,
    openDowntimesLoading,
    openDowntimesError,
    openDowntimesTotalCount,
    loadNextPage,
  } = useCurrentShiftDowntimes(selectedEntity === undefined, selectedEntity);

  const { ref, height = 1 } = useResizeObserver<HTMLDivElement>();

  const loading =
    currentShiftLoading || shiftReportLoading || openDowntimesLoading;

  const thereWasAnError =
    (currentShiftError && currentShiftError !== REQUEST_CANCEL_ERROR) ||
    (openDowntimesError && openDowntimesError !== REQUEST_CANCEL_ERROR);

  return (
    <Card
      margin="none"
      titleContent={
        <OpenDowntimeReportsListCardTitle
          count={openDowntimesTotalCount ?? 0}
        />
      }
    >
      <ListContainer ref={ref}>
        <CardInfiniteScroll
          dataLength={openDowntimes.length}
          next={loadNextPage}
          height={height}
          hasMore={(openDowntimesTotalCount ?? 0) > openDowntimes.length}
        >
          {openDowntimes.map((d) => {
            return (
              <OpenDowntimeReportsListItem
                key={d.id}
                shiftReportId={shiftReport?.id}
                loading={openDowntimesLoading}
                openDowntimeReport={d}
              />
            );
          })}
          {openDowntimes.length > 3 && (
            <>
              <LinearGradientPadding />
              <LinearGradient />
            </>
          )}
          {!currentShift && !loading ? (
            <HintContainer>
              <Hint messageKey={'no_shift_present'} />
            </HintContainer>
          ) : (!shiftReport || shiftReportError) && !loading ? (
            <HintContainer>
              <Hint messageKey={'shift_could_not_retrieved'} />
            </HintContainer>
          ) : (
            <EmptyOrLoadingView
              loadingInProgress={loading}
              hasError={thereWasAnError}
              hasResults={openDowntimes.length > 0}
            />
          )}
        </CardInfiniteScroll>
      </ListContainer>
    </Card>
  );
};

export default OpenDowntimeReportsListCard;
