import { Checkbox } from '@dev/base-web/dist/view/components/global/checkbox';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import React from 'react';
import { InstructionOverview } from '@/model/domain/instruction/instruction.ts';
import { StyledRightColumn } from '@/components/StyledComponents.tsx';
import { useNavigate } from 'react-router';
import { INSTRUCTIONS_ROUTE_KEY } from '../interfaces';
import styled from 'styled-components';
import {
  LeftAlignedTableCell,
  StyledRow,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import TextWithEllipsisAndTooltip, {
  EllipsisContainer,
} from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import { FormattedMessage } from 'react-intl';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';
import { ManufacturingEntityType } from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import MultiManufacturingEntityPopup from '../../../components/multi_manufacturing_entity_popup';
import UserCell from '@dev/base-web/dist/view/components/global/user_cell';
import LabelsWithEllipsis from '@dev/base-web/dist/view/components/global/labels_with_ellipsis';

const ActionName = styled(LeftAlignedTableCell)`
  font-family: Relative;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #242424;

  &&&& {
    vertical-align: top;
    padding-top: 20px !important;
  }
`;

interface ScoreProps {
  readonly color: number;
}

const Score = styled(LeftAlignedTableCell)<ScoreProps>`
  color: red;
  ${(props) => props.color > 50 && 'color: orange;'}
  ${(props) => props.color > 80 && 'color: green;'}

  &&&& {
    vertical-align: top;
    padding-top: 20px !important;
  }
`;

export const LabelGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  max-height: 64px;
  width: 100%;
  white-space: nowrap;
  padding-right: 10px;
  & > *:not(:last-child) {
    margin-right: 8px;
  }

  & > * {
    margin-top: 6px;
    margin-right: 8px;
  }
  overflow: hidden;
  position: relative;
`;

const StyledLeftAlignedTableCell = styled(LeftAlignedTableCell)`
  &&&& {
    vertical-align: top;
    padding-top: 20px !important;
  }
`;

const LeftAlignedUserTableCell = styled(LeftAlignedTableCell)`
  &&&& {
    vertical-align: top;
  }
`;

const CheckboxCell = styled.div`
  display: flex;
  justify-content: center;
  align-content: flex-start;
  width: 32px;
  padding-top: 24px;
  margin: auto;
`;

export const EllipsisButtonContainer = styled.div`
  display: flex;
  margin-left: 10px;
  justify-content: space-around;
  align-content: center;
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  border-radius: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const LabelsPopup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  width: 25.5%;
  z-index: 10;
  border-radius: 10px;
  margin-top: -20px;
`;

interface InstructionRowProps {
  data: InstructionOverview;
  onRowChecked: (selected: boolean) => void;
  disabled?: boolean;
  selected: boolean;
  showManufacturingEntityHierarchy: boolean;
}

const InstructionRow: React.FC<InstructionRowProps> = ({
  data: {
    id,
    createdDate,
    accessCount,
    votes,
    name,
    createdBy,
    accessLevel,
    manufacturingEntities,
    labels,
  },
  selected,
  disabled = false,
  onRowChecked,
  showManufacturingEntityHierarchy,
}) => {
  const { formatTimestamp } = useLocalizedDateFormatters();

  const navigate = useNavigate();

  const score = votes >= 0 ? `${votes.toFixed(0)} %` : '-';

  const locationSet = new Set(
    manufacturingEntities.map((entity) => entity.name)
  );
  const locations = [...locationSet].join(', ');

  const onActionChecked = (event: React.FormEvent<HTMLInputElement>) => {
    onRowChecked(!selected);
    event.stopPropagation();
  };

  const onRowSelected = () => {
    navigate(`/${INSTRUCTIONS_ROUTE_KEY}/${id}`);
  };

  return (
    <StyledRow
      disabled={disabled}
      cursor={disabled ? 'default' : 'pointer'}
      onClick={disabled ? undefined : onRowSelected}
    >
      <CheckboxCell>
        <Checkbox
          onChange={disabled ? undefined : onActionChecked}
          checked={selected}
        />
      </CheckboxCell>
      <ActionName>
        <TextWithEllipsisAndTooltip text={name} lines={2} />
        <LabelsWithEllipsis labels={labels} />
      </ActionName>
      <StyledLeftAlignedTableCell>
        {manufacturingEntities.length == 0 ? (
          '-'
        ) : manufacturingEntities.length == 1 ? (
          <ManufacturingEntityHierarchyElement
            manufacturingEntity={manufacturingEntities[0]}
            hideHiddenTypes
            showShortNames
            hiddenTypes={[
              ManufacturingEntityType.FACTORY,
              ManufacturingEntityType.AREA,
              ManufacturingEntityType.LINE,
            ]}
            showHierarchy={showManufacturingEntityHierarchy}
          />
        ) : (
          <MultiManufacturingEntityPopup
            manufacturingEntities={manufacturingEntities}
            showHierarchy={showManufacturingEntityHierarchy}
            trigger={
              <EllipsisContainer lines={2}>{locations}</EllipsisContainer>
            }
          />
        )}
      </StyledLeftAlignedTableCell>
      <Score color={votes}>{score}</Score>
      <StyledLeftAlignedTableCell>{accessCount}</StyledLeftAlignedTableCell>
      <StyledLeftAlignedTableCell>
        <FormattedMessage id={accessLevel.toLowerCase()} />
      </StyledLeftAlignedTableCell>
      <LeftAlignedUserTableCell>
        <UserCell user={createdBy} />
      </LeftAlignedUserTableCell>
      <StyledLeftAlignedTableCell>
        {formatTimestamp(createdDate)}
      </StyledLeftAlignedTableCell>
      <StyledLeftAlignedTableCell>
        <StyledRightColumn>
          <Icon name={'arrow-right-bold'} color={'#8c8c8c'} size={16} />
        </StyledRightColumn>
      </StyledLeftAlignedTableCell>
    </StyledRow>
  );
};

export default InstructionRow;
