import {
  createActionsForExecutingOperation,
  createActionsForLoadingPagingList,
  createActionsForSingleItem,
} from '@dev/base-web/dist/model/redux/helpers/actions';
import Event, { EventDefinition } from '../../domain/event/event_definition';
import { createAction } from 'typesafe-actions';
import { Plc } from '@dev/base-web/dist/model/domain/plc/plc';

export const EVENT = 'EVENT';
export const EVENTS_KEY = 'EVENTS_KEY';
export const EVENTS_FOR_DASHBOARD_SEARCH_KEY = 'EVENTS_FOR_DASHBOARD_SEARCH';
export const UPDATE_EVENT_KEY = 'UPDATE_EVENT';
export const CREATE_EVENT_KEY = 'UPDATE_EVENT';
export const MULTIUPDATE_EVENT_KEY = 'MULTIUPDATE_EVENT';
export const ADD_EVENT_TO_INSTRUCTION_KEY = 'ADD_EVENT_TO_INSTRUCTION';
export const REMOVE_INSTRUCTION_FROM_EVENT_KEY =
  'REMOVE_INSTRUCTION_FROM_EVENT_KEY';
export const SET_ACTIVE_EVENTS_TAB = 'SET_ACTIVE_EVENTS_TAB';
export const SUBSCRIBE_EVENT_KEY = 'SUBSCRIBE_EVENT';
export const UNSUBSCRIBE_EVENT_KEY = 'UNSUBSCRIBE_EVENT';
export const INSTRUCTION_SUGGESTION_KEY = 'GET_INSTRUCTION_SUGGESTION_KEY';
export const EVENTS_FOR_ADD_RELATION_KEY = 'EVENTS_FOR_ADD_RELATION_KEY';
export const DATA_SOURCE_OF_EVENT = 'DATA_SOURCE_OF_EVENT';

export const Actions = {
  event: createActionsForSingleItem<EventDefinition>(EVENT),
  events: createActionsForLoadingPagingList<Event>(EVENTS_KEY),
  eventsForDashboardSearch: createActionsForLoadingPagingList<Event>(
    EVENTS_FOR_DASHBOARD_SEARCH_KEY
  ),
  eventsForAddRelationModal: createActionsForLoadingPagingList<Event>(
    EVENTS_FOR_ADD_RELATION_KEY
  ),
  getEvent: createActionsForSingleItem<Event>(EVENT),
  updateEvent: createActionsForExecutingOperation(UPDATE_EVENT_KEY),
  createEvent: createActionsForExecutingOperation(CREATE_EVENT_KEY),
  multiUpdateEvent: createActionsForExecutingOperation(MULTIUPDATE_EVENT_KEY),
  addEventToInstruction: createActionsForExecutingOperation(
    ADD_EVENT_TO_INSTRUCTION_KEY
  ),
  removeInstructionFromEvent: createActionsForExecutingOperation(
    REMOVE_INSTRUCTION_FROM_EVENT_KEY
  ),
  subscribeToEvent: createActionsForExecutingOperation(SUBSCRIBE_EVENT_KEY),
  unsubscribeFromEvent: createActionsForExecutingOperation(
    UNSUBSCRIBE_EVENT_KEY
  ),
  setActiveTab: createAction(SET_ACTIVE_EVENTS_TAB)<number>(),
  suggestedInstruction: createActionsForSingleItem<string>(
    INSTRUCTION_SUGGESTION_KEY
  ),
  dataSourceOfEvent: createActionsForSingleItem<Plc>(DATA_SOURCE_OF_EVENT),
};
