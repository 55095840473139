import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import { Checkbox } from '@dev/base-web/dist/view/components/global/checkbox';
import HintContainer from '@dev/base-web/dist/view/components/global/hint_container';
import {
  CenterAlignedTableCell,
  StyledFirstCell,
  StyledRow,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import { useIntl } from 'react-intl';
import UserSubscriptionTopicSettings from '../../../../model/domain/user/user_subscription_topic_setting';
import styled from 'styled-components';
import Icon from '@dev/base-web/dist/view/components/global/icon';

interface NotificationRowProps {
  readonly topic: UserSubscriptionTopicSettings;
  readonly updateTopic: (topic: UserSubscriptionTopicSettings) => void;
}

const HiddenIcon = styled(Icon)`
  visibility: hidden;
`;

const NotificationRow: React.FC<NotificationRowProps> = ({
  topic,
  updateTopic,
}) => {
  const intl = useIntl();

  return (
    <StyledRow>
      <StyledFirstCell>
        <HintContainer
          hint={topic.topic.toLowerCase() + '_notification_hint'}
          alignHintText={'left'}
        >
          <TextWithEllipsisAndTooltip
            text={intl.formatMessage({ id: topic.topic.toLowerCase() })}
            lines={2}
          />
        </HintContainer>
      </StyledFirstCell>
      <CenterAlignedTableCell>
        {topic.isEmail !== undefined && (
          <Checkbox
            onChange={() => updateTopic({ ...topic, isEmail: !topic.isEmail })}
            checked={topic.isEmail}
          />
        )}
      </CenterAlignedTableCell>
      <CenterAlignedTableCell>
        {topic.isPushNotification !== undefined && (
          <Checkbox
            onChange={() =>
              updateTopic({
                ...topic,
                isPushNotification: !topic.isPushNotification,
              })
            }
            checked={topic.isPushNotification}
          />
        )}
      </CenterAlignedTableCell>
      <CenterAlignedTableCell>
        <HiddenIcon name={'arrow-right-bold'} color={'#8c8c8c'} size={16} />
      </CenterAlignedTableCell>
    </StyledRow>
  );
};

export default NotificationRow;
