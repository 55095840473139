import React, { Component, FormEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Navigate, NavigateProps } from 'react-router';
import LoginButton from './components/LoginButton';
import styled from 'styled-components';
import { RootReducerInterface } from '../../model/redux/interfaces';
import Loader from '@dev/base-web/dist/view/components/global/loader';
import AuthenticationThunks from '../../model/redux/authentication/thunks';
import { ErrorInterface } from '../../model/api/error/ErrorInterface';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import Icons from '../../resources/f2019/Icons.svg';
import { useToastrForError } from '@dev/base-web/dist/view/helpers/notification_helpers';
import {
  LocationProps,
  withLocation,
} from '@dev/base-web/dist/view/helpers/navigation_hoc';

const StyledBackground = styled.div`
  background: linear-gradient(to bottom, #61c6cc, #0da6a0);
  height: 100vh;
  width: 100%;
`;

const StyledMainGrid = styled.div`
  max-width: 346px;
  height: auto;
  position: relative;
  margin: 0 auto;
  vertical-align: middle;
  top: 25%;
`;

const StyledInnerGrid = styled.div`
  position: relative;
  top: 25%;
  bottom: 25%;
`;

const StyledHeader = styled.h1`
  font-family: Relative;
  font-size: 27px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-bottom: 48px;
  margin: 0;
`;

const StyledInputTitle = styled.div`
  font-family: Relative;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  margin-bottom: 8px;
  color: white;
  text-align: left;
`;

const StyledPasswordHolder = styled.div`
  margin-top: 16px;
`;

const StyledInputField = styled.input`
  width: 100%;
  height: 46px;
  border-radius: 4px;
  border: solid 2px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  resize: none;

  &:hover {
    border: solid 2px rgba(0, 0, 0, 0.4);
  }
  &:active {
    border: solid 2px #0da6a0;
  }
  &:invalid {
    border: solid 2px #e30021;
  }
  &:focus {
    border: solid 2px #0da6a0;
  }
  &:disabled {
    border: solid 2px rgba(0, 0, 0, 0.1);
    background-color: #f0f0f0;
  }
`;

interface LoginDispatchPropsInterface {
  readonly authenticate: (username: string, password: string) => void;
}

interface LoginStoreProsInterface {
  readonly loginInProgress: boolean;
  readonly loginError?: ErrorInterface;
  readonly notLoggedIn: boolean;
}

interface ErrorToastrWrapperProps {
  readonly error?: ErrorInterface;
}

interface LoginStateInterface {
  username: string;
  password: string;
}

type LoginPropsInterface = LoginDispatchPropsInterface &
  LoginStoreProsInterface &
  LocationProps;

const ErrorToastrWrapper: React.FC<ErrorToastrWrapperProps> = ({ error }) => {
  useToastrForError(error);

  return null;
};

class Login extends Component<LoginPropsInterface, LoginStateInterface> {
  state: Readonly<LoginStateInterface> = {
    username: '',
    password: '',
  };

  public static ROUTE_ID = '/login';

  authenticate = () => {
    const { username, password } = this.state;
    this.props.authenticate(username, password);
  };

  onUsernameChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ username: e.currentTarget.value });
  };

  onPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ password: e.currentTarget.value });
  };

  onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.authenticate();
  };

  renderLoginScreen() {
    const { loginInProgress, loginError } = this.props;
    const { username, password } = this.state;
    const loginEnabled = username.length > 0 && password.length > 0;

    return (
      <StyledBackground>
        <StyledMainGrid>
          <StyledInnerGrid>
            <StyledHeader>
              <Icon
                name={'plus-10-logo'}
                color={'#ffffff'}
                size={299}
                width={299}
                height={34}
                iconsReference={Icons}
              />
            </StyledHeader>

            <form onSubmit={this.onFormSubmit}>
              <StyledInputTitle>
                <FormattedMessage id="login_username" />
              </StyledInputTitle>
              <StyledInputField onChange={this.onUsernameChange} />

              <StyledPasswordHolder>
                <StyledInputTitle>
                  <FormattedMessage id="login_password" />
                </StyledInputTitle>
                <StyledInputField
                  type="password"
                  onChange={this.onPasswordChange}
                />
              </StyledPasswordHolder>

              <LoginButton disabled={!loginEnabled} />
            </form>
          </StyledInnerGrid>
        </StyledMainGrid>
        {loginInProgress ? <Loader /> : null}
        <ErrorToastrWrapper error={loginError} />
      </StyledBackground>
    );
  }

  render() {
    const { notLoggedIn, location } = this.props;

    const routeState = location.state as { from: Location };
    const redirectAfterLogin: NavigateProps['to'] = {
      pathname: routeState?.from?.pathname ?? '/',
      search: routeState?.from?.search,
    };

    return (
      <div>
        {notLoggedIn ? (
          this.renderLoginScreen()
        ) : (
          <Navigate to={redirectAfterLogin} replace />
        )}
      </div>
    );
  }
}

const mapStateToProps = (
  state: RootReducerInterface
): LoginStoreProsInterface => {
  return {
    loginInProgress:
      state.authenticationState.authenticationMeta.loginInProgress,
    loginError: state.authenticationState.authenticationMeta.error,
    notLoggedIn:
      state.authenticationState.authentication.token.accessToken === '',
  };
};

export default connect(mapStateToProps, {
  authenticate: AuthenticationThunks.authenticate,
})(withLocation(Login));
