import {
  Column,
  ColumnFilterOption,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import {
  UserFilterKey,
  UserSortingKey,
} from '@dev/base-web/dist/model/domain/user/user_details';
import UserStatus from '@dev/base-web/dist/model/domain/user/user_status';

const statusOptions = Object.values(UserStatus).map((s) => {
  return { textId: s.toLowerCase(), value: s };
});

// widths here should add up to 14, +2 comes from the checkbox and the filter icons columns
// which all adds up to 16
export const createColumns = (
  manufacturingEntities: ManufacturingEntity[],
  getAllManufacturingEntities: (query?: string) => void,
  manufacturingEntityLoading: boolean,
  roleOptions: ColumnFilterOption[]
): Array<Column> => [
  {
    title: 'name',
    isLeftAligned: true,
    sortKey: 'username',
    filterKey: 'global',
    filterType: ColumnFilterType.TEXT,
    width: 3,
  },
  {
    title: 'role',
    isLeftAligned: true,
    sortKey: UserSortingKey.ROLE,
    filterType: ColumnFilterType.SELECTOR,
    filterOptions: roleOptions,
    width: 3,
  },
  {
    title: 'status',
    isLeftAligned: true,
    sortKey: UserSortingKey.STATUS,
    filterType: ColumnFilterType.SELECTOR,
    filterOptions: statusOptions,
    width: 3,
  },
  {
    title: 'responsibility',
    isLeftAligned: true,
    sortKey: UserSortingKey.MANUFACTURING_ENTITY,
    filterType: ColumnFilterType.MANUFACTURING_ENTITY,
    filterKey: UserFilterKey.MANUFACTURING_ENTITY,
    filterOptions: manufacturingEntities,
    width: 5,
    getAllManufacturingEntities,
    isLoading: manufacturingEntityLoading,
  } as any, //TableHeaderManufacturingEntityFilterColumn
  {
    title: 'col_created_time',
    isLeftAligned: true,
    sortKey: UserSortingKey.REGISTRATION_DATE,
    filterType: ColumnFilterType.TEXT,
    width: 3,
  },
  {
    title: 'last_login',
    isLeftAligned: true,
    sortKey: UserSortingKey.LAST_LOGIN_DATE,
    filterType: ColumnFilterType.TEXT,
    width: 2,
  },
];
