import { connect } from 'react-redux';
import { RootReducerInterface } from '@/model/redux/interfaces.ts';
import UserAdmin from './view';
import { UserAdminStoreProps } from './interface';
import { injectIntl } from 'react-intl';
import UserThunks from '../../model/redux/user/thunks';
import UserRoleThunks from '../../model/redux/user_role/thunks';
import ManufacturingEntityThunks from '../../model/redux/manufacturing_entity/thunks';
import { withNavigation } from '@dev/base-web/dist/view/helpers/navigation_hoc';

const mapStateToProps = (state: RootReducerInterface): UserAdminStoreProps => {
  return {
    error: state.userState.users.meta.error,
    users: state.userState.users.data.results,
    hasMoreResults: state.userState.users.data.hasMoreResults,
    loadingInProgress: state.userState.users.meta.loadingInProgress,
    updateUsers: state.userState.updateUsers.meta,
    manufacturingEntities:
      state.data.manufacturingEntityState.manufacturingEntities.data,
    manufacturingEntitiesMeta:
      state.data.manufacturingEntityState.manufacturingEntities.meta,
    lastUserSearchData: state.userState.users.meta.previousSearchData,
    userPrivileges: state.userRoleState.roles.data.results,
    userPrivilegesLoading: state.userRoleState.roles.meta.loadingInProgress,
    hasMoreUserPrivileges: state.userRoleState.roles.data.hasMoreResults,
    roleOperation: state.userRoleState.roleOperation.meta,
    roleNames: state.userRoleState.roleNames.data,
    currentUser: state.userState.currentUser.data,
    notRegisteredUsers: state.userState.notRegisteredUsers.data,
    notRegisteredUsersMeta: state.userState.notRegisteredUsers.meta,
    inviteUserMeta: state.userState.inviteUser.meta,
    user: state.userState.user.data,
    isSaaS: state.configurationState.config.data?.isSaaS?.value,
    usersTotalElements: state.userState.users.data.totalElements,
  };
};

export default connect(mapStateToProps, {
  getUsers: UserThunks.getUsers,
  deleteUsers: UserThunks.deleteUsers,
  resetUser: UserThunks.resetUser,
  getAllManufacturingEntities:
    ManufacturingEntityThunks.getAllManufacturingEntities,
  getUserPrivileges: UserRoleThunks.getPrivileges,
  createRole: UserRoleThunks.createRole,
  deleteRole: UserRoleThunks.deleteRole,
  getAllRoleNames: UserRoleThunks.getAllRoleNames,
  updatePrivileges: UserRoleThunks.updatePrivileges,
  getNotRegisteredUsers: UserThunks.getNotRegisteredUsers,
  inviteUser: UserThunks.inviteUser,
})(injectIntl(withNavigation(UserAdmin)));
