import React from 'react';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@dev/base-web/dist/view/components/global/tooltip';
import { P } from './styled_components';
import styled, { useTheme } from 'styled-components';

const TerminatedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TerminatedMarker: React.FC<{
  tooltip?: boolean;
  disabled?: boolean;
}> = ({ tooltip = false, disabled = false }) => {
  const theme = useTheme();

  return (
    <TerminatedContainer>
      <Icon
        name="warning"
        size={16}
        color={theme.colors.pumpkin}
        iconStyle={{ marginRight: 4 }}
      />
      <P disabled={disabled}>
        <FormattedMessage id="terminated" />
      </P>
      {tooltip && (
        <Tooltip
          titleLabel="terminated"
          titleIcon="info"
          contentLabel="event_terminated_hint"
          position="right"
        />
      )}
    </TerminatedContainer>
  );
};
