import { RootReducerInterface } from '../../../../model/redux/interfaces';
import { connect } from 'react-redux';
import { EventSubscriberConfigStoreProps } from './interfaces';
import {
  subscribeToEvent,
  unsubscribeFromEvent,
} from '../../../../model/redux/event_definition/thunks';
import EventSubscriberConfig from './view';

const mapStateToProps = (
  state: RootReducerInterface
): EventSubscriberConfigStoreProps => {
  return {
    subscribeOperationStatus:
      state.data.eventDefinitionState.subscribeToEvent.meta,
    unsubscribeOperationStatus:
      state.data.eventDefinitionState.unsubscribeFromEvent.meta,
    selfUser: state.userState.currentUser.data,
  };
};

export default connect(mapStateToProps, {
  subscribeToEvent,
  unsubscribeFromEvent,
})(EventSubscriberConfig);
