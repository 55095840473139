import createDowntimesReportThunks, {
  createDowntimeReasonsThunks,
} from '@dev/base-web/dist/model/redux/report/downtime/thunk_factory';
import { reportDowntimeApi, reportDowntimeReasonApi } from '../../../api';
import overviewReportsThunk from '../overview/thunks';

export const downtimeReasonsThunks = createDowntimeReasonsThunks(
  reportDowntimeReasonApi
);

export const downtimesReportThunks = createDowntimesReportThunks(
  reportDowntimeApi,
  overviewReportsThunk
);
