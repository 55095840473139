import {
  millisecondsToHours,
  millisecondsToMinutes,
  millisecondsToSeconds,
} from 'date-fns';

export const formatTickForDuration = (
  tickValue: number,
  maxDuration: number
) => {
  let formattedDuration = '0:00:00';
  if (tickValue) {
    const durationInSeconds = Math.round(tickValue * maxDuration);
    const duration = millisToDurationFormat(durationInSeconds * 1000);
    formattedDuration = duration;
  }
  return formattedDuration;
};

const millisToDurationFormat = (value: number) => {
  if (value) {
    const zeroPad = (num: number) => String(num).padStart(2, '0');
    const hours = millisecondsToHours(value);
    const minutes = millisecondsToMinutes(value) - 60 * hours;
    const seconds =
      millisecondsToSeconds(value) - (60 * 60 * hours + 60 * minutes);

    return `${hours}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
  } else return '-';
};
