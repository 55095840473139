import { RootReducerInterface } from '@/model/redux/interfaces.ts';
import { GlossaryModalStoreProps } from './interface';
import { connect } from 'react-redux';
import GlossaryModal from './view';
import {
  getGlossaryEntries,
  updateGlossaryEntries,
} from '@/model/redux/translation/thunks.ts';

const mapStateToProps = (
  state: RootReducerInterface
): GlossaryModalStoreProps => {
  return {
    glossary: state.data.translationState.glossary.data,
    glossaryMeta: state.data.translationState.glossary.meta,
    updateGlossaryEntriesMeta: state.data.translationState.createGlossary.meta,
    currentUser: state.userState.currentUser.data,
  };
};

export default connect(mapStateToProps, {
  getGlossaryEntries: getGlossaryEntries,
  updateGlossaryEntries: updateGlossaryEntries,
})(GlossaryModal);
