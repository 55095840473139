import Icon from '@dev/base-web/dist/view/components/global/icon';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import { P } from './styled_components';
import { TerminatedMarker } from './terminated_marker';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import { EventStatusType } from '@dev/base-web/dist/model/domain/event/event_type';
import { UserTag } from '@/screens/event_definition/components/UserTag.tsx';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';

const Blacky = styled.div`
  font-family: Relative;
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  margin-top: 40px;
  width: 80px;
`;

const EventTimeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const EventElementsContainer = styled.div`
  padding-left: 10px;
`;

const Info = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-family: Relative;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #8c8c8c;
  margin: 0;
  padding: 0;
  margin-top: 24px;
  margin-bottom: 8px;
  vertical-align: middle;
`;

const StyledUserTag = styled(UserTag)`
  background-color: ${({ theme }) => theme.colors.background};
`;

interface EventElementsProps {
  readonly eventId: string;
  readonly locations: any;
  readonly event: any;
  readonly initial?: boolean;
  readonly manufacturingEntity?: ManufacturingEntity;
}

const EventElements = ({
  eventId,
  locations,
  event,
  initial,
  manufacturingEntity,
}: EventElementsProps) => {
  const { formatDate } = useLocalizedDateFormatters();
  const status = event.status;

  const formatTimestamp = (timestamp: number) => formatDate(timestamp, 'P pp');

  return (
    <EventElementsContainer>
      <div>
        <Info>
          <Icon
            name={'location'}
            color={'#939393'}
            size={16}
            iconStyle={{ marginRight: 4 }}
          />
          <FormattedMessage id="location" />
        </Info>
        {eventId !== '' || manufacturingEntity ? (
          <ManufacturingEntityHierarchyElement
            manufacturingEntity={
              manufacturingEntity || event.manufacturingEntity
            }
            lines={3}
          />
        ) : (
          <P>{locations ? locations : '-'}</P>
        )}
        <EventTimeContainer>
          <div style={{ marginTop: 0, marginBottom: 0 }}>
            <Info>
              <Icon
                name={'time'}
                color={'#939393'}
                size={16}
                iconStyle={{ marginRight: 4 }}
              />
              <FormattedMessage id="date" />
            </Info>

            <P>{formatTimestamp(event.timestamp)}</P>
          </div>
          {(status === EventStatusType.CLOSED ||
            status === EventStatusType.TERMINATED) && (
            <>
              <Blacky>-</Blacky>
              <div style={{ marginTop: 0, marginBottom: 0 }}>
                <Info>
                  <FormattedMessage id="solved_at" />
                </Info>
                {status === 'CLOSED' ||
                (event.closedBy && event.closedBy.id) ? (
                  <P>{formatTimestamp(event.endTimestamp)}</P>
                ) : (
                  <TerminatedMarker tooltip />
                )}
              </div>
            </>
          )}
        </EventTimeContainer>
        {status === EventStatusType.TERMINATED &&
          event.closedBy &&
          event.closedBy.id && (
            <>
              <Info>
                <FormattedMessage id="closed_by" />
              </Info>
              <StyledUserTag user={event.closedBy} />
            </>
          )}
        <Info>
          <FormattedMessage id="description" />
        </Info>
        {event.description !== null && event.description.length !== 0 ? (
          <P>{event.description}</P>
        ) : initial ? (
          <P>-</P>
        ) : (
          <P>
            <FormattedMessage id="no_description_info" />
          </P>
        )}
      </div>
    </EventElementsContainer>
  );
};

export default EventElements;
