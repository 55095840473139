import { Popup } from 'semantic-ui-react';
import { VictoryLabelProps } from 'victory';
import styled from 'styled-components';
import React, { useCallback } from 'react';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { FormattedMessage } from 'react-intl';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import AggregatedDowntime, {
  DowntimeMetricTypes,
} from '../../../../../model/domain/downtime/AggregatedDowntime';
import {
  openInNewTab,
  useCallbackToOpenTabWithPathPrefix,
} from '@dev/base-web/dist/view/helpers/common';
import { getUnit } from '@dev/base-web/dist/view/components/global/common';
import ManufacturingEntity, {
  ManufacturingEntityType,
} from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';
import DarwinConfig, {
  DARWIN_BASE_URL,
} from '@dev/base-web/dist/model/domain/authorization/darwin';
import { TimeSpan } from '@dev/base-web/dist/model/domain/common/time_span';
import { formatDuration } from '@dev/base-web/dist/view/helpers/date_helpers';
import {
  addTimeSpanParam,
  TimeSpanSearchStateOptions,
} from '@dev/base-web/dist/view/components/global/url_param_hooks';
import { SHANNON_BASE_URL } from '@dev/base-web/dist/model/domain/authorization/shannon';
import { useBaseUrlWithPathPrefix } from '@dev/base-web/dist/view/helpers/use_path_prefix';
import { EventData } from '@/model/domain/event/event_definition.ts';

const timespanFieldOptions: TimeSpanSearchStateOptions = {
  startKey: 'start',
  endKey: 'end',
  mode: 'time',
};

export const ROUTE_TEMPLATE = (
  manufacturingEntityId: string,
  eventDefinitionId: string,
  considerPlannedStops: boolean,
  timeSpan?: TimeSpan
) => {
  const queryParams: string[] = [];
  if (timeSpan) {
    const search = new URLSearchParams();
    addTimeSpanParam(timeSpan, search, timespanFieldOptions);
    queryParams.push(
      `${search.toString()}&excludePlannedStops=${considerPlannedStops}`
    );
  }
  return `${
    DarwinConfig.Downtimes.url.path
  }/${manufacturingEntityId}/${eventDefinitionId}?${queryParams.join('&')}`;
};

const StyledPopup = styled(Popup)`
  &&&&& {
    :before {
      background-color: #4b4b4b;
      box-shadow: none;
    }

    word-break: break-word;

    border: none;
    background-color: #4b4b4b;

    padding: 8px;

    color: white;
    font-family: Relative;
    font-size: 14px;
  }
`;

const EventTitle = styled.div`
  font-weight: bold;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EventInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
  height: 16px;
`;

const EventButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  &&& > * {
    margin-right: 10px !important;
  }
`;

const EventLocation = styled(EventInfo)`
  flex-direction: row;
  height: fit-content;

  svg {
    margin-right: 4px;
  }

  div {
    flex: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ForeignObjectSafariFix = styled.div`
  width: 100%;
  height: 100%;
`;

const formatNumberValue = (valueKey: string, value: number) => {
  switch (valueKey) {
    case DowntimeMetricTypes.SUM:
      return formatDuration(value, 'HH:mm:ss');
    case DowntimeMetricTypes.RATIO:
      return `${Math.round(value * 100 * 10) / 10}%`;
    default:
      return (Math.round(value * 10000) / 10000).toString();
  }
};

const useCallbackForOpeningDetails = (
  event: EventData,
  manufacturingEntity: ManufacturingEntity,
  timespan: TimeSpan | undefined,
  considerPlannedStops: boolean
) => {
  const eventId = event.eventDefinitionId;
  const mId = manufacturingEntity.id;
  const baseUrlWithPrefixes = useBaseUrlWithPathPrefix(DARWIN_BASE_URL);

  const href = `${baseUrlWithPrefixes}${ROUTE_TEMPLATE(
    mId,
    eventId,
    considerPlannedStops,
    timespan
  )}`;

  return useCallback(() => {
    openInNewTab(href);
  }, [event, manufacturingEntity, timespan, considerPlannedStops, href]);
};

interface AggregatedDowntimesChartLabelProps {
  readonly valueKey: string;
  readonly barWidth: number;
  readonly height: number;
  readonly bottomPadding: number;
  readonly color?: string;
  readonly datum?: { downtime: AggregatedDowntime; [k: string]: any };
  readonly showManufacturingEntityHierarchy: boolean;
  readonly timespan?: TimeSpan;
  readonly considerPlannedStops: boolean;
}

type Props = Omit<VictoryLabelProps, 'datum'> &
  AggregatedDowntimesChartLabelProps;

export const AggregatedDowntimesChartLabel: React.FC<Props> = ({
  valueKey,
  barWidth,
  x,
  y,
  datum,
  height,
  bottomPadding,
  color,
  showManufacturingEntityHierarchy,
  timespan,
  considerPlannedStops,
}) => {
  if (!datum || !datum.downtime) return <></>;

  const { event, manufacturingEntity, count, sumMs } = datum.downtime;

  const definitionsOnClick = useCallbackToOpenTabWithPathPrefix(
    SHANNON_BASE_URL,
    `/events/${event.eventDefinitionId}`,
    [event]
  );

  const addSolutionOnClick = useCallbackToOpenTabWithPathPrefix(
    SHANNON_BASE_URL,
    `/actions/new?eventId=${event.eventDefinitionId}`,
    [event]
  );

  const detailsOnClick = useCallbackForOpeningDetails(
    event,
    manufacturingEntity,
    timespan,
    considerPlannedStops
  );

  return (
    <StyledPopup
      closeOnTriggerClick={false}
      closeOnPortalMouseLeave
      trigger={
        <foreignObject
          x={(x ?? 0) - barWidth / 2}
          y={y}
          height={height - bottomPadding - (y ?? 0)}
          width={barWidth}
          style={{
            backgroundColor: color,
          }}
        >
          <ForeignObjectSafariFix />
        </foreignObject>
      }
      position={'top center'}
    >
      <EventTitle>{event.name}</EventTitle>
      <EventLocation>
        <Icon name={'location-filled'} size={16} />
        <ManufacturingEntityHierarchyElement
          manufacturingEntity={manufacturingEntity}
          hideHiddenTypes
          hideTooltip
          showShortNames
          hiddenTypes={[
            ManufacturingEntityType.FACTORY,
            ManufacturingEntityType.AREA,
            ManufacturingEntityType.LINE,
          ]}
          showHierarchy={showManufacturingEntityHierarchy}
          lines={3}
        />
      </EventLocation>
      <EventInfo>
        <FormattedMessage id={'sum'} />:{' '}
        {formatNumberValue(valueKey, sumMs) + getUnit(valueKey)}
      </EventInfo>
      <EventInfo>
        <FormattedMessage id={'count'} />: {count}
      </EventInfo>
      <EventInfo>
        <FormattedMessage
          id={'available_actions'}
          values={{
            solutionCount: event.solutionCount,
          }}
        />
      </EventInfo>
      <EventButtons>
        <Button
          type={'secondary'}
          size={'small'}
          icon={'bars'}
          tooltipLabel={'details'}
          tooltipDelay={500}
          onClick={detailsOnClick}
        />
        <Button
          type={'secondary'}
          size={'small'}
          icon={'edit'}
          tooltipLabel={'edit_event'}
          tooltipDelay={500}
          onClick={definitionsOnClick}
        />
        {event.solutionCount === 0 && (
          <Button
            type={'secondary'}
            size={'small'}
            icon={'plus'}
            tooltipLabel={'add_solution'}
            tooltipDelay={500}
            onClick={addSolutionOnClick}
          />
        )}
      </EventButtons>
    </StyledPopup>
  );
};
