import React, { useEffect, useState } from 'react';
import { Crop } from 'react-image-crop';
import DrawOnImageView from './draw_on_image_view';
import styled from 'styled-components';
import ImageCropperView from './image_cropper_view';
import ImageRotateView from './image_rotate_view';
import DefaultImageView from './defaut_image_view';
import FunctionButtons from './function_buttons';
import { EditMode } from './interface';
import { useAuthorizationCheckRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';

const RelativeContainer = styled.div`
  position: relative;
`;

const EditImage: React.FC<{
  thumbnailContent?: string;
  fullContent?: string;
  isLoading?: boolean;
  hasError?: boolean;
  fileName?: string;
  onDelete: () => void;
  onRevert?: (asNew: boolean) => void;
  onChange: (picture: File) => void;
  forceCropAspect?: number;
  forceCrop?: Crop;
}> = ({
  thumbnailContent,
  fullContent,
  isLoading,
  hasError,
  fileName,
  onDelete,
  onChange,
  onRevert,
  forceCropAspect,
  forceCrop,
}) => {
  const [mode, setMode] = useState<EditMode | undefined>(
    forceCrop ? EditMode.CROP : undefined
  );
  const [editedContent, setEditedContent] = useState<string | undefined>();
  const [loadedImage, setLoadedImage] = useState<HTMLImageElement>();

  const { write: canEdit, delete: canDelete } = useAuthorizationCheckRWD(
    UserPrivilegePermission.ACTION,
    ApplicationCategory.SHANNON
  );

  useEffect(() => {
    setEditedContent(undefined);

    if (forceCrop) {
      setMode(EditMode.CROP);
    }
  }, [fullContent, forceCrop]);

  const onImageLoaded = (image: HTMLImageElement) => {
    setLoadedImage(image);
  };

  const resetMode = () => {
    setMode(forceCrop ? EditMode.CROP : undefined);
  };

  const onCanvasChanged = async (canvas: HTMLCanvasElement) => {
    const file = await new Promise<File>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }

        resolve(
          new File([blob], fileName || 'fileName.png', {
            type: 'image/png',
            lastModified: new Date().valueOf(),
          })
        );
      }, 'image/png');
    });
    setEditedContent(canvas.toDataURL());
    onChange(file);
    resetMode();
  };

  const rotate = () => {
    if (!loadedImage || !loadedImage.complete) {
      return;
    }

    setMode(EditMode.ROTATE);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.style.width = '100%';

    canvas.width = loadedImage.naturalHeight;
    canvas.height = loadedImage.naturalWidth;

    ctx && ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx &&
      ctx.translate(
        loadedImage.naturalHeight / 2,
        loadedImage.naturalWidth / 2
      );
    ctx && ctx.rotate(-Math.PI / 2);
    ctx &&
      ctx.drawImage(
        loadedImage,
        -loadedImage.naturalWidth / 2,
        -loadedImage.naturalHeight / 2
      );
    ctx && ctx.restore();

    setEditedContent(canvas.toDataURL());
  };

  const cancelRotation = () => {
    setEditedContent(undefined);
    resetMode();
  };

  const functionButtons = (
    <FunctionButtons
      mode={mode}
      disabled={isLoading}
      onCropPressed={() => setMode(EditMode.CROP)}
      onDeletePressed={() => onDelete()}
      onDrawPressed={() => setMode(EditMode.DRAW)}
      onRotatePressed={rotate}
      onRevertPressed={onRevert}
      canEdit={canEdit}
      canDelete={canDelete}
    />
  );

  return (
    <RelativeContainer>
      {mode === undefined && (
        <DefaultImageView
          fullContent={(editedContent || fullContent) as string}
          onImageRendered={onImageLoaded}
          thumbnailContent={thumbnailContent}
          isLoading={isLoading}
          hasError={hasError}
        >
          {functionButtons}
        </DefaultImageView>
      )}
      {mode === EditMode.CROP &&
        (editedContent !== undefined || fullContent !== undefined) && (
          <ImageCropperView
            src={(editedContent || fullContent) as string}
            setLoadedImage={onImageLoaded}
            loadedImage={loadedImage}
            cropActive={true}
            onCropApplied={onCanvasChanged}
            onCropCancelled={resetMode}
            forceCropAspect={forceCropAspect}
            forceCrop={forceCrop}
          >
            {functionButtons}
          </ImageCropperView>
        )}
      {mode === EditMode.DRAW && (
        <DrawOnImageView
          src={(editedContent || fullContent) as string}
          loadedImage={loadedImage}
          onDrawCancelled={resetMode}
          onDrawApplied={onCanvasChanged}
        >
          {functionButtons}
        </DrawOnImageView>
      )}
      {mode === EditMode.ROTATE && (
        <ImageRotateView
          fullContent={editedContent || fullContent}
          thumbnailContent={thumbnailContent}
          isLoading={isLoading}
          hasError={hasError}
          onRotateApplied={onCanvasChanged}
          onRotateCancelled={cancelRotation}
          loadedImage={loadedImage}
          onImageRendered={onImageLoaded}
        >
          {functionButtons}
        </ImageRotateView>
      )}
    </RelativeContainer>
  );
};

export default EditImage;
