import { RootReducerInterface } from '../../../../model/redux/interfaces';
import { connect } from 'react-redux';
import UserThunks from '../../../../model/redux/user/thunks';
import UpdatePasswordModal from './view';
import { UpdatePasswordStoreProps } from './interface';

const mapStateToProps = (
  state: RootReducerInterface
): UpdatePasswordStoreProps => {
  return {
    updateUserPasswordOperation: state.userState.updateUserPassword.meta,
  };
};

export default connect(mapStateToProps, {
  updateUserPassword: UserThunks.updateUserPassword,
})(UpdatePasswordModal);
