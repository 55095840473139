import React, { useState } from 'react';
import styled from 'styled-components';
import { StepNumber } from '../StepNumber';
import Media from '../Media';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  CenteredButtons,
} from '@dev/base-web/dist/view/components/global/button';
import { DecisionBtnRow } from './components';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import { SolutionFeedbackComponent } from '../SolutionFeedback';
import {
  ActionAccessLevel,
  Instruction,
} from '@/model/domain/instruction/instruction.ts';
import {
  DisplayedDecision,
  DisplayedStep,
  SelectedDecision,
  DecisionOptionDTO,
} from '@/model/domain/instruction/step.ts';
import { useNavigate } from 'react-router';
import { LightboxWrapper } from '../lightbox_wrapper';
import ToggleSwitch from '@dev/base-web/dist/view/components/inputs/toggle_switch';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';
import LabelsWithEllipsis from '@dev/base-web/dist/view/components/global/labels_with_ellipsis';
import { LoadingMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import EmptyOrLoadingChartView from '@dev/base-web/dist/view/components/global/no_data';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { getTranslation } from '@/model/domain/instruction/helper.ts';
import PdfViewer from '../../screens/edit_instruction/components/media/pdf_viewer';
import AdditionalSolutionInfo from '../../screens/edit_instruction/components/additional_solution_info';
import {
  changeSelectedDecisions,
  toDisplayedUntilDecision,
} from '@/model/domain/instruction/step_helper';

const P = styled.p`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #242424;
  margin: 0;
  padding: 0;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 18px;
`;

const ListElement = styled.li`
  //height: 16px;
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #242424;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BulletStepNumber = styled(StepNumber)`
  margin-left: 0px;
  margin-right: 10px;
  min-width: 20px;
`;

const CloseIconContainer = styled.div`
  cursor: pointer;
`;

const EditButtonContainer = styled(CenteredButtons)`
  padding-top: 24px;
`;

export const StyledMedia = styled(Media)`
  margin: 15px 0px;
  height: 400px;
  width: 100%;
  align-self: center;

  @media screen and (${({ theme }) => theme.breakpoints.isTablet}) {
    height: 270px;
  }
`;

const DivToggle = styled.div`
  margin-left: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #8c8c8c;
  margin-right: 8px;
`;

export const LabelsPopupLarge = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  width: 95%;
  z-index: 10;
  border-radius: 10px;
  margin-top: -40px;
  z-index: 10;
`;

const StyledToggleSwitch = styled(ToggleSwitch)`
  margin-top: 8px;
`;

const StyledLabelWithHint = styled(LabelWithHint)`
  margin-right: 8px;
`;

interface Props {
  item: Instruction | null;
  closeAction?: () => void;
  feedbackAction?: (
    isPositiveVote: boolean,
    feedbackText: string
  ) => Promise<void>;
  readonly canEdit?: boolean;
  readonly accessLevel?: ActionAccessLevel;
  readonly isHidden?: boolean;
  readonly meta?: LoadingMetaState;
  readonly selectedLanguage?: string;
}

const Step = ({
  step,
  index,
  onSelect,
  selectedLanguage,
  fallbackLanguage,
}: {
  readonly step: DisplayedStep;
  readonly index: number;
  readonly onSelect: (s: DisplayedStep, d: DisplayedDecision) => void;
  readonly selectedLanguage?: string;
  readonly fallbackLanguage?: string;
}) => {
  const description = getTranslation(
    step.orig.translations,
    undefined,
    selectedLanguage,
    fallbackLanguage
  );

  return (
    <div style={{ marginBottom: '24px' }}>
      <ListElement>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
        >
          <BulletStepNumber className={'Wrapper'}>{index + 1}</BulletStepNumber>
          <span style={{ whiteSpace: 'pre-wrap' }}>{description}</span>
        </div>
      </ListElement>

      {step.orig.media &&
        (step.orig.media.fileType !== 'PDF' ? (
          <LightboxWrapper canOpenLightbox media={step.orig.media}>
            <StyledMedia showErrorHint={true} value={step.orig.media} />
          </LightboxWrapper>
        ) : (
          <PdfViewer scale={1} urls={step.orig.media} />
        ))}

      <AdditionalSolutionInfo infos={step.orig.additionalInfos} />

      <DecisionBtnRow value={step} onSelect={onSelect} />
    </div>
  );
};

export const InstructionCard: React.FC<Props> = ({
  closeAction,
  feedbackAction,
  item,
  canEdit,
  accessLevel,
  isHidden,
  meta,
  selectedLanguage,
}) => {
  const navigate = useNavigate();

  const [focusedDecision, setFocusedDecision] = useState<DecisionOptionDTO>();
  const [selectedDecisionIds, setSelectedDecisionIds] = useState<
    readonly SelectedDecision[]
  >([]);

  const onSelect = (s: DisplayedStep, d: DisplayedDecision) => {
    if (d.orig.steps.length === 0 && s.orig.id && d.orig.id) {
      const changedSelectedDecisionIds = changeSelectedDecisions(
        selectedDecisionIds,
        {
          stepNumber: s.orig.step,
          stepId: s.orig.id,
          decisionId: d.orig.id,
        }
      );
      setFocusedDecision(d.orig);
      setSelectedDecisionIds(changedSelectedDecisionIds);
    } else {
      if (focusedDecision === d.orig) {
        setFocusedDecision(undefined);
      } else {
        setFocusedDecision(d.orig);
      }
      if (s.orig.id && d.orig.id) {
        const changedSelectedDecisionIds = changeSelectedDecisions(
          selectedDecisionIds,
          {
            stepNumber: s.orig.step,
            stepId: s.orig.id,
            decisionId: d.orig.id,
          }
        );
        setSelectedDecisionIds(changedSelectedDecisionIds);
      }
    }
  };

  const stepsTopLevel = item?.steps || [];
  const displayedSteps = toDisplayedUntilDecision(
    stepsTopLevel,
    selectedDecisionIds
  );

  if (meta?.loadingInProgress || meta?.error) {
    return (
      <Card>
        <EmptyOrLoadingChartView
          loadingInProgress={meta?.loadingInProgress}
          hasError={!!meta.error}
          hasResults={!!item}
        />
      </Card>
    );
  }

  const fallbackLanguage =
    item?.steps.length && item?.steps[0].translations.length
      ? item?.steps[0].translations[0].language
      : undefined;

  return (
    <Card
      titleId={item?.name}
      headerContent={
        <>
          {closeAction && (
            <CloseIconContainer onClick={closeAction}>
              <Icon name="cross" size={14} color={'black'} />
            </CloseIconContainer>
          )}
          {accessLevel !== undefined && (
            <>
              <StyledLabelWithHint
                label={'access_level'}
                hint={'solution_level_hint'}
              />
              <StyledDropDown
                text={<FormattedMessage id={accessLevel.toLowerCase()} />}
                selection
                disabled={true}
              />
            </>
          )}
          {isHidden !== undefined && (
            <DivToggle>
              <Label>
                {!isHidden ? (
                  <FormattedMessage id="action_active" />
                ) : (
                  <FormattedMessage id="action_inactive" />
                )}
              </Label>
              <StyledToggleSwitch
                disabled={true}
                checked={!isHidden}
                onChange={() => {}}
              />
            </DivToggle>
          )}
        </>
      }
    >
      <>
        <LabelsWithEllipsis labels={item!.labels} />
      </>
      <List>
        {displayedSteps && displayedSteps.length > 0 ? (
          displayedSteps.map((e, index) => (
            <Step
              key={index}
              step={e}
              index={index}
              onSelect={onSelect}
              selectedLanguage={selectedLanguage}
              fallbackLanguage={fallbackLanguage}
            />
          ))
        ) : (
          <P>{<FormattedMessage id="no_actions_info" />}</P>
        )}
      </List>
      {feedbackAction && item && (
        <SolutionFeedbackComponent
          isPositiveVote={item.isPositiveVote}
          feedbackText={item.feedbackText}
          onSubmit={feedbackAction}
        />
      )}
      {canEdit && item && item.id && (
        <EditButtonContainer>
          <Button
            type="tertiary"
            icon="edit"
            label="edit_solution"
            onClick={() => navigate('/actions/' + item.id)}
          />
        </EditButtonContainer>
      )}
    </Card>
  );
};
