import Icon from '@dev/base-web/dist/view/components/global/icon';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import React from 'react';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import HintContent from './hint_content';

const StyledTriggerIcon = styled(Icon)`
  margin-top: 8px;
  margin-left: 4px;
  cursor: pointer;
`;

const style = {
  borderRadius: 12,
  backgroundColor: '#4b4b4b',
  padding: 16,
};

const GlossaryHintTooltip: React.FC = () => {
  const theme = useTheme();

  return (
    <Popup
      trigger={
        <div>
          <StyledTriggerIcon
            name={'info'}
            color={theme.colors.brownGrey}
            size={18}
          />
        </div>
      }
      inverted
      hoverable
      mouseEnterDelay={300}
      style={style}
      position={'top left'}
      offset={[10, 20]}
    >
      <HintContent theme={theme} />
    </Popup>
  );
};

export default GlossaryHintTooltip;
