import {
  CardColumn,
  CardRow,
} from '@dev/base-web/dist/view/components/global/card';
import styled from 'styled-components';
import StateOverviewChartContainer from './aggregated_status_chart';
import ProducedProductsChart from './produced_products_chart';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { TimeSpan } from '@dev/base-web/dist/model/domain/common/time_span';
import { Duration } from '@dev/base-web/dist/model/domain/durations/duration';
import ProductBatchesProducedOrderTable from './product_batches_produced_order_table';
import ProgressCard from './progress_card';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { intervalSecondsToRanges } from '@dev/base-web/dist/model/domain/range/range';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 46px;
  row-gap: 23px;
  margin: 12px 29px;

  @media screen and (min-width: 1400px) {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 12px) [col-start]);
    column-gap: 24px;
  }

  & > :last-child {
    height: 700px;
  }
`;

interface ResultCardsProps {
  readonly selectedDuration: Duration | null;
  readonly selectedManufacturingEntity: ManufacturingEntity | null;
  readonly selectedTimeSpan?: TimeSpan;
  readonly eventStatsIntervals: string | null;
}

const ResultCards = ({
  selectedDuration,
  selectedManufacturingEntity,
  selectedTimeSpan,
  eventStatsIntervals,
}: ResultCardsProps) => {
  const intl = useIntl();

  const intervalRangesDefinitions = useMemo(() => {
    return intervalSecondsToRanges(eventStatsIntervals, intl);
  }, [eventStatsIntervals]);

  return (
    <>
      <CardRow>
        <CardColumn
          style={{
            width: '100%',
            marginTop: 24,
            marginLeft: 29,
            marginRight: 29,
          }}
        >
          <ProgressCard timeSpan={selectedTimeSpan} />
        </CardColumn>
      </CardRow>
      <ContentContainer>
        <ProducedProductsChart
          duration={selectedDuration}
          manufacturingEntity={selectedManufacturingEntity}
          timeSpan={selectedTimeSpan}
        />
        <StateOverviewChartContainer
          intervalRangeDefinitions={intervalRangesDefinitions}
        />
      </ContentContainer>
      <CardRow>
        <CardColumn
          style={{
            width: '100%',
            marginTop: 24,
            marginLeft: 29,
            marginRight: 29,
          }}
        >
          <ProductBatchesProducedOrderTable
            manufacturingEntityId={selectedManufacturingEntity?.id}
            timeSpan={selectedTimeSpan}
          />
        </CardColumn>
      </CardRow>
    </>
  );
};

export default ResultCards;
