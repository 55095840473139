import { store } from '../redux/store';
import LocationConfigApi from './location/LocationConfigApi';
import InstructionApi from './instruction/instruction_api';
import InstructionDefinitionApi from './instruction/instruction_definition_api';
import ManufacturingEntityAPI from '@dev/base-web/dist/model/api/manufacturing_entity/manufacturing_entity_api';
import EventsApi from '@dev/base-web/dist/model/api/events/events_api';
import {
  baseApiClient,
  baseApiClientWithoutParameters,
  darwinApiClient,
  dataApiClient,
  dataApiClientWithoutParameters,
} from './clients';
import FeatureApi from '@dev/base-web/dist/model/api/feature/feature_api';
import ExtraFeatureApi from './feature/feature_extra_api';
import SignalApi from '@dev/base-web/dist/model/api/plc_signal/plc_signal_api';
import ConfigApi from '@dev/base-web/dist/model/api/configuration/config_api';
import FeatureConfigurationAPI from '@dev/base-web/dist/model/api/feature/feature_configuration_api';
import UserRoleApi from '@dev/base-web/dist/model/api/user_role/user_role_api';
import MediaAPI from '@dev/base-web/dist/model/api/common/media_api';
import DowntimeAPI from './downtime/DowntimeAPI';
import FeatureStatisticsApi from '@dev/base-web/dist/model/api/feature/feature_statistics_api';
import EventApi from './event/event_api';
import FeatureTemplateApi from '@dev/base-web/dist/model/api/feature/feature_template_api';
import ImportApi from '@dev/base-web/dist/model/api/import/import_api';
import ParallelEventsAPI from './parallel_events/parallel_events_api';
import ReportOverviewAPI from '@dev/base-web/dist/model/api/report/ReportOverviewAPI';
import ReportDowntimeAPI from '@dev/base-web/dist/model/api/report/ReportDowntimeAPI';
import ReportDowntimeReasonAPI from '@dev/base-web/dist/model/api/report/ReportDowntimeReasonAPI';
import DurationAPI from '@dev/base-web/dist/model/api/durations/durations_api';
import DurationConfigAPI from '@dev/base-web/dist/model/api/durations/duration_config_api';
import KPIOverTimeApi from './report/kpi_over_time_api';
import MainEntityKpiApi from './kpis/main_entity_kpi_api';
import SubscriptionApi from '@dev/base-web/dist/model/api/data_source/subscription_api';
import UserTenantApi from '@dev/base-web/dist/model/api/user_tenant/user_tenant_api';
import ManagementNotificationsApi from '@dev/base-web/dist/model/api/management_notifications/management_notifications_api';
import RawEventsApi from './event/raw_event_api';
import TranslationApi from './translation/translation_api';
import LabelApi from '@dev/base-web/dist/model/api/labels/label_api';
import CommentApi from '@dev/base-web/dist/model/api/comment/comment_api';
import ActionPictureApi from '@dev/base-web/dist/model/api/pictures/ActionPictureApi';
import ProductionProgressApi from './kpis/production_progress_api';
import EventDefinitionApi from './event/event_definition_api';
import InstructionSuggestionApi from './event/instruction_suggestion_api';
import DataSourceApi from '@dev/base-web/dist/model/api/data_source/data_source_api';

export const locationConfigApi = new LocationConfigApi(store, baseApiClient);
export const manufacturingEntityApi = new ManufacturingEntityAPI(store);
export const configApi = new ConfigApi(store, '', dataApiClient);
export const signalApi = new SignalApi(store, '', dataApiClient);
export const dataSourceApi = new DataSourceApi(store, '', dataApiClient);
export const subscriptionAPI = new SubscriptionApi(store, '/data-api');
export const featureApi = new FeatureApi(store, '', dataApiClient);
export const featureTemplatesApi = new FeatureTemplateApi(store, dataApiClient);
export const extraFeatureApi = new ExtraFeatureApi(store, '', dataApiClient);
export const featureConfigurationApi = new FeatureConfigurationAPI(
  store,
  '',
  dataApiClient
);
export const featureStatisticsApi = new FeatureStatisticsApi(
  store,
  '',
  dataApiClient
);
export const instructionApi = new InstructionApi(store, baseApiClient);
export const instructionDefinitionApi = new InstructionDefinitionApi(
  store,
  baseApiClient
);
export const instructionApiForDifferentLanguages = new InstructionDefinitionApi(
  store,
  baseApiClientWithoutParameters
);
export const actionPictureApi = new ActionPictureApi(store, baseApiClient);
export const localEventsApi = new EventApi(store, baseApiClient);
export const eventsApi = new EventsApi(store, baseApiClient);
export const rawEventsApi = new RawEventsApi(store, darwinApiClient);
export const eventDefinitionApi = new EventDefinitionApi(store, baseApiClient);
export const instructionSuggestionApi = new InstructionSuggestionApi(
  store,
  baseApiClient
);
export const commentApi = new CommentApi(store, baseApiClient);
export const labelAPI = new LabelApi(store, baseApiClient);
export const eventDefinitionImportApi = new ImportApi(
  '/event/definition',
  store,
  dataApiClient
);
export const mediaApi = new MediaAPI(store, baseApiClient);
export const downtimeApi = new DowntimeAPI(store, darwinApiClient);
export const durationApi = new DurationAPI(store, darwinApiClient);
export const durationConfigApi = new DurationConfigAPI(store, darwinApiClient);
export const userRoleApi = new UserRoleApi(store, baseApiClient);
export const userTenantApi = new UserTenantApi(
  store,
  dataApiClientWithoutParameters
);
export const parallelEventsAPI = new ParallelEventsAPI(store, baseApiClient);
export const reportOverviewApi = new ReportOverviewAPI(store, baseApiClient);
export const reportDowntimeApi = new ReportDowntimeAPI(store, baseApiClient);
export const reportDowntimeReasonApi = new ReportDowntimeReasonAPI(
  store,
  baseApiClient
);
export const kpiOverTimeApi = new KPIOverTimeApi(store, darwinApiClient);
export const mainEntityKpiApi = new MainEntityKpiApi(store, darwinApiClient);
export const managementNotificationsApi = new ManagementNotificationsApi(
  store,
  baseApiClient
);
export const translationApi = new TranslationApi(store, baseApiClient);
export const productionProgressApi = new ProductionProgressApi(
  store,
  darwinApiClient
);
