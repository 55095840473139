import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  Button,
  CenteredButtons,
} from '@dev/base-web/dist/view/components/global/button';
import {
  EventDefinition,
  EventRule,
  EventRuleType,
} from '../../../../model/domain/event/event_definition';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import RuleCard from './rule_card';
import FeatureConfigurationInstance from '@dev/base-web/dist/model/domain/feature/feature_configuration';

const RuleContainer = styled.div<{ isInternal?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 24px;
  margin-top: ${(props) => (props.isInternal ? '0' : '38px')};
`;

const NewRulesButtonContainer = styled(CenteredButtons)`
  margin-top: 8px;
`;

const NoRulesContainer = styled.div`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.14;
  letter-spacing: normal;
  color: #939393;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 8px;
`;

interface EventRuleContainerProps {
  readonly item: EventDefinition;
  readonly eventDefinitionId: string;
  readonly rules: EventRule[];
  readonly onRuleSaved: (index: number, rule: EventRule) => void;
  readonly onRuleDeleted: (index: number) => void;
  readonly onRuleAdded: (rule: EventRule) => void;
  readonly manufacturingEntities: ManufacturingEntity[];
  readonly manufacturingEntitiesLoading: boolean;
  readonly allowedToModify: boolean;
  readonly featureConfiguration: FeatureConfigurationInstance | null;
}

const RuleListContainer: React.FC<EventRuleContainerProps> = ({
  item,
  eventDefinitionId,
  rules,
  onRuleSaved,
  onRuleDeleted,
  onRuleAdded,
  manufacturingEntities,
  manufacturingEntitiesLoading,
  allowedToModify,
  featureConfiguration,
}) => {
  const [selectedRule, setSelectedRule] = useState<EventRule | null>(null);

  const onRuleDeleteClicked = (ruleNumber: number) => {
    setSelectedRule(null);
    onRuleDeleted(ruleNumber - 1);
  };

  const onRuleSavedClicked = (ruleNumber: number, rule: EventRule) => {
    setSelectedRule(null);
    onRuleSaved(ruleNumber - 1, rule);
  };

  const onRuleAddedClicked = () => {
    const newRule: EventRule = {
      open: null,
      close: null,
      type: EventRuleType.STANDARD,
      isClosedByDuration: null,
      dynamicProperties: { entityName: null, text: null },
    };
    setSelectedRule(newRule);
    onRuleAdded(newRule);
  };

  return (
    <RuleContainer isInternal={item.isInternal}>
      {rules.length !== 0 &&
        rules.map((rule, index) => (
          <RuleCard
            eventDefinitionId={eventDefinitionId}
            key={rule.id}
            ruleNumber={index + 1}
            rule={rule}
            selected={selectedRule === rule}
            onRuleSelected={setSelectedRule}
            onRuleDeleted={onRuleDeleteClicked}
            onRuleSaved={onRuleSavedClicked}
            manufacturingEntities={manufacturingEntities}
            manufacturingEntitiesLoading={manufacturingEntitiesLoading}
            allowedToModify={allowedToModify}
            featureConfiguration={featureConfiguration}
          />
        ))}
      {rules.length === 0 && (
        <NoRulesContainer>
          <FormattedMessage id="no_rules_info" />
        </NoRulesContainer>
      )}
      <NewRulesButtonContainer>
        <Button
          type="tertiary"
          icon="new"
          label="add_rule"
          disabled={!allowedToModify}
          onClick={onRuleAddedClicked}
        />
      </NewRulesButtonContainer>
    </RuleContainer>
  );
};

export default RuleListContainer;
