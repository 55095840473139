import React from 'react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { localizeNumber } from '@dev/base-web/dist/model/helpers/number-formatter';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import ShiftReportOverviewShiftData from '@dev/base-web/dist/model/domain/report/ShiftReportOverviewShiftData';

const LabelCell = styled.td`
  font-weight: bold;
`;

const ValueCell = styled.td`
  padding-left: 8px;
`;

interface PopupProps {
  interval: ShiftReportOverviewShiftData;
  cycleCount: number;
  trigger: any;
}

const ProductionRatioPopup = (props: PopupProps) => {
  const { formatDate, formatDuration } = useLocalizedDateFormatters();

  const end = formatDate(
    new Date().valueOf() < props.interval.end
      ? new Date().valueOf()
      : props.interval.end,
    'Pp'
  );

  return (
    <Popup trigger={props.trigger} hoverable flowing position="bottom left">
      <table>
        <tr>
          <LabelCell>
            <FormattedMessage id={'cycle_count'} />
          </LabelCell>
          <ValueCell>{localizeNumber(props.cycleCount)}</ValueCell>
        </tr>
        <tr>
          <LabelCell>
            <FormattedMessage id={'time_span'} />
          </LabelCell>
          <ValueCell>
            {`${formatDate(props.interval.start, 'Pp')} - 
                ${end}`}
          </ValueCell>
        </tr>
        <tr>
          <LabelCell>
            <FormattedMessage id={'planned_stops'} />
          </LabelCell>
          <ValueCell>
            {formatDuration(props.interval.plannedStopDuration, 'HH:mm:ss')}
          </ValueCell>
        </tr>
      </table>
    </Popup>
  );
};

export default ProductionRatioPopup;
