import { CardColumn } from '@dev/base-web/dist/view/components/global/card';
import { ScreenContent } from '@dev/base-web/dist/view/components/global/styled_components';
import styled from 'styled-components';

export const SlideInAnimation = styled.div`
  animation-duration: 0.25s;
  animation-name: slidein;

  @keyframes slidein {
    from {
      opacity: 0;
      transform: translatex(300px);
    }

    to {
      opacity: 1;
      transform: translatex(0px);
    }
  }
`;

export const NarrowColumn = styled(CardColumn)`
  flex: 0.33;

  @media screen and (min-width: 1900px) {
    flex: 0.25;
  }

  @media screen and (max-width: 1600px) {
    flex: 0.5;
  }

  @media screen and (max-width: 1200px) {
    flex: 1;
  }
`;

export const EventListColumn = styled(NarrowColumn)<{ isActionOpen: boolean }>`
  @media screen and (max-width: 1600px) {
    display: ${({ isActionOpen }) => (isActionOpen ? 'none' : 'flex')};
  }
`;

export const WideColumn = styled(CardColumn)<{ isActionOpen: boolean }>`
  flex: 0.33;

  @media screen and (min-width: 1900px) {
    flex: 0.5;
  }

  @media screen and (max-width: 1600px) {
    flex: 0.5;
    display: ${({ isActionOpen }) => (isActionOpen ? 'flex' : 'none')};
  }

  @media screen and (max-width: 1200px) {
    flex: 1;
    display: ${({ isActionOpen }) => (isActionOpen ? 'flex' : 'none')};
  }
`;

export const TabBarContainer = styled.div`
  margin-left: ${({ theme }) =>
    theme.cards.normalWrapper.margin.horizontal * 2}px;
`;

export const Wrapper = styled(ScreenContent)`
  margin: ${({ theme }) => theme.cards.normalWrapper.margin.vertical}px 24px 0;
`;
