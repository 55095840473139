import {
  getEvent,
  removeInstructionFromEvent,
  addInstructionsToEvent,
  updateEvent,
} from '@/model/redux/event_definition/thunks.ts';
import ManufacturingEntityThunks from '../../model/redux/manufacturing_entity/thunks';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { RootReducerInterface } from '@/model/redux/interfaces.ts';
import { EventDefinitionStoreProps } from './interface';
import { EventDetail } from './view';
import {
  withLocation,
  withNavigation,
  withParams,
} from '@dev/base-web/dist/view/helpers/navigation_hoc';
import FeatureThunks from '../../model/redux/feature/thunks';
import { cleanInstruction } from '@/model/redux/instruction_definition/thunks.ts';

const mapStateToProps = (
  state: RootReducerInterface
): EventDefinitionStoreProps => ({
  event: state.data.eventDefinitionState.event.data,
  eventOperationState: state.data.eventDefinitionState.event.meta,
  manufacturingEntities:
    state.data.manufacturingEntityState.manufacturingEntities.data,
  manufacturingEntitiesLoading:
    state.data.manufacturingEntityState.manufacturingEntities.meta
      .loadingInProgress,
  eventUpdated: state.data.eventDefinitionState.updateEvent.meta,
  instructionAddedToEvent:
    state.data.instructionDefinitionState.addEventsToInstruction.meta,
  instructionRemovedFromEvent:
    state.data.eventDefinitionState.removeInstructionFromEvent.meta,
  commentRemovedFromEvent: state.data.commentState.removeCommentFromEvent.meta,
  configs: state.configurationState.config.data,
  featureConfiguration: state.data.featureState.featureConfiguration.data,
});

export default connect(mapStateToProps, {
  updateEvent,
  getEvent,
  addInstructionsToEvent,
  removeInstructionFromEvent,
  getAllManufacturingEntities:
    ManufacturingEntityThunks.getManufacturingEntities,
  getFeatureConfiguration: FeatureThunks.getConfiguration,
  cleanInstruction,
})(injectIntl(withLocation(withParams(withNavigation(EventDetail)))));
