import styled from 'styled-components';

export const P = styled.p<{ disabled?: boolean }>`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${({ disabled, theme }) =>
    disabled ? '#808080' : theme.colors.black};
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
`;

export const Label = styled.div`
  font-family: Relative;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #878787;
`;
