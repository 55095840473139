import Icon from '@dev/base-web/dist/view/components/global/icon';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import styled from 'styled-components';

const MarkerContainer = styled.div`
  display: table;
`;

const MarkerElement = styled.div`
  display: table-cell;
  vertical-align: top;
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.43;
  letter-spacing: normal;
  color: #a6a6a6;
`;

interface HintProps {
  readonly messageKey: string;
}

export const Hint: React.FC<HintProps> = ({ messageKey }) => {
  return (
    <MarkerContainer>
      <MarkerElement>
        <Icon
          name="hand"
          color="gray"
          size={24}
          iconStyle={{ marginRight: 4 }}
        />
      </MarkerElement>
      <MarkerElement>
        <FormattedMessage id={messageKey} />
      </MarkerElement>
    </MarkerContainer>
  );
};
