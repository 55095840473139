import React, { useState } from 'react';
import { DropdownProps, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { useIntl } from 'react-intl';
import ToggleSwitch from '@dev/base-web/dist/view/components/inputs/toggle_switch';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';
import { IntervalType } from '@dev/base-web/dist/model/domain/interval_types';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-top: 8px;
`;

const StyledDropdown = styled(StyledDropDown)`
  margin-bottom: 12px;
`;

export const StyledLabelWithHint = styled(LabelWithHint)`
  padding-bottom: 10px;
`;

interface ChartOptionsPopupProps {
  readonly selectedIntervalType?: IntervalType;
  readonly user?: UserDetails | null;
  readonly setIntervalType: (intervalType: IntervalType) => void;
  readonly considerPlannedStops: boolean;
  readonly setConsiderPlannedStops: (considerPlannedStops: boolean) => void;
}

const ChartOptionsPopup: React.FC<ChartOptionsPopupProps> = (props) => {
  const {
    considerPlannedStops,
    setConsiderPlannedStops,
    setIntervalType,
    selectedIntervalType,
    user,
  } = props;

  const intl = useIntl();

  const [open, setOpen] = useState<boolean>(false);

  const intervalTypeOptions = [
    {
      key: IntervalType.PRODUCTION_SHIFT,
      value: IntervalType.PRODUCTION_SHIFT,
      text: intl.formatMessage({
        id: IntervalType.PRODUCTION_SHIFT.toLowerCase(),
      }),
      disabled: !(user && user.currentProductionShift),
    },
    {
      key: IntervalType.PRODUCED_ORDER,
      value: IntervalType.PRODUCED_ORDER,
      text: intl.formatMessage({
        id: IntervalType.PRODUCED_ORDER.toLowerCase(),
      }),
      disabled: !(user && user.currentProductionOrder),
    },
  ];

  const handleIntervalTypeChange = (
    _event: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    setIntervalType(value as unknown as IntervalType);
  };

  return (
    <Popup
      wide
      trigger={<Button type="secondary" icon="settings" />}
      on="click"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      position={'bottom right'}
      pinned
      offset={[-14]}
    >
      <FormContainer>
        <StyledLabelWithHint
          label={'interval_type'}
          hint={'interval_type_hint'}
        />
        <StyledDropdown
          text={
            selectedIntervalType
              ? intl.formatMessage({ id: selectedIntervalType.toLowerCase() })
              : null
          }
          defaultValue={selectedIntervalType}
          selection
          onChange={handleIntervalTypeChange}
          options={intervalTypeOptions}
        />
        <StyledLabelWithHint
          label={'exclude_planned_stops'}
          hint={'exclude_planned_stops_hint'}
        />
        <ToggleSwitch
          checked={considerPlannedStops}
          onChange={setConsiderPlannedStops}
        />
      </FormContainer>
    </Popup>
  );
};

export default ChartOptionsPopup;
