import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { MinMax } from '../kpis/factory_area_child_result';

export const INTERVAL_TYPES = [
  'PRODUCTION_SHIFT',
  'PRODUCED_ORDER',
  'PRODUCT_BATCH',
  'DAY',
] as const;
export type INTERVAL_TYPES_TYPE = (typeof INTERVAL_TYPES)[number];

export interface IntervalGroup {
  readonly description: string;
  readonly type: INTERVAL_TYPES_TYPE;
  readonly name: string;
  readonly start: number;
  readonly end: number;
  readonly plannedStopDuration: number;
}

export interface ManufacturingEntitySummaryInterval {
  readonly manufacturingEntity: ManufacturingEntity;
  readonly interval: IntervalGroup;
  readonly currentProduct: string;
  readonly producedProducts: number;
  readonly cycleCount: number;
  readonly productsPerCycle: MinMax;
  readonly lastCycleTimestamp: number;
  readonly utilization: number;
}
