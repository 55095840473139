import { Button } from '@dev/base-web/dist/view/components/global/button';
import React from 'react';
import { EventCoreInterface } from '@dev/base-web/dist/model/domain/event/event_type';
import { DowntimeReason } from '@dev/base-web/dist/model/domain/report/DowntimeReason';
import DowntimeAction from '@dev/base-web/dist/model/domain/report/DowntimeAction';
import DowntimeEvent from '@dev/base-web/dist/model/domain/report/DowntimeEvent';

interface DowntimeEditButtonProps {
  readonly selectedEvent?: EventCoreInterface;
  readonly selectedOtherReason?: DowntimeReason;
  readonly selectedDowntimeAction?: DowntimeAction;
  readonly selectedPossibleEvent?: DowntimeEvent;
  readonly comment?: string;
  readonly disabled?: boolean;
  readonly updateDowntimeInShiftReport: (
    newCause?: DowntimeEvent,
    newAction?: DowntimeAction,
    newOtherReason?: DowntimeReason,
    newComment?: string
  ) => void;
  readonly loading: boolean;
}

const DowntimeEditButton = ({
  selectedEvent,
  selectedDowntimeAction,
  selectedOtherReason,
  selectedPossibleEvent,
  updateDowntimeInShiftReport,
  disabled,
  comment,
  loading,
}: DowntimeEditButtonProps) => {
  return (
    <Button
      icon={'checkmark'}
      disabled={(!selectedEvent && !selectedOtherReason) || disabled}
      onClick={() =>
        (selectedPossibleEvent || selectedOtherReason || selectedEvent) &&
        updateDowntimeInShiftReport(
          selectedPossibleEvent,
          selectedDowntimeAction,
          selectedOtherReason,
          comment
        )
      }
      loading={loading}
    />
  );
};

export default DowntimeEditButton;
