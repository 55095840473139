import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';
import { Instruction } from '../../domain/instruction/instruction';

const CURRENT_INSTRUCTION_URL = (eventId: string, actionId: string) =>
  `/event/${eventId}/action/${actionId}`;

export default class InstructionApi extends BaseDataApi<Instruction> {
  async getCurrentInstruction(
    token: string,
    eventId: string,
    actionId: string
  ) {
    const url = CURRENT_INSTRUCTION_URL(eventId, actionId);
    const response = await this.executeGetRequest(token, url);
    //TODO: why is that needed?

    // The replace was in the original actionActions.js
    return JSON.parse(
      JSON.stringify(response).replace(/"id":(\d+),/g, '"id":"$1",')
    );
  }
}
