import axios from 'axios';

// Models
export const EVENTSLUG = 'events';

export const ACTIONSLUG = 'actions';
export const actionModel = [
  { Header: 'col_name', key: 'name' },
  {
    Header: 'col_rating',
    key: 'figures',
    className: 'votes',
    field: 'votes',
  },
  { Header: 'col_access_count', key: 'figures', field: 'accessCount' },
  {
    Header: 'col_author',
    key: 'user',
    field: { key1: 'image', key2: 'name' },
    deepSort: 'name',
  },
  { Header: 'col_created_time', key: 'createdTimestamp' },
];

export const CURRENTEVENTSSLUG = 'currentEvents';

// ROUTES

export const BASE_URL = import.meta.env.VITE_BASE_URL;
export const AUTH_URL = import.meta.env.VITE_AUTH_URL;

export const events = '/event/definition/search';
export const currentEvents = '/event/search';
export const actions = '/action/definition/search';
export const simpleSearch = '/{method}/definition/simpleSearch';
export const event = '/event/';
export const eventDefinition = '/event/definition/';
export const actionDefinition = '/action/definition/';
export const userProfil = '/user/me ';
export const addActionToEvent = '/event/definition/{}/actions';
export const addEventToAction = '/action/definition/{}/events/';
export const removeCommentFromEvent =
  '/event/definition/{id}/comment/{commentId}';
export const removeActionFromEvent = '/event/definition/{id}/action/{actionId}';
export const removeEventFromAction = '/action/definition/{id}/event/{eventId}';
export const locations = '/config/locations';
export const archiveActions = '/action/definition/archive/';
export const deleteActions = '/action/definition';
export const addEventFeedback = '/event/{}/feedback';
export const plcs = '/data/plc/simpleSearch';
export const signals = '/data/plc/signal/search';

export const archiveEvents = '/event/definition/archive/';
export default axios.create({ baseURL: BASE_URL });

// ACTIONS & REDUCERS SYMBOLS
export const SIGNUP_BEGIN = 'SIGNUP_BEGIN';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const LOGOUT = 'LOGOUT';

export const GETUSER_BEGIN = 'GETUSER_BEGIN';
export const GETUSER_SUCCESS = 'GETUSER_SUCCESS';
export const GETUSER_FAILURE = 'GETUSER_FAILURE';

// TOKEN REFRESH SYMBOLS
export const TOKENREFRESH_BEGIN = 'TOKENREFRESH_BEGIN';
export const TOKENREFRESH_SUCCESS = 'TOKENREFRESH_SUCCESS';
export const TOKENREFRESH_FAILURE = 'TOKENREFRESH_FAILURE';

// ITEM SYMBOLS
export const GETITEMS_BEGIN = 'GETITEMS_BEGIN';
export const GETITEMS_SUCCESS = 'GETITEMS_SUCCESS';
export const GETITEMS_FAILURE = 'GETITEMS_FAILURE';

export const GETITEM_BEGIN = 'GETITEM_BEGIN';
export const GETITEM_SUCCESS = 'GETITEM_SUCCESS';
export const GETITEM_FAILURE = 'GETITEM_FAILURE';

export const ARCHIVEITEMS_SUCCESS = 'ARCHIVEITEMS_SUCCESS';
export const ARCHIVEITEMS_FAILURE = 'ARCHIVEITEMS_FAILURE';

export const DELETEITEMS_SUCCESS = 'DELETEITEMS_SUCCESS';
export const DELETEITEMS_FAILURE = 'DELETEITEMS_FAILURE';

export const UPDATEEVENT_BEGIN = 'UPDATEEVENT_BEGIN';
export const UPDATEEVENT_SUCCESS = 'UPDATEEVENT_SUCCESS';

export const UPDATEACTION_BEGIN = 'UPDATEACTION_BEGIN';
export const UPDATEACTION_SUCCESS = 'UPDATEACTION_SUCCESS';

export const CLEAR_ITEM = 'CLEAR_ACTION';

export const GETLOCATIONS_BEGIN = 'GETLOCATIONS_BEGIN';
export const GETLOCATIONS_SUCCESS = 'GETLOCATIONS_SUCCESS';
export const GETLOCATIONS_FAILURE = 'GETLOCATIONS_FAILURE';

export const GETPLCS_BEGIN = 'GETPLCS_BEGIN';
export const GETPLCS_SUCCESS = 'GETPLCS_SUCCESS';
export const GETPLCS_FAILURE = 'GETPLCS_FAILURE';

export const GETSIGNALS_BEGIN = 'GETSIGNALS_BEGIN';
export const GETSIGNALS_SUCCESS = 'GETSIGNALS_SUCCESS';
export const GETSIGNALS_FAILURE = 'GETSIGNALS_SUCCESS';

// Current events

export const GETCURRENTEVENTS_BEGIN = 'GETCURRENTEVENTS_BEGIN';
export const GETCURRENTEVENTS_SUCCESS = 'GETCURRENTEVENTS_SUCCESS';
export const GETCURRENTEVENTS_FAILURE = 'GETCURRENTEVENTS_FAILURE';

// Localization
export const SET_LOCALE = 'SET_LOCALE';

// Bundle folders
export const SCRIPTS_FOLDER = 'scripts';
