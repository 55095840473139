import { Button } from '@dev/base-web/dist/view/components/global/button';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';
import InputWithCount from '@dev/base-web/dist/view/components/inputs/input_with_count';
import {
  SelectLabels,
  SelectLabelsHandle,
} from '@dev/base-web/dist/view/components/inputs/label_select';
import ToggleSwitch from '@dev/base-web/dist/view/components/inputs/toggle_switch';
import React, { useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DropdownProps, InputProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { ActionTitleCardProps } from './interface';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import EditLabelModal from './edit_label_modal';
import {
  ActionAccessLevel,
  BasicActionDefinitionDTO,
} from '@/model/domain/instruction/instruction.ts';
import {
  getTranslation,
  sortTranslationsByLang,
} from '@/model/domain/instruction/helper.ts';

const DivToggle = styled.div`
  margin-left: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #8c8c8c;
  margin-right: 8px;
`;

const TitleEditWrapper = styled.div`
  margin-top: 12px;
`;

const StyledWideInput = styled(InputWithCount)`
  & {
    width: 100%;
    opacity: 100% !important;
  }
`;

const StyledLabelWithHint = styled(LabelWithHint)`
  margin-right: 8px;
`;

const StyledToggleSwitch = styled(ToggleSwitch)`
  margin-top: 8px;
`;

export type ChangeableFieldsInTitle = Readonly<
  Partial<
    Pick<
      BasicActionDefinitionDTO,
      'translations' | 'isHidden' | 'accessLevel' | 'labels'
    >
  >
>;

const InstructionTitleCard = ({
  allowedToModify,
  allowedToModifyConfig,
  item,
  onChange,
  labels,
  labelsMeta,
  shareInstructions,
  selectedLanguage,
}: ActionTitleCardProps) => {
  const intl = useIntl();

  const selectLabelsMenuRef = useRef<SelectLabelsHandle>(null);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onChangeName: InputProps['onChange'] = (e) => {
    if (selectedLanguage)
      onChange({
        translations: sortTranslationsByLang([
          ...item.translations.filter((t) => t.language !== selectedLanguage),
          { language: selectedLanguage, translation: e.target.value },
        ]),
      });
  };

  const name = getTranslation(item.translations, '', selectedLanguage);

  const additionalOptions = useMemo(
    () => [
      {
        children: (
          <Button
            type="tertiary"
            icon="edit"
            label="manage_labels"
            size="small"
            noMargin
            onClick={(e) => {
              e.stopPropagation();
              setModalOpen(true);
              selectLabelsMenuRef.current?.close();
            }}
          />
        ),
        value: undefined,
        text: undefined,
        key: 'manage_labels',
      },
    ],
    []
  );

  if (!item) {
    return null;
  }

  const levelOptions = shareInstructions
    ? ['OPERATOR', 'EXPERT', 'INTERNAL']
    : ['OPERATOR', 'EXPERT'];

  const accessLevelOptions = levelOptions.map((l) => ({
    key: l,
    value: l,
    text: intl.formatMessage({ id: l.toLowerCase() }),
  }));

  const handleAccessLevelChange = (
    _event: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    onChange({ accessLevel: value as unknown as ActionAccessLevel });
  };

  return (
    <Card
      style={{ marginBottom: '24px', paddingBottom: 12 }}
      titleId="action_title"
      headerContent={
        <>
          <StyledLabelWithHint
            label={'access_level'}
            hint={
              shareInstructions
                ? 'solution_access_level_with_internal_hint'
                : 'solution_access_level_hint'
            }
            hintValues={{ br: <br /> }}
          />
          <StyledDropDown
            text={
              <FormattedMessage
                id={item.accessLevel?.toLowerCase() || 'operator'}
              />
            }
            selection
            onChange={handleAccessLevelChange}
            options={accessLevelOptions}
            disabled={!allowedToModifyConfig}
          />
          <DivToggle>
            <Label>
              {!item.isHidden ? (
                <FormattedMessage id="action_active" />
              ) : (
                <FormattedMessage id="action_inactive" />
              )}
            </Label>
            <StyledToggleSwitch
              disabled={!allowedToModifyConfig}
              checked={!item.isHidden}
              onChange={(value) => onChange({ isHidden: !value })}
            />
          </DivToggle>
        </>
      }
    >
      <TitleEditWrapper>
        <StyledWideInput
          name="name"
          error={!name}
          value={name}
          onChange={onChangeName}
          placeholder={
            item.error
              ? item.error
              : intl.formatMessage({ id: 'add_title_hint' })
          }
          showCount
          disabled={!allowedToModify}
        />
        <SelectLabels
          ref={selectLabelsMenuRef}
          values={item.labels}
          options={labels}
          loading={labelsMeta.loadingInProgress}
          onChange={(values) => {
            onChange({
              labels: values,
            });
          }}
          additionalOptions={additionalOptions}
        />
      </TitleEditWrapper>
      <EditLabelModal
        modalVisible={modalOpen}
        onCancelPressed={() => setModalOpen(false)}
        labels={labels}
      />
    </Card>
  );
};

export default InstructionTitleCard;
