import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import ToggleSwitch from '@dev/base-web/dist/view/components/inputs/toggle_switch';
import styled from 'styled-components';
import { TextArea } from '../../../components/TextArea';
import {
  EventDefinition,
  EventDefinitionTranslation,
} from '../../../model/domain/event/event_definition';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import { EventTypeTagSelect } from '@dev/base-web/dist/view/components/global/event_type_tag';
import InputWithCount from '@dev/base-web/dist/view/components/inputs/input_with_count';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';
import { ThemeProps } from '@dev/base-web/dist/view/themes/interface';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { useDateLocale } from '@dev/base-web/dist/view/helpers/date_hooks';
import { localisation } from '../../../model/redux/reducers';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import { TranslationRow } from './translation_row';
import { Divider, Dropdown } from 'semantic-ui-react';
import { Row } from '@dev/base-web/dist/view/components/global/styled_components';
import {
  Column,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import { useCallbackToOpenTabWithPathPrefix } from '@dev/base-web/dist/view/helpers/common';
import DarwinConfig from '@dev/base-web/dist/model/domain/authorization/darwin';
import { ApplicationCategory } from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import { UserPrivilegePermission } from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import { useAuthorizationCheckRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';

export const translationColumns: Column[] = [
  {
    title: 'language',
    isLeftAligned: true,
    filterType: ColumnFilterType.NONE,
    width: 3,
  },
  {
    title: 'name',
    isLeftAligned: true,
    filterType: ColumnFilterType.NONE,
    width: 6,
  },
  {
    title: 'description',
    isLeftAligned: true,
    filterType: ColumnFilterType.NONE,
    width: 7,
  },
  {
    title: ' ',
    isLeftAligned: false,
    filterType: ColumnFilterType.NONE,
    width: 1,
  },
];

interface LanguageDataWithMetadata extends EventDefinitionTranslation {
  isDefault: boolean;
}

const Info = styled.p`
  margin: 0;
  padding-top: 20px;
  padding-bottom: 8px;
`;

const SettingsContainer = styled.div`
  margin-right: 48px;
`;

const StyledWideInput = styled(InputWithCount)`
  & {
    width: 100%;
  }
`;

const StyledTextArea = styled(TextArea)`
  & {
    width: 100%;
  }
`;

const StyledToggleContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-rows: repeat(2, 24px);
  grid-template-columns: repeat(2, max-content);
  row-gap: 16px;
  column-gap: 16px;
  align-items: center;
  justfiy-content: start;
`;

const StyledToggleSwitch = styled(ToggleSwitch)`
  & {
    label {
      height: 24px;
      transform: translate(0, 4px);
    }
  }
`;

const StyledLabelWithHint = styled(LabelWithHint)`
  & {
    color: ${({ theme }: ThemeProps) => theme.colors.black} !important;
  }
`;

export const FlexEndRow = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  min-width: 200px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 90%;
  height: 18px;
  strong {
    white-space: nowrap;
  }

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
  }
`;

const BigScreenContainer = styled.div`
  display: inline;
  width: 100%;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const SmallScreenContainer = styled.div`
  display: inline;
  width: 100%;
  @media (min-width: 1280px) {
    display: none;
  }
`;

export type EventDefinitionPropertiesOnChangeValue =
  | React.ChangeEvent<HTMLTextAreaElement>
  | { key: string; value: boolean };
export type EventDefinitionPropertiesOnChange = (
  e: EventDefinitionPropertiesOnChangeValue
) => void;

interface EventDefinitionPropertiesProps {
  readonly item: EventDefinition;
  readonly onChange: EventDefinitionPropertiesOnChange;
  readonly onAddTranslation: (translation: EventDefinitionTranslation) => void;
  readonly onDeleteTranslation: (language: string) => void;
  readonly swapButton: (type: EventType) => void;
  readonly allowedToModify: boolean;
}

const EventDefinitionProperties: React.FC<EventDefinitionPropertiesProps> = ({
  item,
  onChange,
  onAddTranslation,
  onDeleteTranslation,
  swapButton,
  allowedToModify,
}) => {
  const { code } = useDateLocale();
  const localeLanguage = code?.substring(0, 2);
  const locales = Object.keys(localisation);
  const defaultTranslation =
    item.translations.find(
      (translation) => translation.language.toLowerCase() === localeLanguage
    ) || item.translations[0];
  const { type, isHidden, excludeFromStats } = item;

  const [filteredLocales, setFilteredLocales] = useState<string[]>([]);
  const [isAddLanguageModalOpen, setIsAddLanguageModalOpen] = useState(false);
  const [selectedLanguageData, setSelectedLanguageData] = useState<
    LanguageDataWithMetadata | undefined
  >(
    defaultTranslation
      ? {
          ...defaultTranslation,
          isDefault: true,
        }
      : undefined
  );
  const [initialItem, setInitialItem] = useState<EventDefinition>();

  useEffect(() => {
    const filter = locales.filter(
      (locale) =>
        !item.translations.some(
          (translation) => translation.language.toLowerCase() === locale
        )
    );

    if (JSON.stringify(filter) !== JSON.stringify(filteredLocales)) {
      setFilteredLocales(filter);
    }
  }, [locales]);

  useEffect(() => {
    if (!initialItem) {
      setInitialItem(item);
    }
  }, [item]);

  const paddingTop = item.isInternal ? '0' : '46px';

  const handleAddLanguageModal = () => {
    setIsAddLanguageModalOpen(!isAddLanguageModalOpen);
  };

  const handleTranslation = (languageData: EventDefinitionTranslation) => {
    setSelectedLanguageData({
      ...languageData,
      isDefault: languageData.language.toLowerCase() === localeLanguage,
    });
    setIsAddLanguageModalOpen(false);
  };

  const removeTranslation = (language: string) => {
    onDeleteTranslation(language);
  };

  const addTranslation = (language: string) => {
    const newTranslation = {
      name: '',
      description: '',
      language: language.toUpperCase(),
    };
    setIsAddLanguageModalOpen(false);
    setSelectedLanguageData({
      ...newTranslation,
      isDefault: language.toLowerCase() === localeLanguage,
    });
    onAddTranslation(newTranslation);
  };

  const eventStatisticsOnClick =
    item.rules.length === 1 && item.rules[0].manufacturingEntity
      ? useCallbackToOpenTabWithPathPrefix(
          'analytics',
          `${DarwinConfig.Downtimes.url.path}/${item.rules[0].manufacturingEntity.id}/${item.id}`,
          [item]
        )
      : undefined;

  const { read: canReadStatistics } = useAuthorizationCheckRWD(
    UserPrivilegePermission.DOWNTIME,
    ApplicationCategory.DARWIN
  );

  return (
    <SettingsContainer>
      <Info style={{ paddingTop }}>
        <FormattedMessage id="event_category" />
      </Info>
      <EventTypeTagSelect
        required
        disabled={!allowedToModify}
        value={[type]}
        onSelect={(t) => swapButton(t[0])}
      />

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <SmallScreenContainer>
          <FlexEndRow
            style={{
              marginBottom: '-15px',
            }}
          >
            <Dropdown
              trigger={
                <div>
                  <Button
                    type="secondary"
                    icon="arrow-down-bold"
                    size="small"
                    iconPosition="left"
                    label={selectedLanguageData?.language.toLowerCase()}
                    mobileLabel={selectedLanguageData?.language.toLowerCase()}
                    onClick={() => {
                      setIsAddLanguageModalOpen(false);
                    }}
                  />
                </div>
              }
              icon={null}
              floating
              direction="left"
              disabled={item.isInternal || !allowedToModify}
              labeled
              closeOnChange={false}
            >
              <Dropdown.Menu>
                {item.translations.map((translation) => (
                  <Dropdown.Item onClick={() => handleTranslation(translation)}>
                    <Row
                      style={{ justifyContent: 'space-between', gap: '10px' }}
                    >
                      <TextContainer>
                        <strong>
                          <FormattedMessage
                            id={translation.language.toLowerCase()}
                          />
                          :
                        </strong>
                        <div>{translation.name}</div>
                      </TextContainer>
                      {translation.language.toLowerCase() ===
                        selectedLanguageData?.language.toLowerCase() && (
                        <Icon name="checkmark" color="#078388" size={16} />
                      )}
                    </Row>
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />

                {isAddLanguageModalOpen ? (
                  <>
                    {filteredLocales.map((locale) => (
                      <Dropdown.Item onClick={() => addTranslation(locale)}>
                        <strong>
                          <FormattedMessage id={'add'} />:{' '}
                          <FormattedMessage id={locale} />
                        </strong>
                      </Dropdown.Item>
                    ))}
                    {filteredLocales.length === 0 && (
                      <Dropdown.Item>
                        <FormattedMessage id={'no_languages_available'} />
                      </Dropdown.Item>
                    )}
                  </>
                ) : (
                  <Dropdown.Item
                    onClick={(e) => {
                      if (filteredLocales.length !== 0) {
                        e.stopPropagation();
                        handleAddLanguageModal();
                      }
                    }}
                  >
                    <Button
                      iconPosition="left"
                      icon="plus"
                      label="add_translation"
                      mobileLabel="add_translation"
                      size="tiny"
                      type="tertiary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddLanguageModal();
                      }}
                      disabled={filteredLocales.length === 0}
                      tooltipLabel={
                        filteredLocales.length === 0
                          ? 'no_languages_available'
                          : undefined
                      }
                    />
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </FlexEndRow>
          <Info>
            <FormattedMessage id="event_title" />
          </Info>
          <StyledWideInput
            name={`name-${selectedLanguageData?.language.toLowerCase()}-${
              selectedLanguageData?.isDefault
            }`}
            disabled={!allowedToModify}
            onChange={onChange}
            value={
              item.translations.find(
                (translation) =>
                  translation.language.toLowerCase() ===
                  selectedLanguageData?.language.toLowerCase()
              )?.name || ''
            }
            showCount
            error={
              item.translations.find(
                (translation) =>
                  translation.language.toLowerCase() ===
                  selectedLanguageData?.language.toLowerCase()
              )?.name === '' || false
            }
          />
          <Info>
            <FormattedMessage id="description" />
          </Info>
          <StyledTextArea
            name={`description-${selectedLanguageData?.language.toLowerCase()}-${
              selectedLanguageData?.isDefault
            }`}
            disabled={!allowedToModify}
            onChange={onChange}
            value={
              item.translations.find(
                (translation) =>
                  translation.language.toLowerCase() ===
                  selectedLanguageData?.language.toLowerCase()
              )?.description || ''
            }
          />
        </SmallScreenContainer>
        <BigScreenContainer>
          <FlexEndRow
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '-10px',
            }}
          >
            <FormattedMessage id={'translations'} />
            <Dropdown
              trigger={
                <div>
                  <Button
                    type="secondary"
                    icon="plus"
                    iconPosition="right"
                    label="add_language"
                    onClick={() => {
                      setIsAddLanguageModalOpen(false);
                    }}
                    size={'small'}
                    disabled={filteredLocales.length === 0}
                    tooltipLabel={
                      filteredLocales.length === 0
                        ? 'no_languages_available'
                        : undefined
                    }
                  />
                </div>
              }
              icon={null}
              floating
              direction="left"
              labeled
              disabled={item.isInternal || !allowedToModify}
              closeOnChange={false}
            >
              <Dropdown.Menu>
                {filteredLocales.map((locale) => (
                  <Dropdown.Item onClick={() => addTranslation(locale)}>
                    <div style={{ height: '15px' }}>
                      <strong>
                        <FormattedMessage id={'add_translation'} />:{' '}
                        <FormattedMessage id={locale} />
                      </strong>
                    </div>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </FlexEndRow>
          <SortableTable
            dataLength={item.translations.length}
            hasMoreResults={false}
            columns={translationColumns}
            getNextResults={() => {}}
            loadingInProgress={false}
            filtersDisabled
            maxHeight={420}
            minHeight={50}
          >
            {item.translations
              .sort((a, b) => a.language.localeCompare(b.language))
              .map((translation) => {
                return (
                  <TranslationRow
                    translation={translation}
                    onChange={onChange}
                    disabled={item.isInternal || !allowedToModify}
                    hideRemove={
                      initialItem?.translations.some(
                        (t) =>
                          t.language.toLowerCase() ===
                          translation.language.toLowerCase()
                      ) || !allowedToModify
                    }
                    disableRemove={
                      localeLanguage === translation.language.toLowerCase()
                    }
                    removeTranslation={removeTranslation}
                  />
                );
              })}
          </SortableTable>
        </BigScreenContainer>
      </div>
      <StyledToggleContainer>
        <StyledLabelWithHint
          label={'event_is_active'}
          hint={'active_event_hint'}
        />
        <StyledToggleSwitch
          checked={!isHidden}
          onChange={(value) => onChange({ key: 'isHidden', value: value })}
          disabled={!allowedToModify}
        />
        <StyledLabelWithHint
          label={'exclude_from_stats'}
          hint={'exclude_from_stats_hint'}
        />
        <StyledToggleSwitch
          checked={excludeFromStats}
          onChange={(value) =>
            onChange({ key: 'excludeFromStats', value: value })
          }
          disabled={!allowedToModify}
        />
      </StyledToggleContainer>
      {eventStatisticsOnClick != null && canReadStatistics && (
        <>
          <Divider />
          <Button
            type={'secondary'}
            icon={'bars'}
            label={'statistics'}
            onClick={eventStatisticsOnClick}
          />
        </>
      )}
    </SettingsContainer>
  );
};

export default EventDefinitionProperties;
