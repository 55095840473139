import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import {
  LeftAlignedTableCell,
  StyledFirstCell,
  StyledRow,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import { StyledRightColumn } from '../../../../components/StyledComponents';
import { AggregationChildRule } from '../../../../model/domain/event/event_definition';
import { openInNewTab } from '@dev/base-web/dist/view/helpers/common';
import React from 'react';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { useEventTypeLook } from '@dev/base-web/dist/view/components/global/common';
import styled from 'styled-components';

interface AggregationChildRuleRowProps {
  readonly rule: AggregationChildRule;
}

const EventNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

export const AggregationChildRuleRow: React.FC<
  AggregationChildRuleRowProps
> = ({ rule }) => {
  const locationSet = new Set(
    rule.eventDefinition.manufacturingEntities?.map(
      (entity) => entity.shortName || entity.name
    )
  );
  const locations = locationSet.size > 0 ? [...locationSet].join(', ') : '-';
  const { color, icon } = useEventTypeLook(rule.eventDefinition.type);

  return (
    <StyledRow
      onClick={() => openInNewTab(`/shannon/events/${rule.eventDefinition.id}`)}
    >
      <StyledFirstCell>
        <TextWithEllipsisAndTooltip
          text={locations}
          lines={2}
        ></TextWithEllipsisAndTooltip>
      </StyledFirstCell>
      <LeftAlignedTableCell>
        <EventNameContainer>
          <StyledIcon name={icon} color={color} size={14} />
          <TextWithEllipsisAndTooltip
            text={rule.eventDefinition.name}
            lines={2}
            style={{ width: 'calc(100% - 32px)' }}
          />
        </EventNameContainer>
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        <StyledRightColumn>
          <Icon name={'arrow-right-bold'} color={'#8c8c8c'} size={16} />
        </StyledRightColumn>
      </LeftAlignedTableCell>
    </StyledRow>
  );
};
