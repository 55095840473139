import { ErrorInterface } from '@dev/base-web/dist/model/api/error/error_interface';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import {
  LoadingMetaState,
  OperationMetaState,
} from '@dev/base-web/dist/model/redux/helpers/interfaces';
import { WrappedComponentProps } from 'react-intl';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import SearchData from '@dev/base-web/dist/model/api/common/search_data';
import {
  ApplicationFunction,
  ApplicationFunctionType,
} from '@dev/base-web/dist/model/domain/authorization/interface';
import { SHANNON_BASE_URL } from '@dev/base-web/dist/model/domain/authorization/shannon';
import {
  ApplicationCategory,
  UserPrivilege,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import { NavigationProps } from '@dev/base-web/dist/view/helpers/navigation_hoc';

export const CONFIG: ApplicationFunction = {
  type: ApplicationFunctionType.SCREEN,
  label: 'user_admin',
  url: {
    baseUrl: SHANNON_BASE_URL,
    path: '/user-admin',
  },
  constraint: {
    category: ApplicationCategory.COMMON,
    permission: UserPrivilegePermission.USER,
    rwd: 'write',
  },
};

export interface UserAdminState {
  readonly page: number;
  readonly filters: FilterData[];
  readonly sortKey?: string;
  readonly sortDirection?: SortingDirection;
  readonly initialUserSearchData?: SearchData;
  readonly activeTab: number;
  readonly initialPrivilegesSearchData?: SearchData;
  readonly userPrivileges?: UserPrivilege[];
  readonly userPrivilegesChanged: boolean;
  readonly selectedNotRegisteredUser?: UserDetails;
  readonly dropdownFocus: boolean;
}

export interface UserAdminStoreProps {
  readonly error?: ErrorInterface;
  readonly users: UserDetails[];
  readonly hasMoreResults: boolean;
  readonly loadingInProgress: boolean;
  readonly updateUsers: OperationMetaState;
  readonly manufacturingEntities: ManufacturingEntity[];
  readonly manufacturingEntitiesMeta: LoadingMetaState;
  readonly lastUserSearchData?: SearchData;
  readonly userPrivileges: UserPrivilege[];
  readonly userPrivilegesLoading: boolean;
  readonly hasMoreUserPrivileges: boolean;
  readonly roleOperation: OperationMetaState;
  readonly roleNames: string[];
  readonly currentUser: UserDetails | null;
  readonly notRegisteredUsers: UserDetails[];
  readonly notRegisteredUsersMeta: LoadingMetaState;
  readonly inviteUserMeta: OperationMetaState;
  readonly user: UserDetails | null;
  readonly isSaaS?: boolean | null;
  readonly usersTotalElements?: number;
}

export interface UserAdminDispatchProps {
  readonly getUsers: (
    page: number,
    filters: FilterData[],
    sortingKey?: string,
    sorting?: SortingDirection
  ) => void;
  readonly resetUser: () => void;
  readonly getAllManufacturingEntities: () => void;
  readonly getUserPrivileges: (page: number) => void;
  readonly createRole: (roleName: string) => void;
  readonly deleteRole: (roleName: string) => void;
  readonly getAllRoleNames: () => void;
  readonly updatePrivileges: (privileges: UserPrivilege[]) => void;
  readonly getNotRegisteredUsers: (query: string) => void;
  readonly inviteUser: (authId: string) => void;
}

export type UserAdminProps = UserAdminStoreProps &
  UserAdminDispatchProps &
  WrappedComponentProps &
  NavigationProps;
