import OpenDowntimeCard from './open_downtime_card';
import { OperationMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import { LoadingCardGrid } from '@dev/base-web/dist/view/components/global/card';
import styled from 'styled-components';
import DowntimeOverview from '@dev/base-web/dist/model/domain/report/DowntimeOverview';
import Downtime from '@dev/base-web/dist/model/domain/report/Downtime';
import { DowntimeReason } from '@dev/base-web/dist/model/domain/report/DowntimeReason';
import DowntimeEvent from '@dev/base-web/dist/model/domain/report/DowntimeEvent';
import DowntimeAction from '@dev/base-web/dist/model/domain/report/DowntimeAction';
import EventDetail from '../../../../../model/domain/event/event';
import ShiftReportOverview from '@dev/base-web/dist/model/domain/report/ShiftReportOverview';

const OpenDowntimeListContainer = styled.div`
  padding: 24px;
  padding-top: 16px;
`;

interface OpenDowntimeListProps {
  readonly downtimesInReport: readonly DowntimeOverview[];
  readonly shiftReport?: ShiftReportOverview;
  readonly downtimesInReportLoadingInProgress: boolean;
  readonly selectedDowntime: Downtime | null;
  readonly selectedDowntimeLoadingInProgress: boolean;
  readonly showManufacturingEntityHierarchy?: boolean | null;
  readonly downtimeReasons: readonly DowntimeReason[];
  readonly downtimeReasonsLoadingInProgress: boolean;
  readonly getDowntime: (reportId: string, downTimeId: string) => void;
  readonly selectedDowntimeOverview?: DowntimeOverview;
  readonly onDowntimeOverviewSelected: (
    downtimeOverview?: DowntimeOverview
  ) => void;
  readonly updateDowntimeInShiftReport: (
    newCause?: DowntimeEvent,
    newAction?: DowntimeAction,
    newOtherReason?: DowntimeReason,
    newComment?: string,
    downtimeOverView?: DowntimeOverview
  ) => void;
  readonly downtimeUpdateMeta: OperationMetaState;
  readonly editable: boolean;
  readonly getSelectedEvent: (eventId: string) => void;
  readonly selectedEventData: EventDetail | null;
}

const OpenDowntimeList = ({
  downtimesInReport,
  downtimesInReportLoadingInProgress,
  selectedDowntime,
  getDowntime,
  selectedDowntimeLoadingInProgress,
  showManufacturingEntityHierarchy,
  updateDowntimeInShiftReport,
  downtimeReasons,
  downtimeReasonsLoadingInProgress,
  selectedDowntimeOverview,
  onDowntimeOverviewSelected,
  downtimeUpdateMeta,
  editable,
  getSelectedEvent,
  selectedEventData,
  shiftReport,
}: OpenDowntimeListProps) => {
  return (
    <OpenDowntimeListContainer>
      <LoadingCardGrid
        loading={downtimesInReportLoadingInProgress}
        empty={downtimesInReport.length === 0}
        cardWidth={450}
        gap={16}
      >
        {downtimesInReport.map((report) => (
          <OpenDowntimeCard
            key={report.id}
            getDowntime={getDowntime}
            selectedDowntimeLoadingInProgress={
              selectedDowntimeLoadingInProgress
            }
            selectedDowntime={selectedDowntime}
            updateDowntimeInShiftReport={updateDowntimeInShiftReport}
            downtimeReasons={downtimeReasons}
            downtimeReasonsLoadingInProgress={downtimeReasonsLoadingInProgress}
            reportId={report.id}
            report={shiftReport}
            downtimeOverview={report}
            showManufacturingEntityHierarchy={
              !!showManufacturingEntityHierarchy
            }
            selected={selectedDowntimeOverview?.id === report.id}
            onSelected={() => onDowntimeOverviewSelected(report)}
            downtimeUpdateInProgress={downtimeUpdateMeta.operationInProgress}
            editable={editable}
            getSelectedEvent={getSelectedEvent}
            selectedEventData={selectedEventData}
          />
        ))}
      </LoadingCardGrid>
    </OpenDowntimeListContainer>
  );
};

export default OpenDowntimeList;
