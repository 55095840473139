import styled, { css } from 'styled-components';
import { Dropdown, Input, Table, TextArea } from 'semantic-ui-react';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { RightAlignedButtons } from '@dev/base-web/dist/view/components/global/button';

export const Seperator = styled.div`
  position: relative;
  height: 1px;
  background-color: #efefef;
  margin-top: 18px;
  margin-bottom: 18px;
`;

export const StyledInput = styled(Input)`
  &&& {
    font-family: Relative;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #242424;

    && input::placeholder {
      font-family: Relative;
      font-size: 14px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      letter-spacing: normal;
      color: #a6a6a6;
      opacity: 1;
    }

    && input:hover {
      border: solid 1px rgba(0, 0, 0, 0.4);
    }
    &&& input:active {
      border: solid 1px #0da6a0;
    }
    &&& input:invalid {
      border: solid 2px #e30021;
    }
    &&& input:focus {
      border: solid 1px #0da6a0;
    }
    &&& input:disabled {
      border: solid 1px rgba(0, 0, 0, 0.1);
      background-color: #f0f0f0;
    }
  }
`;

export const StyledTextArea = styled(TextArea)`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #242424;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  outline: none;

  ::placeholder {
    font-family: Relative;
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #a6a6a6;
  }

  :hover {
    border: solid 1px rgba(0, 0, 0, 0.4);
  }
  :active {
    border: solid 1px #0da6a0;
  }
  :invalid {
    border: solid 2px #e30021;
  }
  :focus {
    border: solid 1px #0da6a0;
  }
  :disabled {
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #f0f0f0;
  }
`;

export const StyledDropDown = styled(Dropdown)`
  &&&&& {
    .default {
      font-family: Relative;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      letter-spacing: normal;
      color: #a6a6a6;
    }
  }
`;

export const Figure = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const positiveCounterStyle = css`
  border: solid 1px #d2edec;
  background-color: #e9f5f5;
  color: #0da6a0;
`;

const negativeCounterStyle = css`
  border: solid 1px #fcdfe2;
  background-color: #fef0f2;
  color: #e30021;
`;

export const Counter = styled.div`
  width: 72px;
  height: 40px;
  border-radius: 8px;
  border: solid 1px rgba(36, 36, 36, 0.05);
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #242424;
  text-align: center;
  line-height: 1.3;
  margin-bottom: 8px;

  ${(props: { status: string }) =>
    props.status === 'positive' && positiveCounterStyle}

  ${(props: { status: string }) =>
    props.status === 'negative' && negativeCounterStyle};
`;

export const Label = styled.span`
  width: 80px;
  height: 30px;
  font-family: Relative;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8c8c8c;
`;

export const ModalHeader = styled.h2`
  text-align: center;
  padding: 16px 0;
  margin: 0px !important;
`;

export const ModalButtonFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px 0;

  > button {
    margin: 0 4px;
  }
`;

export const ModalContentContainer = styled.div`
  background-color: #f7f7f7;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
`;

export const StyledUserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin-left: 4px;
`;

export const StyledName = styled(Table.Cell)`
  &&& {
    padding-bottom: 0;
    padding-left: 0;
    border-color: transparent;
  }

  font-size: 14px;
  font-weight: bold;
`;

export const StyledUserName = styled(Table.Cell)`
  &&& {
    padding-top: 0;
    padding-left: 0;
    border-color: transparent;
  }
  font-size: 10px;
`;

// export const StyledRow = styled(Table.Row)`
//   border-radius: 15px;
//   border-bottom: 1px solid rgba(34, 36, 38, 0.1);
//   cursor: pointer;
// `;

export const StyledRightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const NoMarginIcon = styled(Icon)`
  margin: 0px !important;
  padding-top: 1px !important;
`;

export const HeaderButtons = styled(RightAlignedButtons)`
  padding-right: 16px;
`;

export const TableHeaderContainer = styled.div<{ contentSticky: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  background-color: white;
  padding-bottom: ${({ contentSticky }) => (contentSticky ? 8 : 0)}px;
  padding-top: ${({ contentSticky }) => (contentSticky ? 24 : 0)}px;
`;
