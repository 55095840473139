import { Button } from '@dev/base-web/dist/view/components/global/button';
import Modal from '@dev/base-web/dist/view/components/modal/modal';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { DefaultRoles } from '@dev/base-web/dist/model/domain/user_privilege/role_permission';

const ModalContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
`;

const RoleNameLabelContainer = styled.div`
  margin-bottom: 8px;
  color: #8c8c8c;
  font-family: Relative;
  font-size: 12px;
`;

export interface DeleteRoleModalProps {
  roleNames: string[];
  onModalOkPressed: (roleName: string) => void;
}

const DeleteRoleModal: React.FC<DeleteRoleModalProps> = ({
  roleNames,
  onModalOkPressed,
}) => {
  const intl = useIntl();

  const [selectedRole, setSelectedRole] = useState<string | undefined>(
    undefined
  );

  const options = useMemo(() => {
    return roleNames
      .filter((roleName) => !DefaultRoles.includes(roleName))
      .map((roleName) => ({ text: roleName, value: roleName }));
  }, [roleNames]);

  const onChange = (
    _event: React.SyntheticEvent<HTMLInputElement>,
    data: DropdownProps
  ) => setSelectedRole(data.value as string);

  return (
    <Modal
      headerLabel={'delete_role'}
      onModalOkPressed={() => onModalOkPressed(selectedRole!)}
      okLabel={'ok_button'}
      okDisabled={!selectedRole}
      cancelLabel={'cancel_button'}
      triggerButton={
        <Button
          type="secondary"
          icon="delete"
          label="delete_role"
          onClick={() => setSelectedRole(undefined)}
        />
      }
    >
      <ModalContainer>
        <RoleNameLabelContainer>
          <FormattedMessage id={'select_role_to_delete'} />
        </RoleNameLabelContainer>
        <StyledDropDown
          selection
          disabled={!options.length}
          value={selectedRole}
          options={options}
          onChange={onChange}
          placeholder={intl.formatMessage({ id: 'select_role' })}
        />
      </ModalContainer>
    </Modal>
  );
};

export default DeleteRoleModal;
