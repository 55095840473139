import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { ManufacturingEntitySummaryInterval } from '../../domain/report/manufacturing_entity_summary_interval';
import { DataApiSortConfig } from '@dev/base-web/dist/model/api/common/data_api_sort_config';

const URL = `/areas/childs/results/interval/search`;

export default class KPIOverTimeApi extends BaseDataApi<ManufacturingEntitySummaryInterval> {
  async getKPIResults(
    token: string,
    signal: AbortSignal,
    page: number,
    groupBy: string,
    filters: readonly FilterData[] = [],
    sortConfig?: DataApiSortConfig
  ) {
    const response =
      await this.fetchPagesUntilTheGivenPageOfDataWithSearchAndSort(
        URL,
        token,
        signal,
        page,
        filters,
        sortConfig,
        [groupBy]
      );

    return {
      results: response.content,
      hasMoreResults: !response.last,
    };
  }
}
