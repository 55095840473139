import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import { useState } from 'react';
import _ from 'lodash';
import {
  FilterTag,
  FilterTagMetaData,
} from '../../../../model/domain/filter_tag';

export default function useFilterState<T extends string | number | boolean>(
  filterKey: string,
  filterTagData: FilterTagMetaData,
  filterLabelFunction: (value: T, filterContext?: any) => string,
  localFilters: FilterTag[],
  setLocalFilters: (filters: FilterTag[]) => void,
  externalFilters: FilterTag[],
  filterOperation: FilterOperation = FilterOperation.EQ_OPERATOR
): [T | undefined, (value: T | undefined, filterContext?: any) => void] {
  const externalFilter = externalFilters.find(
    (filter) =>
      filter.filterData.key === filterKey &&
      filter.filterData.operation === filterOperation
  );
  const [filterValue, setFilterValue] = useState<T | undefined>(
    externalFilter ? (externalFilter.filterData.value as T) : undefined
  );

  const setValueWithContext = (value: T | undefined, filterContext?: any) => {
    _.remove(
      localFilters,
      (filter) =>
        filter.filterData.key === filterKey &&
        filter.filterData.operation === filterOperation
    );
    if (value || value === false) {
      localFilters.push({
        filterData: new FilterData(filterKey, filterOperation, value),
        label: filterLabelFunction(value, filterContext),
        filterContext,
        ...filterTagData,
      });
    }
    setFilterValue(value);
    setLocalFilters([...localFilters]);
  };

  return [filterValue, setValueWithContext];
}
