import { WrappedComponentProps } from 'react-intl';
import ShannonConfig from '@dev/base-web/dist/model/domain/authorization/shannon';
import Event, {
  EventDefinitionStatus,
} from '../../model/domain/event/event_definition';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { ErrorInterface } from '@dev/base-web/dist/model/api/error/error_interface';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import SearchData from '@dev/base-web/dist/model/api/common/search_data';
import {
  LoadingMetaState,
  OperationMetaState,
} from '@dev/base-web/dist/model/redux/helpers/interfaces';
import { ConfigValue } from '@dev/base-web/dist/model/domain/configuration/configuration';
import { AsyncJobResponseDTO } from '@dev/base-web/dist/model/domain/jobs/AsyncJobResponseDTO';
import { AsyncJobStatusDTO } from '@dev/base-web/dist/model/domain/jobs/AsyncJobStatusDTO';
import { EventDefinitionValidationResponse } from '@/model/domain/event/event_definition_import.ts';
import { ImportColMap } from '@dev/base-web/dist/model/api/import/import_api';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';

export const EVENTS_ROUTE_KEY = 'events';

export const CONFIG = ShannonConfig.Events;

export interface EventsStoreProps {
  readonly events: Event[];
  readonly eventsTotalElements?: number;
  readonly eventImport: AsyncJobResponseDTO | null;
  readonly eventImportInProgress: boolean;
  readonly eventImportError?: ErrorInterface;
  readonly eventImportStatus: AsyncJobStatusDTO<EventDefinitionValidationResponse> | null;
  readonly eventImportStatusInProgress: boolean;
  readonly eventImportStatusError?: ErrorInterface;
  readonly eventImportConfirmation: AsyncJobResponseDTO | null;
  readonly eventImportConfirmationInProgress: boolean;
  readonly eventImportConfirmationError?: ErrorInterface;
  readonly eventOccurrenceCountDuration?: ConfigValue;
  readonly moreEventsCanBeLoaded: boolean;
  readonly eventLoadingInProgress: boolean;
  readonly multiEventUpdate: OperationMetaState;
  readonly previousEventSearchData?: SearchData;
  readonly eventLoadingError?: ErrorInterface;
  readonly manufacturingEntities: readonly ManufacturingEntity[];
  readonly manufacturingEntitiesMeta: LoadingMetaState;
  readonly activeTab: number;
  readonly archiveEventsError: ErrorInterface | undefined;
  readonly archiveEventsLoading: boolean;
  readonly showHierarchy?: boolean | null;
}

export interface EventDispatchProps {
  readonly getEvents: (
    status: EventDefinitionStatus,
    page: number,
    filters: readonly FilterData[],
    sortingKey?: string,
    sorting?: SortingDirection,
    loadAllPagesUntilTheGivenOne?: boolean
  ) => void;
  readonly archiveEvents: (ids: string[], isHidden: boolean) => void;
  readonly getAllManufacturingEntities: () => void;
  readonly setActiveTab: (tab: number) => void;
  readonly multiUpdateEvents: (ids: string[], body: any) => void;

  readonly resetImport: () => void;
  readonly resetImportStatus: () => void;
  readonly startImport: (file: File, colMap: ImportColMap) => void;
  readonly confirmImport: (
    importId: string,
    save?: boolean,
    overwriteExistings?: boolean
  ) => void;
  readonly checkImportStatus: (importId: string) => void;
}

export type EventsProps = EventsStoreProps &
  EventDispatchProps &
  WrappedComponentProps;
