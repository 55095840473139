import {
  instructionApiForDifferentLanguages,
  instructionSuggestionApi,
  instructionDefinitionApi,
} from '../../api';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import {
  listFetchingWithAbort,
  VoidThunk,
} from '@dev/base-web/dist/model/redux/helpers/thunks';
import { Dispatch } from '../store';
import { userApi } from '../../api/user/user_api';
import { StepDTO } from '../../domain/instruction/step';
import {
  ActionDefinitionHistoryDTO,
  BasicActionDefinitionDTO,
} from '../../domain/instruction/instruction';
import {
  DataApiSortConfig,
  SortingDirection,
} from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import {
  removeDeprecatedNameFields,
  sortAllInstructionStepsByNumber,
  sortAllTranslationFieldsByLang,
} from '@/model/domain/instruction/helper.ts';
import {
  instructionTranslationToInternalModel,
  removeDeprecatedNameTranslationFields,
  toTranslationRequest,
} from '@/model/domain/instruction/translation_helper.ts';
import {
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import { ContextCategory } from '@/model/domain/instruction/suggestion.ts';
import { RootReducerInterface } from '../interfaces';
import { Actions } from './actions';
import { OperationType } from '@dev/base-web/dist/model/redux/helpers/interfaces';

class RetriableError extends Error {}
class FatalError extends Error {}

export const getInstruction =
  (id: string, revision?: number, language?: string | null) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const {
      authenticationState: {
        authentication: { token },
      },
    } = getState();
    const { selectedTenantId } =
      getState().userTenantState.selectedTenantIdState;

    if (language === null) language = undefined;

    dispatch(Actions.instruction.meta.startLoading());
    try {
      let instruction;
      if (language) {
        instruction = await instructionApiForDifferentLanguages.getInstruction(
          id,
          token.accessToken,
          revision,
          language,
          selectedTenantId
        );
      } else {
        instruction = await instructionDefinitionApi.getInstruction(
          id,
          token.accessToken,
          revision,
          language
        );
      }

      const instructionWithRemovedFields = sortAllInstructionStepsByNumber(
        sortAllTranslationFieldsByLang(removeDeprecatedNameFields(instruction))
      );

      dispatch(
        Actions.instruction.loadingItemSuccessful(instructionWithRemovedFields)
      );
      dispatch(Actions.instruction.meta.endLoading());
    } catch (error) {
      dispatch(Actions.instruction.meta.endLoading());
      dispatch(Actions.instruction.meta.loadingFailed({ error }));
    }
  };

export const getInstructionHistory = (id: string) =>
  listFetchingWithAbort<ActionDefinitionHistoryDTO, RootReducerInterface>(
    (accessToken, abortController) =>
      instructionDefinitionApi.getInstructionHistory(
        id,
        accessToken,
        abortController.signal
      ),
    (state) => state.data.instructionDefinitionState.instructionVersions,
    Actions.instructionVersions
  );

export const archiveInstructions =
  (ids: string[], isHidden: boolean) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const token =
      getState().authenticationState.authentication.token.accessToken;

    dispatch(
      Actions.instructionsOperation.meta.startOperation({
        operation: OperationType.UPDATE,
      })
    );
    try {
      await instructionDefinitionApi.archiveInstructions(token, ids, isHidden);
      dispatch(Actions.instructionsOperation.meta.operationSucceeded());
    } catch (error) {
      dispatch(Actions.instructionsOperation.meta.operationFailed({ error }));
    }
  };

export const deleteInstructions =
  (ids: string[]) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const token =
      getState().authenticationState.authentication.token.accessToken;

    dispatch(
      Actions.instructionsOperation.meta.startOperation({
        operation: OperationType.DELETE,
      })
    );
    try {
      await instructionDefinitionApi.deleteInstructions(token, ids);
      dispatch(Actions.instructionsOperation.meta.operationSucceeded());
    } catch (error) {
      dispatch(Actions.instructionsOperation.meta.operationFailed({ error }));
    }
  };

export const createGetInstructionsThunks =
  (actionActions = Actions.instructions) =>
  (
    isApproved: boolean,
    isArchived: boolean,
    page: number,
    filters: readonly FilterData[],
    sortingKey = 'createdTimestamp',
    sorting = SortingDirection.DESCENDING,
    loadAllPagesUntilTheGivenOne?: boolean
  ) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;

    dispatch(actionActions.meta.startLoading());

    let sortConfig = undefined;
    if (sortingKey && sorting) {
      sortConfig = new DataApiSortConfig(sortingKey, sorting);
    }
    try {
      if (page <= 0 || loadAllPagesUntilTheGivenOne) {
        dispatch(
          actionActions.resetList({
            result: { results: [], hasMoreResults: true },
          })
        );
      }

      const result = await instructionDefinitionApi.getInstructions(
        isApproved,
        isArchived,
        page,
        filters,
        token.accessToken,
        sortConfig,
        loadAllPagesUntilTheGivenOne
      );
      if (page <= 0 || loadAllPagesUntilTheGivenOne) {
        dispatch(actionActions.resetList({ result }));
      } else {
        dispatch(actionActions.loadingListSuccessful({ result }));
      }
      dispatch(actionActions.meta.endLoading({ filters, page, sortConfig }));
    } catch (error) {
      dispatch(actionActions.meta.loadingFailed({ error }));
      dispatch(actionActions.meta.endLoading());
    }
  };

export const removeCommentFromInstruction =
  (commentId: string, instructionId: string) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.deleteComment.meta.startOperation({
        operation: OperationType.DELETE,
      })
    );
    try {
      await instructionDefinitionApi.deleteCommentByUrl(
        instructionId,
        commentId,
        token.accessToken
      );
      dispatch(Actions.deleteComment.meta.operationSucceeded());
    } catch (error) {
      dispatch(Actions.deleteComment.meta.operationFailed({ error }));
    }
  };

export const createInstruction =
  (data: BasicActionDefinitionDTO, language?: string | null) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    const { selectedTenantId } =
      getState().userTenantState.selectedTenantIdState;
    dispatch(
      Actions.updateInstruction.meta.startOperation({
        operation: OperationType.CREATE,
      })
    );
    dispatch(Actions.instruction.meta.startLoading());

    if (language === null) language = getState().localisationState.locale;

    try {
      let response;

      if (language) {
        response = await instructionApiForDifferentLanguages.createInstruction(
          data,
          token.accessToken,
          language,
          selectedTenantId
        );
      } else {
        //TODO: check if version is present after save
        response = await instructionDefinitionApi.createInstruction(
          data,
          token.accessToken,
          'en'
        );
      }

      const instructionWithRemovedFields = sortAllInstructionStepsByNumber(
        sortAllTranslationFieldsByLang(removeDeprecatedNameFields(response))
      );

      dispatch(
        Actions.instruction.loadingItemSuccessful(instructionWithRemovedFields)
      );
      dispatch(Actions.updateInstruction.meta.operationSucceeded());
      dispatch(Actions.instruction.meta.endLoading());
    } catch (error) {
      dispatch(Actions.updateInstruction.meta.operationFailed({ error }));
    }
  };

function recursivelyAlterEmptyMediaFilter(
  steps: readonly StepDTO[]
): StepDTO[] {
  return steps.map((s) => {
    return {
      ...s,
      media: !s.media ? { full: '', thumb: '', fileType: 'PICTURE' } : s.media,
      decisionOptions: s.decisionOptions.map((d) => {
        return { ...d, steps: recursivelyAlterEmptyMediaFilter(d.steps) };
      }),
    };
  });
}

function addEmptyMediaField(data: BasicActionDefinitionDTO) {
  return { ...data, steps: recursivelyAlterEmptyMediaFilter(data.steps) };
}

//TODO: remove any
export const updateInstruction =
  (id: string, data: BasicActionDefinitionDTO, language?: string | null) =>
  async (dispatch: any, getState: () => RootReducerInterface) => {
    if (language === null) language = undefined;

    const { token } = getState().authenticationState.authentication;
    const { selectedTenantId } =
      getState().userTenantState.selectedTenantIdState;
    dispatch(
      Actions.updateInstruction.meta.startOperation({
        operation: OperationType.UPDATE,
      })
    );

    const localData = addEmptyMediaField(data);

    try {
      let response;

      if (language) {
        response = await instructionApiForDifferentLanguages.updateInstruction(
          id,
          localData,
          token.accessToken,
          language,
          selectedTenantId
        );
      } else {
        response = await instructionDefinitionApi.updateInstruction(
          id,
          localData,
          token.accessToken
        );
      }

      const instructionWithRemovedFields = sortAllInstructionStepsByNumber(
        sortAllTranslationFieldsByLang(removeDeprecatedNameFields(response))
      );

      dispatch(
        Actions.instruction.loadingItemSuccessful(instructionWithRemovedFields)
      );
      dispatch(Actions.updateInstruction.meta.operationSucceeded());
    } catch (error) {
      dispatch(Actions.updateInstruction.meta.operationFailed({ error }));
    }
  };

export const duplicateInstruction =
  (id: string, language?: string | null) =>
  async (dispatch: any, getState: () => RootReducerInterface) => {
    if (language === null) language = undefined;

    const { token } = getState().authenticationState.authentication;
    const { selectedTenantId } =
      getState().userTenantState.selectedTenantIdState;
    dispatch(
      Actions.updateInstruction.meta.startOperation({
        operation: OperationType.UPDATE,
      })
    );

    try {
      let response;
      if (language) {
        response =
          await instructionApiForDifferentLanguages.duplicateInstruction(
            id,
            token.accessToken,
            language,
            selectedTenantId
          );
      } else {
        response = await instructionDefinitionApi.duplicateInstruction(
          id,
          token.accessToken
        );
      }

      const instructionWithRemovedFields = sortAllInstructionStepsByNumber(
        sortAllTranslationFieldsByLang(removeDeprecatedNameFields(response))
      );

      dispatch(
        Actions.instruction.loadingItemSuccessful(instructionWithRemovedFields)
      );
      dispatch(Actions.updateInstruction.meta.operationSucceeded());
    } catch (error) {
      dispatch(Actions.updateInstruction.meta.operationFailed({ error }));
    }
  };

export const approveInstruction =
  (id: string, revision: number, approve?: boolean, language?: string | null) =>
  async (dispatch: any, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;

    const { selectedTenantId } =
      getState().userTenantState.selectedTenantIdState;

    dispatch(
      Actions.approveInstruction.meta.startOperation({
        operation:
          approve === true ? OperationType.UPDATE : OperationType.DELETE,
      })
    );
    try {
      let response;
      if (language) {
        response = await instructionApiForDifferentLanguages.approveInstruction(
          id,
          revision,
          token.accessToken,
          approve,
          language,
          selectedTenantId
        );
      } else {
        //TODO: return directly?
        response = await instructionDefinitionApi.approveInstruction(
          id,
          revision,
          token.accessToken,
          approve
        );
      }

      dispatch(getInstruction(response.id, revision, language));

      dispatch(Actions.approveInstruction.meta.operationSucceeded());
    } catch (error) {
      dispatch(Actions.approveInstruction.meta.operationFailed({ error }));
    }
  };

export const removeEventFromInstruction =
  (id: string, eventId: string) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.addEventsToInstruction.meta.startOperation({
        operation: OperationType.DELETE,
      })
    );
    try {
      await instructionDefinitionApi.removeEventFromInstruction(
        id,
        eventId,
        token.accessToken
      );
      dispatch(Actions.addEventsToInstruction.meta.operationSucceeded());
      // dispatch(
      //   getInstruction(id, undefined, getState().data.actionState.selectedLanguage)
      // );
    } catch (error) {
      dispatch(Actions.addEventsToInstruction.meta.operationFailed({ error }));
    }
  };

export const addEventsToInstruction =
  (id: string, eventIds: string[]) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.addEventsToInstruction.meta.startOperation({
        operation: OperationType.UPDATE,
      })
    );
    try {
      await instructionDefinitionApi.addEventsToInstruction(
        id,
        eventIds,
        token.accessToken
      );
      dispatch(Actions.addEventsToInstruction.meta.operationSucceeded());
      // dispatch(
      //   getInstruction(id, undefined, getState().data.actionState.selectedLanguage)
      // );
    } catch (error) {
      dispatch(Actions.addEventsToInstruction.meta.operationFailed({ error }));
    }
  };

export const setActiveTab = (tab: number) => (dispatch: Dispatch) => {
  dispatch(Actions.setActiveTab(tab));
};

export const setSelectedInstructionLanguage =
  (lang: string) => (dispatch: Dispatch) => {
    dispatch(Actions.setSelectedInstructionLanguage(lang.toUpperCase()));
  };

export const translateInstruction =
  (
    instructionId: string,
    instruction: BasicActionDefinitionDTO,
    fromLanguage: string,
    translateTo: readonly string[]
  ) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    const { selectedTenantId } =
      getState().userTenantState.selectedTenantIdState;

    dispatch(Actions.translateInstruction.meta.startLoading());

    const translationRequest = toTranslationRequest(instruction, fromLanguage);

    try {
      if (translationRequest) {
        const response =
          await instructionApiForDifferentLanguages.translateInstruction(
            token.accessToken,
            instructionId,
            translationRequest,
            fromLanguage,
            translateTo,
            instructionApiForDifferentLanguages,
            selectedTenantId
          );

        const instructionWithRemovedFields =
          removeDeprecatedNameTranslationFields(response);

        const transformed = instructionTranslationToInternalModel(
          instructionWithRemovedFields
        );

        dispatch(
          Actions.translateInstruction.loadingItemSuccessful(transformed)
        );
      }
    } catch (error) {
      dispatch(Actions.translateInstruction.meta.loadingFailed({ error }));
    }

    dispatch(Actions.translateInstruction.meta.endLoading());
  };

//TODO: remove any
export const updateTranslation =
  (data: any, language?: string | null) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const id = data.id;

    if (language === null) language = undefined;

    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.updateInstruction.meta.startOperation({
        operation: OperationType.UPDATE,
      })
    );

    try {
      if (language) {
        await instructionApiForDifferentLanguages.updateInstruction(
          id,
          data,
          token.accessToken,
          language
        );
      } else {
        await instructionDefinitionApi.updateInstruction(
          id,
          data,
          token.accessToken
        );
      }

      dispatch(Actions.updateInstruction.meta.operationSucceeded());
    } catch (error) {
      dispatch(Actions.updateInstruction.meta.operationFailed({ error }));
    }
  };

export const cleanInstruction =
  (): VoidThunk<RootReducerInterface> => (dispatch) =>
    dispatch(Actions.instruction.reset());

export const requestReview =
  (id: string, reviewerId: string) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.requestReview.meta.startOperation({
        operation: OperationType.CREATE,
      })
    );
    try {
      await instructionDefinitionApi.requestReview(
        token.accessToken,
        id,
        reviewerId
      );
      dispatch(Actions.requestReview.meta.operationSucceeded());
      // dispatch(
      //   getInstruction(id, undefined, getState().data.actionState.selectedLanguage)
      // );
    } catch (error) {
      dispatch(Actions.requestReview.meta.operationFailed({ error }));
    }
  };

export const checkIfInstructionReviewRequestExists =
  (id: string) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(Actions.reviewStatus.meta.startLoading());
    try {
      const result =
        await instructionDefinitionApi.checkIfInstructionReviewRequestExists(
          token.accessToken,
          id
        );

      dispatch(Actions.reviewStatus.loadingItemSuccessful(result));
      dispatch(Actions.reviewStatus.meta.endLoading());
    } catch (error) {
      dispatch(Actions.reviewStatus.meta.endLoading());
      dispatch(Actions.reviewStatus.meta.loadingFailed({ error }));
    }
  };

export const getAllApprovalUsers =
  (
    filters: readonly FilterData[],
    sortingKey?: string,
    sorting?: SortingDirection
  ) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const {
      authenticationState: {
        authentication: { token },
      },
    } = getState();
    let sortConfig = undefined;
    if (sortingKey && sorting) {
      sortConfig = new DataApiSortConfig(sortingKey, sorting);
    }

    const abortController = new AbortController();
    dispatch(
      Actions.instructionApprovalUsers.meta.startLoading({ abortController })
    );

    try {
      const result = await userApi.getAllUsers(
        filters,
        token.accessToken,
        abortController.signal,
        sortConfig
      );

      dispatch(Actions.instructionApprovalUsers.loadingListSuccessful(result));

      dispatch(Actions.instructionApprovalUsers.meta.endLoading());
    } catch (error) {
      dispatch(Actions.instructionApprovalUsers.meta.endLoading());
      dispatch(Actions.instructionApprovalUsers.meta.loadingFailed({ error }));
    }
  };

export const getCachedInstructionSuggestion =
  (suggestionId: string) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;

    dispatch(Actions.suggestedInstruction.meta.startLoading());
    dispatch(Actions.suggestedInstruction.reset());

    try {
      const result =
        await instructionSuggestionApi.getCachedInstructionSuggestion(
          suggestionId,
          token.accessToken
        );
      dispatch(
        Actions.suggestedInstruction.loadingItemSuccessful({
          id: suggestionId,
          suggestion: result.map((r) => r.text).join(''),
        })
      );
      dispatch(Actions.suggestedInstruction.meta.endLoading());
    } catch (error) {
      dispatch(Actions.suggestedInstruction.meta.loadingFailed({ error }));

      dispatch(Actions.suggestedInstruction.meta.endLoading());
    }
  };

export const getPlainInstructionSuggestion =
  (
    eventDefinitionId: string,
    categories: readonly ContextCategory[],
    prompt?: string,
    useCache?: boolean
  ) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;

    dispatch(Actions.suggestedInstruction.meta.startLoading());
    dispatch(Actions.suggestedInstruction.reset());

    try {
      const result = await instructionSuggestionApi.getInstructionSuggestion(
        { eventDefinitionId, categories, prompt, useCache },
        token.accessToken
      );
      dispatch(
        Actions.suggestedInstruction.loadingItemSuccessful({
          id: '',
          suggestion: result.map((r) => r.text).join(''),
        })
      );
      dispatch(Actions.suggestedInstruction.meta.endLoading());
    } catch (error) {
      dispatch(Actions.suggestedInstruction.meta.loadingFailed({ error }));

      dispatch(Actions.suggestedInstruction.meta.endLoading());
    }
  };

export const getInstructionSuggestionStream =
  (
    eventDefinitionId: string,
    categories: readonly ContextCategory[],
    prompt?: string,
    useCache?: boolean,
    selectedLanguage?: string
  ) =>
  (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;

    dispatch(Actions.suggestedInstruction.meta.startLoading());

    dispatch(Actions.suggestedInstruction.reset());

    const { selectedTenantId } =
      getState().userTenantState.selectedTenantIdState;
    const { locale } = getState().localisationState;

    let isDispatching = false;
    let messageBuffer = '';

    fetchEventSource(
      `/shannon-api/${selectedTenantId}/${
        selectedLanguage?.toLowerCase() || locale
      }/action/definition/suggest/stream`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token.accessToken,
        },
        body: JSON.stringify({
          eventDefinitionId: eventDefinitionId,
          categories: categories,
          prompt: prompt,
          useCache: useCache,
        }),
        // eslint-disable-next-line @typescript-eslint/require-await
        async onopen(response) {
          if (
            response.ok &&
            response.headers.get('content-type') === EventStreamContentType
          ) {
            return; // everything's good
          } else if (
            response.status >= 400 &&
            response.status < 500 &&
            response.status !== 429
          ) {
            // client-side errors are usually non-retriable:
            throw new FatalError();
          } else {
            throw new RetriableError();
          }
        },
        onmessage(ev) {
          // if the server emits an error message, throw an exception
          // so it gets handled by the onerror callback below:
          if (ev.event === 'FatalError') {
            throw new FatalError(ev.data);
          }

          const id = ev.id;
          const newData = JSON.parse(ev.data);
          const text = newData.length > 0 ? newData[0].message.content : '';
          messageBuffer += text;

          if (!isDispatching) {
            isDispatching = true;
            setTimeout(() => {
              dispatch(
                Actions.suggestedInstruction.loadingItemSuccessful({
                  id: id,
                  suggestion: messageBuffer,
                })
              );
              isDispatching = false;
            }, 400);
          }
        },
        onclose() {
          // if the server closes the connection unexpectedly, retry:
          throw new RetriableError();
        },
        onerror(err) {
          throw err;
        },
        //signal: ctrl.signal,
      }
    )
      .then(() => {
        dispatch(Actions.suggestedInstruction.meta.endLoading());
      })
      .catch((error) => {
        dispatch(Actions.suggestedInstruction.meta.endLoading());
        if (!(error instanceof RetriableError))
          dispatch(Actions.suggestedInstruction.meta.loadingFailed({ error }));
      });
  };
