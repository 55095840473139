import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import styled from 'styled-components';
import UserAvatar from '@dev/base-web/dist/view/components/global/dummy_avatar';
import React from 'react';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';

interface UserTagProps {
  readonly user: UserDetails;
  readonly className?: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 200px;
  height: 40px;
  padding: 8px 7px 8px 9px;
  margin-bottom: 8px;
  border-radius: 8px;
  border: solid 1px rgba(36, 36, 36, 0.05);
  background-color: ${({ theme }) => theme.colors.background};
  //background-color: rgba(255, 255, 255, 0.5);
  color: ${({ theme }) => theme.colors.black};
`;

export const UserTag: React.FC<UserTagProps> = ({ user, className }) => {
  const fullName = `${user.firstName || '-'} ${user.lastName || '-'}`;
  let userImage: string | undefined;
  if (user.pictures && user.pictures.thumb) {
    userImage = user.pictures.thumb;
  }

  return (
    <Container className={className}>
      <div style={{ flexShrink: 0 }}>
        <UserAvatar name={fullName} src={userImage} size="24px" />
      </div>
      <TextWithEllipsisAndTooltip text={fullName} style={{ paddingLeft: 8 }} />
      {/*<Icon name={'cross'} color={'#c6c6c6'} size={16} />*/}
    </Container>
  );
};
