import {
  ActionDefinitionHistoryDTO,
  BasicActionDefinitionDTO,
} from '@/model/domain/instruction/instruction.ts';
import { EventDefinition } from '@/model/domain/event/event_definition.ts';
import { BasicEvent } from '@/model/redux/actions/interface.ts';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import ConfigurationParameters from '@dev/base-web/dist/model/domain/configuration/configuration';

export const checkAndGetNewerVersion = (
  currentRevision: number,
  pendingUpdates: ActionDefinitionHistoryDTO[],
  versions: ActionDefinitionHistoryDTO[]
) => {
  const currVersionTimestamp = versions.find(
    (v) => v.revisionNumber === currentRevision
  )?.timestamp;

  if (
    currVersionTimestamp &&
    pendingUpdates &&
    pendingUpdates.length > 0 &&
    pendingUpdates[0].timestamp > currVersionTimestamp
  ) {
    return pendingUpdates[0].id;
  }

  return undefined;
};

export const setEventIfListEmpty = (
  eventId?: string,
  eventDefinition?: EventDefinition | null,
  localInstruction?: BasicActionDefinitionDTO
): BasicActionDefinitionDTO | undefined => {
  if (
    eventDefinition &&
    eventId === eventDefinition.id &&
    localInstruction &&
    !localInstruction?.events.length
  ) {
    const manufacturingEntities = eventDefinition.rules
      .map((r) => r.manufacturingEntity)
      .filter((m): m is ManufacturingEntity => Boolean(m));

    const event = {
      id: eventDefinition.id,
      name: eventDefinition.name,
      type: eventDefinition.type,
      manufacturingEntities: manufacturingEntities,
    } as BasicEvent;

    return {
      ...localInstruction,
      events: eventDefinition?.id === eventId ? [event] : [{ id: eventId }],
    };
  }
  return undefined;
};

export const getTranslationWarning = (
  config: ConfigurationParameters | null,
  locale: string
) => {
  const translationServiceWarningTexts = config?.translationServiceWarningActive
    .value
    ? config?.translationServiceWarningTexts.value
    : undefined;

  return translationServiceWarningTexts
    ? translationServiceWarningTexts[locale] ??
        translationServiceWarningTexts['en'] ??
        Object.values(translationServiceWarningTexts).pop()
    : undefined;
};
