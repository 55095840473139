export interface GlossaryDetail {
  readonly glossaryId: string;
  readonly name: string;
  readonly sourceLanguage: string;
  readonly targetLanguage: string;
  readonly entryCount: number;
  readonly ready: boolean;
}

export interface GlossaryEntry extends GlossaryDetail {
  readonly entries: string;
}

export interface GlossaryCreate {
  readonly id?: string;
  readonly sourceLanguage: string;
  readonly targetLanguage: string;
  readonly entries: string;
}

export interface GlossaryTransposedEntry {
  readonly glossaryId: string;
  readonly name: string;
  readonly sourceLanguage: string;
  readonly targetLanguage: string;
  readonly key: string;
  readonly translation: string;
}

export interface KeyValue {
  readonly language: string;
  readonly value?: string;
}

export interface GlossaryGroupedEntry {
  readonly id: number;
  readonly sourceLanguage: string;
  readonly key: string;
  readonly translations: KeyValue[];
  readonly isKeyDuplicate?: boolean;
}

export const extractEntriesFromGlossary = (
  g: GlossaryEntry
): GlossaryTransposedEntry[] => {
  const entries = [];

  for (const e of g.entries.split('\n')) {
    const pair = e.split('\t');
    entries.push(
      ...[
        {
          glossaryId: g.glossaryId,
          name: g.name,
          sourceLanguage: g.sourceLanguage,
          targetLanguage: g.targetLanguage,
          key: pair[0],
          translation: pair[1],
        },
      ]
    );
  }

  return entries;
};

export const groupGlossaryEntriesBySourceAndKey = (
  entries: GlossaryTransposedEntry[]
): GlossaryGroupedEntry[] => {
  let i = 0;

  return entries.reduce((result, item) => {
    const existingGroup = result.find(
      (group) =>
        group.sourceLanguage === item.sourceLanguage && group.key === item.key
    );

    if (existingGroup) {
      existingGroup.translations.push({
        language: item.targetLanguage,
        value: item.translation,
      });
    } else {
      const newGroup: GlossaryGroupedEntry = {
        id: i,
        sourceLanguage: item.sourceLanguage,
        key: item.key,
        translations: [
          { language: item.targetLanguage, value: item.translation },
        ],
      };
      i++;

      result.push(newGroup);
    }

    return result;
  }, [] as GlossaryGroupedEntry[]);
};

export const groupGlossaryEntriesBySourceAndTarget = (
  entries: GlossaryGroupedEntry[]
): GlossaryCreate[] => {
  return entries.reduce((result, item) => {
    for (const translation of item.translations) {
      const existingGlossary = result.find(
        (g) =>
          g.sourceLanguage === item.sourceLanguage &&
          g.targetLanguage === translation.language
      );

      if (existingGlossary && translation.value) {
        result = [
          ...result.filter(
            (g) =>
              !(
                g.sourceLanguage === item.sourceLanguage &&
                g.targetLanguage === translation.language
              )
          ),
          {
            ...existingGlossary,
            entries: existingGlossary.entries.concat(
              `\n${item.key}\t${translation.value}`
            ),
          },
        ];
      } else {
        result.push({
          sourceLanguage: item.sourceLanguage,
          targetLanguage: translation.language,
          entries: `${item.key}\t${translation.value}`,
        });
      }
    }

    return result;
  }, [] as GlossaryCreate[]);
};
