import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { thunk } from 'redux-thunk';
import reducer from './reducers';
import { authenticationMiddleware } from './authentication/authentication_middleware';
import { userApi } from '../api/user/user_api';
import { userDataRefreshMiddleware } from './user/user_data_refresh_middleware_factory';
import { requestMiddleware } from './common/request_middleware';

const store = configureStore({
  reducer,
  devTools: import.meta.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // would be better to enable, but still produces too many false positives:
      serializableCheck: false,
      // serializableCheck: {
      //   ignoredActions: [
      //     // redux-persist actions aren't serializable
      //     // it's fine since they won't be persisted anyway
      //     FLUSH,
      //     REHYDRATE,
      //     PAUSE,
      //     PERSIST,
      //     PURGE,
      //     REGISTER,
      //   ],
      //   ignoredActionPaths: [
      //     // AbortController object instances aren't serializable
      //     // should be fine to have them as payloads as long as we don't persist them
      //     'payload.abortController',
      //   ],
      //   ignorePaths: {
      //     ignoredPaths: [
      //       // AbortController object instances aren't serializable
      //       // should be fine to have them in the store as long as we don't persist them
      //       /\.meta\.abortController$/,
      //       // redux-toastr stuff uses unserializable state unfortunately
      //       /^toastr\./,
      //       // Axios instances aren't serializable
      //       /^configState.apiClient\./,
      //     ],
      //   },
      // },

      // turn it off for now, it slowes down the runtime in dev mode
      immutableCheck: false,
      // immutableCheck: {
      //   ignoredPaths: [
      //     // AbortController object instances aren't immutable,
      //     // should be fine, inner state changes doesn't affect the app
      //     /\.meta\.abortController$/,
      //     // redux-toastr stuff uses immutable state unfortunately
      //     /^toastr\./,
      //     // Axios instances aren't immutable
      //     /^configState.apiClient\./,
      //   ],
      //   // the check on the current project takes longer than the default timeout and spams the dev console with warnings
      //   warnAfter: 200,
      // },
    })
      .concat(thunk)
      .concat(authenticationMiddleware)
      .concat(userDataRefreshMiddleware)
      .concat(requestMiddleware),
});

// This is needed to not depend on the store when initializing the auth middleware
// TODO use an axios interceptor to dispatch errors to the store and add the interceptor here
userApi.store = store;

const persistor = persistStore(store);

export type Dispatch = typeof store.dispatch;
export type StoreGetState = ReturnType<typeof store.getState>;

export { store, persistor };
