import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { useEventTypeLook } from '@dev/base-web/dist/view/components/global/common';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const EventTypeIcon: React.FC<{
  type: EventType;
  label?: string;
  title?: string;
  size?: number;
  iconStyle?: CSSProperties;
}> = (props) => {
  const { title, color, icon } = useEventTypeLook(props.type);

  return (
    <Wrapper>
      <Icon
        name={icon}
        color={color}
        size={props.size || 14}
        iconStyle={props.iconStyle}
      />
      {props.label ? title : props.title ? props.title : null}
    </Wrapper>
  );
};
