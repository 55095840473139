import React from 'react';
import {
  StyledRow,
  LeftAlignedTableCell,
  CenterAlignedTableCell,
  StyledCenter,
  CheckBoxCell,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import SimpleKPI from '@dev/base-web/dist/view/components/kpi/simple_kpi';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';
import { ManufacturingEntityType } from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import RawEvent from '../../../../../../model/domain/event/raw_event';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import { millisToDurationFormat } from '@dev/base-web/dist/model/domain/durations/duration';
import { getEventTypeLookForType } from '@dev/base-web/dist/view/components/global/common';
import { Radio } from 'semantic-ui-react';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';

const StyledRadio = styled(Radio)`
  &&&& {
    min-height: 16px;
    font-size: 1rem;
    line-height: 16px;
    min-width: 16px;

    label,
    .box {
      width: 16px !important;
      height: 16px !important;
    }

    .box:before,
    label:before {
      border: solid 1px rgba(0, 0, 0, 0.1);
      width: 16px !important;
      height: 16px !important;
    }

    .box:after,
    label:after {
      border: none;
      background-color: #0da6a0;
      width: 16px !important;
      height: 16px !important;
      font-size: 13px;
    }

    input:checked ~ .box:after,
    input:checked ~ label:after {
      opacity: 1;
      color: white;
      background-color: #0da6a0;
    }
  }
`;

interface RawEventTableRowProps {
  readonly result: RawEvent;
  readonly showManufacturingEntityHierarchy: boolean;
  readonly onRowChecked: (checked: boolean) => void;
  readonly selected: boolean;
}

const RawEventTableRow: React.FC<RawEventTableRowProps> = (props) => {
  const {
    event,
    duration,
    manufacturingEntity,
    eventStart,
    eventEnd,
    isParent,
  } = props.result;

  const { selected, onRowChecked } = props;

  const intl = useIntl();
  const theme = useTheme();
  const { formatDate } = useLocalizedDateFormatters();

  const formattedEventStart = formatDate(eventStart, 'P pp');
  const formattedEnd = formatDate(eventEnd, 'P pp');
  const formattedDuration = millisToDurationFormat(duration);

  const { color, icon } = getEventTypeLookForType(
    event?.eventType || EventType.ERROR,
    intl
  );

  return (
    <StyledRow onClick={() => onRowChecked(!selected)}>
      <CheckBoxCell>
        <StyledRadio
          onChange={() => onRowChecked(!selected)}
          checked={selected}
        />
      </CheckBoxCell>
      <CenterAlignedTableCell>
        <StyledCenter>
          <Icon size={16} color={color} name={icon} />
        </StyledCenter>
      </CenterAlignedTableCell>
      <LeftAlignedTableCell>
        <TextWithEllipsisAndTooltip
          text={
            event && event.name
              ? intl.formatMessage({
                  id: event.name,
                  defaultMessage: event.name,
                })
              : '-'
          }
          lines={2}
          style={{
            fontWeight: 'bold',
          }}
        />
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        <ManufacturingEntityHierarchyElement
          manufacturingEntity={manufacturingEntity}
          hideHiddenTypes
          showShortNames
          hiddenTypes={[
            ManufacturingEntityType.FACTORY,
            ManufacturingEntityType.AREA,
            ManufacturingEntityType.LINE,
          ]}
          showHierarchy={props.showManufacturingEntityHierarchy}
          lines={2}
        />
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        <TextWithEllipsisAndTooltip text={formattedEventStart} lines={2} />
      </LeftAlignedTableCell>
      <CenterAlignedTableCell>
        <SimpleKPI value={formattedDuration} small />
      </CenterAlignedTableCell>
      <LeftAlignedTableCell>
        <TextWithEllipsisAndTooltip text={formattedEnd} lines={2} />
      </LeftAlignedTableCell>
      <CenterAlignedTableCell>
        {isParent ? (
          <Icon name={'checkmark'} color={theme.colors.positive} size={16} />
        ) : (
          <Icon name={'cross'} color={theme.colors.cherryRed} size={16} />
        )}
      </CenterAlignedTableCell>
    </StyledRow>
  );
};

export default RawEventTableRow;
