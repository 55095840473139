import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { OperationType } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import { ActionFeedbackDTO } from '../actions/interface';
import { RootReducerInterface } from '../interfaces';
import { Actions } from './actions';
import { baseApiClient } from '../../api/clients';

export const sendInstructionFeedback =
  (
    eventId: string,
    actionId: string,
    params: ActionFeedbackDTO
  ): ThunkAction<Promise<void>, RootReducerInterface, void, AnyAction> =>
  async (dispatch, getState) => {
    const route = `/event/${eventId}/action/${actionId}/feedback`;

    const { token } = getState().authenticationState.authentication;

    dispatch(
      Actions.sendFeedback.meta.startOperation({
        operation: OperationType.CREATE,
      })
    );
    try {
      await baseApiClient.post(route, params, {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
        },
      });

      dispatch(Actions.sendFeedback.meta.operationSucceeded());
    } catch (error) {
      dispatch(Actions.sendFeedback.meta.operationFailed({ error }));
    }
  };
