import { toastrWarning } from '@dev/base-web/dist/view/helpers/notification_helpers';
import { NoMarginIcon } from '../../StyledComponents';
import theme from '@dev/base-web/dist/view/themes/main';
import DeleteConfirmationModal from '@dev/base-web/dist/view/components/modal/delete_confirmation_modal';
import React from 'react';
import { StyledCancelButton } from './styled_components';
import { Image } from '../../../model/redux/actions/interface';
import { useIntl } from 'react-intl';

interface ImageDeleteButtonProps {
  readonly onDelete: (imgUrl: string) => void;
  readonly item: Image;
}

const ImageDeleteButton = ({ onDelete, item }: ImageDeleteButtonProps) => {
  const intl = useIntl();

  return item.manufacturingEntities && item.manufacturingEntities.length > 0 ? (
    <StyledCancelButton
      onClick={() => {
        toastrWarning(intl.formatMessage({ id: 'image_referenced_error' }));
      }}
    >
      <NoMarginIcon name="cross" size={16} color={theme.colors.black} />
    </StyledCancelButton>
  ) : (
    <DeleteConfirmationModal
      title={
        item.urls.fileType === 'PICTURE'
          ? 'delete_image'
          : item.urls.fileType === 'VIDEO'
          ? 'delete_video'
          : 'delete_pdf'
      }
      confirmationTitle={
        item.urls.fileType === 'PICTURE'
          ? 'delete_image_confirmation'
          : item.urls.fileType === 'VIDEO'
          ? 'delete_video_confirmation'
          : 'delete_pdf_confirmation'
      }
      onDeleteConfirmed={() => onDelete(item.urls.full)}
      triggerButton={
        <StyledCancelButton>
          <NoMarginIcon name="cross" size={16} color={theme.colors.black} />
        </StyledCancelButton>
      }
    />
  );
};

export default ImageDeleteButton;
