import { Actions } from './actions';
import { Dispatch } from 'redux';
import { RootReducerInterface } from '../interfaces';
import { translationApi } from '../../api';
import { OperationType } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import {
  extractEntriesFromGlossary,
  GlossaryGroupedEntry,
  GlossaryTransposedEntry,
  groupGlossaryEntriesBySourceAndKey,
  groupGlossaryEntriesBySourceAndTarget,
} from '../../domain/translation/glossary';

export const getGlossaries =
  () => async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const abortController = new AbortController();
    const { token } = getState().authenticationState.authentication;

    dispatch(Actions.glossaries.meta.startLoading({ abortController }));

    try {
      const result = await translationApi.getGlossaries(
        token.accessToken,
        abortController.signal
      );

      dispatch(Actions.glossaries.loadingListSuccessful(result));
      dispatch(Actions.glossaries.meta.endLoading());
    } catch (error) {
      dispatch(Actions.glossaries.meta.endLoading());
      dispatch(Actions.glossaries.meta.loadingFailed({ error }));
    }
  };

export const getGlossaryEntries =
  () => async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const abortController = new AbortController();
    const { token } = getState().authenticationState.authentication;

    dispatch(Actions.glossary.meta.startLoading({ abortController }));
    dispatch(Actions.glossary.loadingListSuccessful([]));

    const glossaries = await translationApi.getGlossaries(
      token.accessToken,
      abortController.signal
    );

    dispatch(Actions.glossaries.loadingListSuccessful(glossaries));

    const glossaryEntries: GlossaryTransposedEntry[] = [];

    try {
      for (const glossary of glossaries) {
        const result = await translationApi.getGlossary(
          glossary.glossaryId,
          token.accessToken,
          abortController.signal
        );

        glossaryEntries.push(...extractEntriesFromGlossary(result));
      }

      const groupedEntries =
        groupGlossaryEntriesBySourceAndKey(glossaryEntries);

      dispatch(Actions.glossary.loadingListSuccessful(groupedEntries));
      dispatch(Actions.glossary.meta.endLoading());
    } catch (error) {
      dispatch(Actions.glossary.meta.endLoading());
      dispatch(Actions.glossary.meta.loadingFailed({ error }));
    }
  };

export const updateGlossaryEntries =
  (entries: GlossaryGroupedEntry[]) =>
  async (dispatch: any, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.createGlossary.meta.startOperation({
        operation: OperationType.CREATE,
      })
    );

    const glossaries = groupGlossaryEntriesBySourceAndTarget(entries);
    const glossaryOverview = getState().data.translationState.glossaries.data;

    const newGlossaries = glossaries.filter(
      (g) =>
        !glossaryOverview.some(
          (o) =>
            o.sourceLanguage === g.sourceLanguage &&
            o.targetLanguage === g.targetLanguage
        )
    );

    try {
      for (const glossary of newGlossaries) {
        await translationApi.addGlossary(glossary, token.accessToken);
      }

      for (const glossary of glossaryOverview) {
        const updatedGlossary = glossaries.find(
          (g) =>
            g.sourceLanguage === glossary.sourceLanguage &&
            g.targetLanguage === glossary.targetLanguage
        );

        if (updatedGlossary)
          await translationApi.addGlossary(updatedGlossary, token.accessToken);
        else
          await translationApi.deleteGlossary(
            glossary.glossaryId,
            token.accessToken
          );
      }

      dispatch(Actions.createGlossary.meta.operationSucceeded());
    } catch (error) {
      dispatch(Actions.createGlossary.meta.operationFailed({ error }));
    }
  };
