import ModalWithHeaderAndTwoButtons from '@dev/base-web/dist/view/components/modal/modal';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { Label } from './styled_components';
import { FormattedMessage } from 'react-intl';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import { useState } from 'react';
import styled from 'styled-components';
import { SingleDatePicker } from '@dev/base-web/dist/view/components/inputs/single_date_picker';
import { isAfter, isBefore, isSameMinute } from 'date-fns';

const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const DateTimePickerContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
`;

interface CloseTaskModalProps {
  readonly closeTask: (closeDate?: number) => void;
  readonly eventStartTimestamp: number;
}

const CloseTaskModal = ({
  closeTask,
  eventStartTimestamp,
}: CloseTaskModalProps) => {
  const [closeDate, setCloseDate] = useState<Date>(new Date());
  const [closeDateSet, setCloseDateSet] = useState<boolean>(false);

  const onCloseDateChanged = (date: Date) => {
    if (isSameMinute(date, closeDate)) {
      setCloseDate(date);
      setCloseDateSet(true);
    }
  };

  const onClosePressed = () => {
    closeTask(closeDateSet ? closeDate.valueOf() : undefined);
  };

  return (
    <ModalWithHeaderAndTwoButtons
      headerLabel={'finish_task'}
      okLabel={'close'}
      cancelLabel={'cancel'}
      onModalOkPressed={onClosePressed}
      triggerButton={
        <Button type="primary" icon="checkmark" label="task_done" />
      }
    >
      <Container>
        <Label>
          <FormattedMessage id={'close_event_time_question'} />
        </Label>
        <DateTimePickerContainer>
          <SingleDatePicker
            filterTime={(date) =>
              isBefore(date, new Date()) &&
              isAfter(date, new Date(eventStartTimestamp))
            }
            filterDate={(date) =>
              isBefore(date, new Date()) &&
              isAfter(date, new Date(eventStartTimestamp))
            }
            value={closeDate}
            onDateChanged={onCloseDateChanged}
            showTimeSelect
            timeIntervals={1}
          />
        </DateTimePickerContainer>
        <Hint messageKey={'close_task_hint'} />
      </Container>
    </ModalWithHeaderAndTwoButtons>
  );
};

export default CloseTaskModal;
