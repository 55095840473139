// Original source: https://stackoverflow.com/questions/70612769/how-do-i-recognize-swipe-events-in-react

import { useState } from 'react';

export enum SwipeState {
  NO_SWIPE,
  LEFT_SWIPE,
  RIGHT_SWIPE,
}

export const useHorizontalSwipe = (minSwipeDistance: number) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [swipeState, setSwipeState] = useState(SwipeState.NO_SWIPE);

  const onTouchStart = (e: any) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      setSwipeState(
        isLeftSwipe ? SwipeState.LEFT_SWIPE : SwipeState.RIGHT_SWIPE
      );
    } else {
      setSwipeState(SwipeState.NO_SWIPE);
    }
  };

  return [onTouchStart, onTouchMove, onTouchEnd, swipeState];
};
