import React, { TouchEventHandler, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css, useTheme } from 'styled-components';
import {
  Button,
  CenteredButtons,
} from '@dev/base-web/dist/view/components/global/button';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import InstructionListContainer from '../../Lists/instruction_list_container';
import { getEventTypeLookForType } from '@dev/base-web/dist/view/components/global/common';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import EventElements from './components/event_elements';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import { EventDefinition } from '@/model/domain/event/event_definition.ts';
import {
  EventInterface,
  EventStatusType,
  EventType,
} from '@dev/base-web/dist/model/domain/event/event_type';
import { useNavigate } from 'react-router';
import CloseTaskModal from './components/close_task_modal';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import SubsequentEventsList from './components/SubsequentEventsList';
import EventDetail from '../../../model/domain/event/event';
import { useAuthorizationCheckRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import ConfigurationParameters from '@dev/base-web/dist/model/domain/configuration/configuration';
import { useCallbackToOpenTabWithPathPrefix } from '@dev/base-web/dist/view/helpers/common';
import DarwinConfig from '@dev/base-web/dist/model/domain/authorization/darwin';
import { Dropdown } from 'semantic-ui-react';
import ForwardEventModal from './components/forward_event_modal';
import { toastrError } from '@dev/base-web/dist/view/helpers/notification_helpers';
import CommentList from '../../comments/comment_list';
import CommentsModal from '../../comments/comment_modal';
import {
  getLocations,
  getManufacturingEntity,
} from '@/model/domain/event/helper.ts';
import { LoadingEventListInterface } from '@dev/base-web/dist/model/redux/events/interface';
import { LoadingMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import EmptyOrLoadingView from '@dev/base-web/dist/view/components/global/no_data';
import { useWindowSize } from '@dev/base-web/dist/view/themes/helpers';
import {
  SwipeState,
  useHorizontalSwipe,
} from '@/components/helpers/use_horizontal_swipe';

const disorderStyle = css`
  content: 'Störung';
  color: #f26b24;
`;

const Type = styled.p<{ disorder?: boolean }>`
  font-family: Relative;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  padding: 0;

  ${(disorder) => disorder && disorderStyle};
`;

const H1 = styled.span`
  font-family: Relative;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #242424;
  margin: 0;
  padding: 0;
  margin-top: 7px;
  margin-bottom: 18px;
`;

const StatusMessage = styled.span`
  display: flex;
  flex-direction: row;
  font-family: Relative;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${(props) => props.color};
`;

const ViewAllButtonContainer = styled(CenteredButtons)`
  padding-top: 24px;
`;

const OverflowContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const FeedbackButtonContainer = styled.div`
  padding-left: 15px;
`;

const DropdownItemContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

const HeaderContainer = styled.div<{ leftAligned?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.leftAligned ? 'flex-start' : 'space-evenly'};
  align-items: center;
  width: 100%;
`;

const HeaderTitleContainer = styled.div<{ leftAligned?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.leftAligned ? 'flex-start' : 'center')};
  align-items: center;
  flex: 0.85;
`;

const EventSwitcherContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0.15;
`;

const EventListIndicatorCardContainer = styled.div`
  position: absolute;
  top: 0;
  left: -75px;
  width: 30px;
`;

const EventListIndicatorCardContent = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
`;

const CardsContainer = styled.div`
  position: relative;
`;

const CardContentContainer = styled.div<{ height?: number }>`
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 24px 24px 24px;
`;

interface Props {
  readonly removeInstruction?: (id: string) => void;
  readonly openCallback?: () => void;
  readonly eventId: string;
  readonly item: EventDetail | EventDefinition | null;
  readonly initialEventInfo: EventInterface | EventDefinition;
  readonly eventDefId: string;
  readonly editable?: boolean;
  readonly onInstructionSelect?: (eventId: string, actionId: string) => void;
  readonly onEventNeedsToBeRefreshed?: () => void;
  readonly currentUser: UserDetails | null;
  readonly initial?: boolean; //if this card is used on the wizard, it is made to be the initial one, so some things do not apply.
  readonly closeEvent?: (eventId: string, closeDate?: number) => void;
  readonly configs: ConfigurationParameters | null;
  readonly cleanInstruction: () => void;
  readonly onClosePressed?: () => void;
  readonly otherEvents?: LoadingEventListInterface;
  readonly onEventSelected?: (id: string) => void;
  readonly showEventListIndicator?: boolean;
  readonly eventLoadingMetaState?: LoadingMetaState;
}

const EventCard: React.FC<Props> = ({
  currentUser,
  item,
  editable,
  initial,
  onInstructionSelect,
  openCallback,
  removeInstruction,
  eventDefId,
  eventId,
  // onEventNeedsToBeRefreshed,
  closeEvent,
  configs,
  cleanInstruction,
  onClosePressed,
  otherEvents,
  onEventSelected,
  showEventListIndicator,
  eventLoadingMetaState,
  initialEventInfo,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();

  const [commentsModalOpen, setCommentsModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [previousEvent, setPreviousEvent] = useState<EventInterface | null>(
    null
  );
  const [nextEvent, setNextEvent] = useState<EventInterface | null>(null);
  const { height } = useWindowSize();
  const fullCardContentHeight = height - 303; // This is based on the event list height
  const fullCardHeight = fullCardContentHeight + 48;

  useEffect(() => {
    if (otherEvents && !otherEvents.meta.error && !otherEvents.meta.error) {
      const index = otherEvents.data.results.findIndex(
        (event) => event.id === eventId
      );
      if (index > 0) {
        setPreviousEvent(otherEvents.data.results[index - 1]);
      } else {
        setPreviousEvent(null);
      }
      if (index < otherEvents.data.results.length - 1) {
        setNextEvent(otherEvents.data.results[index + 1]);
      } else {
        setNextEvent(null);
      }
    }
  }, [otherEvents, eventId]);

  const [onTouchStart, onTouchMove, onTouchEnd, swipeState] =
    useHorizontalSwipe(100);

  useEffect(() => {
    if (swipeState === SwipeState.RIGHT_SWIPE) {
      onClosePressed && onClosePressed();
    }
  }, [swipeState]);

  const createNewInstruction = () => {
    cleanInstruction();
    navigate({
      pathname: `/actions/new`,
      search: `?eventId=${eventDefId || eventId}`,
    });
  };

  const closeTask = (closeDate?: number) => {
    if (closeEvent) {
      closeEvent(initialEventInfo.id, closeDate);
    }
  };

  const { color, icon } = getEventTypeLookForType(initialEventInfo.type, intl);

  const locations = getLocations(item);
  const eventDefManufacturingEntity = getManufacturingEntity(item);

  const { write: canEditEvent } = useAuthorizationCheckRWD(
    UserPrivilegePermission.EVENT,
    ApplicationCategory.SHANNON
  );

  const { write: canChangeSolutionRelation } = useAuthorizationCheckRWD(
    UserPrivilegePermission.ACTION_CONFIG,
    ApplicationCategory.SHANNON
  );

  const { write: canAddNewSolutions } = useAuthorizationCheckRWD(
    UserPrivilegePermission.ACTION,
    ApplicationCategory.SHANNON
  );

  const eventStatisticsOnClick =
    item && 'manufacturingEntity' in item && item.manufacturingEntity
      ? useCallbackToOpenTabWithPathPrefix(
          'analytics',
          `${DarwinConfig.Downtimes.url.path}/${item.manufacturingEntity.id}/${eventDefId}`,
          [item]
        )
      : undefined;

  const showEventStatsLink = !!eventStatisticsOnClick;
  const showEventDefLink = canEditEvent && !editable;

  return (
    <CardsContainer
      onTouchStart={onTouchStart as TouchEventHandler<HTMLDivElement>}
      onTouchMove={onTouchMove as TouchEventHandler<HTMLDivElement>}
      onTouchEnd={onTouchEnd as TouchEventHandler<HTMLDivElement>}
    >
      {showEventListIndicator && (
        <EventListIndicatorCardContainer>
          <Card onClick={onClosePressed}>
            <EventListIndicatorCardContent height={fullCardHeight} />
          </Card>
        </EventListIndicatorCardContainer>
      )}
      <Card
        margin="none"
        titleContent={
          <HeaderContainer leftAligned={editable}>
            {otherEvents && onEventSelected && (
              <EventSwitcherContainer>
                <Button
                  type="tertiary"
                  noMargin
                  icon="arrow-up-light"
                  size={'small'}
                  iconColor={theme.colors.black}
                  disabled={!previousEvent}
                  onClick={() => onEventSelected(previousEvent!.id)}
                />
                <Button
                  type="tertiary"
                  noMargin
                  icon="arrow-down-light"
                  size={'small'}
                  disabled={!nextEvent}
                  iconColor={theme.colors.black}
                  onClick={() => onEventSelected(nextEvent!.id)}
                />
              </EventSwitcherContainer>
            )}
            <HeaderTitleContainer leftAligned={editable}>
              <Type>
                <StatusMessage color={color}>
                  <Icon
                    iconStyle={{ marginRight: 5 }}
                    color={color}
                    name={icon}
                    size={14}
                  />
                </StatusMessage>
              </Type>
              <OverflowContainer>
                <H1>
                  {initialEventInfo.name ? (
                    <TextWithEllipsisAndTooltip
                      text={intl.formatMessage({
                        id: initialEventInfo.name,
                        defaultMessage: initialEventInfo.name,
                      })}
                      lines={2}
                    />
                  ) : (
                    '-'
                  )}
                </H1>
              </OverflowContainer>
            </HeaderTitleContainer>
          </HeaderContainer>
        }
        headerContent={
          <>
            {eventId &&
              closeEvent &&
              initialEventInfo.type === EventType.TASK &&
              (item as EventInterface).status === EventStatusType.ACTIVE && (
                <CloseTaskModal
                  closeTask={closeTask}
                  eventStartTimestamp={(item as EventInterface).timestamp}
                />
              )}
            <FeedbackButtonContainer>
              {(showEventStatsLink || showEventDefLink) && (
                <Button
                  type="tertiary"
                  noMargin
                  icon="more"
                  size={'small'}
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  <Dropdown
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    icon={
                      <Icon name="more" color={theme.colors.black} size={22} />
                    }
                    style={{ display: 'flex', alignItems: 'center' }}
                    open={dropdownOpen}
                    onClose={() => setDropdownOpen(false)}
                  >
                    <Dropdown.Menu direction="left">
                      {showEventStatsLink && (
                        <Dropdown.Item
                          onClick={eventStatisticsOnClick}
                          children={
                            <DropdownItemContent>
                              <StyledIcon
                                name="bars"
                                color={theme.colors.primary}
                                size={16}
                              />
                              <FormattedMessage id={'statistics'} />
                            </DropdownItemContent>
                          }
                        />
                      )}
                      {showEventDefLink && (
                        <Dropdown.Item
                          onClick={() => navigate('/events/' + eventDefId)}
                          children={
                            <DropdownItemContent>
                              <StyledIcon
                                name="edit"
                                color={theme.colors.primary}
                                size={16}
                              />
                              <FormattedMessage id={'edit_event'} />
                            </DropdownItemContent>
                          }
                        />
                      )}
                      {!editable && (
                        <Dropdown.Item
                          onClick={() => {}}
                          children={
                            <ForwardEventModal event={item as EventDetail} />
                          }
                        />
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </Button>
              )}
            </FeedbackButtonContainer>
            {onClosePressed && (
              <FeedbackButtonContainer>
                <Button
                  type="tertiary"
                  noMargin
                  icon="cross"
                  size={'small'}
                  onClick={onClosePressed}
                />
              </FeedbackButtonContainer>
            )}
          </>
        }
      >
        <CardContentContainer
          height={!editable ? fullCardContentHeight : undefined}
        >
          {eventLoadingMetaState &&
          (eventLoadingMetaState.error ||
            eventLoadingMetaState.loadingInProgress) ? (
            <EmptyOrLoadingView
              loadingInProgress={eventLoadingMetaState.loadingInProgress}
              hasError={!!eventLoadingMetaState.error}
              hasResults={false}
            />
          ) : (
            item && (
              <>
                <EventElements
                  eventId={eventId}
                  locations={locations}
                  event={item}
                  initial={initial}
                  manufacturingEntity={eventDefManufacturingEntity}
                />
                <div id="solution_list">
                  <InstructionListContainer
                    event={item}
                    onInstructionSelect={(actionId: string) => {
                      if (onInstructionSelect) {
                        onInstructionSelect(eventId, actionId);
                      }
                    }}
                    notEditable={!editable}
                    canAddNew={
                      canAddNewSolutions &&
                      (!('isInternal' in item) || !item.isInternal)
                    }
                    canDelete={
                      canChangeSolutionRelation &&
                      (!('isInternal' in item) || !item.isInternal)
                    }
                    removeInstruction={removeInstruction}
                    openCallback={openCallback}
                    createNewInstruction={createNewInstruction}
                    instructions={item.actions}
                    currentUser={currentUser}
                    configs={configs}
                  />
                </div>
                {!editable &&
                  !eventLoadingMetaState?.loadingInProgress &&
                  'subsequentEvents' in item &&
                  !item.isSubsequentEvent && (
                    <SubsequentEventsList subEvents={item.subsequentEvents} />
                  )}
                <CommentList
                  initialComments={item.comments}
                  eventDefinitionId={eventDefId}
                  eventId={eventId}
                  deleteDisabled={!canEditEvent}
                  toastrError={toastrError}
                />
                <ViewAllButtonContainer>
                  <Button
                    type="tertiary"
                    label="view_all"
                    onClick={() => setCommentsModalOpen(true)}
                  />
                </ViewAllButtonContainer>
                <CommentsModal
                  initialComments={item.comments}
                  eventDefinitionId={eventDefId}
                  eventId={eventId}
                  open={commentsModalOpen}
                  onClose={() => setCommentsModalOpen(false)}
                  toastrError={toastrError}
                />
              </>
            )
          )}
        </CardContentContainer>
      </Card>
    </CardsContainer>
  );
};

export default EventCard;
