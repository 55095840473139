import React, { useState } from 'react';
import {
  Button,
  RightAlignedButtons,
} from '@dev/base-web/dist/view/components/global/button';
import { InputOnChangeData, Menu, Popup } from 'semantic-ui-react';
import styled, { useTheme } from 'styled-components';
import { localisation } from '../../../../model/redux/reducers';
import { KeyValue } from '../../../../model/domain/translation/glossary';
import { Input, LanguageField, LanguageTitle } from './styled_components';

const StyledMenu = styled(Menu)`
  max-height: 200px;
  overflow-y: auto;
`;

const MenuItem = styled(Menu.Item)`
  &&&&& {
    padding: 16px 24px !important;
    width: 200px;
    font-family: Relative;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #242424;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  > *:nth-child(n + 2) {
    margin-top: 8px;
  }
`;

const AlignedButtons = styled(RightAlignedButtons)<{ leftAlign?: boolean }>`
  justify-content: ${({ leftAlign }) =>
    leftAlign ? 'flex-start !important' : 'flex-end !important'};
`;

interface EditTranslationsListProps {
  readonly sourceLanguage: string;
  readonly translations: KeyValue[];
  readonly setTranslations: (values: KeyValue[]) => void;
}

const EditTranslationsList: React.FC<EditTranslationsListProps> = ({
  sourceLanguage,
  translations,
  setTranslations,
}) => {
  const theme = useTheme();

  const [popupOpen, setPopupOpen] = useState(false);

  const addLanguage = (language: string) => {
    setTranslations([...translations, { language: language.toUpperCase() }]);
    setPopupOpen(false);
  };

  const removeTranslation = (language: string) => {
    setTranslations(
      translations.filter((t) => t.language !== language.toUpperCase())
    );
  };

  const languagesToAdd = Object.keys(localisation).filter(
    (l) =>
      l.toUpperCase() !== sourceLanguage.toUpperCase() &&
      !translations.some((t) => t.language === l.toUpperCase())
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <InputContainer>
        {translations.map((t) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                key={t.language}
                value={t.value}
                icon={<LanguageField>{t.language}</LanguageField>}
                error={!t.value}
                withoutBorder
                onChange={(
                  _event: React.ChangeEvent<HTMLInputElement>,
                  data: InputOnChangeData
                ) => {
                  const tr = [
                    ...translations.filter((tr) => tr.language !== t.language),
                    { language: t.language, value: data.value },
                  ];
                  setTranslations(tr);
                }}
              />
              <Button
                type={'tertiary'}
                icon={'delete'}
                iconColor={theme.colors.brownGrey}
                size={'small'}
                onClick={() => removeTranslation(t.language)}
              />
            </div>
          );
        })}
      </InputContainer>
      <AlignedButtons leftAlign={!translations.length}>
        <div style={{ marginTop: 4 }}>
          <Popup
            basic
            open={popupOpen}
            trigger={
              <Button
                type={'tertiary'}
                icon={'plus'}
                label={!translations.length ? 'add_translation' : undefined}
                onClick={() => setPopupOpen(true)}
                size={'small'}
              />
            }
            hoverable
            style={{
              borderRadius: '4px',
              boxShadow:
                '0 0 4px 0 rgba(0, 0, 0, 0.08), 0 9px 20px 0 rgba(0, 0, 0, 0.09)',
              padding: 0,
              border: 'none',
            }}
            onClose={() => setPopupOpen(false)}
            position={'top right'}
          >
            <StyledMenu
              compact
              vertical
              style={{ padding: 0, border: 'none', boxShadow: 'none' }}
            >
              {languagesToAdd.map((l) => (
                <MenuItem
                  style={{
                    cursor: 'pointer',
                    paddingLeft: 0,
                    paddingRight: 0,
                    borderBottom: 'none',
                    width: '100%',
                  }}
                  onClick={() => addLanguage(l)}
                >
                  <LanguageTitle language={l} />
                </MenuItem>
              ))}
            </StyledMenu>
          </Popup>
        </div>
      </AlignedButtons>
    </div>
  );
};

export default EditTranslationsList;
