import React, { useState, useEffect } from 'react';
import TabBar from '@dev/base-web/dist/view/components/global/tab_bar';
import { FormattedMessage } from 'react-intl';
import EventSubscriberConfig from './event_subscriber_config';
import {
  EventDefinition,
  EventDefinitionTranslation,
  EventRule,
} from '../../../model/domain/event/event_definition';
import styled from 'styled-components';
import EventDefinitionProperties, {
  EventDefinitionPropertiesOnChange,
} from './event_definition_properties';
import RuleListContainer from './event_rule_config/rule_list_container';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import Alert, {
  AlertSeverity,
} from '@dev/base-web/dist/view/components/global/alert';
import FeatureConfigurationInstance from '@dev/base-web/dist/model/domain/feature/feature_configuration';

const Container = styled.div`
  background-color: transparent;
  border: none;
  box-shadow: 0;
  padding-right: 12px;
`;

const AlertContainer = styled.div`
  margin: 16px 0;
`;

interface EventConfigurationCardProps {
  readonly item: EventDefinition;
  readonly onChange: EventDefinitionPropertiesOnChange;
  readonly onAddTranslation: (translation: EventDefinitionTranslation) => void;
  readonly onDeleteTranslation: (language: string) => void;
  readonly onIsCommonNotificationChanged: (value: boolean) => void;
  readonly swapButton: (type: EventType) => void;
  readonly allowedToModify: boolean;
  readonly onRuleSaved: (index: number, rule: EventRule) => void;
  readonly onRuleDeleted: (index: number) => void;
  readonly onRuleAdded: (rule: EventRule) => void;
  readonly manufacturingEntities: ManufacturingEntity[];
  readonly manufacturingEntitiesLoading: boolean;
  readonly getEventTrigger: (eventId: string) => void;
  readonly externalTab?: number; // when needed to externally se the tab number
  readonly tabCallback?: (tab: number) => void; // callback to change the tab
  readonly featureConfiguration: FeatureConfigurationInstance | null;
}

export const EventConfigurationCard: React.FC<EventConfigurationCardProps> = ({
  item,
  onChange,
  onAddTranslation,
  onDeleteTranslation,
  onIsCommonNotificationChanged,
  swapButton,
  allowedToModify,
  onRuleAdded,
  onRuleDeleted,
  onRuleSaved,
  manufacturingEntities,
  manufacturingEntitiesLoading,
  getEventTrigger,
  externalTab,
  tabCallback,
  featureConfiguration,
}) => {
  const externalTabDefined = typeof externalTab === 'number';
  const [tabIndex, setTabIndex] = useState<number>(
    externalTabDefined ? externalTab : 0
  );

  useEffect(() => {
    externalTabDefined && setTabIndex(externalTab);
  }, [externalTabDefined, externalTab]);

  const { isInternal } = item;

  const onChangeIndex = (index: number) => {
    if (externalTabDefined) {
      tabCallback && tabCallback(index);
    } else {
      setTabIndex(index);
      tabCallback && tabCallback(index);
    }
  };

  return (
    <Container>
      <TabBar
        titles={[
          <FormattedMessage id="event_properties" />,
          <FormattedMessage id="event_rules" />,
          <FormattedMessage id="notifications" />,
        ]}
        tabIndex={tabIndex}
        onSelectedIndexChange={onChangeIndex}
      />
      {isInternal && (
        <AlertContainer>
          <Alert
            severity={AlertSeverity.WARNING}
            textId="internal_event_warning"
            textValues={{}}
          />
        </AlertContainer>
      )}
      {tabIndex === 0 ? (
        <EventDefinitionProperties
          item={item}
          onChange={onChange}
          onAddTranslation={onAddTranslation}
          onDeleteTranslation={onDeleteTranslation}
          swapButton={swapButton}
          allowedToModify={allowedToModify}
        />
      ) : tabIndex === 1 ? (
        <RuleListContainer
          item={item}
          eventDefinitionId={item.id}
          rules={item.rules}
          onRuleSaved={onRuleSaved}
          onRuleDeleted={onRuleDeleted}
          onRuleAdded={onRuleAdded}
          manufacturingEntities={manufacturingEntities}
          manufacturingEntitiesLoading={manufacturingEntitiesLoading}
          allowedToModify={allowedToModify}
          featureConfiguration={featureConfiguration}
        />
      ) : (
        <EventSubscriberConfig
          item={item}
          eventId={item.id}
          isCommonNotification={item.isCommon}
          subscribers={item.subscribers}
          allowedToModify={allowedToModify}
          setIsCommonNotification={onIsCommonNotificationChanged}
          getEventTrigger={getEventTrigger}
        />
      )}
    </Container>
  );
};
