import { combineReducers, Reducer } from 'redux';
import { createReducersForLoadingPagingList } from '@dev/base-web/dist/model/redux/helpers/reducers';
import { ManufacturingEntitySummaryInterval } from '../../../domain/report/manufacturing_entity_summary_interval';
import { ACTION_TYPES } from './actions';
import { KPIStateInterface } from './interface';

const kpiState: Reducer<KPIStateInterface> = combineReducers({
  kpiResults:
    createReducersForLoadingPagingList<ManufacturingEntitySummaryInterval>(
      ACTION_TYPES.GET_KPI_RESULTS_KEY
    ),
});

export default kpiState;
