import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';
import ParallelEventsEntry from '../../domain/parallel_events/parallel_events';
import { TimeSpan } from '@dev/base-web/dist/model/domain/common/time_span';

const PARALLEL_EVENTS_URL = '/event/parallel?';

export default class ParallelEventsAPI extends BaseDataApi<ParallelEventsEntry> {
  async getByTimeSpan(
    token: string,
    timespan: TimeSpan
  ): Promise<ParallelEventsEntry[]> {
    const url =
      PARALLEL_EVENTS_URL + 'begin=' + timespan.start + '&end=' + timespan.end;
    return this.executeGetRequest<ParallelEventsEntry[]>(token, url);
  }
}
