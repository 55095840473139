import React from 'react';
import {
  CenterAlignedTableCell,
  StyledFirstCell,
  LeftAlignedTableCell,
  StyledCell,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import ParallelEventsEntry, {
  EventRule,
} from '../../../model/domain/parallel_events/parallel_events';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';
import { ManufacturingEntityType } from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';

const MultiRow = styled.div<{ paddingLeft?: boolean; paddingRight?: boolean }>`
  padding-top: 30px;
  padding-left: ${(props) => (props.paddingLeft ? '8px' : '0px')};
  padding-right: ${(props) => (props.paddingRight ? '8px' : '0px')};
  height: 64px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  &:last-child {
    border-bottom: none;
  }
  &:first-child {
    padding-top: 20px;
  }
`;

const LastOccurrenceCell = styled(LeftAlignedTableCell)`
  position: relative;
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  bottom: 18px;
  right: 30px;
`;

const StyledRow = styled(Table.Row)`
  vertical-align: top;
  border-radius: 15px;
  height: 64px;
  padding: 12px 3px 12px 7px;
`;

const StyledTextContainer = styled.div`
  padding-top: 20px;
`;

const StyledOccurencesCellContainer = styled.div`
  padding-top: 20px;
  font-weight: bold;
`;

const StyledFirstCellWithoutPadding = styled(StyledFirstCell)`
  &&&& {
    padding-right: 0;
  }
`;

const StyledCellWithoutPadding = styled(StyledCell)`
  &&&& {
    padding-left: 0;
  }
`;

interface ParallelEventsRowExternalProps {
  parallelEvents: ParallelEventsEntry;
  selectEvents: (rules: EventRule[]) => void;
}

export const ParallelEventsRow = (props: ParallelEventsRowExternalProps) => {
  const {
    parallelEvents: {
      eventRules,
      parallelOccurrences,
      singleOccurrence,
      lastOccurrence,
    },
    selectEvents,
  } = props;

  const { formatDate } = useLocalizedDateFormatters();

  const renderEventsRows = (eventRules: EventRule[]) => {
    return (
      <>
        {eventRules.map((entry) => {
          return (
            <MultiRow paddingLeft>
              <TextWithEllipsisAndTooltip text={entry.name} lines={1} />
            </MultiRow>
          );
        })}
      </>
    );
  };

  const renderManufacturingEntityRows = (eventRules: EventRule[]) => {
    // if distinct manufacturing entities in event rules, show them individually
    const distinctManufacturingEntities = Array.from(
      new Set(eventRules.map((rule) => rule.manufacturingEntity))
    );

    if (distinctManufacturingEntities.length > 1) {
      return (
        <>
          {distinctManufacturingEntities.map((entity) => (
            <MultiRow key={entity.id} paddingRight>
              <ManufacturingEntityHierarchyElement
                manufacturingEntity={entity}
                hideHiddenTypes
                showShortNames
                hiddenTypes={[
                  ManufacturingEntityType.FACTORY,
                  ManufacturingEntityType.AREA,
                  ManufacturingEntityType.LINE,
                ]}
                lines={1}
              />
            </MultiRow>
          ))}
        </>
      );
    }

    // If there's only one distinct manufacturing entity, render grouped
    return (
      <StyledTextContainer>
        <ManufacturingEntityHierarchyElement
          manufacturingEntity={eventRules[0].manufacturingEntity}
          hideHiddenTypes
          showShortNames
          hiddenTypes={[
            ManufacturingEntityType.FACTORY,
            ManufacturingEntityType.AREA,
            ManufacturingEntityType.LINE,
          ]}
          lines={2}
        />
      </StyledTextContainer>
    );
  };

  return (
    <StyledRow>
      <StyledFirstCellWithoutPadding>
        {renderManufacturingEntityRows(eventRules)}
      </StyledFirstCellWithoutPadding>
      <StyledCellWithoutPadding>
        {renderEventsRows(eventRules)}
      </StyledCellWithoutPadding>
      <CenterAlignedTableCell>
        <StyledOccurencesCellContainer>
          {parallelOccurrences}
        </StyledOccurencesCellContainer>
      </CenterAlignedTableCell>
      <CenterAlignedTableCell>
        <StyledOccurencesCellContainer>
          {singleOccurrence}
        </StyledOccurencesCellContainer>
      </CenterAlignedTableCell>
      <LastOccurrenceCell>
        <StyledTextContainer>
          {formatDate(lastOccurrence, 'Ppp')}
        </StyledTextContainer>
        <StyledButtonContainer>
          <Button
            type="primary"
            icon="duplicate"
            label={'merge'}
            onClick={() => {
              selectEvents(eventRules);
            }}
            // disabled={true}
          />
        </StyledButtonContainer>
      </LastOccurrenceCell>
      <Table.Cell />
    </StyledRow>
  );
};
