import { Card } from '@dev/base-web/dist/view/components/global/card';
import React, { useEffect, useState } from 'react';
import { ProductionProgressCardProps } from './interface';
import { TimeSpan } from '@dev/base-web/dist/model/domain/common/time_span';
import theme from '@dev/base-web/dist/view/themes/main';
import { constructConditionTypes } from '@dev/base-web/dist/view/components/charts/status_charts/helper';
import styled from 'styled-components';
import ChartCardOptions from '@dev/base-web/dist/view/components/charts/chart_card/chart_card_options';
import { ChartLegendItem } from '@dev/base-web/dist/view/components/global/chart_legend';
import StatusChart from '@dev/base-web/dist/view/components/charts/status_charts/gantt_chart';
import ProductionProgressChart from '@dev/base-web/dist/view/components/charts/production_progress_chart/production_progress_chart';
import { calculateChartTicks } from '@dev/base-web/dist/view/components/charts/helper';
import EmptyOrLoadingChartView from '@dev/base-web/dist/view/components/global/no_data';
import { CHART_LEGEND_ITEMS } from '@dev/base-web/dist/view/components/charts/production_progress_chart/helper';

const StyledEmptyOrLoadingChartView = styled(EmptyOrLoadingChartView)`
  height: 400px;
`;

const LegendContainer = styled.div`
  position: absolute;
  right: 44px;
`;

const LowerLegendContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 44px;
  margin-top: 18px;
  z-index: 9999;
`;

const ProgressCard = ({
  timeSpan,
  productionProgress,
  productionProgressMeta,
  durationDeviationsMeta,
  durationDeviations,
}: ProductionProgressCardProps) => {
  const [localTimeSpan, setLocalTimeSpan] = useState<TimeSpan | undefined>(
    timeSpan && timeSpan.end > Date.now()
      ? { start: timeSpan.start, end: Date.now() }
      : timeSpan
  );
  const [ticks, setTicks] = useState<number[]>([]);
  const [isStatusChartDownsampled, setStatusChartDownsampled] = useState(false);

  useEffect(() => {
    const newTicks = calculateChartTicks(localTimeSpan);
    setTicks(newTicks);
  }, [localTimeSpan]);

  const hasProgressResults =
    productionProgress?.cumulativeProducedProducts &&
    Object.keys(productionProgress?.cumulativeProducedProducts).length > 1;

  const onZoomed = (start: number, end: number) => {
    setLocalTimeSpan({ start: start, end: end });
  };

  const onDownsampleStatusChanged = (localCount: number, limit: number) => {
    setStatusChartDownsampled(localCount >= limit);
  };

  const conditionTypes = constructConditionTypes(theme);

  return (
    <Card titleId="production_progress">
      {hasProgressResults && (
        <>
          <LegendContainer>
            {CHART_LEGEND_ITEMS(theme).map((item, index) => (
              <ChartLegendItem key={index} item={item} />
            ))}
          </LegendContainer>
          <ProductionProgressChart
            productionProgress={productionProgress}
            onZoomChanged={onZoomed}
            zoomedDomain={localTimeSpan}
            ticks={ticks}
            height={300}
            trimTillNow
          />
        </>
      )}
      {!hasProgressResults && (
        <StyledEmptyOrLoadingChartView
          loadingInProgress={
            !productionProgress &&
            productionProgressMeta &&
            productionProgressMeta.loadingInProgress
          }
          hasError={!!productionProgressMeta.error}
          hasResults={hasProgressResults}
        />
      )}
      <LowerLegendContainer>
        <ChartCardOptions
          legendOptions={Object.values(conditionTypes)}
          warning={{
            isActive: isStatusChartDownsampled,
            label: 'filtered',
            tooltipLabel: 'status_filtered_hint',
          }}
          small
        />
      </LowerLegendContainer>
      <StatusChart
        durationDeviations={durationDeviations}
        durationDeviationsLoading={durationDeviationsMeta.loadingInProgress}
        onZoomChanged={onZoomed}
        zoomedDomain={localTimeSpan}
        globalDomain={timeSpan}
        ticks={ticks}
        onDownsampleStatusChanged={onDownsampleStatusChanged}
      />
    </Card>
  );
};

export default ProgressCard;
