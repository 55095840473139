import { connect } from 'react-redux';
import {
  ProductBatchesProducedOrderTableDispatchProps,
  ProductBatchesProducedOrderTableStoreProps,
} from './interface';
import ProductBatchesProducedOrderTable from './view';
import { RootReducerInterface } from '../../../../../../model/redux/interfaces';
import { getKPIResults } from '../../../../../../model/redux/report/kpi/thunks';

const mapStateToProps = (
  state: RootReducerInterface
): ProductBatchesProducedOrderTableStoreProps => ({
  kpiResults: state.data.kpiState.kpiResults.data.results,
  hasMoreKpiResults: state.data.kpiState.kpiResults.data.hasMoreResults,
  kpiResultsLoading: state.data.kpiState.kpiResults.meta.loadingInProgress,
});

const mapDispatchToProps: ProductBatchesProducedOrderTableDispatchProps = {
  getKPIResults,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductBatchesProducedOrderTable);
