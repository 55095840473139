import {
  DecisionOptionDTO,
  DecisionOptionTranslationRequest,
  StepDTO,
  StepTranslationRequest,
  Translation,
} from '@/model/domain/instruction/step.ts';
import {
  ActionDecisionOptionTranslationDTO,
  ActionDefinitionTranslationDTO,
  ActionDefinitionTranslationRequest,
  ActionInstructionTranslationDTO,
  InstructionDecisionOptionTranslation,
  InstructionStepTranslation,
  InstructionWithTranslation,
  TranslationDTO,
  TranslationWithPrev,
} from '@/model/domain/instruction/translation.ts';
import { removeDeprecatedStepDescriptions } from '@/model/domain/instruction/helper.ts';
import { BasicActionDefinitionDTO } from '@/model/domain/instruction/instruction.ts';

export const translationDtoToTranslation = (t: TranslationDTO): Translation => {
  return {
    language: t.targetLanguage,
    translation: t.targetLanguageText,
  };
};

const addTranslationToSteps = (
  baseSteps: readonly StepDTO[],
  lang: string,
  translatedSteps: readonly ActionInstructionTranslationDTO[],
  allTranslatedLangs: string[]
): StepDTO[] => {
  return baseSteps.map((baseStep) => {
    const translations: Translation[] = baseStep.translations.filter(
      (t) => t.language !== lang
    );

    const translation = translatedSteps.find((item) => item.id === baseStep.id);

    if (translation) {
      translations.push({
        language: lang,
        translation:
          translation.descriptionTranslation?.targetLanguageText || '',
      });

      return {
        ...baseStep,
        translations,
        decisionOptions: addTranslationToDecisionOptions(
          baseStep.decisionOptions,
          lang,
          translation.decisionOptionTranslations,
          allTranslatedLangs
        ),
      };
    } else return baseStep;
  });
};

const addTranslationToDecisionOptions = (
  baseOptions: readonly DecisionOptionDTO[],
  lang: string,
  translatedOptions: readonly ActionDecisionOptionTranslationDTO[],
  allTranslatedLangs: string[]
): DecisionOptionDTO[] => {
  return baseOptions.map((baseOption) => {
    const translation = translatedOptions.find(
      (item) => item.id === baseOption.id
    );

    if (translation) {
      return {
        ...baseOption,
        steps: addTranslationToSteps(
          baseOption.steps,
          lang,
          translation.stepTranslations,
          allTranslatedLangs
        ),
      };
    } else return baseOption;
  });
};

export const removeDeprecatedStepTranslationDescriptions = (
  steps: readonly ActionInstructionTranslationDTO[]
): ActionInstructionTranslationDTO[] => {
  return steps.map((s) => {
    return {
      id: s.id,
      type: s.type,
      step: s.step,
      media: s.media,
      additionalInfos: s.additionalInfos,
      translations: s.translations,
      decisionOptions: s.decisionOptions.map((o) => {
        return {
          ...o,
          steps: removeDeprecatedStepDescriptions(o.steps),
        };
      }),
      descriptionTranslation: s.descriptionTranslation,
      decisionOptionTranslations: s.decisionOptionTranslations.map((o) => {
        return {
          ...o,
          stepTranslations: removeDeprecatedStepTranslationDescriptions(
            o.stepTranslations
          ),
        };
      }),
    };
  });
};

export const removeDeprecatedNameTranslationFields = (
  i: ActionDefinitionTranslationDTO
): ActionDefinitionTranslationDTO => {
  const stepsTranslations = Object.fromEntries(
    Object.entries(i.stepsTranslations).map(([key, value]) => [
      key,
      removeDeprecatedStepTranslationDescriptions(value),
    ])
  );
  return {
    id: i.id,
    revisionNumber: i.revisionNumber,
    language: i.language,
    comments: i.comments,
    metrics: i.metrics,
    createdTimestamp: i.createdTimestamp,
    updatedTimestamp: i.updatedTimestamp,
    user: i.user,
    availableTranslations: i.availableTranslations,
    pendingUpdates: i.pendingUpdates,
    pendingUpdateOf: i.pendingUpdateOf,
    nameTranslations: i.nameTranslations,
    stepsTranslations: stepsTranslations,
    isHidden: i.isHidden,
    accessLevel: i.accessLevel,
    labels: i.labels,
    events: i.events,
    translations: i.translations,
    steps: removeDeprecatedStepDescriptions(i.steps),
  };
};

//TODO: check that all fields are set?
export const toTranslationRequest = (
  instruction: BasicActionDefinitionDTO,
  fromLanguage: string
): ActionDefinitionTranslationRequest => {
  const name = instruction.translations.find(
    (t) => t.language === fromLanguage
  )?.translation;

  return {
    ...instruction,
    name: name || '',
    steps: instruction.steps.map((s) =>
      toStepTranslationRequest(s, fromLanguage)
    ),
  };
};

export const toStepTranslationRequest = (
  step: StepDTO,
  fromLanguage: string
): StepTranslationRequest => {
  const name = step.translations.find(
    (t) => t.language === fromLanguage
  )?.translation;
  return {
    ...step,
    description: name || '',
    dummyId: undefined,
    decisionOptions: step.decisionOptions.map((o) =>
      toDecisionOptionTranslationRequest(o, fromLanguage)
    ),
  };
};

//TODO: also translate decision steps
export const toDecisionOptionTranslationRequest = (
  option: DecisionOptionDTO,
  fromLanguage: string
): DecisionOptionTranslationRequest => {
  return {
    ...option,
    steps: option.steps.map((s) => toStepTranslationRequest(s, fromLanguage)),
  };
};

export const instructionTranslationToInstruction = (
  t: InstructionWithTranslation
): BasicActionDefinitionDTO => {
  const {
    nameTranslations: _,
    stepTranslations: __,
    ...cleanedInstruction
  } = t;

  // we need to add the source translation to the array
  const baseTranslation = t.nameTranslations.length
    ? t.nameTranslations[0]
    : undefined;
  const translations: readonly Translation[] = [
    ...t.nameTranslations.map((t) => ({
      language: t.targetLanguage,
      translation: t.targetLanguageText,
    })),
    ...(baseTranslation
      ? [
          {
            language: baseTranslation.sourceLanguage,
            translation: baseTranslation.sourceLanguageText,
          },
        ]
      : []),
  ];

  const steps: readonly StepDTO[] = t.stepTranslations.map((s) =>
    instructionStepTranslationToStep(s)
  );

  return {
    ...cleanedInstruction,
    translations: translations,
    steps: steps,
  };
};

const instructionStepTranslationToStep = (
  t: InstructionStepTranslation
): StepDTO => {
  const {
    translationUpdate: _,
    decisionOptionTranslations: __,
    ...cleanedStep
  } = t;

  return {
    ...cleanedStep,
    translations: t.translationUpdate
      ? [
          ...Object.values(t.translationUpdate.translations).map((tr) => ({
            language: tr.language,
            translation: tr.translation,
          })),
          {
            language: t.translationUpdate.sourceLanguage,
            translation: t.translationUpdate.sourceLanguageText,
          },
        ]
      : t.translations,
    decisionOptions: t.decisionOptionTranslations.map((o) =>
      decisionOptionTranslationToOption(o)
    ),
  };
};

const decisionOptionTranslationToOption = (
  o: InstructionDecisionOptionTranslation
): DecisionOptionDTO => {
  const { nameTranslation: _, stepTranslations: __, ...cleanedDecision } = o;

  return {
    ...cleanedDecision,
    steps: o.stepTranslations.map((s) => instructionStepTranslationToStep(s)),
  };
};

export const switchLanguageInTranslation = (
  translations: readonly Translation[],
  newLanguage: string
): readonly Translation[] => {
  const newLang = newLanguage.toUpperCase();
  if (!translations.length) return translations;
  else if (
    translations.length > 1 &&
    translations.some((t) => t.language.toUpperCase() !== newLang)
  )
    return [...translations, { language: newLang, translation: '' }];
  else return [{ language: newLang, translation: translations[0].translation }];
};

export const switchLanguageInStepTranslation = (
  step: StepDTO,
  newLanguage: string
): StepDTO => {
  return {
    ...step,
    translations: switchLanguageInTranslation(step.translations, newLanguage),
    decisionOptions: step.decisionOptions.map((o) =>
      switchLanguageInDecisionTranslation(o, newLanguage)
    ),
  };
};

export const switchLanguageInDecisionTranslation = (
  option: DecisionOptionDTO,
  newLanguage: string
): DecisionOptionDTO => {
  return {
    ...option,
    steps: option.steps.map((s) =>
      switchLanguageInStepTranslation(s, newLanguage)
    ),
    translations: switchLanguageInTranslation(option.translations, newLanguage),
  };
};

export const instructionTranslationToInternalModel = (
  i: ActionDefinitionTranslationDTO
): InstructionWithTranslation => {
  return {
    id: i.id,
    revisionNumber: i.revisionNumber,
    language: i.language,
    comments: i.comments,
    metrics: i.metrics,
    createdTimestamp: i.createdTimestamp,
    updatedTimestamp: i.updatedTimestamp,
    user: i.user,
    availableTranslations: i.availableTranslations,
    pendingUpdates: i.pendingUpdates,
    pendingUpdateOf: i.pendingUpdateOf,
    labels: i.labels,
    isHidden: i.isHidden,
    events: i.events,
    nameTranslations: i.nameTranslations,
    stepTranslations: stepTranslationsToInternalModel(i.stepsTranslations),
  };
};

const optionStepTranslationsToInternalModel = (
  steps: readonly ActionInstructionTranslationDTO[],
  language: string
): InstructionStepTranslation[] => {
  return steps.map((s) => stepTranslationToInternalModel(s, language));
};

const stepTranslationToInternalModel = (
  s: ActionInstructionTranslationDTO,
  language: string
): InstructionStepTranslation => {
  return {
    id: s.id,
    type: s.type,
    step: s.step,
    media: s.media,
    decisionOptions: s.decisionOptions,
    additionalInfos: s.additionalInfos,
    translations: s.translations,
    translationUpdate: s.descriptionTranslation
      ? {
          sourceLanguage: s.descriptionTranslation.sourceLanguage,
          sourceLanguageText: s.descriptionTranslation.sourceLanguageText,
          translations: {
            [language]: {
              language: language,
              translation: s.descriptionTranslation.targetLanguageText,
              prevTranslation:
                s.descriptionTranslation.previousTargetLanguageText,
            },
          },
        }
      : null,
    decisionOptionTranslations: s.decisionOptionTranslations.map((o) => ({
      id: o.id,
      name: o.name,
      translations: o.translations,
      steps: o.steps,
      stepTranslations: optionStepTranslationsToInternalModel(
        o.stepTranslations,
        language
      ),
      nameTranslation: o.nameTranslation,
    })),
  };
};

const stepTranslationsToInternalModel = (
  tr: Record<string, readonly ActionInstructionTranslationDTO[]>
): readonly InstructionStepTranslation[] => {
  const languages = Object.keys(tr);

  if (!languages.length) return [];
  else {
    let arr: InstructionStepTranslation[] = [];
    const baseLanguage = languages[0];
    const baseSteps = tr[baseLanguage];

    baseSteps.forEach((s) => {
      arr.push(stepTranslationToInternalModel(s, baseLanguage));
    });

    languages.forEach((l) => {
      if (l !== baseLanguage) {
        const steps = tr[l];

        arr = arr.map((e) => {
          const step = steps.find((s) => s.id === e.id);
          return addTranslationToStep(e, step, l);
        });
      }
    });

    return arr;
  }
};

const addTranslationToStep = (
  baseStep: InstructionStepTranslation,
  newTranslation: ActionInstructionTranslationDTO | undefined,
  language: string
): InstructionStepTranslation => {
  if (
    newTranslation &&
    newTranslation.descriptionTranslation &&
    baseStep.translationUpdate
  ) {
    const translations: Record<string, TranslationWithPrev> | undefined =
      baseStep.translationUpdate
        ? {
            ...baseStep.translationUpdate.translations,
            [language]: {
              language: language,
              translation:
                newTranslation.descriptionTranslation.targetLanguageText,
              prevTranslation:
                newTranslation.descriptionTranslation
                  .previousTargetLanguageText,
            },
          }
        : undefined;

    if (translations)
      return {
        ...baseStep,
        translationUpdate: {
          ...baseStep.translationUpdate,
          translations: translations,
        },
        decisionOptionTranslations: addTranslationsToDecisionOptions(
          baseStep.decisionOptionTranslations,
          newTranslation.decisionOptionTranslations,
          language
        ),
      };
  }

  return baseStep;
};

const addTranslationsToSteps = (
  baseSteps: readonly InstructionStepTranslation[],
  newTranslations: readonly ActionInstructionTranslationDTO[] | undefined,
  language: string
): readonly InstructionStepTranslation[] => {
  if (newTranslations) {
    return baseSteps.map((s) => {
      const newTranslation = newTranslations.find((t) => t.id === s.id);
      return addTranslationToStep(s, newTranslation, language);
    });
  } else return baseSteps;
};

const addTranslationsToDecisionOptions = (
  baseOptions: readonly InstructionDecisionOptionTranslation[],
  newTranslations: readonly ActionDecisionOptionTranslationDTO[],
  language: string
): InstructionDecisionOptionTranslation[] => {
  return baseOptions.map((o) => {
    const newTranslation = newTranslations.find((t) => t.id === o.id);

    return {
      ...o,
      stepTranslations: addTranslationsToSteps(
        o.stepTranslations,
        newTranslation?.stepTranslations,
        language
      ),
    };
  });
};
