import React from 'react';
import ColoredKPI from '@dev/base-web/dist/view/components/kpi/colored_kpi';

interface Props {
  readonly small?: boolean;
  readonly value: number | null;
  readonly upperTreshold: number;
  readonly lowerTreshold: number;
  readonly labelId?: string;
  readonly className?: string;
}

const ThreeStatePercentageKPI = ({
  value,
  upperTreshold,
  lowerTreshold,
  small,
  labelId,
  className,
}: Props) => {
  const percentage = Math.round((value || 0) * 1000.0) / 10.0;

  const colorValue =
    (value || 0) < lowerTreshold ? 20 : (value || 0) < upperTreshold ? 14 : 2;

  return (
    <ColoredKPI
      value={`${percentage}%`}
      small={small}
      showGradient
      colorValue={colorValue}
      title={labelId}
      className={className}
    />
  );
};

export default ThreeStatePercentageKPI;
