import { Button } from '@dev/base-web/dist/view/components/global/button';
import ModalWithHeaderAndTwoButtons from '@dev/base-web/dist/view/components/modal/modal';
import { UpdatePasswordProps } from './interface';
import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import {
  useNotificationForOperation,
  useNotificationForOperationError,
} from '@dev/base-web/dist/view/helpers/notification_helpers';
import { useIntl } from 'react-intl';
import PasswordForm from './password_form';

const UpdatePasswordModal = ({
  userId,
  user,
  minimumPasswordStrength,
  updateUserPassword,
  updateUserPasswordOperation,
  isAdUser,
  isUserEditable,
}: UpdatePasswordProps) => {
  const intl = useIntl();

  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const reset = () => {
    setPassword(undefined);
    setConfirmPassword(undefined);
    setPasswordShown(false);
    setConfirmPasswordShown(false);
    setPasswordStrength(0);
  };

  useNotificationForOperation(
    updateUserPasswordOperation,
    'password_updated',
    'password_updated',
    'password_updated',
    intl,
    reset
  );

  useNotificationForOperationError(updateUserPasswordOperation, intl);

  const isPasswordWeak = passwordStrength < minimumPasswordStrength;
  const isPasswordValid = !!(
    password &&
    confirmPassword &&
    password === confirmPassword
  );

  const onUpdateUserPassword = () => {
    if (!isPasswordWeak && isPasswordValid)
      updateUserPassword(userId, password);
  };

  return (
    <ModalWithHeaderAndTwoButtons
      headerLabel={'change_password'}
      okLabel={'update'}
      cancelLabel={'cancel'}
      onModalOkPressed={onUpdateUserPassword}
      triggerButton={<Button type="secondary" label="change_password" />}
      okDisabled={isPasswordWeak || !isPasswordValid}
      okLoading={updateUserPasswordOperation.operationInProgress}
      onModalCancelPressed={reset}
    >
      <Form id={'update_password'}>
        <PasswordForm
          password={password}
          confirmPassword={confirmPassword}
          passwordShown={passwordShown}
          confirmPasswordShown={confirmPasswordShown}
          setPassword={setPassword}
          setConfirmPassword={setConfirmPassword}
          setPasswordStrength={setPasswordStrength}
          isAdUser={isAdUser}
          isUserEditable={isUserEditable}
          setPasswordShown={setPasswordShown}
          setConfirmPasswordShown={setConfirmPasswordShown}
          isPasswordValid={isPasswordValid}
          isPasswordWeak={isPasswordWeak}
          user={user}
          dark
        />
      </Form>
    </ModalWithHeaderAndTwoButtons>
  );
};

export default UpdatePasswordModal;
