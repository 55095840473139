import React, { Component } from 'react';
import styled from 'styled-components';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { Button as SemanticButton } from 'semantic-ui-react';
import { TabStatus } from '../view';
import { FormattedMessage } from 'react-intl';
import theme from '@dev/base-web/dist/view/themes/main';

interface WizardStepsInterface {
  readonly isSelected: boolean;
  readonly last?: boolean;
}

const StyledWizardStepsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const borderBottomStyle = `2px solid ${theme.colors.primary}`;

const StyledTab = styled.a`
  position: relative;
  cursor: pointer;
  color: ${(props: WizardStepsInterface) =>
    props.isSelected ? theme.colors.primary : '#939393'};
  border-bottom: ${(props: WizardStepsInterface) =>
    props.isSelected ? borderBottomStyle : 0};
  padding-bottom: ${(props: WizardStepsInterface) =>
    props.isSelected ? 6 : 0};
  font-family: Relative;
  font-weight: bold;
  font-size: 16px;
  margin-right: ${(props: WizardStepsInterface) => (props.last ? '0' : '16px')};
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: normal;

  &:hover {
    color: #242424;
  }
`;

const IconWrapper = styled.a`
  position: relative;
  color: #939393;
  font-family: Relative;
  font-weight: bold;
  font-size: 16px;
  margin-right: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: normal;
`;

const StyledIcon = styled(Icon)`
  margin: 0px !important;
`;

const AddButton = styled(SemanticButton)`
  &&&&& {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 13px;
    background-color: #e8ebea;
    padding: 0;
    margin: 0;
    right: -12px;
    top: -16px;
    border: solid 1px rgba(36, 36, 36, 0.05);
    visibility: visible;
  }
`;

const CompleteMark = () => {
  return (
    <AddButton
      style={{ backgroundColor: theme.colors.primary, border: 'none' }}
    >
      <StyledIcon name={'checkmark'} size={24} color="white" />
    </AddButton>
  );
};

interface WizardStepsPropsInterface {
  readonly tabs: { [id: string]: TabStatus };
  readonly tabIndex?: number;
  readonly onSelectedIndexChange?: (index: number) => void;
}

interface WizardStepsStateInterface {
  selectedIndex: number;
}

class WizardSteps extends Component<
  //////this is a simplified version of the TabBar component
  WizardStepsPropsInterface,
  WizardStepsStateInterface
> {
  state: Readonly<WizardStepsStateInterface> = {
    selectedIndex: 0,
  };

  constructor(props: WizardStepsPropsInterface) {
    super(props);
    const { tabIndex } = props;

    this.state = {
      selectedIndex: tabIndex ? tabIndex : 0,
    };
  }

  changeActive(index: number) {
    if (index !== this.props.tabIndex && this.props.onSelectedIndexChange) {
      this.props.onSelectedIndexChange(index);
    }
  }

  render() {
    const tabKeys = Object.keys(this.props.tabs);

    return (
      <StyledWizardStepsContainer>
        {tabKeys.map((tab, index) => {
          return (
            <>
              <StyledTab
                last={tabKeys.length === index + 1}
                isSelected={this.props.tabIndex === index}
                onClick={() => this.changeActive(index)}
              >
                {this.props.tabs[tab].isValid && <CompleteMark />}
                <FormattedMessage id={tab} />
              </StyledTab>
              {tabKeys.length !== index + 1 && (
                <IconWrapper>
                  <Icon
                    name={'arrow-right-light'}
                    color={'#939393'}
                    size={16}
                  />
                </IconWrapper>
              )}
            </>
          );
        })}
      </StyledWizardStepsContainer>
    );
  }
}

export default WizardSteps;
