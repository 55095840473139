import { RootReducerInterface } from '@/model/redux/interfaces.ts';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ShannonConfig from '@dev/base-web/dist/model/domain/authorization/shannon';
import Actions from './view';
import {
  createGetInstructionsThunks,
  archiveInstructions,
  deleteInstructions,
  cleanInstruction,
} from '@/model/redux/instruction_definition/thunks.ts';
import { InstructionsStoreProps } from './interfaces';

export const CONFIG = ShannonConfig.Actions;

const mapStateToProps = (
  state: RootReducerInterface
): InstructionsStoreProps => {
  return {
    instructions:
      state.data.instructionDefinitionState.instructions.data.results,
    moreInstructionsCanBeLoaded:
      state.data.instructionDefinitionState.instructions.data.hasMoreResults,
    totalElements:
      state.data.instructionDefinitionState.instructions.data.totalElements,
    instructionLoadingInProgress:
      state.data.instructionDefinitionState.instructions.meta.loadingInProgress,
    instructionLoadingError:
      state.data.instructionDefinitionState.instructions.meta.error,
    instructionsOperation:
      state.data.instructionDefinitionState.instructionsOperation.meta
        .operation,
    instructionsOperationError:
      state.data.instructionDefinitionState.instructionsOperation.meta.error,
    instructionsOperationLoading:
      state.data.instructionDefinitionState.instructionsOperation.meta
        .operationInProgress,
    previousInstructionsSearchData:
      state.data.instructionDefinitionState.instructions.meta
        .previousSearchData,
    showHierarchy:
      state.configurationState.config.data?.showManufacturingEntityHierarchy
        .value,
  };
};

export default connect(mapStateToProps, {
  getInstructions: createGetInstructionsThunks(),
  archiveInstructions,
  deleteInstructions,
  cleanInstruction,
})(injectIntl(Actions));
