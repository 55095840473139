import React, { Component } from 'react';
import styled from 'styled-components';
import UserUpdateDTO from '@dev/base-web/dist/model/domain/user/user_update_dto';
import { FormattedMessage } from 'react-intl';

const StyledContainer = styled.div`
  margin-left: 16px;
`;

const StyledUserName = styled.div`
  font-family: Relative;
  font-size: 18px;
  font-weight: bold;
  color: #242424;
`;

const StyledRole = styled.div`
  font-family: Relative;
  font-size: 14px;
  color: #8c8c8c;
  text-transform: uppercase;
`;

const StyledUsername = styled.div`
  margin-top: 12px;
  font-family: Relative;
  font-size: 14px;
  color: #242424;
`;

const StyledLocation = styled.div`
  margin-top: 12px;
  font-family: Relative;
  font-weight: bold;
  font-size: 14px;
  color: #242424;
`;

interface UserDetailsProps {
  readonly user: UserUpdateDTO | null;
}

export default class UserDetails extends Component<UserDetailsProps> {
  render() {
    const { user } = this.props;
    const name =
      user && (user.firstName || user.lastName)
        ? `${user.firstName || '-'} ${user.lastName || '-'}`
        : null;
    const role = user ? user.role : null;
    const username = user ? user.username : null;
    const location =
      user && user.manufacturingEntities
        ? user.manufacturingEntities.map((entity) => entity.name).join(', ')
        : null;

    return (
      <StyledContainer>
        {name && <StyledUserName> {name} </StyledUserName>}
        {role && (
          <StyledRole>
            <FormattedMessage id={role.toLowerCase()} />
          </StyledRole>
        )}
        {username && <StyledUsername> {username} </StyledUsername>}
        {location && <StyledLocation> {location} </StyledLocation>}
      </StyledContainer>
    );
  }
}
