import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import React from 'react';
import styled from 'styled-components';
import { FeatureGeneratorInputProps } from '@dev/base-web/dist/model/domain/feature/feature';
import { FeatureFunction as InitialFeature } from '@dev/base-web/dist/view/components/feature/feature_config_card/feature_function';
import { FeatureColors } from '@dev/base-web/dist/view/components/feature/styles';
import FeatureConfigurationInstance from '@dev/base-web/dist/model/domain/feature/feature_configuration';

interface FeatureCardProps {
  readonly featureId: string;
  readonly featureValue: FeatureGeneratorInputProps;
  readonly odd?: boolean;
  readonly replaceColors?: FeatureColors;
  readonly featureConfiguration: FeatureConfigurationInstance | null;
}

const DragAndDrop = styled.div``;

const FeatureCard: React.FC<FeatureCardProps> = ({
  featureValue,
  odd,
  replaceColors,
  featureConfiguration,
}) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <DragAndDrop>
        <InitialFeature
          allowedInput={[]}
          data={featureValue}
          sendChanges={() => {}}
          node="initial"
          odd={odd}
          canDrag={false}
          isParentDragging={false}
          disabled
          replaceColors={replaceColors}
          featureConfiguration={featureConfiguration?.functions}
        />
      </DragAndDrop>
    </DndProvider>
  );
};

export default FeatureCard;
