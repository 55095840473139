import ManufacturingEntity, {
  ManufacturingEntityType,
} from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import {
  Column,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import { useEffect, useState } from 'react';
import { ErrorInterface } from '@dev/base-web/dist/model/api/error/error_interface';
import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import DowntimesInReportTableRow from './downtimes_in_report_table_row';
import OpenDowntimeList from './open_downtime_list';
import {
  LoadingMetaState,
  OperationMetaState,
} from '@dev/base-web/dist/model/redux/helpers/interfaces';
import {
  useNotificationForOperation,
  useNotificationForOperationError,
} from '@dev/base-web/dist/view/helpers/notification_helpers';
import { useIntl } from 'react-intl';
import { TableContainer } from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import DowntimesInReportChart from './downtimes_in_report_chart';
import ShiftReportOverview from '@dev/base-web/dist/model/domain/report/ShiftReportOverview';
import DowntimeOverview from '@dev/base-web/dist/model/domain/report/DowntimeOverview';
import Downtime from '@dev/base-web/dist/model/domain/report/Downtime';
import { DowntimeReason } from '@dev/base-web/dist/model/domain/report/DowntimeReason';
import DowntimeEvent from '@dev/base-web/dist/model/domain/report/DowntimeEvent';
import DowntimeAction from '@dev/base-web/dist/model/domain/report/DowntimeAction';
import { useAuthorizationCheckRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import EventDetail from '../../../../../model/domain/event/event';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';

const DOCUMENTED_KEY = 'isDocumented';

export const isDocumentedOptions = [
  {
    textId: 'yes',
    value: true,
  },
  {
    textId: 'no',
    value: false,
  },
];

export const createColumns = (
  manufacturingEntities: readonly ManufacturingEntity[],
  getAllManufacturingEntities: (query?: string) => void,
  manufacturingEntityLoading: boolean
): Array<Column> => [
  {
    title: 'downtime_start',
    isLeftAligned: true,
    sortKey: 'start',
    filterType: ColumnFilterType.DATE,
    width: 2,
  },
  {
    title: 'downtime',
    sortKey: 'downtime',
    filterType: ColumnFilterType.DURATION,
    width: 2,
  },
  {
    title: 'manufacturing_entity',
    isLeftAligned: true,
    filterKey: 'manufacturingEntity.id',
    sortKey: 'manufacturingEntity.name',
    filterType: ColumnFilterType.MANUFACTURING_ENTITY,
    filterOptions: manufacturingEntities,
    width: 3,
    getAllManufacturingEntities,
    isLoading: manufacturingEntityLoading,
  } as any, //TableHeaderManufacturingEntityFilterColumn
  {
    title: 'cause',
    isLeftAligned: true,
    sortKey: 'causingEvent.name',
    filterType: ColumnFilterType.TEXT,
    width: 5,
  },
  {
    title: 'solution',
    isLeftAligned: true,
    sortKey: 'chosenAction.name',
    filterType: ColumnFilterType.TEXT,
    width: 3,
  },
  {
    title: 'documented',
    sortKey: 'isDocumented',
    filterType: ColumnFilterType.SELECTOR,
    filterOptions: isDocumentedOptions,
    width: 1,
  },
];

interface DowntimesInReportTableProps {
  readonly reportId: string;
  readonly report: ShiftReportOverview;
  readonly selectedDowntimeId?: string;
  readonly setSelectedDowntimeId: (id?: string) => void;
  readonly downtimesInReport: readonly DowntimeOverview[];
  readonly downtimesInReportLoadingInProgress: boolean;
  readonly downtimesInReportLoadingError?: ErrorInterface;
  readonly manufacturingEntities: readonly ManufacturingEntity[];
  readonly manufacturingEntityMeta: LoadingMetaState;
  readonly selectedDowntime: Downtime | null;
  readonly selectedDowntimeLoadingInProgress: boolean;
  readonly showManufacturingEntityHierarchy?: boolean | null;
  readonly downtimeReasons: readonly DowntimeReason[];
  readonly downtimeReasonsLoadingInProgress: boolean;
  readonly showOnlyNotDocumented?: boolean;
  readonly getDowntime: (reportId: string, downTimeId: string) => void;
  readonly getSelectedEvent: (eventId: string) => void;
  readonly getDowntimesInShiftReport: (
    reportId: string,
    filters: readonly FilterData[],
    sortKey?: string,
    sortDirection?: SortingDirection
  ) => void;
  readonly getAllManufacturingEntities: (
    query?: string,
    includedTypes?: readonly ManufacturingEntityType[]
  ) => void;
  readonly updateDowntimeInShiftReport: (
    originalDowntime: Downtime,
    reportId: string,
    filters: readonly FilterData[],
    newCause?: DowntimeEvent,
    newAction?: DowntimeAction,
    newOtherReason?: DowntimeReason,
    newComment?: string
  ) => void;
  readonly downtimeUpdateMeta: OperationMetaState;
  readonly updateDowntimeInShiftReportBasedOnOverview: (
    downtimeOverview: DowntimeOverview,
    reportId: string,
    filters: readonly FilterData[]
  ) => void;
  readonly selectedEventData: EventDetail | null;
}

const DowntimesInReportTable = ({
  report,
  reportId,
  downtimesInReport,
  downtimesInReportLoadingError,
  downtimesInReportLoadingInProgress,
  getDowntimesInShiftReport,
  manufacturingEntities,
  getAllManufacturingEntities,
  manufacturingEntityMeta,
  selectedDowntime,
  getDowntime,
  selectedDowntimeLoadingInProgress,
  showManufacturingEntityHierarchy,
  updateDowntimeInShiftReport,
  downtimeReasons,
  downtimeReasonsLoadingInProgress,
  showOnlyNotDocumented,
  downtimeUpdateMeta,
  selectedDowntimeId,
  setSelectedDowntimeId,
  updateDowntimeInShiftReportBasedOnOverview,
  getSelectedEvent,
  selectedEventData,
}: DowntimesInReportTableProps) => {
  const intl = useIntl();

  const [selectedDowntimeOverview, setSelectedDowntimeOverview] =
    useState<DowntimeOverview>();
  const [localFilters, setLocalFilters] = useState<readonly FilterData[]>([]);

  useEffect(() => {
    getAllManufacturingEntities();
  }, []);

  useEffect(() => {
    if (showOnlyNotDocumented) {
      const newLocalFilter = [
        new FilterData(DOCUMENTED_KEY, FilterOperation.EQ_OPERATOR, false),
      ];

      getDowntimesInShiftReport(reportId, newLocalFilter);
      setLocalFilters(newLocalFilter);
    }
  }, [showOnlyNotDocumented]);

  useEffect(() => {
    if (selectedDowntimeId) {
      const foundDowntimeOverview = downtimesInReport.find(
        (downtime) => downtime.id === selectedDowntimeId
      );
      if (foundDowntimeOverview) {
        setSelectedDowntimeOverview(foundDowntimeOverview);
      }
      getDowntime(reportId, selectedDowntimeId);
    }
  }, [selectedDowntimeId, downtimesInReport]);

  const onDowntimeSelected = (downtime?: DowntimeOverview) => {
    setSelectedDowntimeId(downtime?.id);
    setSelectedDowntimeOverview(downtime);
  };

  const getNextResults = (
    page: number,
    filters: readonly FilterData[],
    sortKey?: string,
    sortDirection?: SortingDirection
  ) => {
    // Loading all the data for the first page
    if (page === 0) {
      setLocalFilters(filters);
      getDowntimesInShiftReport(reportId, filters, sortKey, sortDirection);
    }
  };

  const onDowntimeUpdate = (
    newCause?: DowntimeEvent,
    newAction?: DowntimeAction,
    newOtherReason?: DowntimeReason,
    newComment?: string,
    downtimeOverView?: DowntimeOverview
  ) => {
    if (selectedDowntime) {
      updateDowntimeInShiftReport(
        selectedDowntime,
        reportId,
        localFilters,
        newCause,
        newAction,
        newOtherReason,
        newComment
      );
    } else if (downtimeOverView) {
      updateDowntimeInShiftReportBasedOnOverview(
        downtimeOverView,
        reportId,
        localFilters
      );
    }
  };

  useNotificationForOperation(
    downtimeUpdateMeta,
    'downtime_updated',
    'downtime_updated',
    'downtime_updated',
    intl,
    () => setSelectedDowntimeOverview(undefined)
  );

  useNotificationForOperationError(downtimeUpdateMeta, intl);

  const { write: canEdit } = useAuthorizationCheckRWD(
    UserPrivilegePermission.REPORT,
    ApplicationCategory.SHANNON
  );

  return showOnlyNotDocumented ? (
    <OpenDowntimeList
      downtimeReasons={downtimeReasons}
      downtimeReasonsLoadingInProgress={downtimeReasonsLoadingInProgress}
      updateDowntimeInShiftReport={onDowntimeUpdate}
      selectedDowntime={selectedDowntime}
      getDowntime={getDowntime}
      selectedDowntimeLoadingInProgress={selectedDowntimeLoadingInProgress}
      showManufacturingEntityHierarchy={showManufacturingEntityHierarchy}
      downtimesInReport={downtimesInReport}
      downtimesInReportLoadingInProgress={downtimesInReportLoadingInProgress}
      selectedDowntimeOverview={selectedDowntimeOverview}
      onDowntimeOverviewSelected={setSelectedDowntimeOverview}
      downtimeUpdateMeta={downtimeUpdateMeta}
      editable={canEdit}
      getSelectedEvent={getSelectedEvent}
      selectedEventData={selectedEventData}
      shiftReport={report}
    />
  ) : (
    <TableContainer>
      <DowntimesInReportChart
        downtimesInReport={downtimesInReport}
        downtimesInReportLoadingInProgress={downtimesInReportLoadingInProgress}
        report={report}
        onDowntimeSelected={onDowntimeSelected}
      />
      <SortableTable
        dataLength={downtimesInReport.length}
        hasMoreResults={false}
        columns={createColumns(
          manufacturingEntities,
          getAllManufacturingEntities,
          manufacturingEntityMeta.loadingInProgress
        )}
        getNextResults={getNextResults}
        loadingInProgress={downtimesInReportLoadingInProgress}
        error={downtimesInReportLoadingError}
        minHeight={500}
        //initialSearchData={{sortConfig: {key: 'start', direction: SortingDirection.DESCENDING}}}
      >
        {downtimesInReport.map((result) => {
          const downtimeSelected = selectedDowntimeOverview?.id === result.id;
          return (
            <DowntimesInReportTableRow
              key={result.id}
              onSelected={() =>
                setSelectedDowntimeOverview(
                  downtimeSelected ? undefined : result
                )
              }
              selected={downtimeSelected}
              downtimeOverview={result}
              selectedDowntime={selectedDowntime}
              getDowntime={getDowntime}
              selectedDowntimeLoadingInProgress={
                selectedDowntimeLoadingInProgress
              }
              reportId={reportId}
              report={report}
              showManufacturingEntityHierarchy={
                !!showManufacturingEntityHierarchy
              }
              downtimeReasons={downtimeReasons}
              downtimeReasonsLoadingInProgress={
                downtimeReasonsLoadingInProgress
              }
              updateDowntimeInShiftReport={onDowntimeUpdate}
              downtimeUpdateInProgress={downtimeUpdateMeta.operationInProgress}
              editable={canEdit}
              getSelectedEvent={getSelectedEvent}
              selectedEventData={selectedEventData}
            />
          );
        })}
      </SortableTable>
    </TableContainer>
  );
};

export default DowntimesInReportTable;
