import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const StyledLoginButton = styled.button`
  width: 100%;
  height: 48px;
  padding: 12px;
  margin-top: 64px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  color: #ffffff;
  background-color: black;
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};
  font-family: Relative;
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal;
  &:hover {
    background: rgba(36, 36, 36, 0.85);
  }
  &:active {
    background: rgba(36, 36, 36, 0.7);
  }
}
 
`;

interface LoginButtonProps {
  readonly disabled: boolean;
}

const LoginButton: React.FC<LoginButtonProps> = (props) => {
  return (
    <StyledLoginButton disabled={props.disabled} type="submit">
      <FormattedMessage id="login_button_title" />
    </StyledLoginButton>
  );
};

export default LoginButton;
