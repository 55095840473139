import {
  createActionsForExecutingOperation,
  createActionsForLoadingPagingList,
  createActionsForSingleItem,
} from '@dev/base-web/dist/model/redux/helpers/actions';
import EventDetail from '../../domain/event/event';
import RawEvent from '../../domain/event/raw_event';

export const CURRENT_EVENT_KEY = 'CURRENT_EVENT';
export const CLOSE_EVENT = 'CLOSE_EVENT';
export const ARCHIVE_EVENTS_KEY = 'ARCHIVE_EVENTS';
export const RAW_EVENTS_KEY = 'RAW_EVENTS';
export const FORWARD_EVENT = 'FORWARD_EVENT';

export const Actions = {
  currentEvent: createActionsForSingleItem<EventDetail>(CURRENT_EVENT_KEY),
  closeEventUpdate: createActionsForExecutingOperation(CLOSE_EVENT),
  archiveEvent: createActionsForExecutingOperation(ARCHIVE_EVENTS_KEY),
  rawEvents: createActionsForLoadingPagingList<RawEvent>(RAW_EVENTS_KEY),
  forwardEvent: createActionsForExecutingOperation(FORWARD_EVENT),
};
