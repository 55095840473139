import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  CenterAlignedTableCell,
  LeftAlignedTableCell,
  StyledFirstCell,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import SimpleKPI from '@dev/base-web/dist/view/components/kpi/simple_kpi';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import { useIntl } from 'react-intl';
import { localizeNumber } from '@dev/base-web/dist/model/helpers/number-formatter';
import ThreeStatePercentageKPI from '../../../../overview/components/three_state_percentage_kpi';
import {
  INTERVAL_TYPES_TYPE,
  ManufacturingEntitySummaryInterval,
} from '../../../../../../model/domain/report/manufacturing_entity_summary_interval';
import ProducedProductsPopup from '../../../../../dashboard/components/produced_products_tooltip';

const StyledRow = styled.tr`
  border-radius: 15px;
  border-bottom: solid 1px rgba(36, 36, 36, 0.05);
`;

interface Props {
  readonly kpi: ManufacturingEntitySummaryInterval;
  readonly intervalType: INTERVAL_TYPES_TYPE;
}

const TableRow = ({
  kpi: {
    producedProducts,
    interval,
    utilization,
    manufacturingEntity,
    cycleCount,
    productsPerCycle,
  },
  intervalType,
}: Props) => {
  const { formatDate } = useLocalizedDateFormatters();
  const intl = useIntl();

  const formattedIntervalName = useMemo(() => {
    if (intervalType === 'DAY') {
      // Response returns a date like: 2022-05-16

      // If date is invalid, then dateValue is NaN.
      const dateValue = new Date(interval.name).valueOf();

      if (!isNaN(dateValue)) {
        return formatDate(dateValue, 'P');
      } else {
        return interval.name;
      }
    }

    return interval.name;
  }, [interval]);

  return (
    <StyledRow>
      <StyledFirstCell>
        {formattedIntervalName ? (
          <TextWithEllipsisAndTooltip text={formattedIntervalName} />
        ) : (
          '-'
        )}
      </StyledFirstCell>
      <LeftAlignedTableCell>
        {formatDate(interval.start, 'P p')}
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        {formatDate(interval.end, 'P p')}
      </LeftAlignedTableCell>
      <CenterAlignedTableCell>
        <ProducedProductsPopup
          manufacturingEntity={manufacturingEntity}
          productsPerCycle={productsPerCycle}
          producedProducts={producedProducts}
          cycleCount={cycleCount}
          trigger={
            <div>
              <SimpleKPI
                value={localizeNumber(producedProducts) as string}
                small
                showBorder
              />
            </div>
          }
        />
      </CenterAlignedTableCell>
      <CenterAlignedTableCell>
        {producedProducts > 30 ? (
          <ThreeStatePercentageKPI
            value={utilization}
            upperTreshold={0.7}
            lowerTreshold={0.4}
            small
          />
        ) : (
          <Popup
            content={intl.formatMessage({ id: 'not_enough_data' })}
            trigger={
              <div>
                <SimpleKPI value={'-'} small showBorder />
              </div>
            }
          />
        )}
      </CenterAlignedTableCell>
    </StyledRow>
  );
};

export default TableRow;
