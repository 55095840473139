import { StepDTO } from '@/model/domain/instruction/step.ts';
import { useIntl } from 'react-intl';
import React, { useState } from 'react';
import {
  MediaPathsDTO,
  MediaType,
  PageRange,
} from '@dev/base-web/dist/model/domain/common/media';
import { MediaErrorType } from '@dev/base-web/dist/view/components/global/media';
import { toastrWarning } from '@dev/base-web/dist/view/helpers/notification_helpers';
import { LightboxWrapper } from '../lightbox_wrapper';
import { StyledMedia } from './instruction_card';
import {
  Button,
  CenteredButtons,
} from '@dev/base-web/dist/view/components/global/button';
import PdfViewer from '../../screens/edit_instruction/components/media/pdf_viewer';
import styled from 'styled-components';
import AddMediaModal from '../overlays/AddImage/add_media_modal';

const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const ImagePlaceholderContainer = styled.div`
  padding: 12px 0px;
`;

const ImageActions = styled(CenteredButtons)`
  margin: 15px 0px;
`;

const ButtonContainer = styled.div`
  margin-right: 12px;
`;

const StepMedia = ({
  step,
  onChangeStep,
  allowedToModify,
}: {
  step: StepDTO;
  onChangeStep?: (oldStep: StepDTO, step: StepDTO) => void;
  allowedToModify: boolean;
}) => {
  const intl = useIntl();

  const [showAddMediaModal, setShowAddMediaModal] = useState(false);
  const [mediaTypeToAdd, setMediaTypeToAdd] = useState<MediaType>();

  const editStepMedia = (media: MediaPathsDTO | null) => {
    if (media !== null) {
      onChangeStep &&
        onChangeStep(step, {
          ...step,
          media: media,
        });
    } else {
      onChangeStep &&
        onChangeStep(step, {
          ...step,
          media: null,
        });
    }
    setShowAddMediaModal(false);
    setMediaTypeToAdd('PICTURE');
  };

  const onMediaError = (media: MediaPathsDTO | null, error?: any) => {
    if (media && media.fileType === 'VIDEO') {
      if (
        error.title === 'BAD_REQUEST' ||
        error.type === MediaErrorType.VIDEO_PLAYING_ERROR
      )
        toastrWarning(intl.formatMessage({ id: 'video_still_processing' }));
      else toastrWarning(intl.formatMessage({ id: 'video_processing_error' }));
    }
  };

  const onDeleteClicked = () => {
    onChangeStep &&
      onChangeStep(step, {
        ...step,
        media: null,
      });
  };

  const updatePdfPageRange = (range: PageRange) => {
    onChangeStep &&
      onChangeStep(step, {
        ...step,
        media: {
          ...step.media,
          pageRange: range,
          full: step.media?.full || '',
          thumb: step.media?.thumb || '',
          fileType: step.media?.fileType || 'PDF',
        },
      });
  };

  const addMedia = (type: MediaType) => {
    setMediaTypeToAdd(type);
    setShowAddMediaModal(true);
  };

  return (
    <>
      {step.media && step.media.full ? (
        <MediaContainer>
          {step.media.fileType !== 'PDF' ? (
            <>
              <LightboxWrapper canOpenLightbox media={step.media}>
                <StyledMedia
                  showErrorHint={true}
                  value={step.media}
                  onError={(error) => onMediaError(step.media, error)}
                />
              </LightboxWrapper>
              <ImageActions>
                <Button
                  type="secondary"
                  size="small"
                  icon="edit"
                  onClick={() => addMedia('PICTURE')}
                  disabled={!allowedToModify}
                />
                <Button
                  type="secondary"
                  size="small"
                  icon="cross"
                  onClick={onDeleteClicked}
                  disabled={!allowedToModify}
                />
              </ImageActions>
            </>
          ) : (
            <PdfViewer
              urls={
                step.media || {
                  full: '',
                  thumb: '',
                  fileType: 'PDF',
                }
              }
              scale={1}
              //width={size.width}
              //height={400}
              deleteComponent={
                <ButtonContainer>
                  <Button
                    type="secondary"
                    size="medium"
                    icon="cross"
                    onClick={onDeleteClicked}
                    disabled={!allowedToModify}
                  />
                </ButtonContainer>
              }
              updatePageRange={updatePdfPageRange}
            />
          )}
        </MediaContainer>
      ) : (
        <ImagePlaceholderContainer>
          <ImageActions>
            <Button
              type="secondary"
              icon="picture"
              label="photo"
              onClick={() => addMedia('PICTURE')}
              disabled={!allowedToModify}
            />
            <Button
              type="secondary"
              icon="arrow-right-big"
              label="video"
              onClick={() => addMedia('VIDEO')}
              disabled={!allowedToModify}
            />
            <Button
              type="secondary"
              icon="list"
              label="pdf"
              onClick={() => addMedia('PDF')}
              disabled={!allowedToModify}
            />
          </ImageActions>
        </ImagePlaceholderContainer>
      )}
      <AddMediaModal
        open={showAddMediaModal}
        mediaType={mediaTypeToAdd}
        onEditMedia={editStepMedia}
      />
    </>
  );
};

export default StepMedia;
