import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import ToggleSwitch from '@dev/base-web/dist/view/components/inputs/toggle_switch';
import InputWithCount from '@dev/base-web/dist/view/components/inputs/input_with_count';
import { InputOnChangeData } from 'semantic-ui-react';
import TemporaryTranslation from './temporary_translation';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import { InstructionWithTranslation } from '@/model/domain/instruction/translation.ts';

const ActionTitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: inset 0 -1px 0 0 #efefef;
  margin-bottom: 12px;
`;
const DivToggle = styled.div`
  display: inline-block;
`;

const ToggleLabelContainer = styled.div`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #8c8c8c;
  margin-top: 3px;
  margin-right: 8px;
`;

const StyledWideInput = styled(InputWithCount)`
  & {
    width: 100%;
  }
`;

export interface Props {
  readonly item: InstructionWithTranslation;
  readonly onChange: (value: string) => void;
  readonly selectedLanguage: string;
}

const InstructionTitleTranslationCard: React.FC<Props> = ({
  item,
  onChange,
  selectedLanguage,
}) => {
  const intl = useIntl();

  const [invalidValue, setInvalidValue] = useState(false);

  const translation = item.nameTranslations.find(
    (t) => t.targetLanguage === selectedLanguage
  );

  const [value, setValue] = useState(translation?.targetLanguageText ?? '');

  useEffect(() => {
    const newValue = translation?.targetLanguageText ?? '';
    if (newValue !== value) {
      setValue(newValue);
    }
  }, [translation, selectedLanguage]);

  if (!item) return null;

  const viewingLastEditedLanguage =
    translation?.sourceLanguage === translation?.targetLanguage;

  const didTranslationChange =
    translation?.previousTargetLanguageText !== translation?.targetLanguageText;

  return (
    <Card style={{ marginBottom: '24px', paddingBottom: 12 }}>
      <ActionTitleHeader>
        <h2 className="SectionTitleLeftLarge">
          <FormattedMessage id="action_title" />
        </h2>
        <DivToggle style={{ display: 'flex', flexDirection: 'row' }}>
          <ToggleLabelContainer>
            {!item.isHidden ? (
              <FormattedMessage id="action_active" />
            ) : (
              <FormattedMessage id="action_inactive" />
            )}
          </ToggleLabelContainer>
          <ToggleSwitch disabled checked={!item.isHidden} onChange={() => {}} />
        </DivToggle>
      </ActionTitleHeader>
      {!viewingLastEditedLanguage && didTranslationChange && (
        <TemporaryTranslation
          fromLanguageCode={translation?.sourceLanguage ?? ''}
          originalText={translation?.sourceLanguageText ?? ''}
          translatedText={translation?.previousTargetLanguageText ?? '-'}
        />
      )}
      <StyledWideInput
        className={invalidValue ? 'inputError' : ''}
        name="name"
        value={value}
        onChange={(_e: unknown, data: InputOnChangeData) => {
          setInvalidValue(!data.value);
          setValue(data.value);
          onChange(data.value);
        }}
        disabled={viewingLastEditedLanguage}
        placeholder={intl.formatMessage({ id: 'add_title_hint' })}
        showCount
      />
    </Card>
  );
};

export default InstructionTitleTranslationCard;
