import { RootReducerInterface } from '@/model/redux/interfaces';
import { connect } from 'react-redux';
import AutoGeneratePopup from './view';
import { AutoGeneratePopupStoreProps } from './interface';
import { getDataSourceOfEvent } from '@/model/redux/event_definition/thunks.ts';
import ManufacturingEntityThunks from '@/model/redux/manufacturing_entity/thunks.ts';
import { getInstructionSuggestionStream } from '@/model/redux/instruction_definition/thunks.ts';

const mapStateToProps = (
  state: RootReducerInterface
): AutoGeneratePopupStoreProps => {
  return {
    suggestionMeta:
      state.data.instructionDefinitionState.suggestedInstruction.meta,
    suggestion: state.data.instructionDefinitionState.suggestedInstruction.data,
    manufacturingEntity:
      state.data.manufacturingEntityState.manufacturingEntity.data,
    dataSourceOfEvent: state.data.eventDefinitionState.dataSourceOfEvent.data,
  };
};

export default connect(mapStateToProps, {
  getInstructionSuggestion: getInstructionSuggestionStream,
  getManufacturingEntity: ManufacturingEntityThunks.getManufacturingEntityById,
  getDataSourceOfEvent: getDataSourceOfEvent,
})(AutoGeneratePopup);
