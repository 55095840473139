import { InstructionStepTranslation } from '@/model/domain/instruction/translation.ts';

export const rebuildStepsRecursiveModify = (
  steps: readonly InstructionStepTranslation[],
  oldStep: InstructionStepTranslation,
  newStep: InstructionStepTranslation,
  parentId?: string
): readonly InstructionStepTranslation[] => {
  return rebuildStepsRecursiveModifyInternal(steps, oldStep, newStep, parentId);
};

const rebuildStepsRecursiveModifyInternal = (
  steps: readonly InstructionStepTranslation[],
  oldStep: InstructionStepTranslation,
  newStep: InstructionStepTranslation,
  parentId?: string,
  isActualParent?: boolean
): readonly InstructionStepTranslation[] => {
  if (!parentId || isActualParent) {
    const idx = steps.findIndex((s) => s.step === oldStep.step);

    const pre = steps.slice(0, idx);
    const post = steps.slice(idx + 1);

    return [...pre, newStep, ...post];
  } else {
    return steps.map((step) => ({
      ...step,
      decisionOptionTranslations: step.decisionOptionTranslations.map((o) => ({
        ...o,
        stepTranslations: rebuildStepsRecursiveModifyInternal(
          o.stepTranslations,
          oldStep,
          newStep,
          parentId,
          o.id === parentId
        ),
      })),
    }));
  }
};
