import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { emptyArray } from '@dev/base-web/dist/model/helpers/immutable_array';
import { DecisionBtn } from '@/components/instruction/components.tsx';
import { DecisionOptionDTO, StepDTO } from '@/model/domain/instruction/step.ts';
import { areStandardDecisionOptions } from '@/model/domain/instruction/helper.ts';
import { AddButton, DecisionItem } from './custom_decision_item';

const DecisionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 24px;
  gap: 10px;
`;

const TextWrapper = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
  padding: 38px 0px 38px 0px;
`;

const HintText = styled.p`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.43;
  color: #939393;
  text-align: left;
`;

const SmallerDecisionBtn = styled(DecisionBtn)`
  width: 64px;
`;

const IconWrapper = styled.div`
  width: 28px;
  padding-right: 8px;
`;

const standardDecisions = [
  {
    id: 'yes',
    name: 'yes',
    translations: [],
    steps: [],
  },
  {
    id: 'no',
    name: 'no',
    translations: [],
    steps: [],
  },
];

export interface AddDecisionModalProps {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly step: StepDTO | undefined;
  readonly addDecisionsCallback: (decision: StepDTO) => void;
}

const AddDecisionModal = ({
  open,
  step,
  addDecisionsCallback,
  onClose,
}: AddDecisionModalProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const [decisions, setDecisions] = useState<readonly DecisionOptionDTO[]>(
    emptyArray<DecisionOptionDTO>()
  );

  useEffect(() => {
    if (open) {
      setEditMode(false);

      if (step && !areStandardDecisionOptions(step.decisionOptions)) {
        setDecisions(step.decisionOptions);
      } else {
        setDecisions(emptyArray());
      }
    }
  }, [open, step]);

  const addDecision = (value: string) => {
    setDecisions((oldDecisions) => [
      ...oldDecisions,
      {
        id: uuidv4(),
        name: value,
        translations: [],
        steps: [],
      },
    ]);
  };

  const onSave = () => {
    if (step) {
      if (decisions.length === 0) {
        addDecisionsCallback({ ...step, decisionOptions: standardDecisions });
      } else if (decisions.length >= 2) {
        addDecisionsCallback({ ...step, decisionOptions: decisions });
      }
    }

    onClose();
  };

  return (
    <BasicModalWithHeaderAndTwoButtons
      modalSize="small"
      open={open}
      headerLabel="edit_decision_options"
      okLabel="save"
      okDisabled={editMode || decisions.length === 1}
      cancelLabel="cancel_button"
      onOk={onSave}
      onCancel={onClose}
      onClose={onClose}
      closeOnEscape={!editMode}
    >
      <DecisionWrapper>
        <SmallerDecisionBtn
          type="green"
          onClick={() => {}}
          text="yes"
          selected={false}
          disabled={decisions.length > 0}
        />
        <SmallerDecisionBtn
          type="red"
          onClick={() => {}}
          text="no"
          selected={false}
          disabled={decisions.length > 0}
        />
        {decisions.map((decision) => (
          <DecisionItem
            key={decision.id}
            item={decision}
            onDelete={() => {
              setDecisions((oldDecisions) =>
                oldDecisions.filter((d) => d !== decision)
              );
            }}
            onRename={(name) => {
              setDecisions((oldDecisions) =>
                oldDecisions.map((d) => (d === decision ? { ...d, name } : d))
              );
            }}
            onEditModeChange={setEditMode}
          />
        ))}
        <AddButton onAddOption={addDecision} onEditModeChange={setEditMode} />
      </DecisionWrapper>
      <TextWrapper>
        <IconWrapper>
          <Icon name="hand" color="#242424" size={24} />
        </IconWrapper>
        <HintText>
          <FormattedMessage id="custom_decision_options_modal_hint" />
        </HintText>
      </TextWrapper>
    </BasicModalWithHeaderAndTwoButtons>
  );
};

export default AddDecisionModal;
