import { connect } from 'react-redux';
import {
  createEvent,
  getEvent,
} from '../../model/redux/event_definition/thunks';
import { RootReducerInterface } from '../../model/redux/interfaces';
import { getParallelEvents } from '../../model/redux/parallel_events/thunks';
import { ParallelEventsScreenStorePropsInterface } from './interface';
import ParallelEventsView from './view';
import ParallelEventsEntry from '../../model/domain/parallel_events/parallel_events';
import ShannonConfig from '@dev/base-web/dist/model/domain/authorization/shannon';

export const CONFIG = ShannonConfig.Events;

const mapStateToProps = (
  state: RootReducerInterface
): ParallelEventsScreenStorePropsInterface => {
  return {
    event: state.data.eventDefinitionState.event.data,
    eventOperationState: state.data.eventDefinitionState.event.meta,
    parallelEvents: state.data.parallelEventsState.parallelEvents
      .data as unknown as ParallelEventsEntry[],
    parallelEventsOperationState:
      state.data.parallelEventsState.parallelEvents.meta,
    loadingInProgress:
      state.data.parallelEventsState.parallelEvents.meta.loadingInProgress,
    loadingFailed: state.data.parallelEventsState.parallelEvents.meta.error,
    eventCreationOperationState:
      state.data.eventDefinitionState.createEvent.meta,
  };
};

export default connect(mapStateToProps, {
  getParallelEvents,
  createEvent: createEvent,
  getEvent,
})(ParallelEventsView);
