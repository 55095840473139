import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import React from 'react';

export interface Props {
  readonly fromLanguageCode: string;
  readonly originalText: string;
  readonly translatedText: string;
}

const Table = styled.table`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  padding-bottom: 6px;
  td {
    padding-right: 16px;
  }
`;

const TemporaryTranslation: React.FC<Props> = ({
  fromLanguageCode,
  originalText,
  translatedText,
}) => {
  return (
    <Table>
      <tr>
        <td>
          <b>
            <FormattedMessage id={fromLanguageCode.toLowerCase()} />:
          </b>
        </td>
        <td>{originalText}</td>
      </tr>
      <tr>
        <td>
          <b>
            <FormattedMessage id="previous_translation" />:
          </b>
        </td>
        <td>{translatedText}</td>
      </tr>
    </Table>
  );
};

export default TemporaryTranslation;
