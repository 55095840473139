import { useEffect, useMemo, useState } from 'react';
import ManufacturingEntity, {
  ManufacturingVendorModelBase,
} from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { ContextCategory } from '@/model/domain/instruction/suggestion.ts';
import { Plc } from '@dev/base-web/dist/model/domain/plc/plc';
import { useIntl } from 'react-intl';
import { LabelOption } from '@dev/base-web/dist/model/domain/label/label';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import { BasicEvent } from '@/model/redux/actions/interface.ts';

const extractVendorModel = (
  m: ManufacturingEntity | null
): ManufacturingVendorModelBase | undefined => {
  if (!m) return undefined;
  else if (m?.vendorModel) return m.vendorModel;
  return m.parents?.reverse().find((p) => p.vendorModel)?.vendorModel;
};

const toCategoryArray = (
  vendorModel?: string,
  processTechnology?: string,
  dataSourceType?: string
): ContextCategory[] => {
  const arr: ContextCategory[] = [];

  if (vendorModel !== undefined)
    arr.push({
      category: 'VENDOR_MODEL',
      value: vendorModel,
    });

  if (processTechnology !== undefined)
    arr.push({
      category: 'PROCESS_TECHNOLOGY',
      value: processTechnology,
    });

  if (dataSourceType !== undefined)
    arr.push({
      category: 'DATA_SOURCE_TYPE',
      value: dataSourceType,
    });

  return arr;
};

const useCategoryHandling = (
  manufacturingEntity: ManufacturingEntity | null,
  dataSourceOfEvent: Plc | null,
  selectedEvent?: BasicEvent
) => {
  const intl = useIntl();
  const theme = useTheme();

  const [vendorModel, setVendorModel] = useState<string>();
  const [processTechnology, setProcessTechnology] = useState<string>();
  const [plcType, setPlcType] = useState<string>();

  useEffect(() => {
    const vendorModel = extractVendorModel(manufacturingEntity);
    if (vendorModel && selectedEvent) {
      setVendorModel(`${vendorModel.vendor} ${vendorModel.model}`);
      setProcessTechnology(vendorModel.processType);
    } else {
      setVendorModel(undefined);
      setProcessTechnology(undefined);
    }
  }, [manufacturingEntity, selectedEvent]);

  useEffect(() => {
    if (dataSourceOfEvent && selectedEvent) {
      setPlcType(
        intl.formatMessage({ id: dataSourceOfEvent.type.toLowerCase() })
      );
    }
  }, [dataSourceOfEvent, selectedEvent]);

  const selectedLabels = useMemo(() => {
    const arr: LabelOption[] = [];

    if (vendorModel !== undefined)
      arr.push({
        id: 'production_component',
        name: vendorModel,
        group: 'production_component',
        color: theme.buttons.tertiary.hovered.color,
      });

    if (processTechnology !== undefined)
      arr.push({
        id: 'process_technology',
        name: processTechnology,
        group: 'process_technology',
        color: theme.buttons.tertiary.hovered.color,
      });

    if (plcType !== undefined)
      arr.push({
        id: 'plc_type',
        name: plcType,
        group: 'plc_type',
        color: theme.buttons.tertiary.hovered.color,
      });

    return arr;
  }, [vendorModel, processTechnology, plcType]);

  const categoryArray = useMemo(() => {
    return toCategoryArray(vendorModel, processTechnology, plcType);
  }, [vendorModel, processTechnology, plcType]);

  const onCategorySelected = (category: string) => {
    if (category === 'production_component')
      setVendorModel(
        manufacturingEntity?.vendorModel
          ? `${manufacturingEntity.vendorModel.vendor} ${manufacturingEntity.vendorModel.model}`
          : ''
      );
    else if (category === 'process_technology')
      setProcessTechnology(manufacturingEntity?.vendorModel?.processType || '');
    else if (category === 'plc_type')
      setPlcType(
        dataSourceOfEvent
          ? intl.formatMessage({ id: dataSourceOfEvent.type.toLowerCase() })
          : ''
      );
  };

  const onRemoveCategory = (label: LabelOption) => {
    if (label.group === 'production_component') setVendorModel(undefined);
    else if (label.group === 'process_technology')
      setProcessTechnology(undefined);
    else if (label.group === 'plc_type') setPlcType(undefined);
  };

  const onLabelChanged = (id: string, label: string) => {
    if (id === 'production_component') setVendorModel(label);
    else if (id === 'process_technology') setProcessTechnology(label);
    else if (id === 'plc_type') setPlcType(label);
  };

  return {
    selectedLabels,
    categoryArray,
    onCategorySelected,
    onRemoveCategory,
    onLabelChanged,
  };
};

export default useCategoryHandling;
