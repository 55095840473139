import { Card } from '@dev/base-web/dist/view/components/global/card';
import { StyledInput } from '@dev/base-web/dist/view/components/inputs/styled_components';
import React from 'react';
import FunctionList from '@dev/base-web/dist/view/components/feature/function_card/function_list';
import styled from 'styled-components';
import FeatureConfigurationInstance, {
  FeatureFunction,
} from '@dev/base-web/dist/model/domain/feature/feature_configuration';
import { useIntl } from 'react-intl';

const CardContent = styled.div`
  margin-top: 1rem;
`;

const FunctionsCardContainer = styled(CardContent)`
  display: flex;
  flex-direction: column;
`;

const StyledFunctionList = styled(FunctionList)`
  max-height: 80vh;
  overflow: auto;

  @media (min-width: 1000px) {
    min-width: 200px;
    max-width: 20vw;
  }
`;

interface FunctionsCardProps {
  readonly functionSearch: string;
  readonly featureConfiguration: FeatureConfigurationInstance | null;
  readonly acceptableInput: string[];
  readonly setFunctionSearch: (search: string) => void;
  readonly onlyEdgeFunctions?: boolean;
}

const FunctionsCard = ({
  acceptableInput,
  featureConfiguration,
  functionSearch,
  setFunctionSearch,
  onlyEdgeFunctions,
}: FunctionsCardProps) => {
  const intl = useIntl();

  return (
    <Card titleId="functions">
      <FunctionsCardContainer>
        <StyledInput
          placeholder={intl.formatMessage({ id: 'search' }) + '...'}
          value={functionSearch}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFunctionSearch(e.target.value)
          }
        />
        {featureConfiguration && (
          <StyledFunctionList
            filter={functionSearch}
            functions={featureConfiguration.functions}
            acceptedInputs={acceptableInput}
            allowedFeatureFunctions={
              onlyEdgeFunctions
                ? [FeatureFunction.FALLING_EDGE, FeatureFunction.RISING_EDGE]
                : []
            }
          />
        )}
      </FunctionsCardContainer>
    </Card>
  );
};

export default FunctionsCard;
