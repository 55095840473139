import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';
import Event, {
  EventDefinition,
  EventDefinitionAdd,
  EventDefinitionStatus,
  EventDefinitionUpdate,
  EventFeedbackDTO,
} from '../../domain/event/event_definition';
import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import * as Constants from '../../../Constants';
import { PagingRequestResult } from '@dev/base-web/dist/model/api/common/paging_request_result';
import { DataApiSortConfig } from '@dev/base-web/dist/model/api/common/data_api_sort_config';

const EVENT_DEFINITION_URL = '/event/definition';
const EVENT_URL = (id: string) => `/event/definition/${id}`;
const ADD_INSTRUCTIONS_TO_EVENT_URL = (id: string) =>
  `/event/definition/${id}/actions`;
const DELETE_INSTRUCTION_FROM_EVENT_URL = (eventId: string, actionId: string) =>
  `/event/definition/${eventId}/action/${actionId}`;
const DELETE_COMMENT_FROM_EVENT_URL = (eventId: string, commentId: string) =>
  `/event/definition/${eventId}/comment/${commentId}`;
const EVENT_FEEDBACK_URL = (eventId: string) =>
  `/event/definition/${eventId}/feedback`;
const EVENT_SUBSCRIBE_URL = (eventId: string) =>
  `${EVENT_URL(eventId)}/subscribe`;
const EVENT_UNSUBSCRIBE_URL = (eventId: string) =>
  `${EVENT_URL(eventId)}/unsubscribe`;

//TODO: remove any
export default class EventDefinitionApi extends BaseDataApi<
  EventDefinition,
  any,
  any,
  EventDefinitionAdd
> {
  async getEvents(
    status: EventDefinitionStatus | null,
    page: number,
    filters: readonly FilterData[],
    token: string,
    sortConfig?: DataApiSortConfig,
    loadAllPagesUntilTheGivenOne?: boolean
  ): Promise<PagingRequestResult<Event>> {
    const archiveFilters = [];

    if (status) {
      const archiveFilter = new FilterData(
        'isHidden',
        FilterOperation.EQ_OPERATOR,
        status === EventDefinitionStatus.ARCHIVED
      );
      archiveFilters.push(archiveFilter);

      if (status !== EventDefinitionStatus.ARCHIVED) {
        const activeFilter = new FilterData(
          'isActive',
          FilterOperation.EQ_OPERATOR,
          status !== EventDefinitionStatus.INACTIVE
        );
        archiveFilters.push(activeFilter);
      }
    }

    let response;
    if (loadAllPagesUntilTheGivenOne) {
      response = await this.fetchPagesUntilTheGivenPageOfDataWithSearchAndSort(
        Constants.events,
        token,
        undefined,
        page,
        [...filters, ...archiveFilters],
        sortConfig
      );
    } else {
      response = await this.fetchOnePageOfDataWithSearchAndSort(
        Constants.events,
        token,
        undefined,
        page,
        [...filters, ...archiveFilters],
        sortConfig
      );
    }
    return {
      results: response.content,
      hasMoreResults: !response.last,
      totalElements: response.totalElements,
    };
  }

  async multiUpdateEvents(
    ids: string[],
    params: any,
    token: string
  ): Promise<EventDefinitionUpdate> {
    if (params.id) {
      delete params.id;
    }
    const body = { ids: ids, updates: params };
    return this.updateItem(body, EVENT_DEFINITION_URL, token);
  }

  async updateEvent(
    id: string,
    data: EventDefinitionAdd,
    token: string
  ): Promise<EventDefinition> {
    return this.updateItem(data, EVENT_URL(id), token);
  }

  async getEvent(id: string, token: string): Promise<EventDefinition> {
    const url = EVENT_URL(id);
    return await this.fetchOneItemByIdWithUrl(token, url);
  }

  async addInstructionsToEvent(
    eventId: string,
    actionIds: string[],
    token: string
  ) {
    const url = ADD_INSTRUCTIONS_TO_EVENT_URL(eventId);
    const body = { ids: actionIds };

    return this.executePutRequest(token, url, undefined, body);
  }
  async createEvent(
    data: EventDefinitionAdd,
    token: string
  ): Promise<EventDefinition> {
    const url = EVENT_DEFINITION_URL;
    return this.createItem(data, url, token);
  }

  async removeInstructionFromEvent(
    eventId: string,
    actionId: string,
    token: string
  ) {
    const url = DELETE_INSTRUCTION_FROM_EVENT_URL(eventId, actionId);
    return this.deleteItem(url, token);
  }

  async removeCommentFromEvent(
    eventId: string,
    commentId: string,
    token: string
  ) {
    const url = DELETE_COMMENT_FROM_EVENT_URL(eventId, commentId);
    return this.deleteItem(url, token);
  }

  async addComment(
    eventDefinitionId: string,
    comment: EventFeedbackDTO,
    token: string
  ) {
    const url = EVENT_FEEDBACK_URL(eventDefinitionId);
    return this.executePostRequest(token, url, undefined, comment);
  }

  async subscribeToEvent(id: string, token: string) {
    const url = EVENT_SUBSCRIBE_URL(id);
    return this.executePostRequest(token, url, undefined, {});
  }

  async unsubscribeFromEvent(id: string, token: string) {
    const url = EVENT_UNSUBSCRIBE_URL(id);
    return this.executePostRequest(token, url, undefined, {});
  }
}
