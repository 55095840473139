import {
  Column,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import { useEffect, useState } from 'react';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { findAndCheckStringFilter } from '@dev/base-web/dist/view/helpers/local_filter_helpers';
import { sortBasedOnStringValue } from '@dev/base-web/dist/view/helpers/local_sorting_helpers';
import { GlossaryGroupedEntry } from '../../../../model/domain/translation/glossary';
import { LanguageTitle } from './styled_components';

export const KEY = 'key';
const SOURCE_LANGUAGE_KEY = 'sourceLanguage';
export const TRANSLATION_KEY = 'translations';

export const columns = (languages: string[]): Array<Column> => {
  return [
    {
      title: 'source_language',
      isLeftAligned: true,
      sortKey: SOURCE_LANGUAGE_KEY,
      filterType: ColumnFilterType.SELECTOR,
      filterOptions: languages.map((l) => {
        return {
          value: l.toUpperCase(),
          textId: l,
          content: <LanguageTitle language={l} />,
        };
      }),
      width: 4,
    },
    {
      title: KEY,
      sortKey: KEY,
      isLeftAligned: true,
      filterType: ColumnFilterType.TEXT,
      width: 4,
    },
    {
      title: TRANSLATION_KEY,
      isLeftAligned: true,
      filterType: ColumnFilterType.NONE,
      width: 8,
    },
  ];
};

const applySorting = (
  steps: GlossaryGroupedEntry[],
  localSortKey?: string,
  localSortDirection?: SortingDirection
) => {
  const sortedData = [...steps];
  let sortFunction: (
    a: GlossaryGroupedEntry,
    b: GlossaryGroupedEntry
  ) => number = () => 1;

  if (localSortKey && localSortDirection) {
    switch (localSortKey) {
      case SOURCE_LANGUAGE_KEY:
        sortFunction = (a, b) =>
          sortBasedOnStringValue(
            a.sourceLanguage,
            b.sourceLanguage,
            localSortDirection
          ) || sortBasedOnStringValue(a.key, b.key, SortingDirection.ASCENDING);
        break;
      case KEY:
        sortFunction = (a, b) =>
          sortBasedOnStringValue(a.key, b.key, localSortDirection);
        break;
    }
  }

  return sortedData.sort(sortFunction);
};

export const useGlossaryTableSortingAndFiltering = (
  labels: readonly GlossaryGroupedEntry[],
  localFilters: readonly FilterData[],
  localSortKey?: string,
  localSortDirection?: SortingDirection
) => {
  const [filteredData, setFilteredData] = useState<GlossaryGroupedEntry[]>([]);

  useEffect(() => {
    const newFilteredData = labels
      .filter((l) =>
        findAndCheckStringFilter(
          l.sourceLanguage || '',
          localFilters,
          SOURCE_LANGUAGE_KEY
        )
      )
      .filter((l) => findAndCheckStringFilter(l.key || '', localFilters, KEY));

    const filteredAndSortedLabels = applySorting(
      newFilteredData,
      localSortKey,
      localSortDirection
    );

    setFilteredData(filteredAndSortedLabels);
  }, [labels, localFilters, localSortKey, localSortDirection]);

  return filteredData;
};
