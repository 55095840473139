import createThunks from '@dev/base-web/dist/model/redux/events/thunks_factory';
import { eventsApi, localEventsApi, rawEventsApi } from '../../api';
import EventDetail, { EventForwardRequest } from '../../domain/event/event';
import { RootReducerInterface } from '../interfaces';
import { Actions } from './actions';
import {
  paginatedListFetchingWithAbort,
  VoidThunk,
} from '@dev/base-web/dist/model/redux/helpers/thunks';
import { OperationType } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import { Dispatch } from 'redux';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import RawEvent from '../../domain/event/raw_event';
import {
  DataApiSortConfig,
  SortingDirection,
} from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import { dummyItem } from '@/components/events_list_with_filters/components/dummy_item';

const baseThunks = createThunks(eventsApi, {
  // TODO: add implementation for showing toasts
  showInfo: () => () => Promise.resolve(),
  showSuccess: () => () => Promise.resolve(),
  showError: () => () => Promise.resolve(),
  showErrorText: () => () => Promise.resolve(),
});

export default {
  ...baseThunks,
  getCurrentEvent:
    (id: string): VoidThunk<RootReducerInterface> =>
    async (dispatch, getState) => {
      const { token } = getState().authenticationState.authentication;

      dispatch(Actions.currentEvent.meta.startLoading());

      try {
        if (id === 'dum') {
          dispatch(
            Actions.currentEvent.loadingItemSuccessful(dummyItem as EventDetail)
          );
        } else {
          const currentEvent = await localEventsApi.getCurrentEvent(
            id,
            token.accessToken
          );
          dispatch(Actions.currentEvent.loadingItemSuccessful(currentEvent));
        }
      } catch (error) {
        dispatch(Actions.currentEvent.meta.loadingFailed({ error }));
      }
      dispatch(Actions.currentEvent.meta.endLoading());
    },

  closeEvent:
    (eventId: string, closeDate?: number): VoidThunk<RootReducerInterface> =>
    async (dispatch, getState) => {
      const { token } = getState().authenticationState.authentication;
      dispatch(
        Actions.closeEventUpdate.meta.startOperation({
          operation: OperationType.UPDATE,
        })
      );
      try {
        await localEventsApi.closeEvent(eventId, token.accessToken, closeDate);
        dispatch(Actions.closeEventUpdate.meta.operationSucceeded());
      } catch (error) {
        dispatch(Actions.closeEventUpdate.meta.operationFailed({ error }));
      }
    },

  archiveEvents:
    (ids: string[], isHidden: boolean) =>
    async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
      const token =
        getState().authenticationState.authentication.token.accessToken;

      dispatch(
        Actions.archiveEvent.meta.startOperation({
          operation: OperationType.UPDATE,
        })
      );
      try {
        await localEventsApi.archiveEvents(token, ids, isHidden);
        dispatch(Actions.archiveEvent.meta.operationSucceeded());
      } catch (error) {
        dispatch(Actions.archiveEvent.meta.operationFailed({ error }));
      }
    },
  getRawEvents: (
    page: number,
    filters: readonly FilterData[],
    sortKey?: string,
    sortDirection?: SortingDirection
  ) =>
    paginatedListFetchingWithAbort<RawEvent, RootReducerInterface>(
      page,
      async (accessToken, abort) => {
        return await rawEventsApi.getRawEvents(
          page,
          filters,
          new DataApiSortConfig(
            sortKey || 'start',
            sortDirection || SortingDirection.DESCENDING
          ),
          accessToken,
          abort.signal
        );
      },
      (state) => state.data.eventState.rawEvents,
      Actions.rawEvents,
      false
    ),
  forwardEvent:
    (id: string, request: EventForwardRequest) =>
    async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
      const token =
        getState().authenticationState.authentication.token.accessToken;

      dispatch(
        Actions.forwardEvent.meta.startOperation({
          operation: OperationType.UPDATE,
        })
      );
      try {
        await localEventsApi.forwardEvent(token, id, request);
        dispatch(Actions.forwardEvent.meta.operationSucceeded());
      } catch (error) {
        dispatch(Actions.forwardEvent.meta.operationFailed({ error }));
      }
    },
};
