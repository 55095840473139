import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';
import Inputfield from '../Inputs/Inputfield';
import { AddItemsItem } from './AddItemsItem';
import { createGetInstructionsThunks } from '../../model/redux/instruction_definition/thunks';
import { RootReducerInterface } from '../../model/redux/interfaces';
import DetailLoader from '@dev/base-web/dist/view/components/global/detail_loader';
import {
  InstructionOverview,
  ActionDTO,
} from '../../model/domain/instruction/instruction';
import {
  DataApiSortConfig,
  SortingDirection,
} from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import { Actions } from '../../model/redux/instruction_definition/actions';

//TODO: this whole file should be refactored: it has a lot of anys, not using theme, split up event and instruction handling...

const SearchHeader = styled.div`
  background: #f7f7f7;
  padding: 24px;
`;

const AddItemList = styled.div`
  padding: 0 24px;
`;

const NoItems = styled.p`
  text-align: center;
  padding: 15px 0;
  font-weight: bold;
`;

interface Props extends WrappedComponentProps {
  readonly open: boolean;
  readonly title: any;
  readonly method: string;
  readonly id: any;
  readonly add: any;
  readonly addCallback: any;
  readonly closeCallback: any;
  readonly items: readonly ActionDTO[];
  readonly getInstructions: (
    isApproved: boolean,
    isArchived: boolean,
    page: number,
    filters: FilterData[],
    sortingKey: string,
    sorting: SortingDirection,
    loadAllPagesUntilTheGivenOne?: boolean
  ) => void;
  readonly instructions: InstructionOverview[];
}

interface State {
  readonly last: boolean;
  readonly method: string;
  readonly addItemToCollection: any;
  readonly oldItems: any;
  readonly checkedItems: any;
  readonly addCallback: any;
  readonly sort: DataApiSortConfig;
  readonly search: FilterData[];
  readonly pagination: any;
  readonly items: any;
}

class AddItems extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      last: false,
      method: props.method,
      addItemToCollection: props.add,
      oldItems: props.items,
      checkedItems: [],
      addCallback: props.addCallback,
      sort: {
        key: 'name',
        direction: SortingDirection.ASCENDING,
      },
      search: [
        {
          key: 'isHidden',
          operation: FilterOperation.EQ_OPERATOR,
          value: false,
        },
      ],
      pagination: {
        page: 0,
        size: 50,
      },
      items: [],
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.open && this.props.open) {
      this.getItems();
    }
  }

  getItems = () => {
    const options = {
      sort: this.state.sort,
      search: this.state.search,
      pagination: this.state.pagination,
    };

    this.props.getInstructions(
      true,
      false,
      options.pagination.page,
      options.search,
      options.sort.key,
      options.sort.direction
    );
  };

  checkItem = (item: { id: string }) => {
    let checkedItems = this.state.checkedItems;
    if (checkedItems.find((cItem: { id: string }) => cItem.id === item.id)) {
      checkedItems = checkedItems.filter(
        (cItem: { id: string }) => cItem.id !== item.id
      );
    } else {
      checkedItems.push(item);
    }
    this.setState({ checkedItems });
  };

  onSearch = (e: { target: { value: string } }) => {
    const globalFilterKey = this.state.method === 'event' ? 'global' : 'name';

    this.setState(
      {
        search: [
          {
            key: 'isHidden',
            operation: FilterOperation.EQ_OPERATOR,
            value: false,
          },
          {
            key: globalFilterKey,
            operation: FilterOperation.CT_OPERATOR,
            value: e.target.value,
          },
        ],
        pagination: {
          page: 0,
          size: 50,
        },
      },
      () => {
        this.getItems();
      }
    );
  };

  addItemToCollection = () => {
    const arr = this.state.checkedItems.map((item: { id: any }) => item.id);
    const { id } = this.props.id;
    this.state
      .addItemToCollection(id, arr)
      .then(() => {
        this.state.addCallback();
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  loadMore = () => {
    const { pagination } = this.state;
    pagination.page += 1;
    this.setState({ pagination }, () => {
      this.getItems();
    });
  };

  render() {
    let diff: InstructionOverview[] = [];
    const { intl, instructions } = this.props;
    if (instructions) {
      diff = instructions.filter((action: { id: string }) => {
        const alreadyAdded = this.state.oldItems?.find(
          (oItem: { id: string }) => oItem.id === action.id
        );
        if (!alreadyAdded) {
          return action;
        }
      });
    }

    return (
      <BasicModalWithHeaderAndTwoButtons
        open={this.props.open}
        headerLabel={
          this.props.method == 'action' ? 'add_solutions' : 'add_events'
        }
        cancelLabel="cancel_button"
        okLabel="add"
        okDisabled={this.state.checkedItems.length < 1}
        onOk={this.addItemToCollection}
        onCancel={this.props.closeCallback}
        onClose={this.props.closeCallback}
      >
        <SearchHeader>
          <Inputfield
            placeholder={intl.formatMessage({ id: 'search' })}
            onChange={this.onSearch}
          />
        </SearchHeader>
        {diff.length > 0 ? (
          <div id="scrollContainer" style={{ overflowY: 'scroll' }}>
            <InfiniteScroll
              dataLength={diff.length}
              next={this.loadMore}
              hasMore={!this.state.last}
              loader={<DetailLoader />}
              scrollableTarget="scrollContainer"
              height={400}
            >
              <AddItemList>
                {diff.map((item) => {
                  const checked = this.state.checkedItems.some(
                    (cItem: { id: any }) => cItem.id === item.id
                  );
                  return (
                    <AddItemsItem
                      onClick={this.checkItem}
                      item={item}
                      checked={checked}
                    />
                  );
                })}
              </AddItemList>
            </InfiniteScroll>
          </div>
        ) : (
          <NoItems>
            <FormattedMessage
              id="no_items_available"
              values={{ title: this.props.title }}
            />
          </NoItems>
        )}
      </BasicModalWithHeaderAndTwoButtons>
    );
  }
}

const mapStateToProps = (state: RootReducerInterface) => ({
  instructions:
    state.data.instructionDefinitionState.instructionsForAddRelationModal.data
      .results,
});

export default connect(mapStateToProps, {
  getInstructions: createGetInstructionsThunks(
    Actions.instructionsForAddRelationModal
  ),
})(injectIntl(AddItems));
