import React from 'react';
import { DecisionBtnRow } from './components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { StepNumber } from '../StepNumber';
import {
  DisplayedDecision,
  DisplayedStep,
} from '@/model/domain/instruction/step.ts';
import Media from '../Media';
import PdfViewer from '../../screens/edit_instruction/components/media/pdf_viewer';
import AdditionalSolutionInfo from '../../screens/edit_instruction/components/additional_solution_info';
import { getTranslation } from '@/model/domain/instruction/helper.ts';

const P = styled.p`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #242424;
  margin: 0;
  padding: 0;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 18px;
`;

const ListElement = styled.li`
  //height: 16px;
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #242424;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BulletStepNumber = styled(StepNumber)`
  margin-left: 0px;
  margin-right: 10px;
  min-width: 20px;
`;

const StyledMedia = styled(Media)`
  max-width: 90%;
  padding-left: 30px;
  min-height: 100px;
`;

interface Props {
  readonly displayedSteps: DisplayedStep[];
  readonly onSelect?: (s: DisplayedStep, d: DisplayedDecision) => void;
  readonly selectedLanguage: string;
}
const InstructionStepList: React.FC<Props> = ({
  displayedSteps,
  onSelect,
  selectedLanguage,
}) => {
  return (
    <List>
      {displayedSteps ? (
        displayedSteps.map((e, index) => {
          return (
            <div key={index} style={{ marginBottom: '24px' }}>
              <ListElement>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <BulletStepNumber className={'Wrapper'}>
                    {index + 1}
                  </BulletStepNumber>
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    {getTranslation(e.orig.translations, '', selectedLanguage)}
                  </span>
                </div>
              </ListElement>
              {e.orig.media &&
                (e.orig.media.fileType !== 'PDF' ? (
                  <StyledMedia showErrorHint={true} value={e.orig.media} />
                ) : (
                  <PdfViewer urls={e.orig.media} scale={1} />
                ))}
              <AdditionalSolutionInfo infos={e.orig.additionalInfos} />

              <DecisionBtnRow value={e} onSelect={onSelect || (() => {})} />
            </div>
          );
        })
      ) : (
        <P>{<FormattedMessage id="no_actions_info" />}</P>
      )}
    </List>
  );
};

export default InstructionStepList;
