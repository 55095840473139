import { mediaApi } from '../model/api';
import mediaCompontentFactory, {
  mediaThumbnailComponentFactory,
} from '@dev/base-web/dist/view/components/global/media';
import { useApiBlobAsObjectUrlFactory } from '@dev/base-web/dist/view/components/global/media_hooks';

const Media = mediaCompontentFactory(mediaApi);

export const MediaThumbnail = mediaThumbnailComponentFactory(mediaApi);

export const useApiBlob = useApiBlobAsObjectUrlFactory(mediaApi);

export const convertMediaToBlob = (src: string) => {
  const result = useApiBlob(src, false);
  return result[0];
};

export default Media;
