import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import OpeningConditionCard from '../../event_definition/components/event_rule_config/opening_condition_card';
import ClosingConditionCard from '../../event_definition/components/event_rule_config/closing_condition_card';
import { OneToOneOperation } from '@dev/base-web/dist/model/domain/feature/feature';
import sizeMe, { SizeMeProps } from 'react-sizeme';
import { FeatureColors } from '@dev/base-web/dist/view/components/feature/styles';
import FeatureConfigurationInstance from '@dev/base-web/dist/model/domain/feature/feature_configuration';

const Description = styled.h2`
  margin: 16px 0 8px 0;
  font-size: 14px;
  color: #8c8c8c;
`;

const GridWrapper = styled.div<{ column?: boolean }>`
  display: flex;
  margin-bottom: 48px;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  @media (max-width: 1200px) {
    flex-direction: column !important;
  }
  gap: 32px;
`;

const TriggerWrapper = styled.div`
  flex: 1;
  display: inline-block;
`;

const Wrapper = styled.div<{ inline?: boolean }>`
  display: ${({ inline }) => (inline ? 'auto' : 'inline-block;')};
`;

function getCounterFeatureOperation(operation: string) {
  return operation === OneToOneOperation.RISING_EDGE
    ? OneToOneOperation.FALLING_EDGE
    : OneToOneOperation.RISING_EDGE;
}

const featureColors: FeatureColors = {
  even: '#ffffff',
  odd: '#e8ebea',
};

const getOppositeFeature = (condition: any) => {
  if (
    !condition ||
    condition === null ||
    condition.value === null ||
    !condition.value ||
    !condition.type ||
    condition.type !== 'FEATURE'
  )
    return null;
  else
    return {
      ...condition,
      value: {
        ...condition.value,
        operation: getCounterFeatureOperation(condition.value.operation),
      },
    };
};

interface RuleCopyModalExternalProps {
  rule: any;
  newStage: string;
  readonly featureConfiguration: FeatureConfigurationInstance | null;
}

interface RuleCopyModalState {
  column: boolean;
}

type RuleCopyModalProps = RuleCopyModalExternalProps & SizeMeProps;

class RuleCopyModalContent extends Component<
  RuleCopyModalProps,
  RuleCopyModalState
> {
  constructor(props: RuleCopyModalProps) {
    super(props);

    this.state = {
      column: false,
    };
  }

  componentDidMount() {
    if (
      this.props.size &&
      this.props.size.width !== null &&
      this.props.size.width > 1100
    ) {
      this.setState({ column: true });
    }
  }

  componentDidUpdate(prevProps: RuleCopyModalProps) {
    const { size: prevSize } = prevProps;

    if (
      this.props.size !== prevSize &&
      this.props.size &&
      this.props.size.width !== null &&
      this.props.size.width > 1100
    ) {
      this.setState({ column: true });
    }
  }

  render() {
    const { newStage, rule, featureConfiguration } = this.props;

    const previousStage = newStage === 'open' ? 'close' : 'open';

    return (
      <Wrapper inline={this.state.column}>
        <GridWrapper column={this.state.column}>
          <TriggerWrapper>
            <Description>
              <FormattedMessage id="new_trigger_feature" />
            </Description>
            {rule !== null && (
              <>
                {newStage === 'close' ? (
                  <ClosingConditionCard
                    condition={getOppositeFeature(rule[previousStage])}
                    odd
                    replaceColors={featureColors}
                    featureConfiguration={featureConfiguration}
                  ></ClosingConditionCard>
                ) : (
                  <OpeningConditionCard
                    condition={getOppositeFeature(rule[previousStage])}
                    odd
                    replaceColors={featureColors}
                    featureConfiguration={featureConfiguration}
                  ></OpeningConditionCard>
                )}
              </>
            )}
          </TriggerWrapper>
          <TriggerWrapper>
            <Description>
              <FormattedMessage id="old_trigger_feature" />
            </Description>
            {rule !== null && (
              <>
                {newStage === 'close' ? (
                  <ClosingConditionCard
                    condition={rule[newStage]}
                    odd
                    replaceColors={featureColors}
                    featureConfiguration={featureConfiguration}
                  ></ClosingConditionCard>
                ) : (
                  <OpeningConditionCard
                    condition={rule[newStage]}
                    odd
                    replaceColors={featureColors}
                    featureConfiguration={featureConfiguration}
                  ></OpeningConditionCard>
                )}
              </>
            )}
          </TriggerWrapper>
        </GridWrapper>
      </Wrapper>
    );
  }
}

export default sizeMe({ monitorWidth: true })(RuleCopyModalContent);
