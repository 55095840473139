import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';
import CompletionChoice from '../../domain/event/solution_suggestion';
import { InstructionSuggestionRequest } from '@/model/domain/instruction/suggestion.ts';

export default class InstructionSuggestionApi extends BaseDataApi<CompletionChoice> {
  async getInstructionSuggestion(
    request: InstructionSuggestionRequest,
    token: string
  ): Promise<CompletionChoice[]> {
    return await this.executePostRequest(
      token,
      '/action/definition/suggest',
      undefined,
      request
    );
  }

  async getCachedInstructionSuggestion(
    suggestionId: string,
    token: string
  ): Promise<CompletionChoice[]> {
    return await this.executeGetRequest(
      token,
      `/action/definition/suggest/${suggestionId}`,
      undefined
    );
  }
}
