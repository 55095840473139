import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import UserRole from '@dev/base-web/dist/model/domain/user/user_role';
import UserSubscriptionTopic from '@dev/base-web/dist/model/domain/user/user_subscription_topic';
import { Column } from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import UserSubscriptionTopicSettings from '../../../../model/domain/user/user_subscription_topic_setting';
import NotificationRow from './notifications_row';
import {
  getSubscriptionTopicSettings,
  updateSubscriptionTopic,
} from './helper';

export interface NotificationSettingsProps {
  readonly userRole?: UserRole | string;
  readonly subscriptionTopics?: UserSubscriptionTopic[];
  readonly updateSubscriptionTopics: (topics: UserSubscriptionTopic[]) => void;
}

const columns: Array<Column> = [
  {
    title: 'type',
    width: 8,
    isLeftAligned: true,
  },
  {
    title: 'email',
    width: 4,
  },
  {
    title: 'push_notification',
    width: 4,
  },
];

const NotificationSettings: React.FC<NotificationSettingsProps> = ({
  subscriptionTopics,
  userRole,
  updateSubscriptionTopics,
}) => {
  const notificationElements: UserSubscriptionTopicSettings[] =
    subscriptionTopics && userRole
      ? getSubscriptionTopicSettings(userRole, subscriptionTopics)
      : [];

  const updateTopic = (topic: UserSubscriptionTopicSettings) => {
    const updatedTopics = updateSubscriptionTopic(
      subscriptionTopics || [],
      topic
    );
    updateSubscriptionTopics(updatedTopics);
  };

  return (
    <SortableTable
      dataLength={notificationElements.length}
      columns={columns}
      hasMoreResults={false}
      loadingInProgress={false}
      getNextResults={() => {}}
      filtersDisabled
    >
      {notificationElements &&
        notificationElements.map((n) => {
          return <NotificationRow topic={n} updateTopic={updateTopic} />;
        })}
    </SortableTable>
  );
};

export default NotificationSettings;
