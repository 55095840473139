import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Icon from '@dev/base-web/dist/view/components/global/icon';

const ItemWrapper = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const MarkerText = styled.span`
  height: 16px;
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #242424;
`;

export interface ItemType {
  id: string;
  name: string;
}

export const AddItemsItem = (
  props: Readonly<{
    item: ItemType;
    checked?: boolean;
    onClick: (item: ItemType) => void;
  }>
) => {
  const { item, checked } = props;

  const checkItem = () => {
    props.onClick(item);
  };

  const intl = useIntl();

  return (
    <ItemWrapper onClick={checkItem}>
      <MarkerText>{intl.formatMessage({ id: item.name })}</MarkerText>
      {checked ? <Icon name="checkmark" color="#0da6a0" size={16} /> : null}
    </ItemWrapper>
  );
};
