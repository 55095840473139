import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootReducerInterface } from './interfaces';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () =>
  useDispatch<ThunkDispatch<RootReducerInterface, any, AnyAction>>();
export const useAppSelector: TypedUseSelectorHook<RootReducerInterface> =
  useSelector;
