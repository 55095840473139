import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';
import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import {
  ActionDefinitionHistoryDTO,
  ApproveRequest,
  BasicActionDefinitionDTO,
  InstructionOverview,
  ActionDefinitionDTO,
} from '../../domain/instruction/instruction';
import { PagingRequestResult } from '@dev/base-web/dist/model/api/common/paging_request_result';
import { DataApiSortConfig } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import { ActionDefinitionTranslationDTO } from '@/model/domain/instruction/translation.ts';
import { toTranslationRequest } from '@/model/domain/instruction/translation_helper.ts';

const TRANSLATE_INSTRUCTION_URL = (
  id: string,
  language: string,
  tenantId: string
) =>
  `${tenantId.toLowerCase()}/${language.toLowerCase()}/action/definition/${id}/translate`;

const APPROVE_INSTRUCTION_URL = (
  id: string,
  revision: number,
  language?: string,
  tenantId?: string
) => `${INSTRUCTION_URL(id, language, tenantId)}/approve/${revision}`;

const DELETE_COMMENT_URL = (commentId: string, instructionId: string) =>
  `/action/definition/${instructionId}/comment/${commentId}`;

const BASE_INSTRUCTION_URL = (language?: string, tenantId?: string) =>
  language && tenantId
    ? `${tenantId.toLowerCase()}/${language.toLowerCase()}/action/definition`
    : `/action/definition`;

const INSTRUCTION_URL = (id: string, language?: string, tenantId?: string) =>
  `${BASE_INSTRUCTION_URL(language, tenantId)}/${id}`;

const REQUEST_REVIEW_URL = (id: string) =>
  `${BASE_INSTRUCTION_URL()}/${id}/review/request`;

const INSTRUCTION_EVENT_URL = (eventId: string) => `/event/${eventId}`;

const ARCHIVE_INSTRUCTIONS_URL = (
  isHidden: boolean,
  language?: string,
  tenantId?: string
) => `${BASE_INSTRUCTION_URL(language, tenantId)}/archive/${isHidden}`;

export default class InstructionDefinitionApi extends BaseDataApi<
  InstructionOverview,
  ActionDefinitionDTO,
  BasicActionDefinitionDTO,
  BasicActionDefinitionDTO
> {
  async getInstruction(
    id: string,
    token: string,
    revision?: number,
    language?: string,
    tenantId?: string
  ): Promise<ActionDefinitionDTO> {
    let url = INSTRUCTION_URL(id, language, tenantId);

    if (revision) url = url + `?revision=${revision}`;

    return await this.fetchOneItemByIdWithUrl(token, url);
  }

  async getInstructions(
    isApproved: boolean,
    isArchive: boolean,
    page: number,
    filters: readonly FilterData[],
    token: string,
    sortConfig?: DataApiSortConfig,
    loadAllPagesUntilTheGivenOne?: boolean
  ): Promise<PagingRequestResult<InstructionOverview>> {
    const archiveFilters = [];
    const approvedFilters = [];

    const approvedFilter = new FilterData(
      'isApproved',
      FilterOperation.EQ_OPERATOR,
      isApproved
    );

    if (!isArchive) {
      approvedFilters.push(approvedFilter);
    }

    const archiveFilter = new FilterData(
      'isHidden',
      FilterOperation.EQ_OPERATOR,
      isArchive
    );
    archiveFilters.push(archiveFilter);

    let response;
    if (loadAllPagesUntilTheGivenOne) {
      response = await this.fetchPagesUntilTheGivenPageOfDataWithSearchAndSort(
        `${BASE_INSTRUCTION_URL()}/search`,
        token,
        undefined,
        page,
        [...filters, ...archiveFilters, ...approvedFilters],
        sortConfig
      );
    } else {
      response = await this.fetchOnePageOfDataWithSearchAndSort(
        `${BASE_INSTRUCTION_URL()}/search`,
        token,
        undefined,
        page,
        [...filters, ...archiveFilters, ...approvedFilters],
        sortConfig
      );
    }
    return {
      results: response.content as InstructionOverview[],
      hasMoreResults: !response.last,
      totalElements: response.totalElements,
    };
  }

  async getInstructionHistory(
    id: string,
    token: string,
    signal: AbortSignal
  ): Promise<ActionDefinitionHistoryDTO[]> {
    const url = INSTRUCTION_URL(id) + '/history';
    return this.executeGetRequest(token, url, signal);
  }

  async approveInstruction(
    id: string,
    revision: number,
    token: string,
    approve?: boolean,
    language?: string,
    tenantId?: string
  ) {
    const url = APPROVE_INSTRUCTION_URL(id, revision, language, tenantId);
    let formData: ApproveRequest = { isApproved: true } as ApproveRequest;
    if (approve !== undefined) {
      formData = { ...formData, isApproved: approve };
    }

    return this.updateItem<ActionDefinitionDTO>(formData as any, url, token);
  }

  createInstruction(
    data: BasicActionDefinitionDTO,
    token: string,
    language: string,
    tenantId?: string
  ): Promise<ActionDefinitionDTO> {
    const url = `${BASE_INSTRUCTION_URL(
      language,
      tenantId
    )}?notTriggerReview=true`;

    //TODO: to fix api issue requiring deprecated name fields
    const i = toTranslationRequest(data, language.toUpperCase());

    return this.createItem(
      i,
      url,
      token
    ) as unknown as Promise<ActionDefinitionDTO>;
  }

  async duplicateInstruction(
    id: string,
    token: string,
    language?: string,
    tenantId?: string
  ): Promise<ActionDefinitionDTO> {
    const url = INSTRUCTION_URL(id, language, tenantId) + '/duplicate';
    return (await this.executePostRequest(
      token,
      url,
      undefined,
      undefined
    )) as unknown as ActionDefinitionDTO;
  }

  async updateInstruction(
    id: string,
    data: BasicActionDefinitionDTO,
    token: string,
    language?: string,
    tenantId?: string
  ) {
    const url = `${INSTRUCTION_URL(
      id,
      language,
      tenantId
    )}?notTriggerReview=true`;
    return this.updateItem<ActionDefinitionDTO>(data, url, token);
  }

  async removeEventFromInstruction(id: string, eventId: string, token: string) {
    const url = INSTRUCTION_URL(id) + INSTRUCTION_EVENT_URL(eventId);
    return this.deleteItem(url, token);
  }

  async addEventsToInstruction(id: string, eventIds: string[], token: string) {
    const url = INSTRUCTION_URL(id) + '/events';
    return this.executePutRequest(token, url, undefined, { ids: eventIds });
  }

  async deleteCommentByUrl(
    instructionId: string,
    commentId: string,
    token: string
  ) {
    const url = DELETE_COMMENT_URL(commentId, instructionId);
    return this.deleteItem(url, token);
  }

  async translateInstruction(
    token: string,
    instructionId: string,
    instruction: BasicActionDefinitionDTO,
    fromLanguage: string,
    translateTo: readonly string[],
    instructionApiInstanceForDifferentLanguages: InstructionDefinitionApi,
    tenantId: string
  ) {
    const url = TRANSLATE_INSTRUCTION_URL(
      instructionId,
      fromLanguage,
      tenantId
    );

    const translateToLangs = translateTo.map((t) => t.toUpperCase());

    return await this.executePostRequest<ActionDefinitionTranslationDTO>(
      token,
      url,
      undefined,
      instruction,
      {
        translateTo: translateToLangs.join(','),
      }
    );
  }

  async archiveInstructions(
    token: string,
    ids: string[],
    isHidden: boolean,
    language?: string,
    tenantId?: string
  ) {
    await this.executePatchRequest(
      token,
      ARCHIVE_INSTRUCTIONS_URL(isHidden, language, tenantId),
      undefined,
      { ids }
    );
  }

  async deleteInstructions(
    token: string,
    ids: string[],
    language?: string,
    tenantId?: string
  ) {
    await this.deleteItem(
      BASE_INSTRUCTION_URL(language, tenantId),
      token,
      undefined,
      {
        ids,
      }
    );
  }

  async requestReview(token: string, id: string, reviewerId: string) {
    await this.executePostRequest(token, REQUEST_REVIEW_URL(id), undefined, {
      ids: [reviewerId],
    });
  }

  async checkIfInstructionReviewRequestExists(token: string, id: string) {
    return this.fetchOneItemByIdWithUrl(
      token,
      `${BASE_INSTRUCTION_URL()}/${id}/review/exists`
    );
  }
}
