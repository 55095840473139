import { Location } from 'history';
import { isValueNumber } from './Validators';

export function getActiveTabFromLocation(
  location: Location
): number | undefined {
  let tabIndex = undefined;
  const tab = new URLSearchParams(location.search).get('tab');

  if (isValueNumber(tab) && tab !== null) {
    tabIndex = Number(tab);
  }

  return tabIndex;
}
