import React from 'react';
import { useDropzone, FileRejection, DropzoneOptions } from 'react-dropzone';
import styled from 'styled-components';
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import MediaType from '../../model/domain/media/media_type';

interface Props {
  onFileUploaded: (files: File[]) => void;
  onRejection: (rej: FileRejection[]) => void;
  intl: IntlShape;
  small?: boolean;
  accept?: DropzoneOptions['accept'];
  label?: string;
  mediaType?: MediaType;
  readonly disabled: boolean;
}

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#0da6a0';
  }
  if (props.isDragReject) {
    return '#e30021';
  }
  if (props.isDragActive) {
    return '#0da6a0';
  }
  return '#939393';
};

const getBorder = (props: any) => {
  if (props.isDragAccept) {
    return 'dashed';
  }
  if (props.isDragReject) {
    return 'dashed';
  }
  if (props.isDragActive) {
    return 'dashed';
  }
  return 'solid';
};

const TextWrapper = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
`;

const Wrapper = styled.div<{ disabled: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  border-width: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: ${(props) => getBorder(props)};
  transition: border 0.24s ease-in-out;
  margin: 24px 24px 16px 48px;
  padding: auto;
  height: 75%;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'auto')};
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.5)};

  @media (max-width: 768px) {
    margin: 24px 24px 16px 24px;
  }
`;

const SmallWrapper = styled.div<{ disabled: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  border-width: 2px;
  border-color: #0da6a0;
  border-style: dashed;
  transition: border 0.24s ease-in-out;
  margin-bottom: 0px;
  height: 120px;
  text-align: center;
  box-sizing: content-box;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: rgba(216, 216, 216, 0.25);
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'auto')};
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.5)};
`;

const CenterDiv = styled.div`
  display: inline-block;
  margin: auto;
  max-width: 75%;
  padding: 48px 0 48px 0;
`;

const HintText = styled.p`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.43;
  color: #939393;
`;

const StyledDropzone: React.FC<Props> = ({
  onRejection,
  onFileUploaded,
  small,
  accept = {
    'image/png': ['.png'],
  },
  label,
  mediaType,
  disabled,
}) => {
  const onDropRejected = (reject: FileRejection[]) => {
    onRejection(reject);
  };

  const onDropAccepted = (files: File[]) => {
    onFileUploaded(files);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    multiple: true,
    onDropRejected,
    onDropAccepted,
    disabled: disabled,
  });

  return small ? (
    <SmallWrapper
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      disabled={disabled}
    >
      <input {...getInputProps()} />
      <CenterDiv>
        <div>
          <Icon
            iconStyle={{ margin: '0 auto', width: '100%' }}
            name="plus"
            color="#0da6a0"
            size={16}
          />
        </div>
      </CenterDiv>
    </SmallWrapper>
  ) : (
    <Wrapper
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      disabled={disabled}
    >
      <input {...getInputProps()} />
      <CenterDiv>
        <div
          style={{
            display: 'flex',
            paddingBottom: '40px',
            justifyContent: 'center',
          }}
        >
          <Icon name="picture" color="#0da6a0" size={24} />
          <span
            style={{
              marginLeft: 4,
              fontSize: '16px',
              textAlign: 'center',
              paddingTop: '2px',
            }}
            className="dropzoneActive"
          >
            {
              <FormattedMessage
                id={
                  label ??
                  (mediaType === 'VIDEO'
                    ? 'choose_video'
                    : mediaType === 'PDF'
                    ? 'choose_pdf'
                    : 'choose_picture')
                }
              />
            }
          </span>
        </div>
        <TextWrapper>
          <Icon
            name="hand"
            color="#242424"
            size={24}
            iconStyle={{ paddingRight: '4px' }}
          />
          <HintText style={{ textAlign: 'left' }}>
            {
              <FormattedMessage
                id={
                  mediaType === 'VIDEO'
                    ? 'add_video_hint'
                    : mediaType === 'PDF'
                    ? 'add_pdf_hint'
                    : 'add_picture_hint'
                }
                values={{
                  br: <br />,
                }}
              />
            }
          </HintText>
        </TextWrapper>
      </CenterDiv>
    </Wrapper>
  );
};

export default injectIntl(StyledDropzone);
