import { RootReducerInterface } from '@/model/redux/interfaces.ts';
import { connect } from 'react-redux';
import {
  createInstruction,
  updateInstruction,
  approveInstruction,
  getInstruction,
  setSelectedInstructionLanguage,
  removeCommentFromInstruction,
  translateInstruction,
  updateTranslation,
  duplicateInstruction,
  deleteInstructions,
  getInstructionHistory,
  getCachedInstructionSuggestion,
} from '@/model/redux/instruction_definition/thunks.ts';
import { getEvent } from '@/model/redux/event_definition/thunks.ts';
import EditInstruction from './view';
import { EditInstructionStoreProps } from './interfaces';
import LabelThunks from '@/model/redux/labels/thunks.ts';

const mapStateToProps = (
  state: RootReducerInterface
): EditInstructionStoreProps => {
  return {
    instruction: state.data.instructionDefinitionState.instruction.data,
    instructionMeta: state.data.instructionDefinitionState.instruction.meta,
    translatedInstruction:
      state.data.instructionDefinitionState.translateInstruction.data,
    translationMeta:
      state.data.instructionDefinitionState.translateInstruction.meta,
    instructionUpdateMeta:
      state.data.instructionDefinitionState.updateInstruction.meta,
    eventAddedToInstruction:
      state.data.instructionDefinitionState.addEventsToInstruction.meta,
    approvalMeta: state.data.instructionDefinitionState.approveInstruction.meta,
    currentUser: state.userState.currentUser.data,
    selectedLanguage: state.data.instructionDefinitionState.selectedLanguage,
    commentDelete: state.data.instructionDefinitionState.deleteComment.meta,
    locale: state.localisationState.locale,
    eventDefinition: state.data.eventDefinitionState.event.data,
    suggestedInstruction:
      state.data.instructionDefinitionState.suggestedInstruction.data,
    suggestedInstructionMeta:
      state.data.instructionDefinitionState.suggestedInstruction.meta,
    instructionDeleteMeta:
      state.data.instructionDefinitionState.instructionsOperation.meta,
    addEventsToInstructionMeta:
      state.data.instructionDefinitionState.addEventsToInstruction.meta,
    instructionVersions:
      state.data.instructionDefinitionState.instructionVersions,
    config: state.configurationState.config.data,
  };
};

export default connect(mapStateToProps, {
  approveInstruction,
  getInstruction: getInstruction,
  createInstruction,
  updateInstruction,
  updateTranslation,
  setSelectedInstructionLanguage,
  removeCommentFromInstruction,
  translateInstruction,
  getEvent,
  getCachedInstructionSuggestion: getCachedInstructionSuggestion,
  duplicateInstruction,
  deleteInstructions,
  getInstructionHistory,
  getLabels: LabelThunks.getLabels,
})(EditInstruction);
