import React, { Component } from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import styled from 'styled-components';
import { connect } from 'react-redux';
import mediaThunks from '../../../model/redux/media/thunks';
import { showNotificationForOperation } from '@dev/base-web/dist/view/helpers/notification_helpers';
import { RootReducerInterface } from '@/model/redux/interfaces.ts';
import { Image } from '@/model/redux/actions/interface.ts';
import {
  LoadingMetaState,
  OperationMetaState,
} from '@dev/base-web/dist/model/redux/helpers/interfaces';
import ManufacturingEntityThunks from '../../../model/redux/manufacturing_entity/thunks';
import Loader from '@dev/base-web/dist/view/components/global/loader';
import { FileRejection } from 'react-dropzone';
import ImageListFilterAndSorting from './image_list_filter_and_sorting';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import UserThunks from '../../../model/redux/user/thunks';
import { FilterTag } from '@/model/domain/filter_tag.ts';
import { showNotificationForUpdate } from '../../helpers/Notifications';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import TabBar from '@dev/base-web/dist/view/components/global/tab_bar';
import { isFilePdf, isFileVideo } from '@/model/helpers/MediaHelpers.ts';
import PreUploadImageEditor from './pre_upload_image_editor';
import AddImageModalContent from './add_image_modal_content';
import {
  ImageUpdate,
  ImageUpload,
} from '@dev/base-web/dist/model/redux/media/interface';
import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';
import MediaType from '../../../model/domain/media/media_type';
import { MediaPathsDTO } from '@dev/base-web/dist/model/domain/common/media';

const TabBarContainer = styled.div`
  padding-top: 15px;
  margin-left: 36px;
  & > *:not(:last-child) {
    margin-right: 36px;
  }
`;

const Divider = styled.div`
  display: flex;
  height: 50vh;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    overflow-y: scroll;
  }
`;

const SortWrapper = styled.div`
  padding-left: 32px;
  padding-right: 32px;
`;

const tabBarElements = [
  <FormattedMessage id="pictures" />,
  <FormattedMessage id="videos" />,
  <FormattedMessage id="pdfs" />,
];

const mediaTypeTabs: MediaType[] = ['PICTURE', 'VIDEO', 'PDF'];

interface StoreProps {
  images: Image[];
  selected: Image | null;
  hasMoreResults: boolean;
  imageListMeta: LoadingMetaState;
  pictureDeleteMeta: OperationMetaState;
  uploads: readonly ImageUpload[];
  updating: ImageUpdate | null;
  readonly manufacturingEntities: ManufacturingEntity[];
  users: UserDetails[];
}

interface DispatchProps {
  clearUploadedPictures: () => void;
  clearSelectedPicture: () => void;
  getNextImages: (
    page: number,
    type: MediaType,
    filters: FilterTag[],
    loadAllPagesUntilTheGivenOne?: boolean
  ) => void;
  uploadMedia: (files: readonly File[]) => void;
  onFileTypeError: (rej: FileRejection) => void;
  readonly getAllManufacturingEntities: () => void;
  getAllUsers: (FilterData: []) => void;
}

interface ExternalProps {
  readonly onEditMedia: (media: MediaPathsDTO | null) => void;
  readonly open: boolean;
  readonly mediaType?: MediaType;
}

type AddImageProps = ExternalProps &
  StoreProps &
  DispatchProps &
  WrappedComponentProps;

interface State {
  filters: FilterTag[];
  page: number;
  imageFilesForCropping: File[];
  activeTab: number;
}

class AddMediaModal extends Component<AddImageProps, State> {
  constructor(props: AddImageProps) {
    super(props);
    this.state = {
      filters: [],
      page: 0,
      activeTab: 0,
      imageFilesForCropping: [],
    };
  }

  componentWillUnmount() {
    this.props.clearUploadedPictures();
  }

  componentDidUpdate(prevProps: AddImageProps) {
    const { pictureDeleteMeta, intl, updating, open } = this.props;
    const { filters } = this.state;

    showNotificationForOperation(
      prevProps.pictureDeleteMeta,
      pictureDeleteMeta,
      'media_upload_success',
      'media_update_success',
      'media_delete_success',
      intl
    );

    showNotificationForUpdate(
      prevProps.updating,
      updating,
      'picture_update_success',
      intl
    );

    if (open && prevProps.open !== open) {
      if (this.props.mediaType && this.props.mediaType === prevProps.mediaType)
        this.props.getNextImages(0, this.props.mediaType, filters);
      //this.props.getImagesWithFilter(filters);
      this.props.getAllManufacturingEntities();
      this.props.getAllUsers([]);
    }

    if (this.props.mediaType !== prevProps.mediaType)
      this.onTabChanged(
        this.props.mediaType === 'VIDEO'
          ? 1
          : this.props.mediaType === 'PDF'
          ? 2
          : 0
      );
  }

  filterSearch = (filters: FilterTag[]) => {
    this.props.clearUploadedPictures();
    this.props.getNextImages(0, mediaTypeTabs[this.state.activeTab], filters);
    this.setState({ page: 0 });
  };

  onFiltersChanged = (filters: FilterTag[]) => {
    this.setState({ filters }, () => this.filterSearch(this.state.filters));
  };

  loadNextPage = () => {
    const { page, filters, activeTab } = this.state;
    if (this.props.hasMoreResults || page <= 0) {
      const nextPage = page + 1;
      this.setState((prevState: State) => ({
        page: prevState.page + 1,
      }));

      this.props.getNextImages(nextPage, mediaTypeTabs[activeTab], filters);
    }
  };

  throwRej = (rej: FileRejection[]) => {
    rej.forEach((file) => {
      this.props.onFileTypeError(file);
    });
  };

  uploadFilesWithoutCropping = (files: readonly File[]) => {
    this.setState({
      imageFilesForCropping: files.filter(
        (f) => !isFileVideo(f) && !isFilePdf(f)
      ),
    });

    this.props.uploadMedia(files.filter((f) => isFileVideo(f) || isFilePdf(f)));
  };

  uploadFilesAfterCropping = (files: readonly File[]) => {
    this.props.uploadMedia(files);
    this.setState({ imageFilesForCropping: [] });
  };

  clickWrapper = (event: any) => {
    if (event.target.id === 'overlay') {
      this.props.onEditMedia(null);
    }
  };

  onTabChanged = (activeTab: number, callback?: () => void) => {
    this.setState(
      {
        activeTab,
      },
      () => {
        this.filterSearch(this.state.filters);
        callback && callback();
      }
    );

    this.props.clearSelectedPicture();
  };

  onCancelPressed = () => {
    const { onEditMedia } = this.props;
    onEditMedia(null);
  };

  addImageCallback = () => {
    if (this.props.selected) {
      this.props.onEditMedia(this.props.selected.urls);
    }
  };

  render() {
    const {
      pictureDeleteMeta,
      manufacturingEntities,
      users,
      images,
      imageListMeta,
      uploads,
      updating,
    } = this.props;
    const { filters, activeTab, imageFilesForCropping } = this.state;

    return (
      <BasicModalWithHeaderAndTwoButtons
        open={this.props.open}
        headerLabel="choose_media"
        okLabel="add"
        cancelLabel="cancel_button"
        onOk={this.addImageCallback}
        okDisabled={this.props.selected === null}
        okLoading={false}
        onCancel={this.onCancelPressed}
        modalSize={'fullscreen'}
        onClose={this.onCancelPressed}
        contentBackgroundColor={'#fff'}
      >
        {!imageFilesForCropping.length && (
          <>
            <TabBarContainer>
              <TabBar
                titles={tabBarElements}
                tabIndex={activeTab}
                onSelectedIndexChange={this.onTabChanged}
              />
            </TabBarContainer>
            <SortWrapper>
              <ImageListFilterAndSorting
                manufacturingEntities={manufacturingEntities}
                users={users}
                filters={filters}
                onFiltersChanged={this.onFiltersChanged}
              />
            </SortWrapper>
            <Divider>
              <AddImageModalContent
                imageListMeta={imageListMeta}
                images={images}
                loadNextPage={this.loadNextPage}
                throwRej={this.throwRej}
                uploading={uploads}
                updating={updating}
                uploadFilesWithoutCropping={this.uploadFilesWithoutCropping}
                mediaType={mediaTypeTabs[this.state.activeTab]}
              />
            </Divider>
          </>
        )}
        {imageFilesForCropping.length > 0 && (
          <PreUploadImageEditor
            imageFilesForCropping={imageFilesForCropping}
            onUploadCancelled={() =>
              this.setState({ imageFilesForCropping: [] })
            }
            uploadFiles={this.uploadFilesAfterCropping}
          />
        )}
        {pictureDeleteMeta.operationInProgress && <Loader />}
      </BasicModalWithHeaderAndTwoButtons>
    );
  }
}

const mapStateToProps = (state: RootReducerInterface): StoreProps => {
  return {
    selected: state.data.mediaState.selected,
    images: state.data.mediaState.imageList.data.results,
    hasMoreResults: state.data.mediaState.imageList.data.hasMoreResults,
    imageListMeta: state.data.mediaState.imageList.meta,
    pictureDeleteMeta: state.data.mediaState.pictureDelete.meta,
    uploads: state.data.mediaState.uploads,
    updating: state.data.mediaState.update,
    manufacturingEntities:
      state.data.manufacturingEntityState.manufacturingEntities.data,
    users: state.userState.allUsers.data,
  };
};

export default connect(mapStateToProps, {
  clearUploadedPictures: mediaThunks.clearUploadedPictures,
  clearSelectedPicture: mediaThunks.clearSelectedPicture,
  getNextImages: mediaThunks.getNextImages,
  uploadMedia: mediaThunks.uploadMedia,
  onFileTypeError: mediaThunks.addFileTypeError,
  getAllManufacturingEntities:
    ManufacturingEntityThunks.getAllManufacturingEntities,
  getAllUsers: UserThunks.getAllUsers,
})(injectIntl(AddMediaModal));
