import { Card } from '@dev/base-web/dist/view/components/global/card';
import {
  ProductBatchesProducedOrderTableProps,
  TABLE_GROUP_BY,
  TABLE_GROUP_BY_TYPE,
} from './interface';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import {
  Column,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DropdownProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import TableRow from './table_row';
import {
  FilterData,
  FilterOperation,
  timeSpanFilters,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';

const LabelContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: ${({ theme }) => theme.colors.warmGrey};
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ExtraSpace = styled.div`
  height: 40px;
`;

const LinearGradient = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0px;
  max-height: 64px;
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9) 45%,
    white
  );
`;

const CardContentContainer = styled.div`
  position: relative;
  max-height: 500px;
  // I'm not sure how safe this is. 100% - 24px padding - titlebar height
  height: calc(100% - 24px - 72px);
  > div {
    height: 100%;
  }
`;

const SmallerDropDown = styled(StyledDropDown)`
  &&& {
    min-width: 10em;
  }
`;

const Isolate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  isolation: isolate;
  z-index: 999;
`;

const ProductBatchesProducedOrderTable: React.FC<
  ProductBatchesProducedOrderTableProps
> = ({
  manufacturingEntityId,
  timeSpan,
  getKPIResults,
  kpiResults,
  hasMoreKpiResults,
  kpiResultsLoading,
}) => {
  const intl = useIntl();

  const [groupBy, setGroupBy] = useState<TABLE_GROUP_BY_TYPE>(
    TABLE_GROUP_BY[1]
  );

  const columns: Column[] = useMemo(() => {
    return [
      {
        title: groupBy.toLowerCase(),
        width: 4,
        sortKey: 'name',
        isLeftAligned: true,
        filterType: ColumnFilterType.TEXT,
      },
      {
        title: 'begin',
        width: 3,
        sortKey: 'start',
        isLeftAligned: true,
        // filterType: ColumnFilterType.DATE,
        filterType: ColumnFilterType.NONE,
        useDateTime: true,
      },
      {
        title: 'end',
        width: 3,
        sortKey: 'end',
        isLeftAligned: true,
        // filterType: ColumnFilterType.DATE,
        filterType: ColumnFilterType.NONE,
        useDateTime: true,
      },
      {
        title: 'produced_products',
        width: 3,
        sortKey: 'producedProducts',
        filterType: ColumnFilterType.NUMBER,
      },
      /* {
        title: 'scrap',
        width: 3,
        sortKey: 'scrap',
        filterType: ColumnFilterType.NUMBER,
      }, */
      {
        title: 'production_rate',
        width: 3,
        sortKey: 'utilization',
        filterType: ColumnFilterType.NUMBER,
      },
    ];
  }, [groupBy]);

  const options: DropdownProps['options'] = useMemo(
    () => [
      {
        value: TABLE_GROUP_BY[0],
        text: intl.formatMessage({ id: TABLE_GROUP_BY[0].toLowerCase() }),
      },
      {
        value: TABLE_GROUP_BY[1],
        text: intl.formatMessage({ id: TABLE_GROUP_BY[1].toLowerCase() }),
      },
    ],
    [intl.locale]
  );

  const externalFilters = useMemo(() => {
    const res = [...timeSpanFilters('start', 'end', timeSpan)];

    if (manufacturingEntityId) {
      res.push({
        key: 'manufacturingEntity.id',
        operation: FilterOperation.EQ_OPERATOR,
        value: manufacturingEntityId,
      });
    }

    return res;
    // the filter array should be re-generated even if groupBy changes, because the filter array reference change forces a re-fetch of the data in the table component!
  }, [manufacturingEntityId, timeSpan, groupBy]);

  const onDropdownChange: DropdownProps['onChange'] = (_, { value }) => {
    setGroupBy(value as TABLE_GROUP_BY_TYPE);
  };

  const getNextResultsCallback = useCallback(
    (
      page: number,
      filters: readonly FilterData[],
      sortKey?: string | undefined,
      sortDirection?: SortingDirection | undefined
    ) => {
      if (manufacturingEntityId && timeSpan && groupBy) {
        getKPIResults(page, groupBy, filters, sortKey, sortDirection);
      }
    },
    [manufacturingEntityId, timeSpan, groupBy]
  );

  const titleId =
    groupBy === 'PRODUCED_ORDER' ? 'produced_orders' : 'product_batches';

  return (
    <Card
      titleId={titleId}
      headerContent={
        <Isolate>
          <LabelContainer>
            <FormattedMessage id="category" />
          </LabelContainer>
          <SmallerDropDown
            selection
            value={groupBy}
            options={options}
            onChange={onDropdownChange}
          />
        </Isolate>
      }
    >
      <CardContentContainer>
        <SortableTable
          columns={columns}
          //stickyHeaderDisabled
          hasMoreResults={hasMoreKpiResults}
          externalFilters={externalFilters}
          getNextResults={getNextResultsCallback}
          dataLength={kpiResults?.length ?? 0}
          loadingInProgress={kpiResultsLoading}
          headerCheckboxInvisible
          minHeight={50}
          height={
            kpiResults?.length > 7 ? 500 : 80 + 64 + kpiResults?.length * 50
          }
        >
          {kpiResults.map((kpiResult, index) => (
            <TableRow key={index} kpi={kpiResult} intervalType={groupBy} />
          ))}
          <ExtraSpace />
        </SortableTable>
        <LinearGradient />
      </CardContentContainer>
    </Card>
  );
};

export default ProductBatchesProducedOrderTable;
