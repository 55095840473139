import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TableCell, Form } from 'semantic-ui-react';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import {
  EventDefinitionPropertiesOnChange,
  EventDefinitionPropertiesOnChangeValue,
} from './event_definition_properties';
import { useDateLocale } from '@dev/base-web/dist/view/helpers/date_hooks';
import {
  LeftAlignedTableCell,
  StyledFirstCell,
  StyledRow,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';

import styled from 'styled-components';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import { EventDefinitionTranslation } from '../../../model/domain/event/event_definition';

const RightAlignedTableCell = styled(TableCell)`
  display: flex;
  justify-content: flex-end;
  padding: 0px;
  margin-right: -15px;
`;
const StyledTranslationRow = styled(StyledRow)`
  padding: 0px;
  margin: 0px;
  cursor: auto;
`;

interface TranslationRowProps {
  readonly translation: EventDefinitionTranslation;
  readonly onChange: EventDefinitionPropertiesOnChange;
  readonly hideRemove: boolean;
  readonly disableRemove: boolean;
  readonly removeTranslation: (language: string) => void;
  readonly disabled: boolean;
}

export const TranslationRow: React.FC<TranslationRowProps> = ({
  translation,
  onChange,
  hideRemove,
  disableRemove,
  removeTranslation,
  disabled,
}) => {
  const { code } = useDateLocale();

  const localeLanguage = code?.substring(0, 2);
  const isEventData = translation.language.toLowerCase() === localeLanguage;
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);

  return (
    <StyledTranslationRow key={translation.language}>
      <StyledFirstCell>
        <FormattedMessage id={translation.language.toLowerCase()} />
      </StyledFirstCell>
      <LeftAlignedTableCell>
        <EditableTableCell
          value={translation.name || ''}
          isEditing={translation.name === '' ? true : isEditingName}
          setIsEditing={setIsEditingName}
          disabled={disabled}
          onEditingComplete={(changedValue) => {
            const mockEvent = {
              target: {
                name: `name-${translation.language.toLowerCase()}-${isEventData}`,
                value: changedValue,
              },
            };
            onChange(mockEvent as EventDefinitionPropertiesOnChangeValue);
          }}
          isMandatory
        />
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        <EditableTableCell
          value={translation.description || ''}
          isEditing={
            translation.description === '' ? true : isEditingDescription
          }
          setIsEditing={setIsEditingDescription}
          disabled={disabled}
          onEditingComplete={(changedValue) => {
            const mockEvent = {
              target: {
                name: `description-${translation.language.toLowerCase()}-${isEventData}`,
                value: changedValue,
              },
            };
            onChange(mockEvent as EventDefinitionPropertiesOnChangeValue);
          }}
        />
      </LeftAlignedTableCell>
      <RightAlignedTableCell>
        {hideRemove ? (
          <></>
        ) : (
          <Button
            type="tertiary"
            icon="delete"
            disabled={disabled || hideRemove || disableRemove}
            onClick={() => removeTranslation(translation.language)}
          />
        )}
      </RightAlignedTableCell>
    </StyledTranslationRow>
  );
};

interface EditableTableCellProps {
  readonly value: string;
  readonly onEditingComplete: (value: string) => void;
  readonly isEditing: boolean;
  readonly setIsEditing: (isEditing: boolean) => void;
  readonly isEditable?: boolean;
  readonly disabled?: boolean;
  readonly isMandatory?: boolean;
}

const EditableCell = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  width: 100%;
  min-width: 100px;
  overflow: auto;
  vertical-align: top;
  cursor: pointer;
`;

const StyledInput = styled(Form.TextArea)`
  &&& {
    font-family: Relative;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.black};

    && input::placeholder {
      font-family: Relative;
      font-size: 14px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      //line-height: 1.43;
      letter-spacing: normal;
      color: ${({ theme }) => theme.colors.lightGrey};
      padding-left: 2px;
    }

    && input:hover {
      border: solid 1px rgba(0, 0, 0, 0.4);
    }
    &&& input:active {
      border: solid 1px ${({ theme }) => theme.colors.primary};
    }
    &&& input:invalid {
      border: solid 2px ${({ theme }) => theme.colors.cherryRed};
    }
    &&& input:focus {
      border: solid 1px ${({ theme }) => theme.colors.primary};
    }
    &&& input:disabled {
      border: solid 1px rgba(0, 0, 0, 0.1);
      background-color: #f0f0f0;
    }
    &&& input:read-only {
      border: solid 1px rgba(0, 0, 0, 0.1);
      background-color: #f0f0f0;
    }
  }
`;

const EditableTableCell: React.FC<EditableTableCellProps> = ({
  value: propValue,
  isEditing,
  setIsEditing,
  onEditingComplete,
  isMandatory,
  disabled,
}) => {
  const [value, setValue] = useState(propValue);

  return (
    <>
      {!isEditing && (
        <EditableCell
          onClick={() => {
            if (!disabled) {
              setIsEditing(true);
            }
          }}
        >
          <TextWithEllipsisAndTooltip text={value} tooltipText={value} />
        </EditableCell>
      )}
      {isEditing && (
        <Form
          onSubmit={() => {
            setIsEditing(false);
            onEditingComplete(value);
          }}
        >
          <Form.Field>
            <StyledInput
              value={value}
              autoFocus
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValue(e.target.value);
              }}
              onBlur={() => {
                setIsEditing(false);
                onEditingComplete(value);
              }}
              error={isMandatory && !value}
            />
          </Form.Field>
        </Form>
      )}
    </>
  );
};

export default EditableTableCell;
