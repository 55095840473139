import Icon from '@dev/base-web/dist/view/components/global/icon';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import { LoadWrapper } from './styled_components';

const WrapAlert = styled.div`
  //display: flex;
  flex-direction: column;
  margin: auto;
`;

interface AddImageItemErrorInterface {
  readonly errorMessage: string;
  readonly errorClick?: () => void;
}

const AddImageItemError = ({
  errorClick,
  errorMessage,
}: AddImageItemErrorInterface) => {
  return (
    <LoadWrapper className="error" onClick={errorClick}>
      <WrapAlert>
        <Icon
          iconStyle={{ margin: '0 auto', width: '100%' }}
          name="warning"
          color="#e30021"
          size={16}
        />
        <span style={{ margin: 4 }}>
          {<FormattedMessage id={errorMessage} />}
        </span>
      </WrapAlert>
    </LoadWrapper>
  );
};

export default AddImageItemError;
