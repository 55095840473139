import { PropsWithChildren, useRef, useState } from 'react';
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from 'react-image-crop';
import styled from 'styled-components';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import {
  ButtonWrapper,
  FunctionContainer,
  FunctionWrapper,
  ImageContainer,
} from '../styled_components';

const CropImg = styled.img<{ active: boolean; processing: boolean }>`
  height: calc(80vh - 420px);
  ${({ active }) => (active ? '' : 'cursor: default;')}
  opacity: ${({ processing }) => (processing ? 0.5 : 1)};
`;

interface ImageCropperViewProps {
  readonly src: string;
  readonly forceCropAspect?: number;
  readonly forceCrop?: Crop;
  readonly loadedImage?: HTMLImageElement;
  readonly setLoadedImage: (image: HTMLImageElement) => void;
  readonly cropActive: boolean;
  readonly onCropApplied: (canvas: HTMLCanvasElement) => Promise<void>;
  readonly onCropCancelled: () => void;
}

/**
 * Create a cropped canvas "view" of the source image using the specified crop settings.
 *
 * The algorithm is a modifed version of what can be found in the example application of the library,
 * it uses percentage crop values instead of pixel values!
 *
 * @param image
 * @param crop
 * @returns canvas component filled with cropped image
 */
const getCroppedImg = (
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: Crop
) => {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  canvas.width = Math.floor((crop.width / 100) * image.naturalWidth);
  canvas.height = Math.floor((crop.height / 100) * image.naturalHeight);

  ctx.imageSmoothingQuality = 'high';

  const cropX = (crop.x / 100) * image.naturalWidth;
  const cropY = (crop.y / 100) * image.naturalHeight;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();

  return canvas;
};

const ImageCropperView = ({
  src,
  forceCropAspect,
  forceCrop,
  loadedImage,
  setLoadedImage,
  cropActive,
  onCropApplied,
  onCropCancelled,
  children,
}: PropsWithChildren<ImageCropperViewProps>) => {
  const canvasRef = useRef(document.createElement('canvas'));

  const [crop, setCrop] = useState<Crop>();
  const [cropProcessing, setCropProcessing] = useState(false);

  const applyCrop = async () => {
    if (
      loadedImage &&
      loadedImage.complete &&
      crop &&
      crop.width &&
      crop.height
    ) {
      // start crop processing
      setCropProcessing(true);

      const croppedImage = getCroppedImg(loadedImage, canvasRef.current, crop);

      if (croppedImage) {
        await onCropApplied(croppedImage);
      }
    }
  };

  function onImageLoad(image: HTMLImageElement) {
    const { naturalWidth, naturalHeight } = image;

    const cropValue = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: '%',
          width: 100,
        },
        forceCropAspect || 4 / 3,
        naturalWidth,
        naturalHeight
      ),
      naturalWidth,
      naturalHeight
    );

    setCrop(cropValue);
    setLoadedImage(image);
    // end crop processing
    setCropProcessing(false);
  }

  return (
    <>
      <ImageContainer>
        <ReactCrop
          aspect={forceCropAspect}
          crop={cropActive ? crop : undefined}
          ruleOfThirds
          onChange={(_, percentCrop) => {
            setCrop(percentCrop);
          }}
          style={loadedImage ? undefined : { display: 'none' }}
        >
          <CropImg
            src={src}
            onLoad={(r) => onImageLoad(r.currentTarget)}
            active={cropActive}
            processing={cropProcessing}
            crossOrigin="anonymous"
          />
        </ReactCrop>
      </ImageContainer>
      <FunctionWrapper>
        <FunctionContainer>
          {children}
          <ButtonWrapper>
            <Button
              type="primary"
              size="small"
              icon="checkmark"
              onClick={() => void applyCrop()}
              disabled={cropProcessing}
              loading={cropProcessing}
            />
            <Button
              type="secondary"
              size="small"
              icon="cross"
              onClick={onCropCancelled}
              disabled={!!forceCrop || cropProcessing}
            />
          </ButtonWrapper>
        </FunctionContainer>
      </FunctionWrapper>
    </>
  );
};

export default ImageCropperView;
