import React, { useEffect, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import styled from 'styled-components';
import ManufacturingEntityFilter from './manufacturing_entity_filter';
import UserFilter from './user_filter';
import UsageFilter from './usage_filter';
import FilterPopupActions from './filter_popup_actions';
import { FormattedMessage } from 'react-intl';
import { FilterTag } from '../../../../model/domain/filter_tag';
import ImageTimestampFilter from './image_timestamp_filter';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import FileNameFilter from './file_name_filter';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 8px;
`;

const LabelContainer = styled.div`
  font-family: Relative;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #8c8c8c;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 8px;
  width: 100%;
`;

interface FilterPopupProps {
  readonly filters: FilterTag[];
  readonly manufacturingEntities: ManufacturingEntity[];
  readonly users: UserDetails[];
  readonly onFiltersChanged: (filters: FilterTag[]) => void;
}

const FilterPopup: React.FC<FilterPopupProps> = (props) => {
  const { filters, onFiltersChanged, manufacturingEntities, users } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [localFilters, setLocalFilters] = useState<FilterTag[]>([...filters]);

  useEffect(() => {
    setLocalFilters([...filters]);
  }, [filters]);

  const onApplyPressed = () => {
    onFiltersChanged([...localFilters]);
    setOpen(false);
  };

  const onClearPressed = () => {
    setLocalFilters([]);
    onFiltersChanged([]);
    setOpen(false);
  };

  return (
    <Popup
      wide
      trigger={
        <Button
          type={filters.length > 0 ? 'primary' : 'secondary'}
          icon="filter-bold"
        />
      }
      on="click"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      position="top right"
    >
      <FormContainer>
        <LabelContainer>
          <FormattedMessage id={'update_date'} />
        </LabelContainer>
        <ImageTimestampFilter
          externalFilters={filters}
          localFilters={localFilters}
          setLocalFilters={setLocalFilters}
        />
        <LabelContainer>
          <FormattedMessage id={'manufacturing_entity'} />
        </LabelContainer>
        <ManufacturingEntityFilter
          manufacturingEntities={manufacturingEntities}
          externalFilters={filters}
          localFilters={localFilters}
          setLocalFilters={setLocalFilters}
        />
        <LabelContainer>
          <FormattedMessage id={'file_name'} />
        </LabelContainer>
        <FileNameFilter
          externalFilters={filters}
          localFilters={localFilters}
          setLocalFilters={setLocalFilters}
        />
        <LabelContainer>
          <FormattedMessage id={'login_username'} />
        </LabelContainer>
        <UserFilter
          users={users}
          externalFilters={filters}
          localFilters={localFilters}
          setLocalFilters={setLocalFilters}
        />
        <LabelContainer>
          <FormattedMessage id={'usage'} />
        </LabelContainer>
        <UsageFilter
          externalFilters={filters}
          localFilters={localFilters}
          setLocalFilters={setLocalFilters}
        />
        <FilterPopupActions
          onClearPressed={onClearPressed}
          onApplyPressed={onApplyPressed}
          localFilters={localFilters}
          externalFilters={filters}
        />
      </FormContainer>
    </Popup>
  );
};

export default FilterPopup;
