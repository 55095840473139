import { RootReducerInterface } from '@/model/redux/interfaces.ts';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Events from './view';
import { EventsStoreProps } from './interfaces';
import {
  createGetEventsThunk,
  setActiveTab,
  multiUpdateEvents,
} from '@/model/redux/event_definition/thunks.ts';
import * as EventDefinitionImportThunks from '../../model/redux/event_definition_import/thunks';
import ManufacturingEntityThunks from '../../model/redux/manufacturing_entity/thunks';
import Thunks from '../../model/redux/events/thunks';

const mapStateToProps = (state: RootReducerInterface): EventsStoreProps => {
  return {
    events: state.data.eventDefinitionState.events.data.results,
    eventsTotalElements:
      state.data.eventDefinitionState.events.data.totalElements,
    eventImport:
      state.data.eventDefinitionImportState.importEventDefinition.data,
    eventImportInProgress:
      state.data.eventDefinitionImportState.importEventDefinition.meta
        .loadingInProgress,
    eventImportError:
      state.data.eventDefinitionImportState.importEventDefinition.meta.error,
    eventImportStatus:
      state.data.eventDefinitionImportState.eventDefinitionImportStatus.data,
    eventImportStatusInProgress:
      state.data.eventDefinitionImportState.eventDefinitionImportStatus.meta
        .loadingInProgress,
    eventImportStatusError:
      state.data.eventDefinitionImportState.eventDefinitionImportStatus.meta
        .error,
    eventImportConfirmation:
      state.data.eventDefinitionImportState.confirmEventDefinitionImport.data,
    eventImportConfirmationInProgress:
      state.data.eventDefinitionImportState.confirmEventDefinitionImport.meta
        .loadingInProgress,
    eventImportConfirmationError:
      state.data.eventDefinitionImportState.confirmEventDefinitionImport.meta
        .error,
    eventOccurrenceCountDuration:
      state.configurationState.config.data?.eventOccurrenceCountDuration,
    moreEventsCanBeLoaded:
      state.data.eventDefinitionState.events.data.hasMoreResults,
    multiEventUpdate: state.data.eventDefinitionState.multiUpdateEvent.meta,
    eventLoadingInProgress:
      state.data.eventDefinitionState.events.meta.loadingInProgress,
    eventLoadingError: state.data.eventDefinitionState.events.meta.error,
    previousEventSearchData:
      state.data.eventDefinitionState.events.meta.previousSearchData,
    manufacturingEntities:
      state.data.manufacturingEntityState.manufacturingEntities.data,
    manufacturingEntitiesMeta:
      state.data.manufacturingEntityState.manufacturingEntities.meta,
    activeTab: state.data.eventDefinitionState.activeTab,
    archiveEventsError: state.data.eventState.archiveEvents.meta.error,
    archiveEventsLoading:
      state.data.eventState.archiveEvents.meta.operationInProgress,
    showHierarchy:
      state.configurationState.config.data?.showManufacturingEntityHierarchy
        .value,
  };
};

export default connect(mapStateToProps, {
  getEvents: createGetEventsThunk(),
  multiUpdateEvents,
  archiveEvents: Thunks.archiveEvents,
  getAllManufacturingEntities:
    ManufacturingEntityThunks.getManufacturingEntities,
  setActiveTab: setActiveTab,
  startImport: EventDefinitionImportThunks.startImport,
  checkImportStatus: EventDefinitionImportThunks.checkImportStatus,
  confirmImport: EventDefinitionImportThunks.confirmImport,
  resetImport: EventDefinitionImportThunks.resetImport,
  resetImportStatus: EventDefinitionImportThunks.resetImportStatus,
})(injectIntl(Events));
