import { RootReducerInterface } from '@/model/redux/interfaces';
import { connect } from 'react-redux';
import RequestReviewPopup from './view';
import { RequestReviewPopupStoreProps } from './interface';
import {
  checkIfInstructionReviewRequestExists,
  getAllApprovalUsers,
  requestReview,
} from '@/model/redux/instruction_definition/thunks';

const mapStateToProps = (
  state: RootReducerInterface
): RequestReviewPopupStoreProps => {
  return {
    instructionApprovalUsers:
      state.data.instructionDefinitionState.instructionApprovalUsers.data,
    usersMeta:
      state.data.instructionDefinitionState.instructionApprovalUsers.meta,
    requestReviewOperation:
      state.data.instructionDefinitionState.requestReview.meta,
    reviewStatus: state.data.instructionDefinitionState.reviewStatus.data,
    reviewStatusMeta: state.data.instructionDefinitionState.reviewStatus.meta,
    currentUser: state.userState.currentUser.data,
  };
};

export default connect(mapStateToProps, {
  getAllUsers: getAllApprovalUsers,
  requestReview,
  checkIfInstructionReviewRequestExists,
})(RequestReviewPopup);
