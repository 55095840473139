import { combineReducers, Reducer } from 'redux';
import {
  createReducersForExecutingOperation,
  createReducersForLoadingList,
} from '@dev/base-web/dist/model/redux/helpers/reducers';
import { ACTION_TYPES } from './actions';
import {
  GlossaryDetail,
  GlossaryGroupedEntry,
} from '../../domain/translation/glossary';
import { TranslationStateInterface } from './interface';

const translationState: Reducer<TranslationStateInterface> = combineReducers({
  glossaries: createReducersForLoadingList<GlossaryDetail>(
    ACTION_TYPES.GLOSSARIES
  ),
  glossary: createReducersForLoadingList<GlossaryGroupedEntry>(
    ACTION_TYPES.GLOSSARY
  ),
  updateGlossary: createReducersForExecutingOperation(
    ACTION_TYPES.UPDATE_GLOSSARY
  ),
  createGlossary: createReducersForExecutingOperation(
    ACTION_TYPES.CREATE_GLOSSARY
  ),
  deleteGlossary: createReducersForExecutingOperation(
    ACTION_TYPES.DELETE_GLOSSARY
  ),
});

export default translationState;
