import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Grid, Button as SemanticButton } from 'semantic-ui-react';
import _ from 'lodash';
import { ThemeProps } from '@dev/base-web/dist/view/themes/interface';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { FilterTag } from '../../../model/domain/filter_tag';
import FilterPopup from './filter_popup/filter_popup';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';

const StyledGrid = styled(Grid)`
  &&& {
    margin: 16px 0 0 0;
    width: 100%;
  }
`;

const StyledColumn = styled(Grid.Column)`
  &&&& {
    padding-right: 4px;
    padding-left: 4px;
  }
`;

const FilterAndSortControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const FilterTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const FilterTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 123px;
  height: ${({ theme }: ThemeProps) => theme.buttons.medium.height}px;
  border-radius: ${({ theme }: ThemeProps) =>
    theme.buttons.basic.borderRadius}px;
  border: solid 1px rgba(36, 36, 36, 0.05);
  background-color: rgba(233, 245, 245, 0.5);
  font-family: Relative;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #242424;
  padding-right: 4px;
`;

const FilterTagButton = styled(SemanticButton)`
  &&&&&& {
    font-family: Relative;
    font-size: 12px;
    line-height: 1.67;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    border-radius: ${({ theme }: ThemeProps) =>
      theme.buttons.basic.borderRadius}px;
    padding: 0px 4px;
    height: ${({ theme }: ThemeProps) => theme.buttons.medium.height}px;

    display: flex;
    flex-direction: row;
    align-items: center;

    color: #242424;
    border: solid 1px rgba(36, 36, 36, 0.05);
    background-color: rgba(233, 245, 245, 0.5);
    justify-content: space-between;

    &:hover {
      border: solid 1px rgba(0, 0, 0, 0.05);
      background-color: rgba(124, 128, 127, 0.12);
    }

    &:active {
      border: solid 1px rgba(0, 0, 0, 0.06);
      background-color: rgba(124, 128, 127, 0.24);
    }
  }
`;

const FilterTagIcon = styled(Icon)`
  &&&&&& {
    margin: 0 4px !important;
    height: 12px;
  }
`;

interface ImageListFilterAndSortingProps {
  readonly manufacturingEntities: ManufacturingEntity[];
  readonly users: UserDetails[];
  readonly filters: FilterTag[];
  readonly initialSortingKey?: string;
  readonly onSortingChanged?: (sortKey: string) => void;
  readonly onFiltersChanged: (filters: FilterTag[]) => void;
}

const ImageListFilterAndSorting: React.FC<ImageListFilterAndSortingProps> = (
  props
) => {
  const { manufacturingEntities, users, onFiltersChanged, filters } = props;

  const [groupedFilterTags, setGroupedFilterTags] = useState<FilterTag[]>([]);

  const handleFilterChange = (filters: FilterTag[]) => {
    const newGroupedFilterTags: FilterTag[] = [];
    filters.forEach((filter) => {
      const alreadyAddedMember = newGroupedFilterTags.find(
        (item) => item.groupingKey === filter.groupingKey
      );

      if (!alreadyAddedMember) {
        newGroupedFilterTags.push(filter);
      } else if (
        alreadyAddedMember.groupingOrder !== undefined &&
        filter.groupingOrder !== undefined
      ) {
        _.remove(
          newGroupedFilterTags,
          (tag) => tag.groupingKey === filter.groupingKey
        );
        const label =
          alreadyAddedMember.groupingOrder < filter.groupingOrder
            ? alreadyAddedMember.label + filter.label
            : filter.label + alreadyAddedMember.label;
        newGroupedFilterTags.push({ ...alreadyAddedMember, label });
      }
    });
    setGroupedFilterTags(newGroupedFilterTags);
    onFiltersChanged(filters);
  };

  const handleFilterDelete = (filter: FilterTag) => {
    _.remove(filters, (item) => item.groupingKey === filter.groupingKey);
    handleFilterChange([...filters]);
  };

  useEffect(() => {
    if (filters.length) {
      handleFilterChange(filters);
    }
  }, []);

  return (
    <StyledGrid reversed="mobile vertically">
      <StyledColumn
        mobile={16}
        tablet={14}
        computer={14}
        largeScreen={12}
        widescreen={10}
      >
        <FilterTagsContainer>
          {!!groupedFilterTags.length &&
            groupedFilterTags.map((filter) => (
              <FilterTagButton
                key={filter.groupingKey}
                onClick={() => handleFilterDelete(filter)}
              >
                <FilterTagIcon name={filter.icon} color={'#242424'} size={16} />
                {filter.label}
                <FilterTagIcon name={'cross'} color={'#c6c6c6'} size={16} />
              </FilterTagButton>
            ))}
          {!groupedFilterTags.length && (
            <FilterTagContainer>
              <FormattedMessage id={'no_filter_active'} />
            </FilterTagContainer>
          )}
        </FilterTagsContainer>
      </StyledColumn>
      <StyledColumn
        mobile={16}
        tablet={2}
        computer={2}
        largeScreen={4}
        widescreen={6}
      >
        <FilterAndSortControls>
          <FilterPopup
            filters={filters}
            onFiltersChanged={handleFilterChange}
            manufacturingEntities={manufacturingEntities}
            users={users}
          />
        </FilterAndSortControls>
      </StyledColumn>
    </StyledGrid>
  );
};

export default ImageListFilterAndSorting;
