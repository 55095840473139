import {
  ClosingCondition,
  ClosingConditionType,
  ClosingFeatureCondition,
  EventDefinition,
  OpeningCondition,
  OpeningConditionType,
  OpeningFeatureCondition,
  ScheduledCondition,
  TimerCondition,
  UserActionCondition,
} from '../../../model/domain/event/event_definition';
import { featureSaveable } from '@dev/base-web/dist/view/components/feature/feature_helpers';
import FeatureConfigurationInstance from '@dev/base-web/dist/model/domain/feature/feature_configuration';

export function isDescriptionValid(event: EventDefinition): boolean {
  return event && event.name !== '' && event.rules.length > 0;
}

function isFeatureValid(
  featureConfiguration: FeatureConfigurationInstance | null,
  open: OpeningFeatureCondition | ClosingFeatureCondition
): boolean {
  return featureSaveable(featureConfiguration, open.value);
}

function isScheduledConditionValid(open: ScheduledCondition): boolean {
  return (
    open.iteration > 0 && open.timestamp > 0 && open.iterationType !== null
  );
}

export function isOpeningConditionValid(
  featureConfiguration: FeatureConfigurationInstance | null,
  open: OpeningCondition | null
): boolean {
  if (open) {
    switch (open.type) {
      case OpeningConditionType.FEATURE:
        if (
          !isFeatureValid(featureConfiguration, open as OpeningFeatureCondition)
        )
          return false;
        break;
      case OpeningConditionType.SCHEDULED:
        if (!isScheduledConditionValid(open as ScheduledCondition))
          return false;
        break;
      default:
        return false;
    }
  } else {
    return false;
  }

  return true;
}

export function areOpeningConditionsValid(
  featureConfiguration: FeatureConfigurationInstance | null,
  event: EventDefinition
): boolean {
  if (event.rules.length == 0) return false;

  for (const rule of event.rules) {
    if (!isOpeningConditionValid(featureConfiguration, rule.open)) return false;
  }

  return true;
}

function isTimerConditionValid(c: TimerCondition): boolean {
  return c.duration > 0;
}

function isUserActionValid(c: UserActionCondition): boolean {
  return c.onClose;
}

export function isClosingConditionValid(
  featureConfiguration: FeatureConfigurationInstance | null,
  close: ClosingCondition | null
): boolean {
  if (close) {
    switch (close.type) {
      case ClosingConditionType.FEATURE:
        if (
          !isFeatureValid(
            featureConfiguration,
            close as ClosingFeatureCondition
          )
        )
          return false;
        break;
      case ClosingConditionType.TIMER:
        if (!isTimerConditionValid(close as TimerCondition)) return false;
        break;
      case ClosingConditionType.USER_ACTION:
        if (!isUserActionValid(close as UserActionCondition)) return false;
        break;
      default:
        return false;
    }
  } else {
    return false;
  }

  return true;
}

export function areClosingConditionsValid(
  featureConfiguration: FeatureConfigurationInstance | null,
  event: EventDefinition
): boolean {
  if (event.rules.length == 0) return false;

  for (const rule of event.rules) {
    if (!isClosingConditionValid(featureConfiguration, rule.close))
      return false;
  }

  return true;
}
