import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ManufacturingEntityDropdown from '@dev/base-web/dist/view/components/inputs/manufacturing_entity_dropdown';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import useFilterState from './filter_hook';
import { FilterProps } from './interfaces';

const StyledManufacturingEntityDropdown = styled(ManufacturingEntityDropdown)`
  width: 100%;
`;

interface ManufacturingEntityFilterProps extends FilterProps {
  manufacturingEntities: ManufacturingEntity[];
}

const ManufacturingEntityFilter: React.FC<ManufacturingEntityFilterProps> = (
  props
) => {
  const {
    localFilters,
    setLocalFilters,
    externalFilters,
    manufacturingEntities,
  } = props;

  const [manufacturingEntity, setManufacturingEntity] = useState<
    ManufacturingEntity | undefined
  >();
  const [manufacturingEntityId, setManufacturingEntityId] =
    useFilterState<string>(
      'manufacturingEntity',
      { groupingKey: 'manufacturingEntity', icon: 'location' },
      (_value, filterContext) => filterContext.name,
      localFilters,
      setLocalFilters,
      externalFilters
    );

  useEffect(() => {
    if (!manufacturingEntityId) {
      setManufacturingEntity(undefined);
    } else {
      const currentFilter = localFilters.find(
        (filter) => filter.groupingKey === 'manufacturingEntity'
      );
      if (currentFilter) {
        setManufacturingEntity(currentFilter.filterContext);
      }
    }
  }, [manufacturingEntityId, localFilters]);

  return (
    <StyledManufacturingEntityDropdown
      clearable
      ignoreSelectable
      //multipleSelectionEnabled
      selectedManufacturingEntity={manufacturingEntity}
      manufacturingEntities={manufacturingEntities}
      searchable
      onManufacturingEntitySelected={(locations) => {
        if (locations.length && locations[0]) {
          setManufacturingEntityId(locations[0].id, locations[0]);
        } else {
          setManufacturingEntityId(undefined);
        }
      }}
    />
  );
};

export default ManufacturingEntityFilter;
