import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';
import ManufacturingEntityProductionProgress from '@dev/base-web/dist/model/domain/production_progress';

const PRODUCTION_PROGRESS_URL = (manufacturingEntityId: string) =>
  `/areas/childs/${manufacturingEntityId}/production-progress`;

export default class ProductionProgressApi extends BaseDataApi<ManufacturingEntityProductionProgress> {
  async getProductionProgress(
    token: string,
    manufacturingEntityId: string,
    begin: number,
    end: number,
    signal: AbortSignal
  ): Promise<ManufacturingEntityProductionProgress> {
    return this.fetchOneItemByIdWithUrl(
      token,
      PRODUCTION_PROGRESS_URL(manufacturingEntityId),
      signal,
      {
        begin,
        end,
      }
    );
  }
}
