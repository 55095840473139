import { combineReducers, Reducer } from 'redux';
import { createReducersForLoadingSingleItem } from '@dev/base-web/dist/model/redux/helpers/reducers';
import { ACTION_TYPES } from './actions';
import { Instruction } from '../../domain/instruction/instruction';

const reducers: Reducer<any> = combineReducers({
  currentInstruction: createReducersForLoadingSingleItem<Instruction>(
    ACTION_TYPES.GET_CURRENT_ITEM_KEY
  ),
});

export default reducers;
