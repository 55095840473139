import { RootReducerInterface } from '@/model/redux/interfaces.ts';
import { ActionTitleCardStoreProps } from './interface';
import { connect } from 'react-redux';
import ActionTitleCard from './view';
import LabelThunks from '../../../../model/redux/labels/thunks';

const mapStateToProps = (
  state: RootReducerInterface
): ActionTitleCardStoreProps => {
  return {
    labels: state.data.labelState.labels.data,
    labelsMeta: state.data.labelState.labels.meta,
    shareInstructions:
      state.configurationState.config.data?.shareInstructions.value || false,
  };
};

export default connect(mapStateToProps, {
  getLabels: LabelThunks.getLabels,
})(ActionTitleCard);
