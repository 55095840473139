import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Card } from '@dev/base-web/dist/view/components/global/styled_components';
import ToggleSwitch from '@dev/base-web/dist/view/components/inputs/toggle_switch';
import Icon from '@dev/base-web/dist/view/components/global/icon';

const CustomCard = styled(Card)`
  margin: 0 0 24px 0px;
  padding: 16px 24px 8px 24px;
`;

const DivToggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 8px;
`;

const HeaderSpan = styled.span`
  font-family: Relative;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--black);
`;

interface Props {
  readonly onSwitch: (approved: boolean) => void;
  readonly approved: boolean;
  readonly authorized: boolean;
}
const ApprovedCard = ({ onSwitch, approved, authorized }: Props) => {
  return (
    <CustomCard>
      <DivToggle>
        <HeaderSpan style={{ marginTop: '1px', display: 'flex' }}>
          <Icon name="warning" size={23} color="#DC8500" />
          <span style={{ margin: '3px 0 4px 5px' }}>
            {authorized ? (
              <FormattedMessage id="approve_version" />
            ) : (
              <FormattedMessage id="new_version_not_permitted" />
            )}
          </span>
        </HeaderSpan>
        {authorized && <ToggleSwitch checked={approved} onChange={onSwitch} />}
      </DivToggle>
      <span
        style={{ fontStyle: 'italic', lineHeight: '1.43', color: '#939393' }}
      >
        {authorized ? (
          <FormattedMessage id="new_version_hint" />
        ) : (
          <FormattedMessage id="new_version_not_permitted_hint" />
        )}
      </span>
    </CustomCard>
  );
};

export default ApprovedCard;
