import {
  CenterAlignedTableCell,
  LeftAlignedTableCell,
  StyledCenter,
  StyledFirstCell,
  StyledRow,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import { FormattedMessage } from 'react-intl';
import { Table } from 'semantic-ui-react';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { useEffect, useRef } from 'react';
import { useEventTypeLook } from '@dev/base-web/dist/view/components/global/common';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import styled from 'styled-components';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import { ManufacturingEntityType } from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import DowntimeCauseSelector from './downtime_cause_selector';
import DowntimeSolutionSelector from './downtime_solution_selector';
import useDowntimeReportEditing from './use_downtime_report_editing';
import DowntimeEditButton from './downtime_edit_button';
import DowntimeCommentSelector from './downtime_comment_selector';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import DowntimeOverview from '@dev/base-web/dist/model/domain/report/DowntimeOverview';
import Downtime from '@dev/base-web/dist/model/domain/report/Downtime';
import DowntimeEvent from '@dev/base-web/dist/model/domain/report/DowntimeEvent';
import DowntimeAction from '@dev/base-web/dist/model/domain/report/DowntimeAction';
import { DowntimeReason } from '@dev/base-web/dist/model/domain/report/DowntimeReason';
import EventDetail from '../../../../../model/domain/event/event';
import ShiftReportOverview from '@dev/base-web/dist/model/domain/report/ShiftReportOverview';

const EventName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const LowerRowCell = styled(LeftAlignedTableCell)`
  &&&&& {
    border-top: 0;
    overflow: visible;
  }
`;

const DowntimeEditingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 16px;
`;

const EventAndActionSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 80%;
`;

interface DowntimesInReportTableRowProps {
  readonly reportId: string;
  readonly report?: ShiftReportOverview;
  readonly downtimeOverview: DowntimeOverview;
  readonly selected: boolean;
  readonly onSelected: () => void;
  readonly getDowntime: (reportId: string, downTimeId: string) => void;
  readonly getSelectedEvent: (eventId: string) => void;
  readonly selectedDowntime: Downtime | null;
  readonly selectedDowntimeLoadingInProgress: boolean;
  readonly showManufacturingEntityHierarchy: boolean;
  readonly downtimeReasons: readonly DowntimeReason[];
  readonly downtimeReasonsLoadingInProgress: boolean;
  readonly updateDowntimeInShiftReport: (
    newCause?: DowntimeEvent,
    newAction?: DowntimeAction,
    newOtherReason?: DowntimeReason,
    newComment?: string
  ) => void;
  readonly downtimeUpdateInProgress: boolean;
  readonly editable: boolean;
  readonly selectedEventData: EventDetail | null;
}

const DowntimesInReportTableRow = ({
  downtimeOverview,
  onSelected,
  selected,
  getDowntime,
  selectedDowntime,
  reportId,
  report,
  selectedDowntimeLoadingInProgress,
  showManufacturingEntityHierarchy,
  updateDowntimeInShiftReport,
  downtimeReasons,
  downtimeReasonsLoadingInProgress,
  downtimeUpdateInProgress,
  editable,
  getSelectedEvent,
  selectedEventData,
}: DowntimesInReportTableRowProps) => {
  const {
    startTimestamp,
    duration,
    causingEvent,
    chosenActionName,
    isDocumented,
    id,
    cause,
    isPrefilled,
    shiftReportManufacturingEntity,
  } = downtimeOverview;

  const theme = useTheme();
  const { formatDate, formatDuration } = useLocalizedDateFormatters();

  const { icon, color } = useEventTypeLook(
    causingEvent?.type ?? EventType.UNKNOWN_EVENT
  );

  const formattedStartShift = formatDate(startTimestamp, 'Ppp');
  const formattedDownTime = formatDuration(duration, 'HH:mm:ss');
  const {
    selectedEvent,
    selectedOtherReason,
    selectedActionName,
    selectedDowntimeAction,
    selectedPossibleEvent,
    comment,
    onRowPressed,
    onPossibleEventSelected,
    onOtherReasonSelected,
    onActionSelected,
    onCommentChanged,
  } = useDowntimeReportEditing(
    downtimeOverview,
    chosenActionName,
    selectedDowntime,
    selected,
    getDowntime,
    reportId,
    id,
    onSelected,
    getSelectedEvent,
    selectedEventData
  );

  const currentElementRef = useRef<any>(null);

  useEffect(() => {
    if (selected && currentElementRef && currentElementRef.current) {
      setTimeout(
        () =>
          currentElementRef.current.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          }),
        500
      );
    }
  }, [selected, currentElementRef.current]);

  return (
    <>
      <StyledRow onClick={onRowPressed}>
        <StyledFirstCell ref={currentElementRef}>
          {formattedStartShift}
        </StyledFirstCell>
        <CenterAlignedTableCell>
          <FormattedMessage id={formattedDownTime} />
        </CenterAlignedTableCell>
        <LeftAlignedTableCell>
          {causingEvent && causingEvent.manufacturingEntity ? (
            <ManufacturingEntityHierarchyElement
              manufacturingEntity={causingEvent.manufacturingEntity}
              hideHiddenTypes
              showShortNames
              hiddenTypes={[
                ManufacturingEntityType.FACTORY,
                ManufacturingEntityType.AREA,
                ManufacturingEntityType.LINE,
              ]}
              showHierarchy={showManufacturingEntityHierarchy}
              lines={2}
            />
          ) : (
            '-'
          )}
        </LeftAlignedTableCell>
        <LeftAlignedTableCell>
          <EventName>
            {causingEvent && (
              <>
                <Icon
                  iconStyle={{ marginRight: 9, flexShrink: 0 }}
                  name={icon}
                  color={color}
                  size={18}
                />
                <TextWithEllipsisAndTooltip
                  text={causingEvent.name}
                  lines={2}
                />
              </>
            )}
            {!causingEvent && cause && (
              <FormattedMessage
                id={cause.toLowerCase()}
                defaultMessage={cause}
              />
            )}
            {isPrefilled && (
              <Icon
                iconStyle={{ marginLeft: 9, marginRight: 9, flexShrink: 0 }}
                name={'magic'}
                color={theme.colors.primary}
                size={18}
              />
            )}
          </EventName>
          {!causingEvent && !cause && '-'}
          {comment && (
            <EventName>
              <Icon
                iconStyle={{ marginRight: 9 }}
                name={'comment'}
                color={theme.colors.brownGrey}
                size={18}
              />
              <TextWithEllipsisAndTooltip
                text={comment}
                lines={1}
                style={{
                  width: 'calc(100% - 32px)',
                  color: theme.colors.brownGrey,
                  fontStyle: 'italic',
                  paddingTop: 1,
                }}
              />
            </EventName>
          )}
        </LeftAlignedTableCell>
        <LeftAlignedTableCell>
          <TextWithEllipsisAndTooltip
            text={chosenActionName || '-'}
            lines={2}
          />
        </LeftAlignedTableCell>
        <CenterAlignedTableCell>
          {isDocumented ? (
            <Icon name="approved" size={28} color={theme.colors.primary} />
          ) : (
            <Icon
              name={'not-approved'}
              color={theme.colors.primary}
              size={28}
            />
          )}
        </CenterAlignedTableCell>
        <Table.Cell>
          <StyledCenter>
            <Icon
              name={selected ? 'arrow-down-bold' : 'arrow-right-bold'}
              color={'#8c8c8c'}
              size={16}
            />
          </StyledCenter>
        </Table.Cell>
      </StyledRow>
      {selected && (
        <StyledRow>
          <LowerRowCell colSpan={8}>
            <DowntimeEditingContainer>
              <EventAndActionSelectionContainer>
                <DowntimeCauseSelector
                  shiftReportManufacturingEntity={
                    shiftReportManufacturingEntity
                  }
                  report={report}
                  downtimeReasons={downtimeReasons}
                  downtimeReasonsLoadingInProgress={
                    downtimeReasonsLoadingInProgress
                  }
                  selectedDowntime={selectedDowntime}
                  selectedDowntimeLoadingInProgress={
                    selectedDowntimeLoadingInProgress
                  }
                  selectedEvent={selectedEvent}
                  onOtherReasonSelected={onOtherReasonSelected}
                  onPossibleEventSelected={onPossibleEventSelected}
                  selectedOtherReason={selectedOtherReason}
                  disabled={!editable}
                />
                <DowntimeSolutionSelector
                  selectedActionName={selectedActionName}
                  onActionSelected={onActionSelected}
                  selectedPossibleEvent={selectedPossibleEvent}
                  disabled={!editable}
                />
                <DowntimeCommentSelector
                  comment={comment}
                  onCommentChanged={onCommentChanged}
                  disabled={!editable}
                />
              </EventAndActionSelectionContainer>
              <div style={{ marginTop: 24 }}>
                <DowntimeEditButton
                  updateDowntimeInShiftReport={updateDowntimeInShiftReport}
                  selectedPossibleEvent={selectedPossibleEvent}
                  selectedOtherReason={selectedOtherReason}
                  selectedDowntimeAction={selectedDowntimeAction}
                  selectedEvent={selectedEvent}
                  comment={comment}
                  loading={downtimeUpdateInProgress}
                  disabled={!editable}
                />
              </div>
            </DowntimeEditingContainer>
          </LowerRowCell>
        </StyledRow>
      )}
    </>
  );
};

export default DowntimesInReportTableRow;
