import { RootReducerInterface } from '../../../../../../model/redux/interfaces';
import { connect } from 'react-redux';
import SelectRawEventsDialog from './view';
import Thunks from '../../../../../../model/redux/events/thunks';
import { SelectRawEventsDialogStoreProps } from './interface';

const mapStateToProps = (
  state: RootReducerInterface
): SelectRawEventsDialogStoreProps => {
  return {
    rawEvents: state.data.eventState.rawEvents.data.results,
    moreEventsCanBeLoaded: state.data.eventState.rawEvents.data.hasMoreResults,
    rawEventsMeta: state.data.eventState.rawEvents.meta,
    showManufacturingEntityHierarchy:
      state.configurationState.config.data?.showManufacturingEntityHierarchy
        .value,
  };
};

export default connect(mapStateToProps, {
  getRawEvents: Thunks.getRawEvents,
})(SelectRawEventsDialog);
