import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';
import { DataApiSortConfig } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import AggregatedDowntime from '../../domain/downtime/AggregatedDowntime';

const AGGREGATED_DOWNTIME_SEARCH_URL = '/downtimes/aggregated/search';

export default class DowntimeApi extends BaseDataApi<AggregatedDowntime> {
  async getAggregatedDowntimes(
    token: string,
    startTime: Date,
    endTime: Date,
    filters: FilterData[],
    signal: AbortSignal,
    sortConfig?: DataApiSortConfig
  ) {
    const dateFilters = [
      new FilterData(
        'startTimestamp',
        FilterOperation.GE_OPERATOR,
        startTime.valueOf()
      ),
      new FilterData(
        'endTimestamp',
        FilterOperation.LE_OPERATOR,
        endTime.valueOf()
      ),
    ];

    const response = await this.fetchOnePageOfDataWithPageAndSearchAndSort(
      AGGREGATED_DOWNTIME_SEARCH_URL,
      token,
      signal,
      0,
      30,
      [...filters, ...dateFilters],
      sortConfig
    );

    return {
      results: response.content,
      hasMoreResults: !response.last,
    };
  }
}
