import React, { ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';
import InstructionStepTranslationCard from './instruction_step_translation_card.tsx';
import {
  InstructionStepTranslation,
  InstructionWithTranslation,
} from '@/model/domain/instruction/translation.ts';
import {
  DisplayedDecision,
  DisplayedStep,
  SelectedDecision,
} from '@/model/domain/instruction/step.ts';
import InstructionTitleTranslationCard from './instruction_title_translation_card';

const Content = styled.div`
  margin-left: 16px;
  flex-direction: column;
  display: flex;
  width: 100%;
  align-items: center;

  > div {
    width: 100%;
    //margin-bottom: 16px;
    justify-self: center;
  }
`;

const TitleCardWrapper = styled.div`
  padding-right: 24px;
`;

const StepDivider = styled.div`
  scroll-behavior: smooth;
  flex-direction: column;
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 24px;
  overflow: visible;
  //max-height: calc(100vh - 320px);

  > div {
    width: 100%;
    //margin-bottom: 16px;
    justify-self: center;
  }
`;

const renderStepsRecursively = (
  decisions: Record<number, number>,
  items: readonly InstructionStepTranslation[],
  onChangeStep: (
    oldStep: InstructionStepTranslation,
    newStep: InstructionStepTranslation,
    parent?: string
  ) => void,
  onSelect: (s: InstructionStepTranslation, d: number) => void,
  selectedLanguage: string,
  parent?: string
): ReactNode => {
  const length = items.length ? items[0].step + items.length - 1 : 0;

  return items.map((item) => {
    const currentStep = (
      <div className="step">
        <InstructionStepTranslationCard
          key={item.id}
          index={item.step}
          length={length}
          item={item}
          selectedDecision={decisions[item.step]}
          onChangeStep={onChangeStep}
          onSelect={onSelect}
          selectedLanguage={selectedLanguage}
          parent={parent}
        />
      </div>
    );

    if (Object.prototype.hasOwnProperty.call(decisions, item.step)) {
      return [
        currentStep,
        renderStepsRecursively(
          decisions,
          item.decisionOptionTranslations[decisions[item.step]]
            .stepTranslations,
          onChangeStep,
          onSelect,
          selectedLanguage,
          item.decisionOptionTranslations[decisions[item.step]]?.id
        ),
      ];
    }

    return currentStep;
  });
};

// const findStepOrDecisionRecursively = (
//   id: string,
//   decisions: Record<number, number>,
//   items: readonly StepDTO[]
// ): InstructionStepTranslation | null => {
//   let foundItem: StepDTO | null = null;
//   items.forEach((item) => {
//     if (item.id === id) {
//       foundItem = item;
//       return;
//     }
//
//     if (Object.prototype.hasOwnProperty.call(decisions, item.step)) {
//       const index = decisions[item.step];
//       foundItem = findStepOrDecisionRecursively(
//         id,
//         decisions,
//         item.decisionOptions[index].steps
//       );
//     }
//   });
//
//   return foundItem;
// };

export interface InstructionTranslationCardContentProps {
  readonly translateItem: InstructionWithTranslation;
  readonly selectedLanguage: string;
  readonly onChangeTitle: (selectedLanguage: string, title: string) => void;
  readonly onChangeStep: (
    oldStep: InstructionStepTranslation,
    newStep: InstructionStepTranslation,
    parent?: string
  ) => void;
  readonly allowedToModify?: boolean;
  readonly onSelect: (s: DisplayedStep, d: DisplayedDecision) => void;
  readonly selectedDecisions: readonly SelectedDecision[];
  readonly onEditDecision: (step: InstructionStepTranslation) => void;
}

const InstructionTranslationCardContent = ({
  translateItem,
  onChangeTitle,
  selectedLanguage,
  onChangeStep,
}: InstructionTranslationCardContentProps) => {
  const [selectedDecisions, setSelectedDecisions] = useState<
    Record<number, number>
  >({});

  const stepsEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    setTimeout(function () {
      stepsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 1);
  };

  const onTitleChange = (value: string) => {
    onChangeTitle(selectedLanguage, value);
  };

  const onStepOrDecisionChange = (
    oldStep: InstructionStepTranslation,
    newStep: InstructionStepTranslation,
    parent?: string
  ) => {
    onChangeStep(oldStep, newStep, parent);
  };

  const onInternalSelect = (
    item: InstructionStepTranslation,
    index: number
  ) => {
    const selectedDecisionEntries = Object.entries(selectedDecisions);
    if (selectedDecisionEntries.length > 0) {
      const isLastSelection = selectedDecisionEntries.every(
        ([k]) => Number(k) <= item.step
      );
      if (isLastSelection) {
        setSelectedDecisions((s) => ({
          ...s,
          [item.step]: index,
        }));
      } else {
        const decisionsBefore = selectedDecisionEntries
          .filter(([k]) => Number(k) < item.step)
          .reduce((acc, [k, v]) => ({ ...acc, [Number(k)]: v }), {});
        setSelectedDecisions({
          ...decisionsBefore,
          [item.step]: index,
        });
      }
    } else {
      setSelectedDecisions((s) => ({
        ...s,
        [item.step]: index,
      }));
    }

    scrollToBottom();
  };

  return (
    <Content>
      <TitleCardWrapper id="solution_title">
        <InstructionTitleTranslationCard
          item={translateItem}
          onChange={onTitleChange}
          selectedLanguage={selectedLanguage}
        />
      </TitleCardWrapper>
      <StepDivider>
        {renderStepsRecursively(
          selectedDecisions,
          translateItem.stepTranslations,
          onStepOrDecisionChange,
          onInternalSelect,
          selectedLanguage
        )}
        <div ref={stepsEndRef} />
      </StepDivider>
    </Content>
  );
};

export default InstructionTranslationCardContent;
