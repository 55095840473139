import MediaDetailView from './media_detail_view';
import StyledDropzone from '../../Inputs/Dropzone';
import { Visibility } from 'semantic-ui-react';
import { Col, Container, Row } from 'fluid-react';
import AddImageItem from './addImageItem';
import { FormattedMessage } from 'react-intl';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Image as Img, Image } from '../../../model/redux/actions/interface';
import { FileRejection } from 'react-dropzone';
import { LoadingMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import DetailLoader from '@dev/base-web/dist/view/components/global/detail_loader';
import {
  ImageUpdate,
  ImageUpload,
} from '@dev/base-web/dist/model/redux/media/interface';
import { useAppDispatch, useAppSelector } from '../../../model/redux/hooks';
import {
  SelectedImageActions,
  UpdateActions,
  UploadActions,
} from '@dev/base-web/dist/model/redux/media/actions';
import mediaThunks from '../../../model/redux/media/thunks';
import { MediaCacheContext } from '@dev/base-web/dist/view/components/global/media_cache_provider';
import { useAuthorizationCheckRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import MediaType from '../../../model/domain/media/media_type';

const allowedFileTypes = {
  'image/png': [],
  'video/mp4': [],
  'image/jpeg': [],
  'image/heic': [],
  'video/quicktime': [],
  'video/mpeg': [],
  'application/pdf': [],
};

const DetailViewCol = styled(Col)`
  margin-bottom: 24px;
  width: 50%;
  box-sizing: border-box;
  overflow: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ImageWrapper = styled(Container)`
  padding: 24px 24px 24px 24px;
  display: flex;

  @media (max-width: 768px) {
    padding: 24px 0px 24px 24px;
  }
`;

const StyledCol = styled(Col)`
  padding-right: 24px;
  margin-bottom: 24px;
`;

const ScrollCol = styled(Col)`
  overflow-y: scroll;
  margin-bottom: 70px;

  @media (max-width: 768px) {
    overflow-y: visible;
  }
`;

const NoImages = styled.span`
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
`;

interface AddImageModalContentProps {
  mediaType: MediaType;
  images: Image[];
  imageListMeta: LoadingMetaState;
  uploading: readonly ImageUpload[];
  updating: ImageUpdate | null;
  uploadFilesWithoutCropping: (files: File[]) => void;
  throwRej: (rej: FileRejection[]) => void;
  loadNextPage: () => void;
}

const AddImageModalContent = ({
  mediaType,
  images,
  imageListMeta,
  uploading,
  updating,
  uploadFilesWithoutCropping,
  throwRej,
  loadNextPage,
}: AddImageModalContentProps) => {
  const dispatch = useAppDispatch();
  const selected = useAppSelector((state) => state.data.mediaState.selected);
  const cacheContext = useContext(MediaCacheContext);

  const { write: canEdit, delete: canDelete } = useAuthorizationCheckRWD(
    UserPrivilegePermission.ACTION,
    ApplicationCategory.SHANNON
  );

  const imageListState: readonly ImageUpdate[] = images.map((i) => {
    if (i === updating?.data) {
      return updating;
    } else {
      return {
        data: i,
        meta: {
          operationInProgress: false,
        },
      };
    }
  });

  const selectImage = (image: Image) => {
    dispatch(SelectedImageActions.select({ image }));
  };

  const deleteImage = () => {
    if (selected) {
      dispatch(mediaThunks.deleteActionImage(selected.urls.full));
    }
  };

  const updateImage = (image: Img, picture: File) => {
    cacheContext.invalidateCacheForUrl(image.urls.full);
    cacheContext.invalidateCacheForUrl(image.urls.thumb);
    dispatch(mediaThunks.updatePicture(image, picture));
  };

  const revertImage = (image: Img, asNew: boolean) => {
    cacheContext.invalidateCacheForUrl(image.urls.full);
    cacheContext.invalidateCacheForUrl(image.urls.thumb);
    dispatch(mediaThunks.revertPicture(image, asNew));
  };

  return (
    <>
      <DetailViewCol>
        {selected !== null ? (
          <MediaDetailView
            item={selected}
            onDelete={deleteImage}
            onChange={updateImage}
            onRevert={revertImage}
          />
        ) : (
          <StyledDropzone
            onFileUploaded={uploadFilesWithoutCropping}
            onRejection={throwRej}
            accept={allowedFileTypes}
            mediaType={mediaType}
            disabled={!canEdit}
          />
        )}
      </DetailViewCol>
      <ScrollCol>
        <ImageWrapper>
          <Visibility
            onBottomVisible={loadNextPage}
            once={false}
            style={{ width: '100%' }}
            //fireOnMount
          >
            <Row style={{ width: '100%' }}>
              {!(
                (!images || images.length === 0) &&
                imageListMeta.loadingInProgress
              ) && (
                <StyledCol xs="6" sm="4" md="6" lg="6" xl="4">
                  <StyledDropzone
                    onFileUploaded={uploadFilesWithoutCropping}
                    onRejection={throwRej}
                    small
                    accept={allowedFileTypes}
                    disabled={!canEdit}
                  />
                </StyledCol>
              )}
              {uploading &&
                uploading.length > 0 &&
                uploading.map((item, pos) => {
                  return (
                    <StyledCol key={pos} xs="6" sm="4" md="6" lg="6" xl="4">
                      <AddImageItem
                        errorClick={() =>
                          dispatch(UploadActions.removeError({ pos }))
                        }
                        selected={false}
                        onSelect={() => {}}
                        item={{
                          creationTimestamp: 0,
                          createdBy: null,
                          manufacturingEntities: [],
                          urls: {
                            full: '',
                            thumb: '',
                            fileType: 'PICTURE',
                          },
                        }}
                        onDelete={() => {}}
                        isLoading={item.meta.operationInProgress}
                        gotError={item.meta.error}
                        canDelete={canDelete}
                      />
                    </StyledCol>
                  );
                })}
              {imageListState.length > 0
                ? imageListState.map((image, _) => {
                    return (
                      <StyledCol
                        key={image.data.urls.thumb}
                        xs="6"
                        sm="4"
                        md="6"
                        lg="6"
                        xl="4"
                      >
                        <AddImageItem
                          errorClick={() =>
                            dispatch(UpdateActions.clearUpdateState())
                          }
                          selected={
                            selected?.urls.full === image.data.urls.full
                          }
                          onSelect={
                            !image.meta.operationInProgress
                              ? selectImage
                              : () => {}
                          }
                          item={image.data}
                          onDelete={
                            !image.meta.operationInProgress
                              ? deleteImage
                              : () => {}
                          }
                          isLoading={image.meta.operationInProgress}
                          gotError={image.meta.error}
                          canDelete={canDelete}
                        />
                      </StyledCol>
                    );
                  })
                : !imageListMeta.loadingInProgress && (
                    <NoImages>
                      {
                        <FormattedMessage
                          id={
                            mediaType === 'VIDEO'
                              ? 'no_videos'
                              : mediaType === 'PDF'
                              ? 'no_pdfs'
                              : 'no_pictures'
                          }
                        />
                      }
                    </NoImages>
                  )}
            </Row>
            {imageListMeta.loadingInProgress && <DetailLoader />}
          </Visibility>
        </ImageWrapper>
      </ScrollCol>
    </>
  );
};

export default AddImageModalContent;
