import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import {
  EventCoreInterface,
  EventStatusType,
  EventType,
} from '@dev/base-web/dist/model/domain/event/event_type';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import { FeatureGeneratorInputProps } from '@dev/base-web/dist/model/domain/feature/feature';
import { DurationUnit } from '@dev/base-web/dist/model/domain/durations/duration';
import { ActionDTO } from '../instruction/instruction';
import { TElement } from '@udecode/plate-common';

export default interface Event {
  readonly id: string;
  readonly type: EventType;
  readonly name: string;
  readonly description: string;
  readonly actionCount: number;
  readonly occurrenceCount: number;
  readonly commentCount: number;
  readonly manufacturingEntities: ManufacturingEntity[];
  readonly manufacturingEntity: ManufacturingEntity;
  readonly createdBy: UserDetails;
  readonly endTriggerTimestamp: number;
  readonly startTimestamp: number;
  readonly modifiedDate: number;
}

export interface SubsequentEventDTO extends EventCoreInterface {
  readonly startTimestamp: number;
  readonly endTriggerTimestamp: number;
  readonly endTimestamp: number;
  readonly manufacturingEntity: ManufacturingEntity;
  readonly status: EventStatusType;
}

export interface EventFeedbackDTO {
  readonly feedbackRichText: string; // TElement[];
  readonly feedbackText?: string;
  readonly eventId?: string;
}

export interface EventData {
  readonly id?: string;
  readonly description: string;
  readonly eventDefinitionId: string;
  readonly name: string;
  readonly solutionCount: number;
  readonly type: EventType;
  readonly eventType?: EventType;
}

export interface EventDefinitionBase {
  readonly id: string;
  readonly type: EventType;
  readonly name: string;
  readonly manufacturingEntities?: ManufacturingEntity[];
}

export interface EventDefinition extends EventDefinitionBase {
  readonly description: string;
  readonly isHidden: boolean;
  readonly isCommon: boolean;
  readonly isInternal: boolean;
  readonly excludeFromStats: boolean;
  readonly subscribers: UserDetails[];
  readonly rules: EventRule[];
  readonly actions: readonly ActionDTO[];
  readonly comments: Comment[];
  readonly translations: EventDefinitionTranslation[];
}

export interface EventDefinitionAdd {
  readonly id?: string;
  readonly type: EventType;
  readonly name: string;
  readonly description: string;
  readonly isHidden: boolean;
  readonly isCommon: boolean;
  readonly excludeFromStats: boolean;
  readonly subscribers: readonly string[];
  readonly rules: EventRule[];
  readonly translations: EventDefinitionTranslation[];
  readonly actions: readonly string[];
}

export interface EventDefinitionUpdate {
  readonly ids: string[];
  readonly updates: EventDefinition;
}

export interface Comment {
  readonly id: string;
  readonly user: UserDetails;
  readonly timestamp: number;
  readonly description?: string;
  readonly descriptionRichText?: TElement[];
}

export interface EventDefinitionTranslation {
  readonly language: string;
  readonly name: string;
  readonly description: string;
}

export enum EventRuleType {
  AGGREGATED = 'AGGREGATED',
  DYNAMIC = 'DYNAMIC',
  STANDARD = 'STANDARD',
}

export enum EventRuleGroupOperation {
  ALL = 'ALL',
  ANY = 'ANY',
}

export interface AggregationChildRule {
  readonly childRuleId: string;
  readonly primary: boolean;
  readonly eventDefinition: EventDefinitionBase;
}

export interface EventRule {
  readonly id?: string;
  readonly type: EventRuleType;
  readonly open: OpeningFeatureCondition | null;
  readonly close: ClosingFeatureCondition | null;
  readonly closedByTrigger?: CloseByTriggerFeatureCondition;
  readonly manufacturingEntity?: ManufacturingEntity;
  readonly isClosedByDuration: boolean | null;
  readonly dynamicProperties: EventRuleDynamicProperties;
  readonly groupOperation?: EventRuleGroupOperation;
  readonly aggregationChildRules?: AggregationChildRule[];
  readonly temporaryId?: string;
}

export interface CloseByTriggerFeatureCondition {
  readonly type: ClosingConditionType.FEATURE;
  readonly featureId: string;
  readonly value: FeatureGeneratorInputProps;
}

export interface EventRuleDynamicProperties {
  readonly text: string | null;
  readonly entityName: string | null;
}

export interface OpeningCondition {
  readonly type: OpeningConditionType;
}

export enum OpeningConditionType {
  SCHEDULED = 'SCHEDULED',
  FEATURE = 'FEATURE',
}

export interface ClosingCondition {
  readonly type: ClosingConditionType;
}

export enum ClosingConditionType {
  FEATURE = 'FEATURE',
  TIMER = 'TIMER',
  USER_ACTION = 'USER_ACTION',
}

export const EVENT_CONDITION_TYPE = {
  FEATURE: { key: 'FEATURE', label: 'signal' },
  USER_ACTION: { key: 'USER_ACTION', label: 'condition_user_action' },
  SCHEDULED: { key: 'SCHEDULED', label: 'condition_time' },
  TIMER: { key: 'TIMER', label: 'condition_duration' },
};

export interface ClosingFeatureCondition extends ClosingCondition {
  readonly featureId: string;
  readonly value: FeatureGeneratorInputProps;
}

export interface OpeningFeatureCondition extends OpeningCondition {
  readonly featureId: string;
  readonly value: FeatureGeneratorInputProps;
}

export interface UserActionCondition extends ClosingCondition {
  readonly onClose: boolean;
}

export interface TimerCondition extends ClosingCondition {
  readonly duration: number;
  readonly unit: DurationUnit;
}

export interface ScheduledCondition extends OpeningCondition {
  readonly timestamp: number;
  readonly iterationType: IterationType;
  readonly iteration: number;
}

export enum IterationType {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
}

export enum EventDefinitionStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  INACTIVE = 'INACTIVE',
}
