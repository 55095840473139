import React from 'react';
import styled from 'styled-components';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { FormattedMessage } from 'react-intl';
import { CONFIG as REPORTS_CONFIG } from '../index';
import { Table } from 'semantic-ui-react';
import {
  LeftAlignedTableCell,
  StyledFirstCell,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import { useNavigate } from 'react-router';
import ColoredKPI from '@dev/base-web/dist/view/components/kpi/colored_kpi';
import SimpleKPI from '@dev/base-web/dist/view/components/kpi/simple_kpi';
import { calculateDocumentedDowntimesColorValue } from '../../components/kpi_helpers';
import ProductionRatioPopup from './production_ratio_popup';
import ThreeStatePercentageKPI from './three_state_percentage_kpi';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import ShiftReportOverview from '@dev/base-web/dist/model/domain/report/ShiftReportOverview';
import { ManufacturingEntityType } from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';

const StyledRow = styled(Table.Row)`
  border-radius: 15px;
  border-bottom: solid 1px rgba(36, 36, 36, 0.05);
  cursor: pointer;
`;

const StyledCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ReportOverviewRowExternalProps {
  result: ShiftReportOverview;
}

type ReportOverviewRowProps = ReportOverviewRowExternalProps;

export const ReportOverviewRow: React.FC<ReportOverviewRowProps> = ({
  result,
}) => {
  const navigate = useNavigate();
  const { formatDate, formatDuration } = useLocalizedDateFormatters();

  const onRowSelected = () => {
    navigate(
      {
        pathname: `${REPORTS_CONFIG.url.path}/${result.id}`,
      },
      { state: { selectedReport: result } }
    );
  };

  const {
    shift,
    manufacturingEntity,
    documentedDowntimes,
    totalDowntimes,
    producedProducts,
    cycleCount,
    productionRate,
    downtime,
  } = result;

  const formattedStartShift = formatDate(shift.start, 'P');
  const formattedDownTime = formatDuration(downtime, 'HH:mm:ss');

  return (
    <StyledRow onClick={() => onRowSelected()}>
      <StyledFirstCell>{formattedStartShift}</StyledFirstCell>
      <LeftAlignedTableCell>
        <FormattedMessage id={shift.shiftName} />
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        <ManufacturingEntityHierarchyElement
          manufacturingEntity={manufacturingEntity}
          hideHiddenTypes
          hiddenTypes={[
            ManufacturingEntityType.FACTORY,
            ManufacturingEntityType.AREA,
          ]}
          lines={2}
        />
      </LeftAlignedTableCell>
      <Table.Cell>
        <StyledCenter>
          <SimpleKPI value={producedProducts || '-'} small />
        </StyledCenter>
      </Table.Cell>
      <Table.Cell>
        <StyledCenter>
          <ProductionRatioPopup
            interval={shift}
            cycleCount={cycleCount || 0}
            trigger={
              <div>
                <ThreeStatePercentageKPI
                  value={productionRate}
                  upperTreshold={0.7}
                  lowerTreshold={0.4}
                  small
                />
              </div>
            }
          />
        </StyledCenter>
      </Table.Cell>
      <Table.Cell>
        <StyledCenter>
          <SimpleKPI value={formattedDownTime} small />
        </StyledCenter>
      </Table.Cell>
      <Table.Cell>
        <StyledCenter>
          <ColoredKPI
            value={`${documentedDowntimes}/${totalDowntimes}`}
            small
            showGradient
            colorValue={calculateDocumentedDowntimesColorValue(
              documentedDowntimes,
              totalDowntimes
            )}
          />
        </StyledCenter>
      </Table.Cell>
      <Table.Cell>
        <StyledCenter>
          <Icon name={'arrow-right-bold'} color={'#8c8c8c'} size={16} />
        </StyledCenter>
      </Table.Cell>
    </StyledRow>
  );
};
