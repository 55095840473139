import React, { Component } from 'react';
import styled from 'styled-components';
import { StyledInput } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { FormattedMessage } from 'react-intl';
import { Divider, Form, InputOnChangeData } from 'semantic-ui-react';
import ToggleSwitch from '@dev/base-web/dist/view/components/inputs/toggle_switch';
import UserUpdateDTO from '@dev/base-web/dist/model/domain/user/user_update_dto';
import UserStatus from '@dev/base-web/dist/model/domain/user/user_status';
import { emailValidator } from '@dev/base-web/dist/model/helpers/validators';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import { ConfigContainer } from './styled_components';
import Alert, {
  AlertSeverity,
} from '@dev/base-web/dist/view/components/global/alert';
import {
  Button,
  LeftAlignedButtons,
} from '@dev/base-web/dist/view/components/global/button';
import DeleteConfirmationModal from '@dev/base-web/dist/view/components/modal/delete_confirmation_modal';
import UpdatePasswordModal from './update_password_modal';
import PasswordForm from './update_password_modal/password_form';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';

const TextInput = styled(StyledInput)`
  margin-top: 8px;
  width: 100%;
`;

interface GeneralSettingsCardProps {
  readonly userData: UserUpdateDTO;
  readonly isAdUser: boolean;
  readonly isUserNew?: boolean;
  readonly isUserEditable?: boolean;
  readonly userId?: string;
  readonly canDelete?: boolean;
  readonly isUsernameTaken: boolean;
  readonly updateUserData: (newUserData: UserUpdateDTO) => void;
  readonly updatePasswordValidity: (isValid: boolean) => void;
  readonly getUsernameExists: (username: string) => void;
  readonly minimumPasswordStrength: number;
  readonly deleteUser: () => void;
}

interface GeneralSettingsCardState {
  readonly password: string | undefined;
  readonly passwordConfirmation: string | undefined;
  readonly passwordValid: boolean;
  readonly passwordWeak: boolean;
  readonly passwordShown: boolean;
  readonly passwordConfirmationShown: boolean;
}

export default class GeneralSettingsCard extends Component<
  GeneralSettingsCardProps,
  GeneralSettingsCardState
> {
  constructor(props: GeneralSettingsCardProps) {
    super(props);
    this.state = {
      password: undefined,
      passwordConfirmation: undefined,
      passwordValid: false,
      passwordWeak: false,
      passwordShown: false,
      passwordConfirmationShown: false,
    };
  }

  componentDidUpdate(prevProps: GeneralSettingsCardProps) {
    if (
      this.props.userData !== prevProps.userData &&
      !Object.keys(this.props.userData).includes('password')
    ) {
      this.setState({ password: undefined, passwordConfirmation: undefined });
    }
  }

  onFirstNameChanged = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    this.props.updateUserData({ firstName: data.value });
  };

  onLastNameChanged = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    this.props.updateUserData({ lastName: data.value });
  };

  onUserNameChanged = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    this.props.updateUserData({ username: data.value });
    data.value.length && this.props.getUsernameExists(data.value);
  };

  onEmailChanged = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    this.props.updateUserData({
      email: data.value !== '' ? data.value : undefined,
    });
  };

  onStatusChanged = (checked: boolean) => {
    const newStatus = checked ? UserStatus.ACTIVE : UserStatus.INACTIVE;
    this.props.updateUserData({ status: newStatus });
  };

  onPasswordChanged = (password?: string) => {
    this.setState({ password });
    this.validateAndUpdatePassword(password, this.state.passwordConfirmation);
  };

  onPasswordConfirmationChanged = (passwordConfirmation?: string) => {
    this.setState({ passwordConfirmation });
    this.validateAndUpdatePassword(this.state.password, passwordConfirmation);
  };

  onPasswordShownChanged = () => {
    this.setState({ passwordShown: !this.state.passwordShown });
  };

  onPasswordConfirmationShownChanged = () => {
    this.setState({
      passwordConfirmationShown: !this.state.passwordConfirmationShown,
    });
  };

  validateAndUpdatePassword = (
    password: string | undefined,
    passwordConfirmation: string | undefined
  ) => {
    if (
      password &&
      passwordConfirmation &&
      password === passwordConfirmation &&
      !this.state.passwordWeak
    ) {
      const passwordValid = true;
      this.setState({ passwordValid });
      this.props.updateUserData({ password });
      this.props.updatePasswordValidity(passwordValid);
    }
  };

  validatePasswordStrength = (passwordStrength: number) => {
    this.setState({
      passwordWeak: passwordStrength < this.props.minimumPasswordStrength,
    });
  };

  render() {
    const { firstName, lastName, username, email, status } =
      this.props.userData;
    const {
      isUserEditable,
      isAdUser,
      isUserNew,
      isUsernameTaken,
      deleteUser,
      canDelete,
      userId,
    } = this.props;
    const {
      passwordWeak,
      passwordValid,
      password,
      passwordConfirmation,
      passwordShown,
      passwordConfirmationShown,
    } = this.state;

    const isDeleteEnabled =
      canDelete &&
      !isUserNew &&
      username !== 'bot' &&
      username !== 'ghost' &&
      !username?.includes('plus10');

    return (
      <Card titleId="general">
        {isAdUser && (
          <div style={{ marginTop: 12 }}>
            <Alert
              severity={AlertSeverity.WARNING}
              textId="ad_user_warning"
              textValues={{}}
            />
          </div>
        )}
        <ConfigContainer>
          <Form id={'update_user'}>
            <Form.Field>
              <LabelWithHint label="first_name" />
              <TextInput
                disabled={!isUserEditable || isAdUser}
                value={firstName}
                onChange={this.onFirstNameChanged}
                //error={!firstName}
              />
            </Form.Field>
            <Form.Field>
              <LabelWithHint label="last_name" />
              <TextInput
                disabled={!isUserEditable || isAdUser}
                value={lastName}
                onChange={this.onLastNameChanged}
                //error={!lastName}
              />
            </Form.Field>
            <Form.Field error={!username}>
              <LabelWithHint label="login_username" />
              <TextInput
                disabled={!isUserEditable || isAdUser || !isUserNew}
                value={username}
                onChange={this.onUserNameChanged}
                error={!username}
              />
            </Form.Field>
            {isUsernameTaken && (
              <div style={{ marginBottom: 24 }}>
                <Alert
                  severity={AlertSeverity.WARNING}
                  textId="username_exists"
                  textValues={{}}
                />
              </div>
            )}
            <Form.Field error={!(email && emailValidator(email))}>
              <LabelWithHint label="email_address" />
              <TextInput
                disabled={!isUserEditable || isAdUser}
                value={email}
                onChange={this.onEmailChanged}
                error={!(email && emailValidator(email))}
              />
            </Form.Field>
            {isUserNew && (
              <>
                <Divider />
                <PasswordForm
                  password={password}
                  confirmPassword={passwordConfirmation}
                  passwordShown={passwordShown}
                  confirmPasswordShown={passwordConfirmationShown}
                  setPassword={this.onPasswordChanged}
                  setConfirmPassword={this.onPasswordConfirmationChanged}
                  setPasswordStrength={this.validatePasswordStrength}
                  isAdUser={isAdUser}
                  isUserEditable={isUserEditable}
                  setPasswordShown={this.onPasswordShownChanged}
                  setConfirmPasswordShown={
                    this.onPasswordConfirmationShownChanged
                  }
                  isPasswordValid={passwordValid}
                  isPasswordWeak={passwordWeak}
                  user={this.props.userData}
                />
              </>
            )}
            <Divider />
            <ToggleSwitch
              disabled={!isUserEditable || isAdUser}
              onChange={this.onStatusChanged}
              checked={status === UserStatus.ACTIVE}
            >
              <FormattedMessage id="user_status" />
            </ToggleSwitch>
            <div
              style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}
            >
              <LeftAlignedButtons>
                <DeleteConfirmationModal
                  title="delete_user"
                  primaryButtonTitle={'delete'}
                  confirmationTitle="delete_user_confirmation"
                  onDeleteConfirmed={deleteUser}
                  confirmationTitleValues={{
                    user: username || '-',
                    br: <br />,
                  }}
                  triggerDisabled={!isDeleteEnabled}
                  triggerButton={
                    <Button
                      type="secondary"
                      label="delete"
                      disabled={!isDeleteEnabled}
                    />
                  }
                />
                {!isUserNew && this.props.userData && userId && !isAdUser && (
                  <UpdatePasswordModal
                    userId={userId}
                    isAdUser={isAdUser}
                    minimumPasswordStrength={this.props.minimumPasswordStrength}
                    isUserEditable={isUserEditable}
                    user={this.props.userData}
                  />
                )}
              </LeftAlignedButtons>
            </div>
          </Form>
        </ConfigContainer>
      </Card>
    );
  }
}
