import {
  DomainPropType,
  DomainTuple,
  ForAxes,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryZoomContainer,
} from 'victory';
import EmptyOrLoadingView from '@dev/base-web/dist/view/components/global/no_data';
import sizeMe, { SizeMeProps } from 'react-sizeme';
import { firstTickOfTheDay } from '@dev/base-web/dist/view/components/charts/helper';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import { Theme } from '@dev/base-web/dist/view/themes/interface';
import { snakeToCamel } from '@dev/base-web/dist/model/helpers/snake_camel';
import GanttChartLabel from './downtime_in_report_chart_label';
import DowntimeOverview from '@dev/base-web/dist/model/domain/report/DowntimeOverview';
import ShiftReportOverview from '@dev/base-web/dist/model/domain/report/ShiftReportOverview';

interface DowntimesInReportChartExternalProps {
  readonly report: ShiftReportOverview;
  readonly downtimesInReport: readonly DowntimeOverview[];
  readonly downtimesInReportLoadingInProgress: boolean;
  readonly onDowntimeSelected: (downtime?: DowntimeOverview) => void;
}

type DowntimesInReportChartProps = DowntimesInReportChartExternalProps &
  SizeMeProps;

const stroke = { stroke: 'rgba(0, 0, 0, 0.1)' };

export interface ChartDatum {
  readonly x: string;
  readonly y: Date;
  readonly y0: Date;
  readonly downtimeOverview: DowntimeOverview;
  readonly label: string;
}

const getDowntimeReportColor = (d: DowntimeOverview, theme: Theme): string => {
  if (d.causingEvent)
    return theme.colors.diagrams.paletteManufacturingStates.error;
  else if (d.cause) {
    const camelCause = snakeToCamel(d.cause.toLowerCase());
    if (
      Object.keys(theme.colors.diagrams.paletteManufacturingStates).includes(
        camelCause
      )
    )
      return (
        theme.colors.diagrams.paletteManufacturingStates as Record<
          string,
          string
        >
      )[camelCause];
    else return theme.colors.black;
  } else return theme.colors.diagrams.paletteManufacturingStates.stop;
};

const DowntimesInReportChart = ({
  downtimesInReport,
  downtimesInReportLoadingInProgress,
  size,
  report,
  onDowntimeSelected,
}: DowntimesInReportChartProps) => {
  const intl = useIntl();
  const { formatDate } = useLocalizedDateFormatters();
  const theme = useTheme();

  const initialDomain = useMemo<DomainPropType>(
    () => ({
      y: [report.shift.start, Math.min(report.shift.end, Date.now())],
    }),
    [report]
  );

  const xPadding = 40;

  const [domain, setDomain] = useState<ForAxes<DomainTuple>>(initialDomain);
  const domainWidthForPixel =
    ((domain as any).y[1] - (domain as any).y[0]) /
    Math.max((size.width ?? 1) - xPadding * 2, 1);

  const chartData = useMemo(
    () =>
      downtimesInReport.map<ChartDatum[]>((downtime) => {
        return [
          {
            x: '____',
            y: new Date(downtime.startTimestamp),
            y0: new Date(downtime.endTimestamp),
            downtimeOverview: downtime,
            label: downtime.causingEvent?.type ?? EventType.UNKNOWN_EVENT,
          },
        ];
      }),

    [downtimesInReport]
  );

  const backgroundBarData = [
    {
      x: '____',
      y: new Date(report.shift.start),
      y0: new Date(Math.min(report.shift.end, Date.now())),
    },
  ];

  return (
    <>
      {downtimesInReport.length && !downtimesInReportLoadingInProgress ? (
        <div style={{ height: 190 }}>
          <VictoryChart
            domain={initialDomain}
            padding={{
              left: xPadding,
              right: xPadding,
              top: 0,
            }}
            domainPadding={{ x: xPadding, y: [0, 0] }}
            width={size.width ?? 0}
            height={100}
            scale={{ x: 'linear', y: 'time' }}
            containerComponent={
              <VictoryZoomContainer
                zoomDimension="y"
                minimumZoom={{ x: 1 }}
                onZoomDomainChange={setDomain}
                // onZoomDomainChange={setDomain}
              />
            }
          >
            <VictoryAxis
              style={{
                axisLabel: { fontSize: 10, padding: xPadding },
                axis: stroke,
              }}
              tickLabelComponent={<></>}
            />
            <VictoryAxis
              dependentAxis
              tickFormat={(tick: Date, i: number, all: Date[]) =>
                firstTickOfTheDay(tick, i, all)
                  ? `${formatDate(tick, 'P')}\n${formatDate(tick, 'pp')}`
                  : formatDate(tick, 'pp')
              }
              label={intl.formatMessage({ id: 'condition_time_time' })}
              axisLabelComponent={
                <VictoryLabel
                  textAnchor="end"
                  style={{
                    fontFamily: 'Relative',
                    fontSize: 14,
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.67,
                    letterSpacing: 'normal',
                  }}
                  x={(size.width ?? 0) - xPadding}
                />
              }
              style={{
                axis: { stroke: '#898989' },
                axisLabel: {
                  padding: 30,
                },
                grid: stroke,
              }}
            />
            <VictoryAxis
              dependentAxis
              orientation="top"
              style={{
                axis: stroke,
              }}
              tickFormat={() => ''}
            />
            <VictoryAxis
              offsetX={(size.width ?? 0) - xPadding}
              style={{
                axis: stroke,
              }}
              tickFormat={() => ''}
            />
            <VictoryBar
              key={'background_bar'}
              samples={100}
              horizontal
              barWidth={20}
              cornerRadius={{
                top: 2,
                bottom: 2,
                topLeft: 2,
                topRight: 2,
                bottomLeft: 2,
                bottomRight: 2,
              }}
              style={{
                data: {
                  fill: theme.colors.diagrams.paletteManufacturingStates
                    .processing,
                  width: 6,
                  borderRadius: 10,
                },
              }}
              data={backgroundBarData}
            />
            {chartData.map((data, index) => (
              <VictoryBar
                key={index}
                horizontal
                barWidth={24}
                cornerRadius={{
                  top: 2,
                  bottom: 2,
                  topLeft: 2,
                  topRight: 2,
                  bottomLeft: 2,
                  bottomRight: 2,
                }}
                labelComponent={
                  <GanttChartLabel
                    domainWidthForPixel={domainWidthForPixel}
                    barWidth={24}
                    onDowntimeSelected={onDowntimeSelected}
                  />
                }
                style={{
                  data: {
                    fill: ({ datum }) => {
                      return getDowntimeReportColor(
                        (datum as ChartDatum).downtimeOverview,
                        theme
                      );
                    },
                    width: 6,
                    borderRadius: 10,
                  },
                }}
                data={data}
              />
            ))}
          </VictoryChart>
        </div>
      ) : (
        <EmptyOrLoadingView
          loadingInProgress={downtimesInReportLoadingInProgress}
          dataSelected={!!downtimesInReport}
          hasResults={!!downtimesInReport}
        />
      )}
    </>
  );
};

export default sizeMe({ monitorWidth: true })(DowntimesInReportChart);
