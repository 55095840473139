import React from 'react';
import { format } from 'date-fns';
import { FilterOperation } from '@dev/base-web/dist/model/domain/common/filter_data';
import useFilterState from './filter_hook';
import { DateRangePicker } from '@dev/base-web/dist/view/components/inputs/date_range_picker';
import { FilterProps } from './interfaces';

const ImageTimestampFilter: React.FC<FilterProps> = (props) => {
  const { localFilters, setLocalFilters, externalFilters } = props;

  const [startTimestamp, setStartTimestamp] = useFilterState<number>(
    'createdAt',
    { groupingKey: 'timestamp', icon: 'time', groupingOrder: 0 },
    (value) => format(value, 'dd.MM.yyyy-'),
    localFilters,
    setLocalFilters,
    externalFilters,
    FilterOperation.GE_OPERATOR
  );
  const [endTimestamp, setEndTimestamp] = useFilterState<number>(
    'createdAt',
    { groupingKey: 'timestamp', icon: 'time', groupingOrder: 1 },
    (value) => format(value, 'dd.MM.yyyy'),
    localFilters,
    setLocalFilters,
    externalFilters,
    FilterOperation.LE_OPERATOR
  );

  return (
    <DateRangePicker
      onDateRangeChanged={(startDate: Date, endDate: Date) => {
        setStartTimestamp(startDate.valueOf());
        setEndTimestamp(endDate.valueOf());
      }}
      selectedStartDate={startTimestamp ? new Date(startTimestamp) : undefined}
      selectedEndDate={endTimestamp ? new Date(endTimestamp) : undefined}
      onDateRangeCleared={() => {
        setStartTimestamp(undefined);
        setEndTimestamp(undefined);
      }}
      clearable
    />
  );
};

export default ImageTimestampFilter;
