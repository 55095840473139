import {
  ClosingCondition,
  ClosingConditionType,
  ClosingFeatureCondition,
  TimerCondition,
  UserActionCondition,
} from '../../../../model/domain/event/event_definition';
import React from 'react';
import FeatureCard from './feature_card';
import UserActionConditionCard from './user_action_condition_card';
import TimerConditionCard from './timer_condition_card';
import EmptyConditionCard from './empty_condition_card';
import { FeatureColors } from '@dev/base-web/dist/view/components/feature/styles';
import FeatureConfigurationInstance from '@dev/base-web/dist/model/domain/feature/feature_configuration';

interface ClosingConditionCardProps {
  readonly condition: ClosingCondition | null;
  readonly odd?: boolean;
  readonly replaceColors?: FeatureColors;
  readonly featureConfiguration: FeatureConfigurationInstance | null;
}

const ClosingConditionCard: React.FC<ClosingConditionCardProps> = ({
  condition,
  odd,
  replaceColors,
  featureConfiguration,
}) => {
  return (
    <>
      {condition === null ||
      (condition.type === ClosingConditionType.FEATURE &&
        !(condition as ClosingFeatureCondition).value) ? (
        <EmptyConditionCard />
      ) : condition.type === ClosingConditionType.FEATURE ? (
        <FeatureCard
          featureId={(condition as ClosingFeatureCondition).featureId}
          featureValue={(condition as ClosingFeatureCondition).value}
          odd={odd}
          replaceColors={replaceColors}
          featureConfiguration={featureConfiguration}
        />
      ) : condition.type === ClosingConditionType.TIMER ? (
        <TimerConditionCard
          condition={condition as TimerCondition}
          disabled={false}
          replaceColor={replaceColors && replaceColors.even}
        />
      ) : condition.type === ClosingConditionType.USER_ACTION ? (
        <UserActionConditionCard condition={condition as UserActionCondition} />
      ) : (
        <EmptyConditionCard />
      )}
    </>
  );
};

export default ClosingConditionCard;
