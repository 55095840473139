import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MediaThumbnail } from '../../Media';
import Loader from '@dev/base-web/dist/view/components/global/loader';
import { IntlShape, useIntl } from 'react-intl';
import ReactLoading from 'react-loading';
import { Image } from '../../../model/redux/actions/interface';
import {
  toastrError,
  toastrWarning,
} from '@dev/base-web/dist/view/helpers/notification_helpers';
import { LoadWrapper, StyledCancelButton } from './styled_components';
import AddImageItemError from './add_image_item_error';
import ImageDeleteButton from './image_delete_button';
import { ErrorInterface } from '@dev/base-web/dist/model/api/error/error_interface';

const ItemThumbnail = styled(MediaThumbnail)<{ selected: boolean }>`
  margin-bottom: 0px;
  width: 100%;
  height: 124px;
  cursor: pointer;
  border: solid 2px;
  border-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary : 'transparent'};
  box-sizing: border-box;
  border-radius: 4px;
  line-height: 0em;
  overflow: hidden;
`;

const ImageItemContainer = styled.div`
  position: relative;
  box-sizing: content-box;
  //padding-top: 2px;
  ${StyledCancelButton} {
    visibility: visible;
  }

  // :hover {
  //   ${StyledCancelButton} {
  //     visibility: visible;
  //   }
  // }
`;

interface Props {
  onDelete: (imgUrl: string) => void;
  onSelect: (item: Image) => void;
  errorClick?: () => void;
  item: Image;
  selected: boolean;
  isLoading?: boolean;
  gotError?: ErrorInterface;
  readonly canDelete: boolean;
}

function getErrorMessage(gotError?: ErrorInterface) {
  return gotError
    ? gotError.title === 'SERVER_ERROR'
      ? 'upload_failed'
      : gotError.title === 'BAD_REQUEST' && gotError.message.includes('Maximum')
      ? 'max_file_size_exceeded'
      : 'error'
    : 'error';
}

function getFileSizeMb(matches: RegExpMatchArray | null) {
  return matches && Number(matches[1]) > 0
    ? Math.round(Number(matches[1]) * 0.000001)
    : 0;
}

const LoadingSpinner = () => {
  return (
    <LoadWrapper>
      <div style={{ margin: '0 auto', display: 'block' }}>
        <ReactLoading
          type="spokes"
          color="#0da6a0"
          height="16px"
          width="16px"
        />
      </div>
    </LoadWrapper>
  );
};

function maxFileSizeToast(intl: IntlShape, gotError: ErrorInterface) {
  // extract max file size
  //TODO: dirty!
  let maxFileSizeMb = 0;
  const matches = gotError.message.match(/of (.*) bytes/);
  if (matches) maxFileSizeMb = getFileSizeMb(matches);

  toastrError(
    intl.formatMessage(
      { id: 'max_file_size_exceeded_explain' },
      { size: maxFileSizeMb }
    )
  );
}

const AddImageItem = ({
  item,
  onDelete,
  onSelect,
  selected,
  isLoading,
  gotError,
  errorClick,
  canDelete,
}: Props) => {
  const [showError, setShowError] = useState<boolean>(false);
  const intl = useIntl();
  const errorMessage = getErrorMessage(gotError);

  useEffect(() => {
    if (gotError && errorMessage === 'max_file_size_exceeded') {
      maxFileSizeToast(intl, gotError);
    }
  }, [errorMessage]);

  function onClick() {
    !isLoading && !showError
      ? onSelect(item)
      : toastrWarning(intl.formatMessage({ id: 'media_not_selectable' }));
  }

  return item ? (
    <ImageItemContainer onClick={() => onClick()}>
      {isLoading ? (
        <LoadingSpinner />
      ) : gotError || showError ? (
        <AddImageItemError
          errorMessage={errorMessage}
          errorClick={errorClick}
        />
      ) : (
        <>
          {canDelete && <ImageDeleteButton onDelete={onDelete} item={item} />}
          <ItemThumbnail
            selected={selected}
            value={item.urls}
            showErrorHint
            onError={() => setShowError(true)}
          />
        </>
      )}
    </ImageItemContainer>
  ) : (
    <Loader />
  );
};

export default AddImageItem;
