import {
  Button,
  RightAlignedButtons,
} from '@dev/base-web/dist/view/components/global/button';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import InputWithCount from '@dev/base-web/dist/view/components/inputs/input_with_count';
import TemporaryTranslation from './temporary_translation';
import { InputOnChangeData } from 'semantic-ui-react/dist/commonjs/elements/Input/Input';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import {
  InstructionDecisionOptionTranslation,
  InstructionStepTranslation,
} from '@/model/domain/instruction/translation.ts';
import {
  ActionStepContainer,
  DecisionBtn,
  DecisionBtnEditRowContainer,
  DecisionFooterContainer,
  DecisionHeaderContainer,
  FixedWidthDecisionBtn,
} from '@/components/instruction/components.tsx';
import { areStandardDecisionOptions } from '@/model/domain/instruction/helper.ts';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 60px;
`;

const StyledWideInput = styled(InputWithCount)`
  & {
    width: 100%;
  }
`;

const TemporaryTranslationContainer = styled.div`
  align-self: flex-start;
`;

interface DecisionButtonsProps {
  readonly options: readonly InstructionDecisionOptionTranslation[];
  readonly selectedDecision: number | null;
  readonly onSelect: (index: number) => void;
}

const StandardDecisions = ({
  selectedDecision,
  options,
  onSelect,
}: Readonly<DecisionButtonsProps>) => {
  const [yesDecision, noDecision] = options;

  return (
    <>
      <FixedWidthDecisionBtn
        type="green"
        addStep={false}
        text={yesDecision.name}
        inactive={false}
        selected={selectedDecision === 0}
        onClick={() => onSelect(0)}
      />
      <FixedWidthDecisionBtn
        type="red"
        addStep={false}
        text={noDecision.name}
        inactive={false}
        selected={selectedDecision === 1}
        onClick={() => onSelect(1)}
      />
    </>
  );
};

const CustomDecisions = ({
  options,
  selectedDecision,
  onSelect,
}: Readonly<DecisionButtonsProps>) => {
  return (
    <>
      {options.map((decision, index) => (
        <DecisionBtn
          type="custom"
          key={decision.id}
          addStep={false}
          text={decision.name}
          inactive={false}
          selected={selectedDecision === index}
          onClick={() => onSelect(index)}
        />
      ))}
    </>
  );
};

export interface Props {
  readonly item: InstructionStepTranslation;
  readonly selectedDecision?: number;
  readonly inactive?: boolean;
  //readonly onDescriptionBlur: () => void;
  //TODO: remove any
  readonly onDescriptionChange: (e: any) => void;
  readonly onSelect: (s: InstructionStepTranslation, d: number) => void;
  readonly selectedLanguage: string;
}

const InstructionDecisionTranslationCard: React.FC<Props> = ({
  item,
  selectedDecision: selectedDecisionProp,
  //onDescriptionBlur,
  onDescriptionChange,
  onSelect,
  selectedLanguage,
}) => {
  const intl = useIntl();
  const [description, setDescription] = useState('');

  useEffect(() => {
    setDescription(
      item.translationUpdate?.translations?.[selectedLanguage]?.translation ??
        ''
    );
  }, [item]);

  const tr = item.translationUpdate?.translations?.[selectedLanguage];

  const [selectedDecision, setSelectedDecision] = useState<number | null>(
    selectedDecisionProp ?? null
  );

  const hasStandardDecisions = areStandardDecisionOptions(
    item.decisionOptionTranslations
  );

  const onSelectLocal = (item: InstructionStepTranslation, index: number) => {
    setSelectedDecision(index);
    onSelect(item, index);
  };

  const onChangeLocal = (e: unknown, data: InputOnChangeData) => {
    onDescriptionChange(e);
    setDescription(data.value);
  };

  const viewingLastEditedLanguage =
    item.translationUpdate?.sourceLanguage === selectedLanguage;

  // const didTranslationChange =
  //   item?.descriptionTranslation?.previousTargetLanguageText !==
  //   item?.descriptionTranslation?.targetLanguageText;

  // If previousTargetLanguageText === sourceLanguageText then it means it's a
  // a newly added step.
  //TODO: is this still true?
  const isNewlyAdded =
    tr?.prevTranslation === item?.translationUpdate?.sourceLanguageText;

  return (
    <ActionStepContainer>
      <DecisionHeaderContainer>
        <IconWrapper>
          <Icon color="#242424" name="decision" size={28} />
        </IconWrapper>
        <RightAlignedButtons>
          <Button disabled type="secondary" icon="cross" />
        </RightAlignedButtons>
      </DecisionHeaderContainer>
      <TemporaryTranslationContainer>
        {!viewingLastEditedLanguage && (
          <TemporaryTranslation
            fromLanguageCode={item.translationUpdate?.sourceLanguage ?? ''}
            originalText={item.translationUpdate?.sourceLanguageText ?? ''}
            translatedText={!isNewlyAdded ? tr?.prevTranslation ?? '' : '-'}
          />
        )}
      </TemporaryTranslationContainer>
      <StyledWideInput
        //onBlur={onDescriptionBlur}
        name="description"
        className={!description ? 'inputError' : ''}
        onChange={onChangeLocal}
        value={description}
        disabled={viewingLastEditedLanguage}
        placeholder={
          !description
            ? intl.formatMessage({ id: 'add_solution_decision_hint' })
            : ''
        }
        showCount
      />
      <DecisionFooterContainer>
        <DecisionBtnEditRowContainer>
          {hasStandardDecisions ? (
            <StandardDecisions
              selectedDecision={selectedDecision}
              options={item.decisionOptionTranslations}
              onSelect={(index) => onSelectLocal(item, index)}
            />
          ) : (
            <CustomDecisions
              selectedDecision={selectedDecision}
              options={item.decisionOptionTranslations}
              onSelect={(index) => onSelectLocal(item, index)}
            />
          )}
        </DecisionBtnEditRowContainer>
      </DecisionFooterContainer>
    </ActionStepContainer>
  );
};

export default InstructionDecisionTranslationCard;
