import { Reducer, AnyAction } from 'redux';
import * as Constants from '../../Constants';
import uniqBy from 'lodash.uniqby';
import { ActionsStateInterface } from './actions/interface';

/**
 * TODO: old code converted to typescript (with as minimal changes as possible)
 * The whole file should be removed in the future and the reducer which uses it
 * should be rewritten to use our common redux helpers
 */

const initialState: ActionsStateInterface & { pageable: { pageNumber: -1 } } = {
  loading: false,
  isLast: false,
  items: [],
  error: [],
  pageable: {
    pageNumber: -1,
  },
  pagination: {},
  activeItem: {
    isLoading: true,
    item: {},
    error: {},
  },
  locations: {
    location: [],
    isLoading: false,
    error: [],
  },
  images: [],
};

const listReducer = ((state = initialState, action: AnyAction) => {
  const { payload } = action;
  switch (action.type) {
    case Constants.GETITEMS_BEGIN:
      return { ...state, loading: true };
    case Constants.GETITEMS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatepageDataIfPageIncreased =
        // this is because of infinite scrolling
        payload.pageable.pageNumber > state.pageable.pageNumber
          ? { pageable: payload.pageable, isLast: payload.isLast }
          : {};

      if (payload.replace) {
        return {
          ...state,
          loading: false,
          items: payload.responseData,
          ...updatepageDataIfPageIncreased,
        };
      } else {
        return {
          ...state,
          loading: false,
          items: uniqBy(
            state.items.concat(payload.responseData),
            (item) => item.id
          ),
          ...updatepageDataIfPageIncreased,
        };
      }

    case Constants.GETITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: { message: action.error },
        items: [],
      };

    case Constants.GETITEM_BEGIN:
      return { ...state, activeItem: { ...state.activeItem, isLoading: true } };

    case Constants.GETITEM_SUCCESS:
      return {
        ...state,
        activeItem: { isLoading: false, item: payload.responseData },
      };

    case Constants.GETITEM_FAILURE:
      return {
        ...state,
        activeItem: { isLoading: false, error: { message: action.error } },
      };

    case Constants.GETLOCATIONS_BEGIN:
      return { ...state, locations: { isLoading: true } };

    case Constants.GETLOCATIONS_SUCCESS:
      return {
        ...state,
        locations: { isLoading: false, location: payload.responseData },
      };

    case Constants.GETLOCATIONS_FAILURE:
      return {
        ...state,
        locations: { isLoading: false, error: { message: action.error } },
      };

    case Constants.CLEAR_ITEM:
      return { ...state, activeItem: {} };

    default:
      return state;
  }
}) as Reducer<typeof initialState>;

export default listReducer;
