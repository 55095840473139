import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { FactoryAreaChildResult } from '../../domain/kpis/factory_area_child_result';
import { DataApiSortConfig } from '@dev/base-web/dist/model/api/common/data_api_sort_config';

export const AREAS = 'areas';

const AREAS_LINES_URL = `/${AREAS}/childs/results/search`;

export default class MainEntityKpiApi extends BaseDataApi<FactoryAreaChildResult> {
  async getAllMainEntityKpis(
    filters: FilterData[],
    groupBy: string[],
    token: string,
    signal: AbortSignal,
    sortConfig?: DataApiSortConfig
  ): Promise<FactoryAreaChildResult[]> {
    return this.fetchAllDataWithSearchAndSort(
      AREAS_LINES_URL,
      token,
      signal,
      filters,
      sortConfig,
      groupBy,
      100
    );
  }
}
