import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import shiftReportThunks from '../../../model/redux/report/overview/thunks';
import styled from 'styled-components';
import { ReportOverviewRow } from './components/report_overview_row';
import { RootReducerInterface } from '@/model/redux/interfaces.ts';
import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import {
  Column,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import ShannonConfig from '@dev/base-web/dist/model/domain/authorization/shannon';
import ManufacturingEntity, {
  ManufacturingEntityType,
} from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import ManufacturingEntityThunks from '../../../model/redux/manufacturing_entity/thunks';
import SearchData from '@dev/base-web/dist/model/api/common/search_data';
import { useNavigate } from 'react-router';
import { ReportOverviewSortingKey } from '@dev/base-web/dist/model/redux/report/overview/interface';
import ShiftReportOverview from '@dev/base-web/dist/model/domain/report/ShiftReportOverview';
import { LoadingMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import Header from '@dev/base-web/dist/view/components/global/header_view/index';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';

export const CONFIG = ShannonConfig.Report;

export const createColumns = (
  manufacturingEntities: readonly ManufacturingEntity[],
  getAllManufacturingEntities: (query?: string) => void,
  manufacturingEntityLoading: boolean
): Array<Column> => [
  {
    title: 'date',
    isLeftAligned: true,
    sortKey: ReportOverviewSortingKey.START_SHIFT,
    filterType: ColumnFilterType.DATE,
    width: 3,
  },
  {
    title: 'shift',
    isLeftAligned: true,
    sortKey: ReportOverviewSortingKey.SHIFT,
    filterType: ColumnFilterType.TEXT,
    width: 3,
  },
  {
    title: 'manufacturing_entity',
    isLeftAligned: true,
    sortKey: ReportOverviewSortingKey.MANUFACTURING_ENTITY,
    filterType: ColumnFilterType.MANUFACTURING_ENTITY,
    filterOptions: manufacturingEntities,
    width: 2,
    getAllManufacturingEntities,
    isLoading: manufacturingEntityLoading,
  } as any, //TableHeaderManufacturingEntityFilterColumn
  {
    title: 'produced_products',
    width: 2,
  },
  {
    title: 'production_rate',
    width: 2,
  },
  {
    title: 'downtime',
    sortKey: ReportOverviewSortingKey.DOWNTIME,
    filterType: ColumnFilterType.DURATION,
    width: 2,
  },
  {
    title: 'documented_downtimes',
    sortKey: ReportOverviewSortingKey.DOCUMENTED_DOWNTIMES,
    filterType: ColumnFilterType.NUMBER,
    width: 2,
  },
];

const FUTURE_FILTER = new FilterData(
  ReportOverviewSortingKey.START_SHIFT,
  FilterOperation.LT_OPERATOR,
  Date.now()
);

export const TableContainer = styled.div`
  border-radius: 16px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  width: 100%;
  margin-top: 16px;
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
`;

interface ReportOverviewTableStoreProsInterface {
  readonly results: readonly ShiftReportOverview[];
  readonly manufacturingEntities: readonly ManufacturingEntity[];
  readonly manufacturingEntitiesMeta: LoadingMetaState;
  readonly hasMoreResults: boolean;
  readonly loadingInProgress: boolean;
  readonly previousSearchData?: SearchData;
}

interface ReportOverviewTableDispatchPropsInterface {
  readonly getReportOverviewData: (
    page: number,
    filters: readonly FilterData[],
    sortingKey?: string,
    sorting?: SortingDirection,
    loadAllPagesUntilTheGivenOne?: boolean
  ) => void;
  readonly getAllManufacturingEntities: (
    query?: string,
    includedTypes?: ManufacturingEntityType[]
  ) => void;
}

type ReportOverviewTableProps = ReportOverviewTableStoreProsInterface &
  ReportOverviewTableDispatchPropsInterface;

const ShiftReportOverViewTable = ({
  getReportOverviewData,
  getAllManufacturingEntities,
  manufacturingEntities,
  manufacturingEntitiesMeta,
  results,
  hasMoreResults,
  loadingInProgress,
  previousSearchData,
}: ReportOverviewTableProps) => {
  const navigate = useNavigate();
  const [initialSearchData] = useState<SearchData | undefined>(
    previousSearchData
  );

  const getManufacturingEntitiesFiltered = (query?: string) => {
    getAllManufacturingEntities(query, [
      ManufacturingEntityType.AREA,
      ManufacturingEntityType.FACTORY,
      ManufacturingEntityType.LINE,
      ManufacturingEntityType.MACHINE,
    ]);
  };

  useEffect(() => {
    getManufacturingEntitiesFiltered();
  }, []);

  const getNextResults = (
    page: number,
    filters: readonly FilterData[],
    sortKey?: string,
    sortDirection?: SortingDirection,
    loadAllPagesUntilTheGivenOne?: boolean
  ) => {
    getReportOverviewData(
      page,
      [...filters, FUTURE_FILTER],
      sortKey,
      sortDirection,
      loadAllPagesUntilTheGivenOne
    );
  };

  return (
    <div>
      <Header
        title={CONFIG.label ?? ''}
        backButtonTitle={ShannonConfig.Dashboard.label}
        onBackPressed={() => navigate('/', { replace: true })}
      />
      <TableContainer>
        <SortableTable
          dataLength={results.length}
          hasMoreResults={hasMoreResults}
          columns={createColumns(
            manufacturingEntities,
            getManufacturingEntitiesFiltered,
            manufacturingEntitiesMeta.loadingInProgress
          )}
          getNextResults={getNextResults}
          loadingInProgress={loadingInProgress}
          initialSearchData={initialSearchData}
        >
          {results.map((result) => {
            return <ReportOverviewRow key={result.id} result={result} />;
          })}
        </SortableTable>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (
  state: RootReducerInterface
): ReportOverviewTableStoreProsInterface => {
  return {
    results: state.data.reportOverview.reportOverview.data.results,
    hasMoreResults:
      state.data.reportOverview.reportOverview.data.hasMoreResults,
    loadingInProgress:
      state.data.reportOverview.reportOverview.meta.loadingInProgress,
    manufacturingEntities:
      state.data.manufacturingEntityState.manufacturingEntities.data,
    manufacturingEntitiesMeta:
      state.data.manufacturingEntityState.manufacturingEntities.meta,
    previousSearchData:
      state.data.reportOverview.reportOverview.meta.previousSearchData,
  };
};

export default connect(mapStateToProps, {
  getReportOverviewData: shiftReportThunks.getReportOverviewData,
  getAllManufacturingEntities:
    ManufacturingEntityThunks.getAllManufacturingEntities,
})(ShiftReportOverViewTable);
