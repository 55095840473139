import React from 'react';
import {
  Button,
  RightAlignedButtons,
} from '@dev/base-web/dist/view/components/global/button';
import styled from 'styled-components';
import DeleteConfirmationModal from '@dev/base-web/dist/view/components/modal/delete_confirmation_modal';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';

const ButtonContainer = styled(RightAlignedButtons)`
  margin-right: 16px;
`;

interface InstructionActionButtonsProps {
  readonly onDeletePressed: () => void;
  readonly onArchivePressed: () => void;
  readonly onNewPressed: () => void;
  readonly deleteEnabled: boolean;
  readonly archiveEnabled: boolean;
  readonly newEnabled: boolean;
  readonly archiveShown: boolean;
  readonly deleteCount: number;
  readonly isEditAllowed: boolean;
}

interface ElementWithPopupProps {
  children: any;
  showPopup?: boolean;
  popupText?: string;
}

function ElementWithPopup(props: ElementWithPopupProps) {
  if (props.popupText && props.showPopup) {
    return (
      <Popup
        content={<FormattedMessage id={props.popupText} />}
        position="bottom center"
        size="small"
        mouseEnterDelay={200}
        mouseLeaveDelay={100}
        on="click"
        trigger={<div>{props.children}</div>}
      />
    );
  } else {
    return props.children;
  }
}

const InstructionActionButtons: React.FC<InstructionActionButtonsProps> = ({
  onDeletePressed,
  onArchivePressed,
  onNewPressed,
  deleteEnabled,
  archiveEnabled,
  newEnabled,
  archiveShown,
  deleteCount,
  isEditAllowed,
}) => {
  return (
    <ButtonContainer>
      <ElementWithPopup
        showPopup={!archiveEnabled && !isEditAllowed}
        popupText="not_allowed_asked_supervisor_hint"
      >
        <Button
          type="secondary"
          icon={archiveShown ? 'revert' : 'archive'}
          label={archiveShown ? 'restore' : 'archive'}
          disabled={!archiveEnabled}
          onClick={onArchivePressed}
        />
      </ElementWithPopup>
      <ElementWithPopup
        showPopup={!isEditAllowed}
        popupText="not_allowed_asked_supervisor_hint"
      >
        <DeleteConfirmationModal
          onDeleteConfirmed={onDeletePressed}
          title="verify"
          confirmationTitle="toast_delete_verify"
          confirmationTitleValues={{ count: deleteCount.toString() }}
          triggerButton={
            <Button
              type="secondary"
              icon="delete"
              label="delete"
              disabled={!deleteEnabled}
            />
          }
          triggerDisabled={!deleteEnabled}
        />
      </ElementWithPopup>
      <Button
        type="secondary"
        icon="plus"
        label="new"
        disabled={!newEnabled}
        onClick={onNewPressed}
      />
    </ButtonContainer>
  );
};

export default InstructionActionButtons;
