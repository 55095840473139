import React from 'react';
import { StyledInput } from '@dev/base-web/dist/view/components/inputs/styled_components';
import useFilterState from './filter_hook';
import { FilterProps } from './interfaces';
import styled from 'styled-components';
import { FilterOperation } from '@dev/base-web/dist/model/domain/common/filter_data';

const TextInput = styled(StyledInput)`
  width: 100%;
`;

const FileNameFilter: React.FC<FilterProps> = (props) => {
  const { localFilters, setLocalFilters, externalFilters } = props;

  const [fileName, setFileName] = useFilterState<string>(
    'initialFilename',
    { groupingKey: 'initialFilename', icon: 'list' },
    (value) => value,
    localFilters,
    setLocalFilters,
    externalFilters,
    FilterOperation.CT_OPERATOR
  );

  return (
    <TextInput
      value={fileName}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setFileName(e.target.value)
      }
    />
  );
};

export default FileNameFilter;
