import React from 'react';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import styled from 'styled-components';

const SideCard = styled(Card)`
  width: 322px;
  padding-bottom: 10px !important;
`;

const AddEventContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`;

interface InstructionSideCardProps {
  readonly title: string;
  readonly bottomButton?: React.ReactNode;
  readonly disableContainerMargin?: boolean;
  readonly children?: React.ReactNode;
  readonly headerContent?: React.ReactNode;
  readonly noRightContentMargin?: boolean;
}

const InstructionSideCard: React.FC<InstructionSideCardProps> = (props) => {
  const {
    children,
    title,
    bottomButton,
    disableContainerMargin,
    headerContent,
    noRightContentMargin,
  } = props;

  return (
    <SideCard
      titleId={title}
      headerContent={headerContent}
      smallTitle
      headerProps={{ height: 48 }}
      noRightContentMargin={noRightContentMargin}
    >
      <div
        style={{
          marginTop: disableContainerMargin ? 0 : 16,
          marginBottom: disableContainerMargin ? 0 : 22,
        }}
      >
        {children}
      </div>
      {bottomButton && <AddEventContainer>{bottomButton}</AddEventContainer>}
    </SideCard>
  );
};

export default InstructionSideCard;
