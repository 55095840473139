import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import { set as setDateComponents } from 'date-fns';
import {
  IterationType,
  ScheduledCondition,
} from '../../../../model/domain/event/event_definition';
import { SingleDatePicker } from '@dev/base-web/dist/view/components/inputs/single_date_picker';
import NumberInput from '@dev/base-web/dist/view/components/inputs/number_input';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { TimePicker } from '@dev/base-web/dist/view/components/inputs/time_picker';

const ScheduleDateTimePickerContainer = styled.div<{
  wizard?: boolean;
  ruleCard?: boolean;
}>`
  display: flex;
  flex-direction: ${({ wizard }) => (wizard ? 'row' : 'column')};
  align-items: ${({ ruleCard }) => (ruleCard ? 'flex-start' : 'flex-end')};
  justify-content: ${({ wizard }) => (wizard ? 'flex-start' : 'flex-end')};

  .SingleDatePicker {
    width: 212px;
    ${({ wizard }) => wizard && 'margin-right:8px'}
  }

  .SingleDatePicker .SingleDatePickerInput {
    width: 212px;
  }

  .SingleDatePicker .SingleDatePickerInput .DateInput {
    width: 212px;
  }
`;

const Label = styled.div`
  font-family: Relative;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #8c8c8c;
  padding-right: 12px;
`;

const RepeatConfigContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;

  ${Label} {
    min-width: max-content;
  }
`;

const UnitDropDown = styled(Dropdown)`
  &&&& {
    width: 120px;
    margin-left: 12px;

    .default {
      font-family: Relative;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.43;
      letter-spacing: normal;
      color: #a6a6a6;
    }
  }

  &[aria-disabled='true'] {
    border-radius: 4px;
    background-color: #f0f0f0 !important;
    opacity: 1 !important;
  }
`;

const StyledTimePicker = styled(TimePicker)`
  margin-top: 12px;

  .rc-time-picker-input,
  .rc-time-picker-panel-input {
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    width: 212px;
    height: 42px;
    color: #242424;
    font-family: Relative;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;

    ::placeholder {
      font-family: Relative;
      font-size: 14px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #a6a6a6;
    }
  }

  .rc-time-picker-panel-select {
    color: #242424;
    font-family: Relative;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
  }
  .rc-time-picker-panel-select {
    width: 112px;
  }

  .rc-time-picker-panel-select li {
    text-align: center;
    padding: 0px;
  }
`;

interface ScheduledConditionsConfigProps {
  readonly condition: ScheduledCondition;
  readonly onConditionUpdate?: (
    condition: ScheduledCondition,
    valid: boolean
  ) => void;
  readonly disabled: boolean;
  readonly wizard?: boolean; /// to use in eventWizard
  readonly ruleCard?: boolean; // show in event_definition
}

const Scheduled_condition_config: React.FC<ScheduledConditionsConfigProps> = ({
  condition,
  onConditionUpdate,
  disabled,
  wizard,
  ruleCard,
}) => {
  function getInitialCondition(
    condition: ScheduledCondition
  ): ScheduledCondition {
    if (!condition.iteration)
      return {
        ...condition,
        iteration: 1,
        iterationType: IterationType.DAILY,
        timestamp: Date.now(),
      };
    else return condition;
  }

  const [tempCondition, setTempCondition] = useState<ScheduledCondition>(
    getInitialCondition(condition)
  );

  useEffect(() => {
    const conditionValid = validateCondition(tempCondition);
    onConditionUpdate && onConditionUpdate(tempCondition, conditionValid);
  }, [tempCondition]);

  const intl = useIntl();

  const scheduleUnitOptions = [
    { value: IterationType.HOURLY, text: intl.formatMessage({ id: 'hours' }) },
    { value: IterationType.DAILY, text: intl.formatMessage({ id: 'days' }) },
    { value: IterationType.WEEKLY, text: intl.formatMessage({ id: 'weeks' }) },
    // {
    //   value: IterationType.MONTHLY,
    //   text: intl.formatMessage({ id: 'months' }),
    // },
    // { value: IterationType.ANNUAL, text: intl.formatMessage({ id: 'years' }) },
  ];

  const date =
    tempCondition && tempCondition.timestamp
      ? new Date(tempCondition.timestamp)
      : new Date(0);

  const time =
    tempCondition && tempCondition.timestamp
      ? new Date(tempCondition.timestamp)
      : new Date(0);

  const updateCondition = (updateObject: any) => {
    const newCondition = { ...tempCondition, ...updateObject };
    setTempCondition(newCondition);
  };

  const validateCondition = (condition: ScheduledCondition) => {
    const iterationTypeValid = condition.iterationType;
    const iterationValid = isIterationAmountValid(condition.iteration);
    const conditionValid = iterationTypeValid && iterationValid;
    return conditionValid ? true : false;
  };

  const updateDate = (date: Date) => {
    // construct date from update date + currently selected time
    //const newDate = date.startOf('day') //+ moment.unix(tempCondition.timestamp).format("HH:mm");

    const oldDate = new Date(tempCondition.timestamp);
    const newDate = setDateComponents(date, {
      hours: oldDate.getHours(),
      minutes: oldDate.getMinutes(),
      seconds: 0,
      milliseconds: 0,
    });
    updateCondition({ timestamp: newDate.valueOf() });
  };

  const updateTime = (time: Date) => {
    //const newDate = moment.unix(tempCondition.timestamp).startOf('day') + time.format("HH:mm");
    const newDate = setDateComponents(tempCondition.timestamp, {
      hours: time.getHours(),
      minutes: time.getMinutes(),
      seconds: 0,
      milliseconds: 0,
    });

    updateCondition({ timestamp: newDate.valueOf() });
  };

  const isIterationAmountValid = (iterationValue: number) =>
    iterationValue &&
    iterationValue > 0 &&
    !Number.isNaN(Number(iterationValue));

  return (
    <div>
      <ScheduleDateTimePickerContainer wizard={wizard} ruleCard={ruleCard}>
        <SingleDatePicker
          value={date}
          onDateChanged={(date) => date && updateDate(date)}
          placeholder={intl.formatMessage({ id: 'condition_time_date' })}
          disabled={disabled}
        />
        <StyledTimePicker
          value={time}
          onChange={(time) => updateTime(time)}
          placeholder={intl.formatMessage({ id: 'condition_time_time' })}
          disabled={disabled}
        />
      </ScheduleDateTimePickerContainer>
      <RepeatConfigContainer>
        <Label>
          <FormattedMessage id="schedule_repeat" />
        </Label>
        <NumberInput
          key={'condition_timer_iteration'}
          value={tempCondition.iteration}
          onChange={(e) => {
            const numberValue = parseFloat(e.target.value);
            const numValueCorrectNumber = !isNaN(numberValue);
            if (numValueCorrectNumber) {
              updateCondition({ iteration: numberValue });
            }
            if (e.target.value === '') {
              updateCondition({ iteration: null });
            }
          }}
          error={!isIterationAmountValid(tempCondition.iteration)}
          disabled={disabled}
        />
        <UnitDropDown
          fluid
          selection
          value={tempCondition.iterationType}
          options={scheduleUnitOptions}
          onChange={(
            _event: React.SyntheticEvent<HTMLElement>,
            data: DropdownProps
          ) => {
            updateCondition({ iterationType: data.value });
          }}
          placeholder={intl.formatMessage({ id: 'condition_time_repeat_unit' })}
          disabled={disabled}
        />
      </RepeatConfigContainer>
    </div>
  );
};

export default Scheduled_condition_config;
