import React, { Component, CSSProperties } from 'react';
import styled from 'styled-components';
import sizeMe, { SizeMeProps } from 'react-sizeme';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryPortal,
  VictoryVoronoiContainer,
} from 'victory';
import TextWrappingTickLabel from '../../../../../components/chart/TextWrappingTickLabel';
import theme from '@dev/base-web/dist/view/themes/main';
import AggregatedDowntime from '../../../../../model/domain/downtime/AggregatedDowntime';
import _ from 'lodash';
import { AggregatedDowntimesChartLabel } from './aggregated_downtimes_chart_label';
import { LoadingMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import { formatTickForDuration } from './helper';
import EmptyOrLoadingChartView from '@dev/base-web/dist/view/components/global/no_data';
import { IntervalType } from '@dev/base-web/dist/model/domain/interval_types';
import { IntervalGroup } from '../../../../../model/domain/report/manufacturing_entity_summary_interval';

const CHART_X_AXIS_TICK_LABEL_WIDTH = 100;
const CHART_X_AXIS_TICK_LABEL_HEIGHT = 70;

const CHART_TICK_ROUNDING_MINUTES = 5;

export const CHART_LEGEND_ITEMS = [
  {
    labelId: 'aggregated_duration',
    color: theme.colors.diagrams.paletteNeutral[0],
  },
  { labelId: 'count', color: theme.colors.diagrams.paletteNeutral[2] },
];

const ChartContainer = styled.div`
  width: 100%;
`;

export const StyledEmptyOrLoadingChartView = styled(EmptyOrLoadingChartView)`
  height: 400px;
`;

interface ChartData {
  x: number;
  y: number;
  label?: string;
  downtime: AggregatedDowntime;
}

export interface AggregatedEventResultsChartExternalProps {
  aggregatedDowntimes: AggregatedDowntime[];
  height: number;
  showEventCount?: boolean;
  meta: LoadingMetaState;
  showManufacturingEntityHierarchy: boolean;
  interval?: IntervalGroup;
  considerPlannedStops: boolean;
}

type AggregatedEventResultsChartProps =
  AggregatedEventResultsChartExternalProps &
    SizeMeProps &
    WrappedComponentProps;

interface AggregatedEventResultsChartState {
  chartWidth: number;
}

class AggregatedEventResultsChart extends Component<
  AggregatedEventResultsChartProps,
  AggregatedEventResultsChartState
> {
  formatHorizontalTicks = (
    tickValue: string,
    index: number,
    numberOfTicks: number,
    width: number,
    fullWidth: number,
    maxLength: number
  ) => {
    const maxTickCount = fullWidth / (width * 1.1);
    if (maxTickCount < numberOfTicks) {
      const skipRate = Math.ceil(numberOfTicks / maxTickCount);
      return index % skipRate === 0
        ? this.formatTextWrap(tickValue, maxLength)
        : '';
    } else {
      return this.formatTextWrap(tickValue, maxLength);
    }
  };

  formatTextWrap = (text: string, maxlength: number) => {
    return text.length > maxlength ? text.slice(0, maxlength) + '...' : text;
  };

  private removeRedurantDayNumber = (formattedDuration: string) => {
    const str = formattedDuration.substring(0, 2);
    const num = Number(str);
    if (!isNaN(num) && num > 0) {
      return num < 11
        ? `0${num - 1}` + formattedDuration.substring(2)
        : `${num - 1}` + formattedDuration.substring(2);
    }
    return formattedDuration;
  };

  private calculateTicks = (maxDuration: number, roundingMinutes: number) => {
    let currentTick = 0;
    const ticks = [currentTick];
    const tickIntervalMinutes = Math.max(
      roundingMinutes,
      Math.floor(maxDuration / 60 / 3 / roundingMinutes) * roundingMinutes
    );

    while (currentTick < maxDuration) {
      currentTick = this.addNewTick(
        currentTick,
        ticks,
        maxDuration,
        tickIntervalMinutes
      );
    }

    if (currentTick - maxDuration < (tickIntervalMinutes / 4) * 60) {
      currentTick = this.addNewTick(
        currentTick,
        ticks,
        maxDuration,
        tickIntervalMinutes
      );
    }

    while (ticks.length < 4) {
      currentTick = this.addNewTick(
        currentTick,
        ticks,
        maxDuration,
        tickIntervalMinutes
      );
    }

    return ticks;
  };

  private addNewTick = (
    currentTick: number,
    ticks: number[],
    maxDuration: number,
    tickIntervalMinutes: number
  ) => {
    currentTick += tickIntervalMinutes * 60;
    ticks.push(currentTick / maxDuration);
    return currentTick;
  };

  render() {
    const {
      showEventCount,
      height,
      size,
      meta,
      intl,
      aggregatedDowntimes,
      showManufacturingEntityHierarchy,
      interval,
      considerPlannedStops,
    } = this.props;

    const durationChartData: ChartData[] = [];
    const countChartData: ChartData[] = [];

    const maxDuration = Math.max(
      ...aggregatedDowntimes.map((result) => result.sumMs / 1000)
    );
    const maxCount = Math.max(
      ...aggregatedDowntimes.map((result) => result.count)
    );

    const roundingMinutes =
      CHART_TICK_ROUNDING_MINUTES * 0.5 > maxDuration / 60
        ? 1
        : CHART_TICK_ROUNDING_MINUTES;

    const ticks = maxDuration
      ? this.calculateTicks(maxDuration, roundingMinutes)
      : [];

    const smallestMaxCountWithIntegerTickValues =
      Math.ceil(maxCount / (ticks.length - 1)) * (ticks.length - 1);
    const tickValueOffsetFromNormalized = ticks[ticks.length - 1];

    aggregatedDowntimes.forEach((result, index) => {
      durationChartData.push({
        x: index + 1,
        y: result.sumMs / 1000 / maxDuration,
        label: `${result.event.name}`,
        downtime: result,
      });
      countChartData.push({
        x: index + 1,
        y:
          (result.count / smallestMaxCountWithIntegerTickValues) *
          tickValueOffsetFromNormalized,
        label: `${result.event.name}`,
        downtime: result,
      });
    });

    const yAxisTickLabelStyle: CSSProperties = {
      fontFamily: 'Helvetica',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      fill: '#767676',
    };

    const width = size.width || 0;
    const barWidth = Math.min(
      24,
      (width - 200 - aggregatedDowntimes.length * 5) /
        (aggregatedDowntimes.length * 2)
    );

    const bottomPadding = 70;

    const timespan = interval
      ? { start: interval.start, end: interval.end }
      : undefined;

    return aggregatedDowntimes.length ? (
      <ChartContainer>
        <VictoryChart
          padding={{
            top: 50,
            bottom: bottomPadding,
          }}
          domain={{ x: [-0.25, aggregatedDowntimes.length + 1] }}
          width={width}
          height={height}
          containerComponent={<VictoryVoronoiContainer voronoiDimension="x" />}
        >
          <VictoryAxis
            style={{
              axis: { stroke: 'rgba(0, 0, 0, 0.2)' },
            }}
            tickValues={_.range(1, aggregatedDowntimes.length + 1)}
            tickFormat={(
              tickValue: number,
              index: number,
              allTicks: number[]
            ) =>
              this.formatHorizontalTicks(
                aggregatedDowntimes[tickValue - 1].event.name,
                index,
                allTicks.length,
                CHART_X_AXIS_TICK_LABEL_WIDTH,
                width,
                45
              )
            }
            tickLabelComponent={
              <TextWrappingTickLabel
                width={CHART_X_AXIS_TICK_LABEL_WIDTH}
                height={CHART_X_AXIS_TICK_LABEL_HEIGHT}
              />
            }
          />
          <VictoryAxis
            dependentAxis
            tickValues={ticks}
            tickFormat={(t: number) => formatTickForDuration(t, maxDuration)}
            tickLabelComponent={
              <VictoryLabel dy={10} dx={12} textAnchor={'start'} />
            }
            offsetX={-0.1}
            style={{
              axis: { stroke: 'transparent' },
              axisLabel: { fontSize: 20, padding: 30 },
              grid: { stroke: 'rgba(0, 0, 0, 0.1)' },
              tickLabels: yAxisTickLabelStyle,
            }}
            label={intl.formatMessage({ id: 'sum' })}
            axisLabelComponent={
              <VictoryLabel
                dy={height / -2 + 50}
                textAnchor="start"
                dx={32}
                angle={0}
                style={{
                  fontFamily: 'Relative',
                  fontSize: 14,
                  fontWeight: 'bold',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 1.67,
                  letterSpacing: 'normal',
                }}
              />
            }
          />
          {showEventCount && (
            <VictoryAxis
              dependentAxis
              offsetX={size.width || 0}
              tickValues={ticks}
              tickFormat={(tick: number, index: number, allTicks: number[]) => {
                const calculatedTick = Math.round(
                  (tick / tickValueOffsetFromNormalized) *
                    smallestMaxCountWithIntegerTickValues
                );

                if (index === 0) return calculatedTick;

                const previousTick = Math.round(
                  (allTicks[index - 1] / tickValueOffsetFromNormalized) *
                    smallestMaxCountWithIntegerTickValues
                );

                if (previousTick !== calculatedTick) return calculatedTick;

                return '';
              }}
              tickLabelComponent={<VictoryLabel dy={10} dx={10} />}
              style={{
                axis: { stroke: 'transparent' },
                tickLabels: yAxisTickLabelStyle,
              }}
              label={intl.formatMessage({ id: 'count' })}
              axisLabelComponent={
                <VictoryPortal>
                  <VictoryLabel
                    dy={height / -2 + 50}
                    textAnchor="end"
                    dx={20}
                    angle={0}
                    style={{
                      fontFamily: 'Relative',
                      fontSize: 14,
                      fontWeight: 'bold',
                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: 1.67,
                      letterSpacing: 'normal',
                    }}
                  />
                </VictoryPortal>
              }
            />
          )}
          <VictoryGroup offset={barWidth + 2}>
            <VictoryBar
              barWidth={barWidth}
              style={{
                data: { fill: theme.colors.diagrams.paletteNeutral[0] },
              }}
              data={durationChartData}
              labelComponent={
                <AggregatedDowntimesChartLabel
                  valueKey={'sum'}
                  height={height}
                  barWidth={barWidth}
                  bottomPadding={bottomPadding}
                  showManufacturingEntityHierarchy={
                    showManufacturingEntityHierarchy
                  }
                  timespan={timespan}
                  considerPlannedStops={considerPlannedStops}
                />
              }
            />
            {showEventCount && (
              <VictoryBar
                barWidth={barWidth}
                style={{
                  data: { fill: theme.colors.diagrams.paletteNeutral[2] },
                }}
                data={countChartData}
                labelComponent={
                  <AggregatedDowntimesChartLabel
                    valueKey={'sum'}
                    height={height}
                    barWidth={barWidth}
                    bottomPadding={bottomPadding}
                    showManufacturingEntityHierarchy={
                      showManufacturingEntityHierarchy
                    }
                    timespan={timespan}
                    considerPlannedStops={considerPlannedStops}
                  />
                }
              />
            )}
          </VictoryGroup>
        </VictoryChart>
      </ChartContainer>
    ) : (
      <StyledEmptyOrLoadingChartView
        loadingInProgress={meta.loadingInProgress}
        hasResults={!!aggregatedDowntimes.length}
        hasError={!!meta.error}
        noDataAvailableText={
          interval && interval.type === IntervalType.PRODUCTION_SHIFT
            ? 'no_errors_in_current_shift'
            : interval && interval.type === IntervalType.PRODUCED_ORDER
            ? 'no_errors_in_current_order_batch'
            : interval
            ? 'no_data_in_current_interval'
            : 'no_current_interval'
        }
      />
    );
  }
}

export default sizeMe({ monitorWidth: true, monitorHeight: true })(
  injectIntl(AggregatedEventResultsChart)
);
