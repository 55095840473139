import styled from 'styled-components';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { Button as SemanticButton } from 'semantic-ui-react';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';

const StyledIcon = styled(Icon)`
  margin: 0px !important;
`;

const AddButton = styled(SemanticButton)`
  &&&&& {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background-color: #e8ebea;
    padding: 0;
    margin: 0;
    right: -6px;
    top: -12px;
    border: solid 1px rgba(36, 36, 36, 0.05);
    visibility: visible;
  }
`;

export const AddMark = ({
  icon,
  onClick,
}: {
  icon: string;
  onClick?: () => any;
}) => {
  return (
    <AddButton onClick={onClick}>
      <StyledIcon name={icon} size={16} color="black" />
    </AddButton>
  );
};

export const CompleteMark = ({ onClick }: { onClick?: () => any }) => {
  const theme = useTheme();

  return (
    <AddButton
      style={{ backgroundColor: theme.colors.primary, border: 'none' }}
      onClick={onClick}
    >
      <StyledIcon name={'checkmark'} size={24} color="white" />
    </AddButton>
  );
};
