import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { EventData } from '../event/event_definition';

export interface DowntimeMetrics {
  readonly count: number;
  readonly ratio: number;
  readonly sum: number;
}

export interface DowntimeIntervalMetrics {
  [key: string]: DowntimeMetrics;
}

export default interface AggregatedDowntime {
  readonly count: number;
  readonly event: EventData;
  readonly ratio: number;
  readonly sumMs: number;
  readonly intervalMetrics: DowntimeIntervalMetrics;
  readonly manufacturingEntity: ManufacturingEntity;
}

export enum DowntimeMetricTypes {
  COUNT = 'count',
  RATIO = 'ratio',
  SUM = 'sum',
}
