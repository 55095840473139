import { GlossaryModalProps } from './interface';
import React, { useEffect, useMemo, useState } from 'react';
import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';
import GlossaryHintTooltip from './hint_popup';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import GlossaryRow from './glossary_row';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import {
  columns,
  useGlossaryTableSortingAndFiltering,
} from './glossary_table_helper';
import {
  GlossaryGroupedEntry,
  KeyValue,
} from '@/model/domain/translation/glossary.ts';
import {
  Button,
  LeftAlignedButtons,
} from '@dev/base-web/dist/view/components/global/button';
import EditTranslationsList from './edit_translations_list';
import { Input } from './styled_components';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';
import styled from 'styled-components';
import { InputOnChangeData } from 'semantic-ui-react';
import LanguageDropdown from './language_dropdown';
import { localisation } from '@/model/redux/reducers.ts';
import { useNotificationForOperation } from '@dev/base-web/dist/view/helpers/notification_helpers';
import _ from 'lodash';
import { InputErrorHint } from '@dev/base-web/dist/view/components/inputs/input_error';
import { useIntl } from 'react-intl';
import HintContent from './hint_content';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import { checkAuthorizationRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';

const SOURCE_LANGUAGE_KEY = 'sourceLanguage';

const StyledLabelWithHint = styled(LabelWithHint)`
  margin-bottom: 8px;
`;

const StyledHintContent = styled(HintContent)`
  width: 100%;
`;

const GlossaryModal: React.FC<GlossaryModalProps> = ({
  modalVisible,
  onCancelPressed,
  getGlossaryEntries,
  glossary,
  glossaryMeta,
  selectedLanguage,
  availableTranslationLanguages,
  updateGlossaryEntriesMeta,
  updateGlossaryEntries,
  currentUser,
}) => {
  const intl = useIntl();
  const theme = useTheme();

  const [localFilters, setLocalFilters] = useState<readonly FilterData[]>([]);
  const [localSortKey, setLocalSortKey] = useState<string | undefined>(
    SOURCE_LANGUAGE_KEY
  );
  const [localSortDirection, setLocalSortDirection] = useState<
    SortingDirection | undefined
  >(SortingDirection.ASCENDING);

  const [selectedSourceLanguage, setSelectedSourceLanguage] =
    useState<string>(selectedLanguage);
  const [newPendingEntry, setNewPendingEntry] =
    useState<GlossaryGroupedEntry>();
  const [localData, setLocalData] = useState<GlossaryGroupedEntry[]>([]);
  const [activeRow, setActiveRow] = useState<GlossaryGroupedEntry>();

  useEffect(() => {
    setLocalData(glossary);
  }, [glossary]);

  useEffect(() => {
    if (modalVisible) getGlossaryEntries();
  }, [modalVisible]);

  useEffect(() => {
    // if a translation exists, which matches the selected source language,
    // we need to switch it - as translating to the same language doesn't make sense.
    if (newPendingEntry) {
      const translationsWithoutSourceLanguage =
        newPendingEntry.translations.filter(
          (t) => t.language !== selectedSourceLanguage.toUpperCase()
        );
      if (
        translationsWithoutSourceLanguage.length !==
        newPendingEntry.translations.length
      ) {
        setNewPendingEntry({
          ...newPendingEntry,
          sourceLanguage: selectedSourceLanguage,
          translations: translationsWithoutSourceLanguage,
        });
      } else
        setNewPendingEntry({
          ...newPendingEntry,
          sourceLanguage: selectedSourceLanguage,
        });
    }
  }, [selectedSourceLanguage]);

  const onOk = () => {
    updateGlossaryEntries(localData);
  };

  const filteredData = useGlossaryTableSortingAndFiltering(
    localData,
    localFilters,
    localSortKey || SOURCE_LANGUAGE_KEY,
    localSortDirection || SortingDirection.ASCENDING
  );

  const addNewEntry = () => {
    const newEntry = {
      id:
        (localData.length
          ? localData.reduce((prev, current) =>
              prev && prev.id > current.id ? prev : current
            )?.id
          : 0) + 1,
      sourceLanguage: selectedSourceLanguage,
      key: '',
      translations: availableTranslationLanguages
        .filter((t) => t !== selectedSourceLanguage)
        .map((t) => {
          return { language: t };
        }),
    };
    setNewPendingEntry(newEntry);
    setActiveRow(undefined);
  };

  const addNewEntryToList = () => {
    if (newPendingEntry) {
      setLocalData([newPendingEntry, ...localData]);
      setNewPendingEntry(undefined);
    }
  };

  const updateTranslationsInNewEntry = (translations: KeyValue[]) => {
    if (newPendingEntry)
      setNewPendingEntry({
        ...newPendingEntry,
        translations: translations,
      });
  };

  const updateGlossaryRow = (row: GlossaryGroupedEntry) => {
    const dataWithoutRow = localData.filter((t) => t.id !== row.id);
    const isDuplicateKey = dataWithoutRow.some(
      (t) => t.sourceLanguage === row.sourceLanguage && t.key === row.key
    );

    setLocalData([
      ...dataWithoutRow,
      {
        ...row,
        isKeyDuplicate: isDuplicateKey,
      },
    ]);
  };

  const removeGlossaryRow = (row: GlossaryGroupedEntry) => {
    setLocalData(localData.filter((t) => t.id !== row.id));
    setActiveRow(undefined);
  };

  useNotificationForOperation(
    updateGlossaryEntriesMeta,
    'changes_saved',
    'changes_saved',
    'changes_saved',
    intl,
    () => onCancel()
  );

  const { write: canWrite } = checkAuthorizationRWD(
    UserPrivilegePermission.CONFIGURATION,
    ApplicationCategory.COMMON,
    currentUser
  );

  const onCancel = () => {
    onCancelPressed();
    setActiveRow(undefined);
  };

  const isSavable = useMemo(() => {
    return (
      !_.isEqual(glossary, localData) &&
      !localData.some(
        (t) => !t.key || t.isKeyDuplicate || !t.translations.length
      )
    );
  }, [glossary, localData]);

  const isNewKeyDuplicate = useMemo(() => {
    return !!(
      newPendingEntry?.key &&
      newPendingEntry?.sourceLanguage &&
      localData.some(
        (t) =>
          t.sourceLanguage === newPendingEntry?.sourceLanguage &&
          t.key === newPendingEntry.key
      )
    );
  }, [newPendingEntry?.key, newPendingEntry?.sourceLanguage]);

  const languages = Object.keys(localisation);

  return (
    <BasicModalWithHeaderAndTwoButtons
      okLabel="save"
      cancelLabel="cancel"
      headerLabel={'glossary'}
      onOk={onOk}
      okLoading={updateGlossaryEntriesMeta.operationInProgress}
      onClose={() => onCancel()}
      onCancel={() => onCancel()}
      open={modalVisible}
      okDisabled={!isSavable}
      modalSize={'large'}
      rightHeaderComponent={<GlossaryHintTooltip />}
    >
      {newPendingEntry ? (
        <div
          style={{ display: 'flex', justifyContent: 'space-between', gap: 24 }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '50%',
            }}
          >
            <div style={{ marginBottom: 12 }}>
              <StyledLabelWithHint label={'key'} />
              <Input
                value={newPendingEntry.key}
                icon={
                  <LanguageDropdown
                    selectedLanguage={selectedSourceLanguage}
                    setSelectedLanguage={setSelectedSourceLanguage}
                    selectableLanguages={languages}
                  />
                }
                error={!newPendingEntry.key}
                withoutBorder
                onChange={(
                  _event: React.ChangeEvent<HTMLInputElement>,
                  data: InputOnChangeData
                ) => {
                  setNewPendingEntry({
                    ...newPendingEntry,
                    key: data.value,
                  });
                }}
              />
              {isNewKeyDuplicate && <InputErrorHint id={'duplicate_found'} />}
            </div>
            <LeftAlignedButtons>
              <Button
                type={'primary'}
                icon={'plus'}
                label={'add_entry'}
                onClick={() => addNewEntryToList()}
                disabled={
                  isNewKeyDuplicate ||
                  !newPendingEntry.key ||
                  !newPendingEntry.translations.some(
                    (t) => t.language && t.value
                  )
                }
              />
              <Button
                type={'tertiary'}
                icon={'delete'}
                onClick={() => setNewPendingEntry(undefined)}
              />
            </LeftAlignedButtons>
          </div>
          <div style={{ width: '50%' }}>
            <StyledLabelWithHint label={'translations'} />
            <EditTranslationsList
              sourceLanguage={selectedSourceLanguage}
              translations={newPendingEntry.translations}
              setTranslations={updateTranslationsInNewEntry}
            />
          </div>
        </div>
      ) : (
        <Button
          type={'primary'}
          icon={'plus'}
          label={'add_entry'}
          onClick={() => addNewEntry()}
          disabled={!canWrite}
          tooltipLabel={'no_permission'}
        />
      )}
      {!localData.length &&
      !glossaryMeta.loadingInProgress &&
      !glossaryMeta.error ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{ margin: 24, display: 'flex', justifyContent: 'center' }}
          >
            <Card>
              <StyledHintContent theme={theme} hasWhiteBackground />
            </Card>
          </div>
        </div>
      ) : (
        <SortableTable
          dataLength={glossary.length}
          hasMoreResults={false}
          columns={columns(languages)}
          initialSearchData={{
            sortConfig: {
              key: SOURCE_LANGUAGE_KEY,
              direction: SortingDirection.ASCENDING,
            },
          }}
          getNextResults={(_, filters, sortKey, sortDirection) => {
            setLocalFilters(filters);
            setLocalSortKey(sortKey);
            setLocalSortDirection(sortDirection);
          }}
          loadingInProgress={glossaryMeta.loadingInProgress}
          error={glossaryMeta.error}
          maxHeight={400}
          openFiltersOnMount
        >
          {filteredData.map((g) => {
            return (
              <GlossaryRow
                key={g.id}
                data={g}
                onValueChanged={updateGlossaryRow}
                isEditActive={!!(activeRow && activeRow.id === g.id)}
                setEditActive={() => canWrite && setActiveRow(g)}
                removeGlossaryEntry={() => removeGlossaryRow(g)}
                isEditDisabled={!canWrite}
              />
            );
          })}
        </SortableTable>
      )}
    </BasicModalWithHeaderAndTwoButtons>
  );
};

export default GlossaryModal;
