import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

const size32InputStyle = css`
  height: 32px;
  padding-left: 8px;
`;

interface InputfieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  size32?: boolean;
}

const Input = styled.input<InputfieldProps>`
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 10px 12px;
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  outline: none;
  resize: none;

  &:hover {
    border: solid 1px rgba(0, 0, 0, 0.4);
  }
  &:active {
    border: solid 1px #0da6a0;
  }
  &:invalid {
    border: solid 2px #e30021;
  }
  &:focus {
    border: solid 1px #0da6a0;
  }
  &:disabled {
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #f0f0f0;
  }
  ${(props) => props.size32 && size32InputStyle};
`;

const Inputfield = (props: PropsWithChildren<InputfieldProps>) => {
  return (
    <Input className="InputRegular" type="text" {...props}>
      {props.children}
    </Input>
  );
};

export default Inputfield;
