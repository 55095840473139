import {
  LabelModalDispatchProps,
  LabelModalStoreProps,
} from '@dev/base-web/dist/view/components/modal/label_modal/interface';
import { connect } from 'react-redux';
import EditLabelModal from '@dev/base-web/dist/view/components/modal/label_modal/view';
import LabelThunks from '../../../../model/redux/labels/thunks';
import { RootReducerInterface } from '../../../../model/redux/interfaces';

const mapStateToProps = (state: RootReducerInterface): LabelModalStoreProps => {
  return {
    updateLabelMeta: state.data.labelState.updateLabel.meta,
  };
};

const mapDispatchToProps: LabelModalDispatchProps = {
  updateLabels: LabelThunks.updateLabels,
  getLabels: LabelThunks.getLabels,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLabelModal);
