import { connect } from 'react-redux';
import eventsThunks from '../../model/redux/events/thunks';
import { RootReducerInterface } from '../../model/redux/interfaces';
import manufacturingEntitiesThunks from '../../model/redux/manufacturing_entity/thunks';
import { CurrentEventsCardStoreProps } from './interface';
import CurrentEventsCard from './view';

const mapStateToProps = (
  state: RootReducerInterface
): CurrentEventsCardStoreProps => ({
  activeEvents: state.data.eventsState.activeEvents,
  closedEvents: state.data.eventsState.closedEvents,
  manufacturingEntities:
    state.data.manufacturingEntityState.manufacturingEntities.data,
  manufacturingEntitiesLoading:
    state.data.manufacturingEntityState.manufacturingEntities.meta
      .loadingInProgress,
});

export default connect(mapStateToProps, {
  getActiveEvents: eventsThunks.getActiveEvents,
  reloadActiveEvents: eventsThunks.reloadActiveEvents,
  getClosedEventsFirstPage: eventsThunks.getClosedEventsFirstPage,
  getMoreClosedEvents: eventsThunks.getMoreClosedEvents,
  reloadClosedEvents: eventsThunks.reloadClosedEvents,
  getAllManufacturingEntities:
    manufacturingEntitiesThunks.getManufacturingEntities,
})(CurrentEventsCard);
