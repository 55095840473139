import React, { Component } from 'react';
import CropImageModal from '@dev/base-web/dist/view/components/modal/crop_image_modal';
import Loader from '@dev/base-web/dist/view/components/global/loader';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import UserUpdateDTO from '@dev/base-web/dist/model/domain/user/user_update_dto';
import { UserPictureUpdateResultDTO } from '@dev/base-web/dist/model/domain/user/user_picture_update_result_dto';
import UserAvatar from '@dev/base-web/dist/view/components/global/dummy_avatar';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { NoMarginIcon } from '../../../components/StyledComponents';

interface UserImageViewProps {
  readonly user: UserUpdateDTO | null;
  readonly pictureUploadResult: UserPictureUpdateResultDTO | null;
  readonly pictureUploadInProgress: boolean;
  readonly uploadPicture: (picture: Blob) => void;
  readonly updateUserData: (newUserData: UserUpdateDTO) => void;
  readonly canEdit: boolean;
}

const kImageSize = 120;

const StyledImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: ${kImageSize}px;
  height: ${kImageSize}px;
  border-radius: ${kImageSize / 2}px;
`;

const StyledCancelButton = styled(Button)`
  &&&&& {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: black;
    padding: 0;
    margin: 0;
  }
`;

const StyledCropButton = styled(Button)`
  &&&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: ${kImageSize}px;
    height: ${kImageSize}px;
    font-family: Relative;
    font-size: 14px;
    font-weight: bold;
    border-radius: ${kImageSize}px;
    background-color: black;
    color: white;
    &:hover {
      background-color: #000000cc;
    }
  }
`;

export default class UserImageView extends Component<UserImageViewProps> {
  state = {
    fileUrl: '',
  };

  componentDidUpdate(prevProps: Readonly<UserImageViewProps>): void {
    const { pictureUploadResult } = this.props;

    if (
      pictureUploadResult &&
      pictureUploadResult !== prevProps.pictureUploadResult
    ) {
      this.props.updateUserData({ pictures: pictureUploadResult.urls });
    }
  }

  private onCropFinished = (picture: Blob) => {
    const { fileUrl } = this.state;
    if (fileUrl) window.URL.revokeObjectURL(fileUrl);
    this.setState({ fileUrl: window.URL.createObjectURL(picture) });
    this.props.uploadPicture(picture);
  };

  private onRemoveImage = () => {
    this.props.updateUserData({ pictures: undefined });
    this.setState({ fileUrl: '' });
  };

  render() {
    const { fileUrl } = this.state;
    const { user, pictureUploadInProgress, canEdit } = this.props;
    const imageUrl =
      user && user.pictures ? user.pictures.full : fileUrl ? fileUrl : null;

    const fullName = user && `${user.firstName || '-'} ${user.lastName || '-'}`;

    return (
      <div>
        {imageUrl || !canEdit ? (
          <StyledImageContainer>
            <UserAvatar
              name={fullName ? fullName : undefined}
              src={imageUrl || undefined}
              size="120px"
            />
            {canEdit && (
              <StyledCancelButton onClick={this.onRemoveImage}>
                <NoMarginIcon name="cross" size={16} color="white" />
              </StyledCancelButton>
            )}
          </StyledImageContainer>
        ) : (
          <CropImageModal
            title={'image_crop'}
            triggerButton={
              <StyledCropButton>
                {' '}
                <Icon name="plus" size={16} color="white" />{' '}
                <FormattedMessage id="choose_picture" />
              </StyledCropButton>
            }
            onCropFinished={this.onCropFinished}
            aspectRatio={1}
            width={400}
            circularCrop
          />
        )}

        {pictureUploadInProgress && <Loader />}
      </div>
    );
  }
}
