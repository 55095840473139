import {
  createActionsForExecutingOperation,
  createActionsForList,
} from '@dev/base-web/dist/model/redux/helpers/actions';
import {
  GlossaryDetail,
  GlossaryGroupedEntry,
} from '../../domain/translation/glossary';

export const ACTION_TYPES = {
  GLOSSARIES: 'GLOSSARIES',
  GLOSSARY: 'GLOSSARY',
  CREATE_GLOSSARY: 'CREATE_GLOSSARY',
  UPDATE_GLOSSARY: 'UPDATE_GLOSSARY',
  DELETE_GLOSSARY: 'DELETE_GLOSSARY',
};
export const Actions = {
  glossaries: createActionsForList<GlossaryDetail>(ACTION_TYPES.GLOSSARIES),
  glossary: createActionsForList<GlossaryGroupedEntry>(ACTION_TYPES.GLOSSARY),
  createGlossary: createActionsForExecutingOperation(
    ACTION_TYPES.CREATE_GLOSSARY
  ),
  updateGlossary: createActionsForExecutingOperation(
    ACTION_TYPES.UPDATE_GLOSSARY
  ),
  deleteGlossary: createActionsForExecutingOperation(
    ACTION_TYPES.DELETE_GLOSSARY
  ),
};
