import styled from 'styled-components';
import ColoredKPI from '@dev/base-web/dist/view/components/kpi/colored_kpi';
import { formatDuration } from '@dev/base-web/dist/view/helpers/date_helpers';
import SimpleKPI from '@dev/base-web/dist/view/components/kpi/simple_kpi';
import { calculateDocumentedDowntimesColorValue } from '../../../components/kpi_helpers';
import ThreeStatePercentageKPI from '../../../overview/components/three_state_percentage_kpi';
import ShiftReportOverview from '@dev/base-web/dist/model/domain/report/ShiftReportOverview';
import ProducedProductsPopup from '../../../../dashboard/components/produced_products_tooltip';
import { localizeNumber } from '@dev/base-web/dist/model/helpers/number-formatter';
import React from 'react';

const KPIRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const StyledColoredKPI = styled(ColoredKPI)`
  margin-right: 32px;
`;

const StyledSimpleKPI = styled(SimpleKPI)`
  margin-right: 32px;
`;

const StyledThreeStatePercentageKPI = styled(ThreeStatePercentageKPI)`
  margin-right: 32px;
`;

interface ReportKPIsProps {
  readonly report: ShiftReportOverview;
}

const ReportKPIs = ({ report }: ReportKPIsProps) => {
  const {
    productionRate,
    downtime,
    documentedDowntimes,
    totalDowntimes,
    cycleCount,
    producedProducts,
    manufacturingEntity,
    productsPerCycle,
    downtimeDocumentationThreshold,
  } = report;
  const formattedDownTime = formatDuration(downtime, 'HH:mm:ss');

  return (
    <KPIRow>
      <StyledThreeStatePercentageKPI
        value={productionRate}
        upperTreshold={0.7}
        lowerTreshold={0.4}
        labelId={'production_rate'}
      />
      <ProducedProductsPopup
        manufacturingEntity={manufacturingEntity}
        productsPerCycle={productsPerCycle}
        producedProducts={producedProducts || 0}
        cycleCount={cycleCount || 0}
        trigger={
          <div>
            <StyledSimpleKPI
              value={localizeNumber(producedProducts) || '-'}
              title={'produced_products'}
            />
          </div>
        }
      />
      <StyledSimpleKPI
        value={formattedDownTime}
        title={
          downtimeDocumentationThreshold ? 'downtime_in_report' : 'downtime'
        }
        titleValues={
          downtimeDocumentationThreshold
            ? {
                value: downtimeDocumentationThreshold / (60 * 1000),
                unit: 'minutes',
              }
            : undefined
        }
      />
      <StyledColoredKPI
        value={`${documentedDowntimes}/${totalDowntimes}`}
        showGradient
        colorValue={calculateDocumentedDowntimesColorValue(
          documentedDowntimes,
          totalDowntimes
        )}
        title={'documented_downtimes'}
      />
    </KPIRow>
  );
};

export default ReportKPIs;
