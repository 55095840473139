import PrivateRoute, {
  PrivateRouteParentPropsInterface,
} from '@dev/base-web/dist/view/components/global/private_route';
import { AUTH_REDIRECT_URL } from '../../model/api/common/config';
import React from 'react';
import { AuthCodeInterface } from '@dev/base-web/dist/model/redux/authentication/interface';
import { CLIENT_ID } from '@dev/base-web/dist/model/api/common/config';
import { RootReducerInterface } from '../../model/redux/interfaces';
import { connect } from 'react-redux';
import AuthenticationThunks from '../../model/redux/authentication/thunks';

interface PrivateRouteWithRedirectLoginDispatchProps {
  readonly receivedAuthCode?: (authCode: AuthCodeInterface) => void;
}

type PrivateRouteWithRedirectLoginProps =
  PrivateRouteWithRedirectLoginDispatchProps & PrivateRouteParentPropsInterface;

const PrivateRouteWithRedirectLogin = ({
  receivedAuthCode,
  ...routeProps
}: PrivateRouteWithRedirectLoginProps) => {
  return (
    <PrivateRoute
      receivedAuthCode={receivedAuthCode}
      clientId={CLIENT_ID}
      authUrl={AUTH_REDIRECT_URL}
      {...routeProps}
    />
  );
};

export default connect<
  undefined,
  PrivateRouteWithRedirectLoginDispatchProps,
  PrivateRouteParentPropsInterface,
  RootReducerInterface
>(null, {
  receivedAuthCode: AuthenticationThunks.exchangeAuthCodeForToken,
})(PrivateRouteWithRedirectLogin);
