import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';

const LOCATIONS_CONFIG_URL = '/config/locations';

export default class LocationConfigApi extends BaseDataApi<string> {
  async getAllLocations(token: string) {
    const url = LOCATIONS_CONFIG_URL;
    return this.fetchAllData(url, token);
  }
}
