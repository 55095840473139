import styled from 'styled-components';

export const Container = styled.div<{ isInternal?: boolean }>`
  padding-top: ${(props) => (props.isInternal ? '0' : '48px')};
  font-family: Relative;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #8c8c8c;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 18px;
`;

export const HorizontalLabel = styled.span`
  margin-right: 20px;
`;

export const UserTags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 12px;
`;

export const Info = styled.p`
  margin: 0;
  padding-top: 24px;
  padding-bottom: 8px;
`;
