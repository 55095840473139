import { createActionsForLoadingPagingList } from '@dev/base-web/dist/model/redux/helpers/actions';
import { ManufacturingEntitySummaryInterval } from '../../../domain/report/manufacturing_entity_summary_interval';

export const ACTION_TYPES = {
  GET_KPI_RESULTS_KEY: 'GET_KPI_RESULTS',
};

export const Actions = {
  kpiResults:
    createActionsForLoadingPagingList<ManufacturingEntitySummaryInterval>(
      ACTION_TYPES.GET_KPI_RESULTS_KEY
    ),
};
