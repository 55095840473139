import {
  Column,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import { createEventTypeDropdownIcon } from '../../../../../events/view';
import { IntlShape } from 'react-intl';
import RawEvent from '../../../../../../model/domain/event/raw_event';
import {
  AlertProps,
  AlertSeverity,
} from '@dev/base-web/dist/view/components/global/alert';

export const createColumns = (
  intl: IntlShape,
  minimumSelectableEntityId?: string
): Array<Column> => [
  {
    title: 'type',
    sortKey: 'event.type',
    filterType: ColumnFilterType.SELECTOR,
    filterOptions: Object.values(EventType).map((eventType) => {
      const dropdownIcon = createEventTypeDropdownIcon(eventType);
      return {
        image: dropdownIcon,
        value: eventType,
        textId: ' ',
        content: intl.formatMessage({ id: eventType.toLowerCase() }),
      };
    }),
    width: 1,
    fixedWidthInPx: 64,
  },
  {
    title: 'name',
    isLeftAligned: true,
    sortKey: 'event.name',
    filterType: ColumnFilterType.TEXT,
    width: 5,
  },
  {
    title: 'manufacturing_entity',
    isLeftAligned: true,
    filterKey: 'manufacturingEntity.id',
    sortKey: 'manufacturingEntity.name',
    filterType: ColumnFilterType.MANUFACTURING_ENTITY,
    minimumSelectableEntityId: minimumSelectableEntityId,
    useIdAsFilterValue: true,
    width: 3,
  } as any,
  {
    title: 'date',
    isLeftAligned: true,
    sortKey: 'eventStart',
    filterType: ColumnFilterType.NONE,
    width: 2,
    useDateTime: true,
  },
  {
    title: 'duration',
    isLeftAligned: false,
    sortKey: 'duration',
    filterType: ColumnFilterType.DURATION,
    width: 2,
    popupText: intl.formatMessage({ id: 'event_duration_hint' }),
  },
  {
    title: 'end',
    isLeftAligned: true,
    sortKey: 'eventEnd',
    filterType: ColumnFilterType.NONE,
    width: 2,
    useDateTime: true,
    popupText: intl.formatMessage({ id: 'event_end_hint' }),
  },
  {
    title: 'root_event',
    sortKey: 'isParent',
    filterType: ColumnFilterType.SELECTOR,
    filterOptions: [true, false].map((value) => ({
      textId: String(value),
      value: value,
    })),
    width: 1,
  },
];

export const generateDowntimeReportEventMatchingAlerts = (
  begin: number,
  end: number,
  selectedRow: RawEvent | undefined
): AlertProps[] => {
  const alerts = [];

  if (selectedRow) {
    if (selectedRow.eventStart > begin)
      alerts.push({
        severity: AlertSeverity.WARNING,
        textId: 'event_started_after_downtime_start_hint',
        textValues: {},
      });

    const eventDuration = selectedRow.duration;
    const downtimeDuration = end - begin;

    if (eventDuration && eventDuration < downtimeDuration * 0.8)
      alerts.push({
        severity: AlertSeverity.WARNING,
        textId: 'event_shorter_than_downtime_hint',
        textValues: {},
      });

    if (eventDuration && eventDuration > downtimeDuration * 1.25)
      alerts.push({
        severity: AlertSeverity.WARNING,
        textId: 'event_longer_than_downtime_hint',
        textValues: {},
      });

    if (
      selectedRow.event.eventType &&
      selectedRow.event.eventType !== EventType.ERROR
    )
      alerts.push({
        severity: AlertSeverity.WARNING,
        textId: 'event_not_error_hint',
        textValues: {},
      });
  }

  return alerts;
};
