import React from 'react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';

const PopupAlignContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const SubEventAlign = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  max-height: 100px;
  overflow: auto;
  padding-left: 1.2em;
`;

interface Props {
  readonly manufacturingEntities: readonly ManufacturingEntity[];
  readonly trigger: React.ReactNode;
  readonly showHierarchy: boolean;
}

const MultiManufacturingEntityPopup = (props: Props) => {
  const { manufacturingEntities, trigger, showHierarchy } = props;

  return (
    <Popup
      wide={true}
      position={'top left'}
      hoverable
      mouseEnterDelay={600}
      trigger={trigger}
    >
      <PopupAlignContent>
        <SubEventAlign>
          <ListWrapper>
            {manufacturingEntities.map((manufacturingEntity, index) => {
              return (
                <li style={{ margin: 0 }} key={index}>
                  <ManufacturingEntityHierarchyElement
                    manufacturingEntity={manufacturingEntity}
                    hideHiddenTypes
                    showHierarchy={showHierarchy}
                    lines={2}
                  />
                </li>
              );
            })}
          </ListWrapper>
        </SubEventAlign>
      </PopupAlignContent>
    </Popup>
  );
};
export default MultiManufacturingEntityPopup;
