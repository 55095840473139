import { DataApiSortConfig } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';
import RawEvent from '../../domain/event/raw_event';
import { PagingRequestResult } from '@dev/base-web/dist/model/api/common/paging_request_result';

export default class RawEventApi extends BaseDataApi<RawEvent> {
  async getRawEvents(
    page: number,
    filters: readonly FilterData[],
    sort: DataApiSortConfig,
    token: string,
    signal: AbortSignal
  ): Promise<PagingRequestResult<RawEvent>> {
    const response = await this.fetchOnePageOfDataWithSearchAndSort(
      '/events/raw/search',
      token,
      signal,
      page,
      filters,
      sort
    );

    return {
      results: response.content,
      hasMoreResults: !response.last,
    };
  }
}
