import { PropsWithChildren } from 'react';
import {
  FunctionContainer,
  FunctionWrapper,
  Image,
} from '../styled_components';

export interface DefaultImageViewProps {
  readonly fullContent: string | undefined;
  readonly thumbnailContent?: string;
  readonly isLoading?: boolean;
  readonly hasError?: boolean;
  readonly onImageRendered: (image: HTMLImageElement) => void;
}

const DefaultImageView = ({
  fullContent,
  thumbnailContent,
  isLoading = false,
  hasError = false,
  onImageRendered,
  children,
}: PropsWithChildren<DefaultImageViewProps>) => {
  return (
    <>
      <Image
        fullContent={fullContent}
        thumbnailContent={thumbnailContent}
        loading={isLoading}
        mediaError={hasError}
        onLoad={onImageRendered}
      />
      <FunctionWrapper>
        <FunctionContainer>{children}</FunctionContainer>
      </FunctionWrapper>
    </>
  );
};

export default DefaultImageView;
