import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import { Popup } from 'semantic-ui-react';
import { BasicEvent } from '@/model/redux/actions/interface.ts';
import { EventTypeIcon } from '@/components/items/EventTypeIcon.tsx';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';

const PopupAlignContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const SubEventAlign = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  max-height: 100px;
  overflow: auto;
  padding-left: 1.2em;
`;

interface Props {
  readonly event: BasicEvent;
  readonly trigger: React.ReactNode;
}

const RelatedEventPopup = (props: Props) => {
  const { trigger, event } = props;

  const intl = useIntl();
  const eventName = intl.formatMessage({
    id: event.name,
    defaultMessage: event.name,
  });

  return (
    <Popup
      wide={true}
      position={'top left'}
      hoverable
      mouseEnterDelay={600}
      trigger={trigger}
      flowing
    >
      <PopupAlignContent>
        <SubEventAlign>
          {event.type && <EventTypeIcon type={event.type} size={16} />}
          <TextWithEllipsisAndTooltip
            text={eventName}
            lines={2}
            style={{ paddingLeft: 6 }}
          />
        </SubEventAlign>
        <SubEventAlign>
          <Icon
            name={'location'}
            color={'#939393'}
            size={16}
            iconStyle={{ marginRight: 5, minWidth: 16 }}
          />
          {event.manufacturingEntities &&
          event.manufacturingEntities.length > 1 ? (
            <ListWrapper>
              {event.manufacturingEntities &&
                event.manufacturingEntities.map(
                  (manufacturingEntity, _index) => {
                    return (
                      <li style={{ margin: 0 }}>
                        <ManufacturingEntityHierarchyElement
                          manufacturingEntity={manufacturingEntity}
                          hideHiddenTypes
                          lines={2}
                          hideTooltip
                        />
                      </li>
                    );
                  }
                )}
            </ListWrapper>
          ) : event.manufacturingEntities?.length === 1 ? (
            <ManufacturingEntityHierarchyElement
              manufacturingEntity={event.manufacturingEntities[0]}
              hideHiddenTypes
              lines={2}
              hideTooltip
            />
          ) : (
            '-'
          )}
        </SubEventAlign>
      </PopupAlignContent>
    </Popup>
  );
};
export default RelatedEventPopup;
