import styled from 'styled-components';
import { Dropdown, DropdownItemProps, Popup } from 'semantic-ui-react';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { AutoGeneratePopupProps } from './interface';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import { FormattedMessage, useIntl } from 'react-intl';
import TextAreaWithCount from '@/components/text_area_with_count.tsx';
import { BasicEvent } from '@/model/redux/actions/interface.ts';
import LabelsWithEllipsis from '@dev/base-web/dist/view/components/global/labels_with_ellipsis';
import AddEventsModal from '@/screens/edit_instruction/components/add_events_modal';
import { FakeSingleDropDown } from '@dev/base-web/dist/view/components/inputs/fake_dropdown';
import { Row } from '@dev/base-web/dist/view/components/global/styled_components';
import usePromptHandling from './use_prompt_handling';
import useCategoryHandling from '@/screens/edit_instruction/components/auto_generate_popup/use_category_handling.ts';
import Alert, {
  AlertSeverity,
} from '@dev/base-web/dist/view/components/global/alert';
import { useNotificationForOperationError } from '@dev/base-web/dist/view/helpers/notification_helpers';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 500px;
  padding-top: 8px;
  padding-left: 8px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
`;

const StyledLabelWithHint = styled(LabelWithHint)`
  margin-top: 18px;
  margin-bottom: 4px;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 12px;
`;

const StyledTextArea = styled(TextAreaWithCount)`
  & {
    width: 100%;
    opacity: 100% !important;
  }
`;

const StyledFakeSingleDropDown = styled(FakeSingleDropDown)`
  min-height: 36px !important;
`;

const AutoGeneratePopup = ({
  instruction,
  getInstructionSuggestion,
  suggestionMeta,
  selectedLanguage,
  manufacturingEntity,
  getManufacturingEntity,
  onEventsAdded,
  getDataSourceOfEvent,
  dataSourceOfEvent,
}: AutoGeneratePopupProps) => {
  const intl = useIntl();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<BasicEvent>();

  const [eventsModalOpen, setEventsModalOpen] = useState<boolean>(false);

  const { prompt, setPrompt } = usePromptHandling(
    open,
    instruction?.translations,
    instruction?.steps
  );
  const {
    selectedLabels,
    categoryArray,
    onCategorySelected,
    onRemoveCategory,
    onLabelChanged,
  } = useCategoryHandling(
    manufacturingEntity,
    dataSourceOfEvent,
    selectedEvent
  );

  useNotificationForOperationError(
    {
      operationInProgress: suggestionMeta.loadingInProgress,
      error: suggestionMeta.error,
    },
    intl,
    'instruction_generation_failed'
  );

  useEffect(() => {
    if (instruction?.events.length) setSelectedEvent(instruction.events[0]);
    else setSelectedEvent(undefined);
  }, [instruction?.events]);

  useEffect(() => {
    if (selectedEvent && selectedEvent.manufacturingEntities?.length) {
      getManufacturingEntity(selectedEvent.manufacturingEntities[0].id);
      getDataSourceOfEvent(selectedEvent.id);
    }
  }, [selectedEvent]);

  const onClick = () => {
    if (instruction?.events.length) {
      getInstructionSuggestion(
        instruction?.events[0].id,
        categoryArray,
        prompt,
        false,
        selectedLanguage
      );
      setOpen(false);
    }
  };

  const onLocalDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setPrompt(e.target.value);
  };

  const onLocalEventsAdded = (newEvents: BasicEvent[]) => {
    onEventsAdded(newEvents, true);
  };

  const categoryOptions: DropdownItemProps[] = useMemo(() => {
    return ['production_component', 'process_technology', 'plc_type']
      .filter((t) => !selectedLabels.some((l) => l.group === t))
      .map((t) => ({
        key: t,
        text: t,
        value: t,
      }));
  }, [selectedLabels]);

  return (
    <>
      <Popup
        wide
        trigger={
          <div>
            <Button
              type="secondary"
              icon={'magic'}
              loading={suggestionMeta.loadingInProgress}
            />
          </div>
        }
        on="click"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        position={'bottom right'}
        pinned
        offset={[-6]}
        style={{ paddingLeft: 4 }}
        disabled={false}
      >
        <FormContainer>
          <Title>
            <FormattedMessage id={'auto_generate_instruction'} />
          </Title>
          <Hint messageKey={'instruction_suggestion_hint'} />
          <StyledLabelWithHint
            label={'related_event'}
            hint={'suggest_instruction_related_event_hint'}
          />
          <StyledFakeSingleDropDown
            placeholder={!selectedEvent ? 'select_an_event' : undefined}
            fluid
            onClick={() => {
              setOpen(false);
              setEventsModalOpen(true);
            }}
            error={!selectedEvent}
          >
            {selectedEvent && (
              <p
                style={{
                  fontStyle: !selectedEvent ? 'italic' : 'normal',
                  color: !selectedEvent ? '#a6a6a6' : 'inherit',
                }}
              >
                {selectedEvent.name}
              </p>
            )}
          </StyledFakeSingleDropDown>
          <StyledLabelWithHint
            label={'what_should_instruction_be_about'}
            hint={'suggest_instruction_prompt_hint'}
          />
          <StyledTextArea
            name="description"
            onChange={onLocalDescriptionChange}
            value={prompt}
            placeholder={intl.formatMessage({
              id: 'what_should_instruction_be_about_hint',
            })}
            showCount
            maxLength={500}
          />
          <StyledLabelWithHint
            label={'categories'}
            hint={'suggest_instruction_categories_hint'}
            hintValues={{
              b: (chunks: ReactNode) => <strong>{chunks}</strong>,
              br: <br />,
              ul: (chunks: ReactNode) => <ul>{chunks}</ul>,
              li: (chunks: ReactNode) => <li>{chunks}</li>,
            }}
            popupWide={true}
          />
          <Row
            style={{
              alignItems: 'center',
              width: '100%',
            }}
          >
            <LabelsWithEllipsis
              labels={selectedLabels}
              labelMaxWidth={460}
              onRemoveLabel={onRemoveCategory}
              maxHeight={120}
              editable
              onLabelChanged={onLabelChanged}
            />
            {!!categoryOptions.length && (
              <Dropdown
                trigger={
                  <Button type="tertiary" noMargin icon="plus" size={'small'} />
                }
                icon={null}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Dropdown.Menu direction="left">
                  {categoryOptions.map((c) => (
                    <Dropdown.Item
                      value={c.value}
                      text={<FormattedMessage id={c.value as string} />}
                      onClick={() => onCategorySelected(c.value as string)}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Row>
          {instruction && instruction.steps.length > 0 && (
            <div style={{ marginTop: 12 }}>
              <Alert
                severity={AlertSeverity.WARNING}
                textId="instruction_will_be_overwritten"
                textValues={{}}
              />
            </div>
          )}
          <ButtonContainer>
            <Button
              type="primary"
              label={'generate'}
              onClick={onClick}
              disabled={!instruction?.events.length}
            />
          </ButtonContainer>
        </FormContainer>
      </Popup>
      <AddEventsModal
        modalVisible={eventsModalOpen}
        onCancelPressed={() => {
          setEventsModalOpen(false);
          setOpen(true);
        }}
        onEventsAdded={onLocalEventsAdded}
        existingEvents={instruction?.events || []}
        singleSelect
      />
    </>
  );
};

export default AutoGeneratePopup;
