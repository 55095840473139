import { ImportColMap } from '@dev/base-web/dist/model/api/import/import_api';
import { Dispatch } from '../store';
import { eventDefinitionImportApi } from '../../api';
import { RootReducerInterface } from '../interfaces';
import { EventDefinitionValidationResponse } from '../../domain/event/event_definition_import';
import { Actions } from './actions';

export const resetImport = () => (dispatch: Dispatch) => {
  dispatch(Actions.importEventDefinition.reset());
  dispatch(Actions.confirmEventDefinitionImport.reset());
  dispatch(Actions.eventDefinitionImportStatus.reset());
};

export const resetImportStatus = () => (dispatch: Dispatch) => {
  dispatch(Actions.eventDefinitionImportStatus.reset());
};

export const startImport =
  (file: File, colMap: ImportColMap, maxAllowedInvalidEntries?: number) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(Actions.importEventDefinition.meta.startLoading());

    try {
      const data = await eventDefinitionImportApi.startImport(
        token.accessToken,
        file,
        colMap,
        false,
        {
          maxAllowedInvalidEntries,
        }
      );

      dispatch(Actions.importEventDefinition.loadingItemSuccessful(data));
    } catch (error: unknown) {
      dispatch(Actions.importEventDefinition.meta.loadingFailed({ error }));
    } finally {
      dispatch(Actions.importEventDefinition.meta.endLoading());
    }
  };

export const confirmImport =
  (importId: string, save = false, overwriteExistings = false) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(Actions.confirmEventDefinitionImport.meta.startLoading());

    try {
      const data = await eventDefinitionImportApi.confirmImport(
        token.accessToken,
        importId,
        save,
        overwriteExistings
      );

      dispatch(
        Actions.confirmEventDefinitionImport.loadingItemSuccessful(data)
      );
    } catch (error: unknown) {
      dispatch(
        Actions.confirmEventDefinitionImport.meta.loadingFailed({ error })
      );
    } finally {
      dispatch(Actions.confirmEventDefinitionImport.meta.endLoading());
    }
  };

export const checkImportStatus =
  (importId: string) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(Actions.eventDefinitionImportStatus.meta.startLoading());

    try {
      const data =
        await eventDefinitionImportApi.getImportStatus<EventDefinitionValidationResponse>(
          token.accessToken,
          importId
        );

      dispatch(Actions.eventDefinitionImportStatus.loadingItemSuccessful(data));
    } catch (error: unknown) {
      dispatch(
        Actions.eventDefinitionImportStatus.meta.loadingFailed({ error })
      );
    } finally {
      dispatch(Actions.eventDefinitionImportStatus.meta.endLoading());
    }
  };
