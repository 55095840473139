import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  Button,
  RightAlignedButtons,
} from '@dev/base-web/dist/view/components/global/button';
import TemporaryTranslation from './temporary_translation';
import InstructionDecisionTranslationCard from './instruction_decision_translation_card';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import TextAreaWithCount from '@/components/text_area_with_count.tsx';
import {
  InstructionStepTranslation,
  TranslationWithSource,
} from '@/model/domain/instruction/translation.ts';
import { StepNumber } from '@/components/StepNumber.tsx';
import StepMedia from '@/components/instruction/step_media.tsx';

const StyledTextArea = styled(TextAreaWithCount)`
  & {
    width: 100%;
    opacity: 100% !important;
  }
`;

const ActionStepHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 12px;
  box-shadow: inset 0 -1px 0 0 #efefef;
`;
const HeadLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ActionStepContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Line = styled.div<{ hidden?: boolean }>`
  width: 1px;
  height: 24px;
  margin: 0 auto;
  border: solid 2px hsl(0, 0%, 59%);

  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;

interface Props {
  readonly index: number;
  readonly item: InstructionStepTranslation;
  readonly selectedDecision?: number;
  readonly length: number;
  readonly onSelect: (s: InstructionStepTranslation, d: number) => void;
  readonly onChangeStep: (
    oldStep: InstructionStepTranslation,
    newStep: InstructionStepTranslation,
    parent?: string
  ) => void;
  readonly selectedLanguage: string;
  readonly parent?: string;
}

const InstructionStepTranslationCard: React.FC<Props> = ({
  item,
  selectedDecision,
  length,
  onChangeStep,
  onSelect,
  selectedLanguage,
  parent,
}) => {
  const intl = useIntl();
  const [description, setDescription] = useState('');

  useEffect(() => {
    setDescription(
      item.translationUpdate?.translations?.[selectedLanguage].translation ?? ''
    );
  }, [item, selectedLanguage]);

  const onChangeStepLocal = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);

    if (item.translationUpdate) {
      const translationUpdate: TranslationWithSource = {
        ...item.translationUpdate,
        translations: {
          ...item.translationUpdate.translations,
          [selectedLanguage]: {
            ...item.translationUpdate.translations[selectedLanguage],
            translation: e.target.value,
          },
        },
      };
      const itemCopy = { ...item, translationUpdate: translationUpdate };
      onChangeStep(item, itemCopy, parent);
    }
  };

  // const handleOnBlur = () => {
  //   const itemCopy = { ...item, description };
  //   onChangeStep(item, itemCopy, parent);
  // };

  const tr = item.translationUpdate?.translations[selectedLanguage];
  const sourceLanguage = item?.translationUpdate?.sourceLanguage;

  const viewingLastEditedLanguage = sourceLanguage === selectedLanguage;

  // const didTranslationChange =
  //   item?.descriptionTranslation?.previousTargetLanguageText !==
  //   item?.descriptionTranslation?.targetLanguageText;

  // If previousTargetLanguageText === sourceLanguageText then it means it's a
  // a newly added step.
  const isNewlyAdded =
    tr?.prevTranslation === item?.translationUpdate?.sourceLanguageText;

  if (item.decisionOptions && item.decisionOptions.length === 2) {
    return (
      <Card
        style={{
          marginBottom: '24px',
          paddingBottom: 12,
        }}
        id="decision"
      >
        <InstructionDecisionTranslationCard
          item={item}
          selectedDecision={selectedDecision}
          onDescriptionChange={onChangeStepLocal}
          //onDescriptionBlur={handleOnBlur}
          onSelect={onSelect}
          selectedLanguage={selectedLanguage}
        />
      </Card>
    );
  } else {
    return (
      <>
        <Card
          style={{
            marginBottom:
              item.step === length && item.decisionOptions.length === 0
                ? '24px'
                : 0,
            paddingBottom: 12,
          }}
        >
          <ActionStepHeader>
            <HeadLine>
              <h2 className="SectionTitleLeftLarge StepTitle">
                <FormattedMessage id="step" />
              </h2>
              <StepNumber>{item ? item.step : 1}</StepNumber>
            </HeadLine>
            <RightAlignedButtons>
              <Button disabled type="secondary" icon="arrow-up-light" />
              <Button disabled type="secondary" icon="arrow-down-light" />
              <Button disabled type="secondary" icon="cross" />
            </RightAlignedButtons>
          </ActionStepHeader>
          <ActionStepContent>
            <StepMedia step={item} allowedToModify={false} />
          </ActionStepContent>
          {!viewingLastEditedLanguage && (
            <TemporaryTranslation
              fromLanguageCode={sourceLanguage ?? ''}
              originalText={item?.translationUpdate?.sourceLanguageText ?? ''}
              translatedText={!isNewlyAdded ? tr?.prevTranslation ?? '' : '-'}
            />
          )}
          <StyledTextArea
            //onBlur={handleOnBlur}
            name="description"
            className={!description ? 'inputError' : ''}
            disabled={viewingLastEditedLanguage}
            onChange={onChangeStepLocal}
            value={description ? description : ''}
            placeholder={
              !description
                ? intl.formatMessage({ id: 'add_solution_step_hint' })
                : ''
            }
            showCount
            maxLength={500}
          />
        </Card>
        <div>
          <Line hidden={item.step === length} />
        </div>
      </>
    );
  }
};

export default InstructionStepTranslationCard;
