import React from 'react';
import { VictoryLabelProps } from 'victory';
import styled from 'styled-components';

const TickLabel = styled.div`
  font-family: Relative;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8c8c8c;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  word-wrap: break-word;
  width: 90%;
`;

interface TextWrappingTickLabelProps extends VictoryLabelProps {
  readonly width: number;
  readonly height: number;
}

const TextWrappingTickLabel: React.FC<TextWrappingTickLabelProps> = (props) => {
  const { text, x, y, width, height } = props;

  return (
    <foreignObject
      x={(x || 0) - width / 2}
      y={(y || 0) + 0}
      width={width}
      height={height}
    >
      {/*<TextWithEllipsisAndTooltip text={text as string}  lines={3} style={{textAlign: "center"}}/>*/}
      <TickLabel>{text as string}</TickLabel>
    </foreignObject>
  );
};

export default TextWrappingTickLabel;
