import {
  DataApiSortConfig,
  SortingDirection,
} from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { downtimeApi } from '../../api';
import { RootReducerInterface } from '../interfaces';
import { Actions } from './actions';
import AggregatedDowntime from '../../domain/downtime/AggregatedDowntime';
import { paginatedListFetchingWithAbort } from '@dev/base-web/dist/model/redux/helpers/thunks';

export const getAggregatedDowntimes = (
  startTime: Date,
  endTime: Date,
  filters?: FilterData[],
  sortKey?: string,
  sortDirection?: SortingDirection
) =>
  paginatedListFetchingWithAbort<AggregatedDowntime, RootReducerInterface>(
    0,
    async (accessToken, abort) => {
      let sortConfig = undefined;
      if (sortKey && sortDirection) {
        sortConfig = new DataApiSortConfig(sortKey, sortDirection);
      }

      const result = await downtimeApi.getAggregatedDowntimes(
        accessToken,
        startTime,
        endTime,
        filters ?? [],
        abort.signal,
        sortConfig
      );

      result.results.sort((a: AggregatedDowntime, b: AggregatedDowntime) => {
        return b.sumMs - a.sumMs;
      });

      return result;
    },
    (state) => state.data.aggregatedDowntimesState.aggregatedDowntimes,
    Actions.aggregatedDowntimes,
    true
  );

export const resetAggregatedDowntimes = () => (dispatch: any) => {
  dispatch(
    Actions.aggregatedDowntimes.resetList({
      result: { results: [], hasMoreResults: false },
    })
  );
};
