import { createActionsForList } from '@dev/base-web/dist/model/redux/helpers/actions';
import ParallelEventsEntry from '../../domain/parallel_events/parallel_events';

export const PARALLEL_EVENTS_ENTRY_KEY = 'PARALLEL_EVENTS_ENTRY_KEY';

export const Actions = {
  parallelEvents: createActionsForList<ParallelEventsEntry>(
    PARALLEL_EVENTS_ENTRY_KEY
  ),
};
