import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  Button,
  CenteredButtons,
} from '@dev/base-web/dist/view/components/global/button';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { connect } from 'react-redux';
import { EllipsisContainer } from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import {
  ClickableItem,
  ListMenu,
} from '@dev/base-web/dist/view/components/global/list_menu';
import { openInNewTab } from '@dev/base-web/dist/view/helpers/common';
import RelatedEventPopup from './related_event_popup';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import InstructionSideCard from '../instruction_side_card';
import { BasicEvent } from '@/model/redux/actions/interface.ts';
import { EventTypeIcon } from '@/components/items/EventTypeIcon.tsx';
import { CONFIG as EVENTS_CONFIG } from '../../../../events/interfaces';
import { RootReducerInterface } from '@/model/redux/interfaces.ts';
import { removeEventFromInstruction } from '@/model/redux/instruction_definition/thunks.ts';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';

const RelatedEventListCard = styled(InstructionSideCard)`
  width: 100%;
  max-height: 360px;
  position: relative;
`;

const LinearGradient = styled.div<{ showAddButton: boolean }>`
  position: absolute;
  width: 100%;
  pointer-events: none;
  bottom: ${(showAddButton) => (showAddButton ? '55px' : '15px')};
  left: 0;
  height: 64px;
  border-radius: 8px;
  z-index: 1;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9) 45%,
    white
  );
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 16px 8px;

  span {
    width: 230px;
  }
`;

const StyledListMenu = styled(ListMenu)`
  margin-bottom: 18px;
`;

const Span = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.43;
  color: #939393;
`;

const EventLabel = styled.span`
  padding-left: 8px;
  padding-right: 6px;
  width: calc(100% - 32px);
`;

const ClickableIconContainer = styled.div`
  cursor: pointer;
`;

const StyledClickableItem = styled(ClickableItem)`
  width: 100%;
  margin-right: 8px;
`;

const ScrollArea = styled.div`
  max-height: 280px;
  overflow: auto;
  //margin-right: -20px;
`;

interface Props {
  readonly showAddEventsModal: (visible: boolean) => void;
  readonly title: string;
  readonly events: BasicEvent[];
  readonly deletedItemCallback: (id: string) => void;
  readonly removeEventFromInstruction: (
    instructionId: string,
    eventId: string
  ) => void;
  readonly itemId?: string;
  readonly isNewInstruction: boolean;
  readonly canEdit: boolean;
  readonly canDelete: boolean;
}

const InstructionRelatedEventsCard = (props: Props) => {
  const deleteItem = (itemId: string, eventId: string) => {
    props.removeEventFromInstruction(itemId, eventId);
    props.deletedItemCallback(eventId);
  };

  const intl = useIntl();
  const theme = useTheme();

  return (
    <RelatedEventListCard
      title={props.title}
      bottomButton={
        !props.isNewInstruction && (
          <CenteredButtons style={{ zIndex: 2 }}>
            <Button
              type="tertiary"
              icon="plus"
              label="add_events"
              onClick={() => props.showAddEventsModal(true)}
              disabled={!props.canEdit}
            />
          </CenteredButtons>
        )
      }
    >
      <ScrollArea>
        <StyledListMenu>
          {props.events && props.events.length > 0 ? (
            props.events
              .filter((e) => e.name)
              .map((event, index) => {
                const eventName = intl.formatMessage({
                  id: event.name,
                  defaultMessage: event.name,
                });
                return (
                  <RelatedEventPopup
                    trigger={
                      <ListItem key={index}>
                        {event.type && (
                          <EventTypeIcon
                            type={event.type}
                            size={16}
                            iconStyle={{ marginRight: 6 }}
                          />
                        )}
                        <StyledClickableItem
                          onClick={() =>
                            openInNewTab(
                              `${EVENTS_CONFIG.url.baseUrl}${EVENTS_CONFIG.url.path}/${event.id}`
                            )
                          }
                        >
                          <EventLabel>
                            <EllipsisContainer lines={1}>
                              {eventName}
                            </EllipsisContainer>
                            {event.manufacturingEntities?.length === 1 && (
                              <div
                                style={{
                                  color: theme.colors.brownGrey,
                                  fontStyle: 'italic',
                                }}
                              >
                                <ManufacturingEntityHierarchyElement
                                  manufacturingEntity={
                                    event.manufacturingEntities[0]
                                  }
                                  hideHiddenTypes
                                  hideTooltip
                                  lines={1}
                                />
                              </div>
                            )}
                          </EventLabel>
                        </StyledClickableItem>
                        {!props.isNewInstruction &&
                          props.canDelete &&
                          props.itemId && (
                            <ClickableIconContainer
                              onClick={() =>
                                props.itemId &&
                                deleteItem(props.itemId, event.id)
                              }
                            >
                              <Icon name="cross" color={'#939393'} size={14} />
                            </ClickableIconContainer>
                          )}
                      </ListItem>
                    }
                    event={event}
                  />
                );
              })
          ) : (
            <Span>
              <FormattedMessage id="add_action_to_events" />
            </Span>
          )}
        </StyledListMenu>
      </ScrollArea>
      {props.events.length > 2 && (
        <LinearGradient showAddButton={!props.isNewInstruction} />
      )}
    </RelatedEventListCard>
  );
};
const mapStateToProps = (_state: RootReducerInterface) => {
  return {};
};

export default connect(mapStateToProps, {
  removeEventFromInstruction,
})(InstructionRelatedEventsCard);
