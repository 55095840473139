import axios from 'axios';
import {
  BASE_URL,
  DATA_API_BASE_URL,
  DARWIN_API_BASE_URL,
} from './common/config';
import ClientConfig from '@dev/base-web/dist/model/api/common/client_config';
import { parseIfBlobError } from '@dev/base-web/dist/model/api/error/error_parser';

export const dataApiClient = axios.create({ baseURL: DATA_API_BASE_URL });
dataApiClient.interceptors.response.use(
  (response) => response,
  parseIfBlobError
);
export const dataApiClientWithoutParameters = axios.create({
  baseURL: DATA_API_BASE_URL,
});
dataApiClientWithoutParameters.interceptors.response.use(
  (response) => response,
  parseIfBlobError
);
export const darwinApiClient = axios.create({ baseURL: DARWIN_API_BASE_URL });
darwinApiClient.interceptors.response.use(
  (response) => response,
  parseIfBlobError
);
export const baseApiClient = axios.create({ baseURL: BASE_URL });
baseApiClient.interceptors.response.use(
  (response) => response,
  parseIfBlobError
);
export const baseApiClientWithoutParameters = axios.create({
  baseURL: BASE_URL,
});
baseApiClientWithoutParameters.interceptors.response.use(
  (response) => response,
  parseIfBlobError
);

export const clientConfigs: ClientConfig[] = [
  { axiosClient: dataApiClient, baseUrl: DATA_API_BASE_URL },
  { axiosClient: darwinApiClient, baseUrl: DARWIN_API_BASE_URL },
  { axiosClient: baseApiClient, baseUrl: BASE_URL },
];
