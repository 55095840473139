import EditImage from './edit_image';
import React, { useEffect, useState } from 'react';
import { Crop } from 'react-image-crop';
import { Image as Img } from '../../../model/redux/actions/interface';
import { useApiBlob } from '../../Media';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import styled from 'styled-components';

const ApiImageContainer = styled.div`
  position: relative;
`;

const HintContainer = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  z-index: 2;
`;

interface EditApiImageProps {
  item: Img;
  onDelete: (imgUrl: string) => void;
  onChange: (image: Img, picture: File) => void;
  onRevert: (image: Img, asNew: boolean) => void;
  forceCropAspect?: number;
  forceCrop?: Crop;
}

const EditApiImage = ({
  item,
  onChange,
  onDelete,
  onRevert,
  forceCropAspect,
  forceCrop,
}: EditApiImageProps) => {
  const [error, setError] = useState(false);
  const onError = (_e: unknown) => {
    setError(true);
  };
  const [thumbnailContent, isThumbnailLoading] = useApiBlob(
    item.urls.thumb,
    false
  );
  const [fullContent, isLoading, hasError] = useApiBlob(
    item.urls.full,
    false,
    onError
  );

  useEffect(() => {
    setError(false);
  }, [item.urls.full]);

  const onDeletePressed = () => {
    onDelete(item.urls.full);
  };

  const onPictureUpdated = (picture: File) => {
    onChange(item, picture);
  };

  const onPictureRevert = (asNew: boolean) => {
    onRevert(item, asNew);
  };

  return (
    <ApiImageContainer>
      {error && (
        <HintContainer>
          <Hint messageKey={'error_loading_media'} />
        </HintContainer>
      )}
      <EditImage
        onDelete={onDeletePressed}
        onChange={onPictureUpdated}
        onRevert={onPictureRevert}
        fullContent={fullContent}
        thumbnailContent={thumbnailContent}
        forceCropAspect={forceCropAspect}
        forceCrop={forceCrop}
        isLoading={isThumbnailLoading || isLoading}
        hasError={hasError}
      />
    </ApiImageContainer>
  );
};

export default EditApiImage;
