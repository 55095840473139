import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { DropdownProps, Button as SemanticButton } from 'semantic-ui-react';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import {
  DisplayedDecision,
  DisplayedStep,
} from '../../model/domain/instruction/step';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { areStandardDecisionOptions } from '../../model/domain/instruction/helper';

export const ActionStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DecisionHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
`;

export const DecisionFooterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 26px;
`;

export const AbsoluteDiv = styled.div`
  &&&&& {
    position: absolute;
    text-align: center;
    width: 145px;
  }
`;

const AddButton = styled(SemanticButton)`
  &&&&& {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #e8ebea;
    padding: 0;
    margin: 0;
    right: -6px;
    top: -12px;
    border: solid 1px rgba(36, 36, 36, 0.05);
  }
`;

const DeleteButton = styled(AddButton)`
  &&&&& {
    cursor: pointer;
  }
`;

const StyledIcon = styled(Icon)`
  margin: 0px !important;
`;

const AddMark = () => {
  return (
    <AddButton>
      <StyledIcon name="plus" size={16} color="black" />
    </AddButton>
  );
};

const DeleteMark = ({ onClick }: Readonly<{ onClick: () => void }>) => {
  const onClickWrapper = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <DeleteButton onClick={onClickWrapper}>
      <StyledIcon name="cross" size={16} color="black" />
    </DeleteButton>
  );
};

export const Line = styled.div<{ selected: boolean }>`
  &&&& {
    position: absolute;
    width: 1px;
    height: 48px;
    margin: 20px 70.5px 0px 70.5px;
    border: solid 2px hsl(0, 0%, 59%);
    visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
  }
`;

const DecisionText = styled.span`
  font-size: 14px;
  line-height: 18px;
  font-family: Relative;
  display: block;
  width: 100%;
  padding: 0px ${({ theme }) => theme.buttons.medium.padding}px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: none;
`;

interface DecisionContainerProps {
  inactive?: boolean;
}

const DecisionContainer = styled.div<DecisionContainerProps>`
  display: flex;
  height: ${({ theme }) => theme.buttons.medium.height}px;
  max-width: 250px;
  border-radius: ${({ theme }) => theme.buttons.medium.borderRadius}px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.buttons.medium.fontSize}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  opacity: ${({ inactive }) => (inactive ? 0.75 : 1)};

  /*
  margin-left: 10px;

  &:first-child {
    margin-left: 0px;
  }
  */

  &.disabled {
    opacity: 0.25;
    cursor: default;
  }

  &.green {
    background-color: #e9f5f5;
    border: solid 1px #d2edec;

    &:not(.disabled):hover {
      border-color: #b5e3e2;
      background-color: #d2edec;
    }

    ${DecisionText} {
      color: #0da6a0;
    }
  }

  &.green.selected {
    background-color: #0da6a0;
    border: solid 1px #0da6a0;

    ${DecisionText} {
      color: #ffffff;
    }
  }

  &.red {
    background-color: #fef0f2;
    border: solid 1px #fcdfe2;

    &:not(.disabled):hover {
      border-color: #ffcdd2;
      background-color: #fcdfe2;
    }

    ${DecisionText} {
      color: #e30021;
    }
  }

  &.red.selected {
    background-color: #e30021;
    border: solid 1px #e30021;

    ${DecisionText} {
      color: #ffffff;
    }
  }

  &.custom {
    padding-left: 26px;
    padding-right: 26px;
    background-color: #ffffff;
    border: ${({ theme }) => theme.buttons.secondary.border};
    box-shadow: ${({ theme }) => theme.buttons.secondary.shadow} !important;

    &:not(.disabled):hover {
      border: solid 1px #ffcdd2;
      background-color: ${({ theme }) => theme.buttons.secondary.hovered.color};

      ${DecisionText} {
        color: ${({ theme }) => theme.buttons.secondary.hovered.textColor};
      }
    }

    ${DecisionText} {
      color: ${({ theme }) => theme.buttons.secondary.textColor};
    }
  }

  &.custom.selected {
    background-color: ${({ theme }) => theme.colors.primary};

    ${DecisionText} {
      color: ${({ theme }) => theme.buttons.secondary.active.textColor};
    }
  }
`;

export type DecisionBtnProps = Readonly<{
  text: string;
  inactive?: boolean;
  disabled?: boolean;
  selected: boolean;
  onClick: () => void;
  addStep?: boolean;
  onDelete?: () => void;
}> &
  Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>;

export const DecisionBtn = ({
  type,
  text,
  onClick,
  selected,
  inactive,
  disabled,
  addStep,
  onDelete,
  className,
}: DecisionBtnProps & { type: 'green' | 'red' | 'custom' }) => {
  return (
    <DecisionContainer
      className={`${type}${disabled ? ' disabled' : ''}${
        selected ? ' selected' : ''
      } ${className ?? ''}`}
      inactive={inactive}
      onClick={onClick}
    >
      {addStep && <AddMark />}
      {onDelete !== undefined && <DeleteMark onClick={onDelete} />}
      {addStep !== undefined && (
        <AbsoluteDiv>
          <Line selected={selected} />
        </AbsoluteDiv>
      )}
      <DecisionText>
        <FormattedMessage id={text} />
      </DecisionText>
    </DecisionContainer>
  );
};

export const FixedWidthDecisionBtn = styled(DecisionBtn)`
  width: 145px;
`;

export const DecisionBtnRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const DecisionBtnEditRowContainer = styled(DecisionBtnRowContainer)`
  justify-content: center;
`;

interface DecisionButtonsProps {
  readonly value: DisplayedStep;
  readonly onSelect: (s: DisplayedStep, d: DisplayedDecision) => void;
}

const StandardDecisions = ({
  value,
  onSelect,
}: Readonly<DecisionButtonsProps>) => {
  const [yesDecision, noDecision] = value.decisionOptions;

  return (
    <>
      <FixedWidthDecisionBtn
        type="green"
        text={yesDecision.orig.name}
        inactive={false}
        selected={yesDecision.isSelected}
        onClick={() => onSelect(value, yesDecision)}
      />
      <FixedWidthDecisionBtn
        type="red"
        text={noDecision.orig.name}
        inactive={false}
        selected={noDecision.isSelected}
        onClick={() => onSelect(value, noDecision)}
      />
    </>
  );
};

const DecisionDropdown = ({
  value,
  onSelect,
}: Readonly<DecisionButtonsProps>) => {
  const options: DropdownProps['options'] = useMemo(
    () =>
      value.decisionOptions.map((decision) => ({
        value: decision.orig.id,
        text: decision.orig.name,
      })),
    [value]
  );

  const onDropdownChange: DropdownProps['onChange'] = (_, { value: id }) => {
    const decision = value.decisionOptions.find((d) => d.orig.id === id);

    if (decision !== undefined) {
      onSelect(value, decision);
    }
  };

  return (
    <StyledDropDown
      selection
      fluid
      value={value.decisionOptions.find((d) => d.isSelected)?.orig.id}
      options={options}
      onChange={onDropdownChange}
    />
  );
};

const CustomDecisions = ({
  value,
  onSelect,
}: Readonly<DecisionButtonsProps>) => {
  if (value.decisionOptions.length <= 3) {
    return (
      <>
        {value.decisionOptions.map((decision) => (
          <DecisionBtn
            type="custom"
            key={decision.orig.id}
            text={decision.orig.name}
            inactive={false}
            selected={decision.isSelected}
            onClick={() => onSelect(value, decision)}
          />
        ))}
      </>
    );
  } else {
    return <DecisionDropdown value={value} onSelect={onSelect} />;
  }
};

export interface DecisionBtnRowProps {
  value: DisplayedStep;
  onSelect: (s: DisplayedStep, d: DisplayedDecision) => void;
}

const IconWrapper = styled.div`
  margin-right: 16px;
  margin-left: 30px;
`;

export const DecisionBtnRow = ({ value, onSelect }: DecisionBtnRowProps) => {
  if (value.decisionOptions.length === 0) {
    return null;
  }

  const hasStandardDecisions = areStandardDecisionOptions(
    value.decisionOptions.map((d) => d.orig)
  );

  return (
    <DecisionBtnRowContainer>
      <IconWrapper>
        <Icon color="#242424" name="decision" size={28} />
      </IconWrapper>
      {hasStandardDecisions ? (
        <StandardDecisions value={value} onSelect={onSelect} />
      ) : (
        <CustomDecisions value={value} onSelect={onSelect} />
      )}
    </DecisionBtnRowContainer>
  );
};
