import {
  CenterAlignedTableCell,
  LeftAlignedTableCell,
  StyledRow,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import React from 'react';
import Event from '../../../model/domain/event/event_definition';
import { StyledRightColumn } from '../../../components/StyledComponents';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { EVENTS_ROUTE_KEY } from '../../events/interfaces';
import { useIntl } from 'react-intl';
import { useEventTypeLook } from '@dev/base-web/dist/view/components/global/common';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';

const EventName = styled.div`
  font-family: Relative;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #242424;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const StyledEventRow = styled(StyledRow)`
  height: 64px;
`;

interface EventRowProps {
  data: Event;
}

const EventRowSimplified: React.FC<EventRowProps> = ({ data }) => {
  const {
    id,
    name,
    type,
    actionCount,
    commentCount,
    manufacturingEntities,
    occurrenceCount,
  } = data;
  const navigate = useNavigate();
  const intl = useIntl();
  const { color, icon } = useEventTypeLook(type);
  const locationSet = new Set(
    manufacturingEntities.map((entity) => entity.name)
  );
  const locations = [...locationSet].join(', ');

  const onRowSelected = () => {
    navigate(`/${EVENTS_ROUTE_KEY}/${id}`);
  };

  return (
    <StyledEventRow onClick={onRowSelected}>
      <CenterAlignedTableCell>
        <Icon name={icon} color={color} size={14} />
      </CenterAlignedTableCell>
      <LeftAlignedTableCell>
        <EventName>
          <TextWithEllipsisAndTooltip
            text={intl.formatMessage({ id: name })}
            lines={2}
            style={{ width: 'calc(100% - 32px)' }}
          />
        </EventName>
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        <TextWithEllipsisAndTooltip text={locations} lines={2} />
      </LeftAlignedTableCell>
      <CenterAlignedTableCell>{occurrenceCount}</CenterAlignedTableCell>
      <CenterAlignedTableCell>{actionCount}</CenterAlignedTableCell>
      <CenterAlignedTableCell>{commentCount}</CenterAlignedTableCell>
      <LeftAlignedTableCell>
        <StyledRightColumn>
          <Icon name={'arrow-right-bold'} color={'#8c8c8c'} size={16} />
        </StyledRightColumn>
      </LeftAlignedTableCell>
    </StyledEventRow>
  );
};

export default EventRowSimplified;
