import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const TitleContainer = styled.div`
  display: flex;
`;

const CountIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: -8px;
  margin-left: -3px;

  padding-left: 4px;
  padding-right: 4px;

  height: 16px;
  min-width: 16px;
  border-radius: 16px;

  background-color: #ff5900;

  font-size: 10px;
  color: white;

  user-select: none;
`;

interface Props {
  readonly count: number;
}

const OpenDowntimeReportsListCardTitle: React.FC<Props> = ({ count }) => {
  return (
    <TitleContainer>
      <FormattedMessage id="open_downtime_reports" />
      {count > 0 && <CountIndicator>{count}</CountIndicator>}
    </TitleContainer>
  );
};

export default OpenDowntimeReportsListCardTitle;
