import { Dropdown, Icon } from 'semantic-ui-react';
import React from 'react';
import styled from 'styled-components';
import { LanguageTitle } from './styled_components';

const dropdownTriggerWidthSmall = 50;
const dropdownTriggerWidth = 80;
const dropdownWidth = 135;

const StyledDropdown = styled(Dropdown)`
  &&&&&&& {
    font-family: Relative;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #242424;

    .visible.menu.transition {
      margin-top: ${4}px;
      border-top-right-radius: 0.2857rem;
      border-top-left-radius: 0.2857rem;
    }
  }
`;

const DropdownTrigger = styled.div<{ isFilter?: boolean }>`
  width: ${({ isFilter }) =>
    isFilter ? dropdownTriggerWidthSmall : dropdownTriggerWidth}px;
  height: ${({ isFilter }) =>
    isFilter ? '30px !important' : '38px !important'};
  border-radius: 0 4px 4px 0;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-left: none;
  background-color: #ffffff;
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: ${({ isFilter }) => (isFilter ? 1 : 1.43)};
  letter-spacing: normal;
  color: #242424;
  padding: 8px 22px 8px 8px;
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  max-height: 200px;
  overflow-y: auto;
`;

const MenuItem = styled(Dropdown.Item)`
  &&&&& {
    padding: 16px !important;
    width: ${dropdownWidth}px;
    font-family: Relative;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #242424;
  }
`;

const IconContainer = styled.div<{ isFilter?: boolean }>`
  position: absolute;
  right: 6px;
  top: 10px;
  ${({ isFilter }) => (isFilter ? 'display: none;' : '')}
`;

interface LanguageDropdownProps {
  readonly selectableLanguages: string[];
  readonly selectedLanguage: string;
  readonly setSelectedLanguage: (language: string) => void;
  readonly upward?: boolean;
  readonly disabled?: boolean;
}

const LanguageDropdown = ({
  selectableLanguages,
  selectedLanguage,
  setSelectedLanguage,
  upward,
  disabled,
}: LanguageDropdownProps) => {
  return (
    <StyledDropdown
      icon={
        <IconContainer>
          <Icon name="angle down" color="grey" />
        </IconContainer>
      }
      disabled={disabled}
      trigger={
        <DropdownTrigger>{selectedLanguage.toUpperCase()}</DropdownTrigger>
      }
      upward={upward}
      direction={'left'}
    >
      <StyledDropdownMenu>
        {selectableLanguages.map((option) => (
          <MenuItem
            key={option}
            onClick={() => setSelectedLanguage(option.toUpperCase())}
          >
            <LanguageTitle language={option} />
          </MenuItem>
        ))}
      </StyledDropdownMenu>
    </StyledDropdown>
  );
};

export default LanguageDropdown;
