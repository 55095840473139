import { Form, InputOnChangeData } from 'semantic-ui-react';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';
import { PasswordMeter } from '../password_meter';
import Alert, {
  AlertSeverity,
} from '@dev/base-web/dist/view/components/global/alert';
import React from 'react';
import styled from 'styled-components';
import InputWithCount from '@dev/base-web/dist/view/components/inputs/input_with_count';
import UserUpdateDTO from '@dev/base-web/dist/model/domain/user/user_update_dto';

const TextInput = styled(InputWithCount)`
  margin-top: 8px;
  width: 100%;
`;

interface PasswordFormProps {
  readonly user: UserUpdateDTO;
  readonly isUserEditable?: boolean;
  readonly isAdUser: boolean;
  readonly password?: string;
  readonly confirmPassword?: string;
  readonly passwordShown: boolean;
  readonly confirmPasswordShown: boolean;
  readonly setPasswordStrength: (strength: number) => void;
  readonly setPassword: (password?: string) => void;
  readonly setConfirmPassword: (password?: string) => void;
  readonly isPasswordWeak: boolean;
  readonly isPasswordValid: boolean;
  readonly setPasswordShown: (value: boolean) => void;
  readonly setConfirmPasswordShown: (value: boolean) => void;
  readonly dark?: boolean;
}

const PasswordForm = ({
  password,
  confirmPassword,
  passwordShown,
  confirmPasswordShown,
  setPasswordStrength,
  setPassword,
  setConfirmPassword,
  user,
  isUserEditable,
  isAdUser,
  isPasswordValid,
  isPasswordWeak,
  setPasswordShown,
  setConfirmPasswordShown,
  dark,
}: PasswordFormProps) => {
  const onPasswordChanged = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    const password = data.value.length > 0 ? data.value : undefined;

    setPassword(password);
  };

  const onPasswordConfirmationChanged = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    const passwordConfirmation = data.value.length > 0 ? data.value : undefined;

    setConfirmPassword(passwordConfirmation);
  };

  return (
    <>
      <Form.Field error={!(isPasswordValid && !isPasswordWeak)}>
        <LabelWithHint label="login_password" dark={dark} />
        <PasswordMeter
          onStrengthChanged={setPasswordStrength}
          password={password}
          username={user?.username}
          barElementsCount={5}
        />
        <TextInput
          value={password || ''}
          disabled={!isUserEditable || isAdUser}
          onChange={onPasswordChanged}
          error={!(isPasswordValid && !isPasswordWeak)}
          type={passwordShown ? 'text' : 'password'}
          id={'new-password'}
          autocomplete="new-password"
          showInlineButton={!!password}
          inlineButtonIcon={passwordShown ? 'invisible' : 'visible'}
          onInlineButtonClicked={() => setPasswordShown(!passwordShown)}
        />
      </Form.Field>
      <Form.Field error={!isPasswordValid}>
        <LabelWithHint label="password_confirm" dark={dark} />
        <TextInput
          value={confirmPassword || ''}
          disabled={!isUserEditable || isAdUser}
          onChange={onPasswordConfirmationChanged}
          error={!isPasswordValid}
          type={confirmPasswordShown ? 'text' : 'password'}
          id={'new-password'}
          autocomplete="new-password"
          showInlineButton={!!confirmPassword}
          inlineButtonIcon={confirmPasswordShown ? 'invisible' : 'visible'}
          onInlineButtonClicked={() =>
            setConfirmPasswordShown(!confirmPasswordShown)
          }
        />
      </Form.Field>
      {password && isPasswordWeak && (
        <div style={{ marginBottom: 24 }}>
          <Alert
            severity={AlertSeverity.WARNING}
            textId="password_weak"
            textValues={{}}
          />
        </div>
      )}
      {password !== confirmPassword && (
        <div style={{ marginBottom: 24 }}>
          <Alert
            severity={AlertSeverity.WARNING}
            textId="passwords_different"
            textValues={{}}
          />
        </div>
      )}
    </>
  );
};

export default PasswordForm;
