import { FormattedMessage } from 'react-intl';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import React, { useEffect, useMemo, useState } from 'react';
import {
  DropdownWithLabelContainer,
  LabelContainer,
} from '../styled_components';
import { DropdownProps } from 'semantic-ui-react';
import AddSolutionDialog from './add_solution_dialog';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import DowntimeEvent from '@dev/base-web/dist/model/domain/report/DowntimeEvent';

interface DowntimeSolutionSelectorProps {
  readonly selectedPossibleEvent?: DowntimeEvent;
  readonly selectedActionName?: string;
  readonly onActionSelected: (actionName: string) => void;
  readonly disabled: boolean;
}

const NewSolutionElement = (props: {
  newSolutionTitle: string | undefined;
  onClick: () => void;
}) => {
  return !props.newSolutionTitle ? (
    <Button
      type="tertiary"
      icon="plus"
      label="add_new_solution"
      size="small"
      noMargin
      onClick={props.onClick}
    />
  ) : (
    <Button
      type="tertiary"
      icon="edit"
      label={props.newSolutionTitle}
      size="small"
      noMargin
      iconPosition="right"
      onClick={props.onClick}
    />
  );
};

const DowntimeSolutionSelector = ({
  onActionSelected,
  selectedActionName,
  selectedPossibleEvent,
  disabled,
}: DowntimeSolutionSelectorProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [newSolutionTitle, setNewSolutionTitle] = useState<
    string | undefined
  >();

  const actionOptions = useMemo(() => {
    if (selectedPossibleEvent) {
      return [
        {
          value: null,
          text: '-',
          data: null,
        },
        ...selectedPossibleEvent.possibleActions.map((action) => ({
          value: action.name,
          text: action.name,
          data: action,
        })),
        {
          children: (
            <NewSolutionElement
              newSolutionTitle={newSolutionTitle}
              onClick={() => setModalOpen(true)}
            />
          ),
          value: newSolutionTitle,
          text: newSolutionTitle,
        },
      ].filter((a) => a !== undefined);
    } else {
      return [];
    }
  }, [selectedPossibleEvent, newSolutionTitle]);

  useEffect(() => {
    if (newSolutionTitle) onActionSelected(newSolutionTitle);
  }, [newSolutionTitle]);

  const onSelected = (
    _event: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    const actionName = value as string;
    onActionSelected(actionName);
  };

  const onSelectNewSolutionTitle = (title: string) => {
    setNewSolutionTitle(title);
    setModalOpen(false);
  };

  return (
    <DropdownWithLabelContainer>
      <LabelContainer>
        <FormattedMessage id={'how_was_it_resolved'} />
      </LabelContainer>
      <StyledDropDown
        fluid
        selection
        value={selectedActionName}
        options={actionOptions}
        onChange={onSelected}
        placeholder={'-'}
        disabled={!selectedPossibleEvent || disabled}
        search
      />
      <AddSolutionDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        existingSolutions={
          selectedPossibleEvent ? selectedPossibleEvent.possibleActions : []
        }
        addNewSolution={onSelectNewSolutionTitle}
        newSolutionName={newSolutionTitle}
      />
    </DropdownWithLabelContainer>
  );
};

export default DowntimeSolutionSelector;
