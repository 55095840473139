import React from 'react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import { IntervalGroup } from '../../../model/domain/report/manufacturing_entity_summary_interval';

const LabelCell = styled.td`
  font-weight: bold;
`;

const ValueCell = styled.td`
  padding-left: 8px;
`;

interface PopupProps {
  interval?: IntervalGroup;
  trigger: any;
}

const IntervalTypePopup = (props: PopupProps) => {
  const { formatDate } = useLocalizedDateFormatters();

  const end = props.interval
    ? formatDate(
        new Date().valueOf() < props.interval.end
          ? new Date().valueOf()
          : props.interval.end,
        'Pp'
      )
    : null;

  return (
    <Popup
      trigger={props.trigger}
      hoverable
      flowing
      position="bottom left"
      disabled={!props.interval}
    >
      {props.interval && (
        <table>
          <tr>
            <LabelCell>
              <FormattedMessage
                id={
                  props.interval.type === 'PRODUCED_ORDER'
                    ? 'order'
                    : props.interval.type === 'PRODUCTION_SHIFT'
                    ? 'shift'
                    : 'day'
                }
              />
            </LabelCell>
            <ValueCell>{props.interval.name}</ValueCell>
          </tr>
          <tr>
            <LabelCell>
              <FormattedMessage id={'time_span'} />
            </LabelCell>
            <ValueCell>
              {`${formatDate(props.interval.start, 'Pp')} - 
                ${end}`}
            </ValueCell>
          </tr>
        </table>
      )}
    </Popup>
  );
};

export default IntervalTypePopup;
