import { Actions } from './actions';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import {
  DataApiSortConfig,
  SortingDirection,
} from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import { Dispatch } from 'redux';
import { RootReducerInterface } from '../interfaces';
import { mainEntityKpiApi, productionProgressApi } from '../../api';
import { TimeSpan } from '@dev/base-web/dist/model/domain/common/time_span';

export const getAllMainEntityKpis =
  (
    filters: FilterData[],
    groupBy: string[],
    sortingKey?: string,
    sorting?: SortingDirection
  ) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const abortController = new AbortController();
    const { token } = getState().authenticationState.authentication;

    dispatch(Actions.mainEntityKpis.meta.startLoading({ abortController }));

    let sortConfig: DataApiSortConfig | undefined = undefined;
    if (sortingKey && sorting) {
      sortConfig = new DataApiSortConfig(sortingKey, sorting);
    }

    try {
      const result = await mainEntityKpiApi.getAllMainEntityKpis(
        filters,
        groupBy,
        token.accessToken,
        abortController.signal,
        sortConfig
      );

      dispatch(Actions.mainEntityKpis.loadingListSuccessful(result));
      dispatch(Actions.mainEntityKpis.meta.endLoading({ filters, sortConfig }));
    } catch (error) {
      dispatch(Actions.mainEntityKpis.meta.endLoading());
      dispatch(Actions.mainEntityKpis.meta.loadingFailed({ error }));
    }
  };

export const getProductionProgress =
  (manufacturingEntityId: string, timeSpan: TimeSpan) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const abortController = new AbortController();
    const { token } = getState().authenticationState.authentication;

    dispatch(Actions.productionProgress.meta.startLoading({ abortController }));

    try {
      const result = await productionProgressApi.getProductionProgress(
        token.accessToken,
        manufacturingEntityId,
        timeSpan.start,
        timeSpan.end,
        abortController.signal
      );

      result.cumulativeProducedProducts[timeSpan.start] = {
        cumulativeActualParts: 0,
        cumulativeTheorParts: 0,
        plannedStopEvents: [],
      };

      dispatch(Actions.productionProgress.loadingItemSuccessful(result));
      dispatch(Actions.productionProgress.meta.endLoading());
    } catch (error) {
      dispatch(Actions.productionProgress.meta.endLoading());
      dispatch(Actions.productionProgress.reset());
      dispatch(Actions.productionProgress.meta.loadingFailed({ error }));
    }
  };
