import React from 'react';
import styled from 'styled-components';
import { Image as Img } from '../../../model/redux/actions/interface';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { EditMedia } from './edit_media';
import EditApiImage from './edit_api_image';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import MediaType from '../../../model/domain/media/media_type';

const FILTER_ICON_COLOR = '#242424 !important ';

const FilterTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 8px 0 8px;
  padding: 8px 8px 0 8px;
  box-sizing: border-box;
  box-shadow: inset 0 1px 0 0 #efefef;
`;

const Text = styled.div`
  display: block;
  margin: 0 12px 0 0;
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const FilterTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 210px;
  text-overflow: ellipsis;
  height: 32px;
  border-radius: 8px;
  border: solid 1px #efefef;
  font-family: Relative;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #242424;
  margin-right: 8px;
  margin-bottom: 4px;
`;

const FilterTagIcon = styled(Icon)`
  &&&&&& {
    margin: 0 4px;
    height: 12px;
  }
`;
const EditMediaWrapper = styled.div`
  height: 35vh;
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  justify-content: flex-start;
`;
const MediaDetailWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  border: solid 1px #828282;
`;
interface Props {
  item: Img;
  onDelete: (imgUrl: string) => void;
  onChange: (image: Img, picture: File) => void;
  onRevert: (image: Img, asNew: boolean) => void;
}

const MediaDetailView: React.FC<Props> = ({
  item,
  onDelete,
  onChange,
  onRevert,
}) => {
  const { formatDate } = useLocalizedDateFormatters();
  const mediaType: MediaType = item.urls.fileType;

  return (
    <>
      <MediaDetailWrapper>
        {mediaType === 'PICTURE' ? (
          <EditApiImage
            item={item}
            onDelete={onDelete}
            onChange={onChange}
            onRevert={onRevert}
          />
        ) : (
          <EditMediaWrapper>
            <EditMedia item={item} onDelete={onDelete} />
          </EditMediaWrapper>
        )}
        <FilterTagsContainer>
          <FilterTagContainer>
            <FilterTagIcon name={'time'} color={FILTER_ICON_COLOR} size={16} />
            <Text>
              {item.creationTimestamp === 0
                ? formatDate(Date.now(), 'P')
                : formatDate(item.creationTimestamp, 'P')}
            </Text>
          </FilterTagContainer>
          {item.manufacturingEntities.join(', ').length > 4 ? (
            <FilterTagContainer>
              <FilterTagIcon
                name={'location-bold'}
                color={FILTER_ICON_COLOR}
                size={16}
              />
              <Text>{item.manufacturingEntities.join(', ')}</Text>
            </FilterTagContainer>
          ) : null}
          {item.createdBy && (
            <FilterTagContainer>
              <FilterTagIcon
                name={'user'}
                color={FILTER_ICON_COLOR}
                size={16}
              />
              <Text>
                {item.createdBy.firstName == null ||
                item.createdBy.firstName.trim() === ''
                  ? item.createdBy.username
                  : `${item.createdBy.firstName} ${item.createdBy.lastName}`}
              </Text>
            </FilterTagContainer>
          )}
          {item.initialFilename && (
            <FilterTagContainer>
              <FilterTagIcon
                name={'list'}
                color={FILTER_ICON_COLOR}
                size={16}
              />
              <Text>{item.initialFilename}</Text>
            </FilterTagContainer>
          )}
        </FilterTagsContainer>
      </MediaDetailWrapper>
    </>
  );
};

export default MediaDetailView;
