import { RootReducerInterface } from '../../../model/redux/interfaces';
import { connect } from 'react-redux';
import ReportOverviewDetails from './view';
import {
  ReportDetailsDispatchProps,
  ReportDetailsStoreProps,
} from './interface';
import {
  downtimeReasonsThunks,
  downtimesReportThunks,
} from '../../../model/redux/report/downtime/thunks';
import shiftReportThunks from '../../../model/redux/report/overview/thunks';
import ManufacturingEntityThunks from '../../../model/redux/manufacturing_entity/thunks';
import DurationThunks from '../../../model/redux/durations/thunks';
import Thunks from '../../../model/redux/events/thunks';
import { getProductionProgress } from '../../../model/redux/kpis/thunk';

const mapStateToProps = (
  state: RootReducerInterface
): ReportDetailsStoreProps => {
  return {
    downtimesInReport: state.data.downtimes.downtimesInReport.data,
    downtimesInReportLoadingError:
      state.data.downtimes.downtimesInReport.meta.error,
    downtimesInReportLoadingInProgress:
      state.data.downtimes.downtimesInReport.meta.loadingInProgress,
    reportOverview: state.data.reportOverview.selectedReportOverview.data,
    reportOverviewLoadingInProgress:
      state.data.reportOverview.selectedReportOverview.meta.loadingInProgress,
    selectedDowntime: state.data.downtimes.selectedDowntime.data,
    selectedDowntimeLoadingInProgress:
      state.data.downtimes.selectedDowntime.meta.loadingInProgress,
    manufacturingEntities:
      state.data.manufacturingEntityState.manufacturingEntities.data,
    manufacturingEntityMeta:
      state.data.manufacturingEntityState.manufacturingEntities.meta,
    showManufacturingEntityHierarchy:
      state.configurationState.config.data?.showManufacturingEntityHierarchy
        .value,
    downtimeReasons: state.data.downtimes.downtimeReasons.data,
    downtimeReasonsLoadingInProgress:
      state.data.downtimes.downtimeReasons.meta.loadingInProgress,
    downtimeUpdate: state.data.downtimes.downtimeUpdate.meta,
    selectedEventData: state.data.eventState.currentEvent.data,
    eventStatsIntervals:
      state.configurationState.config.data?.eventStatisticIntervals.value,
  };
};

export default connect<
  ReportDetailsStoreProps,
  ReportDetailsDispatchProps,
  unknown,
  RootReducerInterface
>(mapStateToProps, {
  getDowntimesInShiftReport: downtimesReportThunks.getDowntimesInShiftReport,
  getDowntime: downtimesReportThunks.getDowntime,
  getShiftReport: shiftReportThunks.getShiftReport,
  getDurationDeviations: DurationThunks.getDurationDeviations,
  getProductionProgress,
  getAllManufacturingEntities:
    ManufacturingEntityThunks.getManufacturingEntities,
  updateDowntimeInShiftReport:
    downtimesReportThunks.updateDowntimeInShiftReport,
  getDowntimeReasons: downtimeReasonsThunks.getDowntimeReasons,
  updateDowntimeInShiftReportBasedOnOverview:
    downtimesReportThunks.updateDowntimeInShiftReportBasedOnOverview,
  getSelectedEvent: Thunks.getCurrentEvent,
})(ReportOverviewDetails);
