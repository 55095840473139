import { PropsWithChildren } from 'react';
import {
  ButtonWrapper,
  FunctionContainer,
  FunctionWrapper,
  Image,
} from '../styled_components';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { DefaultImageViewProps } from './defaut_image_view';

interface ImageRotateViewProps extends DefaultImageViewProps {
  readonly loadedImage?: HTMLImageElement;
  readonly onRotateApplied: (canvas: HTMLCanvasElement) => Promise<void>;
  readonly onRotateCancelled: () => void;
}

const ImageRotateView = ({
  fullContent,
  thumbnailContent,
  isLoading,
  hasError,
  onImageRendered,
  onRotateApplied,
  onRotateCancelled,
  loadedImage,
  children,
}: PropsWithChildren<ImageRotateViewProps>) => {
  const getRotatedImg = async () => {
    if (!loadedImage || !loadedImage.complete) {
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = loadedImage.naturalWidth;
    canvas.height = loadedImage.naturalHeight;

    ctx && ctx.drawImage(loadedImage, 0, 0);

    await onRotateApplied(canvas);
  };

  return (
    <>
      <Image
        fullContent={fullContent}
        thumbnailContent={thumbnailContent}
        loading={isLoading}
        mediaError={hasError}
        onLoad={onImageRendered}
      />
      <FunctionWrapper>
        <FunctionContainer>
          {children}
          <ButtonWrapper>
            <Button
              type="primary"
              size="small"
              icon="checkmark"
              onClick={() => void getRotatedImg()}
              disabled={!loadedImage || !loadedImage.complete}
            />
            <Button
              type="secondary"
              size="small"
              icon="cross"
              onClick={onRotateCancelled}
            />
          </ButtonWrapper>
        </FunctionContainer>
      </FunctionWrapper>
    </>
  );
};

export default ImageRotateView;
