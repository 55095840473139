import { FormattedMessage } from 'react-intl';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import React from 'react';
import styled from 'styled-components';
import { Theme } from '@dev/base-web/dist/view/themes/interface';

const Content = styled.div`
  display: flex;
  width: 620px;
`;

const Example = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
`;

const ExampleTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 18px;
`;

const Explanation = styled.div`
  width: 50%;
`;

const HintTitle = styled.p`
  font-weight: bold;
  padding-bottom: 12px;
`;

const ExampleKeyTitle = styled.p`
  font-style: italic;
  color: ${({ theme }) => theme.colors.brownGrey};
`;

const ExampleWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-left: 6px;
`;

const HintContent = ({
  theme,
  hasWhiteBackground,
}: {
  theme: Theme;
  hasWhiteBackground?: boolean;
}) => {
  return (
    <Content>
      <Example>
        <ExampleTitle>"waste"</ExampleTitle>
        <table>
          <tbody>
            <tr>
              <td>
                <ExampleKeyTitle>
                  <FormattedMessage id={'automated'} />
                </ExampleKeyTitle>
              </td>
              <td></td>
              <td>
                <ExampleKeyTitle>
                  <FormattedMessage id={'intentional'} />
                </ExampleKeyTitle>
              </td>
            </tr>
            <tr>
              <td>
                <ExampleWithIcon>
                  "Müll"
                  <StyledIcon
                    name={'cross'}
                    color={theme.colors.cherryRed}
                    size={16}
                  />
                </ExampleWithIcon>
              </td>
              <td>
                <Icon
                  name={'arrow-rightdash'}
                  color={
                    hasWhiteBackground ? theme.colors.black : theme.colors.white
                  }
                  size={16}
                />
              </td>
              <td>
                <ExampleWithIcon>
                  "Ausschuss"
                  <StyledIcon
                    name={'checkmark'}
                    color={theme.colors.primary}
                    size={16}
                  />
                </ExampleWithIcon>
              </td>
            </tr>
          </tbody>
        </table>
      </Example>
      <Explanation>
        <HintTitle>
          <FormattedMessage id={'glossary_hint_title'} />
        </HintTitle>
        <FormattedMessage id={'glossary_hint_content'} />
      </Explanation>
    </Content>
  );
};

export default HintContent;
