import {
  EventInterface,
  EventStatusType,
  EventType,
} from '@dev/base-web/dist/model/domain/event/event_type';
import ManufacturingEntity, {
  ManufacturingEntityType,
} from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';

interface DummyType extends EventInterface {
  [k: string]: any;
}

const dummyManu = {
  shortName: 'Machine',
  name: 'Machine',
  type: ManufacturingEntityType.LINE,
  id: 'Machine',
  selectable: true,
  parallelizationFactor: 0,
  productsPerCycle: 0,
};

export const dummyItem: DummyType = {
  manufacturingEntity: dummyManu as ManufacturingEntity,
  defId: 'dummyEvent',
  hasFeedback: false,
  description: 'This is a Dummy Event.',
  status: EventStatusType.ACTIVE,
  id: 'dummyEvent',
  timestamp: Date.now(),
  type: EventType.ERROR,
  name: 'Dummy event',
  hasSolution: false,
  actionCount: 0,
  actions: [],
  commentCount: 0,
  comments: [],
  occurrenceCount: 0,
  manufacturingEntities: [],
  isClosedByUser: false,
};
