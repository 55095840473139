import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { RootReducerInterface } from '../interfaces';
import { Actions } from './actions';
import { eventDefinitionApi, dataSourceApi } from '../../api';
import {
  EventDefinitionAdd,
  EventDefinitionStatus,
  OpeningConditionType,
  OpeningFeatureCondition,
} from '../../domain/event/event_definition';
import { OperationType } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import { Dispatch } from 'redux';
import {
  DataApiSortConfig,
  SortingDirection,
} from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import { extractSignalFunctions } from '@dev/base-web/dist/view/components/feature/feature_config_card/helper';

export const createGetEventsThunk =
  (eventActions = Actions.events) =>
  (
    status: EventDefinitionStatus | null,
    page: number,
    filters: readonly FilterData[],
    sortingKey = 'name',
    sorting = SortingDirection.ASCENDING,
    loadAllPagesUntilTheGivenOne?: boolean
  ) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;

    dispatch(eventActions.meta.startLoading());

    let sortConfig = undefined;
    if (sortingKey && sorting) {
      sortConfig = new DataApiSortConfig(sortingKey, sorting);
    }
    try {
      if (page <= 0 || loadAllPagesUntilTheGivenOne) {
        dispatch(
          eventActions.resetList({
            result: { results: [], hasMoreResults: true },
          })
        );
      }

      const result = await eventDefinitionApi.getEvents(
        status,
        page,
        filters,
        token.accessToken,
        sortConfig,
        loadAllPagesUntilTheGivenOne
      );
      if (page <= 0 || loadAllPagesUntilTheGivenOne) {
        dispatch(eventActions.resetList({ result }));
      } else {
        dispatch(eventActions.loadingListSuccessful({ result }));
      }
      dispatch(eventActions.meta.endLoading({ filters, sortConfig, page }));
    } catch (error) {
      dispatch(eventActions.meta.loadingFailed({ error }));
      dispatch(eventActions.meta.endLoading());
    }
  };

export const setActiveTab = (tab: number) => (dispatch: Dispatch) => {
  dispatch(Actions.setActiveTab(tab));
};

export const updateEvent =
  (id: string, event: EventDefinitionAdd) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.updateEvent.meta.startOperation({
        operation: OperationType.UPDATE,
      })
    );

    try {
      const result = await eventDefinitionApi.updateEvent(
        id,
        event,
        token.accessToken
      );
      dispatch(Actions.event.loadingItemSuccessful(result));
      dispatch(Actions.updateEvent.meta.operationSucceeded());
    } catch (error) {
      dispatch(Actions.updateEvent.meta.operationFailed({ error }));
    }
  };

export const multiUpdateEvents =
  (ids: string[], body: any) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.multiUpdateEvent.meta.startOperation({
        operation: OperationType.UPDATE,
      })
    );

    try {
      await eventDefinitionApi.multiUpdateEvents(
        [...new Set(ids)],
        body,
        token.accessToken
      );
      dispatch(Actions.multiUpdateEvent.meta.operationSucceeded());
      dispatch(Actions.event.reset());
    } catch (error) {
      dispatch(Actions.multiUpdateEvent.meta.operationFailed({ error }));
    }
  };

export const getEvent =
  (id: string) =>
  (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;

    dispatch(Actions.event.meta.startLoading());

    eventDefinitionApi
      .getEvent(id, token.accessToken)
      .then((event) => {
        dispatch(Actions.event.loadingItemSuccessful(event));
        dispatch(Actions.event.meta.endLoading());
      })
      .catch((error) => {
        dispatch(Actions.event.meta.endLoading());
        dispatch(Actions.event.meta.loadingFailed({ error }));
      });
  };

export const getDataSourceOfEvent =
  (id: string) =>
  (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;

    dispatch(Actions.dataSourceOfEvent.meta.startLoading());

    eventDefinitionApi
      .getEvent(id, token.accessToken)
      .then((event) => {
        if (
          event.rules.length &&
          event.rules[0].open?.type === OpeningConditionType.FEATURE
        ) {
          const f = (event.rules[0].open as OpeningFeatureCondition).value;
          const sigs = extractSignalFunctions(f);

          if (sigs.length && sigs[0].plcId) {
            dataSourceApi
              .getDataSourceById(sigs[0].plcId, token.accessToken)
              .then((dataSource) => {
                dispatch(
                  Actions.dataSourceOfEvent.loadingItemSuccessful(dataSource)
                );
              })
              .catch((error) => {
                dispatch(Actions.dataSourceOfEvent.meta.endLoading());
                dispatch(
                  Actions.dataSourceOfEvent.meta.loadingFailed({ error })
                );
              });
          }
        } else {
          dispatch(Actions.dataSourceOfEvent.reset());
        }
        dispatch(Actions.dataSourceOfEvent.meta.endLoading());
      })
      .catch((error) => {
        dispatch(Actions.dataSourceOfEvent.meta.endLoading());
        dispatch(Actions.dataSourceOfEvent.meta.loadingFailed({ error }));
      });
  };

export const createEvent =
  (event: EventDefinitionAdd) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.createEvent.meta.startOperation({
        operation: OperationType.CREATE,
      })
    );
    dispatch(Actions.event.reset());

    try {
      const eventResult = await eventDefinitionApi.createEvent(
        event,
        token.accessToken
      );
      dispatch(Actions.createEvent.meta.operationSucceeded());
      dispatch(Actions.event.loadingItemSuccessful(eventResult));
      return eventResult;
    } catch (error) {
      dispatch(Actions.createEvent.meta.operationFailed({ error }));
    }
  };

export const addInstructionsToEvent =
  (eventId: string, actionIds: string[]) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.addEventToInstruction.meta.startOperation({
        operation: OperationType.UPDATE,
      })
    );

    try {
      await eventDefinitionApi.addInstructionsToEvent(
        eventId,
        actionIds,
        token.accessToken
      );
      dispatch(Actions.addEventToInstruction.meta.operationSucceeded());
    } catch (error) {
      dispatch(Actions.addEventToInstruction.meta.operationFailed({ error }));
    }
  };

export const removeInstructionFromEvent =
  (eventId: string, actionId: string) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.removeInstructionFromEvent.meta.startOperation({
        operation: OperationType.UPDATE,
      })
    );

    try {
      await eventDefinitionApi.removeInstructionFromEvent(
        eventId,
        actionId,
        token.accessToken
      );
      dispatch(Actions.removeInstructionFromEvent.meta.operationSucceeded());
    } catch (error) {
      dispatch(
        Actions.removeInstructionFromEvent.meta.operationFailed({ error })
      );
    }
  };

export const subscribeToEvent =
  (eventId: string) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.subscribeToEvent.meta.startOperation({
        operation: OperationType.UPDATE,
      })
    );

    try {
      await eventDefinitionApi.subscribeToEvent(eventId, token.accessToken);
      dispatch(Actions.subscribeToEvent.meta.operationSucceeded());
    } catch (error) {
      dispatch(Actions.subscribeToEvent.meta.operationFailed({ error }));
    }
  };

export const unsubscribeFromEvent =
  (eventId: string) =>
  async (dispatch: Dispatch, getState: () => RootReducerInterface) => {
    const { token } = getState().authenticationState.authentication;
    dispatch(
      Actions.unsubscribeFromEvent.meta.startOperation({
        operation: OperationType.UPDATE,
      })
    );

    try {
      await eventDefinitionApi.unsubscribeFromEvent(eventId, token.accessToken);
      dispatch(Actions.unsubscribeFromEvent.meta.operationSucceeded());
    } catch (error) {
      dispatch(Actions.unsubscribeFromEvent.meta.operationFailed({ error }));
    }
  };
