import { createActionsForSingleItem } from '@dev/base-web/dist/model/redux/helpers/actions';
import { AsyncJobResponseDTO } from '@dev/base-web/dist/model/domain/jobs/AsyncJobResponseDTO';
import { AsyncJobStatusDTO } from '@dev/base-web/dist/model/domain/jobs/AsyncJobStatusDTO';
import { EventDefinitionValidationResponse } from '../../domain/event/event_definition_import';

export const IMPORT_EVENT_DEFINITIONS_KEY = 'IMPORT_EVENT_DEFINITIONS';
export const EVENT_DEFINITIONS_IMPORT_STATUS_KEY =
  'EVENT_DEFINITIONS_IMPORT_STATUS';
export const CONFIRM_EVENT_DEFINITIONS_IMPORT_KEY =
  'CONFIRM_EVENT_DEFINITIONS_IMPORT';

export const Actions = {
  importEventDefinition: createActionsForSingleItem<AsyncJobResponseDTO>(
    IMPORT_EVENT_DEFINITIONS_KEY
  ),
  eventDefinitionImportStatus: createActionsForSingleItem<
    AsyncJobStatusDTO<EventDefinitionValidationResponse>
  >(EVENT_DEFINITIONS_IMPORT_STATUS_KEY),
  confirmEventDefinitionImport: createActionsForSingleItem<AsyncJobResponseDTO>(
    CONFIRM_EVENT_DEFINITIONS_IMPORT_KEY
  ),
};
