import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { StyledInput } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { DecisionOptionDTO } from '../../../model/domain/instruction/step';
import { DecisionBtn } from '../../../components/instruction/components';

const DecisionInput = styled(StyledInput)`
  max-width: 100px;
  height: ${({ theme }) => theme.buttons.medium.height}px;
`;

interface DecisionItemProps {
  readonly item: DecisionOptionDTO;
  readonly onRename: (name: string) => void;
  readonly onDelete: () => void;
  readonly onEditModeChange: (value: boolean) => void;
}

const useEscapableEditState = (onEditModeChange: (value: boolean) => void) => {
  const [onEdit, setOnEdit] = useState(false);

  useEffect(() => {
    const escFunction = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        event.stopPropagation();
        setOnEdit(false);
        onEditModeChange(false);
      }
    };

    if (onEdit) {
      document.addEventListener('keydown', escFunction, false);

      return () => {
        document.removeEventListener('keydown', escFunction, true);
      };
    }
  }, [onEdit]);

  return [onEdit, setOnEdit] as const;
};

export const DecisionItem = ({
  item,
  onRename,
  onDelete,
  onEditModeChange,
}: DecisionItemProps) => {
  const [value, setValue] = useState('');
  const [onEdit, setOnEdit] = useEscapableEditState(onEditModeChange);

  const onFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onRename(value);
    setOnEdit(false);
    onEditModeChange(false);
  };

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target?.value);
  };

  if (onEdit) {
    return (
      <form onSubmit={onFormSubmit}>
        <DecisionInput
          type="text"
          onChange={changeValue}
          value={value}
          placeholder={item.name}
          autoFocus={true}
        />
      </form>
    );
  } else {
    return (
      <DecisionBtn
        type="custom"
        text={item.name}
        selected={false}
        onClick={() => {
          setValue(item.name);
          setOnEdit(true);
          onEditModeChange(true);
          setValue(item.name);
        }}
        onDelete={onDelete}
      />
    );
  }
};

interface AddButtonProps {
  readonly onAddOption: (value: string) => void;
  readonly onEditModeChange: (value: boolean) => void;
}

export const AddButton = ({
  onAddOption,
  onEditModeChange,
}: AddButtonProps) => {
  const [value, setValue] = useState('');
  const [onEdit, setOnEdit] = useEscapableEditState(onEditModeChange);

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onAddOption(value);
    setOnEdit(false);
    onEditModeChange(false);
  };

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  if (onEdit) {
    return (
      <form onSubmit={onFormSubmit}>
        <DecisionInput
          type="text"
          onChange={changeValue}
          value={value}
          placeholder="-"
          autoFocus={true}
        />
      </form>
    );
  } else {
    return (
      <Button
        type="secondary"
        onClick={() => {
          setValue('');
          setOnEdit(true);
          onEditModeChange(true);
        }}
        icon="plus"
      />
    );
  }
};
