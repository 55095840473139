import ManufacturingEntity, {
  ManufacturingEntityData,
  ManufacturingEntityType,
} from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { IntervalGroup } from '../report/manufacturing_entity_summary_interval';

export interface FactoryAreaChildResult {
  readonly manufacturingEntity: ManufacturingEntity;
  readonly performance: number;
  readonly availability: number;
  readonly utilization: number;
  readonly producedProducts: number;
  readonly cycleCount: number;
  readonly currentProduct: string;
  readonly lastCycleTimestamp: number;
  readonly interval: IntervalGroup;
  readonly productsPerCycle: MinMax;
}

export interface MinMax {
  readonly min: number;
  readonly max: number;
}

export const groupKpisByMainEntity = (
  kpis: FactoryAreaChildResult[]
): FactoryAreaChildResult[] => {
  const kpisToGroup = kpis.filter(
    (k) =>
      k.manufacturingEntity.type !== ManufacturingEntityType.LINE &&
      k.manufacturingEntity.type !== ManufacturingEntityType.MACHINE
  );

  const group = kpisToGroup.reduce(
    (acc: { [key: string]: FactoryAreaChildResult }, obj) => {
      const key: ManufacturingEntityData =
        obj.manufacturingEntity.parents?.find(
          (p) =>
            p.type === ManufacturingEntityType.LINE ||
            p.type === ManufacturingEntityType.MACHINE
        ) || obj.manufacturingEntity;

      const curGroup = acc[key.id] ?? {};

      const groupedKpi = !curGroup.manufacturingEntity
        ? obj
        : {
            ...curGroup,
            manufacturingEntity: {
              ...key,
              childs: [],
              childCount: 0,
              followingManufacturingEntities: [],
              viewWidth: 0,
              viewHeight: 0,
              viewCoordinateY: 0,
              viewCoordinateX: 0,
              parallelizationFactor: 1,
              productsPerCycle: 1,
              parents: [],
            },
            utilization: (curGroup.utilization + obj.utilization) / 2,
            producedProducts: curGroup.producedProducts + obj.producedProducts,
            cycleCount: curGroup.cycleCount + obj.producedProducts,
            lastCycleTimestamp: Math.max(
              curGroup.lastCycleTimestamp,
              obj.lastCycleTimestamp
            ),
          };

      return { ...acc, [key.id]: groupedKpi };
    },
    {}
  );

  return [
    ...kpis.filter(
      (k) =>
        k.manufacturingEntity.type === ManufacturingEntityType.LINE ||
        k.manufacturingEntity.type === ManufacturingEntityType.MACHINE
    ),
    ...Object.values(group),
  ];
};
