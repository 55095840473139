import { combineReducers, Reducer } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';
import listReducer from './listReducer';
import * as Constants from '../../Constants';
import manufacturingEntityState from '@dev/base-web/dist/model/redux/manufacturing_entity/reducers';
import featureState from '@dev/base-web/dist/model/redux/feature/reducers';
import signalState from '@dev/base-web/dist/model/redux/plc_signal/reducers';
import createLocalisationReducer from '@dev/base-web/dist/model/redux/localisation/reducers';
import reportOverviewState from '@dev/base-web/dist/model/redux/report/overview/reducers';
import shiftReportProductState from './report/product/reducers';
import downtimesState from '@dev/base-web/dist/model/redux/report/downtime/reducers';
import aggregatedDowntimesState from './downtime/reducers';
import instructionDefinitionState from './instruction_definition/reducers';
import instructionState from './instruction/reducers';
import mediaState from '@dev/base-web/dist/model/redux/media/reducers';
import eventState from './events/reducers';
import parallelEventsState from './parallel_events/reducers';
import feedbackState from './feedback/reducers';
import userState from '@dev/base-web/dist/model/redux/user/reducers';
import userTenantState from '@dev/base-web/dist/model/redux/user_tenant/persisted_reducers';
import configurationState from '@dev/base-web/dist/model/redux/configuration/reducers';
import userRoleState from '@dev/base-web/dist/model/redux/user_role/reducers';
import authenticationState from '@dev/base-web/dist/model/redux/authentication/persisted_reducers';
import eventsState from '@dev/base-web/dist/model/redux/events/reducers';
import managementNotificationsState from '@dev/base-web/dist/model/redux/management_notifications/reducers';
import eventDefinitionState from './event_definition/reducers';
import eventDefinitionImportState from './event_definition_import/reducers';
import durationsState from '@dev/base-web/dist/model/redux/durations/reducers';
import durationConfigState from '@dev/base-web/dist/model/redux/duration_config/reducers';
import kpiState from './report/kpi/reducers';
import mainEntityKpiState from './kpis/reducers';

import localisationDE from '../../resources/localisation/de.json';
import localisationEN from '../../resources/localisation/en.json';
import localisationSK from '../../resources/localisation/sk.json';
import localisationIT from '../../resources/localisation/it.json';
import localisationFR from '../../resources/localisation/fr.json';
import localisationZH from '../../resources/localisation/zh.json';
import localisationCS from '../../resources/localisation/cs.json';
import localisationNL from '../../resources/localisation/nl.json';
import localisationES from '../../resources/localisation/es.json';
import localisationTODO from '../../resources/localisation/TODO.json';
import localisationUK from '../../resources/localisation/uk.json';
import localisationRO from '../../resources/localisation/ro.json';
import localisationSR from '../../resources/localisation/sr.json';
import { clientConfigs } from '../api/clients';
import createConfigReducer from '@dev/base-web/dist/model/redux/config/persisted_reducers';
import subscriptionState from '@dev/base-web/dist/model/redux/subscription/reducers';
import dataSourceState from '@dev/base-web/dist/model/redux/data_source/reducers';
import translationState from './translation/reducers';
import labelState from '@dev/base-web/dist/model/redux/labels/reducers';
import commentState from '@dev/base-web/dist/model/redux/event_comment/reducers';
import productionShiftState from '@dev/base-web/dist/model/redux/production_shift/reducers';
import { RootReducerInterface } from './interfaces';
import { ActionsStateInterface } from './actions/interface';
import importerState from '@dev/base-web/dist/model/redux/importer_status/reducers';

export const localisation = {
  de: { messages: localisationDE, label: 'Deutsch' },
  en: {
    messages: { ...localisationTODO, ...localisationEN },
    label: 'English',
  },
  sk: { messages: localisationSK, label: 'Slovakian' },
  it: { messages: localisationIT, label: 'Italian' },
  fr: { messages: localisationFR, label: 'French' },
  zh: { messages: localisationZH, label: 'Chinese' },
  cs: { messages: localisationCS, label: 'Czech' },
  nl: { messages: localisationNL, label: 'Dutch' },
  es: { messages: localisationES, label: 'Spanish' },
  uk: { messages: localisationUK, label: 'Ukrainian' },
  ro: { messages: localisationRO, label: 'Romanian' },
  sr: { messages: localisationSR, label: 'Serbian' },
};

/**
 * TODO: old code converted to typescript (with as minimal changes as possible)
 */
function createNamedListReducer(
  reducerFunction: typeof listReducer,
  reducerName: string
): Reducer<ActionsStateInterface> {
  return (state, action) => {
    const { name } = action as any;
    const isInitializationCall = state === undefined;
    if (name !== reducerName && !isInitializationCall) return state;

    return reducerFunction(state as any, action);
  };
}

const reducers: Reducer<RootReducerInterface> = combineReducers({
  authenticationState,
  userState,
  userTenantState,
  managementNotificationsState,
  userRoleState,
  localisationState: createLocalisationReducer(localisation),
  data: combineReducers({
    // TODO: the whole state should be rewritten to use our common redux helpers
    actions: createNamedListReducer(listReducer, Constants.ACTIONSLUG),
    signalState,
    reportOverview: reportOverviewState,
    shiftReportProduct: shiftReportProductState,
    downtimes: downtimesState,
    manufacturingEntityState,
    // New reducers
    eventDefinitionState,
    eventDefinitionImportState,
    instructionDefinitionState,
    instructionState,
    mediaState,
    eventState,
    parallelEventsState,
    eventsState,
    feedbackState,
    aggregatedDowntimesState,
    durations: durationsState,
    durationConfigState,
    kpiState,
    mainEntityKpiState,
    subscriptionState,
    dataSourceState,
    translationState,
    labelState,
    commentState,
    productionShiftState,
    featureState,
    importerState,
  }),
  toastr,
  configState: createConfigReducer({
    apiClient: { axiosClients: clientConfigs },
  }),
  configurationState,
});

export default reducers;
