import React from 'react';
import styled from 'styled-components';
import {
  Button,
  RightAlignedButtons,
} from '@dev/base-web/dist/view/components/global/button';

const StyledButtonContainer = styled(RightAlignedButtons)`
  margin-right: 16px;
`;

interface EventActionButtonsProps {
  readonly onArchivePressed: () => void;
  readonly onEditPressed: () => void;
  readonly onAddPressed: () => void;
  readonly archiveEnabled: boolean;
  readonly editDisabled: boolean;
  readonly archiveShown: boolean;
  readonly importModal: React.ReactNode;
  readonly allowedToModify: boolean;
}

const EventActionButtons: React.FC<EventActionButtonsProps> = ({
  onArchivePressed,
  archiveEnabled,
  archiveShown,
  onEditPressed,
  onAddPressed,
  importModal,
  allowedToModify,
  editDisabled,
}) => {
  return (
    <StyledButtonContainer>
      <Button
        type="secondary"
        icon="plus"
        label="new"
        onClick={onAddPressed}
        disabled={!allowedToModify}
      />
      <Button
        type="secondary"
        icon="edit"
        label="edit"
        disabled={editDisabled || !allowedToModify}
        onClick={onEditPressed}
      />
      <Button
        type="secondary"
        icon={archiveShown ? 'revert' : 'archive'}
        label={archiveShown ? 'restore' : 'archive'}
        disabled={!(archiveEnabled && allowedToModify)}
        onClick={onArchivePressed}
      />
      {importModal}
    </StyledButtonContainer>
  );
};

export default EventActionButtons;
