import { RootReducerInterface } from '../../../../../model/redux/interfaces';
import { connect } from 'react-redux';
import ForwardEventModal from './view';
import { ForwardEventModalStorePropsWithMedia } from './interface';
import UserThunks from '../../../../../model/redux/user/thunks';
import EventThunks from '../../../../../model/redux/events/thunks';
import MediaThunks from '../../../../../model/redux/media/thunks';

const mapStateToProps = (
  state: RootReducerInterface
): ForwardEventModalStorePropsWithMedia => {
  return {
    users: state.userState.allUsers.data,
    usersMeta: state.userState.allUsers.meta,
    forwardEventMeta: state.data.eventState.forwardEvent.meta,
    images: state.data.mediaState.imageList.data.results,
    selected: state.data.mediaState.selected,
    uploads: state.data.mediaState.uploads,
    updating: state.data.mediaState.update,
  };
};

export default connect(mapStateToProps, {
  getAllUsers: UserThunks.getAllUsers,
  forwardEvent: EventThunks.forwardEvent,
  clearUploadedPictures: MediaThunks.clearUploadedPictures,
  clearSelectedPicture: MediaThunks.clearSelectedPicture,
  uploadFiles: MediaThunks.uploadMedia,
})(ForwardEventModal);
