import { useEffect, useState } from 'react';
import { StepDTO, Translation } from '@/model/domain/instruction/step.ts';
import { usePrevious } from '@dev/base-web/dist/view/helpers/use_previous';
import { useIntl } from 'react-intl';

const usePromptHandling = (
  open: boolean,
  instructionTitle?: readonly Translation[],
  instructionSteps?: readonly StepDTO[]
) => {
  const intl = useIntl();

  const [prompt, setPrompt] = useState<string>('');
  const [firstInstructionTitle, setFirstInstructionTitle] = useState<string>();

  useEffect(() => {
    if (open) {
      setFirstInstructionTitle(
        instructionTitle?.length ? instructionTitle[0].translation : undefined
      );
    }
  }, [instructionTitle, open]);

  const lastFirstInstructionTitle = usePrevious(firstInstructionTitle);

  useEffect(() => {
    if (
      open &&
      !instructionSteps?.length &&
      (!prompt ||
        prompt ===
          intl.formatMessage(
            { id: 'suggest_instruction_prompt_title' },
            { title: lastFirstInstructionTitle }
          ))
    )
      setPrompt(
        firstInstructionTitle
          ? intl.formatMessage(
              { id: 'suggest_instruction_prompt_title' },
              { title: firstInstructionTitle }
            )
          : ''
      );
  }, [open, firstInstructionTitle]);

  return {
    prompt,
    setPrompt,
  };
};

export default usePromptHandling;
