import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import {
  Button,
  CenteredButtons,
} from '@dev/base-web/dist/view/components/global/button';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { DropdownProps } from 'semantic-ui-react';
import InstructionSideCard from './instruction_side_card';
import AddLanguagePopup from './add_language_popup';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import { localisation } from '@/model/redux/reducers.ts';

const LanguageListCard = styled(InstructionSideCard)`
  width: 100%;
  //max-height: 360px;
  position: relative;
`;

export const ListItem = styled.div<{ last: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  padding: 16px 6px;

  ${(last) => !last && 'box-shadow: inset 0 -1px 0 0 #efefef;'};

  span {
    width: 230px;
  }

  &:hover {
    background: rgba(124, 128, 127, 0.04);
  }

  cursor: pointer;
`;

const StyledDropdown = styled(StyledDropDown)`
  margin-top: 12px;
  margin-bottom: 19px;
`;

const ButtonContainer = styled(CenteredButtons)`
  margin-top: 8px;
`;

interface InstructionLanguagesSideCardProps {
  readonly title: string;
  readonly isNewAction: boolean;
  readonly selectedLanguage: string | null;
  readonly existingTranslations: string[];
  readonly onLanguageChanged: (languageCode: string) => void;
  readonly onNewLanguageTranslated: (languageCode: string) => void;
  readonly canEdit: boolean;
  readonly readMode: boolean;
  readonly onGlossaryClicked: () => void;
}

const InstructionLanguagesSideCard: React.FC<
  InstructionLanguagesSideCardProps
> = (props) => {
  const {
    title,
    isNewAction,
    existingTranslations,
    onLanguageChanged,
    onNewLanguageTranslated,
    selectedLanguage,
    canEdit,
    readMode,
    onGlossaryClicked,
  } = props;

  const intl = useIntl();
  const theme = useTheme();

  const selectLowerLanguage =
    selectedLanguage !== null
      ? selectedLanguage.toLowerCase()
      : selectedLanguage;

  const selectableLanguages = Object.keys(localisation).filter(
    (item) => existingTranslations.indexOf(item.toUpperCase()) < 0
  );

  const languageOptions = Object.keys(localisation).map((l) => {
    return {
      key: l,
      value: l,
      text: intl.formatMessage({ id: l.toLowerCase() }),
    };
  });

  const handleLanguageDropdownChange = (
    _event: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    onLanguageChanged(value as unknown as string);
  };

  // TODO: Make onClose and onOk into a common function?
  return (
    <>
      <LanguageListCard
        title={title}
        bottomButton={
          !isNewAction &&
          !readMode && (
            <AddLanguagePopup
              values={selectableLanguages}
              trigger={
                <ButtonContainer>
                  <Button
                    type="tertiary"
                    icon="plus"
                    label="add_translation"
                    disabled={!canEdit}
                  />
                </ButtonContainer>
              }
              onAddLanguage={onNewLanguageTranslated}
              disabled={!canEdit}
            />
          )
        }
        headerContent={
          !readMode && (
            <Button
              icon={'glossary'}
              type={'tertiary'}
              noMargin
              onClick={onGlossaryClicked}
              tooltipLabel={'glossary'}
              tooltipDelay={600}
            />
          )
        }
        disableContainerMargin
      >
        {isNewAction && (
          <StyledDropdown
            text={
              selectedLanguage
                ? intl.formatMessage({ id: selectedLanguage?.toLowerCase() })
                : null
            }
            selection
            onChange={handleLanguageDropdownChange}
            options={languageOptions}
          />
        )}
        {!isNewAction &&
          existingTranslations.map((lang, index) => (
            <ListItem
              key={lang}
              onClick={() => {
                const language = lang.toLowerCase();
                onLanguageChanged(language);
              }}
              last={existingTranslations.length - 1 === index}
            >
              <span>
                <FormattedMessage id={lang.toLowerCase()} />
              </span>
              {lang.toLowerCase() === selectLowerLanguage && (
                <Icon name="checkmark" color={theme.colors.primary} size={16} />
              )}
            </ListItem>
          ))}
      </LanguageListCard>
    </>
  );
};

export default InstructionLanguagesSideCard;
