import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';
import { Feature } from '@dev/base-web/dist/model/domain/feature/feature';
import { Store } from 'redux';
import { AxiosInstance } from 'axios';

const FEATURE_URL = '/feature';
const FEATURE_URL_TEMPLATE = (id: string) => `/feature/${id}`;

export default class FeatureApi extends BaseDataApi<Feature> {
  constructor(
    public store: Store,
    public baseUrl: string,
    axiosClient?: AxiosInstance
  ) {
    super(store, axiosClient);
  }

  async createFeature(newFeature: any, token: string) {
    const url = this.baseUrl + FEATURE_URL;
    return await this.createItem(newFeature, url, token);
  }

  async updateFeature(featureId: string, newFeature: any, token: string) {
    const url = this.baseUrl + FEATURE_URL_TEMPLATE(featureId);
    return await this.updateItem<Feature>(newFeature, url, token);
  }
}
