import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 20px;
  height: 20px;
  background-color: black;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
export const StepNumber = (
  props: PropsWithChildren<{ className?: string }>
) => {
  return (
    <Wrapper className={props.className}>
      <span className="Number" style={{ alignSelf: 'center' }}>
        {props.children}
      </span>
    </Wrapper>
  );
};
