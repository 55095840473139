import styled from 'styled-components';

export const DropdownWithLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 300px;
  margin-right: 24px;
  margin-bottom: 24px;
`;

export const LabelContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: ${({ theme }) => theme.colors.warmGrey};
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
