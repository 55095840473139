import UserRole from '@dev/base-web/dist/model/domain/user/user_role';
import UserSubscriptionTopic, {
  SubscriptionMediaType,
  UserSubscriptionTopicType,
} from '@dev/base-web/dist/model/domain/user/user_subscription_topic';
import UserSubscriptionTopicSettings from '../../../../model/domain/user/user_subscription_topic_setting';

const subscriptionTopicMapping = [
  {
    topic: UserSubscriptionTopicType.EVENT,
    allowedRoles: [],
    allowedMediaTypes: [SubscriptionMediaType.PUSH_NOTIFICATION],
  },
  {
    topic: UserSubscriptionTopicType.SUBSCRIBED_EVENT,
    allowedRoles: [],
    allowedMediaTypes: [
      SubscriptionMediaType.EMAIL,
      SubscriptionMediaType.PUSH_NOTIFICATION,
    ],
  },
  {
    topic: UserSubscriptionTopicType.ACCESS_REQUEST,
    allowedRoles: [UserRole.ADMIN, UserRole.SUPERADMIN],
    allowedMediaTypes: [SubscriptionMediaType.EMAIL],
  },
  {
    topic: UserSubscriptionTopicType.FEATURE_DISABLED,
    allowedRoles: [UserRole.ADMIN, UserRole.SUPERADMIN],
    allowedMediaTypes: [SubscriptionMediaType.EMAIL],
  },
  {
    topic: UserSubscriptionTopicType.FEEDBACK,
    allowedRoles: [UserRole.MAINTAINER, UserRole.ADMIN, UserRole.SUPERADMIN],
    allowedMediaTypes: [SubscriptionMediaType.EMAIL],
  },
  {
    topic: UserSubscriptionTopicType.USAGE_STATISTICS,
    allowedRoles: [UserRole.MAINTAINER, UserRole.ADMIN, UserRole.SUPERADMIN],
    allowedMediaTypes: [SubscriptionMediaType.EMAIL],
  },
];

const subscriptionTopicsDefinitions = (
  role: UserRole | string
): UserSubscriptionTopicSettings[] => {
  return subscriptionTopicMapping
    .filter(
      (s) =>
        s.allowedRoles.length === 0 || s.allowedRoles.includes(role as UserRole)
    )
    .map((s) => ({
      topic: s.topic,
      isPushNotification: s.allowedMediaTypes.includes(
        SubscriptionMediaType.PUSH_NOTIFICATION
      )
        ? false
        : undefined,
      isEmail: s.allowedMediaTypes.includes(SubscriptionMediaType.EMAIL)
        ? false
        : undefined,
    }));
};

const setSubscriptionTopicMediaTypes = (
  definitions: UserSubscriptionTopicSettings[],
  topics: UserSubscriptionTopic[]
): UserSubscriptionTopicSettings[] => {
  const newDefinitions: UserSubscriptionTopicSettings[] = [];
  definitions.forEach((d) => newDefinitions.push(Object.assign({}, d)));

  for (const t of topics) {
    const idx = definitions.findIndex((d) => d.topic === t.name);

    if (
      t.mediaType === SubscriptionMediaType.PUSH_NOTIFICATION &&
      newDefinitions[idx] &&
      newDefinitions[idx].isPushNotification !== undefined
    )
      newDefinitions[idx].isPushNotification = true;

    if (
      t.mediaType === SubscriptionMediaType.EMAIL &&
      newDefinitions[idx] &&
      newDefinitions[idx].isEmail !== undefined
    )
      newDefinitions[idx].isEmail = true;
  }

  return newDefinitions;
};

export const getSubscriptionTopicSettings = (
  role: UserRole | string,
  topics: UserSubscriptionTopic[]
): UserSubscriptionTopicSettings[] => {
  const defs = subscriptionTopicsDefinitions(role);
  return setSubscriptionTopicMediaTypes(defs, topics);
};

export const updateSubscriptionTopic = (
  topics: UserSubscriptionTopic[],
  updatedTopic: UserSubscriptionTopicSettings
): UserSubscriptionTopic[] => {
  const newTopics: UserSubscriptionTopic[] = [];
  topics
    .filter((d) => d.name !== updatedTopic.topic)
    .forEach((d) => newTopics.push(Object.assign({}, d)));

  if (updatedTopic.isEmail)
    newTopics.push(
      Object.assign(
        {},
        { name: updatedTopic.topic, mediaType: SubscriptionMediaType.EMAIL }
      )
    );

  if (updatedTopic.isPushNotification)
    newTopics.push(
      Object.assign(
        {},
        {
          name: updatedTopic.topic,
          mediaType: SubscriptionMediaType.PUSH_NOTIFICATION,
        }
      )
    );

  return newTopics;
};
