import { DARWIN_BASE_URL } from '@dev/base-web/dist/model/domain/authorization/darwin';
import { DATA_COLLECTOR_BASE_URL } from '@dev/base-web/dist/model/domain/authorization/data_collector';
import { SHANNON_BASE_URL } from '@dev/base-web/dist/model/domain/authorization/shannon';
import { ApplicationCategory } from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import { TokenAttributesInterface } from '@dev/base-web/dist/model/redux/authentication/interface';
import { LocaleMappingInterface } from '@dev/base-web/dist/model/redux/localisation/interface';
import { setLocale } from '@dev/base-web/dist/model/redux/localisation/thunks';
import useUserLicenses from '@dev/base-web/dist/view/components/global/user_licenses_hook';
import usePathPrefix, {
  PATH_PREFIX_SEPARATOR,
} from '@dev/base-web/dist/view/helpers/use_path_prefix';
import { usePrevious } from '@dev/base-web/dist/view/helpers/use_previous';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'yet-another-react-lightbox/styles.css';
import ConfigurationThunks from './model/redux/configuration/thunks';
import { RootReducerInterface } from './model/redux/interfaces';
import routes from './routes';
import { CONFIG as USER_PROPERTIES_CONFIG } from './screens/user_properties';
import './styles.css';
import UserTenantThunks from './model/redux/user_tenant/thunks';
import { TooltipProvider } from '@radix-ui/react-tooltip';
const TITLE = 'Shannon® - Operator Assistance';

interface AppStorePropsInterface {
  readonly token: string;
  readonly tokenAttributes: TokenAttributesInterface;
  readonly userLoggedOut: boolean;
  readonly locales: ReadonlyArray<LocaleMappingInterface>;
  readonly locale: string;
  readonly selectedTenantId: string;
}

type AppPropsInterface = AppStorePropsInterface & {
  readonly getConfig: () => Promise<void>;
  readonly setLocale: (locale: string) => void;
  readonly setSelectedTenantId: (tenantId: string) => void;
};

const App: React.FC<AppPropsInterface> = ({
  getConfig,
  token,
  tokenAttributes,
  userLoggedOut,
  locales,
  locale,
  setLocale,
  selectedTenantId,
  setSelectedTenantId,
}) => {
  const licenses = useUserLicenses();

  useEffect(() => {
    if (
      licenses === undefined ||
      licenses.includes(ApplicationCategory.SHANNON) ||
      window.location.pathname.match(
        `${USER_PROPERTIES_CONFIG.url.baseUrl}/[\\w/]*${PATH_PREFIX_SEPARATOR}${USER_PROPERTIES_CONFIG.url.path}`
      )
    ) {
      // if the /user/me endpoint didn't return yet, or it contains shannon, then we shouldn't redirect!
      // also: the user profile page (/shannon/user/<id>) should be available even if the user has no shannon license!
      return;
    } else if (
      licenses.includes(ApplicationCategory.DARWIN) ||
      licenses.includes(ApplicationCategory.HOPPER)
    ) {
      // redirect to darwin
      window.location.replace(DARWIN_BASE_URL);
    } else if (licenses.includes(ApplicationCategory.DATA)) {
      // redirect to data-collector
      window.location.replace(DATA_COLLECTOR_BASE_URL);
    }
  }, [licenses]);

  useEffect(() => {
    if (token && selectedTenantId) {
      void getConfig();
    }
  }, [token, selectedTenantId]);

  const prevTenant = usePrevious(selectedTenantId);
  const prevLocale = usePrevious(locale);

  const pathPrefix = usePathPrefix(
    SHANNON_BASE_URL,
    window.location.pathname + window.location.search,
    userLoggedOut,
    [
      {
        key: 'tenant',
        possibleValues: tokenAttributes.tenant?.split(',') ?? [],
        selectedValue: selectedTenantId,
        previousValue: prevTenant,
        selectedValueChanged: (tenant) => {
          setSelectedTenantId(tenant);
        },
      },
      {
        key: 'locale',
        possibleValues: locales.map((locale) => locale.key),
        selectedValue: locale,
        previousValue: prevLocale,
        selectedValueChanged: setLocale,
      },
    ],
    [selectedTenantId, locale, prevLocale, prevTenant]
  );

  const router = useMemo(
    () =>
      createBrowserRouter(routes, {
        basename: SHANNON_BASE_URL + pathPrefix,
      }),
    [pathPrefix, routes]
  );

  return (
    <>
      <TooltipProvider>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <RouterProvider router={router} />
      </TooltipProvider>
    </>
  );
};

const mapStateToProps = (
  state: RootReducerInterface
): AppStorePropsInterface => {
  return {
    token: state.authenticationState.authentication.token.accessToken,
    tokenAttributes: state.authenticationState.authentication.attributes,
    userLoggedOut: state.authenticationState.authentication.userLoggedOut,
    locales: state.localisationState.locales,
    locale: state.localisationState.locale,
    selectedTenantId:
      state.userTenantState.selectedTenantIdState.selectedTenantId,
  };
};

export default connect(mapStateToProps, {
  getConfig: ConfigurationThunks.getConfig,
  setLocale,
  setSelectedTenantId: UserTenantThunks.setSelectedTenantId,
})(App);
